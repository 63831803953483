export function buildDeviceStatusChart(returnValue) {
    // 指定图表的配置项和数据
    var option = {
        color: ['#37A2DA', '#FB7293'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        tooltip: {
            trigger: 'axis'
        },
        legend: {
            show: true
        },
        grid: {
            left: '5%',
            right: '5%'
        },
        xAxis: {
            type: 'category',
            axisLabel: {
                interval: 0
            },
            data: returnValue.option.xAxis.data
        },
        yAxis: returnValue.option.yAxis,
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100
            }
        ],
        series: returnValue.option.series
    };
    return option;
}
