import { ObjType } from '../../../common/models/codeItems.model';

export interface PointCategory {
  cateId: string; // idxCode
  displayPrecision: number;
  pointUnit: string; // idxDimension
  cateName: string;
  cateStatus: string;
  calcPeriod: number; // calcPriority
  savePrecision: number;
  dataPeriod: string; // savePeriod
  persistenceType: string; // saveType
  dataType: string; // idxDataType
  calcFormula: string; // idxFormula

  displayGroup1: string;
  displayGroup2: string;
  displayOrder1: number;
  displayOrder2: number;
  displayOrder3: number;
  importBatchNo: number;
  isDisplay: string;
  nullable: string;
  pointClass: string;
  propTypeId: string;
  remotionType: string;
  sysCateId: string;
}

export interface PointInfo {
  pointId: number;
  pointName: string; // 标题
  pointStatus: string; // 状态
  objId: string; // 与电站 areaId关联  objCode
  updateDate: string;
  pointCategory: PointCategory;
  value?: PointValue;
}

export interface PointValue {
  _id: number;
  pid: number;
  v: number;
  dt: number;
  t: number;
  q: number;
  displayValue: string;
}

export interface PointValueWithOldValue extends PointValue {
  oldValue: PointValue;
}

export interface PointRequest {
  pointIds?: number[] | string[];
  objType?: ObjType;
  objIds?: string[];
  cateIds?: string[];
  pointName?: string;
  dataPeriod?: string;
  isDisplay?: string;
  getCurrValue?: boolean;
  getHisValues?: boolean;
  beginTime?: Date | string;
  endTime?: Date | string;
  sort?: string;
  fill?: string; // 数据填充方式
  round?: boolean; // 是否把数据时间按5分钟规整

}

export interface PointResponse {
  calcFormula: string;
  calcPeriod: string;
  cateId: string;
  currValue: PointValueWithOldValue;
  dataPeriod: string;
  historyValues: PointValueWithOldValue[];
  importBatchNo: number;
  isDisplay: string;
  objId: string;
  objType: string;
  pointCategory: PointCategory;
  pointId: number;
  pointName: string;
}

/**
 * 数据填充方式：
 * none：不填充，按实际数据返回
 * full：对于实时数据和小时数据，从00:00:00到23:59:59，按savePeriod填充null对象
 *       对于日数据，从1号到月底，按日填充
 *       对于月数据，从1月到12月，按月填充
 * dynamic：对于实时数据和小时数据，从第一个实际数据到最后一个数据，按savePeriod填充null对象
 *          如果最后一个数据时间，小于24:00-第一个数据时间，则按24:00-第一个数据时间填充
 *          对于日数据，从1号到月底，按日填充
 *          对于月数据，从1月到12月，按月填充
 */
export enum FillStrategy {
  full = 'full',
  dynamic = 'dynamic',
  none = 'none'
}
