import * as moment from 'moment';
import * as _ from 'lodash';

export function buildStartTimeRankingChart(returnValue: any) {
    let option = {
        color: ['rgba(0,0,0,0)', '#37A2DA', '#FF9F7F', '#FF9F7F'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
            left: '5%',
            width: '90%',
            top: '2%',
            height: '96%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (param) {
                var start = null,
                    end = null,
                    duration = null;
                start = new Date(returnValue.dateBegin + param[0].value);
                end = new Date(
                    returnValue.dateBegin + param[0].value + param[1].value
                );
                var tip =
                    param[0].name +
                    '</br>开机时间：' +
                    (param[0].value ? moment(start).format('hh:mm:ss') : '') +
                    '</br>停机时间：' +
                    (returnValue.stopFlag[param[0].dataIndex]
                        ? moment(end).format('hh:mm:ss')
                        : '');
                return tip;
            }
        },
        xAxis: {
            show: false,
            type: 'value'
        },
        yAxis: [
            {
                type: 'category',
                data: returnValue.option.yAxis.data,
                splitLine: { show: false },
                axisTick: { show: false }
            }
        ],
        series: [
            {
                stack: '1',
                data: returnValue.option.series[0].data,
                name: 'start',
                type: 'bar',
                barWidth: 12
            },
            {
                stack: '1',
                data: returnValue.option.series[0].data,
                name: '运行时段',
                type: 'bar',
                barWidth: 12
            }
        ]
    };

    return option;
}
