import { CacheService } from 'src/app/common/services/cache.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildTotalAlarmCountChart } from './chart/total-alarm-count.chart';
import { buildAlarmDistributionChart } from './chart/alarm-distribution.chart';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { StationMonitorService } from '../../station-monitor.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Constants } from 'src/app/common/Constants';

@Component({
  selector: 'app-real-time-alarm-card',
  templateUrl: './real-time-alarm-card.component.html',
  styleUrls: [
    '../overview.component.less',
    './real-time-alarm-card.component.less',
  ],
})
export class RealTimeAlarmCardComponent implements OnInit, OnDestroy {
  //告警总数 图表option
  public totalAlarmCountOption: any = null;
  //实时告警 图表option
  public alarmDistributionOption: any = null;
  //实时告警 tab
  public alarmDistributionType: string = 'hour';

  public total_alarm_count_block = true;
  public alarm_distribution_block = true;
  public canlendarLocale: any;
  public alarmLevel: { value: number; name: string }[] = [];
  public date: string = moment().format('YYYY-MM-DD');
  private alarm1Interval: any = null;
  private alarm1IntervalTime: number = 1;
  private alarm2Interval: any = null;
  private alarm2IntervalTime: number = 1;

  staParams: any = {};
  alarm2Params: any;
  stationId: any;
  alarm1loading: boolean = true;
  alarm2loading: boolean = true;

  constructor(
    private calendarLocales: CalendarLocales,
    private stationmonitorservice: StationMonitorService,
    private router: Router,
    private route: ActivatedRoute,
    private cacheService: CacheService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
        this.initPage({ stationId });
      } else {
        this.stationId = params.stationId;
        this.initPage({ stationId: params.stationId });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    clearInterval(this.alarm1Interval);
    this.alarm1Interval = null;
    clearInterval(this.alarm2Interval);
    this.alarm2Interval = null;
  }

  /**
   * initPage
   */
  public initPage(params?) {
    if (this.alarm1Interval) {
      clearInterval(this.alarm1Interval);
      this.alarm1Interval = null;
    }
    if (this.alarm2Interval) {
      clearInterval(this.alarm2Interval);
      this.alarm2Interval = null;
    }
    this.getAlarm1(params);
    this.alarm1Interval = setInterval(() => {
      this.getAlarm1(params);
    }, 1000 * 60 * this.alarm1IntervalTime);

    this.staParams = params;

    this.alarm2Params = _.merge({}, this.staParams, {
      dataType: this.alarmDistributionType,
      date:
        this.alarmDistributionType == 'hour' ? this.date : this.date + '-01',
    });
    this.getAlarm2(this.alarm2Params);
    this.alarm2Interval = setInterval(() => {
      this.getAlarm2(this.alarm2Params);
    }, 1000 * 60 * this.alarm2IntervalTime);
  }

  /**
   * getAlarm1
   */
  public getAlarm1(params) {
    this.alarm1loading = true;
    this.stationmonitorservice.alarm1(params).then((res: any) => {
      this.alarm1loading = false;
      let data = res;
      this.totalAlarmCountOption = buildTotalAlarmCountChart(data.alarmType);
      this.alarmLevel = data.alarmLevel;
    });
  }

  /**
   * getAlarm2
   */
  public getAlarm2(params) {
    this.alarm2loading = true;
    this.stationmonitorservice.alarm2(params).then((res: any) => {
      this.alarm2loading = false;
      let data = res;
      this.alarmDistributionOption = buildAlarmDistributionChart(data);
    });
  }

  /**
   * selectDistribution
   */
  public selectDistribution(type, date?) {
    this.alarmDistributionType = type;
    this.date =
      type == 'hour'
        ? moment().format('YYYY-MM-DD')
        : moment().format('YYYY-MM');
    if (date) {
      this.date = date;
    }
    this.alarm2Params = _.merge({}, this.staParams, {
      dataType: this.alarmDistributionType,
      date:
        this.alarmDistributionType == 'hour' ? this.date : this.date + '-01',
    });
    this.getAlarm2(this.alarm2Params);
  }

  public dateSelect(event, type) {
    let date =
      type == 'hour'
        ? moment(event).format('YYYY-MM-DD')
        : moment(event).format('YYYY-MM');
    this.selectDistribution(type, date);
  }

  /**
   * goEnergy
   */
  public goAlarm(type?) {
    let params = {};
    if (type == 'date') {
      params = {
        dateType: this.alarmDistributionType,
        date: this.date,
      };
    }
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/monitorStation/overview'],
      })
    );
    this.router.navigate(['/alarmManage/realTime'], {
      queryParams: {
        stationId: this.stationId,
        ...params,
      },
      queryParamsHandling: 'merge',
    });
  }

  public goAlarm1(event) {
    const params = {
      alarmTypeIndex: event.data[1],
      dateType: this.alarmDistributionType,
      date: this.date,
    };
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/monitorStation/overview'],
      })
    );
    this.router.navigate(['/alarmManage/realTime'], {
      queryParams: {
        stationId: this.stationId,
        ...params,
      },
      queryParamsHandling: 'merge',
    });
  }
}
