import { Injectable } from '@angular/core';

/** 保存全局配置的服务 */
@Injectable()
export class AppConfigService {
  /** Api的基础地址 */
  public apiUrl: string;
  /** Api的基础地址 */
  public projectName: string;

  /** 推送服务的基础地址 */
  public messagingUrl: string;

  /** 文件基础地址 */
  public fileEndpointUrl: string;

  /** 当前使用的语言 */
  public language: string;

  private appConfig: any;

  /** 当前使用的主题 */
  private theme: string;

  /** 当前租户登录配置化页面 */
  public login: any;

  constructor() {
    let appConfig = window['appConfig'] || {};

    if (appConfig.apiMap) {
      this.apiUrl = appConfig.apiMap[location.origin];
    }

    if (!this.apiUrl) {
      this.apiUrl =
        typeof appConfig.apiUrl === 'number'
          ? location.protocol +
            '//' +
            location.hostname +
            (appConfig.apiUrl === 80 ? '' : ':' + appConfig.apiUrl)
          : appConfig.apiUrl;
    }

    this.messagingUrl =
      appConfig.messagingUrl || location.protocol + '//' + location.host;
    this.language = appConfig.language || 'zh_CN';
    this.theme = appConfig.theme || 'light';
    this.login = appConfig.login;
    this.projectName = appConfig.projectName || null;
    this.appConfig = appConfig;
    this.fileEndpointUrl =
      appConfig.fileEndpoint || location.protocol + '//' + location.host;
  }

  public getAppConfig(): any {
    return this.appConfig;
  }
}
