import BaseEvent from './BaseEvent';
declare const Cesium: any;
class CesiumPopup extends BaseEvent {
  className = '';
  title = '';
  offset = [0, 0];
  _viewer = null;
  _closeBtn = null;
  position = null;
  _panelContainer = null;
  _renderListener = null;
  _isRender = false;
  _isClose = false;
  headerTitle = null;
  _contentContainer = null;
  content = null;

  constructor(options) {
    super();
    this.className = options.className || '';
    this.title = options.title || '';
    this.offset = options.offset || [0, 0];

    this.closeHander = this.closeHander.bind(this);
    this.clickHander = this.clickHander.bind(this);
  }

  addTo(viewer) {
    if (this._viewer) this.remove();
    this._viewer = viewer;
    this.initPanle();
    this._closeBtn.addEventListener('click', this.closeHander, false);

    if (this.position) {
      this._panelContainer.style.display = 'block';
      this._renderListener = this._viewer.scene.postRender.addEventListener(
        this.render.bind(this),
        this
      );
    }

    this._panelContainer.addEventListener('click', this.clickHander, false);

    return this;
  }

  initPanle() {
    const closeBtnIcon = `
            <svg t="1603334792546" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1328" width="32" height="32">
                <path d="M568.922 508.232L868.29 208.807a39.139 39.139 0 0 0 0-55.145l-1.64-1.64a39.139 39.139 0 0 0-55.09 0l-299.367 299.82-299.425-299.934a39.139 39.139 0 0 0-55.088 0l-1.697 1.64a38.46 38.46 0 0 0 0 55.09l299.48 299.594-299.424 299.48a39.139 39.139 0 0 0 0 55.09l1.64 1.696a39.139 39.139 0 0 0 55.09 0l299.424-299.48L811.56 864.441a39.139 39.139 0 0 0 55.089 0l1.696-1.64a39.139 39.139 0 0 0 0-55.09l-299.48-299.537z" p-id="1329"></path>
            </svg>`;

    this._panelContainer = document.createElement('div');
    this._panelContainer.classList.add('cesium-popup-panel');
    if (this.className) {
      this._panelContainer.classList.add(this.className);
    }

    this._closeBtn = document.createElement('div');
    this._closeBtn.classList.add('cesium-popup-close-btn');
    this._closeBtn.innerHTML = closeBtnIcon;

    const headerContainer = document.createElement('div');
    headerContainer.classList.add('cesium-popup-header-panel');

    this.headerTitle = document.createElement('div');
    this.headerTitle.classList.add('cesium-poput-header-title');
    this.headerTitle.innerHTML = this.title;

    headerContainer.appendChild(this.headerTitle);
    this._panelContainer.appendChild(this._closeBtn);
    this._panelContainer.appendChild(headerContainer);

    this._contentContainer = document.createElement('div');
    this._contentContainer.classList.add('cesium-popup-content-panel');
    this._contentContainer.innerHTML = this.content;

    this._panelContainer.appendChild(this._contentContainer);

    const tipContaienr = document.createElement('div');
    tipContaienr.classList.add('cesium-popup-tip-panel');

    const tipDiv = document.createElement('div');
    tipDiv.classList.add('cesium-popup-tip-bottom');

    tipContaienr.appendChild(tipDiv);
    this._panelContainer.appendChild(tipContaienr);

    this._panelContainer.style.display = 'none';
    this._viewer.cesiumWidget.container.appendChild(this._panelContainer);
    this.emit('open');
  }

  setHTML(html) {
    if (this._contentContainer) {
      this._contentContainer.innerHTML = html;
    }
    this.content = html;
    return this;
  }

  render() {
    const geometry = this.position;
    if (!geometry) return;
    const position = Cesium.SceneTransforms.wgs84ToWindowCoordinates(
      this._viewer.scene,
      geometry
    );
    if (!position) return;
    if (this._panelContainer) {
      this._panelContainer.style.left = `${
        position.x - this._panelContainer.offsetWidth / 2 + this.offset[0]
      }px`;
      this._panelContainer.style.top = `${
        position.y - this._panelContainer.offsetHeight - 10 + this.offset[1]
      }px`;
    }
  }

  setPosition(cartesian3) {
    this.position = cartesian3;
    return this;
  }

  addClassName(className) {
    if (this._panelContainer) {
      this._panelContainer.classList.add(className);
    }
    return this;
  }

  removeClass(className) {
    if (this._panelContainer) {
      this._panelContainer.classList.remove(className);
    }
    return this;
  }

  setTitle(title) {
    this.headerTitle.innerHTML = title;
    return this;
  }

  setOffset(offset) {
    this.offset = offset;
    return this;
  }

  closeHander(event) {
    event.stopPropagation();
    this.remove();
  }
  clickHander() {
    this.emit('click');
  }

  remove() {
    this._closeBtn.removeEventListener('click', this.closeHander, false);

    if (this._closeBtn) {
      this._closeBtn.parentNode.removeChild(this._closeBtn);
      this._closeBtn = null;
    }

    if (this._contentContainer) {
      this._contentContainer.parentNode.removeChild(this._contentContainer);
      this._contentContainer = null;
    }

    if (this._panelContainer) {
      this._panelContainer.parentNode.removeChild(this._panelContainer);
      this._panelContainer = null;
    }

    if (this._renderListener) {
      this._renderListener();
      this._renderListener = null;
    }

    if (this._viewer) {
      this._viewer = null;
    }
    this.emit('close');
  }
}

export default CesiumPopup;
