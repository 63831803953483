import { StationMonitorService } from './../../station-monitor/station-monitor.service';
import { Router } from '@angular/router';
import { CacheService } from './../../../common/services/cache.service';
import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { MenuItem } from 'primeng/primeng';
import { AppConfigService } from './../../../app.config';
import { AuthHttp } from './../../../core/auth-http';
import { Constants } from 'src/app/common/Constants';
import { Location } from '@angular/common';
import * as _ from 'lodash';

@Injectable()
export class BreadcrumbService {
  private itemsSource = new Subject<MenuItem[]>();
  itemsHandler = this.itemsSource.asObservable();

  private stationTreeSource = new Subject<MenuItem[]>();
  stationTreeHandler = this.stationTreeSource.asObservable();

  private configSource = new Subject<any>();
  configHandler = this.configSource.asObservable();

  private favoriteSource = new Subject<boolean>();
  favoriteHandler = this.favoriteSource.asObservable();

  private reloadFavoriteSource = new Subject<boolean>();
  reloadFavoriteHandler = this.reloadFavoriteSource.asObservable();

  private homeSource = new Subject<boolean>();
  homeHandler = this.homeSource.asObservable();

  private overviewDialogSource = new Subject<boolean>();
  overviewDialogHandler = this.overviewDialogSource.asObservable();

  private powerDialogSource = new Subject<boolean>();
  powerDialogHandler = this.powerDialogSource.asObservable();

  private energyDialogSource = new Subject<boolean>();
  energyDialogHandler = this.energyDialogSource.asObservable();

  private gisMonitorDialogSource = new Subject<boolean>();
  gisMonitorDialogHandler = this.gisMonitorDialogSource.asObservable();

  private stationSource = new Subject();
  stationSourceHandler = this.stationSource.asObservable();

  private recentStations = [];
  breadcrumbItem: MenuItem[];

  constructor(
    private appConfig: AppConfigService,
    private http: AuthHttp,
    private cacheService: CacheService,
    private location: Location,
    private router: Router,
    private stationmonitorservice: StationMonitorService
  ) {}

  setItems(items: MenuItem[]) {
    if (items.length > 0) {
      this.breadcrumbItem = items;
      this.itemsSource.next(items);
    }
  }

  getItems() {
    return this.breadcrumbItem;
  }

  setStationTree(data) {
    this.stationTreeSource.next(data);
  }

  setStationSource(data) {
    this.stationSource.next(data);
  }

  setRecentStations(recentStations = []) {
    this.recentStations = recentStations;
  }

  setStation(
    station: { stationId: any; shortName: any; stationName?: any },
    route?: any,
    queryParams?: any
  ) {
    this.setStationSource(station);

    let index = _.findIndex(this.recentStations, (o: any) => {
      return o.stationId == station.stationId;
    });
    if (index == -1) {
      this.recentStations.splice(5, 1);
    } else {
      this.recentStations.splice(index, 1);
    }

    this.recentStations.unshift(station);
    this.cacheService.setCache(
      Constants.KEY_CURRENT_STATION_ID,
      station.stationId,
      true
    );
    this.cacheService.setCache(
      Constants.KEY_CURRENT_STATION_NAME,
      station.shortName || station.stationName,
      true
    );
    if (route) {
      if (queryParams) {
        this.router.navigate([route], {
          queryParams,
          queryParamsHandling: 'merge',
        });
      } else {
        this.router.navigate([route], {
          queryParams: {
            stationId: station.stationId,
          },
          queryParamsHandling: 'merge',
        });
      }
    } else {
      let params = this.splitUrl();
      this.router.navigate([params.route], {
        queryParams: {
          stationId: station.stationId,
        },
        queryParamsHandling: 'merge',
      });
    }
  }

  setConfig(config) {
    this.configSource.next(config);
  }

  setFavoriteIcon(flag) {
    this.favoriteSource.next(flag);
  }

  reloadFavorite() {
    this.reloadFavoriteSource.next(true);
  }

  setHome(url) {
    this.homeSource.next(url);
  }

  setFavorites(params) {
    return this.http.post(`${this.appConfig.apiUrl}/api/menu/favorites`, '', {
      params,
    });
  }

  removeFavorites(params) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/menu/favorites`, {
      params,
    });
  }

  setHomepage(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/menu/homePage`, '', {
      params,
    });
  }

  /**
   * 监盘中心 配置按钮
   * toggleOverviewDialog
   */
  public toggleOverviewDialog(flag) {
    this.overviewDialogSource.next(flag);
  }
  /**
   * 监盘中心 功率 配置按钮
   * togglePowerDialog
   */
  public togglePowerDialog(flag) {
    this.powerDialogSource.next(flag);
  }
  /**
   * 监盘中心 电量 配置按钮
   * toggleEnergyDialog
   */
  public toggleEnergyDialog(flag) {
    this.energyDialogSource.next(flag);
  }

  /**
   * 监盘中心 电站分布
   * toggleOverviewDialog
   */
  public toggleGisMonitorDialog(flag) {
    this.gisMonitorDialogSource.next(flag);
  }

  /**
   * 截取url中route和params
   */
  private splitUrl(): any {
    let url = this.location.path();
    let urlParams = {};
    if (url.indexOf('?') != -1) {
      urlParams = { route: url.split('?')[0], params: url.split('?')[1] };
    } else {
      urlParams = { route: url };
    }
    return urlParams;
  }
}
