import { Material } from './material.model';

// 库存
export interface Inventory {
  companyName: string;
  inventoryCate: string;
  inventoryCateText: string;
  inventoryId: number;
  inventoryNum: number;
  isDelete: string;
  materialDesc: string;
  materialId: number;
  materialName: string;
  materialNo: string;
  materialSpec: string;
  safetyInventory: number;
  tenantId: string;
  unit: string;
  updater: string;
  warehouseId: string;
  warehouseName: string;
}

// 出入库
export interface InOutStock {
  createTime?: number;
  creater?: string;
  createrName?: string;
  customerId?: string;
  inventoryId?: string;
  ioCode?: string;
  ioDesc?: string;
  ioManager?: string;
  ioManagerName?: string;
  approvalTime: string;
  ioOperator?: string;
  ioOperatorName?: string;
  ioStatus?: string;
  ioStatusText?: string;
  ioSubType?: string;
  ioSubTypeText?: string;
  ioTime?: number;
  ioType?: string;
  ioTypeText?: string;
  iostorageId?: number;
  planTime?: number;
  relaBusinessId?: string;
  relaBusinessIdName?: string;
  relaBusinessType?: string;
  relaBusinessTypeText?: string;
  updateTime?: number;
  updater?: string;
  warehouseId?: string;
  warehouseName?: string;
  iostorgeDetailDtos?: Material[];
  materialIostorgeDetails?: Material[];
}

export enum IostorageStatus {
  plan = '01', // 计划
  completed = '02', // 已完成
  refuse = '03', // 拒绝
}

export enum IoType {
  out = '01', // 出库
  into = '02', // 入库
}

export enum DateChooseType {
  ALL,
  DAY,
  WEEK,
  MONTH,
  YEAR,
  OTHER,
}
