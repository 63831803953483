import { Injectable } from "@angular/core";
import { TipService } from "@common/services/tip.service";
import { Observable, Subject } from "rxjs";
import { CommonService } from "../common/services/common.service";

export interface ToastEvent {
  message: string;
  level: string;
}

export interface Toast {
  message: string;
  level: string;
  styles: any[];
}

@Injectable()
export class ToastService {
  constructor(private commonService: CommonService, private tipservice: TipService) { }

  private toastEvents: Subject<ToastEvent> = new Subject<Toast>();

  get events(): Observable<ToastEvent> {
    return this.toastEvents.asObservable();
  }

  success(message: string) {
    this.publish({ message: message, level: "success" });
  }

  warning(message: string) {
    this.publish({ message: message, level: "warn" });
  }

  error(message: string) {
    this.publish({ message: message, level: "error" });
  }

  private publish(toast: ToastEvent) {
    // TODO liqian 没有引入cie-base的toast完整代码, 暂时用tip来代替
    // this.toastEvents.next(toast);
    this.tipservice.tip(toast.level, toast.message);
  }
}
