import { WidgetsService } from './../widgets.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { OnDestroy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { buildElectricityDayChart } from './chart/electricity-day.chart';
import { buildElectricityMonthChart } from './chart/electricity-month.chart';
import { buildElectricityYearChart } from './chart/electricity-year.chart';


@Component({
  selector: 'app-equivalent-hours',
  templateUrl: './equivalent-hours.component.html',
  styleUrls: ['./equivalent-hours.component.less']
})
export class EquivalentHoursComponent implements OnInit, OnDestroy {

  electricityType: string = 'electricityDay';
  public date: string = moment().format('YYYY-MM-DD');
  public dataType: any = 'day';
  common_params: { dataType: string; date?: string; };
  interval: any;
  sta_params: {};
  intervalTime: number = 5;
  canlendarLocale: any;
  option: any;
  loading: boolean;

  constructor(private widgetsservice: WidgetsService, private calendarLocales: CalendarLocales) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
  }

  ngOnInit() {
    this.initPage()
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
    this.interval = null;
  }

  /**
 * initPage
 */
  public initPage() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.sta_params = {};
    if (_.isEmpty(this.common_params)) {
      this.common_params = { dataType: this.dataType, date: this.date };
    } else {
      this.common_params = _.cloneDeep(this.common_params);
    }

    this.getData(_.merge({}, this.common_params, this.sta_params));
    this.interval = setInterval(() => {
      this.getData(_.merge({}, this.common_params, this.sta_params));
    }, 1000 * 60 * this.intervalTime);
  }

  dateSelect(event) {
    this.selectElectricity(this.electricityType, event);
  }

  /**
 * selectElectricityPower
 */
  public selectElectricity(type, date?) {
    this.electricityType = type;
    switch (type) {
      case 'electricityDay':
        if (date) {
          this.date = moment(date).format('YYYY-MM-DD');
        } else {
          this.date = moment().format('YYYY-MM-DD');
        }
        this.common_params = { dataType: 'day', date: this.date };
        this.getData(_.merge({}, this.common_params));
        break;
      case 'electricityMonth':
        if (date) {
          this.date = moment(date).format('YYYY-MM');
        } else {
          this.date = moment().format('YYYY-MM');
        }
        this.common_params = {
          dataType: 'month',
          date: this.date + '-01',
        };
        this.getData(_.merge({}, this.common_params));
        break;
      case 'electricityYear':
        this.common_params = { dataType: 'year' };
        this.getData(_.merge({}, this.common_params));
        break;
      default:
        break;
    }
  }

  /**
* getData
*/
  public getData(params) {
    this.loading = true;
    this.widgetsservice.kwhkwp(params).then((res: any) => {
      let data = res;
      if (params.dataType == 'day') {
        this.option = buildElectricityDayChart(data);
      } else if (params.dataType == 'month') {
        this.option = buildElectricityMonthChart(data);
      } else if (params.dataType == 'year') {
        this.option = buildElectricityYearChart(data);
      }
      this.loading = false;
    });
  }

}
