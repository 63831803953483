import { Injectable } from '@angular/core';
import * as echarts from 'echarts/lib/echarts';
import * as _ from 'lodash';

import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { CommonService } from '../../../common/services/common.service';
import {
  Device,
  DevicePoint,
  CombinerBoxDataRequest,
  InvertersDataRequest,
  StationDevicesRequest,
  TransformerDataRequest,
} from './index.model';
import { RankingRequest } from './index.model';

@Injectable()
export class DeviceService {
  private apiUrl: string = this.appConfig.apiUrl;
  private assetlistUrl = `${this.apiUrl}/api/assetlist`;
  deviceDetailPermissions: any;
  constructor(
    private http: AuthHttp,
    private common: CommonService,
    private appConfig: AppConfigService
  ) {}

  //获取运行状态
  public deviceRunStatus() {
    return this.http.get(`${this.apiUrl}/api/prop/propLists`, {
      search: { typeId: 'deviceRunStatus' },
    });
  }

  // 获取电站区域
  public getStationArea(stationId: string) {
    return this.http.get(`${this.apiUrl}/api/stationAreas/all`, {
      search: { stationId },
    });
  }

  // 设备类型
  public getDeviceType(stationId: string, locationIds: string) {
    return this.http
      .get(`${this.assetlistUrl}/queryAssetsSelect`, {
        search: this.common.filterEffectParam({
          stationId,
          locationIds,
        }),
      })
      .toPromise();
  }

  // 设备列表
  public getDeviceList(param, page, size) {
    return this.http.get(`${this.assetlistUrl}/devices`, {
      search: _.assign(this.common.filterEffectParam(param), {
        page,
        size,
      }),
    });
  }

  public getDevicePoint(idxCodelist) {
    // 测点数据过滤
    idxCodelist = _.filter(idxCodelist, (cateId: DevicePoint) => {
      return cateId.displayOrder2 > 0;
    });
    // 排序
    idxCodelist = _.sortBy(idxCodelist, (cateId: DevicePoint) => {
      return cateId.displayOrder2;
    });

    idxCodelist = _.take(idxCodelist, 2);
    for (let cateId of idxCodelist) {
      if (cateId.value) {
        cateId.value = parseFloat(
          cateId.value.toFixed(cateId.displayPrecision ? cateId.displayPrecision : 2)
        );
      }
    }
    return idxCodelist;
  }
  /**
   * 设备排名
   * @param param
   */
  public getRanking(param: RankingRequest) {
    return this.http.get(`${this.apiUrl}/api/device/ranking`, {
      search: param,
    });
  }

  /**
   * 获取汇流箱实时数据
   */
  public getCombinerBoxData(param: CombinerBoxDataRequest) {
    return this.http.get(`${this.apiUrl}/api/device/combinerBoxes`, {
      search: param,
    });
  }

  /**
   * 获取逆变器实时数据
   */
  public getInverterData(param: InvertersDataRequest) {
    return this.http.get(`${this.apiUrl}/api/device/inverters`, {
      search: param,
    });
  }

  /**
   * 获取逆变器实时数据
   * @param param
   */
  public getTransformerData(param: TransformerDataRequest) {
    return this.http.get(`${this.apiUrl}/api/device/transformers`, {
      search: param,
    });
  }

  /**
   * 获取电站设备类别分组情况
   */
  public getDeviceGroup(stationId: string) {
    return this.http.get(`${this.apiUrl}/api/device/deviceTypes`, {
      search: { stationId },
    });
  }

  /**
   * 获取电站设备列表
   */
  public getStationDevices(param: StationDevicesRequest) {
    return this.http.get(`${this.apiUrl}/api/device/deviceList`, {
      search: param,
    });
  }
  /**
   * 获取电站设备列表
   */
  public getDevicesPermissions() {
    return this.http
      .get(`${this.apiUrl}/api/device/permissions`)
      .toPromise()
      .then((res) => {
        this.deviceDetailPermissions = res;
      });
  }
}
