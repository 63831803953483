import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { FileService } from '../../services/file.service';
import * as _ from 'lodash';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-upload-docs',
  templateUrl: './upload-docs.component.html',
  styleUrls: ['./upload-docs.component.less'],
})
export class UploadDocsComponent implements OnInit {
  @Input() orderId = null;
  @Input() docPermission = 2;

  @Input() files = [];

  @Output() filesChangeEvent = new EventEmitter();
  constructor(private tipservice: TipService, private fileservice: FileService) { }

  ngOnInit() { }
  onSelectFile(event) {
    for (const file of event.files) {
      this.fileservice
        .uploadDocs('/api/workOrders/uploadDocs', file, { orderId: this.orderId })
        .then((res: any) => {
          if (res) {
            this.tipservice.tip('success', '上传成功');
            this.files.push(res);
            this.filesChangeEvent.emit(this.files);
          }
        });
    }
  }

  deleteItem(event, docId) {
    this.fileservice.deleteFile(docId).then((res) => {
      this.tipservice.tip('success', '删除成功');
      _.remove(this.files, (o) => {
        return o.docId === docId;
      });
      this.filesChangeEvent.emit(this.files);
    });
  }

  download(docId) {
    this.fileservice.exportFile(docId);
  }
}
