import { Location } from '@angular/common';
import { AfterViewInit, Component, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { ScrollPanel } from 'primeng/primeng';
import { filter } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app.config';
import { Constants } from 'src/app/common/Constants';
import { CacheService } from 'src/app/common/services/cache.service';
import { BreadcrumbService } from 'src/app/components/layout/breakcrumb/breadcrumb.service';
import { NavService } from 'src/app/components/layout/nav/nav.service';

declare const $: any;

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  styleUrls: ['./full-layout.component.less'],
})
export class FullLayoutComponent implements AfterViewInit {
  layoutMode = 'static';

  darkMenu = true;

  profileMode = 'popup';

  rotateMenuButton: boolean;

  topbarMenuActive: boolean;

  overlayMenuActive: boolean;

  staticMenuDesktopInactive: boolean;

  staticMenuMobileActive: boolean;

  layoutMenuScroller: HTMLDivElement;

  menuClick: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: any;

  resetMenu: boolean;

  menuHoverActive: boolean;

  grouped = false;

  @ViewChild('layoutMenuScroller', { static: true })
  layoutMenuScrollerViewChild: ScrollPanel;
  menus: any = [];
  logo: any = {
    logo1: '',
    logo2: '',
    logoTitle: '',
    logoAll: '',
    backgroundColor: '#42A5F5',
    marquee: null,
  };
  homePageUrl: any;
  favorite: any = [];
  activeUrl: string;
  orginalMenu: any;
  contentHeight: string;
  title: any;

  stationName = '';
  defaultStationId = '';

  constructor(
    public renderer: Renderer2,
    private navService: NavService,
    private cacheService: CacheService,
    private router: Router,
    private breadcrumbService: BreadcrumbService,
    private appConfig: AppConfigService,
    private titleservice: Title,
    private location: Location
  ) {
    // 获取本地页面配置
    const defaultStyle = JSON.parse(localStorage.getItem('style'));
    if (defaultStyle) {
      this.layoutMode = defaultStyle.layout;
      this.darkMenu = defaultStyle.color != 'light' ? true : false;
      this.grouped = defaultStyle.grouped;
    }

    // 路由监听设置 面包屑
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // example: NavigationStart, RoutesRecognized, NavigationEnd
        this.activeUrl = event.url;
        $('.layout-content').scrollTop(0);
        if (this.cacheService.getCache(Constants.KEY_CURRENT_STATION_NAME)) {
          this.stationName = this.cacheService.getCache(
            Constants.KEY_CURRENT_STATION_NAME
          );
        }
        if (this.menus.length != 0) {
          this.breadcrumbService.setItems(
            this.getBreadcrumbItems(this.orginalMenu, this.menus)
          );
        } else {
          let breadcrumbItems = [];
          if (this.activeUrl.indexOf('/ems/map') !== -1) {
            breadcrumbItems = [
              { routerLink: '', label: '储能监视' },
              { routerLink: '', label: '站点分布' },
            ];
          }
          if (this.activeUrl.indexOf('/ems/monitor/station') !== -1) {
            breadcrumbItems = [
              { routerLink: '', label: '储能监视' },
              { routerLink: '', label: '站点概览' },
            ];
          }
          if (this.activeUrl.indexOf('/ems/monitor/cluster') !== -1) {
            breadcrumbItems = [
              { routerLink: '', label: '储能监视' },
              { routerLink: '', label: '设备监视' },
            ];
          }

          this.breadcrumbService.setItems(breadcrumbItems);
        }
        if (this.favorite.length != 0) {
          this.breadcrumbService.setFavoriteIcon(this.favoritesActive());
        }
      });

    // 获取init接口
    this.navService.getInit().subscribe((res: any) => {
      localStorage.setItem('websocket', 'false');
      // 获取租户logo
      if (res.logo) {
        this.logo = res.logo;
        localStorage.setItem('logo', JSON.stringify(res.logo));
      }
      // 获取首页
      if (res.homePage) {
        this.homePageUrl = res.homePage.url;
      }
      // 获取默认电站
      if (this.getUrlParameters() && this.getUrlParameters().stationId) {
        this.defaultStationId = this.getUrlParameters().stationId;
      } else if (
        this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID) !=
        undefined
      ) {
        this.defaultStationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
      } else {
        this.cacheService.setCache(
          Constants.KEY_CURRENT_STATION_ID,
          res.defaultStationId,
          true
        );
        this.defaultStationId = res.defaultStationId;
      }

      if (res.stationTree) {
        // 获取电站树
        this.breadcrumbService.setStationTree({
          recentStations: res.recentStations,
          stationTree: this.getStationTree({
            stationTree: res.stationTree,
            stationId: this.defaultStationId,
          }),
        });
      }

      this.orginalMenu = res.menus;

      // 生成菜单
      const menus = res.menus.map((item) => {
        const exact = item.url ? this.setExactMenusItem(item.url) : false;
        return {
          label: item.menuLabel,
          icon: item.menuIcon2,
          routerLink: item.url ? [item.url] : '',
          menuId: item.menuId,
          parentId: item.parentId,
          routerLinkActiveOptions: { exact },
          items: item.children.length > 0 ? item.children : '',
          target: item.openType === '1' ? '_blank' : '_self',
          menuParam: item.menuParam,
        };
      });
      this.menus = this.treeData(menus, 'menuId', 'parentId', 'items');
      this.breadcrumbService.setItems(
        this.getBreadcrumbItems(this.orginalMenu, this.menus)
      );

      // 获取favorite
      if (res.favorite) {
        this.favorite = res.favorite;
        this.breadcrumbService.setFavoriteIcon(this.favoritesActive());
      }
    });

    this.navService.getPageStyle().subscribe((res: any) => {
      if (res) {
        this.changeStyle(res);
        localStorage.setItem('style', JSON.stringify(res));
      }
    });

    // 订阅 homepage 首页
    this.breadcrumbService.homeHandler.subscribe((res: any) => {
      if (res) {
        this.homePageUrl = res;
      }
    });

    // 订阅点击收藏按钮
    this.breadcrumbService.reloadFavoriteHandler.subscribe((res: any) => {
      this.refreshMenus();
    });

    const login = localStorage.getItem('pageConfigs')
      ? JSON.parse(localStorage.getItem('pageConfigs'))
      : this.appConfig.login;
    this.title = login.title;
    this.titleservice.setTitle(this.title);
  }

  public setExactMenusItem(url) {
    switch (url) {
      case '/base/users':
        return true;
      case '/ticket/operate':
        return true;
      case '/inspections':
        return true;
      case '/report/custom':
        return true;
      case '/sparePartsManage/safeinventoryreport':
        return true;
      case '/sparePartsManage/inoutinventoryrepot':
        return true;
      default:
        return false;
    }
  }

  public refreshMenus() {
    this.navService.getInit().subscribe((res: any) => {
      // 生成菜单
      const menus = res.menus.map((item) => {
        const exact = item.url ? this.setExactMenusItem(item.url) : false;
        return {
          label: item.menuLabel,
          icon: item.menuIcon2,
          routerLink: item.url ? [item.url] : '',
          menuId: item.menuId,
          parentId: item.parentId,
          routerLinkActiveOptions: { exact },
          items: item.children.length > 0 ? item.children : '',
          target: item.openType === '1' ? '_blank' : '_self',
          menuParam: item.menuParam,
        };
      });
      this.menus = this.treeData(menus, 'menuId', 'parentId', 'items');
      this.favorite = res.favorite;
    });
  }

  private getBreadcrumbItems(menus, json) {
    let breadcrumbItems = [];
    const ActiveMenu = this.getActiveMenu(menus, this.activeUrl);
    if (!ActiveMenu) {
      return breadcrumbItems;
    }
    const activeMenuId = ActiveMenu.menuId;
    const breadcrumbArray = this.getTreeList(json, activeMenuId);
    // 设备列表
    let deviceListFlag = false;
    let deviceItems = [];

    breadcrumbItems = breadcrumbArray.map((item) => {
      if (item.routerLink[0] == '/monitorStation/deviceList') {
        deviceListFlag = true;
        deviceItems = breadcrumbArray;
        deviceItems.splice(1, 0, {
          label: this.stationName,
          url: '/monitorStation/overview',
          routerLink: false,
          queryParams: item.menuParam,
        });
      }
      if (item.routerLink[0] == '/monitorStation/overview') {
        deviceListFlag = true;
        deviceItems = breadcrumbArray;
        deviceItems.splice(1, 0, {
          label: this.stationName,
          url: '/monitorStation/overview',
          routerLink: false,
          queryParams: item.menuParam,
        });
        // return {
        //   label: this.stationName,
        //   url: item.routerLink[0],
        //   routerLink: false,
        //   queryParams: item.menuParam,
        // };
      } else {
        return {
          label: item.label,
          url: item.routerLink[0],
          routerLink: item.routerLink,
          queryParams: item.menuParam,
        };
      }
    });
    if (deviceListFlag) {
      breadcrumbItems = deviceItems;
    }
    return breadcrumbItems;
  }

  /**
   * 通过当前url 查询原始menu数组，获取activeMenu对象
   * getActiveMenu
   */
  private getActiveMenu(menus, url) {
    if (url === '/') {
      url = localStorage.getItem('homepage');
    }
    return _.find(menus, (o) => {
      if (url.indexOf('?') >= 0) {
        if (o.menuParam) {
          if (o.url === '/monitorCenter/deviceStatus') {
            return (
              this.getUrlParameters().deviceType === o.menuParam.deviceType
            );
          }
          return (
            o.url === url.split('?')[0] &&
            _.isEqual(o.menuParam, this.getUrlParameters())
          );
        } else {
          if (
            o.url === '/sparePartsManage/safeinventoryreport' ||
            o.url === '/sparePartsManage/inoutinventoryrepot'
          ) {
            return o.url === url;
          }
          return o.url === url.split('?')[0];
        }
      } else {
        return o.url === url;
      }
      // if (url.indexOf('?') >= 0) {
      //   switch (url.split('?')[0]) {
      //     case '/documentManage':
      //       return o.url == url.split('?')[0] && _.isEqual(o.menuParam, this.getUrlParameters());
      //     case '/report/custom':
      //       return o.url == url.split('?')[0] && _.isEqual(o.menuParam, this.getUrlParameters());
      //     default:
      //       return o.url == url.split('?')[0];
      //   }
      // } else {
      //   return o.url == url;
      // }
    });
  }

  /**
   * 子节点menuId 反推 所有父节点menu数组对象
   * getTreeList
   */
  private getTreeList(json, id) {
    const temp = [];
    const forFn = function (arr, id) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.menuId === id) {
          temp.unshift(item);
          forFn(json, item.parentId);
          break;
        } else {
          if (item.items) {
            forFn(item.items, id);
          }
        }
      }
    };
    forFn(json, id);
    return temp;
  }

  public favoritesActive() {
    return (
      _.findIndex(this.favorite, (o: any) => {
        return o.url == this.splitUrl().route;
      }) != -1
    );
  }

  /**
   * 截取url中route和params
   */
  private splitUrl(): any {
    const url = this.location.path();
    let urlParams = {};
    if (url.indexOf('?') != -1) {
      urlParams = { route: url.split('?')[0], params: url.split('?')[1] };
    } else {
      urlParams = { route: url };
    }
    return urlParams;
  }

  private getUrlParameters(): any {
    const url = this.location.path();
    if (url.match(/([^?=&]+)(=([^&]*))/g)) {
      return url
        .match(/([^?=&]+)(=([^&]*))/g)
        .reduce(
          (a, v) => (
            (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
          ),
          {}
        );
    } else {
      return null;
    }
  }

  /**
   * name
   */
  private getStationTree(data) {
    let i = 0;
    const treeData = [];
    _.forEach(data.stationTree, (value, key) => {
      const citys = [];
      _.forEach(value, (v, k) => {
        const stations = [];
        v.forEach((station) => {
          stations.push({
            objId: station.stationId,
            objName: station.shortName,
            key: station.shortName,
            objType: '03',
          });
          if (station.stationId == data.stationId) {
            this.stationName = station.shortName;
            this.cacheService.setCache(
              Constants.KEY_CURRENT_STATION_NAME,
              this.stationName,
              true
            );
          }
        });
        citys.push({
          objId: k + i,
          objName: k,
          key: k,
          children: stations,
          objType: '02',
        });
      });
      treeData.push({
        objId: i,
        objName: key,
        key,
        children: citys,
        objType: '01',
      });
      i++;
    });
    return treeData;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.layoutMenuScrollerViewChild.moveBar();
      this.setContentHeight();
    }, 100);
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
  }

  onMenuButtonClick(event) {
    this.menuClick = true;
    this.rotateMenuButton = !this.rotateMenuButton;
    this.topbarMenuActive = false;

    if (this.layoutMode === 'overlay' && !this.isMobile()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }

    event.preventDefault();
  }

  changeStyle(style) {
    this.layoutMode = style.layout;
    this.grouped = style.grouped;
    this.darkMenu = style.color === 'dark' ? true : false;
    // style.theme = 'dark';

    // if (style.theme === 'light') {
    //   this.changeStyleSheetsColor(
    //     'theme-css',
    //     '/assets/layout/css/layout-blue.css'
    //   );
    //   this.changeStyleSheetsColor(
    //     'layout-css',
    //     '/assets/theme/blue/theme-accent.css'
    //   );
    // } else {
    //   this.changeStyleSheetsColor(
    //     'theme-css',
    //     '/assets/layout/css/layout-darktheme.css'
    //   );
    //   this.changeStyleSheetsColor(
    //     'layout-css',
    //     '/assets/theme/darktheme/theme-darktheme.css'
    //   );
    // }

    this.setContentHeight();
  }

  changeStyleSheetsColor(l, u) {
    const t = document.getElementById(l);
    this.replaceLink(t, u);
  }

  isIE() {
    return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent);
  }

  replaceLink(l, e) {
    if (this.isIE()) {
    } else {
      const n = l.getAttribute('id');
      const t = l.cloneNode(!0);
      t.setAttribute('href', e),
        t.setAttribute('id', n + '-clone'),
        l.parentNode.insertBefore(t, l.nextSibling),
        t.addEventListener('load', () => {
          l.remove(), t.setAttribute('id', n);
        });
    }
  }

  staticMode() {
    this.grouped = false;
    this.layoutMode = 'static';
  }

  overlayMode() {
    this.layoutMode = 'overlay';
  }

  slimMode() {
    this.grouped = true;
    this.layoutMode = 'slim';
  }

  horizontalMode() {
    this.grouped = true;
    this.layoutMode = 'horizontal';
  }

  groupedMode() {
    this.grouped = true;
  }

  ungroupedMode() {
    this.grouped = false;
  }

  onMenuClick($event) {
    this.menuClick = true;
    this.resetMenu = false;

    if (!this.isHorizontal()) {
      setTimeout(() => {
        this.layoutMenuScrollerViewChild.moveBar();
      }, 450);
    }
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;
    this.topbarMenuActive = !this.topbarMenuActive;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event) {
    event.preventDefault();
  }

  hideOverlayMenu() {
    this.rotateMenuButton = false;
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1024 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1024;
  }

  isMobile() {
    return window.innerWidth <= 640;
  }

  isOverlay() {
    return this.layoutMode === 'overlay';
  }

  isHorizontal() {
    return this.layoutMode === 'horizontal';
  }

  isSlim() {
    return this.layoutMode === 'slim';
  }

  isStatic() {
    return this.layoutMode === 'static';
  }

  private treeData(
    source: any,
    id: string,
    parentId: string,
    children: string
  ) {
    const cloneData = JSON.parse(JSON.stringify(source));
    return cloneData.filter((father) => {
      const branchArr = cloneData.filter(
        (child) => father[id] == child[parentId]
      );
      branchArr.length > 0 ? (father[children] = branchArr) : '';
      return father[parentId] == null;
    });
  }

  private setContentHeight() {
    this.contentHeight =
      document.body.clientHeight -
      65 -
      35 -
      (this.isHorizontal() ? 41 : 0) +
      'px';
  }
}
