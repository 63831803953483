import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { PointSelectorService } from './point-selector.service';
import * as _ from 'lodash';
import { ObjType } from '../relation-point-selector/point-selector.model';

@Component({
  selector: 'mpt-point-selector',
  templateUrl: 'point-selector.component.html'
})

export class PointSelectorComponent implements OnChanges {
  @Input() public ruleId: number = 0;
  @Input() public busType: string = 'alarmRule';
  @Input() public stationId: string;
  @Input() public isOpen: boolean = false;
  @Input() public isRadio: boolean = false;
  @Input() public objectOrder: string[] = ['s', 'a', 'd'];
  @Output() public openChange = new EventEmitter<boolean>();
  @Output() public submitDataEvent = new EventEmitter<any[]>();

  public objType = ObjType;
  public defaultParam = {
    objType: ObjType.device,
    busType: null
  };
  public param = {};

  constructor(
    private pointSelectorService: PointSelectorService
  ) {
    this.param = _.assign({}, {
      busId: this.ruleId,
      busType: this.busType,
      objType: ObjType.device,
    });
  }

  ngOnChanges(){
    this.param = _.assign({}, 
      this.param, 
    {
      stationId: this.stationId,
    });
  }

  // 取消
  public cancelData() {
    this.openChange.emit(false);
  }

  // 打开窗口
  public openEvent(event) {
    this.openChange.emit(event);
  }

  // 提交数据
  public submitData(event) {
    this.submitDataEvent.emit(event);
  }

  public onShow() {
    this.isOpen = true;
    this.openChange.emit(true);
    this.defaultParam = _.assign({}, this.defaultParam, {
      busType: this.busType,
    });
    this.param = _.assign({}, this.param, this.defaultParam);
  }

  public tabChanged(data) {
    switch (data.index) {
      case 0:
        this.defaultParam = _.assign({}, this.defaultParam, {
          objType: ObjType.device
        });
        break;
      case 1:
        this.defaultParam = _.assign({}, this.defaultParam, {
          objType: ObjType.station
        });
        break;
      case 2:
        this.defaultParam = _.assign({}, this.defaultParam, {
          objType: ObjType.area
        });
        break;
      default:
        break;
    }
    this.param = _.assign({}, this.param, this.defaultParam);
  }

}
