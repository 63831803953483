import { WidgetsService } from './../widgets.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { OnDestroy } from '@angular/core';
import { MonitorCenterService } from './../../../../components/monitor-center/monitor-center.service';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';
import * as moment from 'moment';
import { buildElectricityDayChart } from './chart/electricity-day.chart';
import { buildElectricityMonthChart } from './chart/electricity-month.chart';
import { buildElectricityYearChart } from './chart/electricity-year.chart';

@Component({
  selector: 'app-power-generation',
  templateUrl: './power-generation.component.html',
  styleUrls: ['./power-generation.component.less']
})
export class PowerGenerationComponent implements OnInit, OnDestroy {

  electricityType: string = 'electricityDay';
  public date: string = moment().format('YYYY-MM-DD');
  public dataType: any = 'day';
  common_params: { dataType: string; date?: string; };
  energyLoading: boolean;
  energyOption: any;
  electricityInterval: any;
  sta_params: {};
  intervalTime: number = 5;
  canlendarLocale: any;

  constructor(private widgetsservice: WidgetsService, private calendarLocales: CalendarLocales) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
  }

  ngOnInit() {
    this.initPage()
  }

  ngOnDestroy(): void {
    clearInterval(this.electricityInterval);
    this.electricityInterval = null;
  }

  /**
 * initPage
 */
  public initPage() {
    if (this.electricityInterval) {
      clearInterval(this.electricityInterval);
      this.electricityInterval = null;
    }
    this.sta_params = {};
    if (_.isEmpty(this.common_params)) {
      this.common_params = { dataType: this.dataType, date: this.date };
    } else {
      this.common_params = _.cloneDeep(this.common_params);
    }

    this.getEnergy(_.merge({}, this.common_params, this.sta_params));
    this.electricityInterval = setInterval(() => {
      this.getEnergy(_.merge({}, this.common_params, this.sta_params));
    }, 1000 * 60 * this.intervalTime);
  }

  dateSelect(event) {
    this.selectElectricity(this.electricityType, event);
  }

  /**
 * selectElectricityPower
 */
  public selectElectricity(type, date?) {
    this.electricityType = type;
    switch (type) {
      case 'electricityDay':
        if (date) {
          this.date = moment(date).format('YYYY-MM-DD');
        } else {
          this.date = moment().format('YYYY-MM-DD');
        }
        this.common_params = { dataType: 'day', date: this.date };
        this.getEnergy(_.merge({}, this.common_params));
        break;
      case 'electricityMonth':
        if (date) {
          this.date = moment(date).format('YYYY-MM');
        } else {
          this.date = moment().format('YYYY-MM');
        }
        this.common_params = {
          dataType: 'month',
          date: this.date + '-01',
        };
        this.getEnergy(_.merge({}, this.common_params));
        break;
      case 'electricityYear':
        this.common_params = { dataType: 'year' };
        this.getEnergy(_.merge({}, this.common_params));
        break;
      default:
        break;
    }
  }

  /**
* getEnergy
*/
  public getEnergy(params) {
    this.energyLoading = true;
    this.widgetsservice.energy(params).then((res: any) => {
      let data = res;
      if (params.dataType == 'day') {
        this.energyOption = buildElectricityDayChart(data);
      } else if (params.dataType == 'month') {
        this.energyOption = buildElectricityMonthChart(data);
      } else if (params.dataType == 'year') {
        this.energyOption = buildElectricityYearChart(data);
      }
      this.energyLoading = false;
    });
  }


}
