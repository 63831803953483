import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadDocsComponent } from './upload-docs.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { FileUploadModule } from 'primeng/primeng';
import { FileService } from '../../services/file.service';

@NgModule({
    declarations: [UploadDocsComponent],
    imports: [CommonModule, HttpClientModule, SharedModule, FileUploadModule],
    exports: [UploadDocsComponent],
    providers: [FileService],
})
export class UploadDocsModule {}
