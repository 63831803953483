import { Component, OnInit, AfterViewInit } from '@angular/core';
import { CalendarLocales } from '@shared/locales/calendar.locales';
import * as moment from 'moment';
import { buildPieChart, buildSinglePieChart } from './chart/pie.chart';
import { WindService } from '../wind.service';
import { ActivatedRoute } from '@angular/router';
import { Constants } from '@common/Constants';
import { CacheService } from '@common/services/cache.service';
import { StationMonitorService } from '@components/station-monitor/station-monitor.service';
import * as _ from 'lodash';
import { buildPowerChart } from '@components/station-monitor/overview/power-generation-card/chart/power.chart';
import { buildElectricityDayChart } from '@components/station-monitor/overview/power-generation-card/chart/electricity-day.chart';
import { buildElectricityMonthChart } from '@components/station-monitor/overview/power-generation-card/chart/electricity-month.chart';
import { buildElectricityYearChart } from '@components/station-monitor/overview/power-generation-card/chart/electricity-year.chart';

@Component({
  selector: 'app-wind-station-monitor',
  templateUrl: './station-monitor.component.html',
  styleUrls: ['./station-monitor.component.less'],
})
export class StationMonitorComponent implements OnInit, AfterViewInit {
  overviews = [];
  overviewsColor = [
    '#1976d2',
    '#0974b2',
    '#37a2da',
    '#53a05f',
    '#22a092',
    '#34959b',
  ];

  statusColor = ['#0e9c98', '#fe946f', '#0058ff', '#00ffa3'];

  electricityDate: string = moment().format('YYYY-MM-DD');
  energyOption = null;
  electricityType: any = 'power';
  energyLoading = false;
  canlendarLocale: any;
  piesOption: any;
  piesLoading: boolean = true;

  public electricity_common_params = null;
  sta_params: any = {};
  public electricityInterval: any = null;
  intervalTime: number = 5;
  deviceStatus: any = [];
  stationId: any;
  showConfig: boolean;
  isShow: boolean;

  constructor(
    private calendarLocales: CalendarLocales,
    private windservice: WindService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private stationmonitorservice: StationMonitorService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();

    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.initPage({ stationId });
      } else {
        this.initPage({ stationId: params.stationId });
      }
    });
  }

  ngOnInit() {}

  initPage(params) {
    this.stationId = params.stationId;

    this.sta_params = params;

    this.getWindOverview(params);

    this.getEnergy(
      _.merge({}, this.electricity_common_params, this.sta_params)
    );
    this.electricityInterval = setInterval(() => {
      this.getEnergy(
        _.merge({}, this.electricity_common_params, this.sta_params)
      );
    }, 1000 * 60 * this.intervalTime);

    this.getDeviceStatus(params);
  }

  getWindOverview(params) {
    // 获取风电场概览
    this.windservice.getWindOverview(params).subscribe((res: any) => {
      console.log(res);

      this.overviews = res;
    });
  }

  /**
   * getEnergy
   */
  public getEnergy(params) {
    if (this.electricityType == 'power') {
      this.energyLoading = true;
      this.stationmonitorservice.power(params).then((res: any) => {
        let data = res;
        this.energyOption = buildPowerChart(data);
        this.energyLoading = false;
      });
    } else {
      this.energyLoading = true;
      this.stationmonitorservice.energy(params).then((res: any) => {
        let data = res;
        if (params.dataType == 'day') {
          this.energyOption = buildElectricityDayChart(data);
        } else if (params.dataType == 'month') {
          this.energyOption = buildElectricityMonthChart(data);
        } else if (params.dataType == 'year') {
          this.energyOption = buildElectricityYearChart(data);
        }
        this.energyLoading = false;
      });
    }
  }

  ngAfterViewInit(): void {
    // setTimeout(() => {
    //   this.selectElectricity('power');
    // }, 1000);
  }

  /**
   * selectElectricityPower
   */
  public selectElectricity(type, date?) {
    this.electricityType = type;
    switch (type) {
      case 'power':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          dataType: 'day',
          date: this.electricityDate,
        };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      case 'electricityDay':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          dataType: 'day',
          date: this.electricityDate,
        };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      case 'electricityMonth':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM');
        } else {
          this.electricityDate = moment().format('YYYY-MM');
        }
        this.electricity_common_params = {
          dataType: 'month',
          date: this.electricityDate + '-01',
        };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      case 'electricityYear':
        this.electricity_common_params = { dataType: 'year' };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      default:
        break;
    }
  }
  electricityDateSelect(event) {
    this.selectElectricity(this.electricityType, event);
  }

  getDeviceStatus(params) {
    this.windservice.getDeviceStatus(params).subscribe((res: any) => {
      console.log(res);
      // this.status = res;

      this.deviceStatus = res.map((item, index) => {
        return {
          ...item,
          options: buildSinglePieChart(
            item.groupValue,
            this.statusColor[index]
          ),
        };
      });
      this.piesLoading = false;
    });
  }

  deviceDetailEvent(event) {
    this.deviceDetail(event);
  }

  /**
   * deviceDetail
   */
  public deviceDetail({ deviceId, stationId, deviceType }) {
    this.isShow = true;
    this.showConfig = true;
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });
  }

  back() {
    this.showConfig = false;
  }
}
