import { NgModule } from '@angular/core';
import {
    PickListModule,
    MultiSelectModule,
    InputTextModule,
    CalendarModule,
    InputTextareaModule,
    ButtonModule,
    PaginatorModule,
    DialogModule,
    DropdownModule,
    ConfirmDialogModule,
    PanelModule,
    AutoCompleteModule,
    CheckboxModule
} from 'primeng/primeng';
import { DeptPickListComponent } from './dept-picklist.component';
import { DeptPicklistService } from './dept-picklist.service';
import { ClassesCategoryModule } from '../classes-category/classes-category.module';
import { TableModule } from 'primeng/table';

@NgModule({
    imports: [
        PickListModule,
        MultiSelectModule,
        InputTextModule,
        CalendarModule,
        InputTextareaModule,
        ButtonModule,
        TableModule,
        PaginatorModule,
        DialogModule,
        DropdownModule,
        ConfirmDialogModule,
        PanelModule,
        AutoCompleteModule,
        CheckboxModule,
        ClassesCategoryModule
    ],
    exports: [DeptPickListComponent],
    declarations: [DeptPickListComponent],
    providers: [DeptPicklistService]
})
export class DeptPickListModule {}
