import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
import { Location } from "@angular/common";
import { Observable, forkJoin } from "rxjs";
import * as _ from "lodash";

import { CommonService } from "../../../common/services/common.service";
import { NavService } from "./nav.service";
import { AuthService } from "../../../core/auth.service";
import { MenuType } from "./nav.model";
import { CacheService } from "../../../common/services/cache.service";
import { Constants } from "../../../common/Constants";

@Component({
    selector: "app-nav",
    templateUrl: "./nav.component.html",
    styleUrls: ["./nav.component.less"]
})
export class NavComponent implements OnInit {
    public logoUrl: string = null;

    public navList: any;
    public isExpand = true;
    public overlaycontainerstyle: object;
    public thirdLevelNav: Array<object> = [];
    public niceScrollBarOption: object;
    public thirdLevel = true;

    @Output() public collapse = new EventEmitter();
    private allMenu;
    // 包含在数组中的resId会一直显示再页面,否则运行监视下如果没有选择电站将不显示其他菜单
    private monitorPageResIds = [
        "feature.monitor-overview",
        "feature.alarm.realtime"
    ];
    private stationId;
    firstItemshow: boolean;

    public constructor(
        private navService: NavService,
        private commonService: CommonService,
        private authService: AuthService,
        private cacheService: CacheService,
        private router: Router,
        private location: Location,
    ) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                if (!this.navList) {
                    return;
                }

                const newStationId = this.cacheService.getCache(
                    Constants.KEY_CURRENT_STATION_ID
                );
                if (
                    (this.stationId && !newStationId) ||
                    (!this.stationId && newStationId)
                ) {
                    this.navList = this.toggleStationMonitorMenu(this.navList);
                }
                this.stationId = newStationId;
            }
        });
    }

    public ngOnInit() {
        // 渲染导航数据
        forkJoin(
            this.navService.getNavData("menu"),
            // this.navService.getFavorite(),
            // (nav, favoriteConfig) => {
            nav => {
                this.allMenu = nav;
                let filteredNav = this.filterMenu(nav);

                // this.hanldeFavoriteMenu(favoriteConfig, filteredNav);
                filteredNav.map(item => {
                    item.hovered = false;
                    item.group = {};
                });
                return filteredNav;
            }
        ).subscribe((nav: any) => {
            if (!nav) {
                nav = [];
            }
            nav.sort((a, b) => a.menuOrder - b.menuOrder);
            nav.forEach(navItem => {
                if (navItem.children.length) {
                    navItem.children.sort((a, b) => a.menuOrder - b.menuOrder);
                } else {
                    if (navItem.menuType !== MenuType.favorite) {
                        navItem.hide = true;
                    }
                }
            });
            this.navList = this.toggleStationMonitorMenu(nav);
            this.findNavNode();
        });

        this.navService.getLogoConfig().subscribe((logo: any) => {
            if (!logo) {
                this.logoUrl = "";
            }
            this.logoUrl = logo.cfgValue1;
        });
    }

    public showSubMenuItems(navItem) {
        if (!navItem) {
            return;
        }
        this.navList.forEach(item => {
            if (navItem.menuId === item.menuId) {
                // 菜单收起的时候, 点击都是展开子菜单
                item.show = !this.isExpand ? true : !item.show;
            } else {
                item.show = false;
            }
        });
        this.isExpand = true;
        this.collapse.emit(!this.isExpand);
    }

    public clickHander() {
        this.isExpand = !this.isExpand;
        this.collapse.emit(!this.isExpand);
        this.navService.collapseSubject.next(!this.isExpand);
    }

    public routeLink(event, subitem) {
        // if(subitem.url){
        //   let navPath = "";
        //   this.navList.forEach((item,index)=>{
        //       if(item.menuId==subitem.parentId){
        //         navPath+=item.menuLabel;
        //         navPath+=">"+subitem.menuLabel
        //       }
        //       item.children.forEach((i)=>{
        //         if(i.menuLabel!=subitem.name){
        //           i.active = false;
        //         }
        //       })
        //   })
        //   subitem.active = true;
        //   // this.router.navigate([subitem.url]);
        //   this.navPath.emit(navPath);
        // }
        // localStorage.setItem('path',subitem.url);
    }

    public removeActive(thirdNav) {
        // this.navList.forEach((item)=>{
        //   item.children.forEach((i)=>{
        //     if(i.menuId==thirdNav.parentId){
        //       console.log(item)
        //     }
        //   })
        // })
        // localStorage.setItem('path',thirdNav.url);
    }
    /**
     * 三级菜单显示
     */
    public showThirdNav(event, navItems = null) {
        this.overlaycontainerstyle = {
            top: event.clientY - event.offsetY + "px",
            display: "block"
        };
        if (_.isEmpty(navItems)) {
            this.thirdLevelNav = [];
            this.thirdLevel = false;
        } else {
            this.thirdLevelNav = navItems;
            this.thirdLevel = true;
        }
    }

    /**
     * 三级菜单隐藏
     */
    public hideThirdNav() {
        this.overlaycontainerstyle = {
            display: "none"
        };
    }

    private filterMenu(menu) {
        if (!menu && menu.length) {
            return [];
        }
        return menu.filter(
            item =>
                item.menuType === MenuType.favorite ||
                item.menuType === MenuType.group
        );
    }

    private findMenuById(menuId) {
        if (this.allMenu) {
            return this.allMenu.find(menu => menu.menuId === menuId);
        }
    }

    private hanldeFavoriteMenu(favoriteConfig, nav) {
        let favorite = [];
        if (favoriteConfig && favoriteConfig.cfgValue1) {
            try {
                favorite = JSON.parse(favoriteConfig.cfgValue1);
            } catch (e) {
                console.warn("用户菜单收藏格式转换是失败." + e);
            }
        }
        favorite.forEach(item => {
            item.type = 1;
            if (this.findMenuById(item.menuId)) {
                item.icon = this.findMenuById(item.menuId).menuIcon1;
            }
        });
        let favoriteMenu = nav.find(
            item => item.menuType === MenuType.favorite
        );
        if (favoriteMenu) {
            favoriteMenu.isFavorite = true;
            favoriteMenu.children = favorite;
        }
    }

    // 运行监视菜单 如果 没有选择电站 只显示部分菜单, 和电站相关菜单不显示
    private toggleStationMonitorMenu(nav) {
        if (!nav) {
            return nav;
        }
        const stationId = this.cacheService.getCache(
            Constants.KEY_CURRENT_STATION_ID
        );
        const monitor = nav.find(item => item.menuLabel === "运行监视");
        if (!monitor) {
            return nav;
        }
        if (monitor.children) {
            monitor.children.forEach(item => {
                item.hide =
                    !stationId &&
                    !_.includes(this.monitorPageResIds, item.resId);
            });
        }
        return nav;
    }

    //获取当前展开的节点
    private findNavNode() {
        let path = this.location.path().split("?")[0];
        this.firstItemshow = false;
        this.navList.forEach((item, index) => {
            item.children.forEach(i => {
                if (i.url == path) {
                    i.active = true;
                    this.firstItemshow = true;
                    return;
                }
                if (i.children.length > 0) {
                    i.children.forEach(element => {
                        if (element.url == path) {
                            this.firstItemshow = true;
                        }
                        return;
                    });
                }
            });
            if (this.firstItemshow == true) {
                item.show = true;
                this.firstItemshow = false;
            }
        });
    }
}
