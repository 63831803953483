import { ActivatedRoute, Router } from '@angular/router';
import { DeviceService } from 'src/app/components/monitor/shared/device.service';
import { CommonService } from './../../../../common/services/common.service';
import { CodeTableService } from './../../../../core/code-table.service';
import {
  Component,
  OnInit,
  Input,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { DeviceManageService } from 'src/app/components/device-assets/device-manage/device-manage.service';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { FormService } from 'src/app/common/services/form.service';
import { StationMonitorService } from '../../station-monitor.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-equipment-files',
  templateUrl: './equipment-files.component.html',
  styleUrls: ['./equipment-files.component.less'],
})
export class EquipmentFilesComponent implements OnInit, OnChanges {
  @Input() deviceId = '';
  @Input() pDeviceId = '';
  @Input() pDeviceNo = '';
  @Input() deviceType = '';
  @Input() addDevice = 'edit';
  @Input() stationId = '';
  @Input() areaId = '';
  @Input() showButton;
  @Output() outputEvent = new EventEmitter<boolean>();
  fieldsDisplay: any;
  fieldsLabel: any;
  device: any;
  editForm: any;
  picUri: any;
  deviceAreas: { label: string; value: string }[];
  deviceStatus: SelectItem[];
  qualityStatus: SelectItem[];
  deviceUseFlagOptions: SelectItem[];
  depreciateMethod: SelectItem[];
  canlendarLocale: any;
  stations: { label: string; value: string }[];

  isShowObjectDialog = false;
  isShowModeSelector = false;

  constructor(
    private deviceService: DeviceManageService,
    private fb: FormBuilder,
    private codeTableService: CodeTableService,
    private calendarLocales: CalendarLocales,
    private formService: FormService,
    private commonService: CommonService,
    private stationmonitorservice: StationMonitorService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private tipservice: TipService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.activatedroute.queryParams.subscribe((res) => {
      this.deviceType = res.deviceType;
      this.stationId = res.stationId;
    });
  }

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.deviceId && changes.deviceId.currentValue) {
      this.getDeviceInfo(changes.deviceId.currentValue);
    }
    if (changes.areaId && changes.areaId.currentValue) {
      this.areaId = changes.areaId.currentValue;
    }
    if (changes.pDeviceId && changes.pDeviceId.currentValue) {
      this.pDeviceId = changes.pDeviceId.currentValue;
    }
    if (changes.pDeviceNo && changes.pDeviceNo.currentValue) {
      this.pDeviceNo = changes.pDeviceNo.currentValue;
    }
    if (changes.addDevice && changes.addDevice.currentValue) {
      if (changes.addDevice.currentValue === 'view') {
        setTimeout(() => {
          this.editForm && this.editForm.disable();
        }, 500);
      } else {
        setTimeout(() => {
          this.editForm && this.editForm.enable();
        }, 500);
      }
    }
    if (changes.deviceType && changes.deviceType.currentValue) {
      this.getDeviceInfoFields(changes.deviceType.currentValue);
    }
  }

  public buildForm() {
    this.editForm = this.fb.group({
      stationName: new FormControl(this.device.shortName),
      stationId: new FormControl(this.device.stationId, Validators.required),
      areaId: new FormControl(
        this.device.areaId || this.areaId,
        Validators.required
      ),
      areaName: new FormControl(this.device.areaName),
      deviceNo: new FormControl(this.device.deviceNo, Validators.required),
      deviceName: new FormControl(this.device.deviceName, Validators.required),
      modelName: new FormControl(this.device.modelNo),
      modelId: new FormControl(this.device.modelId, Validators.required),
      companyName: new FormControl(this.device.companyName),
      supplierName: new FormControl(this.device.supplierName),
      headerUserName: new FormControl(this.device.headerUserName),
      companyId: new FormControl(this.device.companyId), // , Validators.required
      classId: new FormControl(this.device.classId), // , Validators.required
      className: new FormControl(this.device.className),
      designNum: new FormControl(this.device.designNum),
      deviceId: new FormControl(this.device.deviceId),
      assetNum: new FormControl(this.device.assetNum),
      deviceSn: new FormControl(this.device.deviceSn),
      pDeviceId: new FormControl(this.device.pDeviceId),
      pDeviceNo: new FormControl(this.device.pDeviceNo || this.pDeviceNo),
      pdeviceId: new FormControl(this.device.pdeviceId || this.pDeviceId),
      pdeviceNo: new FormControl(this.device.pdeviceNo || this.pDeviceNo),
      inputNo: new FormControl(this.device.inputNo),
      useFlagText: new FormControl(this.device.useFlagText),
      useFlagNo: new FormControl(this.device.useFlagNo),
      collDeviceId: new FormControl(
        this.device.collDeviceId,
        Validators.maxLength(30)
      ),
      memo: new FormControl(this.device.memo),
      longitude: new FormControl(this.device.longitude),
      latitude: new FormControl(this.device.latitude),
      location: new FormControl(
        this.device.location,
        Validators.maxLength(100)
      ),

      deviceStatus: new FormControl(
        this.device.deviceStatus,
        Validators.required
      ),
      productionDate: new FormControl(this.device.productionDate),
      installDate: new FormControl(this.device.installDate),
      operationDate: new FormControl(
        this.device.operationDate,
        Validators.required
      ),
      runDays: new FormControl(this.device.runDays),
      qualityStatus: new FormControl(this.device.qualityStatus),
      qualityStartDate: new FormControl(this.device.qualityStartDate),
      qualityPeriod: new FormControl(this.device.qualityPeriod),
      qualityRemindTime: new FormControl(this.device.qualityRemindTime),
      mainterancePeriod: new FormControl(this.device.mainterancePeriod),
      mainteRemindTime: new FormControl(this.device.mainteRemindTime),
      mainteranceDate: new FormControl(this.device.mainteranceDate),
      testingPeriod: new FormControl(this.device.testingPeriod),
      detectingPeriod: new FormControl(this.device.detectingPeriod),
      checkPeriod: new FormControl(this.device.checkPeriod),
      testingDate: new FormControl(this.device.testingDate),
      detectingDate: new FormControl(this.device.detectingDate),
      checkDate: new FormControl(this.device.checkDate),
      ratedPower: new FormControl(
        this.device.ratedPower,
        Validators.pattern(/^\d+(\.\d{1,6})?$/)
      ),
      capacity: new FormControl(
        this.device.capacity,
        Validators.pattern(/^\d+(\.\d{1,6})?$/)
      ),
      childNum: new FormControl(this.device.childNum),
      installChildNum: new FormControl(this.device.installChildNum),
      invalidInput: new FormControl(this.device.invalidInput),
      pvModelNum: new FormControl(this.device.pvModelNum),
      modelAngle: new FormControl(
        this.device.modelAngle,
        Validators.maxLength(100)
      ),
      pollution: new FormControl(
        this.device.pollution,
        Validators.maxLength(100)
      ),
      block: new FormControl(this.device.block, Validators.maxLength(100)),
      modelLayout: new FormControl(
        this.device.modelLayout,
        Validators.maxLength(100)
      ),

      devicePrice: new FormControl(this.device.devicePrice),
      originalValue: new FormControl(this.device.originalValue),
      netValue: new FormControl(this.device.netValue),
      depreciatePeriod: new FormControl(this.device.depreciatePeriod),
      depreciateMethod: new FormControl(this.device.depreciateMethod),
      netRatio: new FormControl(this.device.netRatio),
    });
  }

  // 过滤字段是否显示
  private filterField() {
    this.fieldsDisplay = {
      deviceBasicInfoTittle: false, // 基本信息标题
      deviceLocationTittle: false,
      deviceRunInfoTittle: false, // 运行信息标题 deviceAttr deviceRunInfo
      deviceAssetInfoTittle: false, // 资产信息标题
    };
    this.fieldsLabel = {};

    if (this.device.fields) {
      this.device.fields.forEach((element) => {
        if (
          element.groupCode === 'deviceBasicInfo' &&
          this.fieldsDisplay['deviceBasicInfoTittle'] === false
        ) {
          this.fieldsDisplay['deviceBasicInfoTittle'] = true;
        }
        if (
          element.groupCode === 'deviceLocation' &&
          this.fieldsDisplay['deviceLocationTittle'] === false
        ) {
          this.fieldsDisplay['deviceLocationTittle'] = true;
        }

        if (
          (element.groupCode === 'deviceRunInfo' ||
            element.groupCode === 'deviceAttr') &&
          this.fieldsDisplay['deviceRunInfoTittle'] === false
        ) {
          this.fieldsDisplay['deviceRunInfoTittle'] = true;
        }

        if (
          element.groupCode === 'deviceAssetInfo' &&
          this.fieldsDisplay['deviceAssetInfoTittle'] === false
        ) {
          this.fieldsDisplay['deviceAssetInfoTittle'] = true;
        }

        this.fieldsDisplay[element.fieldCode] = element.isDisplay;
        this.fieldsLabel[element.fieldCode] = element.fieldName;
      });
    }
  }

  getDeviceInfo(deviceId) {
    this.deviceService.getDeviceById(deviceId).subscribe((res) => {
      if (res) {
        this.device = res;
        this.device.productionDate = this.device.productionDate
          ? moment(this.device.productionDate).format('YYYY-MM-DD')
          : null;
        this.device.operationDate = this.device.operationDate
          ? moment(this.device.operationDate).format('YYYY-MM-DD')
          : null;
        this.device.installDate = this.device.installDate
          ? moment(this.device.installDate).format('YYYY-MM-DD')
          : null;
        this.stationId = this.device.stationId;
        this.filterField();
        this.buildForm();
        this.loadFormData();
      }
    });
  }

  getDeviceInfoFields(deviceType) {
    if (!this.deviceId) {
      this.deviceService
        .getDeviceInfoFields({ deviceType })
        .subscribe((res) => {
          this.device = {
            fields: null,
          };
          if (this.stationId) {
            this.device['stationId'] = this.stationId;
          }
          if (this.pDeviceId) {
            this.device['pDeviceId'] = this.pDeviceId;
          }
          this.device.fields = res;
          this.filterField();
          this.buildForm();
          this.loadFormData();
        });
    }
  }

  loadFormData() {
    this.codeTableService
      .getNemsSelectItems('deviceStatus')
      .then((deviceStatus) => {
        this.deviceStatus = deviceStatus;
        if (!this.deviceId) {
          this.editForm.patchValue({
            deviceStatus: this.deviceStatus[0].value,
          });
        }
      });

    this.codeTableService
      .getNemsSelectItems('qualityStatus')
      .then((qualityStatus) => {
        this.qualityStatus = qualityStatus;
      })
      .then((next) => {
        this.codeTableService
          .getNemsSelectItems('depreciateMethod')
          .then((depreciateMethod) => {
            this.depreciateMethod = depreciateMethod;
          });
      });
    this.codeTableService.getNemsSelectItems('deviceUseFlag').then((res) => {
      this.deviceUseFlagOptions = res;
    });

    this.deviceService
      .getSampleStationAll()
      .toPromise()
      .then((stations) => {
        this.stations = stations.map((station) => {
          return {
            label: station.shortName,
            value: station.stationId,
          };
        });
      })
      .then((res) => {
        if (!this.stationId) {
          if (this.stations && this.stations[0]) {
            this.stationId = this.stations[0].value;
            this.device.stationId = this.stationId;
            this.loadDeviceArea(this.device.stationId);
            this.editForm.patchValue({
              stationId: this.stations[0].value,
            });
          }
        } else {
          this.loadDeviceArea(this.stationId);
        }
      });

    if (this.device.deviceId) {
      this.stationmonitorservice
        .getDevicePics(this.device.deviceId)
        .subscribe((modelInfo: any) => {
          this.picUri = modelInfo;
        });
    }
  }

  public submit() {
    if (!this.formService.isValid(this.editForm)) {
      this.tipservice.tip('warn', '请检查输入项');
      return;
    }
    this.commonService.mask.show();
    this.deviceService.saveDevice(this.editForm.value).subscribe(
      () => {
        this.tipservice.tip('success', '保存成功');
        this.outputEvent.emit(true);
      },
      () => {
        this.commonService.mask.hide();
      },
      () => {
        this.commonService.mask.hide();
      }
    );
  }

  public close() {
    if (this.addDevice) {
      this.outputEvent.emit(true);
    } else {
      if (this.stationId && this.deviceType) {
        this.router.navigate(['/monitorStation/deviceList'], {
          queryParams: { stationId: this.stationId },
        });
      }
      this.stationmonitorservice.setDeviceDetail({
        deviceId: '',
        stationId: '',
      });
    }
  }

  private loadDeviceArea(stationId) {
    this.deviceService.getStationArea(stationId).subscribe((devices) => {
      this.deviceAreas = devices.map((device) => {
        return { label: device.areaName, value: device.areaId };
      });
    });
  }

  public changeStation(stationId) {
    this.loadDeviceArea(stationId);
  }

  showObjectDialog() {
    if (this.addDevice !== 'view') {
      this.isShowObjectDialog = true;
    }
  }

  public deviceSelected(device) {
    if (_.isEmpty(device)) {
      device = {
        deviceId: null,
        deviceNo: null,
      };
    }

    this.editForm.patchValue({
      pDeviceId: device.deviceId,
      pDeviceNo: device.deviceNo,
      pdeviceId: device.deviceId,
      pdeviceNo: device.deviceNo,
    });
  }

  showModeSelector() {
    if (this.addDevice !== 'view') {
      this.isShowModeSelector = true;
    }
  }

  public onSelectModel(model) {
    this.editForm.patchValue({
      companyId: model.companyId,
      companyName: model.companyName,
      modelId: model.modelId,
      modelName: model.modelName,
      classId: model.classId,
      className: model.className,
    });
    if (model.deviceId) {
      this.deviceService
        .getDevicePic(model.deviceId)
        .subscribe((modelInfo: any) => {
          this.picUri = modelInfo;
        });
    }

    this.isShowModeSelector = false;
  }
}
