import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { CommonService } from '../../../../common/services/common.service';
import { CodeTableService } from '../../../../core/code-table.service';
import { SelectItem, ConfirmationService } from 'primeng/primeng';
import { RepairService } from '../repair.service';
import { CalendarLocales } from '../../../../shared/locales/calendar.locales';
import * as _ from 'lodash';
import * as moment from 'moment';
import { buildWorkFlow } from './work-flow.echarts';
import { ClassesType } from '../../../../common/models/classes-type.model';
import { MaterialType, IoType } from '../../../device-assets/shared/index.model';
import { TipService } from '@common/services/tip.service';
@Component({
  selector: 'app-repair-ticket',
  templateUrl: './repair-ticket.component.html',
  styleUrls: ['./repair-ticket.component.less'],
})
export class RepairTicketComponent implements OnInit {
  @Input() public type = 'add';
  @Input() public ticketId = 0;
  @Input() public data = null;
  @Input() public isShow = false;

  @Output() hideEvent = new EventEmitter<boolean>();
  @Output() refreshEvent = new EventEmitter<boolean>();
  // 标题
  title: string;
  // ticket表单
  form: FormGroup;
  // 字段
  fields: any;
  // 电站id
  stationId: any;
  // 电站选择器参数
  staParams = null;
  // 用户选择器弹窗 开关
  isShowUserDialog: boolean;
  // 用户选择弹窗类型 单选/多选
  userDialogType: boolean;
  // 用户选择器 修改字段名称
  userDialogTarget: any;
  // 用户选择器参数
  person = [];
  // 日历参数
  canlendarLocale: any;

  // 抢修设备 表格字段
  public deviceColumns = [
    {
      header: '设备编号',
      field: 'objNo',
    },
    {
      header: '设备名称',
      field: 'objName',
    },
    {
      header: '设备类型',
      field: 'objClassName',
    },
    {
      header: '型号',
      field: 'modelNo',
    },
    {
      header: '厂商',
      field: 'objCompany',
    },
    {
      header: '操作',
      field: '',
      width: '50px',
    },
  ];
  // 抢修设备弹窗
  isShowObject: boolean;
  // 抢修设备
  repairDevices: any = [];

  // 订单Id
  orderId: any;
  // 工作流程 nodes
  nodes: any;
  // 工作流程 links
  links: any;
  // 工作流程 图表 options
  options: any;

  // 图片权限
  picPermission: any;
  // 文件权限
  docPermission: any;
  // 抄送权限
  ccPermission: any;
  // 评论权限 （由评论列表中参数控制）
  commentPermission: any;
  // 弹窗 按钮组
  buttons: any;

  // 抢修检查 弹窗开关
  ticketCheckDialog = false;
  // 抢修检查默认值
  checkDialog = {
    checkStatus: '02',
    checkMemo: null,
  };
  // 抢修检查状态 选项
  checkStatuses: SelectItem[];

  // 出入库弹窗 开关
  isShowRecords: boolean;
  // 出入库 传入参数
  recordsParam: any;
  // materialType
  materialType = MaterialType.spareParts;
  // ClassesType
  classesType = ClassesType.spareParts;
  // 是否是进行节点扭转 选择用户
  nextUserFlag: boolean;
  // 扭转用户ID
  nextUserIds: any;

  // 备注弹窗 开关
  memoDialog: boolean;
  // 备注弹窗 备注字段
  memo: any;

  // 按钮行为
  buttonAction: any;

  // 评论列表
  commentsList: any[];

  // 设备Id
  deviceId: any;
  objClassOptions: any;
  ioTypes: IoType;
  selectedTeam: { teamId: any; teamName: any }[];

  constructor(
    private fb: FormBuilder,
    private repairservice: RepairService,
    private calendarLocales: CalendarLocales,
    private commonservice: CommonService,
    private codeTableService: CodeTableService,
    private confirmationService: ConfirmationService,
    private tipservice: TipService
  ) {
    // 初始化 日历参数
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    // 初始化 抢修单form
    this.initForm();
  }

  ngOnInit() {
    // 根据 type 判断 弹窗显示 挑剔
    if (this.type === 'add') {
      this.title = '新增抢修单';
    } else if (this.type === 'view') {
      this.title = '查看抢修单';
    } else if (this.type === 'edit') {
      this.title = '编辑抢修单';
    }
    // 获取设备id 由告警转抢修时 需要
    this.deviceId = (this.data && this.data.deviceId) || null;
    // 初始化数据
    this.getTicketData();
  }

  /**
   * 初始化form表单
   */
  private initForm() {
    this.form = this.fb.group({
      // 基础信息
      ticketId: new FormControl(null), // 抢修Id
      ticketCode: new FormControl(null), // 抢修单号
      orderId: new FormControl(null), // orderId
      parentOrderId: new FormControl(null), // parentOrderId
      stationId: new FormControl(null), // 电站Id
      stationName: new FormControl(null), // 电站名称
      responsiblePerson: new FormControl(null), // 工作负责人(监护人)
      responsiblePersonName: new FormControl(null), // 工作负责人(监护人)名称
      creater: new FormControl(null), // 工作布置人
      createrName: new FormControl(null), // 工作布置人名称
      teamId: new FormControl(null), // 班组
      teamName: new FormControl(null), // 班组名称
      teamUsers: this.fb.array([]), // 抢修班组人员
      teamUsersText: new FormControl(null), // 抢修班组人员
      repairDevices: this.fb.array([]), // 设备
      repairDesc: new FormControl(null), // 抢修任务
      precautions: new FormControl(null), // 安全措施
      attentions: new FormControl(null), // 抢修地点保留带电部分或注意事项
      // 抢修许可
      extrPrecautions: new FormControl(null), // 参与人
      permitUser: new FormControl(null), // 经许可人(调度/运行人员)
      permitUserName: new FormControl(null), // 经许可人名称(调度/运行人员)
      permitTime: new FormControl(new Date()), // 许可时间
      permitWorkTime: new FormControl(new Date()), // 许可抢修时间
      // 处理结果
      startTime: new FormControl(new Date()), // 开始时间
      endTime: new FormControl(null), // 完成时间
      workHours: new FormControl(null), // 工作量
      repairStatus: new FormControl(null), // 工作量
      resultDesc: new FormControl(null), // 现场设备状况及保留安全措施
      reportTime: new FormControl(null), // 填写时间

      pics: this.fb.array([]), // 抢修图片
      docs: this.fb.array([]), // 抢修附件
      checker: new FormControl(null), // 检查人
      checkerName: new FormControl(null), // 检查人名称
      checkTime: new FormControl(null), // 检查时间
      checkStatus: new FormControl(null), // 检查结果
      checkStatusText: new FormControl(null), // 检查结果
      checkMemo: new FormControl(null), // 检查备注
      // 工作流程
      workflow: this.fb.group({
        nodes: this.fb.array([]),
        links: this.fb.array([]),
      }),

      // 流程日志
      workflowLogs: this.fb.array([]),

      // 抄送
      ccUsers: this.fb.array([]),
    });
  }

  /**
   * 初始化 页面数据
   */
  private getTicketData() {
    this.commonservice.mask.show();
    this.repairservice
      .getRepairTicketByTicketId(this.ticketId, { deviceId: this.deviceId })
      .subscribe(
        (res: any) => {
          // 页面字段
          this.fields = res.fields;
          for (const key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
              const element = this.fields[key];
              if (key === 'repairStatus') {
                if (element.props) {
                  element.props.map((item) => {
                    item.value = item.first;
                    item.label = item.second;
                  });
                }
              }
            }
          }

          // 按钮组
          this.buttons = res.buttons;
          // 返回值
          const values = res.ticket;

          // 图片权限
          this.picPermission = res.picPermission;
          // 文档权限
          this.docPermission = res.docPermission;
          // 抄送权限
          this.ccPermission = res.ccPermission;
          // 评论权限
          this.commentPermission = res.commentPermission;
          // orderId
          this.orderId = values.orderId;
          // 处理电站
          if (this.data && this.data.stationId) {
            values.stationId = this.data.stationId;
          }
          this.stationId = values.stationId;
          this.staParams = {
            sta_stationIds: values.stationId,
          };
          if (this.data && this.data.parentOrderId) {
            values.parentOrderId = this.data.parentOrderId;
          }
          if (!values.permitUser) {
            values.permitUser = localStorage.getItem('userId');
            values.permitUserName = localStorage.getItem('userName');
          }
          // 时间格式处理
          values.permitTime = values.permitTime
            ? moment(values.permitTime).format('YYYY-MM-DD HH:mm')
            : moment().format('YYYY-MM-DD HH:mm');
          values.permitWorkTime = values.permitWorkTime
            ? moment(values.permitWorkTime).format('YYYY-MM-DD HH:mm')
            : moment().format('YYYY-MM-DD HH:mm');
          values.startTime = values.startTime
            ? moment(values.startTime).format('YYYY-MM-DD HH:mm')
            : null;
          values.endTime = values.endTime
            ? moment(values.endTime).format('YYYY-MM-DD HH:mm')
            : null;
          values.reportTime = values.reportTime
            ? moment(values.reportTime).format('YYYY-MM-DD HH:mm')
            : moment().format('YYYY-MM-DD HH:mm');

          // 处理抢修设备
          if (values.repairDevices !== null) {
            const control = this.form.get('repairDevices') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.repairDevices.map((item, index) => {
              item.objIndex = index;
              control.push(this.initDefectDevice());
            });
            this.repairDevices = values.repairDevices;
          } else {
            values.repairDevices = [];
            this.repairDevices = [];
          }

          if (values.teamId && values.teamName) {
            this.selectedTeam = [
              {
                teamId: values.teamId,
                teamName: values.teamName,
              },
            ];
          } else {
            this.selectedTeam = [];
          }

          // 抢修班组人员
          if (values.teamUsers !== null) {
            const control = this.form.get('teamUsers') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            const teamUsersText = values.teamUsers.map((item) => {
              control.push(this.initUsers());
              return item.userName;
            });
            values.teamUsersText = teamUsersText.join(',');
          } else {
            values.teamUsers = [];
          }

          // 照片
          if (values.pics !== null) {
            const control = this.form.get('pics') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.pics.map((item) => {
              control.push(this.initDoc());
            });
          } else {
            values.pics = [];
          }

          // 文件
          if (values.docs !== null) {
            const control = this.form.get('docs') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.docs.map((item) => {
              control.push(this.initDoc());
            });
          } else {
            values.docs = [];
          }

          // 工作流程
          if (values.workflow !== null) {
            const workflow = values.workflow;
            for (const key in workflow) {
              if (workflow.hasOwnProperty(key)) {
                const element = workflow[key];
                if (key === 'nodes') {
                  this.nodes = element;
                  const control = (this.form.get('workflow') as FormGroup).controls[
                    key
                  ] as FormArray;
                  while (control.length) {
                    control.removeAt(control.length - 1);
                  }
                  element.map((item) => {
                    control.push(this.initNodes());
                  });
                } else if (key === 'links') {
                  this.links = element;
                  const control = (this.form.get('workflow') as FormGroup).controls[
                    key
                  ] as FormArray;
                  while (control.length) {
                    control.removeAt(control.length - 1);
                  }
                  element.map((item) => {
                    control.push(this.initLinks());
                  });
                }
              }
            }
            this.options = buildWorkFlow(this.links, this.nodes);
          } else {
            values.workflow = {};
          }

          // 流程日志
          if (values.workflowLogs) {
            const control = this.form.get('workflowLogs') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.workflowLogs.map((item) => {
              control.push(this.initWorkflowLogs());
            });
          } else {
            values.workflowLogs = [];
          }

          // 抄送
          if (values.ccUsers !== null) {
            const control = this.form.get('ccUsers') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.ccUsers.map((item) => {
              control.push(this.initCcUser());
            });
          } else {
            values.ccUsers = [];
          }

          // 评论
          if (values.comments === null) {
            values.comments = [];
            this.commentsList = [];
          } else {
            this.commentsList = values.comments;
          }
          // 表单填值
          this.form.patchValue(values);
          // 查看状态显示权限
          if (this.type === 'view') {
            // 图片权限
            this.picPermission = 0;
            // 文档权限
            this.docPermission = 0;
            // 抄送权限
            this.ccPermission = 0;
            this.form.disable();
          }
        },
        () => { },
        () => {
          this.commonservice.mask.hide();
        },
      );
  }

  // 流程日志数组
  getWorkflowLogs(form) {
    return form.controls.workflowLogs.controls;
  }
  // 弹窗关闭事件
  onHide(event) {
    this.hideEvent.emit(true);
  }
  // 电站选择器 返回事件
  outputStaParamsEvent(event) {
    if (
      this.stationId &&
      event.sta_stationIds !== this.stationId &&
      this.repairDevices.length > 0
    ) {
      this.confirmationService.confirm({
        header: '提示',
        message: `变更电站后要重新填写抢修设备列表，确定变更么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          this.stationId = event.sta_stationIds;
          this.repairDevices = [];
          this.form.patchValue({
            stationId: event.sta_stationIds,
            stationName: event.sta_stationName,
          });
        },
        reject: () => {
          this.staParams = {
            sta_stationIds: this.stationId,
          };
        },
      });
    } else {
      this.stationId = event.sta_stationIds;
      this.form.patchValue({
        stationId: event.sta_stationIds,
        stationName: event.sta_stationName,
      });
    }
  }

  outputTeamEvent(event) {
    if (event) {
      this.form.patchValue({
        teamName: event.teamName,
        teamId: event.teamId,
      });
      if (event.users && event.users.length > 0) {
        const control = this.form.get('teamUsers') as FormArray;
        while (control.length) {
          control.removeAt(control.length - 1);
        }
        event.users.map((item) => {
          control.push(this.initUsers());
        });
        this.form.patchValue({
          teamUsers: event.users,
          teamUsersText: event.userNames,
        });
      }
    }
  }

  /**
   * 打开用户选择
   * @param <{ String }> type - 用户选择器类型 single/multi.
   * @param <{ String }> field - 当前操作的字段.
   * @param <{ Boolean }> editable - 当前字段是否客编辑.
   */
  openUserDialog(event, type, field, editable) {
    // 如果是查看或是不可编辑状态 返回
    if (this.type === 'view' || editable === false) {
      return;
    }
    this.userDialogType = type === 'single' ? true : false;
    this.userDialogTarget = field;
    const value = this.form.value[field];
    // 判断 单选还是多选
    if (this.userDialogType === true) {
      this.person = value ? [{ userId: value }] : null;
    } else {
      this.person = value.map((item) => {
        return {
          userId: item.userId,
          realName: item.userName,
        };
      });
    }
    this.isShowUserDialog = true;
  }

  // 用户选择器 返回事件
  personChange(event) {
    // 节点扭转选择用户
    if (this.nextUserFlag === true) {
      if (event.userId) {
        this.nextUserIds = event.userId;
        this.alertTypeFunction();
      }
      this.nextUserFlag = false;
      return;
    }
    if (this.userDialogType) {
      this.form.patchValue({
        [this.userDialogTarget]: event.userId,
        [this.userDialogTarget + 'Name']: event.realName,
      });
    } else {
      const control = this.form.get(this.userDialogTarget) as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      const Users = [];
      const UsersText = event.map((item) => {
        Users.push({
          userId: item.userId,
          userName: item.realName,
        });
        control.push(this.initUsers());
        return item.realName;
      });
      this.form.get(this.userDialogTarget).patchValue(Users);
      this.form.get(this.userDialogTarget + 'Text').patchValue(UsersText.join(','));
    }
  }

  // 添加设备按钮事件
  addDevices(event) {
    if (!this.stationId) {
      this.tipservice.tip('warn', '请线选择电站');
      return;
    }
    this.isShowObject = true;
  }

  // 设备选择器 返回事件
  getObjectSelected(event) {
    const value = event;
    const control = this.form.get('repairDevices') as FormArray;
    this.repairDevices = value.map((item, index) => {
      item.objId = item.deviceId;
      item.objNo = item.deviceNo;
      item.objName = item.deviceName;
      item.psrId = item.psrId;
      item.objIndex = control.length + 1 + index;
      control.push(this.initDefectDevice());
      return item;
    });
    this.form.get('repairDevices').patchValue(this.repairDevices);
  }

  /**
   * 移除抢修设备
   * @param <{ String }> objId - 设备Id.
   * @param <{ Number }> index - 下标
   */
  removeDevice(event, objId, index) {
    if (objId) {
      const control = this.form.get('repairDevices') as FormArray;
      control.removeAt(index);
      _.remove(this.repairDevices, (n: any) => {
        return n.objIndex === index;
      });
    }
  }

  // 按钮组 操作
  switchFunction(button) {
    switch (button.buttonAction) {
      case '$remind$':
        this.remindFunction(this.ticketId);
        break;
      case '$check$':
        this.openDefectCheckDialog();
        break;
      case 'materialOut':
        this.materialOutFunction();
        break;
      case 'materialIn':
        this.materialInFunction();
        break;
      case 'delete':
        this.deleteFunction();
        break;
      default:
        this.buttonFunction(button);
        break;
    }
  }

  // 按钮组 step1：formCheck
  buttonFunction(button) {
    if (button.formCheck === '1') {
      this.repairservice.getNullable(button.targetNodeId).then((res: any) => {
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            if (key.indexOf('.') === -1) {
              const element = res[key];
              if (this.form.value[key] === null && !element) {
                this.tipservice.tip('warn', this.fields[key].name + '字段不能为空');
                return;
              }
            } else {
              const aNewKey = key.split('.');
              const element = res[key];
              if (this.form.value[aNewKey[0]].length > 0 && !element) {
                let flag = true;
                this.form.value[aNewKey[0]].every((item) => {
                  if (item[aNewKey[1]] === null) {
                    this.tipservice.tip('warn', this.fields[key].name + '字段不能为空');
                    flag = false;
                    return false;
                  } else {
                    return true;
                  }
                });
                if (!flag) {
                  return;
                }
              }
            }
          }
        }
        this.userSelectorFunction(button);
      });
    } else {
      this.userSelectorFunction(button);
    }
  }

  // 按钮组 step2：userSelector
  public userSelectorFunction(button) {
    this.buttonAction = button;
    if (button.userSelector === '0') {
      this.alertTypeFunction(button);
    } else if (button.userSelector === '1') {
      this.person = [];
      this.userDialogType = true;
      this.nextUserFlag = true;
      this.isShowUserDialog = true;
    } else if (button.userSelector === '2') {
      this.person = [];
      this.userDialogType = false;
      this.nextUserFlag = true;
      this.isShowUserDialog = true;
    }
  }

  public alertTypeFunction(button?) {
    button = button ? button : this.buttonAction;
    this.memo = null;
    if (button.alertType === '0') {
      this.actionFunction();
    } else if (button.alertType === '1') {
      this.confirmationService.confirm({
        header: '提示',
        message: `确定要提交么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          this.actionFunction();
        },
      });
    } else if (button.alertType === '2') {
      this.memo = null;
      this.nextUserIds = null;
      this.memoDialog = true;
    }
  }

  // 催单操作
  remindFunction(ticketId) {
    this.repairservice.remind(ticketId).subscribe((res: string) => {
      this.tipservice.tip('success', res);
    });
  }

  // 打开抢修检查弹窗
  openDefectCheckDialog() {
    this.codeTableService.getNemsProp('orderCheckStatus').then((res) => {
      this.checkStatuses = res;
      this.ticketCheckDialog = true;
    });
  }

  // 检查操作
  checkFunction(event) {
    if (this.checkDialog.checkMemo && this.checkDialog.checkMemo.length > 500) {
      this.tipservice.tip('warn', '结果说明不超过500字符');
      return;
    }
    const params = {
      ticketId: this.ticketId,
      checkStatus: this.checkDialog.checkStatus,
      checkMemo: this.checkDialog.checkMemo,
    };
    this.repairservice.check(params).subscribe((res: any) => {
      this.tipservice.tip('success', '提交成功');
      this.form.patchValue(res);
      this.ticketCheckDialog = false;
    });
  }

  // 出库
  materialOutFunction() {
    this.recordsParam = {
      orderCode: this.form.value.ticketCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '08',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.out;
    this.isShowRecords = true;
  }

  // 入库
  materialInFunction() {
    this.recordsParam = {
      orderCode: this.form.value.ticketCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '08',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.into;
    this.isShowRecords = true;
  }

  // 出入库弹窗 返回事件
  submitAddData(event) {
    this.hideEvent.emit(true);
    this.refreshEvent.emit(true);
  }

  // 删除
  deleteFunction() {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定要删除该抢修单么?`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        const ticketId = this.form.value.ticketId;
        this.repairservice.deleteReparidTicket(ticketId).subscribe((res) => {
          this.tipservice.tip('success', '删除成功');
          this.hideEvent.emit(true);
          this.refreshEvent.emit(true);
        });
      },
    });
  }

  // 打开转派/派单 窗口
  openDispatchDialog(button) {
    this.person = [];
    this.buttonAction = button;
    this.userDialogType = true;
    this.nextUserFlag = true;
    this.isShowUserDialog = true;
  }

  // 驳回/关闭/完成 窗口
  openMemoDialog(button) {
    this.memo = null;
    this.nextUserIds = null;
    this.memoDialog = true;
    this.buttonAction = button;
  }

  //
  actionFunction() {
    const params = {
      title: this.title,
      ticket: this.formatParams(this.form.value),
      action: this.buttonAction,
      nextUserIds: this.nextUserIds ? [this.nextUserIds] : null,
      memo: this.memo,
    };
    this.repairservice.repairTicketsAction(params).subscribe((res) => {
      this.nextUserIds = null;
      this.memo = null;
      this.tipservice.tip('success', '提交成功');
      this.hideEvent.emit(true);
      this.refreshEvent.emit(true);
    });
  }

  // 图片上传组件 返回事件
  setPics(data) {
    const value = this.form.value.pics || [];
    const control = this.form.get('pics') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initDoc());
      value.push(item);
    });
    this.form.get('pics').patchValue(value);
  }

  // 附件上传组件 返回事件
  setDocs(data) {
    const value = this.form.value.docs || [];
    const control = this.form.get('docs') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initDoc());
      value.push(item);
    });
    this.form.get('docs').patchValue(value);
  }

  // 抄送组件 返回事件
  ccUserEvent(data) {
    const control = this.form.get('ccUsers') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initCcUser());
    });
    this.form.patchValue({
      ccUsers: data,
    });
  }

  // 初始化 用户 formGroup
  initUsers() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  // 初始化 设备/设施
  initDefectDevice() {
    return new FormGroup({
      relaId: new FormControl(null),
      orderId: new FormControl(null),
      objNo: new FormControl(null),
      objId: new FormControl(null),
      deviceNo: new FormControl(null),
      objName: new FormControl(null),
      psrId: new FormControl(null),
      objCompany: new FormControl(null),
      objProvider: new FormControl(null),
      objClass: new FormControl(null),
      objClassName: new FormControl(null),
      objIndex: new FormControl(0),
    });
  }

  initDoc() {
    return new FormGroup({
      docId: new FormControl(null),
      docName: new FormControl(null),
      docType: new FormControl(null),
      folderId: new FormControl(null),
      size: new FormControl(null),
      sizeDesc: new FormControl(null),
      memo: new FormControl(null),
      orderNo: new FormControl(null),
      customerId: new FormControl(null),
      uri: new FormControl(null),
      updateTime: new FormControl(null),
      updater: new FormControl(null),
      stationId: new FormControl(null),
      realName: new FormControl(null),
      stationName: new FormControl(null),
      recordType: new FormControl(null),
      recordId: new FormControl(null),
      updaterName: new FormControl(null),
    });
  }

  initNodes() {
    return new FormGroup({
      statusText: new FormControl(null),
      name: new FormControl(null),
      nodeType: new FormControl(null),
      user: new FormControl(null),
      status: new FormControl(null),
    });
  }

  initLinks() {
    return new FormGroup({
      sourceId: new FormControl(null),
      targetId: new FormControl(null),
      source: new FormControl(null),
      target: new FormControl(null),
    });
  }

  initWorkflowLogs() {
    return new FormGroup({
      logId: new FormControl(null),
      action: new FormControl(null),
      executeTime: new FormControl(null),
      flowId: new FormControl(null),
      memo: new FormControl(null),
      nextNodeId: new FormControl(null),
      nextNodeName: new FormControl(null),
      nodeId: new FormControl(null),
      orderId: new FormControl(null),
      preNodeId: new FormControl(null),
      preNodeName: new FormControl(null),
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  initCcUser() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
      userIcon: new FormControl(null),
    });
  }

  formatParams(params) {
    let permitTime = {};
    if (params.permitTime) {
      permitTime = {
        permitTime: moment(params.permitTime).format(),
      };
    }
    let permitWorkTime = {};
    if (params.permitWorkTime) {
      permitWorkTime = {
        permitWorkTime: moment(params.permitWorkTime).format(),
      };
    }

    let startTime = {};
    if (params.startTime) {
      startTime = {
        startTime: moment(params.startTime).format(),
      };
    }
    let endTime = {};
    if (params.endTime) {
      endTime = {
        endTime: moment(params.endTime).format(),
      };
    }
    let reportTime = {};
    if (params.reportTime) {
      reportTime = {
        reportTime: moment(params.reportTime).format(),
      };
    }

    return {
      ...params,
      ...permitTime,
      ...reportTime,
      ...permitWorkTime,
      ...startTime,
      ...endTime,
    };
  }
}
