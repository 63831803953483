import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { TreeviewItem } from '../treeview/index';
import { CommonService } from '../../services/common.service';

@Injectable()
export class DeptSelectorService {
  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService,
  ) {}

  public getDepts() {
    return this.http.get(`${this.appConfig.apiUrl}/api/nems/depts/tree`);
  }

  public filterDeptData(name, value, data): TreeviewItem[] {
    const resultData: TreeviewItem[] = [];
    data.map((item, index) => {
      _.forEach(item, (val, key) => {
        if (key === 'data') {
          resultData.push(
            new TreeviewItem({
              collapsed: val.expanded,
              checked: false,
              text: val[name] || '',
              value: val[value],
            }),
          );
        }
        if (key === 'children' && !_.isEmpty(val)) {
          resultData[index]['children'] = this.filterTreeData(name, value, val);
        }
      });
    });

    return resultData;
  }

  public filterTreeData(name, value, data): TreeviewItem[] {
    const resultData: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
      resultData.push(
        new TreeviewItem({
          collapsed: data[i].data.expanded,
          checked: false,
          text: data[i].data[name],
          value: data[i].data[value],
        }),
      );
      if (!_.isEmpty(data[i].children)) {
        resultData[i]['children'] = this.filterTreeData(name, value, data[i].children);
      }
      resultData[i].correctChecked();
    }
    return resultData;
  }
}
