import {
  Order,
  RequestOrder,
  RequestPagination,
  RequestTime
} from '../../../common/models/request.model';

export interface Device {
  areaId: string;
  areaName: string;
  children: any;
  classId: string;
  className: string;
  deviceId: string;
  deviceName: string;
  deviceNo: string;
  idxCodelist: DevicePoint[];
  modelId: string;
  modelNo: string;
  parentId: string;
  psrId: string;
  statusAlarm: number;
  statusDefect: number;
  statusNormal: number;
  topoItemId: string;
  count: string;
  alarmNumPoint: string;
  classOrder: string;
  commStatusPoint: string;
  defectNumPoint: string;
  deviceAlarmNum: number;
  deviceCommStatus: number;
  deviceDefectNum: number;
  deviceRunStatus: number;
  deviceType: string;
  runStatusPoint: string;
}

// 设备测点
export interface DevicePoint {
  cateId: string;
  dataType: string;
  displayOrder2: number;
  displayPrecision: number;
  pointId: number;
  pointName: string;
  pointUnit: string;
  propTypeId: string;
  remotionType: string;
  value: number;
}

export interface DeviceArea {
  areaId: string;
  areaName: string;
  areaTree?: string;
  areaType?: string;
  capacity?: number;
  latitude?: string;
  longitude?: string;
  memo?: string;
  parentId: string;
  psrId?: string;
  stationId: string;
}

export enum DeviceStatus {
  offline = '中断',
  alarm = '告警',
  defect = '缺陷'
}

/**
 * 设备类型
 */
export enum DeviceType {
  NB = 'NB',	// 逆变器
  JNB = 'JNB',	// 交流逆变器
  BYQ = 'BYQ',	// 变压器
  QT = 'QT',	// 其他
  GYKG = 'GYKG',	// 高压柜
  BWD = 'BWD',	// 并网点
  DNB = 'DNB',	// 电能表
  ZC = 'ZC',	// 组串
  HLX = 'HLX',	// 直流汇流箱
  QXZ = 'QXZ',	// 气象站
  E2BOX = 'E2BOX',	// E2Box
  JHL = 'JHL',	// 交流汇流箱
  OTHER = 'QT' // 其他
}

/**
 * 设备排名请求
 */
export interface RankingRequest extends RequestOrder, RequestPagination, RequestTime {
  stationId?: string;
  groupType?: string;
  areaIds?: string[];
  deviceType?: DeviceType;
  deviceIds?: string[];
  cateIds?: string[];
}

interface DeviceDataSort {
  sortField?: string;
  sort?: string;
}

/**
 * 汇流箱实时数据请求
 */
export interface CombinerBoxDataRequest extends RequestPagination, RequestTime, DeviceDataSort {
  stationId: string;
  deviceNo?: string;
  areaIds?: string[];
  classIds?: string[];
  modelIds?: string[];
  deviceRunStatus?: string[];
  parentDeviceIds?: string[];
}

/**
 * 逆变器实时数据请求
 */
export interface InvertersDataRequest extends RequestPagination, RequestTime, DeviceDataSort {
  stationId: string;
  deviceTypes?: string[];
  deviceType?: string;
  deviceRunStatus?: string[];
  areaIds?: string[];
  classIds?: string[];
  modelIds?: string[];
  parentDeviceIds?: string[];
  deviceNo?: string;
}

export interface StationDevicesRequest extends RequestPagination, RequestTime {
  stationIds: string[];
  areaIds?: string[];
  deviceTypes?: string[];
  parentDeviceIds?: string[];
}

/**
 * 变压器实时数据请求
 */
export interface TransformerDataRequest extends RequestPagination, RequestTime, DeviceDataSort {
  stationId: string;
  areaIds?: string[];
  classIds?: string[];
  deviceRunStatus?: string[];
  modelIds?: string[];
  parentDeviceIds?: string[];
  deviceNo?: string;
}
