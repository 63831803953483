import * as _ from 'lodash';

export const subHealthOption = {
    tooltip: {
        trigger: 'axis',
    },
    legend: {
        data: ['阜阳永强新乌江二期', '阜阳', '安徽', '林洋'],
    },
    grid: {
        top: '10%',
        left: '8%',
        right: '8%',
    },
    xAxis: [
        {
            type: 'category',
            boundaryGap: false,
            axisTick: { show: false },
            axisLine: { show: false },
            axisLabel: { color: '#727272' },
            splitLine: { show: true },
            data: [],
        },
    ],
    yAxis: [
        {
            type: 'value',
            show: false,
            scale: true,
        },
    ],
    series: [
        {
            name: '阜阳永强新乌江二期',
            type: 'line',
            symbolSize: 12,
            lineStyle: {
                width: 3,
            },
            smooth: true,
            label: {
                show: false,
            },
            zlevel: 100,
            data: [],
            markPoint: {
                data: [],
            },
        },
        {
            name: '阜阳',
            type: 'line',
            symbolSize: 8,
            lineStyle: { width: 2, type: 'dashed' },
            smooth: true,
            data: [],
        },
        {
            name: '安徽',
            type: 'line',
            symbolSize: 8,
            lineStyle: { width: 2, type: 'dashed' },
            smooth: true,
            data: [],
        },
        {
            name: '林洋',
            type: 'line',
            symbolSize: 8,
            lineStyle: { width: 2, type: 'dashed' },
            smooth: true,
            data: [],
        },
    ],
};

export function buildSubHealthChart() {
    let option: any = _.cloneDeep(subHealthOption);
    var xAxisData = ['发电', '运行', '告警', '通讯', '效率'];
    var datas = [
        [88, 82, 73, 69, 91],
        [78, 88, 83, 88, 90],
        [85, 75, 93, 69, 81],
        [80, 72, 78, 84, 71],
    ];
    option.xAxis[0].data = xAxisData;
    option.series[0].data = datas[0];
    option.series[0].markPoint.data = [
        {
            name: xAxisData[0],
            value: datas[0][0],
            xAxis: xAxisData[0],
            yAxis: datas[0][0],
        },
        {
            name: xAxisData[1],
            value: datas[0][1],
            xAxis: xAxisData[1],
            yAxis: datas[0][1],
        },
        {
            name: xAxisData[2],
            value: datas[0][2],
            xAxis: xAxisData[2],
            yAxis: datas[0][2],
        },
        {
            name: xAxisData[3],
            value: datas[0][3],
            xAxis: xAxisData[3],
            yAxis: datas[0][3],
        },
        {
            name: xAxisData[4],
            value: datas[0][4],
            xAxis: xAxisData[4],
            yAxis: datas[0][4],
        },
    ];
    option.series[1].data = datas[1];
    option.series[2].data = datas[2];
    option.series[3].data = datas[3];
    return option;
}
