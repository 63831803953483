const color = [
  '#37a2da',
  '#32c5e9',
  '#67e0e3',
  '#9fe6b8',
  '#ffdb5c',
  '#ff9f7f',
  '#fb7293',
  '#e062ae',
  '#e690d1',
  '#e7bcf3',
  '#9d96f5',
  '#8378ea',
  '#96bfff',
];
export function buildEnergyDayChart(returnData) {
  return {
    color: [color[0], color[4], color[2], color[6]],
    backgroundColor: 'rgba(255, 255, 255, 0)',
    tooltip: {
      trigger: 'axis',
    },
    grid: {
      top: 35,
      bottom: 28,
    },
    legend: {
      data: ['小时充电电量', '小时放电电量'],
      x: 'center',
      y: 'top',
    },
    xAxis: {
      type: 'category',
      name: '时间',
      boundaryGap: true,
      data: returnData.xAxis.data,
    },
    yAxis: [
      {
        type: 'value',
        name: 'KWh',
        nameTextStyle: {
          fontSize: 10,
        },
      },
    ],
    dataZoom: [
      {
        type: 'inside',
        start: 0,
        end: 100,
      },
    ],
    series: [
      {
        name: '小时充电电量',
        type: 'bar',
        stack: 'energy',
        data: returnData.series[0].data,
      },
      {
        name: '小时放电电量',
        type: 'bar',
        stack: 'energy',
        data: returnData.series[1].data,
      },
    ],
  };
}
