import { AuthHttp } from 'src/app/core/auth-http';
import { AppConfigService } from 'src/app/app.config';
import { Injectable } from '@angular/core';

@Injectable()
export class InvertersService {

  private apiUrl: String;

  private stationInvertersUrl = '/api/v2/monitor/station/inverters';

  constructor(private appconfig: AppConfigService, private http: AuthHttp) {
    this.apiUrl = this.appconfig.apiUrl;
  }

  public getStationInverters(params): any {
    return this.http.get(`${this.apiUrl}${this.stationInvertersUrl}`, {
      params
    }).toPromise()
  }

}
