import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PaginatorModule,
  ButtonModule,
  AutoCompleteModule,
  InputTextModule,
  DropdownModule,
  MultiSelectModule,
  RadioButtonModule,
  TabViewModule,
  DialogModule,
} from 'primeng/primeng';
import { PointSelectorService } from './point-selector.service';
import { PointSelectorComponent } from './point-selector.component';
import { StationPointComponent } from './station-point/station-point.component';
import { DevicePointComponent } from './device-point/device-point.component';
import { AreaPointComponent } from './area-point/area-point.component';
import { SharedModule } from '../../../shared/shared.module';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    AutoCompleteModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    RadioButtonModule,
    TabViewModule,
    DialogModule,
  ],
  declarations: [
    PointSelectorComponent,
    StationPointComponent,
    AreaPointComponent,
    DevicePointComponent,
  ],
  exports: [
    PointSelectorComponent,
    StationPointComponent,
    AreaPointComponent,
    DevicePointComponent,
  ],
  providers: [PointSelectorService],
})
export class PointSelectorModule {}
