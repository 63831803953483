import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthHttp } from '../../../../core/auth-http';
import { AppConfigService } from '../../../../app.config';
import { CommonService } from '../../../../common/services/common.service';
import { tap } from 'rxjs/operators';
import { TipService } from '@common/services/tip.service';

@Injectable()
export class ModelService {
  public apiUrl: string = this.appConfig.apiUrl;
  public isSubmitModel = new Subject<boolean>();

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService,
    private tipservice: TipService
  ) { }

  public getModels(param) {
    return this.http
      .get(`${this.apiUrl}/api/models`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }

  // 型号相似度
  public getModelSimilarity(modelNo, modelId) {
    return this.http
      .get(`${this.apiUrl}/api/models/similars`, {
        search: { modelNo, modelId },
      })
      .toPromise();
  }

  public deleteModel(id) {
    return this.http
      .delete(`${this.apiUrl}/api/models`, {
        search: { modelId: id },
      })
      .pipe(
        tap((res) => {
          this.tipservice.tip('success', '操作成功！');
        })
      )
      .toPromise();
  }

  public addModel(model) {
    return this.http
      .post(`${this.apiUrl}/api/models`, model)
      .pipe(
        tap((res) => {
          this.isSubmitModel.next(true);
        })
      )
      .toPromise();
  }

  public editModel(model) {
    return this.http
      .put(`${this.apiUrl}/api/models`, model)
      .pipe(
        tap((res) => {
          this.isSubmitModel.next(true);
        })
      )
      .toPromise();
  }
}
