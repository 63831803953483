import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { SelectItem } from "primeng/primeng";
import * as _ from "lodash";
import * as moment from "moment";
import { Observable, fromEvent } from "rxjs";

import { Topo, TopoType } from "../topo.model";
import { PowerStation, DeviceArea } from "../../monitor/shared/index.model";

import { TopoMangeService } from "./topo-manage.service";
import { CommonService } from "../../../common/services/common.service";

import { Constants } from "../../../common/Constants";
import { CacheService } from "../../../common/services/cache.service";
import { debounceTime } from "rxjs/operators";
import { TipService } from "@common/services/tip.service";

declare let JTopo;
declare let topoItems;
declare let topoData;

@Component({
  selector: "topo-manage",
  templateUrl: "topo-manage.component.html",
  styleUrls: ["./topo-manage.component.less"]
})
export class TopoManageComponent implements OnInit, OnDestroy {
  public stations: SelectItem[];
  public selectedStation: PowerStation;
  public toposResult;
  public selectedTopos: Topo[];
  public areaList: DeviceArea[] = [];
  public selectedArea: DeviceArea[] = [];
  public stationId: string;

  public areas: SelectItem[];

  public addTopoType: TopoType = TopoType.PCD;

  public isShowAddDialog = false;
  public isShowDeleteConfirm = false;
  public isShowDeleteConfirms = false;

  public rowsPerPageOptions: [10, 30, 50];

  private CANVAS_ID = "device_topo_canvas";

  public scrollHeight: string;
  public resizeEvent: any;
  public selectRowData: any;

  public constructor(
    private router: Router,
    private activateRouter: ActivatedRoute,
    private topoManageService: TopoMangeService,
    private commonService: CommonService,
    private cacheService: CacheService,
    private tipservice: TipService
  ) {
    this.resizeEvent = fromEvent(window, "resize")
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setScrollHeight();
      });
  }

  public ngOnInit() {
    this.commonService.mask.show();
    this.setScrollHeight();
    this.topoManageService.getStations().subscribe(
      stations => {
        this.activateRouter.queryParams.subscribe(params => {
          this.stations = stations
            ? stations.map(station => {
              return {
                label: station.shortName,
                value: station
              };
            })
            : [];

          if (params.stationId) {
            this.stationId = params.stationId;
          } else {
            this.stationId = this.cacheService.getCache(
              Constants.KEY_CURRENT_STATION_ID
            );
          }
          if (this.stationId) {
            this.selectedStation = stations.find(
              station => station.stationId === this.stationId
            );
          } else {
            if (!this.selectedStation && stations && stations[0]) {
              this.selectedStation = stations[0];
            }
            this.changeQueryString();
          }
          this.loadTopoList();
          this.getAllAreas();
        });
      },
      error => {
        this.commonService.errorMessage(error);
      },
      () => {
        this.commonService.mask.hide();
      }
    );
  }

  public setScrollHeight() {
    this.scrollHeight = document.body.clientHeight - 190 - 60 + "px";
  }

  public ngOnDestroy() {
    this.resizeEvent.unsubscribe();
    this.removeElement(this.getDevcieTopoCanvas());
  }

  public addTopo() {
    this.router.navigate(["/topo/editor"], {
      queryParams: { stationId: this.selectedStation.stationId }
    });
  }

  public editTopo() {
    if (this.selectedTopos && this.selectedTopos.length) {
      this.router.navigate(["/topo/editor"], {
        queryParams: {
          stationId: this.selectedStation.stationId,
          topoId: this.selectedTopos[0].topoId
        }
      });
    }
  }

  public rowEditTopo(rowData) {
    this.router.navigate(["/topo/editor"], {
      queryParams: {
        stationId: rowData.stationId,
        topoId: rowData.topoId
      }
    });
  }

  public changeStation() {
    this.changeQueryString();
    this.loadTopoList();
    this.getAllAreas();
  }

  public getAllAreas() {
    this.topoManageService.getAllAreas(this.stationId).subscribe(
      (res: any) => {
        this.areas = res
          ? res.map(area => {
            return {
              label: area.areaName,
              value: area.areaId
            };
          })
          : [];
        this.areas.unshift({ label: "", value: null });
      },
      err => {
        this.tipservice.tip("error", "获取区域失败");
      }
    );
  }

  public changeArea(rowData) {
    this.commonService.mask.show();
    this.topoManageService
      .changeArea(rowData.topoId, rowData.stationId, rowData.areaId)
      .subscribe(
        res => {
          this.tipservice.tip("success", "修改区域成功");
          this.commonService.mask.hide();
        },
        err => {
          this.tipservice.tip("error", "修改区域失败");
          this.commonService.mask.hide();
        }
      );
  }

  public loadTopoList(page?: number, size?: number) {
    if (!this.selectedStation) {
      this.toposResult = null;
      return;
    }
    this.commonService.mask.show();
    this.topoManageService
      .getTopoList(this.selectedStation.stationId, page, size)
      .subscribe(
        res => {
          if (!res) {
            this.toposResult = null;
          }
          this.toposResult = res;
        },
        error => {
          this.commonService.errorMessage(error);
        },
        () => {
          this.commonService.mask.hide();
        }
      );
  }

  public displayUpdateTime(timestamp) {
    if (!timestamp) {
      return "";
    }
    return moment(timestamp).format("YYYY-MM-DD HH:mm:ss");
  }

  public showAddDialog() {
    this.isShowAddDialog = true;
    this.selectedArea = [];
    if (this.selectedStation.stationId) {
      this.topoManageService
        .getStationArea(this.selectedStation.stationId)
        .subscribe((res: any) => {
          this.areaList = res;
        });
    }
  }

  public gotoAddPage() {
    if (this.addTopoType === TopoType.PCD) {
      this.addTopo();
    } else {
      this.topoManageService
        .getDeviceTopoByAreaIds(
          this.selectedArea.map(item => item.areaId)
        )
        .subscribe(
          res => {
            let deviceTopos = this.loadDeviceTopo(res);
            // 如果是空对象不进行创建
            if (!deviceTopos || _.isEmpty(deviceTopos)) {
              return;
            }
            this.saveDeviceTopos(deviceTopos);
          },
          () => {
            this.tipservice.tip("error", "生成设备拓扑图失败。");
          }
        );
    }
  }

  public isAddDeviceTopo() {
    return this.addTopoType === TopoType.DTP;
  }

  public deleteConfirm(rowData) {
    this.isShowDeleteConfirm = true;
    if (rowData) {
      this.selectRowData = rowData;
    }
  }

  public deleteConfirms() {
    if (this.selectedTopos && this.selectedTopos.length) {
      this.isShowDeleteConfirms = true;
    } else {
      this.tipservice.tip("warn", "请最少选择一条数据！");
      return false;
    }
  }

  public deleteTopos() {
    if (this.selectedTopos && this.selectedTopos.length) {
      this.topoManageService
        .deleteTopo(this.selectedTopos.map((item) => item.topoId))
        .subscribe((res) => {
          this.tipservice.tip('success', '删除设备拓扑图成功。');
          this.isShowDeleteConfirms = false;
          this.loadTopoList();
        },
          () => { this.tipservice.tip('error', '删除设备拓扑图出现错误。'); }
        );
    }
  }

  public deleteTopo() {
    // if(this.selectedTopos && this.selectedTopos.length){
    //   this.topoManageService
    //   .deleteTopo(this.selectedTopos.map((item) => item.topoId))
    //   .subscribe((res) => {
    //     this.tipservice.tip('success', '删除设备拓扑图成功。');
    //     this.isShowDeleteConfirm = false;
    //     this.loadTopoList();
    //   },
    //     () => { this.tipservice.tip('error', '删除设备拓扑图出现错误。'); }
    //   );
    // }else{
    this.topoManageService.deleteTopo(this.selectRowData.topoId).subscribe(
      res => {
        this.tipservice.tip("success", "删除设备拓扑图成功。");
        this.isShowDeleteConfirm = false;
        this.loadTopoList();
      },
      () => {
        this.tipservice.tip("error", "删除设备拓扑图出现错误。");
      }
    );
    // }
  }

  public onPageChange(pager) {
    this.loadTopoList(pager.page + 1, pager.row);
  }

  private loadDeviceTopo(deviceData) {
    let canvas = this.getDevcieTopoCanvas();
    if (!canvas) {
      canvas = this.createHiddenCanvas();
    }
    let drawView = new JTopo.DrawView(canvas);
    let result = {};
    for (let areaId in deviceData) {
      if (!deviceData.hasOwnProperty(areaId)) {
        continue;
      }
      drawView.loadJson(deviceData[areaId]);
      let devices = deviceData[areaId].devices;
      if (devices && devices.length) {
        this.setDeviceNodePoints(drawView, devices);
      }

      result[areaId] = drawView.getJson();
    }
    return result;
  }

  private setDeviceNodePoints(drawView, devices) {
    devices.forEach(device => {
      if (
        !device ||
        device.deviceType === "VISUAL_ZC" ||
        device.deviceType === "ZC"
      ) {
        return;
      }

      let node = drawView.getNodeByDeviceId(device.deviceId);
      if (node && device.idxCodelist && device.idxCodelist.length) {
        device.idxCodelist.forEach(point => {
          node.setObjPointInfo(point.pointId, null, point.pointName);
        });
      }

      if (device.children && device.children.length) {
        this.setDeviceNodePoints(drawView, device.children);
      }
    });
  }

  /**
   * 保存页面自动生成的方阵设备拓扑图
   *
   * @private
   * @param {any} deviceTopos key: areaId, value: topoDesc
   * @memberof TopoManageComponent
   */
  private saveDeviceTopos(deviceTopos) {
    this.commonService.mask.show();
    this.topoManageService.saveDeviceTopos(deviceTopos).subscribe(
      res => {
        this.tipservice.tip("success", "保存设备拓扑图成功。");
        this.isShowAddDialog = false;
        this.selectedArea = [];
        this.loadTopoList();
      },
      () => {
        this.tipservice.tip("error", "保存设备拓扑图出现错误。");
      },
      () => {
        this.commonService.mask.hide();
      }
    );
  }

  private clearDevcieTopoCanvas() {
    let canvas = this.getDevcieTopoCanvas();
    if (!canvas) {
      return;
    }
    canvas.getContext("2d").clearRect(0, 0, canvas.width, canvas.height);
  }

  private getDevcieTopoCanvas(): HTMLCanvasElement {
    return <HTMLCanvasElement>document.getElementById(this.CANVAS_ID);
  }

  private createHiddenCanvas() {
    let canvas = document.createElement("canvas");
    canvas.id = this.CANVAS_ID;
    // TODO liqian 是否可以不需要设置wh
    canvas.width = 1024;
    canvas.height = 768;
    canvas.style.display = "none";
    return canvas;
  }

  private removeElement(node) {
    return node && node.remove && node.remove();
  }

  /**
   * 将组串类型的设备子设备删除
   */
  private handleDeviceTopo(deviceTopo) {
    if (!deviceTopo) {
      return deviceTopo;
    }
    for (let areaId in deviceTopo) {
      if (deviceTopo.hasOwnProperty(areaId)) {
        this.hideZCDeivceChildren(deviceTopo[areaId]);
      }
    }
    return deviceTopo;
  }

  private hideZCDeivceChildren(root) {
    if (!root || !_.isArray(root)) {
      return;
    }
    root.forEach(item => {
      if (item.deviceType === "ZC") {
        item.children = [];
      } else {
        if (item.children && item.children.length) {
          this.hideZCDeivceChildren(item);
        }
      }
    });
  }

  private changeQueryString() {
    this.router.navigate(["/topo/manage"], {
      queryParams: { stationId: this.selectedStation.stationId },
      queryParamsHandling: "merge"
    });
  }
}
