import { TipService } from '@common/services/tip.service';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Constants } from 'src/app/common/Constants';
import { NavService } from 'src/app/components/layout/nav/nav.service';
import { AppConfigService } from './../../app.config';
import { CacheService } from './../../common/services/cache.service';
import { CommonService } from './../../common/services/common.service';
import { AuthService } from './../../core/auth.service';
import { LoginService } from './login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less'],
})
export class LoginComponent implements OnInit {
  public username = '';
  public password = '';

  //
  title: string;
  // 登录页 背景图
  backgroundImage: string;

  // 网站备案信息
  info: SafeHtml;

  // logo信息
  logo: any;

  // qrCodes
  qrCodes: any[];
  showLoading = false;
  loginContentHeight: number;
  version: any;
  align: any;

  isLogin = true;

  inputType = 'password'

  constructor(
    private authservice: AuthService,
    private router: Router,
    private common: CommonService,
    private loginservice: LoginService,
    private appConfig: AppConfigService,
    private titleservice: Title,
    private sanitizer: DomSanitizer,
    private navservice: NavService,
    private cacheService: CacheService,
    private activatedroute: ActivatedRoute,
    private tipservice: TipService
  ) {
    this.activatedroute.queryParams.subscribe((res) => {
      if (res.username && res.password) {
        this.showLoading = true;
        this.username = res.username;
        this.password = res.password
        this.login();
      }
      if (res.username && res.cipherpwd) {
        this.showLoading = true;
        this.autoLogin(res.username, res.cipherpwd.replaceAll(' ', '+'));
      }
    });
  }

  ngOnInit() {
    // 用户登录页面信息 先从浏览器缓存中获取，如果没有，则从config.js文件中获取
    const login = localStorage.getItem('pageConfigs')
      ? JSON.parse(localStorage.getItem('pageConfigs'))
      : this.appConfig.login;
    this.title = login.title;

    const backgrounds = login.backgrounds || [];

    const backgroundsIndex = Math.floor(Math.random() * backgrounds.length);
    this.backgroundImage = backgrounds[backgroundsIndex];

    this.logo = login.logo;

    this.titleservice.setTitle(this.title);

    this.align = login.align || 'right';

    this.qrCodes = login.qrCodes;
    if (_.isEmpty(this.qrCodes)) {
      this.loginContentHeight = 480;
    } else {
      this.loginContentHeight = 600;
    }

    this.version = login.version || null;

    this.info = this.sanitizer.bypassSecurityTrustHtml(login.info);
  }

  public login() {
    if (!this.username) {
      return this.tipservice.tip('warn', '请输入用户名');
    }
    if (!this.password) {
      return this.tipservice.tip('warn', '请输入密码');
    }
    let username = this.username;
    let password = this.password;
    this.authservice.login(username, password).then((res) => {
      this.loginservice.getPageConfigs().subscribe((pageConfigsRes: any) => {
        if (pageConfigsRes == null) {
          localStorage.removeItem('pageConfigs');
        } else {
          localStorage.setItem('pageConfigs', pageConfigsRes);
        }
      });

      this.navservice.getInit('homePage').subscribe((res: any) => {
        this.navservice.getInit().subscribe((stationInfo: any) => {
          const stationId = this.cacheService.getCache(
            Constants.KEY_CURRENT_STATION_ID
          );
          if (!stationId) {
            this.cacheService.setCache(
              Constants.KEY_CURRENT_STATION_ID,
              stationInfo.defaultStationId,
              true
            );
          }

          if (res.homePage.url) {
            if (localStorage.getItem('homepage') !== res.homePage.url) {
              localStorage.setItem('homepage', res.homePage.url);
            }
          } else {
            // localStorage.setItem("homepage", "/overview");
            // 用户首次登录没有设置首页的话， 登录成功之后看到的是系统默认设置的首页（监盘中心）
            localStorage.setItem('homepage', '/yxjs');
          }

          const homeUrl = localStorage.getItem('homepage');
          // if (homeUrl.indexOf("?")) {
          if (homeUrl.indexOf('?') !== Number(-1)) {
            const baseUrl = homeUrl.substring(0, homeUrl.indexOf('?'));
            const paramUrl = homeUrl.substring(
              homeUrl.indexOf('?') + 1,
              homeUrl.length
            );
            const params = paramUrl.split('&');
            const paramMap = {};
            params.forEach((param) => {
              const paramTmp = param.split('=');
              paramMap[paramTmp[0]] = paramTmp[1];
            });

            this.router.navigate([baseUrl], {
              queryParams: paramMap,
            });
          } else {
            this.router.navigate([homeUrl]);
          }
        });
      });
    });
  }

  public autoLogin(username, cipherpwd) {
    if (username == '') {
      return this.tipservice.tip('warn', '请输入用户名');
    }
    if (cipherpwd == '') {
      return this.tipservice.tip('warn', '密码未加密');
    }
    this.authservice.autoLogin(username, cipherpwd).then((res) => {
      this.loginservice.getPageConfigs().subscribe((pageConfigsRes: any) => {
        if (pageConfigsRes == null) {
          localStorage.removeItem('pageConfigs');
        } else {
          localStorage.setItem('pageConfigs', pageConfigsRes);
        }
      });

      this.navservice.getInit('homePage').subscribe((res: any) => {
        this.navservice.getInit().subscribe((stationInfo: any) => {
          const stationId = this.cacheService.getCache(
            Constants.KEY_CURRENT_STATION_ID
          );
          if (!stationId) {
            this.cacheService.setCache(
              Constants.KEY_CURRENT_STATION_ID,
              stationInfo.defaultStationId,
              true
            );
          }

          if (res.homePage.url) {
            if (localStorage.getItem('homepage') !== res.homePage.url) {
              localStorage.setItem('homepage', res.homePage.url);
            }
          } else {
            // localStorage.setItem("homepage", "/overview");
            // 用户首次登录没有设置首页的话， 登录成功之后看到的是系统默认设置的首页（监盘中心）
            localStorage.setItem('homepage', '/yxjs');
          }

          const homeUrl = localStorage.getItem('homepage');
          // if (homeUrl.indexOf("?")) {
          if (homeUrl.indexOf('?') !== Number(-1)) {
            const baseUrl = homeUrl.substring(0, homeUrl.indexOf('?'));
            const paramUrl = homeUrl.substring(
              homeUrl.indexOf('?') + 1,
              homeUrl.length
            );
            const params = paramUrl.split('&');
            const paramMap = {};
            params.forEach((param) => {
              const paramTmp = param.split('=');
              paramMap[paramTmp[0]] = paramTmp[1];
            });

            this.router.navigate([baseUrl], {
              queryParams: paramMap,
            });
          } else {
            this.router.navigate([homeUrl]);
          }
        });
      });
    });
  }
}
