import { Injectable } from '@angular/core';
import { CommonService } from '@common/services/common.service';
import { AuthHttp } from '@core/auth-http';
import { AppConfigService } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class VideoPlayDialogService {
  apiUrl: string;

  constructor(private http: AuthHttp, private appconfig: AppConfigService, private common: CommonService) {
    this.apiUrl = this.appconfig.apiUrl
  }

  public getCameras(params) {
    return this.http.get(`${this.apiUrl}/api/cameras`, {
      params
    }).toPromise()
  }

  public getAccessToken() {
    return this.http.get(`${this.apiUrl}/api/cameras/devices/token/ys7`).toPromise()
  }

  public getCamerasLiveAddress(params) {
    return this.http.get(`${this.apiUrl}/api/cameras/live/address`, { params }).toPromise()
  }
}
