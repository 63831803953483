import * as _ from "lodash";

export const totalHealthOption = {
    backgroundColor: "rgba(255, 255, 255, 0)",
    grid: { top: 0, buttom: 0, left: 0, right: 0 },
    series: [
        {
            type: "liquidFill",
            radius: "70%",
            center: ["50%", "50%"],
            data: [0, 0, 0],
            color: ["#67E0E3", "#32C5E9", "#37A2DA"],
            itemStyle: {
                opacity: 1,
                shadowBlur: 0
            },
            emphasis: {
                itemStyle: {
                    opacity: 1
                }
            },
            backgroundStyle: {
                borderWidth: 3,
                borderColor: "#37A2DA",
                color: "#fff"
            },
            outline: {
                show: false
            },
            label: {
                formatter: Math.round(0 * 100.0) + "",
                fontSize: 72
            }
        }
    ]
};

export function buildTotalHealthChart(data) {
    let option: any = _.cloneDeep(totalHealthOption);
    option.series[0].data = [data, data, data];
    option.series[0].label.formatter = Math.round(data * 100.0) + "";
    return option;
}
