import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import * as _ from 'lodash';

@Component({
    selector: 'app-cc-users',
    templateUrl: './cc-users.component.html',
    styleUrls: ['./cc-users.component.less'],
})
export class CcUsersComponent implements OnInit {
    @Input() users = [];
    @Input() ccPermission = 2;
    @Output() public userEvent = new EventEmitter<any>();

    isShowUserDialog = false;

    constructor() {}

    ngOnInit() {}

    personChange(event) {
        this.users = event.map((item) => {
            return {
                userName: item.realName,
                userId: item.userId,
                userIcon: item.attr3,
            };
        });
        this.userEvent.emit(this.users);
    }

    deleteUser(userId) {
        _.remove(this.users, (o) => o.userId === userId);
        this.userEvent.emit(this.users);
    }
}
