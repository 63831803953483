import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TeamSelectorComponent } from './team-selector.component';
import {
  PaginatorModule,
  InputTextModule,
  ConfirmDialogModule,
  ScrollPanelModule,
  ConfirmationService,
} from 'primeng/primeng';
import { DeptSelectorModule } from 'src/app/common/components/dept-selector/dept-selector.module';
import { UserSelectorModule } from 'src/app/common/components/user-selector/user-selector.module';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { TreeModule } from 'primeng/tree';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { TeamService } from '../../team.service';
import { SharedModule } from 'src/app/shared/shared.module';

@NgModule({
  declarations: [TeamSelectorComponent],
  imports: [
    SharedModule,
    PaginatorModule,
    DeptSelectorModule,
    UserSelectorModule,
    InputTextModule,
    ButtonModule,
    TableModule,
    DialogModule,
    ConfirmDialogModule,
    ScrollPanelModule,
    TreeModule,
  ],
  providers: [CalendarLocales, TeamService, ConfirmationService],
  exports: [TeamSelectorComponent],
})
export class TeamSelectorModule {}
