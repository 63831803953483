import { Component, OnChanges, SimpleChanges, Input, Output, EventEmitter } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'mpt-object-selector',
  templateUrl: './object-selector.component.html',
})
export class ObjectSelectorComponent implements OnChanges {
  @Input() public isOpen = false;
  @Input() public isRadio = false;
  @Input() public stationId: string;
  @Input() public deviceType: string;
  @Input() public selected: any[] = [];
  @Input() public objectOrder: string[] = ['d', 's', 'a'];
  @Output() public openChange = new EventEmitter<boolean>();
  @Output() public dataChange = new EventEmitter<any>();
  @Input() public defaultStationId: string;
  @Input() public selectAllFlag: boolean;
  @Output() public selectAllFlagEvent = new EventEmitter<boolean>();

  public queryIndex = 0;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.selected) {
      this.selected = _.concat([], this.selected);
    }
  }

  public onShow() {
    this.queryIndex++;
  }

  // 取消
  public cancelData() {
    this.openChange.emit(false);
  }

  // 打开窗口
  public isShowObjectSelector(event) {
    this.openChange.emit(event);
  }

  // 提交数据
  public submitData(event) {
    this.dataChange.emit(event);
  }

  public selectAllFlagFn(event) {
    this.selectAllFlagEvent.emit(event)
  }
}
