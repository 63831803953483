import { Injectable } from "@angular/core";
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
    Router
} from "@angular/router";
import { Constants } from "../Constants";
import { NavService } from "../../components/layout/nav/nav.service";
import { Menu } from "../../components/layout/nav/nav.model";

@Injectable()
export class DefaultRouteGuard implements CanActivate {
    public constructor(
        private router: Router,
        private navService: NavService
    ) {}

    public canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        this.navService.getNavData("menu").subscribe(nav => {
            if (!nav[0].children || !nav[0].children.length) {
                const menu: Menu = this.navService.getFirstAccessableMenu(nav);
                this.router.navigateByUrl(menu.children[0].url);
                return false;
            }
        });
        return true;
    }
}
