import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';

const imgReady = (() => {
  const list = [];
  let intervalId = null;
  // 用来执行队列
  const tick = () => {
    let i = 0;
    for (; i < list.length; i++) {
      list[i].end ? list.splice(i--, 1) : list[i]();
    }
    !list.length && stop();
  };
  // 停止所有定时器队列
  const stop = () => {
    clearInterval(intervalId);
    intervalId = null;
  };

  return (url, ready, load?, error?) => {
    let onready;
    let width;
    let height;
    let newWidth;
    let newHeight;
    let img = new Image();

    img.src = url;

    // 如果图片被缓存，则直接返回缓存数据
    if (img.complete) {
      ready.call(img);
      load && load.call(img);
      return;
    }

    width = img.width;
    height = img.height;

    // 加载错误后的事件
    img.onerror = () => {
      error && error.call(img);
      onready.end = true;
      img = img.onload = img.onerror = null;
    };

    // 图片尺寸就绪
    onready = () => {
      newWidth = img.width;
      newHeight = img.height;
      if (
        newWidth !== width ||
        newHeight !== height ||
        // 如果图片已经在其他地方加载可使用面积检测
        newWidth * newHeight > 1024
      ) {
        ready.call(img);
        onready.end = true;
      }
    };
    onready();

    // 完全加载完毕的事件
    img.onload = () => {
      // onload在定时器时间差范围内可能比onready快
      // 这里进行检查并保证onready优先执行
      !onready.end && onready();

      load && load.call(img);

      // IE gif动画会循环执行onload，置空onload即可
      img = img.onload = img.onerror = null;
    };

    // 加入队列中定期执行
    if (!onready.end) {
      list.push(onready);
      // 无论何时只允许出现一个定时器，减少浏览器性能损耗
      if (intervalId === null) intervalId = setInterval(tick, 40);
    }
  };
})();

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.less'],
})
export class ViewerComponent implements OnInit, OnChanges {
  @Input() showView = false;
  @Input() imgSrc = null;
  @Output() closeViewEvent = new EventEmitter();

  direction = 'horizontal';
  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.imgSrc && changes.imgSrc.currentValue) {
      const that: any = this;
      imgReady(changes.imgSrc.currentValue, function () {
        if (this.width > this.height) {
          that.direction = 'horizontal';
        } else {
          that.direction = 'portrait';
        }
      });
    }
  }

  close(event) {
    this.imgSrc = null;
    this.showView = false;
    this.closeViewEvent.emit(false);
  }
}
