/**
 * feature: 设备筛选列表
 * datetime: 20180125
 */
import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";
import { SelectItem, ConfirmationService } from "primeng/primeng";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { Observable } from "rxjs";
import * as _ from "lodash";

import { CodeTableService } from "../../../core/code-table.service";
import { CodeTable } from "../../models/codeTable.model";
import { DeptPicklistService } from "./dept-picklist.service";
import { CommonService } from "../../services/common.service";
import { DeptPick, Rela } from "./dept-picklist.model";
import { StationsService } from "../../services/stations.service";
import { ClassesType } from "../../models/classes-type.model";

@Component({
    selector: "mpt-dept-picklist",
    templateUrl: "dept-picklist.component.html",
    styleUrls: ["./dept-picklist.component.less"]
})
export class DeptPickListComponent implements OnInit {
    @Input() public visible: boolean = false;
    @Input() public param = { stationIds: null };
    @Output() public hide = new EventEmitter();

    public AllDevice: DeptPick[];

    public selectedDevice: DeptPick[];

    public classesType = ClassesType.deviceType; // 设备类别

    // 电站列表
    public stations: SelectItem[] = []; // 电站
    // 区域列表
    public leftAreas: SelectItem[] = []; // 区域
    public rightAreas: SelectItem[] = []; // 区域

    // 设置电站不可选
    public stationIdDisabled: boolean = false;

    // 已选设备：电站
    public selectedStationIds = [];

    // 已选设备：区域
    public selectedAreas = [];

    // 已选设备：设备类别
    public selectedClassIds = [];

    // 已选设备：设备名称
    public selectedDeviceNo = "";

    public selectedLoading = false;

    // 待选设备：电站
    public unSelectedStationIds = [];

    // 待选设备：区域
    public unSelectedAreas = [];

    // 待选设备：设备类别
    public unSelectedClassIds = [];

    // 待选设备：设备名称
    public unSelectedDeviceNo = "";

    public unSelectedLoading = false;

    public selectAllBox: DeptPick[] = [];
    public selectChecks: DeptPick[] = [];
    public relas: Rela[] = [];
    public rela: Rela = {};
    public transitionDept: DeptPick[] = [];
    public relaIds: number[] = [];
    public deviceIds: string[] = [];
    public groupId: string;

    // 接口传参
    private queryParam = {
        stationIds: null,
        areaIds: null,
        classIds: null,
        deviceNoOrName: null,
        groupId: null
    };
    private queryDevice = {
        groupIds: null,
        deviceIds: null
    };
    public unselectedPager = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };
    public selectedPager = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };

    public isResetParam: boolean = false;
    constructor(
        private fb: FormBuilder,
        private codeTableService: CodeTableService,
        private deptPicklistService: DeptPicklistService,
        private common: CommonService,
        private confirmationService: ConfirmationService,
        private stationsService: StationsService
    ) {}

    public ngOnInit() {}

    // 分页事件
    public onUnselectedPageChange(event) {
        this.unselectedPager.pageCurrent = event.page;
        this.unselectedPager.pageSize = parseFloat(event.rows);
        this.queryUnselectedDevices();
    }

    // 分页事件
    public onSelectedPageChange(event) {
        this.selectedPager.pageCurrent = event.page;
        this.selectedPager.pageSize = parseFloat(event.rows);
        this.querySelectedDevices();
    }

    // 已选设备
    public querySelectedDevices() {
        this.selectedLoading = true;
        const param = _.assign({}, this.queryParam, {
            stationIds: this.selectedStationIds,
            areaIds: this.selectedAreas,
            classIds: this.selectedClassIds,
            deviceNoOrName: this.selectedDeviceNo,
            groupId: this.groupId
        });
        this.deptPicklistService
            .getDevicesByGroupId(
                param,
                this.selectedPager.pageCurrent,
                this.selectedPager.pageSize
            )
            .then((res: any) => {
                this.selectedPager.totalRecords = res.totalElements;
                this.selectedDevice = res.content;
                for (let i = 0, len = res.content.length; i < len; i++) {
                    this.selectedDevice[i]["index"] = i + 1;
                }
                this.selectChecks = [];
                this.selectedLoading = false;
            })
            .catch(error => {
                this.common.errorMessage(error);
            });
    }

    // 待选设备
    public queryUnselectedDevices() {
        this.unSelectedLoading = true;
        const param = _.assign({}, this.queryParam, {
            stationIds: this.unSelectedStationIds,
            areaIds: this.unSelectedAreas,
            classIds: this.unSelectedClassIds,
            deviceNoOrName: this.unSelectedDeviceNo,
            groupId: this.groupId
        });
        this.deptPicklistService
            .getAllDevices(
                param,
                this.unselectedPager.pageCurrent,
                this.unselectedPager.pageSize
            )
            .then((res: any) => {
                this.unselectedPager.totalRecords = res.totalElements;
                this.AllDevice = res.content;
                for (let i = 0, len = res.content.length; i < len; i++) {
                    this.AllDevice[i]["index"] = i + 1;
                }
                this.unSelectedLoading = false;
                this.selectAllBox = [];
            })
            .catch(error => {
                this.common.errorMessage(error);
            });
    }

    public addDevice() {
        this.relas = [];
        this.selectAllBox.forEach(item => {
            this.rela = {};
            this.rela.deviceId = item.deviceId;
            this.rela.groupId = this.groupId;
            this.relas.push(this.rela);
        });
        this.deptPicklistService.addDeviceInGroup(this.relas).then(res => {
            this.queryUnselectedDevices();
            this.querySelectedDevices();
        });
        this.selectAllBox = null;
    }

    public removeDevice() {
        this.selectChecks.forEach(item => {
            this.deviceIds.push(item.deviceId);
        });
        const param = _.assign({}, this.queryDevice, {
            groupIds: this.groupId,
            deviceIds: this.deviceIds
        });
        this.deptPicklistService.removeDeviceInGroup(param).then(res => {
            this.queryUnselectedDevices();
            this.querySelectedDevices();
        });
        this.selectChecks = null;
        this.deviceIds = [];
    }

    public addAllDevice() {
        this.relas = [];
        this.AllDevice.forEach(item => {
            this.rela = {};
            this.rela.deviceId = item.deviceId;
            this.rela.groupId = this.groupId;
            this.relas.push(this.rela);
        });
        this.deptPicklistService.addDeviceInGroup(this.relas).then(res => {
            this.queryUnselectedDevices();
            this.querySelectedDevices();
        });
    }

    public removeAllDevice() {
        this.selectedDevice.forEach(item => {
            this.deviceIds.push(item.deviceId);
        });
        const param = _.assign({}, this.queryDevice, {
            groupIds: this.groupId,
            deviceIds: this.deviceIds
        });
        this.deptPicklistService.removeDeviceInGroup(param).then(res => {
            this.queryUnselectedDevices();
            this.querySelectedDevices();
        });
        this.deviceIds = [];
    }

    private show() {
        this.visible = true;
    }

    private getDevciesByGroupId(
        groupId: string,
        stationId: string,
        stationName: string
    ) {
        this.AllDevice = [];
        this.selectedDevice = [];
        this.stations = [];
        this.selectedStationIds = [];
        this.unSelectedStationIds = [];
        this.stationIdDisabled = false;
        this.leftAreas = [];
        this.rightAreas = [];
        this.selectedAreas = [];
        this.unSelectedAreas = [];
        if (stationId === "00000") {
            this.stationsService.getStations().subscribe((res: any) => {
                if (res) {
                    res.forEach(station => {
                        this.stations.push({
                            label: station.shortName,
                            value: station.stationId
                        });
                    });
                }
            });
        } else {
            this.stations.push({ label: stationName, value: stationId });
            this.selectedStationIds = [stationId];
            this.unSelectedStationIds = [stationId];
            this.stationIdDisabled = true;
            this.stationsService
                .getAreasByStationId(stationId)
                .subscribe((res: any) => {
                    if (res) {
                        res.forEach(area => {
                            this.leftAreas.push({
                                label: area.areaName,
                                value: area.areaId
                            });
                            this.rightAreas.push({
                                label: area.areaName,
                                value: area.areaId
                            });
                        });
                    }
                });
        }

        this.groupId = groupId;
        this.querySelectedDevices();
        this.queryUnselectedDevices();
        this.show();
    }

    // 获取已选设备区域列表
    public getSelectedAreas(e) {
        this.leftAreas = [];
        console.log(this.selectedStationIds);

        if (this.selectedStationIds.length == 1) {
            this.stationsService
                .getAreasByStationId(this.selectedStationIds[0])
                .subscribe((res: any) => {
                    if (res) {
                        res.forEach(area => {
                            this.leftAreas.push({
                                label: area.areaName,
                                value: area.areaId
                            });
                        });
                    }
                });
        }
    }

    public getUnselectedAreas(e) {
        this.rightAreas = [];

        if (this.unSelectedStationIds.length == 1) {
            this.stationsService
                .getAreasByStationId(this.unSelectedStationIds[0])
                .subscribe((res: any) => {
                    if (res) {
                        res.forEach(area => {
                            this.rightAreas.push({
                                label: area.areaName,
                                value: area.areaId
                            });
                        });
                    }
                });
        }
    }

    // 已选设备：获取classid
    public getSelectedClassIds(val) {
        this.selectedClassIds = val;
    }

    // 待选设备：获取classid
    public getUnselectedClassIds(val) {
        this.unSelectedClassIds = val;
    }

    public onHide() {
        this.visible = false;
        this.hide.emit();
    }
}
