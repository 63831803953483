import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";

import {
    TreeModule,
    SharedModule as primengSharedModule
} from "primeng/primeng";

import { DeviceTreeComponent } from "./device-tree.component";

import { DeviceTreeService } from "./device-tree.service";

@NgModule({
    imports: [CommonModule, TreeModule, primengSharedModule],
    exports: [DeviceTreeComponent],
    declarations: [DeviceTreeComponent],
    providers: [DeviceTreeService]
})
export class DeviceTreeModule {}
