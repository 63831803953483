export interface Topo {
  topoId: number;
}

export enum TopoType {
  PCD = '01', // 一次接线图
  DTP = '02' // 设备拓扑图
}

export enum TopoItemType {
  PCD = '01', // 一次接线图图元
  DTP = '02' // 设备拓扑图图元
}
export enum TopoItemStatusType {
  ENABLE = '0', // 失效
  DISABLE = '1' // 有效
}

interface GluePoint {
  rx: number;
  ry: number;
  dirAngle: number;
}

export interface TopoItem {
  itemId: number;
  itemName: string;
  itemType: string;
  itemSubtype: string;
  itemDesc: string;
  classId: string;
  modelId: string;
  itemStatus: string;
  topologyItemStatuses: TopoItemStatus[];
}

export interface TopoItemStatus {
  itemStatusId: number;
  itemId: number;
  statusName: string;
  floorValue: string;
  ceilValue: string;
  imgUri: string;
  interval: number;
  imgCss: string;
}
