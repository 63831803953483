export function buildDeviceOnlineRateChart(data) {
    data = [
        {
            name: "阜阳金种子6.16896MW光伏电站",
            value: [115.849146, 32.889156],
            stationType: "水泥屋顶"
        },
        {
            name: "安徽界首蓝天0.924MW屋顶分布式光伏电站",
            value: [115.466991, 33.247196],
            stationType: "水泥屋顶"
        },
        {
            name: "海门灵树",
            value: [121.4474, 32.069315],
            stationType: "农光互补"
        },
        {
            name: "连云港伊山二期",
            value: [119.245959, 34.3015],
            stationType: "农光互补"
        },
        {
            name: "启东合作",
            value: [121.637028, 31.926345],
            stationType: "农光互补"
        },
        {
            name: "合肥宝业",
            value: [117.240116, 31.808623],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥金诺",
            value: [117.269661, 31.837868],
            stationType: "彩钢屋顶"
        },
        {
            name: "阜阳京九丝绸",
            value: [115.829345, 32.898245],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥润嘉",
            value: [117.298406, 31.828051],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥凯盛",
            value: [117.357838, 31.781652],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥桥柜",
            value: [117.429631, 31.889512],
            stationType: "彩钢屋顶"
        },
        {
            name: "邳州大诚",
            value: [117.50463, 34.405543],
            stationType: "渔光互补"
        },
        {
            name: "兴化钓鱼",
            value: [119.853781, 32.963153],
            stationType: "渔光互补"
        },
        {
            name: "张庄华博",
            value: [115.46837, 36.483281],
            stationType: "农光互补"
        },
        {
            name: "合肥强建",
            value: [117.287, 31.946],
            stationType: "彩钢+水泥"
        },
        {
            name: "南通乐歌",
            value: [121.007375, 31.857338],
            stationType: "水泥屋顶"
        },
        {
            name: "启东胜狮二期",
            value: [123.933992, 30.760809],
            stationType: "彩钢屋顶"
        },
        {
            name: "启东中盛机电",
            value: [121.877701, 31.921345],
            stationType: "水泥屋顶"
        },
        {
            name: "灌南飞展三期",
            value: [115.878888, 33.033457],
            stationType: "渔光互补"
        },
        {
            name: "城发华耀二期",
            value: [116.297, 37.509],
            stationType: "农光互补"
        },
        {
            name: "安徽亳州40MW光伏电站",
            value: [115.783169, 33.849388],
            stationType: "山地电站"
        },
        {
            name: "河北衡水7.2MW光伏电站",
            value: [115.561014, 37.726043],
            stationType: "山地电站"
        },
        {
            name: "扬州信息产业园1MW屋顶分布式光伏电站",
            value: [119.5116, 32.395069],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥东维0.5MW屋顶分布式光伏电站",
            value: [117.228806, 31.719746],
            stationType: "水泥屋顶"
        },
        {
            name: "安徽阜阳168KW屋顶分布式光伏电站",
            value: [115.981392, 32.925128],
            stationType: "山地电站"
        },
        {
            name: "安徽亳州华阳",
            value: [115.913536, 33.68733],
            stationType: "农光互补"
        },
        {
            name: "宿州经开区44.02684MW农光互补分布式光伏发电项目",
            value: [116.977577, 33.60714],
            stationType: "农光互补"
        },
        {
            name: "阜阳永强新乌江二期",
            value: [115.851753, 32.891605],
            stationType: "农光互补"
        },
        {
            name: "灌南飞展二期",
            value: [115.878888, 33.033457],
            stationType: "农光互补"
        },
        {
            name: "后寨永创二期",
            value: [116.582684, 37.34141],
            stationType: "农光互补"
        },
        {
            name: "东营力诺",
            value: [118.312595, 37.906691],
            stationType: "农光互补"
        },
        {
            name: "阜阳永强李桥",
            value: [115.823026, 32.890527],
            stationType: "农光互补"
        },
        {
            name: "安徽灵璧40.10292MWp地面分布式光伏电站",
            value: [117.534294, 33.82207],
            stationType: "山地电站"
        },
        {
            name: "安徽萧县20MWp地面分布式光伏电站",
            value: [117.086415, 34.143958],
            stationType: "山地电站"
        },
        {
            name: "启东华泰1MW屋顶光伏电站",
            value: [121.619447, 31.824145],
            stationType: "水泥屋顶"
        },
        {
            name: "启东滨海园区鱼塘15MW光伏电站",
            value: [121.870979, 31.898399],
            stationType: "渔光互补"
        },
        {
            name: "城发华耀",
            value: [116.313582, 37.518698],
            stationType: "农光互补"
        },
        {
            name: "夏津力诺二期",
            value: [116.1511, 37.054587],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳颍上耿棚",
            value: [116.121541, 32.647993],
            stationType: "渔光互补"
        },
        {
            name: "启东南阳4.8MW农光互补光伏电站",
            value: [121.723259, 31.881728],
            stationType: "农光互补"
        },
        {
            name: "辽宁乾丰专用车7MW屋顶光伏发电项目",
            value: [123.680107, 42.178133],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港伊山",
            value: [119.291198, 34.590419],
            stationType: "农光互补"
        },
        {
            name: "连云港朝阳电站",
            value: [119.307159, 34.683782],
            stationType: "农光互补"
        },
        {
            name: "后寨永创一期",
            value: [116.582684, 37.34141],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳伍明",
            value: [115.878888, 33.033457],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳行流",
            value: [115.723941, 33.049921],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳新乌江一期",
            value: [116.166687, 32.869704],
            stationType: "农光互补"
        },
        {
            name: "启东胜狮6MW屋顶分布式光伏电站",
            value: [121.702208, 31.792931],
            stationType: "彩钢屋顶"
        },
        {
            name: "扬州奔多4.979MW屋顶分布式光伏电站",
            value: [119.504115, 32.367086],
            stationType: "彩钢屋顶"
        },
        {
            name: "灵璧明升10MW农光互补光伏电站",
            value: [117.497055, 33.675952],
            stationType: "农光互补"
        },
        {
            name: "安徽萧县华耀20MW农光互补光伏电站",
            value: [116.771111, 34.135592],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳新盛电站",
            value: [116.948536, 34.164347],
            stationType: "渔光互补"
        },
        {
            name: "萧县大屯镇20MW农光互补分布式光伏发电项目",
            value: [116.972251, 34.169984],
            stationType: "农光互补"
        },
        {
            name: "祥光新能源6MW全额上网屋顶分布式光伏发电",
            value: [119.491977, 37.059157],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东淄博1.4MW光伏电站",
            value: [117.8231970119, 37.1824021543],
            stationType: "彩钢+水泥"
        },
        {
            name: "山东冠县宋村华博",
            value: [115.449025, 36.489694],
            stationType: "农光互补"
        },
        {
            name: "宿州经开区河拐村20MW农光互补分布式光伏发电项目库1.",
            value: [116.974604, 33.601044],
            stationType: "农光互补"
        },
        {
            name: "金东二期",
            value: [119.672114, 32.194895],
            stationType: "彩钢屋顶"
        },
        {
            name: "河北风阳卧牛盘",
            value: [117.359653, 42.470969],
            stationType: "山地电站"
        },
        {
            name: "连云港首耀新海",
            value: [119.291198, 34.590419],
            stationType: "水泥屋顶"
        },
        {
            name: "盐城利能",
            value: [120.240052, 33.440862],
            stationType: "农光互补"
        },
        {
            name: "铁岭际华5.78MW屋顶分布式光伏发电项目",
            value: [123.680107, 42.178133],
            stationType: "彩钢屋顶"
        },
        {
            name: "铁岭中欧汽车3.5MW屋顶分布式光伏发电项目",
            value: [123.7008864936, 42.1576004524],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东安丘龙鼎重工",
            value: [119.220486, 36.412793],
            stationType: "彩钢屋顶"
        },
        {
            name: "安丘晨晖2MW全额上网屋顶分布式光伏发电项目",
            value: [119.229382, 36.480252],
            stationType: "彩钢屋顶"
        },
        {
            name: "潍坊安丘汇创",
            value: [119.228152, 36.483145],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东潍坊融光一站6MWp光伏电站",
            value: [119.170064, 37.048124],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东潍坊融光二站6MWp光伏电站",
            value: [119.170064, 37.047952],
            stationType: "彩钢屋顶"
        },
        {
            name: "灌云中森侍庄2.86832MW屋顶电站",
            value: [119.30428, 34.601536],
            stationType: "水泥屋顶"
        },
        {
            name: "启东近海镇18MW农光互补分布式光伏发电项目",
            value: [121.802013, 31.911794],
            stationType: "农光互补"
        },
        {
            name: "泗洪孙园镇20MW渔光互补分布式光伏发电项目",
            value: [118.222029, 33.462109],
            stationType: "渔光互补"
        },
        {
            name: "泗洪梅花镇40MW农光互补光伏“领跑者”项目",
            value: [118.197523, 33.453856],
            stationType: "渔光互补"
        },
        {
            name: "扬中阜润",
            value: [119.802256, 32.242],
            stationType: "彩钢屋顶"
        },
        {
            name: "安徽安庆永安电子电站",
            value: [117.080678, 30.509951],
            stationType: "水泥屋顶"
        },
        {
            name: "沈阳鲁博厨业3.9MW屋顶分布式光伏发电项目",
            value: [123.4132765888, 41.5754322375],
            stationType: "彩钢+水泥"
        },
        {
            name: "惠民永正",
            value: [117.596994, 37.497634],
            stationType: "农光互补"
        },
        {
            name: "商丘当店",
            value: [115.738182, 34.551813],
            stationType: "农光互补"
        },
        {
            name: "冠县公共事业",
            value: [115.510224, 36.450879],
            stationType: "水泥屋顶"
        },
        {
            name: "泗洪天岗湖",
            value: [116.785989, 33.944984],
            stationType: "渔光互补"
        },
        {
            name: "合肥恒科1.55184MW光伏电站",
            value: [117.200839, 31.822187],
            stationType: "水泥屋顶"
        },
        {
            name: "宿州现代鞋城",
            value: [116.895538, 33.552214],
            stationType: "水泥屋顶"
        },
        {
            name: "岳胡华博",
            value: [114.706568, 37.938171],
            stationType: "农光互补"
        },
        {
            name: "河南厢华",
            value: [118.159106, 39.61673],
            stationType: "农光互补"
        },
        {
            name: "营村华博",
            value: [114.218226, 37.110309],
            stationType: "农光互补"
        },
        {
            name: "东营金宇",
            value: [116.198767, 36.399125],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东东营6kv津禾光伏电站",
            value: [118.439848423, 37.5576934579],
            stationType: "彩钢屋顶"
        },
        {
            name: "东营正凯",
            value: [116.557801, 37.398347],
            stationType: "彩钢屋顶"
        },
        {
            name: "微山永旭",
            value: [120.394647, 36.085204],
            stationType: "彩钢屋顶"
        },
        {
            name: "创能二站",
            value: [122.973982, 41.049711],
            stationType: "彩钢屋顶"
        },
        {
            name: "创能一站",
            value: [123.461215, 41.778005],
            stationType: "彩钢屋顶"
        },
        {
            name: "南京雨润4MW屋顶分布式光伏电站",
            value: [119.560247, 31.963432],
            stationType: "水泥屋顶"
        },
        {
            name: "科灵空调",
            value: [123.826202, 42.181145],
            stationType: "彩钢屋顶"
        },
        {
            name: "如皋里高",
            value: [119.383235, 32.416341],
            stationType: "水泥屋顶"
        },
        {
            name: "南通永兴华陆",
            value: [120.367551, 33.404614],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港中森西苑",
            value: [119.278155, 34.259502],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港中盛石榴",
            value: [117.998971, 33.977616],
            stationType: "水泥屋顶"
        },
        {
            name: "连云港易睐珂克浦南1.987MW光伏电站",
            value: [119.3443, 34.586143],
            stationType: "彩钢屋顶"
        },
        {
            name: "南通永兴林森",
            value: [118.920017, 33.552052],
            stationType: "彩钢屋顶"
        },
        {
            name: "融光二站二期",
            value: [114.383267, 36.125962],
            stationType: "彩钢屋顶"
        },
        {
            name: "灌南飞展一期",
            value: [114.084712, 34.308878],
            stationType: "渔光互补"
        },
        {
            name: "淄博开耀",
            value: [114.371502, 35.67851],
            stationType: "水泥屋顶"
        },
        {
            name: "华乐光电一期",
            value: [121.616581, 31.810208],
            stationType: "水泥屋顶"
        },
        {
            name: "启东凯帝华虹1.18MWP屋顶分布式光伏电站",
            value: [121.6123491124, 31.827141196],
            stationType: "彩钢+水泥"
        },
        {
            name: "铁岭林燕新能源科技有限公司",
            value: [123.6904623894, 42.146368079],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东潍坊新瑞永旭2.28MW屋顶电站",
            value: [119.228754, 36.492428],
            stationType: "彩钢屋顶"
        },
        {
            name: "宿州中谷0.6552光伏电站",
            value: [116.954963, 33.65434],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥绿晖3.81MW光伏电站",
            value: [117.231066, 31.819371],
            stationType: "彩钢+水泥"
        },
        {
            name: "泗洪归仁1.59636MW屋顶光伏发电站",
            value: [118.204362, 33.674983],
            stationType: "水泥屋顶"
        },
        {
            name: "泗洪陈圩1.6854MW屋顶光伏发电站",
            value: [118.362715, 33.333696],
            stationType: "水泥屋顶"
        },
        {
            name: "阜阳金坤达3.159MW光伏电站",
            value: [115.809947, 32.902675],
            stationType: "彩钢+水泥"
        },
        {
            name: "镇江金东20MW屋顶分布式光伏电站",
            value: [119.697593, 32.195312],
            stationType: "彩钢屋顶"
        },
        {
            name: "如皋意瑞达2MW屋顶分布式光伏电站",
            value: [120.541273, 32.434058],
            stationType: "彩钢屋顶"
        },
        {
            name: "夏津力诺一期",
            value: [116.059935, 37.026594],
            stationType: "农光互补"
        },
        {
            name: "合肥应流4.32MW屋顶分布式光伏电站",
            value: [117.174211, 31.788516],
            stationType: "彩钢屋顶"
        },
        {
            name: "启东林洋电子2MW屋顶分布式光伏电站",
            value: [121.639277, 31.917826],
            stationType: "水泥屋顶"
        },
        {
            name: "内蒙乾华135MW地面集中式光伏电站",
            value: [111.284262, 40.344304],
            stationType: "农光互补"
        },
        {
            name: "启东乾朔1.78MW屋顶分布式光伏电站",
            value: [121.623939, 31.819655],
            stationType: "水泥屋顶"
        },
        {
            name: "南通永兴永林",
            value: [120.918807, 31.961345],
            stationType: "彩钢屋顶"
        },
        {
            name: "海门银燕0.42MW屋顶分布式光伏电站",
            value: [121.203163, 31.912599],
            stationType: "水泥屋顶"
        },
        {
            name: "南通观音山1MW屋顶分布式光伏电站",
            value: [120.724018, 32.37467],
            stationType: "水泥屋顶"
        },
        {
            name: "南通中心村0.5MW屋顶分布式光伏电站",
            value: [121.084309, 31.885101],
            stationType: "水泥屋顶"
        },
        {
            name: "南通农副1MW屋顶分布式光伏电站",
            value: [120.957317, 31.983873],
            stationType: "水泥屋顶"
        },
        {
            name: "安徽临泉",
            value: [115.264704, 33.070225],
            stationType: "农光互补"
        },
        {
            name: "界首金农",
            value: [115.386274, 33.277882],
            stationType: "农光互补"
        },
        {
            name: "安徽蚌埠4.4MW光伏电站",
            value: [117.391579, 32.925332],
            stationType: "山地电站"
        },
        {
            name: "淮北刘桥永瑞",
            value: [113.262503, 35.190781],
            stationType: "农光互补"
        },
        {
            name: "合肥万润",
            value: [114.276853, 34.697382],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥徽商大市场",
            value: [114.94965, 35.830754],
            stationType: "彩钢+水泥"
        },
        {
            name: "合肥恒春玻璃",
            value: [113.920412, 35.272855],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥恒通玻璃",
            value: [113.964015, 34.024943],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥应流二期",
            value: [113.502879, 34.673248],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港首耀清墩",
            value: [114.742589, 33.608996],
            stationType: "彩钢屋顶"
        },
        {
            name: "华乐光电二期",
            value: [121.642417, 31.745777],
            stationType: "车棚"
        },
        {
            name: "启东宏华",
            value: [121.688697, 31.792931],
            stationType: "彩钢屋顶"
        }
    ];

    var minX, maxX, minY, maxY;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (minX === undefined || minX > data[i].value[0]) {
                minX = data[i].value[0];
            }
            if (maxX === undefined || maxX < data[i].value[0]) {
                maxX = data[i].value[0];
            }
            if (minY === undefined || minY > data[i].value[1]) {
                minY = data[i].value[1];
            }
            if (maxY === undefined || maxY < data[i].value[1]) {
                maxY = data[i].value[1];
            }
        }
    }
    var centerX = (minX + maxX) / 2;
    var centerY = (minY + maxY) / 2;

    var minV, maxV;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (data[i].value[2] === 0.0) continue;
            if (minV === undefined || minV > data[i].value[2]) {
                minV = data[i].value[2];
            }
            if (maxV === undefined || minV < data[i].value[2]) {
                maxV = data[i].value[2];
            }
        }
    }

    var dataTop10 = [];
    var dataOther = [];
    var dataZero = [];
    var topCount = 0;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (data[i].value[2] <= 0.0) {
                dataZero.push(data[i]);
            } else {
                if (topCount < 10) {
                    dataTop10.push(data[i]);
                    ++topCount;
                } else {
                    dataOther.push(data[i]);
                }
            }
        }
    }

    var mapdata = [
        { name: "安徽", value: 95.33 },
        { name: "江苏", value: 94.46 },
        { name: "山东", value: 94.35 },
        { name: "河南", value: 94.38 },
        { name: "内蒙古", value: 76.21 },
        { name: "河北", value: 4.58 },
        { name: "辽宁", value: 62.9 }
    ];

    var nbData = [
        { name: "0-10 %", value: 3613 },
        { name: "10-20 %", value: 1102 },
        { name: "20-30 %", value: 36 },
        { name: "30-40 %", value: 35 },
        { name: "40-50 %", value: 25 },
        { name: "50-60 %", value: 71 },
        { name: "60-70 %", value: 62 },
        { name: "70-80 %", value: 395 },
        { name: "80-90 %", value: 345 },
        { name: "90-100 %", value: 5094 }
    ];
    var yData = [];
    for (const i in nbData) {
        if (data.hasOwnProperty(i)) {
            yData.push(nbData[i].name);
        }
    }

    var colorList = [
        "#37A2DA",
        "#32C5E9",
        "#67E0E3",
        "#9FE6B8",
        "#FFDB5C",
        "#FF9F7F",
        "#FB7293",
        "#E062AE",
        "#E690D1",
        "#E7BCF3",
        "#9D96F5",
        "#8378EA",
        "#96BFFF"
    ];

    var labelCfg = {
        normal: {
            show: true,
            position: "center",
            color: "#727272",
            formatter: function(params) {
                if (params.name == "离线") {
                    return "";
                } else {
                    return params.seriesName + "\n" + params.percent + " %";
                }
            }
        },
        emphasis: {
            show: true,
            position: "center",
            color: "#727272",
            formatter: function(params) {
                if (params.name == "离线") {
                    return "";
                } else {
                    return params.seriesName + "\n" + params.percent + " %";
                }
            }
        }
    };
    var pieTooltipCfg = {
        formatter: function(params) {
            return (
                params.name +
                "<br />在线:" +
                params.value +
                " 台, <br />离线: " +
                params.data.offline +
                " 台, <br />在线率: " +
                params.percent +
                " %"
            );
        }
    };

    var series = [
        {
            name: "出线柜",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["70%", "20%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "出线柜",
                    value: 171,
                    offline: 1,
                    itemStyle: {
                        color: colorList[0]
                    }
                },
                {
                    name: "离线",
                    value: 1,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        {
            name: "集电线路",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["85%", "20%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "集电线路",
                    value: 422,
                    offline: 2,
                    itemStyle: {
                        color: colorList[1]
                    }
                },
                {
                    name: "离线",
                    value: 2,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        {
            name: "箱变",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["70%", "40%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "箱变",
                    value: 755,
                    offline: 15,
                    itemStyle: {
                        color: colorList[2]
                    }
                },
                {
                    name: "离线",
                    value: 15,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        {
            name: "集中式逆变器",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["85%", "40%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "集中式逆变器",
                    value: 374,
                    offline: 57,
                    itemStyle: {
                        color: colorList[3]
                    }
                },
                {
                    name: "离线",
                    value: 57,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        {
            name: "组串式逆变器",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["70%", "60%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "组串式逆变器",
                    value: 3755,
                    offline: 157,
                    itemStyle: {
                        color: colorList[4]
                    }
                },
                {
                    name: "离线",
                    value: 157,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        {
            name: "汇流箱",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["85%", "60%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "汇流箱",
                    value: 744,
                    offline: 82,
                    itemStyle: {
                        color: colorList[5]
                    }
                },
                {
                    name: "离线",
                    value: 82,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        {
            name: "电表",
            type: "pie",
            radius: ["11%", "15%"],
            center: ["70%", "80%"],
            label: labelCfg,
            labelLine: { normal: { show: false } },
            data: [
                {
                    name: "电表",
                    value: 272,
                    offline: 0,
                    itemStyle: {
                        color: colorList[6]
                    }
                },
                {
                    name: "离线",
                    value: 0,
                    itemStyle: { color: "rgba(0, 0, 0, 0)" }
                }
            ],
            tooltip: pieTooltipCfg
        },
        //地图上代表电站的点
        {
            name: "other",
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "stroke"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: 10 /*function (val) {
        if (val[2] === 0.0) return 5;
        return 15 + (val[2] - minV) / (maxV - minV) * 5;
    },*/,
            itemStyle: {
                normal: {
                    color: "#67E0E3"
                }
            },
            data: dataOther
        },
        {
            name: "Top10",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "stroke"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: 10 /*function (val) {
        if (val[2] === 0.0) return 5;
        return 6 + (val[2] - minV) / (maxV - minV) * 6;
    },*/,
            itemStyle: {
                normal: {
                    color: "#9FE6B8"
                }
            },
            data: dataTop10
        },
        {
            name: "zero",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "fill"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: 10,
            itemStyle: {
                normal: {
                    color: "#FB7293"
                }
            },
            data: dataZero
        },
        {
            name: "装机容量",
            type: "map",
            mapType: "china",
            geoIndex: 0,
            data: mapdata
        }
    ];

    let option = {
        backgroundColor: "rgba(255, 255, 255, 0)",
        //color: ['#2196F3', '#FFC107', '#45A349', '#FF5722', '#9C27B0', '#7FBCEC', '#21C7E2', '#84A9C1', '#409E8F'],
        tooltip: {
            trigger: "item",
            formatter: function(params) {
                if (params.seriesType == "map") {
                    if (params.data === undefined) {
                        return null;
                    } else {
                        return (
                            params.data.name +
                            "<br />设备在线率：" +
                            params.data.value +
                            " %"
                        );
                    }
                } else if (params.seriesType == "bar") {
                    return params.data.name + "：" + params.data.value + " %";
                } else {
                    return (
                        params.data.name +
                        "<br />设备在线率：" +
                        params.data.value[2] +
                        " %"
                    );
                }
            }
        },
        legend: { show: false },
        visualMap: {
            show: false,
            type: "continuous",
            //orient: 'horizontal',
            min: 0,
            max: 100,
            inRange: {
                color: ["#56bdff", "#026bad"]
            },
            itemWidth: 8,
            itemHeight: 100,
            left: "left",
            top: "70%",
            //text:['高','低'],           // 文本，默认为数值文本
            calculable: true,
            precision: 0,
            //align: 'bottom',
            seriesIndex: [series.length - 1]
        },
        geo: {
            map: "china",
            //zoom: 1,
            //center: [centerX, centerY],
            // boundingCoords: [
            //     [minX, maxY], // 定位左上角经纬度
            //     [maxX, minY]  // 定位右下角经纬度
            // ],
            layoutCenter: ["30%", "50%"],
            layoutSize: "100%",
            zlevel: 0,
            label: {
                emphasis: {
                    show: false
                }
            },
            roam: true,
            itemStyle: {
                areaColor: "#BBDEFB", //'#F2F2F2',
                borderColor: "#727272",
                opacity: 1
            },
            emphasis: {
                itemStyle: {
                    areaColor: "#005184"
                }
            }
        },
        series: series
    };

    return option;
}
