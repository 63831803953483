import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../shared/shared.module';
import { DeviceAssetsService } from '../device-assets.service';
import { OptionTypeComponent } from './option-type.component';
import { TreeviewModule } from '../../../../common/components/treeview/index';

@NgModule({
  imports: [
    SharedModule,
    TreeviewModule.forRoot()
  ],
  exports: [
    OptionTypeComponent
  ],
  declarations: [
    OptionTypeComponent
  ],
  providers: [
    DeviceAssetsService
  ],
})
export class OptionTypeModule { }
