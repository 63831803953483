import { CompanyTreeSelectorService } from "./company-tree-selector.service";
import {
    Component,
    OnInit,
    Input,
    EventEmitter,
    Output,
    SimpleChanges,
    OnChanges
} from "@angular/core";
import {
    TreeviewItem,
    TreeviewConfig,
    TreeviewI18n,
    DefaultTreeviewI18n
} from "../treeview";

@Component({
    selector: "mpt-company-tree-selector",
    templateUrl: "./company-tree-selector.component.html",
    styleUrls: ["./company-tree-selector.component.less"],
    providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }]
})
export class CompanyTreeSelectorComponent implements OnInit, OnChanges {
    @Input() public isReset: boolean = false;
    @Input() public isRadio: boolean = false;
    @Input() public value: string = ""; // 单选初始化值
    @Input() public values: string[] = []; // 多选初始化值
    @Output() public getSelectedVal = new EventEmitter<string>();
    public treeData: TreeviewItem[] = [];
    public items: TreeviewItem[] = [];
    public mulitConfig = TreeviewConfig.create({
        hasAllCheckBox: true,
        hasFilter: true,
        hasCollapseExpand: false,
        decoupleChildFromParent: false,
        maxHeight: 200
    });
    constructor(
        private companytreeselectorservice: CompanyTreeSelectorService
    ) {}

    ngOnInit() {
        this.companytreeselectorservice
            .getPropertyComps()
            .subscribe((res: any) => {
                this.treeData = res;
                this.items = this.companytreeselectorservice.filterTreeData(
                    "companyName",
                    "companyId",
                    this.treeData
                );
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (this.isReset) {
            this.values = [];
        }
        this.items = this.companytreeselectorservice.filterTreeData(
            "companyName",
            "companyId",
            this.treeData
        );
    }

    public onSelectedChange(event) {
        this.getSelectedVal.emit(event);
    }
}
