import { DeviceService } from 'src/app/components/monitor/shared/device.service';
import { CommonService } from 'src/app/common/services/common.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { StationMonitorService } from './../station-monitor.service';
import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { BreadcrumbService } from '../../layout/breakcrumb/breadcrumb.service';
import { HeaderService } from '@components/layout/header/header.service';
import { TipService } from '@common/services/tip.service';
declare const $;

@Component({
  selector: 'app-device-detail',
  templateUrl: './device-detail.component.html',
  styleUrls: ['./device-detail.component.less'],
})
export class DeviceDetailComponent implements OnInit, OnChanges {
  @Input() showDeviceDetail: boolean;
  @Input() showCloseSideBar = true;
  @Input() showConfig = true;
  @Input() addDevice: string;
  @Output() public showEvent = new EventEmitter<boolean>();
  tabType = 'operationalMonitoring';
  deviceId: any;
  stationId: any;
  deviceType: any;
  refreshData = false;
  deviceDetailPermissions: any;
  featureDeviceQueryPermissions: any;
  featurePartsInoutPermissions: any;
  showFjConfig: boolean;
  constructor(
    private stationmonitorservice: StationMonitorService,
    private activatedroute: ActivatedRoute,
    private router: Router,
    private breadcrumbservice: BreadcrumbService,
    private commonService: CommonService,
    private deviceservice: DeviceService,
    private headerService: HeaderService,
    private tipservice: TipService
  ) {
    this.stationmonitorservice.deviceDetailHandler.subscribe((res) => {
      this.tabType = res.tabType || 'operationalMonitoring';
      this.deviceId = res.deviceId;
      this.deviceType = res.deviceType;
      if (res.stationId) {
        this.stationId = res.stationId;
      }
      if (this.deviceId && res.showConfig) {
        this.breadcrumbservice.setConfig({
          show: true,
          page: 'point-config',
          deviceId: this.deviceId,
        });
      } else {
        this.breadcrumbservice.setConfig({
          show: false,
        });
      }
    });
    this.activatedroute.queryParams.subscribe((res) => {
      this.deviceId = res.deviceId;
      this.deviceType = res.deviceType;
      if (res.stationId) {
        this.stationId = res.stationId;
      }
      if (this.deviceId) {
        setTimeout(() => {
          const breadcrumbItems = this.breadcrumbservice.getItems();
          localStorage.setItem('deviceId', this.deviceId);
          this.breadcrumbservice.setConfig({
            show: true,
            page: 'point-config',
            deviceId: this.deviceId,
          });
          console.log(breadcrumbItems);

          if (breadcrumbItems && [4, 5].includes(breadcrumbItems.length)) {
            breadcrumbItems.length = 4;
            this.stationmonitorservice
              .deviceInfo(this.deviceId)
              .subscribe((device: any) => {
                breadcrumbItems.push({
                  label: device.deviceNo,
                  routerLink: '',
                  queryParams: null,
                });
                breadcrumbItems.length = 5;
                this.breadcrumbservice.setItems(breadcrumbItems);
              });
          }
        }, 0);
      } else {
        this.breadcrumbservice.setConfig({
          show: false,
        });
      }
    });
    this.deviceservice.getDevicesPermissions().then((res) => {
      this.deviceDetailPermissions = this.deviceservice.deviceDetailPermissions;
      this.featureDeviceQueryPermissions =
        this.deviceDetailPermissions &&
        this.deviceDetailPermissions['feature.device.query'];
      this.featurePartsInoutPermissions =
        this.deviceDetailPermissions &&
        this.deviceDetailPermissions['feature.parts.inout'];
    });
  }

  ngOnInit() {}

  ngOnChanges(changes) {
    const breadcrumbItems = this.breadcrumbservice.getItems();
    if (changes.showDeviceDetail && changes.showDeviceDetail.currentValue) {
      this.stationmonitorservice
        .deviceInfo(this.deviceId)
        .subscribe((res: any) => {
          let breadcrumbItems = this.breadcrumbservice.getItems();
          if (breadcrumbItems) {
            breadcrumbItems.push({
              label: res.deviceNo,
              routerLink: '',
              queryParams: null,
            });
            this.breadcrumbservice.setItems(breadcrumbItems);
          }
        });
    } else {
      if (breadcrumbItems && breadcrumbItems.length === 5) {
        breadcrumbItems.pop();
        this.breadcrumbservice.setItems(breadcrumbItems);
      }
    }
  }

  /**
   * tabClick
   */
  public tabClick(e, type) {
    this.tabType = type;
  }

  public closeSideBar() {
    if (this.stationId && this.deviceType && this.deviceId) {
      this.router.navigate(['/monitorStation/deviceList'], {
        queryParams: { stationId: this.stationId },
      });
    }
    this.stationmonitorservice.setDeviceDetail({
      deviceId: '',
      stationId: '',
    });
  }

  public clickConfig() {
    if (this.deviceType === 'FJ') {
      this.showFjConfig = true;
    } else {
      this.headerService.configSubject.next({
        deviceId: this.deviceId,
      });
    }
  }

  public dataInit() {
    this.refreshData = !this.refreshData;
  }

  public closeDialog() {
    this.showEvent.emit(true);
  }
  files = [];
  public downloadFile() {
    this.stationmonitorservice.windConfigExport({ deviceId: this.deviceId });
  }

  public onBasicUpload(event) {
    const file = event.files[0];

    this.stationmonitorservice.importFile(file).then((res: any) => {
      if (res > 0) {
        this.tipservice.tip('success', '成功导入' + res + '条数据');
      }
    });
  }
}
