import { FileService } from './../../common/services/file.service';
import { CommonService } from './../../common/services/common.service';
import { AuthHttp } from './../../core/auth-http';
import { AppConfigService } from './../../app.config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InspectionsService {
  public apiUrl = this.appConfig.apiUrl;
  constructor(
    private appConfig: AppConfigService,
    private http: AuthHttp,
    private commonservice: CommonService,
    private fileservice: FileService
  ) { }

  /**
   * 巡检单列表
   */
  public getInspectionsList(params) {
    return this.http.get(`${this.apiUrl}/api/inspections`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }
  /**
   * 导出巡检单
   */
  public exportInspections(params) {
    return this.commonservice.fileExport(
      `${this.apiUrl}/api/inspections/export`,
      params
    );
  }

  public getInspectionsByinspectionId(inspectionId, params) {
    return this.http.get(`${this.apiUrl}/api/inspections/${inspectionId}`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public deleteInspection(inspectionIds) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/inspections`, {
      search: { inspectionIds },
    });
  }

  public saveInspection(params) {
    return this.http.post(`${this.appConfig.apiUrl}/api/inspections`, params);
  }

  public inspectionAction(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/inspections`, params);
  }

  public getTracks(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/inspections/tracks`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  // 获取 表单配置
  public getNullable(targetNodeId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/workflows/nullable`, {
        params: { targetNodeId },
      })
      .toPromise();
  }

  // 催单
  public remind(ticketId) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/inspections/remind`,
      {},
      // { params: { ticketId }, responseType: 'text' }
      { params: { ticketId } }
    );
  }

  public getInspectionsObjItems(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/inspections/items`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public check(params) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/inspections/check`,
      {},
      { params }
    );
  }

  /**
   * getInspectionsItems
   */
  public getInspectionsItems(params) {
    return this.http.get(`${this.apiUrl}/api/report/inspections/items`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  // 导出巡检明细
  public exportInspectionsItems(params) {
    return this.commonservice.fileExport(
      `${this.apiUrl}/api/report/inspections/items/export`,
      params
    );
  }

  public getInspectionsObjFiles(objItemId) {
    return this.http.get(`${this.apiUrl}/api/inspections/obj/files`, {
      params: {
        objItemId,
      },
    });
  }
  public uploadObjFile(file, params) {
    return this.fileservice.uploadDocs(
      '/api/inspections/obj/files',
      file,
      params
    );
  }

  public deleteObjFile(docIds) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/doc/delete`, {
      params: { docIds },
    });
  }

  public getObjItem(params) {
    return this.http.get(`${this.apiUrl}/api/inspections/obj`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public getObjItemHis(params) {
    return this.http.get(`${this.apiUrl}/api/inspections/obj/itemhis`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public saveObjItem(params) {
    return this.http.post(`${this.apiUrl}/api/inspections/obj`, params);
  }

  public deleteObjItem(objId) {
    return this.http.delete(`${this.apiUrl}/api/inspections/obj`, {
      params: { objId },
    });
  }

  public uploadPic(file, params) {
    return this.fileservice.uploadDocs('/api/inspections/pic', file, params);
  }
}
