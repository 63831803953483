export const COLORS = [
  '#dc4223', '#0e65dc', '#f57300', '#00b085',
  '#834ce9', '#fa4a33', '#0090ff', '#ffa200',
  '#1be2b1', '#a554ea', '#ff9c8f', '#72b3fd',
  '#ffd71d', '#44dad8', '#da54ea', '#f6f616',
  '#27dfff', '#ffc18f', '#ffa8ee', '#18efec'
];

export interface AlarmDateRange {
  startTime: string;
  endTime: string;
}

export interface Point {
  pointId: number;
  pointName: string;
  pointStatus: string;
  cateId: string;
  pointCategory: PointCategory;
}

export interface PointCategory {
  displayGroup1: string;
  displayGroup2: string;
  displayPrecision: number;
  pointUnit: string;
  dataType: string;
  dataPeriod: string;
}

export interface DownExcelParam {
  ids: string;
  dayTime: string;
  pids: string[];
}

export interface BaseData {
  dialogPoints: Point[]; // 两侧的测点列表，根据时间类型过滤
  dateType: [string, string]; // 翻译后的时间类型
}

export interface PointHistory {
  pointName: string;
  pointId: number;
  data: PointHistoryData[];
}

export interface PointHistoryData {
  pointavalue: number;
  pointDt: number;
}

export interface BaseSeries {
  name: string;
  type: string;
  smooth: boolean;
  yAxisIndex: number;
  data: TimeSeriesData[];
  unit: string;
  itemStyle: {
    normal: {
      color: string;
    }
  };
}

export interface TimeSeriesData {
  value: [ string, number];
}
