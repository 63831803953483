import { AuthHttp } from './../../../core/auth-http';
import { Injectable } from '@angular/core';

import { register as registerFlow } from '@topology/flow-diagram';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app.config';

@Injectable({
  providedIn: 'root',
})
export class TopoEditorService {
  apiUrl: string;
  canvasRegister() {
    registerFlow();
  }

  constructor(private http: AuthHttp, private appconfig: AppConfigService) {
    this.apiUrl = this.appconfig.apiUrl;
  }

  async Get(params: any) {
    return await this.http
      .get(`${this.apiUrl}/api/topographic/getTopologyGraphicByTopoId`, {
        params,
      })
      .toPromise();
  }

  async Upload(blob: Blob, shared = false, filename = '/topology/thumb.png') {
    const formData: FormData = new FormData();
    formData.append('file', blob, filename);
    return await this.http
      .post(`/api/file`, formData)
      .pipe(
        map((item: any) => {
          const data = item.data;
          return {
            url: data.path,
          };
        })
      )
      .toPromise();
  }

  async DelImage(image: string) {
    const ret = (await this.http.delete('/api' + image).toPromise()) as any;
    if (ret.error) {
      return false;
    }

    return true;
  }

  async AddImage(image: string) {
    const ret = (await this.http
      .post('/api/user/image', { image })
      .toPromise()) as any;
    if (ret.error) {
      return '';
    }

    return ret.id;
  }

  async Save(data: any) {
    data = Object.assign({}, data);

    let ret: any;
    if (!data.topoName) {
      data.topoName = `Created at ${new Date().toLocaleString()}`;
    }
    ret = await this.http
      .post(`${this.apiUrl}/api/topographic/createTopologyGraphic`, data)
      .toPromise();

    return ret;
  }
  async Update(data: any) {
    data = Object.assign({}, data);

    let ret: any;
    if (!data.topoName) {
      data.topoName = `Update at ${new Date().toLocaleString()}`;
    }
    ret = await this.http
      .put(`${this.apiUrl}/api/topographic/saveTopologyGraphic`, data)
      .toPromise();

    return ret;
  }

  async saveNode(topoId, nodes) {
    return this.http
      .post(`/api/crtTopo/addBatchCrtTopoNode/${topoId}`, nodes)
      .toPromise();
  }

  async Patch(data: any) {
    if (data.image) {
      const retImage = (await this.http.patch('/api' + data.image, {
        public: data.shared,
      })) as any;
      if (retImage.error) {
        return false;
      }
    }

    delete data.image;
    const ret = (await this.http.patch('/api/user/topology', data)) as any;
    if (ret.error) {
      return false;
    }

    return true;
  }
}
