import { DeviceService } from 'src/app/components/monitor/shared/device.service';
import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { StationMonitorService } from '../../station-monitor.service';
import { buildChart } from './chart';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-device-topology',
  templateUrl: './device-topology.component.html',
  styleUrls: ['./device-topology.component.less'],
})
export class DeviceTopologyComponent implements OnInit, OnChanges {
  @Input() deviceId: string = '';
  @Input() stationId: string = '';
  @Input() showButton = true;
  options: any = null;
  parent_data: any[];

  columns = [
    { header: '接入端口', field: 'inputNo' },
    { header: '设备名称', field: 'deviceName' },
    { header: '型号', field: 'modelNo' },
    { header: '装机容量', field: 'capacity' },
    { header: '组串倾斜角', field: 'modelAngle' },
    { header: '组串方位角', field: 'modelLayout' },
    { header: '污染情况', field: 'pollution' },
    { header: '遮挡情况', field: 'block' },
  ];
  oldSubDevice: any;
  isShowObjectDialog = false;
  isChangeSub: boolean;
  tableloading = true;
  constructor(
    private stationmonitorservice: StationMonitorService,
    private tipservice: TipService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes) {
    if (changes.deviceId && changes.deviceId.currentValue) {
      this.getTopo(changes.deviceId.currentValue);
    }
  }

  /**
   * getTopo
   */
  public getTopo(deviceId) {
    this.parent_data = [];
    this.tableloading = true;
    this.stationmonitorservice.getTopo({ deviceId }).subscribe((res: any) => {
      this.tableloading = false;
      if (res) {
        if (res.data && res.data.deviceId && res.data.deviceId !== deviceId) {
          this.parent_data = [res.data];
        }
        const data = this.treeMap(res);
        this.options = buildChart(data);
      }
    });
  }

  treeMap(res) {
    if (res.data && res.data.deviceName) {
      res.name = res.data.deviceName;
    }
    if (res.children && res.children.length > 0) {
      res.children.map((item) => {
        this.treeMap(item);
      });
    }
    return res;
  }

  //显示更新设备选择弹窗
  public chanSubDevice(subDevice) {
    this.isShowObjectDialog = true;
  }

  childrenDeviceChagne(event) {
    this.getTopo(this.deviceId);
  }

  public deviceSelected(e) {
    if (!e.deviceId) {
      this.tipservice.tip('warn', '请选择需要更换的设备');
      return;
    }
    this.stationmonitorservice
      .changeParent({
        deviceId: this.deviceId,
        parentId: e.deviceId,
      })
      .then((res) => {
        this.tipservice.tip('success', '更换父设备成功！');
        this.getTopo(this.deviceId);
      });
  }
}
