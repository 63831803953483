import { Routes } from "@angular/router";

import { TopoManageComponent } from "./topo-manage/topo-manage.component";
import { TopoEditorComponent } from "../topo-v2/topo-editor/topo-editor.component";
import { TopoDisplayComponent } from "../topo-v2/topo-display/topo-display.component";

export const routes: Routes = [
  {
    path: "manage",
    component: TopoManageComponent
  },
  {
    path: "edit/:topoId",
    component: TopoEditorComponent
  },
  {
    path: "editor",
    component: TopoEditorComponent
  },
  {
    path: "meta",
    component: TopoManageComponent
  },
  {
    path: "display",
    component: TopoDisplayComponent
  }
];
