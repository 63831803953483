
// 电站测点
export interface StationPoint {
  ruleId: number;
  pointId: number;
  pointName: string;
  psrId: string;
  remotionType: string;
  remotionTypeName: string;
  stationName: string;
  stationId: string;
  type: string;
}

// 电站区域测点
export interface AreaPoint {
  ruleId: number;
  areaId: string;
  areaName: string;
  cateName: string;
  pointId: number;
  pointName: string;
  psrId: string;
  remotionType: string;
  remotionTypeName: string;
  stationName: string;
  stationId: string;
  type: string;
}

// 设备测点
export interface DevicePoint {
  ruleId: number;
  areaId: string;
  psrId: string;
  deviceId: string;
  deviceNo: string;
  pointId: number;
  pointName: string;
  remotionType: string;
  remotionTypeName: string;
  stationName: string;
  stationId: string;
  type: string;
}

export interface SubmitPoint {
  ruleId: number;
  areaId: string;
  psrId: string;
  deviceId: string;
  deviceNo: string;
  pointId: number;
  pointName: string;
  remotionType: string;
  remotionTypeName: string;
  stationName: string;
  stationId: string;
  type: string;
  areaName: string;
  cateName: string;
}

export enum ObjType {
  station = '05',
  area = '04',
  device = '02'
}

export enum BusType {
  pointpreprocess = 'pointpreprocess',
  alarmRule = 'alarmRule'
}
