// 物料
export interface Material {
  materialId: number;
  materialNo: string;
  materialName: string;
  materialSpec: string;
  inventoryId: number;
  inventoryCate: string;
  inventoryCateText: string;
  inventoryNum: number;
  isDelete: string;
  materialDesc: string;
  companyName: string;
  unit: string;
  safetyInventory: number;
  tenantId: string;
  updateTime: string;
  updater: string;
  warehouseId: string;
  warehouseName: string;
  materialSnId: string;
  materialSn: string;
  isSn: string;
  ioNum: number;
  ioFincanceSubtype: string;
  ioFincanceSubtypeText: string;
}

// 物料清单 表单
export class MaterialFormVal {
  public inventoryId = null;
  public warehouseId = null;
  public materialId = null;
  public materialNo = null;
  public materialName = null;
  public materialSpec = null;
  public materialSn = null;
  public materialSnId = null;
  public inventoryCate = '01';
  public inventoryCateText = null;
  public inventoryNum = null;
  public ioNum = 0;
  public ioFincanceSubtype = null;
  public ioFincanceSubtypeText = '';
}

// 物料类型
export enum MaterialType {
  tool = '02', // 工器具
  spareParts = '01', // 备品备件
  nonProductive = '03', // 非生产物资
}
