import { SharedModule } from "./../../shared/shared.module";
import { MapOverviewComponent } from "./map-overview.component";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

@NgModule({
    declarations: [MapOverviewComponent],
    imports: [SharedModule, RouterModule]
})
export class MapOverviewModule {}
