import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from '@angular/core';
import * as _ from 'lodash';

import { Material } from '../index.model';
import { CommonService } from '../../../../common/services/common.service';
import { DeviceAssetsService } from '../device-assets.service';
import { MaterialSatatusType } from '../../../../common/models/material-status.model';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'mpt-material-inquiry',
  templateUrl: 'material-inquiry.component.html',
  styleUrls: ['./material-inquiry.component.less']
})
export class MaterialInquiryComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public warehouseId: string;
  @Input() public isShow = false;
  @Input() public type: string; // 工器具 or 备品备件
  @Input() public inOrOut: string; // 出库 or 入库
  @Input() public materialNoSearch: string;
  @Output() public isShowMaterials = new EventEmitter<boolean>();
  @Output() public getMaterialVal = new EventEmitter<Material>();
  public materials: Material[] = [];
  public isShowMaterial = true;
  public isShowMaterialContent = false;
  public isBlur: any;

  constructor(
    private common: CommonService,
    private deviceAssetsService: DeviceAssetsService,
    private tipservice: TipService
  ) { }

  public ngOnInit() {
    document.addEventListener('click', (e: any) => {
      if (this.isBlur) {
        this.setMaterialEvent(e);
        this.isBlur = false;
      }
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if (this.warehouseId) {
    //   this.queryMaterial(this.warehouseId, this.materialNoSearch, false);
    // }
  }

  // 物料查询输入框中字符达到4个自动触发
  public changeQueryList() {
    if (this.materialNoSearch && this.materialNoSearch.length > 3) {
      this.queryList(true);
    }
  }
  public queryList(auto?) {
    this.queryMaterial(this.warehouseId, this.materialNoSearch, auto);
  }

  public ngOnDestroy() {
    document.removeEventListener('click', (e) => {
      this.setMaterialEvent(e);
    });
  }

  public setMaterialEvent(e: any) {
    if (
      !_.includes(e.target.className, 'td-js') &&
      !_.includes(e.target.className, 'search-box')
    ) {
      this.isShowMaterials.emit(false);
    }
  }

  // 物料查询  与仓库关联
  public queryMaterial(warehouseId, value: string = '', auto) {
    if (this.inOrOut === 'out') {
      this.deviceAssetsService
        .getMaterialInventory(value, warehouseId, this.type)
        .subscribe((res: any) => {
          if (_.isEmpty(res.content)) {
            this.isShowMaterialContent = false;
            // 点击查询图标按需给出提示
            if (!auto) { this.tipservice.tip('warn', '查询不到数据!'); }
            if (!value) {
              this.isShowMaterials.emit(false);
              this.tipservice.tip('warn', '当前仓库没有物料');
            }
            return false;
          }
          this.materials = res.content;
          this.materials = _.take(this.materials, 10);
          this.isShowMaterialContent = true;

          this.scrollBottom();
        });
    }
    if (this.inOrOut === 'in') {
      this.deviceAssetsService
        .getMaterialDtos(value, warehouseId, this.type)
        // this.deviceAssetsService.getMaterials(value, this.type, MaterialSatatusType.inUse)
        .subscribe((res: any) => {
          if (_.isEmpty(res.content)) {
            this.isShowMaterialContent = false;
            // 点击查询图标按需给出提示
            if (!auto) { this.tipservice.tip('warn', '查询不到数据!'); }
          } else {
            // this.materials = res;
            this.materials = res.content;
            this.materials = _.take(this.materials, 10);
            this.isShowMaterialContent = true;
          }
          this.scrollBottom();
        });
    }
  }

  // 把div的滚动条置底
  private scrollBottom() {
    setTimeout(
      'var _div = document.getElementById(\'rootDiv\'); _div.scrollTop = _div.scrollHeight;',
      50
    );
  }

  // 选择物料
  public selectMaterial(material: Material) {
    this.isShowMaterials.emit(false);
    material.inventoryNum = material.inventoryNum
      ? material.inventoryNum
      : 0;
    this.getMaterialVal.emit(material);
  }

  public onBlur() {
    this.isBlur = true;
  }

  public onFocus(event) {
    // document.addEventListener('click', (e: any) => {
    //   if (
    //     e.target.className !== 'td-js' &&
    //     e.target.className !== 'search-box'
    //   ) {
    //     this.isShowMaterials.emit(false);
    //   }
    // });
  }
  public onHide() {
    this.isShowMaterials.emit(false);
  }
}
