import { OperateService } from './../operate.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { CommonService } from 'src/app/common/services/common.service';
import * as moment from 'moment';

@Component({
  selector: 'app-template-selector',
  templateUrl: './template-selector.component.html',
  styleUrls: ['./template-selector.component.less'],
})
export class TemplateSelectorComponent implements OnInit {
  @Input() public isShow: boolean;
  @Input() public staParams: any;

  @Output() public showEvent = new EventEmitter<boolean>();
  @Output() public ticketTasksEvent = new EventEmitter<any>();

  public queryForm: any;
  public pager: { page: number; size: number; totalElements: number };
  public canlendarLocale: any;
  public scrollHeight: string;
  public resizeEvent: any;
  public sortBy: string;
  public sortOrder: string;
  public dataList: any[];
  public columns: any[];
  tableloading: boolean;
  public selectedTicket: any;

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private operateservice: OperateService
  ) {}

  ngOnInit() {
    this.initForm();
    this.initPage();
    this.queryList(true);
  }

  public initForm() {
    this.queryForm = this.fb.group({
      stationFlag: this.fb.group({
        sta_stationIds: new FormControl(null),
        sta_provinceIds: new FormControl(null),
        sta_cityIds: new FormControl(null),
        sta_countyIds: new FormControl(null),
        sta_propertyComps: new FormControl(null),
        sta_stationTypes: new FormControl(null),
        sta_name: new FormControl(null),
      }),
      ticketCode: new FormControl(null),
      operateDesc: new FormControl(null),
    });
  }

  public initPage() {
    if (this.staParams) {
      this.queryForm.patchValue({
        stationFlag: this.staParams,
      });
    }
    this.selectedTicket = [];
    this.scrollHeight = '304px';
    this.pager = {
      page: 0,
      size: 50,
      totalElements: 0,
    };
    this.dataList = [];
    this.columns = [
      {
        field: 'ticketCode',
        header: '典型票票号',
        textAlign: 'center',
        width: '150px',
      },
      {
        field: 'stationName',
        header: '电站',
        textAlign: 'center',
        width: '180px',
      },
      {
        field: 'operateTypeText',
        header: '操作类型',
        textAlign: 'center',
        width: '150px',
      },
      {
        field: 'operateDesc',
        header: '操作票任务',
        textAlign: 'left',
        width: '400px',
      },
      {
        field: 'taskNum',
        header: '操作步骤',
        textAlign: 'center',
        width: '150px',
      },
    ];
  }

  outputStaParamsEvent(event) {
    this.queryForm.patchValue({
      stationFlag: event,
    });
  }

  public queryList(first?) {
    this.tableloading = true;
    const values = this.queryForm.value;
    if (first) {
      this.pager.page = 0;
      this.sortBy = 'ticketCode';
      this.sortOrder = 'desc';
    }
    const params = {
      ...values.stationFlag,
      ticketCode: values.ticketCode,
      operateDesc: values.operateDesc,
      page: this.pager.page,
      size: this.pager.size,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    };
    this.operateservice.getOperateTemplatesList(params).subscribe(
      (res: any) => {
        if (res) {
          this.dataList = res.content;
          this.pager.totalElements = res.totalElements;
          this.tableloading = false;
        }
      },
      () => {
        this.tableloading = false;
      },
      () => {
        this.tableloading = false;
      }
    );
  }

  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryList();
  }

  public closeDialog(event) {
    this.showEvent.emit(true);
  }

  public saveSelected(event) {
    if (this.selectedTicket && this.selectedTicket.ticketId) {
      this.common.mask.show();
      this.operateservice.getOperateTemplatesByTicketId(this.selectedTicket.ticketId).subscribe(
        (res: any) => {
          if (res && res.ticket) {
            this.ticketTasksEvent.emit(res.ticket);
          } else {
            this.ticketTasksEvent.emit({});
          }
          this.closeDialog(true);
        },
        (errorr) => {},
        () => {
          this.common.mask.hide();
        }
      );
    } else {
      this.ticketTasksEvent.emit([]);
      this.closeDialog(true);
    }
  }
}
