import { MaterialMultiService } from './material-multi.service';
import {
  Component,
  Input,
  OnInit,
  ViewChild,
  forwardRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-material-multi',
  templateUrl: './material-multi.component.html',
  styleUrls: ['./material-multi.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MaterialMultiComponent),
      multi: true,
    },
  ],
})
export class MaterialMultiComponent
  implements OnInit, OnChanges, ControlValueAccessor
{
  @Input() public materialType: string;
  name = '';
  display: boolean;
  materialCol = [
    {
      header: '料号',
      field: 'materialNo',
    },
    {
      header: '名称',
      field: 'materialName',
    },
    {
      header: '规格',
      field: 'materialSpec',
    },
  ];

  materialList = [];
  tableSelectedMaterials = [];
  @ViewChild('dt', { static: false }) dataTable: any;
  tableHeaderChecked: any;
  materialValue: any;
  pager: any = { page: 0, totalRecords: 0, size: 10 };
  _materialType: any;
  constructor(private mms: MaterialMultiService) {}

  writeValue(obj: any): void {
    if (obj) {
      this.tableSelectedMaterials = obj.split(',').map((item) => ({
        materialId: item,
      }));
    } else {
      this.tableSelectedMaterials = [];
      this.tableHeaderChecked = false;
    }
  }
  propagateChange = (_: any) => {};
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  get materialIds() {
    return this._materialType;
  }

  set materialIds(val) {
    this._materialType = val;
    this.propagateChange(this._materialType);
  }

  ngOnInit() {
    this.queryMaterials(true);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.materialType.firstChange) {
      this.queryMaterials(true);
      this.tableSelectedMaterials = [];
    }
  }

  queryMaterials(first?) {
    if (first) {
      this.pager.page = 0;
    }
    const params = {
      page: this.pager.page,
      size: this.pager.size,
      name: this.name,
      materialType: this.materialType,
    };
    this.mms.queryMaterial(params).subscribe((res: any) => {
      this.materialList = res.content;
      this.materialValue = [];
      this.materialList.map((item) => {
        this.materialValue.push({
          materialId: item.materialId,
          materialNo: item.materialNo,
        });
      });
      this.tableHeaderChecked = this.isAllFilteredValuesChecked(
        this.materialValue
      );
      this.pager.totalRecords = res.totalElements;
    });
  }

  /**
   * 分页
   * @param event
   */
  public onPageChange(event) {
    this.pager.page = event.page;
    this.pager.size = parseFloat(event.rows);
    this.queryMaterials();
  }

  /**
   * openMaterialDialog
   */
  public openMaterialDialog(event) {
    this.display = true;
  }

  public search(event) {
    this.queryMaterials(true);
  }

  onRowSelect(event) {
    setTimeout(() => {
      this.tableHeaderChecked = this.isAllFilteredValuesChecked(
        this.materialValue
      );
    }, 100);
  }

  onRowUnselect(event) {
    this.tableHeaderChecked = false;
  }

  /**
   * 表头全选按钮
   * tableHeaderToggle
   */
  public tableHeaderToggle() {
    let localSelection = _.cloneDeep(this.tableSelectedMaterials);
    if (this.tableHeaderChecked) {
      localSelection = localSelection.concat(this.materialValue);
      const hash = {};
      localSelection = localSelection.reduce((item, next) => {
        hash[next.materialId]
          ? ''
          : (hash[next.materialId] = true && item.push(next));
        return item;
      }, []);
    } else {
      localSelection = [...localSelection].filter((x) =>
        [...this.materialValue].every((y) => y.materialId !== x.materialId)
      );
    }
    this.tableSelectedMaterials = _.cloneDeep(localSelection);
    // console.log(this.tableHeaderChecked);
  }

  /**
   * 判断当前页面是不是被全部选中
   */
  public isAllFilteredValuesChecked(thisPageStations) {
    for (let index = 0; index < thisPageStations.length; index++) {
      const element = thisPageStations[index];
      if (!this.dataTable.isSelected(element)) {
        return false;
      }
    }
    return true;
  }

  /**
   * confirm
   */
  public confirm(event) {
    this.materialIds = this.tableSelectedMaterials
      .map((item) => item.materialId)
      .join(',');
    this.display = false;
  }

  /**
   * clear
   */
  public clear(event) {
    this.tableHeaderChecked = false;
    this.tableSelectedMaterials = [];
    this.materialIds = '';
  }

  /**
   * cancel
   */
  public cancel(event) {
    this.display = false;
  }
}
