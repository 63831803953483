import * as _ from 'lodash';

export const equivalentDayOption = {
    color: ['#32C5E9'],
    backgroundColor: 'rgba(255, 255, 255, 0)',
    tooltip: {
        trigger: 'axis'
    },
    legend: {
        data: []
    },
    xAxis: {
        type: 'category',
        name: '时间',
        boundaryGap: true,
        data: []
    },
    yAxis: {
        type: 'value',
        name: 'h'
    },
    dataZoom: [
        {
            type: 'inside',
            start: 0,
            end: 100
        }
    ],
    series: []
};

export function buildEquivalentDayChart(data) {
    let option: any = _.cloneDeep(equivalentDayOption);
    if (data) {
        option.legend.data = data.legend.data;
        option.xAxis.data = data.xAxis.data;
        option.yAxis.name = data.yAxis.name;
        option.series = data.series;
    }
    return option;
}
