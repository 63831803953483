import { StationAreaListService } from './station-area-list.service';
import { CodeTableService } from 'src/app/core/code-table.service';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { CalendarLocales } from '@shared/locales/calendar.locales';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { StationMonitorService } from '@components/station-monitor/station-monitor.service';

@Component({
  selector: 'app-station-area-list',
  templateUrl: './station-area-list.component.html',
  styleUrls: ['./station-area-list.component.less']
})
export class StationAreaListComponent implements OnInit {
  @Input() stationId: String;
  @Input() runStatus: String;
  canlendarLocale: any;

  queryForm;

  pager = { page: 0, size: 10, totalElements: 0 }
  areaStatusOptions: SelectItem[] = [];
  runStatusOptions: SelectItem[] = [];

  columns = [
    {
      field: 'areaName',
      header: '子站名称'
    },
    {
      field: 'stationTypeText',
      header: '子站类型'
    },
    {
      field: 'capacity',
      header: '装机容量 KW'
    },
    {
      field: 'areaStatusText',
      header: '子站状态'
    },
    {
      field: 'nbCount',
      header: '逆变器数量',
      rowExpansion: true
    },
    {
      field: 'runStatusText',
      header: '运行状态',
      dateFormat: true
    },
    {
      field: 'power',
      header: '实时功率 KW'
    },
    {
      field: 'dayEnergy',
      header: '日发电量 kWh'
    },
    {
      field: 'dayKwhkwp',
      header: '日等效时数 h'
    },
    {
      field: 'allEnergy',
      header: '累计发电量 kWh',
      width: 180
    },
    {
      field: 'address',
      header: '地址'
    },
    {
      field: 'userName',
      header: '业主账号'
    },
    {
      field: 'billingUserName',
      header: '业主姓名'
    },
    {
      field: 'billingUserPhone',
      header: '业主电话'
    },


  ];
  dataList = [];

  querySortParams: { sortBy: any; sortOrder: string } = {
    sortBy: 'runStatusText',
    sortOrder: 'desc',
  };
  tableLoading: boolean;


  inverterDataList = [];

  inverterColumns = [];

  tableChildLoading = false;
  areaId: any;
  isShow: boolean;
  showConfig: boolean;
  scrollHeight: string;

  constructor(private calendarLocales: CalendarLocales, private fb: FormBuilder, private codetableservice: CodeTableService, private stationarealistservice: StationAreaListService, private stationmonitorservice: StationMonitorService) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
  }



  ngOnInit() {
    this.setscrollHeight()
    this.initForm();
    this.getOptions();
    this.queryList(true);
  }

  public setscrollHeight() {
    this.scrollHeight = 0.9 * document.body.clientHeight - 41 - 14 - 17 - 90 - 50 - 41 - 56 + 'px';
  }


  public async getOptions() {
    this.areaStatusOptions = await this.codetableservice.getNemsProp(
      'stationStatus'
    );
    this.runStatusOptions = await this.codetableservice.getNemsProp(
      'stationRunStatus'
    );
  }

  public initForm() {

    this.queryForm = this.fb.group({
      areaName: new FormControl(null),
      areaStatus: new FormControl(null),
      runStatus: new FormControl(this.runStatus),
      billingUserName: new FormControl(null),
      date: new FormControl(moment().format('YYYY-MM-DD')),
    })
  }


  async queryList(first?) {
    if (first) {
      this.pager.page = 0;
    }

    const value = this.queryForm.value;

    const queryParams = {
      ...value,
      ...this.querySortParams,
      date: moment(value.date).format('YYYY-MM-DD'),
      stationId: this.stationId,
      page: this.pager.page,
      size: this.pager.size
    }

    this.tableLoading = true;
    const data = await this.stationarealistservice.getSubstations(queryParams);
    this.dataList = data.content;
    this.tableLoading = false;
    this.pager.totalElements = data.totalElements;

  }

  export() {
    const value = this.queryForm.value;
    const queryParams = {
      ...value,
      date: moment(value.date).format('YYYY-MM-DD'),
      stationId: this.stationId,
    }

    this.stationarealistservice.exportInverterDatas(queryParams);

  }

  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryList();
  }

  public customSort(event) {
    this.querySortParams = {
      sortBy: event.sortField,
      sortOrder: event.sortOrder == 1 ? 'asc' : 'desc',
    };
    this.queryList();
  }

  onRowExpand(event) {
    this.tableChildLoading = true;
    this.stationarealistservice.getInverterDatas({
      areaIds: event.data.areaId,
      page: 0,
      size: 99999,
      stationId: this.stationId,
      sortBy: 'deviceName',
      sortOrder: 'asc',
      deviceType: 'NB,JNB'
    }).subscribe((res: any) => {
      this.tableChildLoading = false;
      if (res) {
        // this.sortBy = res.sortBy;
        // this.sortOrder = res.sortOrder;
        // this.defaultSortOrder = res.sortOrder === 'asc' ? 1 : -1;
        this.inverterColumns = res.headers;
        this.inverterDataList = res.datas.content;

      }

    })
  }

  back() {
    this.showConfig = false;
  }

  /**
  * deviceDetail
  */
  public deviceDetail(deviceId, stationId, deviceType) {
    this.isShow = true;
    this.showConfig = true;
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });

  }

}
