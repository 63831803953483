import { StationMonitorService } from './../../station-monitor.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildWeatherForecastChart } from './chart/weather-forecast.chart';
import { buildWeatherStationDataChart } from './chart/weather-station-data.chart';

@Component({
    selector: 'app-weather-conditions-card',
    templateUrl: './weather-conditions-card.component.html',
    styleUrls: ['../overview.component.less', './weather-conditions-card.component.less'],
})
export class WeatherConditionsCardComponent implements OnInit, OnDestroy {
    public weather_forecast_block = true;
    public weather_station_data_block = true;

    public weatherForecastOption: any = null;
    public weatherStationDataOption: any = null;

    public weatherAlarmList: { level: string; text: string }[];

    private weatherInterval: any = null;
    private weatherIntervalTime: number = 60;
    private weatherAlarmInterval: any;
    private weatherAlarmIntervalTime: number = 60;

    constructor(private stationmonitorservice: StationMonitorService) {}

    ngOnInit() {
        this.getWeather({});
        this.weatherInterval = setInterval(() => {
            this.getWeather({});
        }, 1000 * 60 * this.weatherIntervalTime);

        this.getWeatherAlarm({});
        this.weatherAlarmInterval = setInterval(() => {
            this.getWeatherAlarm({});
        }, 1000 * 60 * this.weatherAlarmIntervalTime);
    }

    ngOnDestroy(): void {
        //Called once, before the instance is destroyed.
        //Add 'implements OnDestroy' to the class.
        clearInterval(this.weatherInterval);
        this.weatherInterval = null;
        clearInterval(this.weatherAlarmInterval);
        this.weatherAlarmInterval = null;
    }

    /**
     * getWeather
     */
    public getWeather(params) {
        // this.stationmonitorservice.weather({}).then(res=>{

        // })

        this.weatherForecastOption = buildWeatherForecastChart({});
    }

    /**
     * getWeatherAlarm
     */
    public getWeatherAlarm(params) {
        // this.stationmonitorservice.weatherAlarm({}).then(res=>{

        // })
        this.weatherStationDataOption = buildWeatherStationDataChart({});
    }
}
