import { CommonService } from './../../services/common.service';
import { AppConfigService } from './../../../app.config';
import { AuthHttp } from './../../../core/auth-http';
import { Injectable } from '@angular/core';

@Injectable()
export class WidgetsService {
  apiUrl: string;

  constructor(private http: AuthHttp, private appconfig: AppConfigService, private common: CommonService) {
    this.apiUrl = this.appconfig.apiUrl

  }

  /**
 * 发电量
 */
  public energy(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/energy`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
* 等效时数
*/
  public kwhkwp(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/kwhkwp2`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
 * 设备在线率
 */
  public runStatus(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/runStatus`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
 * 设备通讯状态
 */
  public commStatus(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/commStatus`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
  * 告警统计
  */
  public alarm1(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/alarm1`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
 * 天气情况
 */
  public weather(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/weather`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  // 场站信息
  public getStationInfo(params) {
    return this.http.get(`${this.apiUrl}/api/v2/monitor/station/info`, { params }).toPromise()
  }

  // 设备状态
  public getDeviceStatus(params) {
    return this.http.get(`${this.apiUrl}/api/dashboard/jszy/widget/yxjsSbzt`, { params }).toPromise()
  }

  // 发电统计
  public getPowerGenerationStatistics(params) {
    return this.http.get(`${this.apiUrl}/api/dashboard/jszy/widget/yxjsFdtj`, { params }).toPromise()
  }

  // 实时功率
  public getRealTimePower(params) {
    return this.http.get(`${this.apiUrl}/api/dashboard/jszy/widget/yxjsSsgl`, { params }).toPromise()
  }


  /**
 * power
 */
  public stationEnergy(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/energy`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  // 电站等效时
  public stationKwhkwp(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/kwhkwp`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  // 设备在线率
  public stationRunStatus(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/runStatus`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
 * 设备通讯状态
 */
  public stationCommStatus(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/commStatus`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
 * alarm1
 */
  public stationAlarm1(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/alarm1`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  public getAutoPlayCamera(params) {
    return this.http.get(`${this.apiUrl}/api/cameras/auto`, {
      params
    }).toPromise()
  }

  public getAccessToken() {
    return this.http.get(`${this.apiUrl}/api/cameras/devices/token/ys7`).toPromise()
  }


  public getCameraUrl(params) {
    return this.http.get(`${this.apiUrl}/api/cameras/live/address`, { params }).toPromise()

    // return new Promise(resolve => {
    //   resolve('ezopen://open.ys7.com/L43637778/1.live')
    // })
  }

}
