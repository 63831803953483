import { InvertersService } from './inverters.service';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { StationMonitorService } from '@components/station-monitor/station-monitor.service';

@Component({
  selector: 'app-inverters',
  templateUrl: './inverters.component.html',
  styleUrls: ['./inverters.component.less']
})
export class InvertersComponent implements OnInit, OnChanges {

  @Input() stationId: String;



  isShow = false;

  public invertersList = [];

  public invertersPager = { page: 0, size: 50, totalRecords: 0 };
  showConfig: boolean = false;

  constructor(private invertersservice: InvertersService, private stationmonitorservice: StationMonitorService) { }


  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.getStationInverters(changes.stationId.currentValue);
    }
  }

  public async getStationInverters(first?) {
    if (first) {
      this.invertersPager.page = 0;
    }
    const data = await this.invertersservice.getStationInverters({ stationId: this.stationId, size: this.invertersPager.size, page: this.invertersPager.page });
    this.invertersList = data.content;
    this.invertersPager.totalRecords = data.totalElements;
  }


  /**
   * onPageChange
   */
  public onInvertersPageChange(event) {
    this.invertersPager.size = event.rows;
    this.invertersPager.page = event.page;
    this.getStationInverters();
  }

  /**
   * deviceDetail
   */
  public deviceDetail(deviceId, stationId, deviceType) {
    this.isShow = true;
    this.showConfig = true;
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });

  }


  back() {
    this.showConfig = false;
  }

}
