import * as _ from "lodash";

export const alarmDistributionOption = {
    backgroundColor: "rgba(255, 255, 255, 0)",
    tooltip: {
        formatter: function(param) {
            return (
                "今日 " +
                param.value[0] +
                ":00 至 " +
                param.value[0] +
                ":59 期间发生 " +
                param.value[2] +
                " 条告警尚未复归"
            );
        }
    },
    grid: {
        left: '15%',
        right: '5%',
        top: '8%',
        bottom: '15%'
    },
    animation: false,
    xAxis: {
        type: "category",
        data: [
            "00",
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",
            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23"
        ],
        splitArea: {
            show: true,
            areaStyle: {
                color: ["rgba(250,250,250,0.1)", "rgba(200,200,200,0.1)"]
            }
        }
    },
    yAxis: {
        type: "category",
        data: [
            "组串告警",
            "汇流箱告警",
            "逆变器告警",
            "箱变告警",
            "通道信号",
            "升压站/开关站告警",
            "通道质量",
            "外线告警"
        ],
        splitArea: {
            show: true,
            areaStyle: {
                color: ["rgba(250,250,250,0.1)", "rgba(200,200,200,0.1)"]
            }
        }
    },
    visualMap: {
        show: false,
        min: 0,
        max: 50,
        calculable: false,
        orient: "horizontal",
        left: "center",
        bottom: "15%",
        color: ["#FB7293", "#FFFF99"]
    },
    series: [
        {
            name: "实时告警",
            type: "heatmap",
            label: {
                normal: {
                    show: true,
                    color: "#727272"
                }
            },
            itemStyle: {
                emphasis: {
                    shadowBlur: 10,
                    shadowColor: "#000" //'rgba(0, 0, 0, 0.5)'
                }
            }
        }
    ]
};

export function buildAlarmDistributionChart(data) {
    let option = _.cloneDeep(alarmDistributionOption);
    option.xAxis.data = data.xAxis.data;
    option.yAxis.data = data.yAxis.data;
    option.visualMap.min = data.visualMap.min;
    option.visualMap.max = data.visualMap.max;
    option.series[0]["data"] = data.series[0].data;
    return option;
}
