export function buildChart(tree) {
  // 指定图表的配置项和数据
  var option = {
    //color: ['#2196F3', '#FFC107', '#45A349', '#FF5722', '#9C27B0', '#7FBCEC', '#21C7E2', '#84A9C1', '#409E8F'],
    backgroundColor: 'rgba(255, 255, 255, 0)',
    tooltip: {
      trigger: 'none'
    },
    series: [
      {
        type: 'tree',
        symbol: 'circle',
        symbolSize: 16,
        roam: true,
        initialTreeDepth: 2,
        itemStyle: {
          borderWidth: 1
        },
        label: {
          normal: {
            position: 'top',
            verticalAlign: 'middle',
            align: 'center',
            fontSize: 12
          }
        },
        color: '#fff',
        leaves: {
          label: {
            normal: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          }
        },
        data: [tree]
      }
    ]
  }
  return option
}
