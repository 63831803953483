export function buildDeviceLineChart(returnValue, theme = null) {
  if (!returnValue) {
    returnValue = {
      title: null,
      option: {
        yAxis: [{ type: 'value' }],
        xAxis: {
          data: [],
        },
        legend: {
          data: [],
        },
        series: [],
      },
    };
  }
  let option = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: [
      {
        left: '5%',
        right: '5%',
        containLabel: true,
      },
    ],
    tooltip: {
      trigger: 'axis',
      confine: true,
    },
    toolbox: {
      feature: {
        dataZoom: {},
        restore: {},
        dataView: {
          backgroundColor: '#ffffff',
          textColor: '#000',
          optionToContent(opt) {
            let axisData = opt.xAxis[0].data;
            let series = opt.series;
            let table =
              '<table style="width:100%;text-align:center"><tbody><tr>' +
              '<td>' +
              opt.xAxis[0].name +
              '</td>';
            for (let i = 0; i < series.length; ++i) {
              table += '<td>' + series[i].name + '</td>';
            }
            table += '</tr>';
            for (let i = 0; i < axisData.length; ++i) {
              table += '<tr><td>' + axisData[i] + '</td>';
              for (let j = 0; j < series.length; ++j) {
                table += '<td>' + series[j].data[i] + '</td>';
              }
              table += '</tr>';
            }
            table += '</tbody></table>';
            return table;
          },
        },
        saveAsImage: {},
      },
    },
    dataZoom: [
      {
        type: 'inside',
        realtime: true,
      },
    ],
    legend: {
      data: returnValue.option.legend.data,
    },
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        name: '时间',
        data: returnValue.option.xAxis.data,
      },
    ],
    yAxis: [
      {
        type: 'value',
        name: returnValue.option.yAxis[0].name,
        scale: true,
      },
    ],
    series: returnValue.option.series,
  };

  return option;
}
