import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EmsService } from '../ems.service';
import { ClusterRoutingModule } from './cluster-routing.module';
import { ClusterComponent } from './cluster.component';

@NgModule({
  declarations: [ClusterComponent],
  imports: [SharedModule, ClusterRoutingModule],
  providers: [EmsService],
})
export class ClusterModule {}
