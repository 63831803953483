import { Injectable } from "@angular/core";
import { Observable, interval } from "rxjs";
import * as moment from "moment";
import { tap } from "rxjs/operators";

@Injectable()
export class SystemTimeService {
    private interval: number = 1000 * 60 * 2;
    private lastRecordTime: Date;

    public getSystemTime(): Observable<any> {
        return interval(1000).pipe(
            tap(() => {
                return new Date();
            })
        );
        // return Observable.defer(() => {
        //   if (this.lastRecordTime
        //     && moment(this.lastRecordTime)
        //       .add(this.interval, 'ms')
        //       .isAfter(this.lastRecordTime)) {

        //   } else {
        //     let interval = Observable.interval(1000);
        //     return Observable.empty();
        //   }
        // });
    }
}
