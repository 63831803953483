import { BreadcrumbService } from 'src/app/components/layout/breakcrumb/breadcrumb.service';
import {
  Component,
  OnInit,
  Input,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';

import { DeviceTreeService } from './device-tree.service';

import { Organization } from './device-tree.model';
import { newObjType } from '../../../../common/models/codeItems.model';
import { CacheService } from '../../../../common/services/cache.service';
import { Constants } from '../../../../common/Constants';
import { Location } from '@angular/common';
@Component({
  selector: 'mpt-device-tree',
  templateUrl: './device-tree.component.html',
  styleUrls: ['./device-tree.component.less'],
})
export class DeviceTreeComponent implements OnInit, OnChanges {
  public devices: any;
  @Input() public treeData;
  private url;

  constructor(
    private deviceService: DeviceTreeService,
    private router: Router,
    private cacheService: CacheService,
    private location: Location,
    private breadcrumbservice: BreadcrumbService
  ) {}

  public ngOnInit() {
    // this.treeData.forEach((item) => {
    //     item.objExpand = true;
    // });
    this.devices = this.convertToTreeNode(this.treeData);
    console.log(this.devices);

    this.expandNode(
      this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID)
    );
    // console.log(this.devices)
    // if (this.treeData) {
    //   this.devices = this.convertToTreeNode(this.treeData);
    // }
    // this.router.events.subscribe((url) => {
    //   this.url = url;
    // });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    this.devices = this.convertToTreeNode(this.treeData);

    this.expandNode(
      this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID)
    );
  }

  public loadNode(event) {
    // if (event.node && event.node.objType === newObjType.province
    //   && (!event.node.children || !event.node.children.length)) {
    //   this.deviceService.getDevicesByAreaId(event.node.objId).subscribe((res) => {
    //     event.node.children = this.convertToTreeNode(res, true);
    //   });
    // }
  }

  public selectNode(event) {
    if (!event.node) {
      return;
    }
    if (event.node.objType === newObjType.station) {
      if (this.splitUrl().route.indexOf(`/yxjs/monitorCenter/`) !== -1) {
        this.breadcrumbservice.setStation(
          {
            stationId: event.node.objId,
            shortName: event.node.objName,
          },
          '/yxjs'
        );
      } else {
        this.breadcrumbservice.setStation({
          stationId: event.node.objId,
          shortName: event.node.objName,
        });
      }
      // else if (this.splitUrl().route === '/yxjs') {
      //   this.breadcrumbservice.setStation(
      //     {
      //       stationId: event.node.objId,
      //       shortName: event.node.objName,
      //     },
      //     '/yxjs',
      //     {
      //       level: 'station',
      //       stationId: event.node.objId,
      //     }
      //   );
      // }

      // this.cacheService.setCache(
      //     Constants.KEY_CURRENT_STATION_ID,
      //     event.node.objId,
      //     true
      // );
      // if (!this.router) {
      //     this.router.navigate(['/dashboard'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //         queryParamsHandling: 'merge',
      //     });
      //     return;
      // }
      // if (_.startsWith(this.router.url, '/deviceList-v2')) {
      //     this.router.navigate(['/deviceList-v2'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //         queryParamsHandling: 'merge',
      //     });
      // } else if (_.startsWith(this.router.url, '/alarmManage/realTime')) {
      //     this.router.navigate(['/alarmManage/realTime'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //     });
      // } else if (_.startsWith(this.router.url, '/video')) {
      //     this.router.navigate(['/video'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //     });
      // } else if (_.startsWith(this.router.url, '/topology')) {
      //     this.router.navigate(['/topology'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //     });
      // } else if (
      //     _.startsWith(this.router.url, '/dashboard') ||
      //     _.startsWith(this.router.url, '/overview') ||
      //     _.startsWith(this.router.url, '/alarmManage')
      // ) {
      //     this.router.navigate(['/dashboard'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //         queryParamsHandling: 'merge',
      //     });
      // } else if (_.startsWith(this.router.url, '/discreteRate')) {
      //     this.router.navigate(['/discreteRate'], {
      //         queryParams: {
      //             stationId: event.node.objId,
      //         },
      //         queryParamsHandling: 'merge',
      //     });
      // }
    } else if (event.node && event.node.objType === newObjType.device) {
      this.router.navigate([`/deviceMonitorNew/${event.node.objId}`]);
    }
    // else {
    //     this.loadNode(event);
    //     event.node.expanded = !event.node.expanded;
    // }
  }

  /**
   *
   * 将后台返回的数据转为PrimeNg格式
   *
   * @private
   * @param {any} treeData
   * @param {boolean} [isLeaf=false] 标记是否为叶子节点, 即最后一层
   * @returns
   * @memberof DeviceTreeComponent
   */
  private convertToTreeNode(treeData, isLeaf = false) {
    for (let treeItem of treeData) {
      if (treeItem.children) {
        this.convertToTreeNode(treeItem.children);
      }
      treeItem.label = treeItem.objName;
      treeItem.icon = null;
      // treeItem.leaf = isLeaf;
      treeItem.expanded = treeItem.objExpand ? true : false;
      if (treeItem.children) {
        treeItem.selectable = false;
        treeItem.expandedIcon = 'fa fa-minus-square-o';
        treeItem.collapsedIcon = 'fa fa-plus-square-o';
      } else {
        treeItem.icon = 'iconfont icon-installed-capacity';
      }
    }
    return treeData;
  }

  public expandNode(stationId) {
    this.devices.forEach((p) => {
      let expandP = false;
      p.children.forEach((c) => {
        let expandC = false;
        c.children.forEach((station) => {
          if (station.objId == stationId) {
            station.expanded = true;
            expandC = true;
          }
        });
        if (!c.expanded) {
          c.expanded = expandC;
        }
        if (c.expanded == true) {
          expandP = true;
        }
      });
      if (!p.expanded) {
        p.expanded = expandP;
      }
    });
  }

  private splitUrl(): any {
    let url = this.location.path();
    let urlParams = {};
    if (url.indexOf('?') != -1) {
      urlParams = { route: url.split('?')[0], params: url.split('?')[1] };
    } else {
      urlParams = { route: url };
    }
    return urlParams;
  }
}
