import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wind-device-monitor',
  templateUrl: './wind-device-monitor.component.html',
  styleUrls: ['./wind-device-monitor.component.less'],
})
export class WindDeviceMonitorComponent implements OnInit {
  windInfo = [
    {
      title: '基本信息',
      color: 'rgba(52, 149, 155, 1)',
      icon: 'iconfont icon-info',
      content: [
        {
          key: '逆时针偏航',
          value: '81.67°',
        },
        {
          key: '顺时针偏航',
          value: '13.17°',
        },
        {
          key: '偏航手动控制方式',
          value: '72.84',
        },
        {
          key: '手动偏航停止',
          value: '31.60',
        },
        {
          key: '运行模式',
          value: '正常',
        },
        {
          key: '偏航模式',
          value: '正常',
        },
        {
          key: '当天的机组可利用率',
          value: '86.67%',
        },
        {
          key: '总的机组可利用率',
          value: '23.67%',
        },
        {
          key: '机组当天运行时间',
          value: '17.9h',
        },
        {
          key: '机组总的运行时间',
          value: '51244h',
        },
      ],
    },
    {
      title: '发电机信息',
      color: 'rgba(34, 160, 146, 1)',
      icon: 'iconfont icon-power-generation',
      content: [
        {
          key: '发电机转速实时值',
          value: '71.67rpm',
        },
        {
          key: '发电机有功功率设定值',
          value: '98.03KW',
        },
        {
          key: '发电机功率实时值',
          value: '93.33KW',
        },
        {
          key: '转速设定值',
          value: '31.60rpm',
        },
        {
          key: '发电机DE端温度',
          value: '43.30℃',
        },
        {
          key: '发电机NDE端温度',
          value: '25.30℃',
        },
        {
          key: '发电机冷风温度',
          value: '0.30℃',
        },
        {
          key: '定子绕组U温度',
          value: '53.30℃',
        },
        {
          key: '定子绕组V温度',
          value: '17.90℃',
        },
        {
          key: '定子绕组W温度',
          value: '51.44℃',
        },
        {
          key: '发电机转速设定值',
          value: '33.08rmp',
        },
        {
          key: '发电机有功电量',
          value: '98kWh',
        },
        {
          key: '发电机有功电量PLC',
          value: '97kWh',
        },
        {
          key: '主轴轴承A温度',
          value: '53.09℃',
        },
        {
          key: '主轴轴承B温度',
          value: '53.09℃',
        },
      ],
    },
    {
      title: '变桨信息',
      color: 'rgba(83, 160, 95, 1)',
      icon: 'iconfont icon-zhinan',
      content: [
        {
          key: '桨叶1电机温度',
          value: '50.00℃',
        },
        {
          key: '桨叶2电机温度',
          value: '13.17℃',
        },
        {
          key: '桨叶3电机温度',
          value: '72.30℃',
        },
        {
          key: '机轮旋转角度',
          value: '53.30°',
        },
        {
          key: '变桨角度',
          value: '0.30°',
        },
        {
          key: '变桨角度',
          value: '84.61°',
        },
        {
          key: '变桨角度设定值',
          value: '25.19°',
        },
        {
          key: '轮毂温度',
          value: '24.44℃',
        },
      ],
    },
    {
      title: '齿轮箱信息',
      color: 'rgba(25, 118, 210, 1)',
      icon: 'iconfont icon-config',
      content: [
        {
          key: '齿轮箱DE端温度',
          value: '25.67℃',
        },
        {
          key: '齿轮箱NDE端温度',
          value: '50.00℃',
        },
        {
          key: '主轴转速',
          value: '39.83 rpm',
        },
        {
          key: 'Y轴主动实时值',
          value: '0.38',
        },
        {
          key: 'Z轴主动实时值',
          value: '72.84',
        },
        {
          key: '齿轮温度',
          value: '15.77℃',
        },
      ],
    },
    {
      title: '变流器信息',
      color: 'rgba(9, 116, 178, 1)',
      icon: 'iconfont icon-power',
      content: [
        {
          key: '电网电压L1',
          value: '81.67V',
        },
        {
          key: '电网电压L2',
          value: '13.17V',
        },
        {
          key: '电网电压L3',
          value: '72.87V',
        },
        {
          key: '电网电流L1',
          value: '31.60A',
        },
        {
          key: '电网电流L2',
          value: '54.23A',
        },
        {
          key: '电网电流L3',
          value: '54.44A',
        },
        {
          key: '电网无功',
          value: '25.67KVar',
        },
        {
          key: '电网频率',
          value: '32.70Hz',
        },
        {
          key: '功率因数',
          value: '0.99',
        },
      ],
    },
    {
      title: '其他信息',
      color: 'rgba(55, 162, 218, 1)',
      icon: 'iconfont icon-inverter',
      content: [
        {
          key: '机舱温度',
          value: '81.67℃',
        },
        {
          key: '机舱外温度',
          value: '13.17℃',
        },
        {
          key: '电缆扭转总角度',
          value: '72.30°',
        },
        {
          key: '机舱电柜温度',
          value: '31.60℃',
        },
        {
          key: '机舱总角度',
          value: '正常°',
        },
        {
          key: '塔基温度',
          value: '正常℃',
        },
        {
          key: '塔基变压器温度',
          value: '25.67℃',
        },
        {
          key: '塔基电柜温度',
          value: '25.67℃',
        },
        {
          key: '风速',
          value: '14.00m/s',
        },
        {
          key: '风向',
          value: '50°',
        },
      ],
    },
  ];

  constructor() {}

  ngOnInit() {}
}
