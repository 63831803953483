import { FullLayoutComponent } from './../../layouts/full-layout/full-layout.component';
import { Injectable, EventEmitter } from '@angular/core';
import { Router, Params, ActivatedRoute } from '@angular/router';
import { Message } from 'primeng/primeng';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { AuthHttp } from '../../core/auth-http';
import { AppConfigService } from '../../app.config';
import { WebsocketService } from './websocket.service';
import {
  PointValue,
  PowerStation,
  DataUnit,
} from '../../components/monitor/shared/index.model';
import { TreeviewItem } from '../components/treeview/index';
import { flatMap } from 'rxjs/operators';
import { TipService } from './tip.service';

@Injectable()
export class CommonService {
  public msgs: Message[] = [];
  public tipContentSource = new Subject<Message[]>();
  public tipContent = this.tipContentSource.asObservable();

  private DATA_SUBSCRIBE_PREFIX: String = '/user/queue/viewDataToUser.';
  private DATA_SEND_PREFIX = {
    point: '/app/searchByPids',
    stationAlarm: '/app/searchAlarmLogByStationId',
    alarmNotice: '/app/searchAlarmNotice',
    alarmStatic: '/app/searchAlarmStatsByStationId',
  };

  constructor(
    public http: AuthHttp,
    private router: Router,
    private appConfig: AppConfigService,
    private websocketService: WebsocketService,
    private tipservice: TipService
  ) {}

  public subscribeData(pointArr, type: string = 'point') {
    localStorage.setItem('websocket', 'false');
    let channelName = this.createChannelName();
    return Observable.create((observer) => {
      let promise;
      if (!pointArr || !pointArr.length) {
        promise = new Promise((resolve, reject) => {
          resolve(null);
          console.warn(
            `websocket订阅的点表为空:${this.DATA_SEND_PREFIX[type]}`
          );
        });
      }
      promise = this.websocketService.send(
        this.DATA_SEND_PREFIX[type],
        {},
        JSON.stringify({
          channelName,
          pid: pointArr.join(','),
        })
      );
      promise.then(
        () => {
          localStorage.setItem('websocket', 'true');
          observer.next();
        },
        () => {
          observer.error();
        }
      );
    }).pipe(
      flatMap(() => {
        return this.websocketService.subscribe(
          this.DATA_SUBSCRIBE_PREFIX + channelName,
          type
        );
      })
    );
  }

  public unSubscribeData(destination) {
    if (destination !== null) {
      this.websocketService.unsubscribe(destination);
    }
  }

  // 路由跳转
  public routeNavigation(url: string, params?: any) {
    this.router.navigate([url, params]);
  }

  // 页面返回
  public getBackItem() {
    let backItem = localStorage.getItem('backItem');
    if (!_.isNull(backItem)) {
      return JSON.parse(backItem);
    }
    return null;
  }

  /**
   * removeBackItem
   */
  public removeBackItem() {
    localStorage.removeItem('backItem');
  }

  // 标准时间转换 为 小时
  public timeConversion(timestamp: number, type: string) {
    let date = new Date(timestamp);
    let newTime: string;

    switch (type) {
      case 'hour':
        newTime = moment(date).format('HH-mm');
        break;
      case 'day':
        newTime = moment(date).format('MM-DD');
        break;
      case 'month':
        newTime = moment(date).format('YY-MM');
        break;
      case 'year':
        newTime = moment(date).format('YY');
        break;
      default:
        break;
    }
    return newTime;
  }

  // 判断是否为空对象
  public isEmptyObject(obj): boolean {
    let flag = true;
    for (let o in obj) {
      if (o) {
        flag = false;
        break;
      }
    }
    return flag;
  }
  // 判断是否为数字
  public isNumber(obj: any): boolean {
    return obj === +obj;
  }
  // 取数组中最大的值
  public getArrMaxVal(arr) {
    let max = arr[0] === null ? 0 : arr[0];
    for (let i = 1, len = arr.length; i < len; i++) {
      if (arr[i] > max) {
        max = arr[i];
      }
    }
    return max;
  }
  // 加法 []
  public add(arr): number {
    let sum = 0;
    for (let i = 0, len = arr.length; i < len; i++) {
      if (this.isNumber(arr[i])) {
        sum += arr[i];
      }
    }
    return sum;
  }
  // 加权平均 []
  public weightedAverage(arr, valueKey: string, weightKey: string) {
    let data = [];
    let newArr = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      let value = arr[i];
      let val = value[valueKey];
      let PR = value[weightKey];
      if (this.isNumber(val) && this.isNumber(PR) && +PR !== Infinity) {
        newArr.push(val * PR);
        data.push(val);
      }
    }
    return newArr.length > 0
      ? parseFloat((this.add(newArr) / this.add(data)).toFixed(2))
      : 0;
  }

  public weightedMean(values: number[], weight: number[]) {
    let valid = [];
    let isValid = 0;
    let numerator = _.zip(values, weight).reduce((memo, val, idx) => {
      if (
        _.isNumber(val[0]) &&
        _.isFinite(val[0]) &&
        _.isNumber(val[1]) &&
        _.isFinite(val[1])
      ) {
        valid.push(val[1]);
        memo += val[0] * val[1];
      } else {
        isValid += 1;
      }
      return memo;
    }, 0);
    if (isValid === values.length) {
      return null;
    }
    let valueSum = valid.reduce((memo, val, idx) => {
      memo += val;
      return memo;
    }, 0);
    return valueSum ? parseFloat((numerator / valueSum).toFixed(2)) : 0;
  }

  public weightedAveragePercent(arr, key1: string, key2: string) {
    let data = [];
    let newArr = [];
    for (let i = 0, len = arr.length; i < len; i++) {
      let value = arr[i];
      let val = value[key1];
      let PR = value[key2];
      if (this.isNumber(val) && this.isNumber(PR / 100) && +PR !== Infinity) {
        newArr.push(val * (PR / 100));
        data.push(val);
      }
    }
    return newArr.length > 0
      ? parseFloat((this.add(newArr) / this.add(data)).toFixed(2))
      : 0;
  }
  // http请求错误
  public httpError(error?: string) {
    let message = error ? error.toString() : '系统错误';
    this.msgs = [];
    this.msgs.push({ severity: 'error', summary: message });

    return this.msgs;
  }
  public dataConversion(arr, maxData: number, bits?: number) {
    if (!bits) {
      bits = 2;
    }
    if (maxData >= 10000 && maxData < 100000000) {
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr[i] !== null) {
          arr[i] = parseFloat((arr[i] / 10000).toFixed(bits));
        }
      }
    } else if (maxData >= 100000000) {
      for (let i = 0, len = arr.length; i < len; i++) {
        if (arr[i] !== null) {
          arr[i] = parseFloat((arr[i] / 100000000).toFixed(bits));
        }
      }
    }
    return arr;
  }
  // 单位转换处理
  public unitConversion(data: number, unit: string, bits?: number): DataUnit {
    let result: DataUnit = {
      data: null,
      unit: null,
    };

    if (!bits) {
      bits = 2;
    }
    switch (unit) {
      case 'MW':
        if (data > 1000) {
          result = {
            data: parseFloat((data / 1000).toFixed(bits)),
            unit: 'GW',
          };
        } else {
          result = {
            data: parseFloat(data.toFixed(bits)),
            unit: 'MW',
          };
        }
        break;
      case 'KW':
        if (data >= 10000 && data < 100000000) {
          let val = (data / 10000).toFixed(bits);
          result = { data: parseFloat(val), unit: '万KW' };
        } else if (data >= 100000000) {
          let val = (data / 100000000).toFixed(bits);
          result = { data: parseFloat(val), unit: '亿KW' };
        } else {
          result = {
            data: parseFloat(data.toFixed(bits)),
            unit: 'KW',
          };
        }
        break;
      case 'KWh':
        if (data >= 10000 && data < 100000000) {
          let val = (data / 10000).toFixed(bits);
          result = { data: parseFloat(val), unit: '万KWh' };
        } else if (data >= 100000000) {
          let val = (data / 100000000).toFixed(bits);
          result = { data: parseFloat(val), unit: '亿KWh' };
        } else {
          result = {
            data: parseFloat(data.toFixed(bits)),
            unit: 'KWh',
          };
        }
        break;
      case '万KWh':
        if (data >= 10000 && data < 100000000) {
          let val = (data / 10000).toFixed(bits);
          result = { data: parseFloat(val), unit: '亿KWh' };
        } else if (data >= 100000000) {
          let val = (data / 100000000).toFixed(bits);
          result = { data: parseFloat(val), unit: '万亿KWh' };
        } else if (data != null) {
          result = {
            data: parseFloat(data.toFixed(bits)),
            unit: '万KWh',
          };
        }
        break;
      default:
        break;
    }
    return result;
  }
  // mask遮罩
  // tslint:disable-next-line:member-ordering
  public mask = {
    show() {
      let mask = document.getElementById('mask').className;
      if (!_.includes(mask, 'show')) {
        document.getElementById('mask').className = mask + ' show';
      }
    },
    hide() {
      let mask = document.getElementById('mask').className;
      document.getElementById('mask').className = mask.replace('show', ' ');
    },
  };

  // tip提示
  public tip(type: string, detail: string, otherMessage?: string) {
    let message = '';
    switch (type) {
      case 'success':
        message = '成功提示';
        break;
      case 'info':
        message = '信息提示';
        break;
      case 'warn':
        message = '警告提示';
        break;
      case 'error':
        message = '错误提示';
        break;
      default:
        break;
    }
    if (otherMessage) {
      message = otherMessage;
    }
    this.msgs = [];
    this.msgs.push({
      severity: type,
      summary: message,
      detail: detail ? detail : '',
    });
    console.log('this.msgs', this.msgs);

    this.tipContentSource.next(this.msgs);
  }

  // 接口异常提示
  public errorMessage(error, errorTxt: string = '') {
    this.mask.hide();
    // if (errorTxt) {
    //     this.tip('error', errorTxt);
    //     return false;
    // }
    // // if (error.status === 404) {
    // //   return false;
    // // }
    // try {
    //     this.tip('error', error.json().message);
    //     return false;
    // } catch {
    //     if (!error) {
    //         this.tip('error', '系统错误');
    //     } else {
    //         this.tip('error', error);
    //     }
    //     return false;
    // }
  }

  // 日历格式中文
  public getChinaCalendar() {
    return {
      firstDayOfWeek: 1,
      dayNames: [
        '星期日',
        '星期一',
        '星期二',
        '星期三',
        '星期四',
        '星期五',
        '星期六',
      ],
      dayNamesMin: ['日', '一', '二', '三', '四', '五', '六'],
      monthNames: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      monthNamesShort: [
        '1月',
        '2月',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
      ],
      today: '今天',
      clear: '清除',
    };
  }

  // 过滤有效参数
  public filterEffectParam(params: any) {
    _.forEach(params, (value, key) => {
      if (_.isObject(value) || _.isArray(value)) {
        if (_.isEmpty(value)) {
          params = _.omit(params, key);
        }
      }
      if (_.isNull(value)) {
        params = _.omit(params, key);
      }
    });
    return params;
  }

  /**
   * 检验数据是否合法, 如果数据的q>100或者v数据为null,则数据为非法数据
   * @private
   * @param {PointValue} pointValue
   * @returns 数据合法:true|数据非法:false
   * @memberof CommonService
   */
  public isPointValueValid(pointValue: PointValue) {
    if (!pointValue) {
      return false;
    }

    // TODO liqian 订阅回来的时间没有数据质量,先通过
    if (!pointValue.q) {
      return true;
    }

    // TODO q过滤
    return pointValue.q % 1000 < 100 && !_.isNil(pointValue.v);
    // return pointValue.q < 100 && pointValue.v !== null;
  }

  /**
   * 根据电站列表,计算装机容量相对大小
   */
  public calcCapacityRelativeSize(value: number, stations: PowerStation[]) {
    let minStation = _.minBy(stations, 'capacity');
    let maxStation = _.maxBy(stations, 'capacity');
    if (
      !minStation ||
      !minStation.capacity ||
      !maxStation ||
      !maxStation.capacity
    ) {
      return value;
    }

    let minValue = minStation.capacity;
    let maxValue = maxStation.capacity;

    try {
      if (value > 50) {
        let size = (1 + value / 500) * 25;
        if (size > 45) {
          return 45;
        }
        if (size < 25) {
          return 25;
        }
        return size;
      } else {
        let size = ((value - minValue) / (maxValue - minValue)) * 25;
        if (size > 25) {
          return 25;
        }
        if (size < 5) {
          return 10;
        }
        return size;
      }
    } catch (e) {
      return 10;
    }
  }

  public isValidNumber(n: number) {
    return _.isNumber(n) && _.isFinite(n);
  }

  // 导出excel
  public fileExport(url, param: any = {}) {
    this.mask.show();
    this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response',
        search: this.filterEffectParam(param),
      })
      .subscribe(
        (res: any) => {
          try {
            let fileNameArr = res.headers.get('Content-Disposition').split('.');
            let suffix = _.findLast(fileNameArr);
            let type;
            if (suffix === 'xlsx') {
              type =
                'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            } else if (suffix === 'xls') {
              type = 'application/vnd.ms-excel';
            }
            let blob = new Blob([res.body], { type });
            let fileName = fileNameArr[0].substring(
              fileNameArr[0].indexOf('=') + 1
            );
            if (fileNameArr.length > 2) {
              for (let i = 1, len = fileNameArr.length; i < len - 1; i++) {
                fileName += '.' + fileNameArr[i];
              }
            }
            fileName = decodeURIComponent(fileName);
            fileName = fileName.split('+').join(' ') + '.' + suffix;
            let a = document.createElement('a');
            document.body.appendChild(a);
            a.download = fileName;
            a.href = URL.createObjectURL(blob);
            a.click();
          } catch (error) {
            this.tipservice.tip('error', '系统错误，请联系管理员！');
          }
        },
        (error) => {
          this.errorMessage(error);
          this.mask.hide();
        },
        () => {
          this.mask.hide();
        }
      );
  }

  public pointFilter(data: PointValue[]) {
    return _.filter(data, this.isPointValueValid);
  }

  /**
   * setTableScrollHeight
   */
  public setTableScrollHeight(otherHeight): string {
    return (
      document.body.clientHeight -
      60 -
      20 - // layout-content padding
      19 - // container-content padding+border
      56 - // 分页条 高度
      otherHeight +
      'px'
    );
  }

  // 树 数据过滤
  public filterTreeData(name, value, data): TreeviewItem[] {
    let resultData: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
      resultData.push(
        new TreeviewItem({
          collapsed: true,
          checked: false,
          text: data[i][name],
          value: data[i][value],
        })
      );
      if (!_.isEmpty(data[i].children)) {
        resultData[i]['children'] = this.filterTreeData(
          name,
          value,
          data[i].children
        );
      }
      resultData[i].correctChecked();
    }

    return resultData;
  }

  // 创建随机数
  private createChannelName() {
    return 'CN' + new Date().getTime();
  }
}
