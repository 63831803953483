import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ViewerComponent } from './viewer.component';
import { DialogModule } from 'primeng/dialog';

@NgModule({
  declarations: [ViewerComponent],
  imports: [CommonModule, DialogModule],
  exports: [ViewerComponent],
})
export class ViewerModule {}
