import {
    HttpClient,
    HttpErrorResponse,
    HttpHeaders,
    HttpParams
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { AppConfigService } from "../app.config";

export interface IRequestOptions {
    headers?: HttpHeaders;
    observe?: "body";
    params?: HttpParams;
    reportProgress?: boolean;
    responseType?: "json";
    withCredentials?: boolean;
    body?: any;
}

export const authConfigConsts = {
    DEFAULT_TOKEN_NAME: "jwt",
    DEFAULT_HEADER_NAME: "Authorization",
    HEADER_PREFIX_BEARER: "Bearer "
};

export enum RequestMethod {
    Get = "get"
}

export function authHttpServiceCreator(
    http: HttpClient,
    appConfig: AppConfigService
) {
    return new AuthHttp(http, appConfig);
}
@Injectable({
    providedIn: "root"
})
export class AuthHttp {
    private api: string = "";

    // Extending the HttpClient through the Angular DI.
    public constructor(
        public http: HttpClient,
        private appConfig: AppConfigService
    ) {
        this.api = this.appConfig.apiUrl;
        // If you don't want to use the extended versions in some cases you can access the public property and use the original one.
        // for ex. this.httpClient.http.get(...)
    }

    /**
     * GET request
     * @param {string} endPoint it doesn't need / in front of the end point
     * @param {any} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public get<T>(endPoint: string, options?: any): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.get<T>(endPoint, newOptions);
    }

    /**
     * POST request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {any} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public post<T>(
        endPoint: string,
        params: Object,
        options?: any
    ): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.post<T>(endPoint, params, newOptions);
    }

    /**
     * PUT request
     * @param {string} endPoint end point of the api
     * @param {Object} params body of the request.
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public put<T>(
        endPoint: string,
        params: Object,
        options?: any
    ): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.put<T>(endPoint, params, newOptions);
    }

    /**
     * DELETE request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public delete<T>(endPoint: string, options?: any): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.delete<T>(endPoint, newOptions);
    }

    /**
     * PATCH request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public patch<T>(
        endPoint: string,
        params: Object,
        options?: any
    ): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.patch<T>(endPoint, params, newOptions);
    }

    /**
     * HEAD request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public head<T>(endPoint: string, options?: any): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.head<T>(endPoint, newOptions);
    }

    /**
     * OPTIONS request
     * @param {string} endPoint end point of the api
     * @param {IRequestOptions} options options of the request like headers, body, etc.
     * @returns {Observable<T>}
     */
    public options<T>(endPoint: string, options?: any): Observable<T> {
        if (options && options.search) {
            options.params = this.formathttpParams(options.search);
            options = _.extend({}, _.omit(options, ["search"]));
        }
        let newOptions: IRequestOptions = _.extend({}, options);
        return this.http.options<T>(endPoint, newOptions);
    }

    private formathttpParams(params: object) {
        let httpParams = new HttpParams();
        if (!_.isEmpty(params)) {
            for (const key in params) {
                if (params[key] === false || params[key] == 0 || params[key]) {
                    httpParams = httpParams.set(key, params[key]);
                }
            }
        }
        return httpParams;
    }
}

export function removeToken(tokenName = authConfigConsts.DEFAULT_TOKEN_NAME) {
    localStorage.removeItem(tokenName);
    // location.reload();
}

export function saveToken(
    jwt: string,
    tokenName = authConfigConsts.DEFAULT_TOKEN_NAME
) {
    localStorage.setItem(tokenName, jwt);
}

/**
 * Helper class to decode and find JWT expiration.
 */

export class JwtHelper {
    public urlBase64Decode(str: string): string {
        let output = str.replace(/-/g, "+").replace(/_/g, "/");
        switch (output.length % 4) {
            case 0: {
                break;
            }
            case 2: {
                output += "==";
                break;
            }
            case 3: {
                output += "=";
                break;
            }
            default: {
                throw "Illegal base64url string!";
            }
        }
        return this.b64DecodeUnicode(output);
    }

    // credits for decoder goes to https://github.com/atk
    private b64decode(str: string): string {
        let chars =
            "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
        let output: string = "";

        str = String(str).replace(/=+$/, "");

        if (str.length % 4 == 1) {
            throw new Error(
                "'atob' failed: The string to be decoded is not correctly encoded."
            );
        }

        for (
            // initialize result and counters
            let bc: number = 0, bs: any, buffer: any, idx: number = 0;
            // get next character
            (buffer = str.charAt(idx++));
            // character found in table? initialize bit storage and add its ascii value;
            ~buffer &&
            ((bs = bc % 4 ? bs * 64 + buffer : buffer),
            // and if not first of each 4 characters,
            // convert the first 8 bits to one ascii character
            bc++ % 4)
                ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
                : 0
        ) {
            // try to find character in table (0-63, not found => -1)
            buffer = chars.indexOf(buffer);
        }
        return output;
    }

    // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
    private b64DecodeUnicode(str: any) {
        return decodeURIComponent(
            Array.prototype.map
                .call(this.b64decode(str), (c: any) => {
                    return (
                        "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
                    );
                })
                .join("")
        );
    }

    public decodeToken(token: string): any {
        let parts = token.split(".");

        if (parts.length !== 3) {
            removeToken();
            throw new Error("JWT must have 3 parts");
        }

        let decoded = this.urlBase64Decode(parts[1]);
        if (!decoded) {
            removeToken();
            throw new Error("Cannot decode the token");
        }
        return JSON.parse(decoded);
    }

    public getTokenExpirationDate(token: string): Date {
        let decoded: any;
        decoded = this.decodeToken(token);

        if (!decoded.hasOwnProperty("exp")) {
            return null;
        }

        let date = new Date(0); // The 0 here is the key, which sets the date to the epoch
        date.setUTCSeconds(decoded.exp);

        return date;
    }

    public isTokenExpired(token: string, offsetSeconds?: number): boolean {
        let date = this.getTokenExpirationDate(token);
        offsetSeconds = offsetSeconds || 0;

        if (date == null) {
            return false;
        }
        // Token expired?
        return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
    }
}

export function tokenNotExpired(
    tokenName = authConfigConsts.DEFAULT_TOKEN_NAME,
    jwt?: string
): boolean {
    const token: string = jwt || localStorage.getItem(tokenName);

    const jwtHelper = new JwtHelper();

    return token != null && !jwtHelper.isTokenExpired(token);
}
