import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from '../../../../common/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { CacheService } from '../../../../common/services/cache.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Constants } from '../../../../common/Constants';
import { debounceTime } from 'rxjs/operators';
import { fromEvent, forkJoin } from 'rxjs';
import { StationMonitorService } from '../../station-monitor.service';
import { LazyLoadEvent } from 'primeng/api';
import { buildTimeChart } from '../chart/time.chart';
import { buildBarChart } from '../chart/bar.chart';
import { buildLineChart } from '../chart/line.chart';
import * as _ from 'lodash';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.less'],
})
export class LineComponent implements OnInit, OnDestroy {
  public pager = { page: 0, size: 50, totalRecords: 1 };

  public stationId: any;
  public resizeEvent: any;
  public scrollHeight = '0px';
  public form: any;
  public areaList: any;
  public useFlagList: any;
  public runStatusList: any;
  public sortBy: any;
  public sortOrder: any;
  public tableloading: boolean = true;
  public timeChartFields = [];
  public lineChartFields = [];
  public barChartFields = [];
  public tableTitle = '';
  public defaultSortOrder = 1;
  public options = null;
  public columns = [];
  public lineLoading = true;
  public dataList = [];
  public toggle = true;
  intervalQuery: NodeJS.Timer;
  echartWidth: any;

  constructor(
    private common: CommonService,
    private stationmonitorservice: StationMonitorService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private formBuilder: FormBuilder,
    private tipservice: TipService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        const stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
      } else {
        this.stationId = params.stationId;
      }
      this.initForm();
      this.form.patchValue({
        runStatus: this.stationmonitorservice.getRunStatusCache(),
      });
      this.lineQuery(true);
    });

    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setscrollHeight();
      });
  }

  ngOnInit() {
    this.intervalQuery = setInterval(() => {
      this.lineQuery();
    }, 1 * 1000 * 60);
  }

  ngOnDestroy(): void {
    if (this.intervalQuery) {
      clearInterval(this.intervalQuery);
      this.intervalQuery = null;
    }
  }

  public setscrollHeight() {
    let lineHeight = 50 + 88 + 50 + 66;
    this.scrollHeight = this.common.setTableScrollHeight(lineHeight);
  }

  public initForm() {
    this.runStatusList = [
      { label: '', value: null },
      { label: '正常', value: 0 },
      { label: '告警', value: 1 },
      { label: '通讯中断', value: 2 },
    ];
    let areaParams = {
      stationId: this.stationId,
    };
    let useFlagParams = {
      typeId: 'deviceUseFlag',
    };
    forkJoin(
      this.stationmonitorservice.getArea(areaParams),
      this.stationmonitorservice.getUseFlagGroups(useFlagParams)
    ).subscribe((res) => {
      this.areaList = res[0];
      this.useFlagList = res[1];
    });

    this.form = this.formBuilder.group({
      areaIds: new FormControl(''),
      useFlags: new FormControl(''),
      runStatus: new FormControl(''),
      deviceName: new FormControl(''),
    });

    this.setscrollHeight();
  }

  public lineQuery(first?) {
    if (first) {
      this.queryTable(true);
      this.buildChart(null);
    } else {
      this.pager.page = 0;
      this.refreshData();
    }
  }

  public queryTable(first?) {
    if (first) {
      this.pager.page = 0;
      this.sortBy = 'AM-WX-PHASEUA';
      this.sortOrder = 'asc';
    }
    let params = _.assign({}, this.form.value, {
      page: this.pager.page,
      size: this.pager.size,
      stationId: this.stationId,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
    this.tableloading = true;
    this.stationmonitorservice.getLineDatas(params).subscribe((res: any) => {
      this.tableloading = false;
      this.timeChartFields = [];
      this.lineChartFields = [];
      this.barChartFields = [];
      if (res) {
        // this.sortBy = res.sortBy;
        // this.sortOrder = res.sortOrder;
        // this.defaultSortOrder = res.sortOrder === 'asc' ? 1 : -1;
        this.columns = res.headers;
        this.dataList = res.datas.content;
        if (
          this.dataList.length === 0 &&
          params.deviceName &&
          !params.areaIds &&
          !params.useFlags &&
          !params.runStatus
        ) {
          this.tipservice.tip(
            'warn',
            `当前电站没有【${params.deviceName}】设备`,
            '查询设备提示'
          );
        }
        this.pager.totalRecords = res.datas.totalElements;
        for (let header of res.headers) {
          if (header.chart === 'time') {
            this.timeChartFields.push(header.code);
          }
          if (header.chart === 'line') {
            this.lineChartFields.push(header.code);
          }
          if (header.chart === 'bar') {
            this.barChartFields.push(header.code);
          }
        }
      }
    });
  }

  // 创建外线chart
  public buildChart(column?) {
    this.lineLoading = true;
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
      column,
    });

    this.stationmonitorservice.getLineCharts(params).subscribe((res: any) => {
      this.echartWidth = (
        document.querySelector('.list-content') as any
      ).offsetWidth;
      this.lineLoading = false;
      this.tableTitle = res.title;
      if (this.timeChartFields.indexOf(column) !== -1) {
        this.options = buildTimeChart(res);
      }

      if (this.barChartFields.indexOf(column) !== -1 || column === null) {
        this.options = buildBarChart(res);
      }

      if (this.lineChartFields.indexOf(column) !== -1 || column == null) {
        this.options = buildLineChart(res);
      }
      // this.toggle = false;
    });
  }

  public customSort(event: LazyLoadEvent) {
    this.sortBy = event.sortField;
    this.sortOrder = event.sortOrder === 1 ? 'asc' : 'desc';
    this.refreshData();
  }

  public refreshData() {
    this.queryTable();

    if (
      this.timeChartFields.indexOf(this.sortBy) !== -1 ||
      this.lineChartFields.indexOf(this.sortBy) !== -1 ||
      this.barChartFields.indexOf(this.sortBy) !== -1
    ) {
      this.toggle = true;
      this.buildChart(this.sortBy);
    }
  }

  /**
   * onPageChange
   */
  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryTable();
  }

  /**
   * export
   */
  public export() {
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
    });
    this.stationmonitorservice.lineExport(params);
  }

  /**
   * deviceDetail
   */
  public deviceDetail(deviceId, stationId, deviceType) {
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });
  }

  public setRunStatus(event) {
    this.stationmonitorservice.setRunStatusCache(this.form.value.runStatus);
  }
}
