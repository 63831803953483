import { WidgetsService } from '../../widgets.service';
import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import * as echarts from 'echarts';


@Component({
  selector: 'app-station-real-time-power',
  templateUrl: './real-time-power.component.html',
  styleUrls: ['./real-time-power.component.less']
})
export class StationRealTimePowerComponent implements OnInit, OnChanges {

  @Input() stationId: string;

  public option = {};
  public loading = true;

  constructor(private widgetsservice: WidgetsService) { }

  ngOnInit() {
    // this.getRealTimePower({ stationId: this.stationId });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.getRealTimePower({ stationId: changes.stationId.currentValue });
    }
  }

  getRealTimePower(params) {
    this.loading = true;
    this.widgetsservice.getRealTimePower(params).then(res => {
      this.loading = false;
      this.option = this.buildOptions(res);
    })
  }

  public buildOptions(data) {
    return {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          label: {
            backgroundColor: '#6a7985',
          },
        },
      },
      legend: {
        show: false,
      },
      grid: {
        top: '15%',
        left: '8%',
        right: '4%',
        bottom: '5%',
        containLabel: true,
      },
      xAxis: [
        {
          type: 'category',
          axisLine: {
            lineStyle: {
              color: '#56677F',
            },
          },
          boundaryGap: false,
          axisTick: {
            show: false,
          },
          data: data.xAxis.data
        },
      ],
      yAxis: [
        {
          type: 'value',
          name: data.yAxis.name,
          splitLine: {
            show: false,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: '#56677F',
            },
          },
        },
      ],
      series: [
        {
          name: '功率',
          type: 'line',
          // smooth: true,
          showSymbol: false,
          areaStyle: {
            opacity: 1,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: 'rgba(34,94,155,0.69)' },
              { offset: 1, color: 'rgba(56,138,219,0.00)' },
            ]),
          },
          lineStyle: {
            width: 2,
            color: '#00C0FF',
          },
          symbol: 'react',
          data: data.series[0].data,
        },
      ],
    }
  }

}
