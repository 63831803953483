import { Injectable } from "@angular/core";

import { AuthHttp } from "../../core/auth-http";
import { AppConfigService } from "../../app.config";

@Injectable()
export class StationsService {
    public constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService
    ) {}

    public getStations() {
        return this.http.get(
            `${this.appConfig.apiUrl}/api/index/getSampleStationAll`
        );
    }

    public getDeviceGroupStations() {
        return this.http.get(
            `${this.appConfig.apiUrl}/api/deviceGroup/stations`
        );
    }

    public getDeviceGroupStationsPromise() {
        return this.http
            .get(`${this.appConfig.apiUrl}/api/deviceGroup/stations`)
            .toPromise();
    }

    public getAreasByStationId(stationId) {
        return this.http.get(`${this.appConfig.apiUrl}/api/stationAreas/all`, {
            search: { stationId }
        });
    }

    public getStationInfoByStationId(stationId) {
        return this.http.get(
            `${this.appConfig.apiUrl}/api/station/getStationByStationId`,
            {
                search: { stationId }
            }
        );
    }
}
