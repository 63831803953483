import * as _ from 'lodash';

export const totalAlarmCountOption = {
  //color: ['#2196F3', '#FFC107', '#45A349', '#FF5722', '#9C27B0', '#7FBCEC', '#21C7E2', '#84A9C1', '#409E8F'],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)',
  },
  legend: {
    type: 'scroll',
    orient: 'vertical',
    left: 'right',
    top: 'middle',
    data: [
      '外线告警',
      '箱变告警',
      '汇流箱告警',
      '组串告警',
      '通道质量',
      '逆变器告警',
      '升压站/开关站告警',
      '通道信号',
    ],
    // formatter: function(name) {
    //     if (name == "箱变告警") return name + " +2";
    //     else if (name == "逆变器告警") return name + " +3";
    //     else return name;
    // }
  },
  series: [
    {
      name: '告警类型',
      type: 'pie',
      radius: ['50%', '70%'],
      avoidLabelOverlap: false,
      center: ['35%', '50%'],
      cursor: 'default',
      label: {
        normal: {
          show: false,
          position: 'center',
        },
        emphasis: {
          show: true,
          textStyle: {
            fontSize: '24',
            fontWeight: 'bold',
          },
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [
        { value: 2, name: '外线告警' },
        { value: 72, name: '箱变告警' },
        { value: 12, name: '汇流箱告警' },
        { value: 33, name: '组串告警' },
        { value: 0, name: '通道质量' },
        { value: 72, name: '逆变器告警' },
        { value: 12, name: '升压站/开关站告警' },
        { value: 3, name: '通道信号' },
      ],
    },
  ],
};

export function buildTotalAlarmCountChart(data) {
  let option = _.cloneDeep(totalAlarmCountOption);
  let lengend = [];
  data.map((item) => {
    lengend.push(item.name);
  });
  option.legend.data = lengend;
  option.series[0].data = data;
  return option;
}
