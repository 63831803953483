import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { CommonService } from '../../../common/services/common.service';

@Injectable()
export class AlarmManageService {
    public stationList: any[] = [];
    // public stationList: Promise<any[]> = null;
    public alarmLevelSubject = new Subject();
    public isRealTimeSubject = new Subject();
    public stationSubject = new Subject();
    public levelSubject = new Subject();
    private apiUrl = this.appConfig.apiUrl;
    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private commonService: CommonService
    ) {}

    public getStationList(): Promise<any[]> {
        if (this.stationList.length === 0) {
            return this.http
                .get(`${this.apiUrl}/api/index/getSampleStationAll`)
                .toPromise()
                .then((res: any) => {
                    this.stationList = res;
                    return this.stationList;
                });
        } else {
            return new Promise((resolve, reject) => {
                resolve(this.stationList);
            });
        }
    }

    public alarmLogInit() {
        return this.http.get(`${this.apiUrl}/api/alarmLog/init`).toPromise();
    }

    // public getStationList(): Promise<any[]> {
    //   if (!this.stationList) {
    //     this.stationList = this.http
    //       .get(`${this.apiUrl}/api/index/getSampleStationAll`)
    //       .map(res => {
    //         return res.json();
    //       })
    //       .toPromise();
    //   }
    //   return this.stationList;
    // }
}
