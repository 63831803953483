import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { FullCalendarModule } from 'primeng/fullcalendar';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressSpinnerModule } from 'primeng/primeng';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { AppComponent } from './app.component';
import { AppConfigService } from './app.config';
import { AppRoutes } from './app.routes';
import { LoginModule } from './base/login/login.module';
import { NoContentComponent } from './base/no-content/no-content.component';
import { NoPermissionComponent } from './base/no-permission/no-permission.component';
import { DeptSelectorModule } from './common/components/dept-selector/dept-selector.module';
import { TipModule } from './common/components/tip/tip.module';
import { DefaultRouteGuard } from './common/guards/DefaultRoute.guard';
import { CacheService } from './common/services/cache.service';
import { TopoService } from './common/services/topo.service';
import { TopoTabService } from './common/services/topoTab.service';
import { AppBreadcrumbComponent } from './components/layout/breakcrumb/app.breadcrumb.component';
import { BreadcrumbService } from './components/layout/breakcrumb/breadcrumb.service';
import { AppFooterComponent } from './components/layout/footer/app.footer.component';
import { DeviceTreeModule } from './components/layout/header/device-tree/device-tree.module';
import { HeaderModule } from './components/layout/header/header.module';
import {
  AppMenuComponent,
  AppSubMenuComponent,
} from './components/layout/menu/app.menu.component';
import { NavModule } from './components/layout/nav/nav.module';
import { AppProfileComponent } from './components/layout/profile/app.profile.component';
import { AppTopBarComponent } from './components/layout/topbar/app.topbar.component';
import { TopbarService } from './components/layout/topbar/app.topbar.service';
import { MapOverviewModule } from './components/map-overview/map-overview.module';
import { TopoModule } from './components/topo/topo.module';
import { CoreModule } from './core/core.module';
import { httpInterceptorProviders } from './core/http-interceptors';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { FullLayoutDashboardComponent } from './layouts/full-layout-dashboard/full-layout-dashboard.component';
import { FullScreenLayoutComponent } from './layouts/full-screen-layout/full-screen-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout/simple-layout.component';
import { SharedModule } from './shared/shared.module';
import { MonitorStationModule } from './components/ems/monitor-station/monitor-station.module';
import { ClusterModule } from './components/ems/cluster/cluster.module';
import { EarthMapBgService } from '@shared/components/earth-map-bg/earth-map-bg.service';
import { EarthMapBgComponent } from '@shared/components/earth-map-bg/earth-map-bg.component';
import { FullLayoutDashboardService } from './layouts/full-layout-dashboard/full-layout-dashboard.service';
import { WidgetsModule } from './common/components/widgets/widgets.module';
import { InvertersModule } from '@shared/components/inverters/inverters.module';
import { StationMonitorModule } from '@components/station-monitor/station-monitor.module';
import { StationAreaListModule } from '@shared/components/station-area-list/station-area-list.module';
import { TipService } from '@common/services/tip.service';
import { WindModule } from '@components/wind/wind.module';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AppRoutes,
    SharedModule,
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    CalendarModule,
    CardModule,
    CarouselModule,
    ChartModule,
    CheckboxModule,
    ChipsModule,
    CodeHighlighterModule,
    ConfirmDialogModule,
    ColorPickerModule,
    ContextMenuModule,
    DataViewModule,
    DialogModule,
    DropdownModule,
    EditorModule,
    FieldsetModule,
    FileUploadModule,
    FullCalendarModule,
    GalleriaModule,
    GrowlModule,
    InplaceModule,
    InputMaskModule,
    InputSwitchModule,
    InputTextModule,
    InputTextareaModule,
    LightboxModule,
    ListboxModule,
    MegaMenuModule,
    MenuModule,
    MenubarModule,
    MessageModule,
    MessagesModule,
    MultiSelectModule,
    OrderListModule,
    OrganizationChartModule,
    OverlayPanelModule,
    PaginatorModule,
    PanelModule,
    PanelMenuModule,
    PasswordModule,
    PickListModule,
    ProgressBarModule,
    RadioButtonModule,
    RatingModule,
    ScrollPanelModule,
    SelectButtonModule,
    SlideMenuModule,
    SliderModule,
    SpinnerModule,
    SplitButtonModule,
    StepsModule,
    TableModule,
    TabMenuModule,
    TabViewModule,
    TerminalModule,
    TieredMenuModule,
    ToastModule,
    ToggleButtonModule,
    ToolbarModule,
    TooltipModule,
    TreeModule,
    TreeTableModule,
    ProgressSpinnerModule,
    VirtualScrollerModule,
    NavModule,
    HeaderModule,
    TipModule,
    TopoModule,
    DeptSelectorModule,
    LoginModule,
    DeviceTreeModule,
    MapOverviewModule,
    MonitorStationModule,
    StationMonitorModule,
    ClusterModule,
    WidgetsModule,
    InvertersModule,
    StationAreaListModule,
    WindModule,
  ],
  declarations: [
    AppComponent,
    AppMenuComponent,
    AppSubMenuComponent,
    AppProfileComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppFooterComponent,
    SimpleLayoutComponent,
    FullLayoutComponent,
    FullLayoutDashboardComponent,
    FullScreenLayoutComponent,
    NoContentComponent,
    NoPermissionComponent,
    EarthMapBgComponent,
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    AppConfigService,
    httpInterceptorProviders,
    CacheService,
    BreadcrumbService,
    TopbarService,
    DefaultRouteGuard,
    TopoService,
    TopoTabService,
    EarthMapBgService,
    FullLayoutDashboardService,
    TipService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
