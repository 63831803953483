import { Component, OnInit } from "@angular/core";
import { buildTotalHealthChart } from "./chart/total-health.chart";
import { buildSubHealthChart } from "./chart/sub-health.chart";

@Component({
    selector: "app-health-card",
    templateUrl: "./health-card.component.html",
    styleUrls: ["../overview.component.less", "./health-card.component.less"]
})
export class HealthCardComponent implements OnInit {
    //总健康度 图表option
    public totalHealthOption: any = null;
    public total_health_block = true;

    //分项健康度 图表option
    public subHealthOption: any = null;
    public sub_health_block = true;

    constructor() {}

    ngOnInit() {
        this.buildTotalHealthCharts();
        this.buildSubHealthCharts();
    }

    //总健康图表
    public buildTotalHealthCharts() {
        this.totalHealthOption = buildTotalHealthChart(0.81);
    }
    //分项健康图表
    public buildSubHealthCharts() {
        this.subHealthOption = buildSubHealthChart();
    }
}
