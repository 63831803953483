import { WidgetsService } from './../../widgets.service';
import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
  OnChanges,
} from '@angular/core';
import { BasicDataService } from '@components/basic-data/shared/basic-data.service';
import * as _ from 'lodash';
import { AppConfigService } from 'src/app/app.config';
declare const EZUIKit;
declare const Hls;

@Component({
  selector: 'app-station-monitor',
  templateUrl: './station-monitor.component.html',
  styleUrls: ['./station-monitor.component.less'],
})
export class StationMonitorComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input() stationId: string;
  accessToken: any;
  display: boolean;
  playr: any;
  showVideo: boolean;
  stationPic: any;
  fileEndpointUrl: string;

  constructor(
    private widgetsservice: WidgetsService,
    private basicDataService: BasicDataService,
    private appConfig: AppConfigService
  ) {
    this.fileEndpointUrl = this.appConfig.fileEndpointUrl;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      setTimeout(() => {
        this.initCamera();
      }, 0);
    }
  }

  public getPic(stationId) {
    this.basicDataService
      .getPic({
        stationId,
      })
      .subscribe((res) => {
        this.stationPic = this.fileEndpointUrl + res[0].uri;
      });
  }

  async initCamera() {
    this.destoryPlayr();

    const width = document.getElementById('video-container').offsetWidth * 0.9;
    const height =
      document.getElementById('video-container').offsetHeight * 0.9;

    const cameraInfo = await this.widgetsservice.getAutoPlayCamera({
      stationId: this.stationId,
    });
    this.showVideo = true;
    if (cameraInfo) {
      const params = {
        cameraIds: (cameraInfo as any).cameraId,
        protocol: 2,
        quality: 2,
      };
      const object = await this.widgetsservice.getCameraUrl(params);
      if (_.isEmpty(object)) {
        return;
      }

      // const playr = new EZUIKit.EZUIKitPlayer({
      //   id: 'video', // 视频容器ID
      //   accessToken,
      //   url: data,
      //   template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题 pcLive；
      //   // plugin: ['talk'], // 加载插件，talk-对讲
      //   width,
      //   height,
      // });

      let video = document.getElementById('video') as any;
      let hls = null;
      video.width = width;
      video.height = height;
      let videoSrc = object[(cameraInfo as any).cameraId].url;
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.muted = true;
          video.play();
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc;
        video.addEventListener('loadedmetadata', function () {
          video.muted = true;
          video.play();
        });
      }

      this.playr = {
        hls,
        video,
      };
    } else {
      this.showVideo = false;
      this.getPic(this.stationId);
    }
  }

  async ngAfterViewInit() {
    // this.initCamera();
  }

  ngOnDestroy(): void {
    // throw new Error('Method not implemented.');
    this.destoryPlayr();
  }

  public destoryPlayr() {
    if (this.playr && this.playr.hls) {
      this.playr.hls.destroy();
      this.playr.hls = null;
    }

    if (this.playr && this.playr.video) {
      this.playr.video.pause();
    }
    this.playr = {};
  }

  hideEvent() {
    this.display = false;
  }
}
