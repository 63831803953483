import { AppConfigService } from './../../app.config';
import { AuthHttp } from './../../core/auth-http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

  public getPageConfigs() {
    return this.http.get(`${this.appConfig.apiUrl}/api/login/pageConfigs`);
  }
}
