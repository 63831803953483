import * as _ from 'lodash';
import { VideoPlayDialogService } from './video-play-dialog.service';
import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
declare const EZUIKit;
declare const Hls;

@Component({
  selector: 'app-video-play-dialog',
  templateUrl: './video-play-dialog.component.html',
  styleUrls: ['./video-play-dialog.component.less']
})
export class VideoPlayDialogComponent implements OnInit, AfterViewInit {


  @Input() display: boolean;
  @Input() stationId: boolean;
  @Output() hideEvent = new EventEmitter();


  cameraList: any = [];

  page = 0;

  maxPage = [];
  width: any;
  height: any;
  accessToken: any;

  players = [];
  showVideoList: any = [];

  showHls = true;
  playr: any;

  constructor(
    private videoplaydialogservice: VideoPlayDialogService
  ) { }



  ngOnInit() {
  }

  async ngAfterViewInit() {

    let cameraList = await this.videoplaydialogservice.getCameras({
      stationId: this.stationId
    });
    // this.cameraList.length = 18;
    const cameraIds = (cameraList as any).map(item => item.cameraId).join('');

    const params = {
      cameraIds,
      protocol: 2,
      quality: 2
    }


    const object = await this.videoplaydialogservice.getCamerasLiveAddress(params);
    this.cameraList = [];
    if (_.isEmpty(object)) {
      return
    }
    for (const key in (object as any)) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        const element = object[key];
        this.cameraList.push({ cameraId: key, url: element.url })
      }
    }

    for (let index = 0; index < Math.ceil(this.cameraList.length / 16); index++) {
      this.maxPage.push(index);
    }

    this.accessToken = await this.videoplaydialogservice.getAccessToken()

    this.width = (document.querySelector('.camera-list') as any).offsetWidth;
    this.height = (document.querySelector('.camera-list') as any).offsetHeight;

    const length = this.cameraList.length;
    let width = 0;
    let height = 0;
    if (length <= 4) {
      width = Math.floor(this.width / 2);
      height = Math.floor(this.height / 2);
    } else if (length <= 6 && length > 4) {
      width = Math.floor(this.width / 3);
      height = Math.floor(this.height / 2);
    } else if (length <= 9 && length > 6) {
      width = Math.floor(this.width / 3);
      height = Math.floor(this.height / 3);
    } else if (length <= 12 && length > 9) {
      width = Math.floor(this.width / 4);
      height = Math.floor(this.height / 3);
    } else if (length > 12) {
      width = Math.floor(this.width / 4);
      height = Math.floor(this.height / 4);
    }
    let array = this.cameraList;
    this.showVideoList = array.slice(this.page * 16, (this.page + 1) * 16);
    setTimeout(() => {
      this.initCamera(width, height);
    }, 0);

  }

  initCamera(width, height) {
    if (!_.isEmpty(this.players)) {
      this.destoryPlayers();
    }

    for (let index = 0; index < this.showVideoList.length; index++) {
      let hls = null;
      let video = (document.getElementById('video-dialog-' + index) as any);
      video.width = width;
      video.height = height;
      let videoSrc = this.showVideoList[index].url;
      if (Hls.isSupported()) {
        hls = new Hls();
        hls.loadSource(videoSrc);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          video.play();
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = videoSrc;
        video.addEventListener('loadedmetadata', function () {
          video.play();
        });
      }
      this.players.push({
        hls,
        video
      });
    }
  }

  hide() {
    this.destoryPlayers();
    this.destoryPlayr();
    this.hideEvent.emit();
  }

  pageChange(page) {
    this.page = page;
    let array = this.cameraList;
    this.showVideoList = array.slice(this.page * 16, (this.page + 1) * 16);
    let length = this.showVideoList.length;
    let width = 0;
    let height = 0;
    if (length <= 4) {
      width = Math.floor(this.width / 2);
      height = Math.floor(this.height / 2);
    } else if (length <= 6 && length > 4) {
      width = Math.floor(this.width / 3);
      height = Math.floor(this.height / 2);
    } else if (length <= 9 && length > 6) {
      width = Math.floor(this.width / 3);
      height = Math.floor(this.height / 3);
    } else if (length <= 12 && length > 9) {
      width = Math.floor(this.width / 4);
      height = Math.floor(this.height / 3);
    } else if (length > 12) {
      width = Math.floor(this.width / 4);
      height = Math.floor(this.height / 4);
    }

    setTimeout(() => {
      this.initCamera(width, height);
    }, 500);
  }


  async showBigScreen(index) {
    // this.showhdVideo = true;
    this.showHls = false;

    const showCamera = this.cameraList[index];

    const params = {
      cameraIds: showCamera.cameraId,
      protocol: 2,
      quality: 1
    }

    const object = await this.videoplaydialogservice.getCamerasLiveAddress(params);
    // console.log(object, showCamera.cameraId, object[showCamera.cameraId]);

    // this.playr = new EZUIKit.EZUIKitPlayer({
    //   id: 'video-container-main', // 视频容器ID
    //   accessToken:
    //     this.accessToken,
    //   url: object[showCamera.cameraId].url,
    //   template: 'pcLive', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
    //   // plugin: ['talk'], // 加载插件，talk-对讲
    //   width: this.width,
    //   height: this.height,
    //   staticPath: "/resources/videoplayer/ezuikit_static/v66",
    // });

    let video = (document.getElementById('video-container-main') as any);
    let hls = null;
    video.width = this.width;
    video.height = this.height;
    let videoSrc = object[showCamera.cameraId].url;
    if (Hls.isSupported()) {
      hls = new Hls();
      hls.loadSource(videoSrc);
      hls.attachMedia(video);
      hls.on(Hls.Events.MANIFEST_PARSED, function () {
        video.play();
      });
    } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
      video.src = videoSrc;
      video.addEventListener('loadedmetadata', function () {
        video.play();
      });
    }

    this.playr = {
      video,
      hls,
    }

    this.destoryPlayers();

  }

  public destoryPlayr() {
    if (this.playr.hls) {
      this.playr.hls.destroy();
      this.playr.hls = null;
    }

    if (this.playr.video) {
      this.playr.video.pause();
    }
    this.playr = {}
  }



  public destoryPlayers() {
    this.players.map(item => {
      if (item.video) {
        item.video.pause();
      }
      if (item.hls) {
        item.hls.destroy();
        item.hls = null;
      }
    })
    this.players = [];
  }


  showOtherCamera() {
    this.destoryPlayr();
    this.showHls = true;
    this.pageChange(this.page)

  }
}
