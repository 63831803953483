import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';

import { CommonService } from '../../services/common.service';
import { ClassesService } from './classes-category.service';
import {
  TreeviewI18n,
  DefaultTreeviewI18n,
  TreeviewItem,
  TreeviewConfig,
} from '../treeview';

@Component({
  selector: 'mpt-classes-category',
  templateUrl: './classes-category.component.html',
  providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }],
})
export class ClassesCategoryComponent implements OnInit, OnChanges {
  @Input() public values: string[] = [];
  @Input() public value = ''; // 单选初始化值
  @Input() public type: string;
  @Input() public isReset = false;
  @Input() public isRadio = false;
  @Output() public getVal = new EventEmitter<string>();
  @Output() public getParams = new EventEmitter<string>();

  public treeData: any = [];
  public items: TreeviewItem[] = [];
  public categoryData: TreeviewItem[];
  public mulitConfig = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 200,
  });
  flattenMap: any = [];

  constructor(
    private common: CommonService,
    private classesService: ClassesService
  ) {}

  public flatten(arr) {
    return arr.reduce((result, item) => {
      return result.concat(
        item,
        Array.isArray(item.children) ? this.flatten(item.children) : []
      );
    }, []);
  }

  public ngOnInit() {
    this.classesService
      .getClassesType(this.type)
      .then((res) => {
        this.treeData = res;

        this.flattenMap = this.flatten(res);
        this.items = this.common.filterTreeData('className', 'classId', res);
      })
      .catch((error) => {
        this.common.errorMessage(error);
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.isReset) {
      this.values = [];
    }
    this.items = this.common.filterTreeData(
      'className',
      'classId',
      this.treeData
    );
  }

  public onSelectedChange(event) {
    if (event !== undefined) {
      const data = _.find(this.flattenMap, (o) => o.classId === event);
      if (data) {
        this.getParams.emit(data.classParam1);
      } else {
        this.getParams.emit('');
      }
    } else {
      this.getParams.emit('');
    }

    this.getVal.emit(event);
  }
}
