import { Injectable } from "@angular/core";
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest
} from "@angular/common/http";

@Injectable()
export class BaseInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        //设置基础请求头
        request = request.clone({
            headers: request.headers
                .set("Access-Control-Allow-Origin", "*")
                .set(
                    "Access-Control-Allow-Methods",
                    "OPTIONS,HEAD,GET,PUT,POST,DELETE,PATCH"
                )
        });
        return next.handle(request);
    }
}
