import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  SharedModule as PrimengSharedModule,
  DropdownModule,
  ButtonModule,
  DragDropModule,
  InputTextModule,
  TabViewModule,
  DataGridModule,
  ToolbarModule,
  SelectButtonModule,
  ToggleButtonModule,
  FileUploadModule,
  PanelModule,
  OverlayPanelModule,
  SpinnerModule,
  ColorPickerModule,
  AccordionModule,
  SplitButtonModule,
  MultiSelectModule,
  PaginatorModule,
  DialogModule,
  RadioButtonModule
} from "primeng/primeng";

import { routes } from "./topo.routes";

import { TopoMangeService } from "./topo-manage/topo-manage.service";
import { PointSelectorModule } from "../../common/components/point-selector/point-selector.module";
import { TopoDisplayComponent } from "../topo-v2/topo-display/topo-display.component";
import { TopoEditorComponent } from "../topo-v2/topo-editor/topo-editor.component";
import { TopoManageComponent } from "./topo-manage/topo-manage.component";
import { ObjectSelectorModule } from "../../common/components/object-selector/object-selector.module";
import { TableModule } from "primeng/table";

const primeng = [
  PrimengSharedModule,
  DropdownModule,
  ButtonModule,
  DragDropModule,
  InputTextModule,
  TabViewModule,
  DataGridModule,
  ToolbarModule,
  SelectButtonModule,
  ToggleButtonModule,
  FileUploadModule,
  PanelModule,
  OverlayPanelModule,
  SpinnerModule,
  ColorPickerModule,
  AccordionModule,
  SplitButtonModule,
  MultiSelectModule,
  TableModule,
  PaginatorModule,
  DialogModule,
  RadioButtonModule
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ...primeng,
    PointSelectorModule,
    ObjectSelectorModule,
    RouterModule.forChild(routes)
  ],
  exports: [],
  declarations: [
    TopoManageComponent,
    TopoDisplayComponent,
    TopoEditorComponent,
  ],
  providers: [TopoMangeService]
})
export class TopoModule { }
