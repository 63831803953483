import * as _ from 'lodash';

//调用接口，获取返回值
// var returnValue = {
// 	tooltip: {
// 		mainUnit: null,
// 		avgLabel: null,
// 		subLabel: null,
// 		subUnit: null,
// 		mainLabel: null,
// 		nullIndex: 3
// 	},
// 	option: {
// 		yAxis: [{
// 			type: "value"
// 		},{
// 	    	splitLine: {show: false},
// 			type: "value"
// 		}],
// 		xAxis: {
// 			data: []
// 		},
// 		legend: {
// 			data: []
// 		},
// 		series: []
// 	}
// };

var returnValue = {
  tooltip: {
    mainUnit: 'h',
    subLabel: '日发电量',
    subUnit: 'KWh',
    mainLabel: '日等效时数',
    nullIndex: 3,
    avgLabel: null,
  },
  title: '日等效时数',
  deviceId: [
    '01024',
    '01022',
    '01005',
    '01020',
    '01018',
    '01011',
    '01016',
    '01002',
    '01023',
    '01013',
    '01015',
    '01003',
    '01014',
    '01019',
    '01007',
    '01004',
    '01017',
    '01010',
    '01025',
    '01009',
    '01006',
    '01021',
    '01001',
    '01012',
    '01008',
  ],
  option: {
    yAxis: [
      { name: 'h', type: 'value' },
      {
        name: 'KWh',
        splitLine: { show: false },
        type: 'value',
      },
    ],
    xAxis: {
      data: [
        'QDSS-070205ZN',
        'QDSS-070203ZN',
        'QDSS-NB301',
        'QDSS-070201ZN',
        'QDSS-070106ZN',
        'QDSS-NB401',
        'QDSS-070104ZN',
        'QDSS-NB102',
        'QDSS-070204ZN',
        'QDSS-070101ZN',
        'QDSS-070103ZN',
        'QDSS-NB201',
        'QDSS-070102ZN',
        'QDSS-070107ZN',
        'QDSS-NB601',
        'QDSS-NB202',
        'QDSS-070105ZN',
        'QDSS-NB502',
        'QDSS-070206ZN',
        'QDSS-NB501',
        'QDSS-NB302',
        'QDSS-070202ZN',
        'QDSS-NB101',
        'QDSS-NB402',
        'QDSS-NB602',
      ],
    },
    legend: {
      data: [
        '100-90%',
        '90-80%',
        '80-70%',
        '70-60%',
        '60-50%',
        '50-40%',
        '40-30%',
        '30-20%',
        '20-10%',
        '10-0%',
        '日发电量',
      ],
    },
    series: [
      {
        stack: '1',
        data: [
          97.02,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '100-90%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          93.94,
          89.48,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '90-80%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          82.49,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '80-70%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          74.41,
          73.5,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '70-60%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          65.71,
          63.24,
          63.15,
          59.24,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '60-50%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          55.67,
          52.55,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '50-40%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          48.46,
          42.54,
          41.88,
          41.1,
          39.45,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
        ],
        name: '40-30%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          34.57,
          32.56,
          31.64,
          30.14,
          null,
          null,
          null,
          null,
        ],
        name: '30-20%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          20.86,
          null,
          null,
          null,
        ],
        name: '20-10%',
        type: 'bar',
      },
      {
        stack: '1',
        data: [
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          null,
          0.07,
          null,
          null,
        ],
        name: '10-0%',
        type: 'bar',
      },
      {
        showSymbol: false,
        lineStyle: {
          width: 1,
          type: 'dashed',
        },
        data: [
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
          53.64,
        ],
        name: '平均值',
        type: 'line',
        markPoint: {
          data: [
            {
              symbol: 'arrow',
              yAxis: 53.64,
              symbolRotate: -90,
              xAxis: 'QDSS-NB602',
              symbolSize: 10,
              name: '平均值',
              label: {
                formatter: '{b}\n{c}',
                fontSize: 14,
                position: 'right',
              },
              value: 53.64,
            },
          ],
        },
      },
      {
        data: [
          51.12,
          75.48,
          89.55,
          63.94,
          68.32,
          42.44,
          6.62,
          11.29,
          51.89,
          73.64,
          41.78,
          41.4,
          64.8,
          77.14,
          2.21,
          16.52,
          37.86,
          93.54,
          48.2,
          75.39,
          12.55,
          23.39,
          37.62,
          null,
          null,
        ],
        name: '日发电量',
        scale: true,
        type: 'line',
        smooth: true,
        yAxisIndex: 1,
      },
    ],
  },
};

// 指定图表的配置项和数据
export const options = {
  color: [
    '#37a2da',
    '#32c5e9',
    '#96DEE8',
    '#A5E7F0',
    '#2EC7C9',
    '#6BE6C1',
    '#22C3AA',
    '#4EA397',
    '#7BD9A5',
    '#EBDBA4',
    '#F2B3C9',
    '#F58DB2',
    '#22C3AA',
  ],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  grid: {
    left: '3%',
    width: '92%',
    containLabel: true,
  },
  tooltip: {
    trigger: 'axis',
    formatter: function (param) {
      console.dir(param);
      var tip = null,
        subTip = null;
      for (var i in param) {
        if (param[i].seriesType == 'line') {
          if (param[i].seriesName != returnValue.tooltip.avgLabel) {
            if (param[i].data == null) {
              subTip = param[i].marker + returnValue.tooltip.subLabel + ': ';
            } else {
              subTip =
                param[i].marker +
                returnValue.tooltip.subLabel +
                ': ' +
                param[i].data +
                ' ' +
                returnValue.tooltip.subUnit;
            }
          }
        } else if (param[i].data != null) {
          tip =
            param[i].name +
            '</br>' +
            param[i].marker +
            returnValue.tooltip.mainLabel +
            ': ' +
            param[i].data +
            ' ' +
            returnValue.tooltip.mainUnit +
            '（排名：' +
            (param[i].dataIndex + 1) +
            '）</br>';
        }
      }
      if (tip == null) {
        tip =
          param[returnValue.tooltip.nullIndex].name +
          '</br>' +
          param[returnValue.tooltip.nullIndex].marker +
          returnValue.tooltip.mainLabel +
          ': （排名：' +
          (param[returnValue.tooltip.nullIndex].dataIndex + 1) +
          '）</br>';
      }
      return tip + subTip;
    },
  },
  toolbox: {
    feature: {
      dataZoom: {},
      restore: {},
      dataView: {},
      saveAsImage: {},
    },
  },
  dataZoom: [
    {
      type: 'inside',
      realtime: true,
    },
    {
      realtime: true,
    },
  ],
  legend: {
    show: true,
    data: returnValue.option.legend.data,
  },
  xAxis: {
    type: 'category',
    data: returnValue.option.xAxis.data,
    axisLabel: { show: false },
  },
  yAxis: returnValue.option.yAxis,
  series: returnValue.option.series,
};
