import { Injectable } from '@angular/core';
import { TipService } from '@common/services/tip.service';
import * as _ from 'lodash';
import { tap } from 'rxjs/operators';
import { AppConfigService } from '../../../app.config';
import { CommonService } from '../../../common/services/common.service';
import { AuthHttp } from '../../../core/auth-http';

@Injectable()
export class SparePartsManageService {
  public apiUrl: string = this.appConfig.apiUrl;

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService,
    private tipservice: TipService
  ) { }

  // 操作类型
  public getOptionTypeList() {
    return this.http.get(`${this.apiUrl}/api/material/classfications`);
  }
  // 操作类型
  public materialInventoryDtosByInventoryCateId(params) {
    return this.http.get(
      `${this.apiUrl}/api/material/materialInventoryDtosByInventoryCateId`,
      {
        params,
      }
    );
  }

  // 库存列表
  public getInventoryList(param, page, size) {
    return this.http.get(`${this.apiUrl}/api/material/materialInventorys`, {
      search: _.assign(this.common.filterEffectParam(param), {
        page,
        size,
      }),
    });
  }

  // 盘点列表
  public getInventoryTaskList(param, page, size) {
    return this.http.get(`${this.apiUrl}/api/inventoryTask/inventory-tasks`, {
      search: _.assign(this.common.filterEffectParam(param), {
        page,
        size,
      }),
    });
  }

  // 备品备件 出入库 物料列表查询
  public getInventoryMaterials(inventoryId) {
    return this.http.get(
      `${this.apiUrl}/api/material/materialInventoryDtosByInventoryId`,
      {
        search: { inventoryId },
      }
    );
  }

  // 出入库操作类别
  public getoperatingCategory(classType: string) {
    return this.http
      .get(`${this.apiUrl}/api/material/propLists/searchSonPropList`, {
        search: { classType },
      })
      .toPromise();
  }

  public getIoFincanceSubtype(codeId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/propList/propLists/search`, {
        search: { codeId },
      })
      .toPromise();
  }

  // 出入库页面 物料列表查询
  public getMaterialsByMaterialSnIds(materialSnId) {
    return this.http.get(
      `${this.apiUrl}/api/material/materialInventoryDtosByMaterialSnIds`,
      {
        search: { materialSnId },
      }
    );
  }

  // 关联业务单号模糊查询
  public getFirmIdList(types: string[], code: string) {
    return this.http.get(`${this.apiUrl}/api/material/relaBusinessIds`, {
      search: { types, code },
    });
  }

  // 出入库新增
  public addInOutStock(param) {
    return this.http.post(
      `${this.apiUrl}/api/material/addMaterialIostorge`,
      param
    );
  }

  // 出入库编辑
  public updateEditStock(iostorge) {
    return this.http.post(
      `${this.apiUrl}/api/material/editMaterialIostorge`,
      iostorge
    );
  }

  // 出入库 完成/作废
  public updateStockStatus(iostorageId: string, ioStatus: string) {
    return this.http
      .patch(`${this.apiUrl}/api/material/materialIostorge`, null, {
        search: { iostorageId, ioStatus },
      })
      .pipe(
        tap((res) => {
          this.tipservice.tip('success', '操作成功！');
        })
      );
  }

  // 出入库导出
  public exportInOutStockList(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/material/exportMaterialIostorges`,
      param
    );
  }

  // 工器具出入库导出
  public exportToolsInOutStockList(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/material/exportIostorges`,
      param
    );
  }

  // 非生产物资出入库导出
  public exportNonProdcutMaterialIostorges(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/material/exportNonProdcutMaterialIostorges`,
      param
    );
  }

  // 库存导出
  public exportInventoryList(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/material/exportMaterialInventorys`,
      param
    );
  }

  // 盘点导出
  public exportInventoryTaskList(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/inventoryTask/exportInventoryTasks`,
      param
    );
  }

  // 获取出入库列表
  public getInOutRecordList(param) {
    return this.http
      .get(`${this.apiUrl}/api/material/materialIostorges`, {
        search: this.common.filterEffectParam(param),
      })
      .pipe(
        tap((res) => {
          return this.formatInOutRecordList(res);
        })
      );
  }

  // 获取工器具出入库列表
  public getToolsInOutRecordList(param) {
    return this.http
      .get(`${this.apiUrl}/api/material/iostorges`, {
        search: this.common.filterEffectParam(param),
      })
      .pipe(
        tap((res) => {
          return this.formatInOutRecordList(res);
        })
      );
  }

  // 获取工器具出入库列表
  public getNonProdcutMaterialIostorgesList(param) {
    return this.http
      .get(`${this.apiUrl}/api/material/nonProdcutMaterialIostorges`, {
        search: this.common.filterEffectParam(param),
      })
      .pipe(
        tap((res) => {
          return this.formatInOutRecordList(res);
        })
      );
  }

  public deleteInventoryTask(param) {
    return this.http
      .delete(
        `${this.appConfig.apiUrl}/api/inventoryTask/deleteInventoryTasks`,
        {
          search: this.common.filterEffectParam(param),
        }
      )
      .pipe(
        tap((res) => {
          this.tipservice.tip('success', '操作成功！');
        })
      )
      .toPromise();
  }

  private formatInOutRecordList(data) {
    let newRecordList = data.content;
    newRecordList.forEach((item) => {
      item.inOutStockId = item.ioCode;
      item.explain = item.ioDesc;
      item.optionType = [item.ioTypeText, item.ioSubTypeText].join('/');
      item.state = item.ioStatusText;
      item.store = item.warehouseName;
      item.manager = item.ioManagerName;
      item.worker = item.ioOperatorName;
      item.firmType = item.relaBusinessTypeText;
      item.firmId = item.relaBusinessIdName;
      // item.planTime = item.planTime
      //   ? moment(new Date(item.planTime)).format('YYYY-MM-DD HH:mm:ss')
      //   : '';
      // item.actualTime = item.ioTime
      //   ? moment(new Date(item.ioTime)).format('YYYY-MM-DD HH:mm:ss')
      //   : '';
    });

    return {
      content: newRecordList,
      totalElements: data.totalElements,
    };
  }

  // 安全报表
  public safeInventoriesReport(params) {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/safeInventories/report`,
      {
        search: this.common.filterEffectParam(params),
      }
    );
  }

  // 仓库导出
  public exportSafeInventoriesReport(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/safeInventories/report/export`,
      param
    );
  }

  /**
   * iostorageReport
   */
  public iostorageReport(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/iostorage/report`, {
      search: this.common.filterEffectParam(params),
    });
  }

  // 仓库导出
  public exportIostorageReport(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/iostorage/report/export`,
      param
    );
  }

  // NC库存 Controller

  /**
   * 获取仓库列表
   */
  public getMaterialNcWarehouses() {
    return this.http.get(`${this.apiUrl}/api/material/nc/warehouses`);
  }
  /**
   * 获取物资分类列表
   */
  public getMaterialNcClasses() {
    return this.http.get(`${this.apiUrl}/api/material/nc/classes`);
  }

  /**
   * 获取物资物料列表
   */
  public getMaterialNcMaterials(params) {
    return this.http.get(`${this.apiUrl}/api/material/nc/materials`, {
      params,
    });
  }

  /**
   * 查询NC数据
   */
  public getMaterialNcList(params) {
    return this.http.get(`${this.apiUrl}/api/material/nc`, {
      params: this.common.filterEffectParam(params),
    });
  }

  /**
   * 导出NC库存
   */
  public exportMaterialNcList(params) {
    return this.common.fileExport(
      `${this.apiUrl}/api/material/nc/export`,
      params
    );
  }

  /**
   * material/approve
   */
  public materialApprove(params) {
    return this.http.post(
      `${this.apiUrl}/api/material/approve`,
      {},
      { search: params }
    );
  }
  public iostorgeWorkFlowRecords(iostorageId) {
    return this.http.get(
      `${this.apiUrl}/api/material/iostorgeWorkFlowRecords`,
      { params: { iostorageId } }
    );
  }
}
