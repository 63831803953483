import { CacheService } from 'src/app/common/services/cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StationMonitorService } from './../../station-monitor.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildDeviceOnlineRateChart } from './chart/device-online-rate.chart';
import { Constants } from 'src/app/common/Constants';

@Component({
    selector: 'app-device-online-rate-card',
    templateUrl: './device-online-rate-card.component.html',
    styleUrls: [
        '../overview.component.less',
        './device-online-rate-card.component.less'
    ]
})
export class DeviceOnlineRateCardComponent implements OnInit, OnDestroy {
    //外线出柜 图表option
    public outsideLineOutletOption: any = null;
    //集电线路 图表option
    public collectingLineBlockOption: any = null;
    //箱变 图表option
    public boxChangeBlockOption: any = null;
    //逆变器 图表option
    public inverterBlockOption: any = null;

    public outside_line_outlet_block = true;
    public collecting_line_block = true;
    public box_change_block = true;
    public inverter_block = true;
    public cardBox: string[] = [];
    private runStatusInterval: any = null;
    private intervalTime: number = 5;
    stationId: any;
    loading: boolean = true;

    constructor(
        private stationmonitorservice: StationMonitorService,
        private router: Router,
        private route: ActivatedRoute,
        private cacheService: CacheService
    ) {
        this.route.queryParams.subscribe((params) => {
            if (!params.stationId) {
                let stationId = this.cacheService.getCache(
                    Constants.KEY_CURRENT_STATION_ID
                );
                this.stationId = stationId;
                this.initPage({ stationId });
            } else {
                this.stationId = params.stationId;
                this.initPage({ stationId: params.stationId });
            }
        });
    }

    ngOnInit() {}

    ngOnDestroy(): void {
        clearInterval(this.runStatusInterval);
        this.runStatusInterval = null;
    }

    public initPage(params?) {
        if (this.runStatusInterval) {
            clearInterval(this.runStatusInterval);
            this.runStatusInterval = null;
        }
        this.getRunStatus(params);
        this.runStatusInterval = setInterval(() => {
            this.getRunStatus(params);
        }, 1000 * 60 * this.intervalTime);
    }

    public getRunStatus(params) {
        this.loading = true;
        this.stationmonitorservice.runStatus(params).then((res: any) => {
            this.loading = false;
            if (res) {
                let data = res;
                this.cardBox = data.cardTitle;

                this.outsideLineOutletOption = buildDeviceOnlineRateChart(
                    data.options[0],
                    'outsideLineOutlet'
                );
                this.collectingLineBlockOption = buildDeviceOnlineRateChart(
                    data.options[1],
                    'collectingLine'
                );
                this.boxChangeBlockOption = buildDeviceOnlineRateChart(
                    data.options[2],
                    'boxChange'
                );
                this.inverterBlockOption = buildDeviceOnlineRateChart(
                    data.options[3],
                    'inverter'
                );
            }
        });
    }

    /**
     * goDeviceStatus
     */
    public goDeviceStatus() {
        localStorage.setItem(
            'backItem',
            JSON.stringify({
                showIcon: true,
                link: ['/monitorStation/overview'],
                params: {}
            })
        );
        this.router.navigate(['/monitorStation/deviceStatus'], {
            queryParams: {
                stationId: this.stationId
            },
            queryParamsHandling: 'merge'
        });
    }
}
