import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PrivatePageFullscreenGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(private router: Router, private authService: AuthService) { }
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (nextState.url.indexOf('/IframeSupportInter')) {
      this.router.navigateByUrl(`/IframeSupportInter${nextState.url}`);
    }

    if (
      !this.authService.loggedIn() &&
      nextState.url !== '/IframeSupportInter/login'
    ) {
      this.router.navigate(['/IframeSupportInter/login']);
    }
    return true;
  }
}
