import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private translate: TranslateService) {
    const appConfig = window['appConfig'] || {};
    this.translate.setDefaultLang('en_US');
    this.translate.use(appConfig.locale || 'en_US');
  }

  ngOnInit(): void {
    // Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    // Add 'implements OnInit' to the class.
  }
}
