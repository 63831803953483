import { DeviceService } from 'src/app/components/monitor/shared/device.service';
import { StationMonitorService } from './../../station-monitor.service';

import { CodeTableService } from './../../../../core/code-table.service';
import { CommonService } from 'src/app/common/services/common.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { FormControl } from '@angular/forms';
import { FormBuilder } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { fromEvent, forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SelectItem, MenuItem } from 'primeng/api';
import { TipService } from '@common/services/tip.service';
import * as _ from 'lodash';
@Component({
  selector: 'app-maintenance-records',
  templateUrl: './maintenance-records.component.html',
  styleUrls: ['./maintenance-records.component.less'],
})
export class MaintenanceRecordsComponent implements OnInit {
  @Input() public deviceID: string;
  @Input() public stationId: string;
  @Input() public permission: any;

  pager = {
    size: 50,
    page: 0,
    totalElements: 0,
  };
  queryForm: any;
  canlendarLocale: any;
  resizeEvent: any;
  scrollHeight: any;
  orderTypesOptions: SelectItem[];
  dataList: any[];
  columns: any[];
  tableloading: boolean;
  showOperateDefect: boolean;
  defectId: any;
  type: string;
  showOperateRepairTickets: boolean;
  ticketId: any;
  public buttonItem: MenuItem[];
  operateData: any;
  showOperateInspections: boolean;
  inspectionId: any;
  showOperateMaintains: boolean;
  showOperateTickets: boolean;
  dialogParams: { deviceId: any };
  inspectionType: any;
  constructor(
    private fb: FormBuilder,
    private calendarLocales: CalendarLocales,
    private common: CommonService,
    private codeTableService: CodeTableService,
    private stationmonitorservice: StationMonitorService,
    private deviceservice: DeviceService,
    private tipservice: TipService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setscrollHeight();
      });
  }

  ngOnInit() {
    this.setscrollHeight();
    this.initForm();
    this.initPage();
    this.getBaseQueryOption();
    this.queryList(true);
  }

  public initForm() {
    this.queryForm = this.fb.group({
      orderTypes: new FormControl(null),
      date: new FormControl([moment().subtract(1, 'years').toDate(), moment().toDate()]),
    });
  }

  public initPage() {
    this.tableloading = true;
    this.columns = [
      {
        field: 'orderCode',
        header: '单号',
        textAlign: 'center',
        fn: true,
      },
      {
        field: 'orderTypeText',
        header: '工单类型',
        textAlign: 'center',
      },
      {
        field: 'orderSubtypeText',
        header: '工单子类型',
        textAlign: 'center',
      },
      {
        field: 'orderDesc',
        header: '任务描述',
        textAlign: 'left',
        width: 345,
      },
      // {
      //   field: 'planStartTime',
      //   header: '计划开始时间',
      //   textAlign: 'center',
      //   width: 165,
      // },
      // {
      //   field: 'planEndTime',
      //   header: '计划完成时间',
      //   textAlign: 'center',
      //   dateFormat: true,
      //   width: 165,
      // },
      {
        field: 'startTime',
        header: '实际开始时间',
        textAlign: 'center',
        dateFormat: true,
        width: 165,
      },
      {
        field: 'endTime',
        header: '实际完成时间',
        textAlign: 'center',
        dateFormat: true,
        width: 165,
      },
      {
        field: 'orderStatusText',
        header: '工单状态',
        textAlign: 'center',
      },
      {
        field: 'executorName',
        header: '负责人',
        textAlign: 'center',
      },
    ];
    this.buttonItem = [];
    const permissions = this.deviceservice.deviceDetailPermissions;
    if (permissions && permissions['feature.maintain.defect']) {
      if (this.buttonItem.length > 0) {
        this.buttonItem.push({ separator: true });
      }
      this.buttonItem.push({
        label: '缺陷单',
        icon: null,
        command: () => {
          this.operateData = {
            deviceId: this.deviceID,
          };
          this.openDefectDialog(0, 'add');
        },
      });
    }
    if (permissions && permissions['feature.maintain.repair']) {
      if (this.buttonItem.length > 0) {
        this.buttonItem.push({ separator: true });
      }
      this.buttonItem.push({
        label: '抢修单',
        icon: null,
        command: () => {
          this.operateData = {
            deviceId: this.deviceID,
          };
          this.openRepairTicketDialog(0, 'add');
        },
      });
    }
  }

  public getBaseQueryOption() {
    forkJoin(this.codeTableService.getNemsProp('orderType')).subscribe((res) => {
      this.orderTypesOptions = res[0];
    });
  }

  public setscrollHeight() {
    const lineHeight = 50 + 44 + 50 + 29;
    this.scrollHeight = this.common.setTableScrollHeight(lineHeight);
  }

  public queryList(first?) {
    const value = this.queryForm.value;
    if (!value.date[1]) {
      this.tipservice.tip('warn', '完成结束时间不能为空');
      return;
    }
    if (first) {
      this.pager.page = 0;
    }
    const params = {
      deviceId: this.deviceID,
      orderTypes: value.orderTypes,
      startTime: moment(value.date[0]).format('YYYY-MM-DD'),
      endTime: moment(value.date[1]).format('YYYY-MM-DD'),
      page: this.pager.page,
      size: this.pager.size,
    };
    this.stationmonitorservice.getWorkOrders(params).subscribe(
      (res: any) => {
        if (res.content) {
          this.dataList = res.content;
          this.pager.totalElements = res.totalElements;
        }
      },
      (err) => {
        console.log(err);
      },
      () => {
        this.tableloading = false;
      },
    );
  }

  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryList();
  }

  buttonFunction(event, orderId, type, orderType, orderSubtype) {
    this.operateData = null;
    switch (orderType) {
      //  缺陷类工单
      case '01':
        this.openDefectDialog(orderId, type);
        break;
      //  巡检类工单
      case '02':
        this.openInspectionsDialog(orderId, type, orderSubtype);
        break;
      //  检修预试单工单
      case '04':
        this.openMaintainsDialog(orderId, type);
        break;
      case '06':
        // todo 工作票
        break;
      case '07':
        // todo 操作票
        this.openTicketDialog(orderId, type);
        break;
      case '08':
        // 抢修单
        this.openRepairTicketDialog(orderId, type);
        break;
      default:
        break;
    }
  }

  openDefectDialog(orderId, type) {
    this.defectId = orderId;
    this.type = type;
    this.showOperateDefect = true;
  }

  hideOperateDefectEvent(event) {
    this.showOperateDefect = false;
  }

  openInspectionsDialog(orderId, type, orderSubtype) {
    this.inspectionId = orderId;
    this.type = type;
    this.inspectionType = orderSubtype;
    this.showOperateInspections = true;
  }

  hideOperateInspectionsEvent(event) {
    this.showOperateInspections = false;
  }

  openMaintainsDialog(orderId, type) {
    this.inspectionId = orderId;
    this.type = type;
    this.showOperateMaintains = true;
  }

  hideOperateMaintainsEvent(event) {
    this.showOperateMaintains = false;
  }

  openTicketDialog(orderId, type) {
    this.ticketId = orderId;
    this.type = type;
    this.showOperateTickets = true;
  }

  hideOperateTicketsEvent(event) {
    this.showOperateTickets = false;
  }

  openRepairTicketDialog(orderId, type) {
    this.ticketId = orderId;
    this.type = type;
    this.showOperateRepairTickets = true;
  }

  hideOperateRepairTicketsEvent(event) {
    this.showOperateRepairTickets = false;
  }

  refreshListEvent(event) {
    if (event) {
      this.queryList(true);
    }
  }
}
