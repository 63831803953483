import { WidgetsService } from '../../widgets.service';
import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-station-power-generation-statistics',
  templateUrl: './power-generation-statistics.component.html',
  styleUrls: ['./power-generation-statistics.component.less']
})
export class StationPowerGenerationStatisticsComponent implements OnInit, OnChanges {

  @Input() stationId: string;

  public kvMap = [];

  constructor(private widgetsservice: WidgetsService) { }

  ngOnInit() {
    // this.getPowerGenerationStatistics({ stationId: this.stationId });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.getPowerGenerationStatistics({ stationId: changes.stationId.currentValue });
    }
  }


  public getPowerGenerationStatistics(params) {
    const kvMap = {
      day: '今日发电',
      month: '当月发电',
      year: '当年发电',
      all: '累计发电',
    };
    this.widgetsservice.getPowerGenerationStatistics(params).then((res: any) => {
      this.kvMap = [];
      for (const key in res) {
        if (Object.prototype.hasOwnProperty.call(res, key)) {
          const element = res[key];
          this.kvMap.push({
            text: kvMap[key],
            value: element || 0
          })
        }
      }
    })
  }

}
