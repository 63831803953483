import { InspectionsService } from './../inspections.service';
import { CommonService } from 'src/app/common/services/common.service';
import { CodeTableService } from 'src/app/core/code-table.service';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { forkJoin } from 'rxjs';
import * as moment from 'moment';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { ConfirmationService } from 'primeng/api';
import * as _ from 'lodash';
import { AppConfigService } from 'src/app/app.config';
import { TipService } from '@common/services/tip.service';

declare const BMap: any;
@Component({
  selector: 'app-inspection-device-details',
  templateUrl: './inspection-device-details.component.html',
  styleUrls: ['./inspection-device-details.component.less'],
})
export class InspectionDeviceDetailsComponent implements OnInit {
  @Input() public objItemInfo = null;
  @Input() public inspectionId = null;
  @Input() public isShow = false;
  @Input() public type: string;

  @Output() hideEvent = new EventEmitter<boolean>();
  @Output() dateEmitterEvent = new EventEmitter<any>();

  @ViewChild('fileinput', { static: false }) fileinput: ElementRef;
  @ViewChild('container', { static: false }) mapElement: ElementRef;
  itemGroups: any;
  longitude: number;
  latitude: number;
  inspectionStatuses: any;
  canlendarLocale: any;
  fileEndpointUrl: string;
  showOperateDefect: boolean;
  operateData: { deviceId: any; parentOrderId: any; defectSource: string };
  itemTypes: string[] = ['01', '02', '03', '04', '05'];
  itemHistoryItemId: any;
  itemHistoryTitle: any;
  showItemHis: boolean;
  itemHistoryData: any;
  itemHistoryPager: any = { page: 0, totalElements: 0, size: 10 };
  itemHistoryColumns = [
    {
      field: 'updateTime',
      header: '时间',
      width: 140,
      itemMemo: '',
    },
    { field: 'itemValue', header: '值', width: 80 },
    { field: 'itemMemo', header: '备注', width: 160 },
  ];
  itemHistoryPsrId: any = null;

  constructor(
    private calendarLocales: CalendarLocales,
    private commonservice: CommonService,
    private inspectionsservice: InspectionsService,
    private confirmationService: ConfirmationService,
    private appConfig: AppConfigService,
    private tipservice: TipService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.fileEndpointUrl = this.appConfig.fileEndpointUrl;
  }

  ngOnInit() {
    this.itemGroups = this.objItemInfo.itemGroups;
    this.inspectionStatuses = this.objItemInfo.status.map((item) => {
      return {
        label: item.second,
        value: item.first,
      };
    });
    this.longitude = +this.objItemInfo.longitude;
    this.latitude = +this.objItemInfo.latitude;
    this.itemHistoryPsrId = this.objItemInfo.psrId;
    if (!this.objItemInfo.objId) {
      this.objItemInfo.updateTime = moment().toDate();
    } else {
      if (this.objItemInfo.updateTime) {
        this.objItemInfo.updateTime = moment(
          this.objItemInfo.updateTime
        ).toDate();
      }
    }
    setTimeout(() => {
      this.setPosition();
    }, 1000);
  }

  public setPosition() {
    const map = new BMap.Map(this.mapElement.nativeElement);
    // 创建点坐标
    const point = new BMap.Point(this.longitude, this.latitude);
    // 初始化地图， 设置中心点坐标和地图级别
    map.centerAndZoom(point, 19);
    map.enableDragging();
    map.enableScrollWheelZoom(true);

    map.setViewport([point]);
    if (this.longitude && this.latitude) {
      const marker = new BMap.Marker(
        new BMap.Point(this.longitude, this.latitude),
        {
          title: this.objItemInfo.deviceNo,
          icon: new BMap.Icon(
            'assets/map/map-mask.svg',
            new BMap.Size(32, 32),
            {
              anchor: new BMap.Size(16, 32),
            }
          ),
        }
      );
      map.addOverlay(marker);
    }
  }

  // 弹窗关闭事件
  onHide(event) {
    this.hideEvent.emit(true);
  }

  public addItemPic(event, item, index) {
    const files = event.dataTransfer
      ? event.dataTransfer.files
      : event.target.files;
    const extName = 'GIF,BMP,JPG,JPEG,PNG';
    for (const file of files) {
      const i = file.name.lastIndexOf('.');
      const len = file.name.length;
      const extEndName = file.name.substring(i + 1, len);
      if (extName.indexOf(extEndName.toUpperCase()) === -1) {
        return this.tipservice.tip('warn', '上传的文件格式不正确');
      }
      console.log(item);
      const params = {
        objItemId: item.objItemId,
      };
      this.inspectionsservice
        .uploadObjFile(file, params)
        .then((res: any) => {
          if (res) {
            item.docs = item.docs || [];
            item.docs.push(res);
            this.tipservice.tip('success', '上传成功');
          }
        })
        .then((res) => {
          if (this.fileinput && this.fileinput.nativeElement) {
            this.fileinput.nativeElement.value = '';
          }
        });
    }
  }

  public deleteItemPic(item, docIds) {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定删除该照片么？`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        this.inspectionsservice.deleteObjFile(docIds).subscribe((res) => {
          _.remove(item.docs, (o: any) => {
            return o.docId === docIds;
          });
          this.tipservice.tip('success', '删除成功');
        });
      },
    });
  }

  deleteFunction() {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定要删除此设备么？`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        this.inspectionsservice
          .deleteObjItem(this.objItemInfo.objId)
          .subscribe((res) => {
            this.dateEmitterEvent.emit({
              params: this.objItemInfo,
              type: 'delete',
            });
            this.hideEvent.emit(true);
            this.tipservice.tip('success', '删除成功');
          });
      },
    });
  }

  defectFunction() {
    this.operateData = {
      deviceId: this.objItemInfo.deviceId,
      parentOrderId: this.inspectionId,
      defectSource: '04',
    };
    this.showOperateDefect = true;
  }

  hideOperateDefectEvent(event) {
    this.showOperateDefect = false;
  }

  saveFunction() {
    const itemGroupsParams = _.cloneDeep(this.itemGroups);

    let flag = true;
    if (_.isArray(itemGroupsParams)) {
      itemGroupsParams.every((item: any) => {
        if (_.isArray(item.items)) {
          item.items.every((i) => {
            if (i.props) {
              i.props = null;
            }
            if (
              !i.nullable &&
              !i.itemValue &&
              i.itemType !== '06' &&
              i.itemType !== '07'
            ) {
              this.tipservice.tip('warn', `${i.itemName}巡检结果不能为空`);
              flag = false;
              return false;
            }
            if (
              !i.nullable &&
              i.itemType === '06' &&
              (i.docs === null || (i.docs && i.docs.length === 0))
            ) {
              this.tipservice.tip('warn', `${i.itemName}不能为空`);
              flag = false;
              return false;
            }
            if (
              i.itemType === '01' &&
              i.defaultValue !== i.itemValue &&
              !i.itemMemo
            ) {
              this.tipservice.tip('warn', `${i.itemName}备注不能为空`);
              flag = false;
              return false;
            }
            return true;
          });
        }
        return true;
      });
    }
    if (flag) {
      const params = {
        ...this.objItemInfo,
        inspectionId: this.inspectionId,
        status: null,
        itemGroups: itemGroupsParams,
      };
      this.inspectionsservice.saveObjItem(params).subscribe((res) => {
        if (res) {
          if (this.objItemInfo.objId) {
            this.dateEmitterEvent.emit({ params, type: this.type });
            this.hideEvent.emit(true);
            this.tipservice.tip('success', '保存成功');
          } else {
            this.dateEmitterEvent.emit({
              params: { ...params, objId: res },
              type: this.type,
            });
            this.hideEvent.emit(true);
            this.tipservice.tip('success', '保存成功');
          }
        }
      });
    }
  }

  itemHistory(item) {
    this.itemHistoryItemId = item.itemId;
    this.itemHistoryTitle = item.itemGroup;
    this.getItemHis(true);
    this.showItemHis = true;
  }

  getItemHis(first?) {
    if (first) {
      this.itemHistoryPager.page = 0;
    }
    const itemHistoryParams = {
      psrId: this.itemHistoryPsrId,
      itemId: this.itemHistoryItemId,
      page: this.itemHistoryPager.page,
      size: this.itemHistoryPager.size,
    };
    this.inspectionsservice
      .getObjItemHis(itemHistoryParams)
      .subscribe((res: any) => {
        this.itemHistoryData = res.content;
        this.itemHistoryPager.totalElements = res.totalElements;
      });
  }

  onPageChange(event) {
    this.itemHistoryPager.size = event.rows;
    this.itemHistoryPager.page = event.page;
    this.getItemHis();
  }
}
