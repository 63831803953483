export enum CodeTable {
  dataImportStatus = 'dataImportStatus', // 数据导入状态
  alarmCompareSymbol = 'alarmCompareSymbol', // 告警类型
  e2boxProtocol = 'e2boxProtocol', // E2Box通信协议
  inventoryCate = 'inventoryCate', // 库存类别
  businessType = 'businessType', // 关联业务类型
  iostorageStatus = 'iostorageStatus', // 出入库状态
  ioStorageType = 'ioStorageType', // 出入库操作类别
  ioStorageSubType = 'ioStorageSubType', // 出入库操作子类别
  warehouseLevel = 'warehouseLevel', // 仓库级别
  warehouseStatus = 'warehouseStatus', // 仓库级别
  safeInventoryRange = 'safeInventoryRange', //作用范围
  safeInventoryStatus = 'safeInventoryStatus', //库存状态
  materialType = 'materialType', // 物料类型
  materialUnit = 'materialUnit', // 物料单位
  alarmLevel = 'alarmLevel', // 告警级别
  alarmType = 'alarmType', // 告警类型
  alarmCheckType = 'alarmCheckType',
  recoverCheckType = 'recoverCheckType',
  alarmSource = 'alarmSource',
  companyNature = 'companyNature', // 公司性质
  companyType = 'companyType', // 公司类型
  stationType = 'stationType', // 电站类型
  stationStatus = 'stationStatus', // 电站状态
  connectionMode = 'connectionMode', // 并网方式
  voltageLevel = 'voltageLevel', // 并网电压等级
  modelLayout = 'modelLayout', // 组件布置方式
  areaType = 'areaType', // 区域类型
  stationCalcEnergySource = 'stationCalcEnergySource', // 电量数据源
  deviceRunStatus = 'deviceRunStatus', // 设备状态
  chartCycle = 'chartCycle', // 统计周期
  meterType = 'meterType', // 抄表设备类型
  defectStatus = 'defectStatus', // 缺陷状态
  defectLevel = 'defectLevel', // 缺陷等级
  defectSource = 'defectSource', // 缺陷来源
  pointPreprocessRuleClass = 'pointPreprocessRuleClass', // 规则类别
  pointPreprocessRuleType = 'pointPreprocessRuleType', // 规则类型
  pointRemotionType = 'pointRemotionType', // 四遥类型
  jobRecordType = 'jobRecordType', // 日志类型
  windDirection = 'windDirection', // 风向
  groupType = 'deviceGroupType', // 设备分组类型
  status = 'status', // 分组状态
  pvStatus = 'pvStatus', // 支路状态
  pointIsDisplay = 'pointIsDisplay', // 显示方式
  unit = 'unit', // 单位
  pointDisplayTransfer = 'pointDisplayTransfer', // 显示转换
  deviceType = 'deviceType', // 设备类型
  weatherInfo = 'weatherInfo', // 天气情况
  airQuality = 'airQuality', // 空气质量
  lossType = 'lossType', // 损失类别
  statusCode = 'statusCode', // 状态
  inventoryType = 'inventoryType', // 盘点类型
  defectType = 'defectType', // 缺陷类型
  defectTechType = 'defectTechType', // 工艺类型
  defectDeviceType = 'defectDeviceType', // 设备类型
  stationStage = 'stationStage', // 阶段
  orderType = 'orderType', // 工单类型
  orderSubtype = 'orderSubtype', // 工单子类
  orderStatus = 'orderStatus', // 工单状态
  orderPriority = 'orderPriority', // 优先级
  orderCheckStatus = 'orderCheckStatus', // 检查结果
  eliminateType = 'eliminateType', // 处理方式
  ticketType = 'ticketType', // 典型票类型
  operateTicketStatus = 'operateTicketStatus', // 操作票状态
  operateType = 'operateType', // 操作类型
  falseTrue = '0F1T', // 0false, 1true
  safetyCheckType = 'safetyCheckType', // 安全检查
  rectificationStatus = 'rectificationStatus', // 整改状态
  securityCheckType = 'securityCheckType', // 安全检查类别
  safetyCheckResult = 'safetyCheckResult', // 检查结果
  ticketPermitType = 'ticketPermitType', // 许可方式
  standardType = 'standardType', // 业务类型
  standardPeriodType = 'standardPeriodType', // 周期类型
  workTicketStatus = 'workTicketStatus', // 工作票状态
  maintainTaskStatus = 'maintainTaskStatus', // 任务状态
  maintainTaskResult = 'maintainTaskResult', // 任务结果
  maintainTaskDetailStatus = 'maintainTaskDetailStatus', // 任务状态
  maintainPlanResult = 'maintainPlanResult', // 任务结果
  cameraSource = 'cameraSource', // 视频来源
  cameraType = 'cameraType', // 摄像头类型
  safetyDocType = 'safetyDocType', // 文档类型
  trainType = 'trainType', // 培训类型
  activityType = 'activityType', //安全培训
  trainMethod = 'trainMethod', // 培训方式
  cleanType = 'cleanType', // 清洗类型
  cleanMethod = 'cleanMethod', // 清洗方式
  cleanStatus = 'cleanStatus', // 清洗状态
  firmwareType = 'firmwareType', // 版本类型
  e2boxProtocolVersion = 'e2boxProtocolVersion', // 通讯协议版本
  e2boxLuaVersion = 'e2boxLuaVersion', // lua协议版本
  docFolderType = 'docFolderType', // 文件类型码表
  weatherAlarmLevel = 'weatherAlarmLevel', // 天气告警级别
  deviceChangeType = 'deviceChangeType', // 设备变更类型
  checkInType = 'checkInType', // 出勤类型
  visualFlag = '0F1T', // 虚拟电站
  autoRecord = 'jobRecordGenType', //日报生成方式
  calcFlag = '0F1T', //自动计算电量
}

export enum DataImportStatus {
  NOT_START = '01',
  EDITING = '02',
  COMPLETED = '03',
}
