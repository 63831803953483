export interface SysCategories {
  cateId: string;
  cateName: string;
  pointCount: number;
}

export const NullItem = {
  label: '空',
  value: null
};
