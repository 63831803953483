import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AuthHttp } from '../../../core/auth-http';
import { CommonService } from '../../services/common.service';
import { AppConfigService } from '../../../app.config';

@Injectable()
export class ObjectSelectorService {
  public apiUrl: string = this.appConfig.apiUrl;

  constructor(
    private http: AuthHttp,
    private common: CommonService,
    private appConfig: AppConfigService
  ) { }

  // 电站列表
  public getStationList(shortName, page, size) {
    return this.http.get(`${this.apiUrl}/api/class/getSampleStation`, {
      search: this.common.filterEffectParam({
        shortName,
        page,
        size,
      }),
    });
  }

  // 电站区域列表
  public getStationAreaList(stationId, page, size) {
    return this.http.get(`${this.apiUrl}/api/stationAreas`, {
      search: this.common.filterEffectParam({
        page,
        size,
        stationId,
      }),
    });
  }

  // 设备列表
  public getDeviceList(params) {
    return this.http.get(`${this.apiUrl}/api/device/page`, { params: this.common.filterEffectParam(params) });
  }

  // 电站 all
  public getStationListAll(shortName) {
    return this.http
      .get(`${this.apiUrl}/api/class/getSampleStationAll`, {
        search: this.common.filterEffectParam({ shortName }),
      })
      .toPromise();
  }

  // 电站区域 all
  public getStationAreaListAll(stationId) {
    return this.http
      .get(`${this.apiUrl}/api/stationAreas/all`, {
        search: this.common.filterEffectParam({ stationId }),
      })
      .toPromise();
  }

  // 设备列表 all
  public getDeviceListAll(params) {
    return this.http
      .post(`${this.apiUrl}/api/model/searchAll`, params)
      .toPromise();
  }

  // 电站下拉框
  public getSampleStationAll() {
    return this.http.get(`${this.apiUrl}/api/index/getSampleStationAll`);
  }

  // 电站区域
  public getStationArea(stationId) {
    return this.http.get(`${this.apiUrl}/api/location/getStationLocation`, {
      search: { stationId },
    });
  }

  // 设备型号 下拉框
  public getDeviceModel(param) {
    if (param.groupType) {
      param.deviceTypes = param.groupType;
    }
    return this.http.get(`${this.appConfig.apiUrl}/api/models/stationModels`, {
      search: this.common.filterEffectParam(param),
    });
  }

  public setSelectedDataType(data, type) {
    if (_.isObject(data)) {
      data = _.assign(data, {
        objType: type,
      });
    }
    if (_.isArray(data)) {
      data = data.map((item: any) => {
        return _.assign(item, { objType: type });
      });
    }
    return data;
  }
}
