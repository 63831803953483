export const RankBaseOption = {
    tooltip: {
        formatter: params => {
            let toolTipText =
                "<div>" +
                params.marker +
                params.name +
                "  " +
                params.value +
                "  " +
                "个" +
                "</div>";
            return toolTipText;
        }
    },
    grid: {
        left: "15%",
        right: "15%",
        bottom: "10%",
        top: "0%",
        containLabel: false
    },
    xAxis: [
        {
            type: "value",
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            splitLine: {
                show: false
            }
        }
    ],
    yAxis: [
        {
            type: "category",
            axisLine: {
                show: false
            },
            axisTick: {
                show: false
            },
            data: ["日志", "提示", "普通", "严重"]
        }
    ],
    series: [
        {
            type: "bar",
            barCategoryGap: "40%",
            data: [
                {
                    name: "日志",
                    value: 0,
                    itemStyle: {
                        normal: {
                            color: "#64ABE8"
                        }
                    }
                },
                {
                    name: "提示",
                    value: 0,
                    itemStyle: {
                        normal: {
                            color: "#33CCCC"
                        }
                    }
                },
                {
                    name: "普通",
                    value: 0,
                    itemStyle: {
                        normal: {
                            color: "#FFBD31"
                        }
                    }
                },
                {
                    name: "严重",
                    value: 0,
                    itemStyle: {
                        normal: {
                            color: "#FF6131"
                        }
                    }
                }
            ]
        }
    ]
};

export const TypeBaseOption = {
    color: ["#FF6632", "#FFCC66", "#33CCCC", "#64ABE8"],
    tooltip: {
        trigger: "axis",
        // axisPointer: {            // 坐标轴指示器，坐标轴触发有效
        //   type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
        // }
        formatter: params => {
            let toolTipText = "";
            for (let item of params) {
                toolTipText +=
                    "<div>" +
                    item.marker +
                    item.seriesName +
                    "  " +
                    item.value +
                    "  " +
                    "个" +
                    "</div>";
            }
            return toolTipText;
        },
        position: (point, params, dom, rect, size) => {
            // 固定在顶部
            return [point[0] + 15, point[1] + 15];
        }
    },
    legend: {
        orient: "vertical",
        align: "left",
        top: "10px",
        right: "20px",
        data: ["严重", "普通", "提示", "日志"]
    },
    grid: {
        left: "2%",
        right: "14%",
        bottom: "3%",
        top: "30px",
        containLabel: true
    },
    yAxis: {
        type: "value",
        axisLine: {
            show: false
        },
        axisTick: {
            show: false
        },
        minInterval: 1,
        name: "个"
    },
    xAxis: {
        type: "category",
        axisTick: {
            show: false
        },
        data: []
    },
    series: [
        {
            name: "严重",
            type: "bar",
            stack: "总量",
            barMaxWidth: "50px",
            barCategoryGap: "40%",
            data: []
        },
        {
            name: "普通",
            type: "bar",
            stack: "总量",
            data: []
        },
        {
            name: "提示",
            type: "bar",
            stack: "总量",
            data: []
        },
        {
            name: "日志",
            type: "bar",
            stack: "总量",
            data: []
        }
    ]
};

export const PieChartOption = {
    series: [
        {
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
                normal: {
                    show: true,
                    position: 'center',
                    formatter: (param) => {
                        if (param.name) {
                            return param.percent + '%';
                        } else {
                            return '';
                        }
                    }
                }
            },
            data: []
        }
    ]
};
