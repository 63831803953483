import { NgModule } from "@angular/core";
import { TipComponent } from "./tip.component";
import { ToastModule } from "primeng/toast";
import { MessageService } from "primeng/primeng";

@NgModule({
  declarations: [TipComponent],
  imports: [ToastModule],
  exports: [TipComponent],
  providers: [MessageService]
})
export class TipModule { }
