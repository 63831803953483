import { Observable, BehaviorSubject, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { AppConfigService } from '../app.config';
import { AuthHttp } from './auth-http';
import { CacheService } from '../common/services/cache.service';
import { Constants } from '../common/Constants';
import { HttpResponse } from '@angular/common/http';
import { tap } from 'rxjs/operators';

export interface User {
  userId?: string;
  userName?: string;
  realName?: string;
  email?: string;
  telephone?: string;
  description?: string;
  wechat?: string;

  deptId?: string;

  isManage?: number;
  isSystem?: number;

  enabled?: number;
  isDelete?: number;

  isLock?: number;
  lockTime?: Date;

  failCount?: number;
  passwordUpdateTime?: Date;

  roles?: string[];

  attr1?: string;
  attr2?: string;
  attr3?: string;
  attr4?: string;
  attr5?: string;
}

@Injectable()
export class UserService {
  private userSubject;
  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private cacheService: CacheService
  ) {}

  public me(): Observable<User> {
    if (this.cacheService.getCache(Constants.KEY_CURRENT_USER)) {
      return of(this.cacheService.getCache(Constants.KEY_CURRENT_USER));
    } else {
      return this.http.get(`${this.appConfig.apiUrl}/api/frame/user`).pipe(
        tap((res) => {
          const user = res;
          this.cacheService.setCache(Constants.KEY_CURRENT_USER, user);
          return user;
        })
      );
    }
  }

  get(id: string): Observable<User> {
    return this.http.get(`${this.appConfig.apiUrl}/api/user-info/${id}`);
  }

  create(user: User, password: string): Observable<HttpResponse<any>> {
    return this.http.post(`${this.appConfig.apiUrl}/api/user-info`, user, {
      search: { password },
    });
  }

  updateMe(user: User, password: string): Observable<HttpResponse<any>> {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/user-info/${user.userId}`,
      user,
      { search: { password } }
    );
  }

  changePassword(
    oldPassword: string,
    newPassword: string
  ): Observable<HttpResponse<any>> {
    return this.http.post(
      `${this.appConfig.apiUrl}/api/change-password`,
      {},
      { search: { oldPassword, newPassword } }
    );
  }
}
