export interface Notify {
  id: number | string;
  level: string;
  message: String;
  voiceTime?: number;
  dialogFlag?: boolean;
  voiceFlag?: boolean;
}

export enum NotifyLevel {
  '01' = 'danger',
  '02' = 'warn',
  '03' = 'info'
}

export enum AlarmLevel {
  'serious' = '01',
  'normal' = '02',
  'prompt' = '03',
  'log' = '04'
}

export enum AlarmAction {
  add = '0',
  update = '1',
  delete = '2'
}

export enum ConfigType {
  point = '/deviceMonitorNew/'
}
