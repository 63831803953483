import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'mpt-model-selector',
  templateUrl: './model-selector.component.html',
})
export class ModelSelectorComponent {
  @Input() public isShow: boolean = false;
  @Input() public isEdit: boolean = true;
  @Input() public isRadio: boolean = true;
  @Input() public deviceTypes = null;
  @Output() public selectedData = new EventEmitter();
  @Output() public showEvent = new EventEmitter<boolean>();
  public selectedModel: any;

  constructor() {}

  public onShow() {
    this.showEvent.emit(true);
  }

  public onHide() {
    this.isShow = false;
    this.showEvent.emit(false);
  }

  public getSelectedModel(data) {
    this.selectedModel = data;
  }

  public submitData() {
    this.selectedData.emit(this.selectedModel);
    this.onHide();
  }
}
