import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { DeviceService } from 'src/app/components/monitor/shared/device.service';
import { CacheService } from 'src/app/common/services/cache.service';
import { Constants } from 'src/app/common/Constants';
import { StationMonitorService } from '../station-monitor.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';

@Component({
  selector: 'app-device-list',
  templateUrl: './device-list.component.html',
  styleUrls: ['./device-list.component.less'],
})
export class DeviceListComponent implements OnInit {
  public tabType = 'topology';
  public topologyType = 'founderTopology';
  public items: any[] = [];
  public stationId: string;
  public backItem = {
    showIcon: false,
    link: '/yxjs',
  };
  showDeviceDetail: boolean = false;
  fromSearchBox: boolean = false;

  constructor(
    private deviceService: DeviceService,
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private stationmonitorservice: StationMonitorService,
    private router: Router
  ) {
    this.activatedRoute.queryParams.subscribe((params) => {
      // this.tabType = 'topology';
      this.showDeviceDetail = false;
      // if (params.stationId && this.stationId === params.stationId) {
      //     return;
      // }
      this.stationId = params.stationId
        ? params.stationId
        : this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID);

      if (!this.stationId) {
        return;
      }

      if (params.deviceId && params.stationId && params.deviceType) {
        this.showDeviceDetail = true;
      }

      this.deviceService
        .getDeviceGroup(this.stationId)
        .subscribe((deviceGroup: any) => {
          this.items = deviceGroup.map((item) => {
            if (item.deviceType === 'U01U02') {
              item.tabType = 'line';
              item.icon = '<i class="iconfont icon-tab-line"></i>';
            }
            if (item.deviceType === 'BYQ') {
              item.tabType = 'boxChange';
              item.icon = '<i class="iconfont icon-tab-box-change"></i>';
            }
            if (item.deviceType === 'JNB') {
              item.tabType = 'concentratedInverter';
              item.icon = '<i class="iconfont icon-tab-inverter"></i>';
            }
            if (item.deviceType === 'NB') {
              item.tabType = 'stringInverter';
              item.icon = '<i class="iconfont icon-tab-inverter"></i>';
            }
            if (item.deviceType === 'HLX') {
              item.tabType = 'confluenceBox';
              item.icon = '<i class="iconfont icon-tab-confluence-box"></i>';
            }
            if (item.deviceType === 'DNB') {
              item.tabType = 'electricMeter';
              item.icon = '<i class="iconfont icon-tab-electric-meter"></i>';
            }
            if (item.deviceType === 'QXZ') {
              item.tabType = 'weatherStation';
              item.icon = '<i class="iconfont icon-tab-weather-station"></i>';
            }
            if (item.deviceType === 'QT') {
              item.tabType = 'other';
              item.icon = '<i class="iconfont icon-tab-other"></i>';
            }
            if (item.deviceType === 'FJ') {
              item.tabType = 'FJ';
              item.icon = '<i class="iconfont icon-tab-other"></i>';
            }
            return {
              name: item.deviceTypeText,
              num: item.deviceNum,
              deviceType: item.deviceType,
              tabType: item.tabType,
              icon: item.icon,
            };
          });
          let index = _.findIndex(this.items, function (o) {
            return o.deviceType == 'NB';
          });
          if (index !== -1) {
            this.tabType = this.items[index].tabType;
          } else {
            index = _.findIndex(this.items, function (o) {
              return o.deviceType == 'JNB';
            });
            if (index != -1) {
              this.tabType = this.items[index].tabType;
            } else {
              index = _.findIndex(this.items, function (o) {
                return o.deviceType == 'BYQ';
              });
              if (index != -1) {
                this.tabType = this.items[index].tabType;
              } else {
                this.tabType = 'topology';
              }
            }
          }
        });
    });
    this.stationmonitorservice.deviceDetailHandler.subscribe((res) => {
      if (res.stationId) {
        this.showDeviceDetail = true;
      } else {
        this.showDeviceDetail = false;
      }
    });
  }

  ngOnInit() { }

  topologyClick(event, type, op) {
    event.stopPropagation();
    this.tabType = 'topology';
    this.topologyType = type;
    op.hide();
  }
}
