import { FormArray } from '@angular/forms';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Injectable()
export class FormService {
  public invalidKeys: string[] = [];

  public constructor() {}

  public getInvalidKey() {
    return this.invalidKeys[0];
  }

  public isValid(form: FormGroup) {
    for (const key in form.controls) {
      if (form.controls.hasOwnProperty(key)) {
        if (!form.controls[key].valid) {
          console.log(key + '为空');
          this.invalidKeys.push(key);
          const controls = form.controls[key] as FormArray;
          if (controls.controls) {
            if (_.isArray(controls.controls)) {
              controls.controls.map((item: FormGroup) => {
                this.isValid(item);
              });
            } else {
              const object = controls.controls as any;
              for (const key in object) {
                if (Object.prototype.hasOwnProperty.call(object, key)) {
                  const element = object[key];
                  this.isValid(element);
                }
              }
            }
          }
          form.controls[key].markAsDirty();
        }
      }
    }
    return form.valid;
  }
}
