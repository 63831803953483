import { Injectable } from '@angular/core';
import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { ClassesType } from '../../../common/models/classes-type.model';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class DeviceAssetsService {
  public apiUrl: string = this.appConfig.apiUrl;

  constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

  // 仓库
  public getWarehouses(allWarehouses) {
    if (!allWarehouses) {
      return this.http.get(`${this.apiUrl}/api/warehouse/myWarehouses`);
    }
    return this.http.get(`${this.apiUrl}/api/warehouse/warehousesByCustomerId`);
  }
  // 仓库
  public getWarehousesTree() {
    return this.http.get(`${this.apiUrl}/api/warehouse/warehouseTree`);
  }

  // 查询页面 物料模糊查询
  public getMaterials(name, materialType, materialStatus) {
    return this.http.get(`${this.apiUrl}/api/materialInfo/materials`, {
      search: { name, materialType, materialStatus },
    });
  }
  // 查询页面 物料模糊查询
  public getMaterialsById(materialId) {
    return this.http.get(`${this.apiUrl}/api/materialInfo/materialListDto`, {
      search: { materialId },
    });
  }

  // 入库 物料查询
  public getMaterialDtos(name, warehouseId, materialType) {
    return this.http.get(`${this.apiUrl}/api/material/materialDtos`, {
      search: { name, warehouseId, materialType },
    });
  }

  // 出入库 物料查询
  public getMaterialInventory(name, warehouseId, materialType) {
    return this.http.get(`${this.apiUrl}/api/material/materialInventoryDtos`, {
      search: { name, warehouseId, materialType },
    });
  }

  // 出入库 操作类别
  public getoperatingCategory(classType: string) {
    return this.http
      .get(`${this.apiUrl}/api/material/propLists/searchSonPropList`, {
        search: { classType },
      })

      .toPromise();
  }

  // 仓库行政区下拉框
  public getRegions() {
    return this.http
      .get(`${this.apiUrl}/api/regions`)

      .toPromise();
  }

  // 获取人员信息
  public getUsers(name) {
    return this.http
      .get(`${this.apiUrl}/api/appUser/appUsers`, { search: { name } })
      .pipe(
        map((res: any) => {
          return res.content;
        })
      )
      .toPromise();
  }

  /**
   * @param type
   * 01	设备分类
   * 02	备品备件分类
   * 03	工器具类别
   * 04	缺陷类别
   * 05	工单类别
   * 09	非生产物资
   */
  public getClassesType(type) {
    let url = '';
    if (type === ClassesType.nonProductiveType) {
      url = `${this.appConfig.apiUrl}/api/class/classes`;
    } else {
      url = `${this.appConfig.apiUrl}/api/class/tree`;
    }
    return this.http
      .get(url, { search: { classType: type } })

      .toPromise();
  }

  // 通过 操作类别listId 取value
  public setOptionTypeVal(items, parentVal, childVal) {
    for (let item of items) {
      if (item.parentPropValue === parentVal && item.propValue === childVal) {
        return item.listId;
      }
      if (item.children) {
        for (let child of item.children) {
          if (
            child.parentPropValue === parentVal &&
            child.propValue === childVal
          ) {
            return child.listId;
          }
        }
      }
    }
  }
}
