// 告警规则
export interface AlarmRule {
  alarmCheckType: string;
  alarmCheckTypeName: string;
  alarmLevel: string;
  alarmLevelName: string;
  alarmSource: string;
  alarmSourceName: string;
  alarmText: string;
  alarmType: string;
  alarmTypeName: string;
  beginDate: string;
  endDate: string;
  beginTime: number;
  endTime: number;
  validPeriod: string;
  compareSymbol: string;
  compareVal: string;
  compareTxt: string;
  duration: string;
  pointCount: number;
  recoverCheckType: string;
  recoverCheckTypeName: string;
  ruleId: number;
  ruleTitle: string;
  ruleStatus: string;
  ruleType: string;
  sonAlarmRuleList: string[]; // 遥测子规则
  alarmSideConds: string[]; // 高级告警列表
}

// 已选测点
export interface PointList {
  ruleId: string;
  relaId: string;
  areaId: string;
  areaName: string;
  deviceId: string;
  deviceNo: string;
  pointId: number;
  pointName: string;
  remotionType: string;
  remotionTypeName: string;
  shortName: string;
  stationId: string;
}

// 遥测告警
export interface TelemetryAlarm {
  ruleType: string;
  compareSymbol: string;
  compareVal: string;
  alarmType: string;
  alarmLevel: string;
}
// 附加条件表格
export interface AdvancedAlarm {
  compareSymbol: string;
  compareVal: string;
  duration: string;
  pointId: string;
}

// 告警级别
export enum AlarmLevel {
  level1 = '01', // 严重
  level2 = '02', // 普通
  level3 = '03', // 提示
  level4 = '04' // 日志
}

// 选择测点类型
export enum PointType {
  stationList = 1,
  areaList = 2,
  deviceList = 3
}

export enum RuleType {
  remoteType = 1, // 遥信告警
  TelemetryType = 2, // 遥测告警
  advancedType = 3  // 高级告警
}

export enum AlarmRuleInitData {
  ruleStatus = '01',
  alarmSource = '01',
  ruleType = '01',
  alarmCheckType = '01', // 人工处理
  recoverCheckType = '01', // 自动确认
  alarmType = '01',
  alarmLevel = '01',
  compareSymbol = '='
}

export enum RemoteInitData {
  alarmType = '01',
  alarmLevel = '01',
  compareSymbol = '=',
  compareTxt = '',
  compareVal = '',
  duration = ''
}

export enum TelemetryInitData {
  ruleType1 = '04',
  compareSymbol1 = '>=',
  compareVal1 = '',
  // alarmType1 = '01',
  // alarmLevel1 = '01',
  ruleType2 = '05',
  compareSymbol2 = '>=',
  compareVal2 = '',
  // alarmType2 = '01',
  // alarmLevel2 = '01',
  ruleType3 = '06',
  compareSymbol3 = '<=',
  compareVal3 = '',
  // alarmType3 = '01',
  // alarmLevel3 = '01',
  ruleType4 = '07',
  compareSymbol4 = '<=',
  compareVal4 = '',
  // alarmType4 = '01',
  // alarmLevel4 = '01'
}

