import { AppConfigService } from './../../../app.config';

import { CommonService } from './../../services/common.service';
import { AuthHttp } from './../../../core/auth-http';
import { Injectable } from '@angular/core';

@Injectable()
export class MaterialMultiService {
  apiUrl: string;
  constructor(
    private http: AuthHttp,
    private cs: CommonService,
    private appconfigservice: AppConfigService
  ) {
    this.apiUrl = this.appconfigservice.apiUrl;
  }

  public queryMaterial(params) {
    return this.http.get(`${this.apiUrl}/api/materialInfo/materials2`, {
      params: this.cs.filterEffectParam(params),
    });
  }
}
