import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges,
    ViewChild,
    ElementRef
} from "@angular/core";
import * as moment from "moment";

import { Alarm, AlarmLevel, AlarmIcon } from "./alarm.model";
import { Constants } from "../../../../common/Constants";
import { HeaderService } from "../header.service";
import { RealTimeService } from "../../../alarm/alarm-manage/real-time/real-time.service";
import { AlarmManageService } from "../../../alarm/alarm-manage/alarm-manage.service";
import { CacheService } from "../../../../common/services/cache.service";
import { Observable, fromEvent } from "rxjs";
import { Router } from "@angular/router";

@Component({
    selector: "mpt-alarm",
    templateUrl: "alarm.component.html",
    styleUrls: ["./alarm.component.less"]
})
export class AlarmComponent implements OnInit, OnChanges {
    @Input() alarmLists = [];
    @Input() stopAlarm = null;
    public times = 0;
    public elem: any;
    public start = 0;
    public alarms: Alarm[] = [];

    @ViewChild("audioOption", { static: false }) audioPlayerRef: ElementRef;
    playAudioSub: any = null;

    public constructor(
        private headerService: HeaderService,
        private alarmService: RealTimeService,
        private alarmManageService: AlarmManageService,
        private router: Router,
        private cacheService: CacheService
    ) {}

    public ngOnInit() {
        // this.alarmManageService.getStationList().then((stations) => {
        //   this.alarmService.getRealTimeData({
        //     alarmLevel: null,
        //     alarmList: '1',
        //     stationId: stations.map((station) => station.stationId),
        //     logId: null,
        //     limit: 10
        //   }).subscribe((res) => {
        //     if (!res || !res.list) {
        //       return;
        //     }
        //     res.list.forEach((alarm) => {
        //       this.alarms.push({
        //         id: alarm.logId,
        //         title: `${alarm.stationName} / ${alarm.deviceName}`,
        //         content: alarm.alarmText,
        //         time: alarm.startTime,
        //         icon: this.getIconPath(alarm.alarmLevel),
        //         level: alarm.alarmLevel
        //       });
        //     });
        //   });
        // });
        // console.log("初始化",this.alarmLists)
    }

    ngOnChanges(changes: SimpleChanges): void {
        //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
        //Add '${implements OnChanges}' to the class.
        if (changes.alarmLists && !changes.alarmLists.firstChange) {
            this.alarmLists = changes.alarmLists.currentValue.alarms;
            this.alarms = [];
            this.alarmLists.forEach(alarm => {
                this.alarms.push({
                    id: alarm.logId,
                    title: `${alarm.stationName} / ${alarm.deviceName}`,
                    content: alarm.alarmText,
                    stationId: alarm.stationId,
                    time: alarm.startTime,
                    icon: this.getIconPath(alarm.alarmLevel),
                    level: alarm.alarmLevel
                });
            });
            this.playTimes(changes.alarmLists.currentValue.voiceTimes);
        }
        if (changes.stopAlarm && !changes.stopAlarm.firstChange) {
            if (changes.stopAlarm.currentValue == null) {
                if (this.playAudioSub) {
                    this.playAudioSub.unsubscribe();
                }
            }
        }
    }

    public realTime(alarm) {
        this.cacheService.setCache(
            Constants.KEY_CURRENT_STATION_ID,
            alarm.stationId,
            true
        );

        alarm.timestamp = new Date().getTime();
        this.router.navigate(["/alarmManage/realTime"], { queryParams: alarm });
    }

    public getIconPath(level: AlarmLevel): string {
        return "/assets/skin/skin-blue/nav/" + AlarmIcon[AlarmLevel[level]];
    }

    public getTimeText(time: string) {
        return moment(time).format(Constants.DATETIME_NO_YEAR);
    }

    public playTimes(count) {
        if (count == 0) {
            return;
        }
        this.elem = this.audioPlayerRef.nativeElement;
        this.start = 0;
        this.times = count;
        var playAudio = fromEvent(this.elem, "ended");
        this.playAudioSub = playAudio.subscribe(e => {
            this.start++;
            if (this.start < this.times) {
                this.elem.play();
            } else {
                this.playAudioSub.unsubscribe();
                this.playAudioSub = null;
            }
        });
        this.elem.play();
    }
}
