export class Constants {
    // 时间相关
    public static readonly DATETIME_FULL = 'YYYY-MM-DD HH:mm:ss';
    public static readonly DATETIME_NO_SECOND = 'YYYY-MM-DD HH:mm';
    public static readonly DATETIME_NO_YEAR = 'MM-DD HH:mm:ss';
    public static readonly DATETIME_DATE = 'YYYY-MM-DD';
    public static readonly DATETIME_TIME = 'HH:mm:ss';
    public static readonly DATETIME_TIME_NO_SECOND = 'HH:mm';
    public static readonly DATETIME_YEAR = 'YYYY';
    // cache相关
    public static readonly KEY_BREADCRUMB_CACHED = 'BREADCRUMB_CACHED';
    public static readonly KEY_CACHED = 'KEY_NEMS_CACHED';
    public static readonly KEY_CURRENT_STATION_ID = 'KEY_CURRENT_STATION_ID';
    public static readonly KEY_CURRENT_STATION_NAME = 'KEY_CURRENT_STATION_NAME';
    public static readonly KEY_ORGANIZATION = 'KEY_ORGANIZATION';
    public static readonly KEY_CURRENT_USER = 'KEY_CURRENT_USER';

    //监视列表页面条件
    public static readonly DEVICE_LIST_CONDITION = 'DEVICE_LIST_CONDITION';

    //告警管理页面条件
    public static readonly ALARM_MANAGE_REAL_TIME = 'ALARM_MANAGE_REAL_TIME';
}
