import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { FormGroup, FormBuilder, FormControl, FormArray } from '@angular/forms';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { MaterialType, IoType } from '../../device-assets/shared/index.model';
import { ClassesType } from 'src/app/common/models/classes-type.model';
import { MaintainsService } from '../../maintains/maintains.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { CommonService } from 'src/app/common/services/common.service';
import { CodeTableService } from 'src/app/core/code-table.service';
import { AppConfigService } from 'src/app/app.config';
import * as moment from 'moment';
import { buildWorkFlow } from '../../defect/defect-manage/operate-defect/work-flow.echarts';
import * as _ from 'lodash';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-operate-maintain',
  templateUrl: './operate-maintain.component.html',
  styleUrls: ['./operate-maintain.component.less'],
})
export class OperateMaintainComponent implements OnInit {
  @Input() public type = 'add';
  @Input() public inspectionId = 0;
  @Input() public inspectionType;
  @Input() public data = null;
  @Input() public isShow = false;

  @Output() hideEvent = new EventEmitter<boolean>();
  @Output() refreshEvent = new EventEmitter<boolean>();

  @ViewChild('fileinput', { static: false }) fileinput: ElementRef;
  @ViewChild('container', { static: false }) mapElement: ElementRef;
  // 标题
  title: string;
  // ticket表单
  form: FormGroup;
  // 字段
  fields: any;
  // 电站id
  stationId: any;
  // 电站选择器参数
  staParams = null;
  // 用户选择器弹窗 开关
  isShowUserDialog: boolean;
  // 用户选择弹窗类型 单选/多选
  userDialogType: boolean;
  // 用户选择器 修改字段名称
  userDialogTarget: any;
  // 用户选择器参数
  person = [];
  // 日历参数
  canlendarLocale: any;

  // 订单Id
  orderId: any;
  // 工作流程 nodes
  nodes: any;
  // 工作流程 links
  links: any;
  // 工作流程 图表 options
  options: any;

  // 抄送权限
  ccPermission: any;
  // 评论权限 （由评论列表中参数控制）
  commentPermission: any;
  // 弹窗 按钮组
  buttons: any;

  // 检修预试单检查 弹窗开关
  ticketCheckDialog = false;
  // 检修预试单检查默认值
  checkDialog = {
    checkStatus: '02',
    checkMemo: null,
  };
  // 检修预试单检查状态 选项
  checkStatuses: SelectItem[];

  // 出入库弹窗 开关
  isShowRecords: boolean;
  // 出入库 传入参数
  recordsParam: any;
  // materialType
  materialType = MaterialType.spareParts;
  // ClassesType
  classesType = ClassesType.spareParts;
  // 是否是进行节点扭转 选择用户
  nextUserFlag: boolean;
  // 扭转用户ID
  nextUserIds: any;

  // 备注弹窗 开关
  memoDialog: boolean;
  // 备注弹窗 备注字段
  memo: any;

  // 按钮行为
  buttonAction: any;

  // 评论列表
  commentsList: any[];

  // 设备Id
  deviceId: any;
  objClassOptions: any;
  ioTypes: IoType;
  selectedTeam: { teamId: any; teamName: any }[];
  objPermission: number;
  fileEndpointUrl: string;
  isShowObject: boolean;
  deviceType: any;
  addDeviceParams: any;
  showMaintainDeviceDetails: boolean;
  objItemInfo: any;
  objGroups: any[];
  deviceDetailsType: string;
  map: any;
  centerPoint: any[] = [];

  freshFlag = false;
  constructor(
    private fb: FormBuilder,
    private maintainsservice: MaintainsService,
    private calendarLocales: CalendarLocales,
    private commonservice: CommonService,
    private codeTableService: CodeTableService,
    private confirmationService: ConfirmationService,
    private appConfig: AppConfigService,
    private tipservice: TipService
  ) {
    // 初始化 日历参数
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    // 初始化 检修预试单单form
    this.initForm();
    this.fileEndpointUrl = this.appConfig.fileEndpointUrl;
  }

  ngOnInit() {
    // 根据 type 判断 弹窗显示 挑剔
    if (this.type === 'add') {
      this.title = '新增检修预试单';
    } else if (this.type === 'view') {
      this.title = '查看检修预试单';
    } else if (this.type === 'edit') {
      this.title = '编辑检修预试单';
    }
    // 获取设备id 由告警转检修预试单时 需要
    this.deviceId = (this.data && this.data.deviceId) || null;
    // 初始化数据
    this.getTicketData();
  }

  /**
   * 初始化form表单
   */
  private initForm() {
    this.form = this.fb.group({
      // 基础信息
      inspectionId: new FormControl(null), // 检修预试单
      inspectionCode: new FormControl(null), // 检修预试单号
      orderId: new FormControl(null), // 检修预试单号
      stationId: new FormControl(null), // 电站Id
      stationName: new FormControl(null), // 电站名称
      inspectionType: new FormControl(null), // 巡检类型
      dispatcher: new FormControl(null), // 派单人
      dispatcherName: new FormControl(null), // 派单人名称
      responsiblePerson: new FormControl(null), // 巡检负责人
      responsiblePersonName: new FormControl(null), // 巡检负责人名称
      players: this.fb.array([]), // 同组选件人员
      playersText: new FormControl(null), // 同组选件人员名称
      planStartTime: new FormControl(null), // 计划开始时间
      planEndTime: new FormControl(null), // 计划完成时间
      startTime: new FormControl(new Date()), // 开始时间
      endTime: new FormControl(null), // 完成时间
      workHours: new FormControl(null), // 工作量

      objGroups: this.fb.array([]), // 巡检对象

      checker: new FormControl(null), // 检查人
      checkerName: new FormControl(null), // 检查人名称
      checkTime: new FormControl(null), // 检查时间
      checkStatus: new FormControl(null), // 检查结果
      checkStatusText: new FormControl(null), // 检查结果
      checkMemo: new FormControl(null), // 检查备注
      // 工作流程
      workflow: this.fb.group({
        nodes: this.fb.array([]),
        links: this.fb.array([]),
      }),

      // 流程日志
      workflowLogs: this.fb.array([]),

      // 抄送
      ccUsers: this.fb.array([]),
    });
  }

  /**
   * 初始化 页面数据
   */
  private getTicketData() {
    this.commonservice.mask.show();
    this.maintainsservice
      .getMaintainsBymaintainId(this.inspectionId, { inspectionType: this.inspectionType })
      .subscribe(
        (res: any) => {
          // 页面字段
          this.initData(res);
        },
        () => { },
        () => {
          this.commonservice.mask.hide();
        }
      );
  }

  public initData(res) {
    this.fields = res.fields || [];
    for (const key in this.fields) {
      if (this.fields.hasOwnProperty(key)) {
        const element = this.fields[key];
        if (key === 'inspectionType') {
          if (element.props) {
            element.props.map((item) => {
              item.value = item.first;
              item.label = item.second;
            });
          }
        }
      }
    }

    // 按钮组
    this.buttons = res.buttons || [];
    // 返回值
    const values = res.inspection;

    // 抄送权限
    this.ccPermission = res.ccPermission;
    // 抄送权限
    this.objPermission = res.objPermission;
    // 评论权限
    this.commentPermission = res.commentPermission;
    // orderId
    this.orderId = values.orderId;
    // 处理电站
    this.stationId = values.stationId;
    if (this.stationId) {
      this.staParams = {
        sta_stationIds: values.stationId,
      };
    } else {
      this.staParams = {};
    }

    // 时间格式处理
    values.planStartTime = values.planStartTime ? moment(values.planStartTime).toDate() : null;
    values.planEndTime = values.planEndTime ? moment(values.planEndTime).toDate() : null;
    values.startTime = values.startTime ? moment(values.startTime).toDate() : null;
    values.endTime = values.endTime ? moment(values.endTime).toDate() : null;

    // 同组选件人员
    if (values.players !== null) {
      const control = this.form.get('players') as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      const playersText = values.players.map((item) => {
        control.push(this.initUsers());
        return item.userName;
      });
      values.playersText = playersText.join(',');
    } else {
      values.players = [];
    }

    if (values.objGroups !== null) {
      const controls = this.form.get('objGroups') as FormArray;
      while (controls.length) {
        controls.removeAt(controls.length - 1);
      }
      this.objGroups = [];
      values.objGroups.map((item, index) => {
        controls.push(this.initObjGroup());
        const objControl = controls.controls[index].get('objs') as FormArray;
        while (objControl.length) {
          objControl.removeAt(objControl.length - 1);
        }
        item.objs.map((i) => {
          objControl.push(this.initObj());
          this.objGroups.push({
            objId: i.objId,
            latitude: +i.latitude,
            longitude: +i.longitude,
            inspectionStatus: i.inspectionStatus,
            deviceNo: i.deviceNo,
            groupType: item.groupType,
          });
        });
      });
    } else {
      this.objGroups = [];
      values.objGroups = [];
    }

    // 工作流程
    if (values.workflow !== null) {
      const workflow = values.workflow;
      for (const key in workflow) {
        if (workflow.hasOwnProperty(key)) {
          const element = workflow[key];
          if (key === 'nodes') {
            this.nodes = element;
            const control = (this.form.get('workflow') as FormGroup).controls[key] as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            element.map((item) => {
              control.push(this.initNodes());
            });
          } else if (key === 'links') {
            this.links = element;
            const control = (this.form.get('workflow') as FormGroup).controls[key] as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            element.map((item) => {
              control.push(this.initLinks());
            });
          }
        }
      }
      this.options = buildWorkFlow(this.links, this.nodes);
    } else {
      values.workflow = {};
    }

    // 流程日志
    if (values.workflowLogs) {
      const control = this.form.get('workflowLogs') as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      values.workflowLogs.map((item) => {
        control.push(this.initWorkflowLogs());
      });
    } else {
      values.workflowLogs = [];
    }

    // 抄送
    if (values.ccUsers !== null) {
      const control = this.form.get('ccUsers') as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      values.ccUsers.map((item) => {
        control.push(this.initCcUser());
      });
    } else {
      values.ccUsers = [];
    }

    // 评论
    if (values.comments === null) {
      values.comments = [];
      this.commentsList = [];
    } else {
      this.commentsList = values.comments;
    }
    // 表单填值
    this.form.patchValue(values);
    // 查看状态显示权限
    if (this.type === 'view') {
      // 文档权限
      this.objPermission = 0;
      // 抄送权限
      this.ccPermission = 0;
      this.form.disable();
    }
  }

  // 流程日志数组
  getWorkflowLogs(form) {
    return form.controls.workflowLogs.controls;
  }

  // 弹窗关闭事件
  onHide(event) {
    this.hideEvent.emit(true);
    if (this.freshFlag) {
      this.refreshEvent.emit(true);
    }
  }

  // 电站选择器 返回事件
  outputStaParamsEvent(event) {
    if (event) {
      this.stationId = event.sta_stationIds;
      this.form.patchValue({
        stationId: event.sta_stationIds,
        stationName: event.sta_stationName,
      });
    } else {
      this.stationId = null;
      this.form.patchValue({
        stationId: null,
        stationName: null,
      });
    }
  }

  /**
   * 打开用户选择
   * @param <{ String }> type - 用户选择器类型 single/multi.
   * @param <{ String }> field - 当前操作的字段.
   * @param <{ Boolean }> editable - 当前字段是否客编辑.
   */
  openUserDialog(event, type, field, editable) {
    // 如果是查看或是不可编辑状态 返回
    if (this.type === 'view' || editable === false) {
      return;
    }
    this.userDialogType = type === 'single' ? true : false;
    this.userDialogTarget = field;
    const value = this.form.value[field];
    // 判断 单选还是多选
    if (this.userDialogType === true) {
      this.person = value ? [{ userId: value }] : null;
    } else {
      this.person = value.map((item) => {
        return {
          userId: item.userId,
          realName: item.userName,
        };
      });
    }
    this.isShowUserDialog = true;
  }

  // 用户选择器 返回事件
  personChange(event) {
    // 节点扭转选择用户
    if (this.nextUserFlag === true) {
      if (event.userId) {
        this.nextUserIds = event.userId;
        this.alertTypeFunction();
      }
      this.nextUserFlag = false;
      return;
    }
    if (this.userDialogType) {
      this.form.patchValue({
        [this.userDialogTarget]: event.userId,
        [this.userDialogTarget + 'Name']: event.realName,
      });
    } else {
      const control = this.form.get(this.userDialogTarget) as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      const Users = [];
      const UsersText = event.map((item) => {
        Users.push({
          userId: item.userId,
          userName: item.realName,
        });
        control.push(this.initUsers());
        return item.realName;
      });
      this.form.get(this.userDialogTarget).patchValue(Users);
      this.form.get(this.userDialogTarget + 'Text').patchValue(UsersText.join(','));
    }
  }

  getObjGroups(form) {
    return form.controls.objGroups.controls;
  }

  getObjs(form) {
    return form.controls.objs.controls;
  }

  addObj(event, objGroup, index) {
    if (!this.inspectionId) {
      event.preventDefault();
      this.tipservice.tip('warn', '请先点击开始按钮');
      return;
    }
    if (objGroup.value.groupType === 'pic') {
      this.addMaintainPic(event, objGroup, index);
    } else {
      this.addMaintainDevice(objGroup, index);
    }
  }

  /**
   * onSelectFile
   */
  public addMaintainPic(event, objGroup, index) {
    const files = event.dataTransfer ? event.dataTransfer.files : event.target.files;
    const extName = 'GIF,BMP,JPG,JPEG,PNG';
    for (const file of files) {
      const i = file.name.lastIndexOf('.');
      const len = file.name.length;
      const extEndName = file.name.substring(i + 1, len);
      if (extName.indexOf(extEndName.toUpperCase()) === -1) {
        return this.tipservice.tip('warn', '上传的文件格式不正确');
      }
      const params = {
        inspectionId: this.inspectionId,
        objGroup: objGroup.value.groupId,
      };
      this.maintainsservice
        .uploadPic(file, params)
        .then((res: any) => {
          if (res) {
            this.savePic(res, index);
          }
        })
        .then((res) => {
          if (this.fileinput && this.fileinput.nativeElement) {
            this.fileinput.nativeElement.value = '';
          }
        });
    }
  }

  public savePic(data, index) {
    const objGroupscontrol = this.form.get('objGroups') as FormArray;
    const control = objGroupscontrol.controls[index].get('objs') as FormArray;
    const objItems = this.form.value.objGroups[index].objs || [];
    objItems.push(data);
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    objItems.map(() => {
      control.push(this.initObj());
    });
    control.patchValue(objItems);
    this.tipservice.tip('success', '上传成功');
    this.freshFlag = true;
  }

  public deleteMaintainsObj(objId, index) {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定要删除该项么？`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        this.maintainsservice.deleteObjItem(objId).subscribe((res) => {
          const objGroupscontrol = this.form.get('objGroups') as FormArray;
          const control = objGroupscontrol.controls[index].get('objs') as FormArray;
          const objItems = this.form.value.objGroups[index].objs || [];
          _.remove(objItems, (o: any) => {
            return o.objId === objId;
          });
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          objItems.map(() => {
            control.push(this.initObj());
          });
          control.patchValue(objItems);
          this.tipservice.tip('success', '删除成功');
        });
      },
    });
  }

  public addMaintainDevice(objGroup, index) {
    this.stationId = this.staParams.sta_stationIds || null;
    this.deviceType = objGroup.value.deviceType || null;
    this.addDeviceParams = {
      index,
      objGroup: objGroup.value.groupId,
      objId: 0,
    };
    this.isShowObject = true;
  }

  public deviceChange(event) {
    if (!_.isArray(event)) {
      const params = {
        deviceId: event.deviceId,
        inspectionType: this.inspectionType,
        objGroup: this.addDeviceParams.objGroup,
        objId: 0,
      };
      this.maintainsservice.getObjItem(params).subscribe((res) => {
        this.objItemInfo = res;
        this.deviceDetailsType = 'add';
        this.showMaintainDeviceDetails = true;
      });
    }
  }

  editObj(objId) {
    this.maintainsservice.getObjItem({ objId }).subscribe((res) => {
      this.objItemInfo = res;
      this.deviceDetailsType = this.type;
      this.showMaintainDeviceDetails = true;
    });
  }

  /**
   * closeMaintainDeviceDetails
   */
  public closeMaintainDeviceDetails(event) {
    this.showMaintainDeviceDetails = false;
  }
  public dateEmitterEvent(event) {
    const objInfo = event;
    if (objInfo) {
      const data = objInfo.params;
      const control = this.form.get('objGroups') as FormArray;
      const objGroupValue = control.value;
      if (objInfo.type === 'add') {
        objGroupValue.map((item, index) => {
          if (item.groupId === data.objGroup) {
            const objControl = control.controls[index].get('objs') as FormArray;
            objControl.push(this.initObj());
            objGroupValue[index].objs.push(data);
            this.form.get('objGroups').patchValue(objGroupValue);
          }
        });
      } else if (objInfo.type === 'edit') {
        objGroupValue.map((item, index) => {
          if (item.groupId === data.objGroup) {
            item.objs.map((objItem, i) => {
              if (objItem.objId === data.objId) {
                objGroupValue[index].objs[i] = data;
                this.form.get('objGroups').patchValue(objGroupValue);
              }
            });
          }
        });
      } else if (objInfo.type === 'delete') {
        objGroupValue.map((item, index) => {
          if (item.groupId === data.objGroup) {
            const objs = _.cloneDeep(objGroupValue[index].objs);
            _.remove(objGroupValue[index].objs, (o: any) => {
              return o.objId === data.objId;
            });
            const objControl = control.controls[index].get('objs') as FormArray;
            objControl.removeAt(
              _.findIndex(objs, (o: any) => {
                return o.objId === data.objId;
              })
            );
          }
        });
      }
      this.objGroups = [];
      this.form.value.objGroups.map((item) => {
        item.objs.map((i) => {
          this.objGroups.push({
            objId: i.objId,
            latitude: +i.latitude,
            longitude: +i.longitude,
            inspectionStatus: i.inspectionStatus,
            deviceNo: i.deviceNo,
            groupType: item.groupType,
          });
        });
      });
      this.freshFlag = true;
    }
  }

  // 按钮组 操作
  switchFunction(button) {
    switch (button.buttonAction) {
      case '$remind$':
        this.remindFunction(this.inspectionId);
        break;
      case '$check$':
        this.openDefectCheckDialog();
        break;
      case 'materialOut':
        this.materialOutFunction();
        break;
      case 'materialIn':
        this.materialInFunction();
        break;
      case 'delete':
        this.deleteFunction();
        break;
      default:
        this.buttonFunction(button);
        break;
    }
  }

  // 按钮组 step1：formCheck
  buttonFunction(button) {
    if (button.formCheck === '1') {
      this.maintainsservice.getNullable(button.targetNodeId).then((res: any) => {
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            if (key.indexOf('.') === -1) {
              const element = res[key];
              if (this.form.value[key] === null && !element) {
                this.tipservice.tip('warn', this.fields[key].name + '字段不能为空');
                return;
              }
            } else {
              const aNewKey = key.split('.');
              const element = res[key];
              if (this.form.value[aNewKey[0]].length > 0 && !element) {
                let flag = true;
                this.form.value[aNewKey[0]].every((item) => {
                  if (item[aNewKey[1]] === null) {
                    this.tipservice.tip('warn', this.fields[key].name + '字段不能为空');
                    flag = false;
                    return false;
                  } else {
                    return true;
                  }
                });
                if (!flag) {
                  return;
                }
              }
            }
          }
        }
        this.userSelectorFunction(button);
      });
    } else {
      this.userSelectorFunction(button);
    }
  }

  // 按钮组 step2：userSelector
  public userSelectorFunction(button) {
    this.buttonAction = button;
    if (button.userSelector === '0') {
      this.alertTypeFunction(button);
    } else if (button.userSelector === '1') {
      this.person = [];
      this.userDialogType = true;
      this.nextUserFlag = true;
      this.isShowUserDialog = true;
    } else if (button.userSelector === '2') {
      this.person = [];
      this.userDialogType = false;
      this.nextUserFlag = true;
      this.isShowUserDialog = true;
    }
  }

  public alertTypeFunction(button?) {
    button = button ? button : this.buttonAction;
    this.memo = null;
    if (button.alertType === '0') {
      this.actionFunction();
    } else if (button.alertType === '1') {
      this.confirmationService.confirm({
        header: '提示',
        message: `确定要提交么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          this.actionFunction();
        },
      });
    } else if (button.alertType === '2') {
      this.memo = null;
      this.nextUserIds = null;
      this.memoDialog = true;
    }
  }

  // 催单操作
  remindFunction(inspectionId) {
    this.maintainsservice.remind(inspectionId).subscribe((res: string) => {
      this.tipservice.tip('success', res);
    });
  }

  // 打开检修预试单检查弹窗
  openDefectCheckDialog() {
    this.codeTableService.getNemsProp('orderCheckStatus').then((res) => {
      this.checkStatuses = res;
      this.ticketCheckDialog = true;
    });
  }

  // 检查操作
  checkFunction(event) {
    if (this.checkDialog.checkMemo && this.checkDialog.checkMemo.length > 500) {
      this.tipservice.tip('warn', '结果说明不超过500字符');
      return;
    }
    const params = {
      ticketId: this.inspectionId,
      checkStatus: this.checkDialog.checkStatus,
      checkMemo: this.checkDialog.checkMemo,
    };
    this.maintainsservice.check(params).subscribe((res: any) => {
      this.tipservice.tip('success', '提交成功');
      this.form.patchValue(res);
      this.ticketCheckDialog = false;
    });
  }

  // 出库
  materialOutFunction() {
    this.recordsParam = {
      orderCode: this.form.value.ticketCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '08',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.out;
    this.isShowRecords = true;
  }

  // 入库
  materialInFunction() {
    this.recordsParam = {
      orderCode: this.form.value.ticketCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '08',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.into;
    this.isShowRecords = true;
  }

  // 出入库弹窗 返回事件
  submitAddData(event) {
    this.freshFlag = true;
    this.hideEvent.emit(true);
  }

  // 删除
  deleteFunction() {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定要删除该检修预试单单么?`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        const inspectionId = this.form.value.inspectionId;
        this.maintainsservice.deleteMaintain(inspectionId).subscribe((res) => {
          this.tipservice.tip('success', '删除成功');
          this.freshFlag = true;
          this.hideEvent.emit(true);
        });
      },
    });
  }

  // 打开转派/派单 窗口
  openDispatchDialog(button) {
    this.person = [];
    this.buttonAction = button;
    this.userDialogType = true;
    this.nextUserFlag = true;
    this.isShowUserDialog = true;
  }

  // 驳回/关闭/完成 窗口
  openMemoDialog(button) {
    this.memo = null;
    this.nextUserIds = null;
    this.memoDialog = true;
    this.buttonAction = button;
  }

  //
  actionFunction() {
    const params = {
      title: this.title,
      inspection: this.formatParams(this.form.value),
      action: this.buttonAction,
      nextUserIds: this.nextUserIds ? [this.nextUserIds] : null,
      memo: this.memo,
    };
    if (this.inspectionId) {
      this.maintainsservice.maintainAction(params).subscribe((res: any) => {
        this.nextUserIds = null;
        this.memo = null;
        if (res && res.inspection) {
          this.inspectionId = res.inspection.inspectionId;
          this.initData(res);
        }
        this.tipservice.tip('success', '提交成功');
        this.freshFlag = true;
        if (this.buttonAction.buttonAction !== 'start') {
          this.onHide(true);
        }
      });
    } else {
      this.maintainsservice.saveMaintain(params).subscribe((res: any) => {
        this.nextUserIds = null;
        this.memo = null;
        if (res && res.inspection) {
          this.inspectionId = res.inspection.inspectionId;
          this.initData(res);
        }
        this.tipservice.tip('success', '提交成功');
        this.freshFlag = true;
        if (this.buttonAction.buttonAction !== 'start') {
          this.onHide(true);
        }
      });
    }
  }

  // 抄送组件 返回事件
  ccUserEvent(data) {
    const control = this.form.get('ccUsers') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initCcUser());
    });
    this.form.patchValue({
      ccUsers: data,
    });
  }

  // 初始化 用户 formGroup
  initUsers() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  initObjGroup() {
    return new FormGroup({
      deviceType: new FormControl(null),
      groupId: new FormControl(null),
      groupName: new FormControl(null),
      groupType: new FormControl(null),
      objs: this.fb.array([]),
    });
  }

  initObj() {
    return new FormGroup({
      objId: new FormControl(null),
      inspectionId: new FormControl(null),
      standardId: new FormControl(null),
      objGroup: new FormControl(null),
      psrId: new FormControl(null),
      deviceId: new FormControl(null),
      latitude: new FormControl(null),
      longitude: new FormControl(null),
      inspectionStatus: new FormControl(null),
      updateTime: new FormControl(null),
      updater: new FormControl(null),
      deviceNo: new FormControl(null),
      deviceName: new FormControl(null),
      inspectionStatusText: new FormControl(null),
      docId: new FormControl(null),
      uri: new FormControl(null),
      items: new FormControl(null),
      status: new FormControl(null),
    });
  }

  initNodes() {
    return new FormGroup({
      statusText: new FormControl(null),
      name: new FormControl(null),
      nodeType: new FormControl(null),
      user: new FormControl(null),
      status: new FormControl(null),
    });
  }

  initLinks() {
    return new FormGroup({
      sourceId: new FormControl(null),
      targetId: new FormControl(null),
      source: new FormControl(null),
      target: new FormControl(null),
    });
  }

  initWorkflowLogs() {
    return new FormGroup({
      logId: new FormControl(null),
      action: new FormControl(null),
      executeTime: new FormControl(null),
      flowId: new FormControl(null),
      memo: new FormControl(null),
      nextNodeId: new FormControl(null),
      nextNodeName: new FormControl(null),
      nodeId: new FormControl(null),
      orderId: new FormControl(null),
      preNodeId: new FormControl(null),
      preNodeName: new FormControl(null),
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  initCcUser() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
      userIcon: new FormControl(null),
    });
  }

  formatParams(params) {
    let planStartTime = {};
    if (params.planStartTime) {
      planStartTime = {
        planStartTime: moment(params.planStartTime).format(),
      };
    }
    let planEndTime = {};
    if (params.planEndTime) {
      planEndTime = {
        planEndTime: moment(params.planEndTime).format(),
      };
    }

    let startTime = {};
    if (params.startTime) {
      startTime = {
        startTime: moment(params.startTime).format(),
      };
    }
    let endTime = {};
    if (params.endTime) {
      endTime = {
        endTime: moment(params.endTime).format(),
      };
    }

    return {
      ...params,
      ...planStartTime,
      ...planEndTime,
      ...startTime,
      ...endTime,
      objGroups: null,
    };
  }
}
