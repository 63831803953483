import { Component, OnInit } from '@angular/core'
import { buildInstalledCapacityChart } from './chart/installed-capacity.chart'
import '../../../resources/map/china.js'
import { buildStaionsNumberChart } from './chart/stations-number.chart'
import * as _ from 'lodash'
import { buildDailyPowerGenerationChart } from './chart/daily-power-generation.chart'
import { buildEquivalentHourChart } from './chart/equivalent-hour.chart'
import { buildDeviceOnlineRateChart } from './chart/device-online-rate.chart'
import { buildCO2EmissionReductionChart } from './chart/co2-emission-reduction.chart'

@Component({
  selector: 'app-map-overview',
  templateUrl: './map-overview.component.html',
  styleUrls: ['./map-overview.component.less']
})
export class MapOverviewComponent implements OnInit {
  public options: any = {}

  public typeArray = [
    'installedCapacity',
    'staionsNumber',
    'dailyPowerGeneration',
    'equivalentHour',
    'deviceOnlineRate',
    'CO2EmissionReduction'
  ]

  public echartsType: string = 'installedCapacity'
  echartInterval: any = null

  constructor() {}

  ngOnInit() {
    this.installedCapacity()
    this.setIntervalEcharts()
  }

  /**
   * setIntervalEcharts
   */
  public setIntervalEcharts() {
    this.echartInterval = null
    this.echartInterval = setInterval(() => {
      let index = _.indexOf(this.typeArray, this.echartsType)
      if (index == this.typeArray.length - 1) {
        this.echartsType = this.typeArray[0]
      } else {
        this.echartsType = this.typeArray[index + 1]
      }
      this.switchType(this.echartsType)
    }, 5000)
  }

  /**
   * setEchartActive
   */
  public stopInterval(type) {
    this.echartsType = type
    this.switchType(this.echartsType)
    clearInterval(this.echartInterval)
    this.echartInterval = null
  }

  /**
   * restartInterval
   */
  public restartInterval() {
    this.setIntervalEcharts()
  }

  /**
   * switchType
   */
  public switchType(type) {
    switch (type) {
      case 'installedCapacity':
        this.installedCapacity()
        break
      case 'staionsNumber':
        this.staionsNumber()
        break
      case 'dailyPowerGeneration':
        this.dailyPowerGeneration()
        break
      case 'equivalentHour':
        this.equivalentHour()
        break
      case 'deviceOnlineRate':
        this.deviceOnlineRate()
        break
      case 'CO2EmissionReduction':
        this.CO2EmissionReduction()
        break
      default:
        this.installedCapacity()
        break
    }
  }

  public installedCapacity() {
    this.options = buildInstalledCapacityChart()
  }

  /**
   * staionsNumberChart
   */
  public staionsNumber() {
    this.options = buildStaionsNumberChart({})
  }

  /**
   * dailyPowerGeneration
   */
  public dailyPowerGeneration() {
    this.options = buildDailyPowerGenerationChart({})
  }

  /**
   * equivalentHour
   */
  public equivalentHour() {
    this.options = buildEquivalentHourChart({})
  }

  /**
   * deviceOnlineRate
   */
  public deviceOnlineRate() {
    this.options = buildDeviceOnlineRateChart({})
  }

  /**
   * CO2EmissionReduction
   */
  public CO2EmissionReduction() {
    this.options = buildCO2EmissionReductionChart({})
  }
}
