import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import {
  ButtonModule,
  InputTextModule,
  TabViewModule,
  SharedModule as primengSharedModule
} from 'primeng/primeng';

import { SearchBoxComponent } from './searchbox.component';

import { SearchBoxService } from './searchbox.service';

@NgModule({
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    TabViewModule,
    primengSharedModule,
    FormsModule,
    RouterModule
  ],
  exports: [SearchBoxComponent],
  declarations: [SearchBoxComponent],
  providers: [SearchBoxService],
})
export class SearchBoxModule { }
