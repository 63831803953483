import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  Validators,
  FormControl,
  FormGroup,
  FormBuilder,
} from '@angular/forms';
import * as _ from 'lodash';

import { CommonService } from '../../../../../common/services/common.service';
import { AssetsBasicInfoService } from '../../basic-info.service';
import { ModelService } from '../model.service';
import { FormService } from '../../../../../common/services/form.service';
import { ClassesType } from '../../../../../common/models/classes-type.model';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'mpt-model-edit',
  templateUrl: './model-edit.component.html',
  styleUrls: ['./model-edit.component.less'],
})
export class ModelEditComponent implements OnInit, OnChanges {
  @Input() public type = 'view';
  @Input() public isShow: boolean = false;
  @Input() public data: any;
  @Output() public showEvent = new EventEmitter<boolean>();

  public classesType = ClassesType.deviceType;
  public classId: string;
  public companys = [];
  public submitData = {};
  public editForm: FormGroup;
  public editTitle: string;
  public similarityContent = [];
  public isShowSimilarityInfo: boolean = false;
  showHiddenData: boolean = false;

  constructor(
    private fb: FormBuilder,
    private formService: FormService,
    private modelService: ModelService,
    private baseInfoService: AssetsBasicInfoService,
    private common: CommonService,
    private tipservice: TipService
  ) {}

  public ngOnInit() {
    this.formInit();
    switch (this.type) {
      case 'view':
        this.editTitle = '查看型号信息';
        this.editForm.disable();
        break;
      case 'add':
        this.editTitle = '新增型号信息';
        this.editForm.enable();
        break;
      case 'edit':
        this.editTitle = '编辑型号信息';
        this.editForm.enable();
        break;
      default:
        break;
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.isShow) {
      return false;
    }
    this.initPager();
  }

  public formInit() {
    this.editForm = this.fb.group({
      modelNo: new FormControl(null, Validators.required),
      modelName: new FormControl(null, Validators.required),
      companyId: new FormControl(null, Validators.required),
      className: new FormControl(),
      memo: new FormControl(),
      powerRating: new FormControl(),
      inputNum2: new FormControl(),
      inputNum: new FormControl(),
    });
  }

  public initPager() {
    if (this.data) {
      this.initEditModel();
    } else {
      this.initAddModel();
    }
    switch (this.type) {
      case 'view':
        this.editTitle = '查看型号信息';
        this.editForm.disable();
        break;
      case 'add':
        this.editTitle = '新增型号信息';
        this.editForm.enable();
        break;
      case 'edit':
        this.editTitle = '编辑型号信息';
        this.editForm.enable();
        break;
      default:
        break;
    }
  }

  public initEditModel() {
    this.data.companyId = {
      companyId: this.data.companyId,
      companyName: this.data.companyName,
    };
    this.classId = this.data.classId;
    this.submitData = this.data;
    this.editForm.patchValue(this.data);
  }

  public initAddModel() {
    this.classId = null;
    this.submitData = {};
    this.editForm.patchValue({
      modelNo: null,
      modelName: null,
      memo: null,
      companyId: {
        companyId: null,
        companyName: null,
      },
    });
  }

  public getCompanys(e) {
    let val = e.query;
    this.baseInfoService.getCompanyByName(e.query).then((res) => {
      this.companys = res;
    });
  }

  public getClassId(data) {
    this.classId = data;
    this.submitData = _.assign({}, this.submitData, {
      classId: data,
    });
  }

  public getParamsData(data) {
    if (['NB', 'JNB'].includes(data)) {
      this.showHiddenData = true;
    } else {
      this.editForm.patchValue({
        inputNum: null,
        inputNum2: null,
        powerRating: null,
      });
      this.showHiddenData = false;
    }
  }

  formatSpinner(event, fixedNumber, field) {
    let value = +event.target.value.replaceAll(',', '');
    this.editForm.patchValue({
      [field]: fixedNumber ? +value.toFixed(fixedNumber) : +value.toFixed(0),
    });
  }

  public submitEvent() {
    this.similarityContent = [];
    this.submitData = _.assign({}, this.submitData, this.editForm.value);

    if (!this.showHiddenData) {
      delete this.submitData['inputNum'];
      delete this.submitData['inputNum2'];
      delete this.submitData['powerRating'];
    }

    if (!this.formValid(this.submitData)) {
      return false;
    }

    this.submitData = _.assign(this.submitData, {
      companyId: this.submitData['companyId']['companyId'],
    });
    // 相似度校验
    this.modelService
      .getModelSimilarity(
        this.submitData['modelNo'],
        this.submitData['modelId']
      )
      .then((res: any) => {
        if (_.isEmpty(res)) {
          this.saveModel(this.submitData);
        } else {
          // 提示相似信息
          this.similarityContent = res;
          this.isShowSimilarityInfo = true;
        }
      });
  }

  public saveModel(model) {
    if (this.data) {
      model.className = null;
      this.modelService
        .editModel(model)
        .then((res) => {
          this.onHide();
        })
        .catch((error) => {
          this.isShowSimilarityInfo = false;
          this.common.errorMessage(error);
        });
    } else {
      this.modelService
        .addModel(model)
        .then((res) => {
          this.onHide();
        })
        .catch((error) => {
          this.isShowSimilarityInfo = false;
          this.common.errorMessage(error);
        });
    }
  }

  public cancelSave() {
    this.isShowSimilarityInfo = false;
  }

  public formValid(result) {
    if (!this.formService.isValid(this.editForm)) {
      this.tipservice.tip('warn', '请检查输入项');
      return false;
    }

    if (!this.classId) {
      this.tipservice.tip('warn', '请检查输入项');
      return false;
    }
    if (
      this.showHiddenData &&
      (result.powerRating === undefined || result.powerRating === null)
    ) {
      this.tipservice.tip('warn', '额定功率不能为空');
      return false;
    }
    if (
      this.showHiddenData &&
      (result.inputNum2 === undefined || result.inputNum2 === null)
    ) {
      this.tipservice.tip('warn', 'MPPT数量不能为空');
      return false;
    }
    if (
      this.showHiddenData &&
      (result.inputNum === undefined || result.inputNum === null)
    ) {
      this.tipservice.tip('warn', '组串数量不能为空');
      return false;
    }

    // 生产厂商
    if (!_.isObject(result.companyId) && !_.isEmpty(result.companyId)) {
      this.tipservice.tip('warn', '生产厂家不存在！');
      return false;
    }
    return true;
  }

  public onHide() {
    this.classId = null;
    this.isShow = false;
    this.isShowSimilarityInfo = false;
    this.showEvent.emit(false);
  }
}
