import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import * as moment from 'moment';

import { AuthHttp } from '../../../../core/auth-http';
import { AppConfigService } from '../../../../app.config';
import { CommonService } from '../../../../common/services/common.service';
import {
    AlarmAction,
    RealTimeParam,
    RealTimeSubmitParam,
    Option
} from '../../shared/index.model';
import { PieChartOption } from './real-time.chart';
import { AlarmManageService } from '../alarm-manage.service';
import { chartConfig } from '../../../monitor/shared/data-list/data-chart/single-station.chart';
import { COLORS } from '../../../../common/components/point-data-dialog/point-data-dialog.model';

@Injectable()
export class RealTimeService {
    public numList = [0, 0, 0, 0, 0];
    private chartBaseOption = PieChartOption;
    private apiUrl = this.appConfig.apiUrl;
    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private commonService: CommonService,
        private alarmManageService: AlarmManageService
    ) {}
    // 更新tab页的代码
    public setLevelList(levelMap) {
        this.numList = [0, 0, 0, 0, 0];
        if (levelMap) {
            levelMap.forEach((element) => {
                this.numList[0] += element.num;
                switch (element.id) {
                    case '01':
                        this.numList[1] = element.num;
                        break;
                    case '02':
                        this.numList[2] = element.num;
                        break;
                    case '03':
                        this.numList[3] = element.num;
                        break;
                    case '04':
                        this.numList[4] = element.num;
                        break;
                    default:
                        break;
                }
            });
        }
        this.alarmManageService.alarmLevelSubject.next(this.numList);
    }

    // 获取告警等级图表的配置
    public getOptionList(chartData = [], label) {
        let color = COLORS;
        let alarmTypeOptionList = [];
        let total = 0;
        let otherOption: Option = {
            chartOption: {},
            name: '其他告警' + label,
            num: 0,
            id: '0'
        };
        for (let i = 0, length = chartData.length; i < length; i++) {
            if (length <= 5 || i < 4) {
                let option: Option = {
                    chartOption: {},
                    name: '',
                    num: 0,
                    id: ''
                };
                option.chartOption = _.cloneDeep(this.chartBaseOption);
                total += chartData[i].num;
                option.chartOption.color = [color[i], '#ccc'];
                option.chartOption.series[0].data.push({
                    value: chartData[i].num,
                    name: chartData[i].name
                });
                option.name = chartData[i].name + label;
                option.num = chartData[i].num;
                option.id = chartData[i].id;
                alarmTypeOptionList.push(option);
            } else {
                if (i === 4) {
                    otherOption.chartOption = _.cloneDeep(this.chartBaseOption);
                    otherOption.chartOption.color = [color[5], '#ccc'];
                    otherOption.chartOption.series[0].data.push({
                        value: chartData[i].num,
                        name: '其他告警'
                    });
                } else {
                    otherOption.chartOption.series[0].data[0].value +=
                        chartData[i].num;
                }
                total += chartData[i].num;
                otherOption.num += chartData[i].num;
                if (i === length - 1) {
                    alarmTypeOptionList.push(otherOption);
                }
            }
        }
        for (let alarmTypeOption of alarmTypeOptionList) {
            alarmTypeOption.chartOption.series[0].data.push(
                total - alarmTypeOption.chartOption.series[0].data[0].value
            );
        }
        return alarmTypeOptionList;
    }

    // 更新告警等级图表的配置
    public updateAlarmTypeOptionList(chartData, newAlarm) {
        if (newAlarm.action === '0') {
            this.numList[0]++;
            switch (newAlarm.alarmLog.alarmLevel) {
                case '01':
                    this.numList[1]++;
                    break;
                case '02':
                    this.numList[2]++;
                    break;
                case '03':
                    this.numList[3]++;
                    break;
                case '04':
                    this.numList[4]++;
                    break;
                default:
                    break;
            }
            let data: any = _.find(chartData, {
                id: newAlarm.alarmLog.alarmType
            });
            if (data) {
                data.num++;
            }
            this.alarmManageService.alarmLevelSubject.next(this.numList);
        }
        if (newAlarm.action === '2') {
            this.numList[0]--;
            switch (newAlarm.alarmLog.alarmLevel) {
                case '01':
                    this.numList[1]--;
                    break;
                case '02':
                    this.numList[2]--;
                    break;
                case '03':
                    this.numList[3]--;
                    break;
                case '04':
                    this.numList[4]--;
                    break;
                default:
                    break;
            }
            let data: any = _.find(chartData, {
                id: newAlarm.alarmLog.alarmType
            });
            if (data) {
                data.num--;
            }
            this.alarmManageService.alarmLevelSubject.next(this.numList);
        }
        return chartData;
        // return this.getOptionList(chartData, '数');
    }

    // 将接口返回的表格数据格式化成用户展示的数据
    public formatRealTimeData(data: any[] = []) {
        let newLogList: any[] = [];
        for (let log of data) {
            let newLog = this.formatData(log);
            if (log.preLogIds && log.preLogIds.length > 0) {
                let newLogPreLogList = [];
                for (let preLog of log.preLogIds) {
                    newLogPreLogList.push(this.formatData(preLog));
                }
                newLog.preLogIds = newLogPreLogList;
            }
            newLogList.push(newLog);
        }
        return newLogList;
    }

    // 数据处理
    public formatData(log) {
        const timeFormat = 'YYYY-MM-DD HH:mm:ss';
        let newLog: any = log;
        // 拼接告警对象
        if (log.alarmObjName) {
            newLog.alarmObj = log.alarmObjName;
        } else {
            if (log.stationName && (log.deviceName || log.compName)) {
                newLog.alarmObj = _.compact([
                    log.stationName,
                    log.deviceName,
                    log.compName
                ]).join('>');
            } else {
                newLog.alarmObj = _.compact([
                    log.stationName,
                    log.areaName,
                    log.deviceName,
                    log.compName
                ]).join('>');
            }
        }

        // 告警动作
        newLog.alarmActionName = log.alarmActionText;
        // 告警内容
        newLog.alarmContent = log.alarmText;
        newLog.alarmPic = log.alarmPic; //告警图片
        // 告警类别
        newLog.alarmType = log.alarmTypeText;
        // 告警级别
        newLog.alarmLevel = +log.alarmLevel;
        newLog.alarmLevelName = log.alarmLevelText;
        // 告警时间
        newLog.startTime = log.startTime
            ? moment(new Date(+log.startTime)).format(timeFormat)
            : '';
        // 复归时间
        newLog.endTime = log.endTime
            ? moment(new Date(+log.endTime)).format(timeFormat)
            : '';
        // 操作
        if (log.alarmAction === '1') {
            if (log.alarmCheckStatus === '01') {
                newLog.option = 1;
            } else if (log.alarmCheckStatus === '02') {
                newLog.option = 2;
            } else if (log.alarmCheckStatus === '03') {
                newLog.option = 3;
            } else {
                Error('alarmCheckStatus状态码出错');
            }
        } else {
            if (log.recoverCheckStatus === '01') {
                newLog.option = 4;
            } else if (log.recoverCheckStatus === '02') {
                newLog.option = 5;
            }
        }

        return newLog;
    }
    // 改变确认状态时，遍历更新本地数据
    public updateStatus(logIds: number[], alarmList: any[], memo?: any) {
        for (let item of alarmList) {
            if (logIds.indexOf(item.logId) !== -1) {
                if (item.alarmAction === 0) {
                    item.recoverCheckStatus = '02';
                    item.recoverCheckMemo = memo;
                } else {
                    item.alarmCheckStatus = '02';
                    item.alarmCheckMemo = memo;
                }
            }
            if (item.preLogIds) {
                for (let preItem of item.preLogIds) {
                    if (logIds.indexOf(preItem.logId) !== -1) {
                        if (preItem.alarmAction === 0) {
                            preItem.recoverCheckStatus = '02';
                            preItem.recoverCheckMemo = memo;
                        } else {
                            preItem.alarmCheckStatus = '02';
                            preItem.alarmCheckMemo = memo;
                        }
                    }
                }
            }
        }
        return alarmList;
    }

    //改变状态(缺陷单)
    public updateDefectStatus(
        logId: string,
        alarmList: any[],
        defectId: number
    ) {
        for (let item of alarmList) {
            if (logId == item.logId) {
                if (item.alarmAction === 0) {
                    item.recoverCheckStatus = '03';
                } else {
                    item.alarmCheckStatus = '03';
                }
                item.defectId = defectId;
            }
            if (item.preLogIds) {
                for (let preItem of item.preLogIds) {
                    if (logId == preItem.logId) {
                        if (preItem.alarmAction === 0) {
                            preItem.recoverCheckStatus = '03';
                        } else {
                            preItem.alarmCheckStatus = '03';
                        }
                        preItem.defectId = defectId;
                    }
                }
            }
        }
        return alarmList;
    }

    // 订阅数据
    public subscribeData(stationId: string[]) {
        return this.commonService
            .subscribeData(stationId, 'stationAlarm')
            .map((data) => JSON.parse(data));
    }

    // 删除，列表操作
    public deleteAlarm(newAlarmObj, alarmList) {
        let newAlarmList: any = _.reject(alarmList, {
            logId: newAlarmObj.logId
        });
        for (let i = 0, length = newAlarmList.length; i < length; i++) {
            if (newAlarmList[i].preLogIds) {
                newAlarmList[i].preLogIds = _.reject(
                    newAlarmList[i].preLogIds,
                    { logId: newAlarmObj.logId }
                );
                if (newAlarmList[i].preLogIds.length === 0) {
                    newAlarmList[i].preLogIds = null;
                }
            }
        }
        return newAlarmList;
    }

    // 增加，列表操作
    public addAlarm(newAlarmObj, alarmList) {
        let newAlarm = this.formatData(newAlarmObj);
        let newAlarmPre = [];
        this.getPreAlarm({
            objId: newAlarm.deviceId,
            objType: '02',
            logId: newAlarm.logId
        }).subscribe((res: any) => {
            for (let item of res) {
                newAlarmPre.push(this.formatData(item));
            }
        });
        newAlarm.preLogIds = newAlarmPre;
        this.scrollTo(0);
        return newAlarm;
    }

    // 修改，列表操作
    public updateAlarm(newAlarmObj, alarmList) {
        newAlarmObj = this.formatData(newAlarmObj);
        let index = _.findIndex(alarmList, { logId: newAlarmObj.logId });
        if (index >= 0) {
            alarmList[index] = _.defaultsDeep(newAlarmObj, alarmList[index]);
        }
        for (let i = 0, length = alarmList.length; i < length; i++) {
            if (alarmList[i].preLogIds) {
                let indexPre = _.findIndex(alarmList[i].preLogIds, {
                    logId: newAlarmObj.logId
                });
                alarmList[i].preLogIds[indexPre] = _.defaultsDeep(
                    newAlarmObj,
                    alarmList[i].preLogIds[indexPre]
                );
            }
        }
        // this.scrollTo(index);
        return alarmList;
    }

    public updateAlarmList(alarm, alarmList) {
        let newAlarm: any = _.omit(
            this.formatData(alarm.alarmLog),
            'preLogIds'
        );
        if (alarm.action === AlarmAction.add) {
            this.scrollTo(0);
            return [newAlarm].concat(alarmList);
        }
        if (alarm.action === AlarmAction.update) {
            let index = _.findIndex(alarmList, { logId: newAlarm.logId });
            if (index >= 0) {
                alarmList[index] = _.defaultsDeep(newAlarm, alarmList[index]);
                this.scrollTo(index);
            }
            return alarmList.concat();
        }
        if (alarm.action === AlarmAction.delete) {
            alarmList = _.reject(alarmList, { logId: newAlarm.logId });
            return alarmList.concat();
        }
        return alarmList;
    }

    public updatepreAlarmMap(alarm, preAlarmMap) {
        return Observable.create((observer) => {
            let newAlarm = this.formatData(alarm.alarmLog);
            if (alarm.action === AlarmAction.add) {
                let newAlarmPre = [];
                this.getPreAlarm({
                    objId: newAlarm.deviceId,
                    objType: '02',
                    logId: newAlarm.logId
                }).subscribe((res: any) => {
                    for (let item of res) {
                        newAlarmPre.push(this.formatData(item));
                    }
                    if (newAlarmPre.length > 0) {
                        preAlarmMap[newAlarm.logId] = newAlarmPre;
                    }
                    observer.next(_.defaultsDeep({}, preAlarmMap));
                    observer.complete();
                });
            }
            if (alarm.action === AlarmAction.update) {
                let index;
                _.forOwn(preAlarmMap, (alarms, logId) => {
                    index = _.findIndex(alarms, { logId: newAlarm.logId });
                    alarms[index] = _.defaultsDeep(newAlarm, alarms[index]);
                    preAlarmMap[logId] = alarms;
                });
                observer.next(_.defaultsDeep({}, preAlarmMap));
                observer.complete();
            }
            if (alarm.action === AlarmAction.delete) {
                if (preAlarmMap[alarm.logId]) {
                    preAlarmMap = _.omit(preAlarmMap, alarm.logId);
                }
                _.forOwn(preAlarmMap, (alarms, logId) => {
                    alarms = _.reject(alarms, { logId: newAlarm.logId });
                    if (alarms.length === 0) {
                        preAlarmMap[logId] = null;
                    } else {
                        preAlarmMap[logId] = alarms;
                    }
                });
                observer.next(_.defaultsDeep({}, preAlarmMap));
                observer.complete();
            }
        });
    }

    // 表格的滚动处理
    public scrollTo(index) {
        let scorllDom = <HTMLElement>(
            document.getElementsByClassName('ui-datatable-scrollable-body')[0]
        );
        if (scorllDom) {
            let trs: any = scorllDom
                .querySelector('.ui-datatable-scrollable-table-wrapper')
                .querySelector('table')
                .querySelector('tbody').children;
            let newTrs = [];
            for (let tr of trs) {
                if (tr.className.indexOf('ui-widget-content') !== -1) {
                    newTrs.push(tr);
                }
            }
            if (index === 0) {
                scorllDom.scrollTop = 0;
            } else {
                scorllDom.scrollTop = newTrs[index].offsetTop;
            }
        }
    }

    // 获取实时告警页面数据数据
    public getRealTimeData(param: RealTimeParam) {
        return this.http.post(`${this.apiUrl}/api/alarmLog/listLogs`, param);
    }

    // 确认提交接口
    public submit(param: RealTimeSubmitParam) {
        return this.http.post(
            `${this.apiUrl}/api/alarmLog/changeStatus`,
            param
        );
    }

    public getMemoList(param: number[]) {
        return this.http.get(
            `${this.apiUrl}/api/alarmController/getComfirmStationData`,
            { search: { ruleIds: param.join(',') } }
        );
    }

    public getPreAlarm(param) {
        return this.http.get(`${this.apiUrl}/api/alarmLog/getPreAlarmLogs`, {
            search: {
                objType: param.objType,
                objId: param.objId,
                logId: param.logId
            }
        });
    }

    public exportExcel(param: any) {
        this.commonService.fileExport(
            `${this.apiUrl}/api/alarmLog/exportRealAlarms`,
            param
        );
    }

    public realAlarmStatistics(param) {
        return this.http.get(
            `${this.apiUrl}/api/alarmLog/realAlarmStatistics`,
            {
                search: this.commonService.filterEffectParam(param)
            }
        );
    }

    public getAlarmStatistics(param) {
        return this.http.get(`${this.apiUrl}/api/alarmLog/getAlarmStatistics`, {
            search: this.commonService.filterEffectParam(param)
        });
    }

    public stationRealAlarms(param) {
        return this.http.get(`${this.apiUrl}/api/alarmLog/stationRealAlarms`, {
            search: this.commonService.filterEffectParam(param)
        });
    }

    public getAlarmLogs(param) {
        return this.http.get(`${this.apiUrl}/api/alarmLog/getAlarmLogs`, {
            search: this.commonService.filterEffectParam(param)
        });
    }
    public getDeviceInfo(param) {
        return this.http.get(`${this.apiUrl}/api/device/deviceInfo`, {
            search: this.commonService.filterEffectParam(param)
        });
    }

    public exportRealAlarms(param) {
        this.commonService.fileExport(
            `${this.apiUrl}/api/alarmLog/exportRealAlarms`,
            this.commonService.filterEffectParam(param)
        );
    }
    public exportAlarms(param) {
        this.commonService.fileExport(
            `${this.apiUrl}/api/alarmLog/exportAlarms`,
            this.commonService.filterEffectParam(param)
        );
    }

    public alarmLogDetail(param) {
        return this.http.post(
            `${this.apiUrl}/api/alarmLog/alarmLogDetail`,
            param
        );
    }

    public alarmCheck(param) {
        return this.http
            .post(`${this.apiUrl}/api/alarmLog/alarmCheck`, param)
            .toPromise();
    }
}
