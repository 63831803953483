import { Injectable } from "@angular/core";
import { TreeviewItem } from "../treeview";
import * as _ from "lodash";
import { AuthHttp } from "src/app/core/auth-http";
import { AppConfigService } from "src/app/app.config";

@Injectable({
    providedIn: "root"
})
export class CompanyTreeSelectorService {
    constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

    public getPropertyComps() {
        return this.http.get(
            `${this.appConfig.apiUrl}/api/company/propertyComps`
        );
    }

    public filterCompanyData(name, value, data): TreeviewItem[] {
        let resultData: TreeviewItem[] = [];
        _.forEach(data, (val, key) => {
            if (key === "data") {
                resultData.push(
                    new TreeviewItem({
                        collapsed: val.expanded,
                        checked: false,
                        text: val[name],
                        value: val[value]
                    })
                );
            }
            if (key === "children" && !_.isEmpty(val)) {
                resultData[0]["children"] = this.filterTreeData(
                    name,
                    value,
                    val
                );
            }
        });

        return resultData;
    }

    public filterTreeData(name, value, data): TreeviewItem[] {
        let resultData: TreeviewItem[] = [];
        for (let i = 0; i < data.length; i++) {
            resultData.push(
                new TreeviewItem({
                    collapsed: data[i].data.expanded,
                    checked: false,
                    text: data[i].data[name],
                    value: data[i].data[value]
                })
            );
            if (!_.isEmpty(data[i].children)) {
                resultData[i]["children"] = this.filterTreeData(
                    name,
                    value,
                    data[i].children
                );
            }
            resultData[i].correctChecked();
        }
        return resultData;
    }
}
