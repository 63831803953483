import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ModelInfoComponent } from './model-info/model-info.component';

const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: 'modelInfo',
  //   pathMatch: 'full'
  // },
  {
    path: 'modelInfo',
    component: ModelInfoComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ModelRoute { }
