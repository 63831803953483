import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TreeTableModule, DialogModule, ButtonModule } from 'primeng/primeng';
import { TranslateModule } from '@ngx-translate/core';
import { DeptsService } from '../depts/shared/depts.service';
import { DeptsPickerComponent } from './depts-picker.component';

@NgModule({
    imports: [TreeTableModule, DialogModule, ButtonModule, TranslateModule, CommonModule],
    declarations: [DeptsPickerComponent],
    exports: [DeptsPickerComponent],
    providers: [DeptsService],
})
export class DeptsPickerModule {}
