import * as _ from "lodash";

export const electricityDayOption = {
    color: [
        "#2196F3",
        "#FFC107",
        "#45A349",
        "#FF5722",
        "#9C27B0",
        "#7FBCEC",
        "#21C7E2",
        "#84A9C1",
        "#409E8F"
    ],
    backgroundColor: "rgba(255, 255, 255, 0)",
    tooltip: {
        trigger: "axis"
    },
    legend: {
        data: ["日发电量"],
        x: "center",
        y: "top"
    },
    xAxis: {
        type: "category",
        name: "时间",
        boundaryGap: true,
        data: []
    },
    yAxis: [],
    dataZoom: [
        {
            type: "inside",
            start: 0,
            end: 100
        }
    ],
    series: []
};

export function buildElectricityDayChart(data) {
    let option: any = _.cloneDeep(electricityDayOption);
    if (data) {
        option.legend.data = data.legend.data;
        option.xAxis.data = data.xAxis.data;
        option.yAxis = data.yAxis;
        option.series = data.series;
        if (option.yAxis && option.yAxis[1]) {
            option.yAxis[1].splitLine = {
                show: false
            };
        }
    }
    return option;
}
