import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { buildEnergyDayChart } from '../map/charts/energyDay.chart';
import { CommonService } from './../../../common/services/common.service';
import { EmsService } from './../ems.service';

@Component({
  selector: 'app-cluster',
  templateUrl: './cluster.component.html',
  styleUrls: ['./cluster.component.less'],
})
export class ClusterComponent implements OnInit, AfterViewInit, OnDestroy {
  mapEnergiesDayOptions: any;
  deviceId: any;
  stationId: any;
  deviceInfo: any;
  backItem: any;
  alarmInfo: any = [];
  packsInfo: any;
  removeBackItem = true;

  constructor(
    private es: EmsService,
    private activatedroute: ActivatedRoute,
    private cs: CommonService,
    private router: Router
  ) {
    this.activatedroute.queryParams.subscribe((res: any) => {
      this.stationId = res.stationId;
      this.deviceId = res.deviceId;
    });
  }

  ngOnInit() {
    this.backItem = this.cs.getBackItem();
    console.log(this.backItem);
  }

  ngAfterViewInit(): void {
    this.getClusterMonitordatas(this.deviceId);
    this.getEmsMapEnergiesDay(this.deviceId);
    this.getClusterMonitorAlarms(this.deviceId);
  }

  ngOnDestroy(): void {
    if (this.removeBackItem) {
      this.cs.removeBackItem();
    }
  }

  async getClusterMonitordatas(deviceId) {
    const data: any = await this.es.getClusterMonitordatas(deviceId);
    this.deviceInfo = {
      ...data,
      soc: data.soc && data.soc.replace(/\s+/g, ''),
      soh: data.soh && data.soh.replace(/\s+/g, ''),
    };
  }
  async getClusterMonitorAlarms(deviceId) {
    const data: any = await this.es.getClusterMonitorAlarms(deviceId);
    this.alarmInfo = data;
    setTimeout(() => {
      document.getElementById('right').style.height =
        document.getElementById('left').clientHeight + 'px';
      this.getClusterMonitorPacks(this.deviceId);
    }, 1);
  }
  async getClusterMonitorPacks(deviceId) {
    const data: any = await this.es.getClusterMonitorPacks(deviceId);
    this.packsInfo = data.map((item) => {
      return {
        ...item,
        units: item.units.map((unit) => {
          return {
            ...unit,
            soc: unit.soc && unit.soc.replace(/\s+/g, ''),
          };
        }),
      };
    });
  }

  async getEmsMapEnergiesDay(deviceId) {
    const returnData = await this.es.getClusterMonitorEnergies(deviceId);
    this.mapEnergiesDayOptions = buildEnergyDayChart(returnData);
  }

  goBack() {
    this.removeBackItem = false;
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        link: ['/ems/map'],
      })
    );
    this.router.navigate(['/ems/monitor/station'], {
      queryParams: { stationId: this.stationId },
    });
  }
}
