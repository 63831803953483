import { OnChanges, SimpleChanges } from '@angular/core';
import { WidgetsService } from './../../widgets.service';
import { Router } from '@angular/router';

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-station-online-rate',
  templateUrl: './online-rate.component.html',
  styleUrls: ['./online-rate.component.less']
})
export class StationOnlineRateComponent implements OnInit, OnChanges {

  @Input() stationId: string;

  public loading = true;
  public option = null;

  constructor(private widgetsservice: WidgetsService, private router: Router) { }

  ngOnInit() {
    // this.getRunStatus();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.getRunStatus();
    }
  }

  public getRunStatus() {
    this.loading = true;
    this.widgetsservice.stationRunStatus({ stationId: this.stationId }).then((res: any) => {
      this.loading = false;
      if (res) {
        this.buildDeviceOnlineRateChart(res)
      }
    });
  }

  public clickEvent(event) {
    let seriesIndex = event.seriesIndex;
    let params = {};
    switch (seriesIndex) {
      case 0:
        params = ({ dataType: '01', deviceType: '02', sortBy: 'u01_rate', sortOrder: 'asc' })
        break;
      case 1:
        params = { dataType: '01', deviceType: '02', sortBy: 'u02_rate', sortOrder: 'asc' }
        break;
      case 2:
        params = {
          dataType: '01',
          deviceType: '01',
          sortBy: 'dBYQ_rate',
          sortOrder: 'asc'
        }
        break;
      case 3:
        params = { dataType: '01', deviceType: '01', sortBy: 'dNB_rate', sortOrder: 'asc' }
        break;

      default:
        break;
    }
    this.goDeviceStatus(params)
  }


  /**
 * goDeviceStatus
 */
  public goDeviceStatus(queryParams) {
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/yxjs'],
      })
    );
    this.router.navigate(['/monitorCenter/deviceStatus'], {
      queryParams,
    });
  }


  public buildDeviceOnlineRateChart(data) {
    const { options, cardTitle } = data;
    this.option = {
      legend: {
        show: false
      },
      tooltip: {},
      series: [
        {
          type: 'pie',
          radius: ['32%', '37%'],
          center: ['25%', '30%'],
          name: cardTitle[0],
          labelLine: {
            show: false
          },
          data: [
            { value: options[0].series[0].data[0].value, name: '在线数', itemStyle: { color: '#0c559f' } },
            { value: options[0].series[0].data[1].value, name: '离线数', itemStyle: { color: '#e4e4e4' } }
          ],
          label: {
            normal: {
              show: true,
              position: 'center',
              formatter: '{total|' + options[0].title.text + '}' + '\n' + '{active|' + cardTitle[0] + '}',
              rich: {
                total: {
                  fontSize: 18,
                  color: '#0c559f'
                },
                active: {
                  fontSize: 11,
                  color: '#8EAFB6',
                  fontWeight: 500
                }
              }
            }
          }
        },
        {
          type: 'pie',
          radius: ['32%', '37%'],
          center: ['75%', '30%'],
          name: cardTitle[1],
          labelLine: {
            show: false
          },
          label: {
            normal: {
              show: true,
              position: 'center',
              formatter: '{total|' + options[1].title.text + '}' + '\n' + '{active|' + cardTitle[1] + '}',
              rich: {
                total: {
                  fontSize: 18,
                  color: '#22a09d'
                },
                active: {
                  fontSize: 11,
                  color: '#8EAFB6',
                  fontWeight: 500
                }
              }
            }
          },
          data: [
            { value: options[1].series[0].data[0].value, name: '在线数', itemStyle: { color: '#22a09d' } },
            { value: options[1].series[0].data[1].value, name: '离线数', itemStyle: { color: '#e4e4e4' } }
          ]
        },
        {
          type: 'pie',
          radius: ['32%', '37%'],
          center: ['25%', '75%'],
          name: cardTitle[2],
          labelLine: {
            show: false
          },
          label: {
            normal: {
              show: true,
              position: 'center',
              formatter: '{total|' + options[2].title.text + '}' + '\n' + '{active|' + cardTitle[2] + '}',
              rich: {
                total: {
                  fontSize: 18,
                  color: '#aedaff'
                },
                active: {
                  fontSize: 11,
                  color: '#8EAFB6',
                  fontWeight: 500
                }
              }
            }
          },
          data: [
            { value: options[2].series[0].data[0].value, name: '在线数', itemStyle: { color: '#aedaff' } },
            { value: options[2].series[0].data[1].value, name: '离线数', itemStyle: { color: '#e4e4e4' } }
          ]
        },
        {
          type: 'pie',
          radius: ['32%', '37%'],
          center: ['75%', '75%'],
          name: cardTitle[3],
          labelLine: {
            show: false
          },
          label: {
            normal: {
              show: true,
              position: 'center',
              formatter: '{total|' + options[3].title.text + '}' + '\n' + '{active|' + cardTitle[3] + '}',
              rich: {
                total: {
                  fontSize: 18,
                  color: '#d09e48'
                },
                active: {
                  fontSize: 11,
                  color: '#8EAFB6',
                  fontWeight: 500
                }
              }
            }
          },
          data: [
            { value: options[3].series[0].data[0].value, name: '在线数', itemStyle: { color: '#d09e48' } },
            { value: options[3].series[0].data[1].value, name: '离线数', itemStyle: { color: '#e4e4e4' } }
          ]
        }
      ]
    }
  }
}
