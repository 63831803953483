import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ClassesCategoryComponent } from './classes-category.component';
import { TreeviewModule } from '../treeview';
import { ClassesService } from './classes-category.service';

@NgModule({
  imports: [
    CommonModule,
    TreeviewModule.forRoot()
  ],
  exports: [
    ClassesCategoryComponent
  ],
  declarations: [
    ClassesCategoryComponent
  ],
  providers: [
    ClassesService
  ]
})
export class ClassesCategoryModule {}
