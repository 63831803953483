import { Injectable } from '@angular/core';
import { AuthHttp } from '@core/auth-http';
import { Subject } from 'rxjs';
import { AppConfigService } from 'src/app/app.config';

@Injectable({
  providedIn: 'root',
})
export class EarthMapBgService {
  private stationAreaSource = new Subject<any>();
  stationAreaHandler = this.stationAreaSource.asObservable();

  apiUrl = '';
  stations = '/api/v2/monitor/center/stations';
  stationsData = '/api/v2/monitor/center/stations/data';
  stationsDataDashboard = '/api/dashboard/jszy/map/stations/data';
  stationsAreas = '/api/v2/monitor/station/areas';
  devices = '/api/v2/monitor/station/map/devices';
  inverter = '/api/v2/monitor/station/inverter';

  constructor(private http: AuthHttp, private appconfig: AppConfigService) {
    this.apiUrl = this.appconfig.apiUrl;
  }

  getStations(): any {
    return this.http.get(`${this.apiUrl}${this.stations}`).toPromise();
  }

  getStationDatas(params: any): any {
    return this.http
      .get(`${this.apiUrl}${this.stationsData}`, { params })
      .toPromise();
  }
  getStationDataDashboard(params: any): any {
    return this.http
      .get(`${this.apiUrl}${this.stationsDataDashboard}`, { params })
      .toPromise();
  }

  getStationAreas(params: any): any {
    return this.http
      .get(`${this.apiUrl}${this.stationsAreas}`, { params })
      .toPromise();
  }

  getDevices(params: any): any {
    return this.http
      .get(`${this.apiUrl}${this.devices}`, { params })
      .toPromise();
  }
  getInverter(params: any): any {
    return this.http
      .get(`${this.apiUrl}${this.inverter}`, { params })
      .toPromise();
  }

  showStationAreaList(runStatus) {
    this.stationAreaSource.next(runStatus);
  }
}
