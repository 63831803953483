export interface UserConfig {
  id: number;
  imgUrl: string;
  title: string;
  amount: number;
  unit: string;
  type: number;
  cateIds: string[];
  xAxis: any;
  progressBarName?: string;
  progressBarNum?: number;
  changeName?: string;
  changeNum?: number;
  changeUnit?: string;
  degree?: number;
}
export interface UserConfigDegree {
  degree: number;
  range: string;
  max: number;
  min: number;
}
export interface DataUnit {
  data: number;
  unit: string;
}
export interface ChartConfig {
  options: any;
  label: string;
}
export interface LonLat {
  minLon: number;
  minLat: number;
  maxLon: number;
  maxLat: number;
  cenLon: number;
  cenLat: number;
}
export interface Regions {
  minValue: number;
  maxValue: number;
  regions: {};
}
export interface XAxis {
  name: string;
  title: string;
  type: string;
  before: number;
  interval: number;
  xAxis: any;
}

export enum MapType {
  county = 'county',
  province = 'province',
  city = 'city'
}
