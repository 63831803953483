import { NgModule } from '@angular/core';
import {
  AutoCompleteModule,
  DropdownModule,
  MultiSelectModule,
  ButtonModule,
  InputTextModule,
  PaginatorModule,
  DialogModule,
  SpinnerModule
} from 'primeng/primeng';

import { SharedModule } from '../../../../shared/shared.module';
import { ModelInfoComponent } from './model-info/model-info.component';
import { ModelEditComponent } from './model-edit/model-edit.component';
import { ModelService } from './model.service';
import { ModelRoute } from './model.route';
import { ClassesCategoryModule } from '../../../../common/components/classes-category/classes-category.module';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    SharedModule,
    AutoCompleteModule,
    DropdownModule,
    MultiSelectModule,
    ButtonModule,
    TableModule,
    InputTextModule,
    PaginatorModule,
    DialogModule,
    ModelRoute,
    SpinnerModule,
    ClassesCategoryModule,
  ],
  exports: [ModelInfoComponent],
  declarations: [ModelInfoComponent, ModelEditComponent],
  providers: [ModelService],
})
export class ModelModule {}
