import * as _ from 'lodash';
import * as echarts from 'echarts';
export const equivalentYearOption = {
  color: [
    '#53FF95'
  ],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: [],
    x: "center",
    y: "bottom"
  },
  xAxis: {
    type: 'category',
    name: '时间',
    boundaryGap: true,
    data: []
  },
  yAxis: {
    type: 'value',
    name: 'h'
  },
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100
    }
  ],
  series: []
};

export function buildEquivalentYearChart(data) {
  let option: any = _.cloneDeep(equivalentYearOption);
  if (data) {
    option.legend.data = data.legend.data;
    option.xAxis.data = data.xAxis.data;
    option.yAxis.name = data.yAxis.name;
    option.series = [{
      ...data.series[0], type: 'line', areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(34,155,93,0.69)'
          },
          {
            offset: 1,
            color: 'rgba(0,101,94,0.00)'
          }
        ])
      }
    }];
  }
  return option;
}
