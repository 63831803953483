export function buildEquivalentHourChart(data: any) {
    // 指定图表的配置项和数据
    /*
select concat('{name: ''', s.station_name, ''', value: [', s.longitude, ', ', s.latitude, ', ', round(r.daily_energy/s.parallel_capacity/1000, 2), ']},'), r.daily_energy
from job_record r 
join station s on r.station_id = s.station_id
where r.report_date = '2019-08-14'
order by daily_energy desc;
*/
    data = [
        {
            name: "内蒙乾华135MW地面集中式光伏电站",
            value: [111.284262, 40.344304, 6.21]
        },
        { name: "商丘当店", value: [115.738182, 34.551813, 5.28] },
        { name: "山东冠县宋村华博", value: [115.449025, 36.489694, 5.09] },
        { name: "安徽阜阳颍上耿棚", value: [116.121541, 32.647993, 4.75] },
        { name: "淮北刘桥永瑞", value: [113.262503, 35.190781, 5.02] },
        {
            name: "泗洪梅花镇40MW农光互补光伏“领跑者”项目",
            value: [118.197523, 33.453856, 4.06]
        },
        { name: "泗洪天岗湖", value: [116.785989, 33.944984, 4.12] },
        {
            name: "安徽灵璧40.10292MWp地面分布式光伏电站",
            value: [117.534294, 33.82207, 3.77]
        },
        { name: "安徽阜阳新盛电站", value: [116.948536, 34.164347, 4.85] },
        { name: "阜阳永强新乌江二期", value: [115.851753, 32.891605, 5.55] },
        {
            name: "宿州经开区河拐村20MW农光互补分布式光伏发电项目库1.",
            value: [116.974604, 33.601044, 5.07]
        },
        {
            name: "萧县大屯镇20MW农光互补分布式光伏发电项目",
            value: [116.972251, 34.169984, 5.27]
        },
        { name: "河南厢华", value: [118.159106, 39.61673, 5.53] },
        { name: "阜阳永强李桥", value: [115.823026, 32.890527, 5.48] },
        { name: "安徽阜阳新乌江一期", value: [116.166687, 32.869704, 5.34] },
        {
            name: "安徽萧县华耀20MW农光互补光伏电站",
            value: [116.771111, 34.135592, 5.06]
        },
        { name: "东营力诺", value: [118.312595, 37.906691, 5.33] },
        { name: "城发华耀", value: [116.313582, 37.518698, 5.02] },
        {
            name: "宿州经开区44.02684MW农光互补分布式光伏发电项目",
            value: [116.977577, 33.60714, 4.97]
        },
        { name: "安徽亳州华阳", value: [115.913536, 33.68733, 4.86] },
        { name: "安徽阜阳行流", value: [115.723941, 33.049921, 5.05] },
        { name: "安徽阜阳伍明", value: [115.878888, 33.033457, 5.04] },
        { name: "安徽临泉", value: [115.264704, 33.070225, 4.49] },
        { name: "界首金农", value: [115.386274, 33.277882, 4.4] },
        {
            name: "安徽萧县20MWp地面分布式光伏电站",
            value: [117.086415, 34.143958, 4.56]
        },
        {
            name: "镇江金东20MW屋顶分布式光伏电站",
            value: [119.697593, 32.195312, 4.5]
        },
        {
            name: "泗洪孙园镇20MW渔光互补分布式光伏发电项目",
            value: [118.222029, 33.462109, 4.2]
        },
        { name: "启东合作", value: [121.637028, 31.926345, 4.14] },
        { name: "灌南飞展三期", value: [115.878888, 33.033457, 3.24] },
        {
            name: "启东近海镇18MW农光互补分布式光伏发电项目",
            value: [121.802013, 31.911794, 3.77]
        },
        {
            name: "启东滨海园区鱼塘15MW光伏电站",
            value: [121.870979, 31.898399, 3.92]
        },
        { name: "城发华耀二期", value: [116.297, 37.509, 5.4] },
        { name: "后寨永创一期", value: [116.582684, 37.34141, 4.78] },
        { name: "夏津力诺二期", value: [116.1511, 37.054587, 4.95] },
        { name: "后寨永创二期", value: [116.582684, 37.34141, 4.73] },
        { name: "灌南飞展二期", value: [115.878888, 33.033457, 3.02] },
        { name: "夏津力诺一期", value: [116.059935, 37.026594, 4.8] },
        { name: "金东二期", value: [119.672114, 32.194895, 4.83] },
        { name: "惠民永正", value: [117.596994, 37.497634, 4.7] },
        { name: "连云港朝阳电站", value: [119.307159, 34.683782, 2.05] },
        { name: "连云港伊山", value: [119.291198, 34.590419, 2.66] },
        {
            name: "灵璧明升10MW农光互补光伏电站",
            value: [117.497055, 33.675952, 3.69]
        },
        {
            name: "山东东营6kv津禾光伏电站",
            value: [118.439848423, 37.5576934579, 4.42]
        },
        { name: "灌南飞展一期", value: [114.084712, 34.308878, 3.04] },
        { name: "邳州大诚", value: [117.50463, 34.405543, 3.09] },
        { name: "海门灵树", value: [121.4474, 32.069315, 6.35] },
        { name: "合肥金诺", value: [117.269661, 31.837868, 4.8] },
        {
            name: "阜阳金种子6.16896MW光伏电站",
            value: [115.849146, 32.889156, 5.06]
        },
        { name: "扬中阜润", value: [119.802256, 32.242, 4.63] },
        { name: "合肥宝业", value: [117.240116, 31.808623, 5.13] },
        { name: "兴化钓鱼", value: [119.853781, 32.963153, 4.53] },
        {
            name: "山东淄博1.4MW光伏电站",
            value: [117.8231970119, 37.1824021543, 4.26]
        },
        { name: "启东宏华", value: [121.688697, 31.792931, 3.85] },
        { name: "盐城利能", value: [120.240052, 33.440862, 3.43] },
        {
            name: "启东胜狮6MW屋顶分布式光伏电站",
            value: [121.702208, 31.792931, 3.53]
        },
        {
            name: "扬州奔多4.979MW屋顶分布式光伏电站",
            value: [119.504115, 32.367086, 4.2]
        },
        {
            name: "合肥应流4.32MW屋顶分布式光伏电站",
            value: [117.174211, 31.788516, 4.69]
        },
        { name: "合肥徽商大市场", value: [114.94965, 35.830754, 4.66] },
        {
            name: "合肥绿晖3.81MW光伏电站",
            value: [117.231066, 31.819371, 4.94]
        },
        {
            name: "启东南阳4.8MW农光互补光伏电站",
            value: [121.723259, 31.881728, 4.0]
        },
        { name: "合肥恒通玻璃", value: [113.964015, 34.024943, 4.72] },
        { name: "张庄华博", value: [115.46837, 36.483281, 4.35] },
        { name: "连云港伊山二期", value: [119.245959, 34.3015, 2.59] },
        { name: "南通永兴永林", value: [120.918807, 31.961345, 4.71] },
        { name: "营村华博", value: [114.218226, 37.110309, 4.49] },
        { name: "岳胡华博", value: [114.706568, 37.938171, 4.45] },
        { name: "微山永旭", value: [120.394647, 36.085204, 3.99] },
        {
            name: "阜阳金坤达3.159MW光伏电站",
            value: [115.809947, 32.902675, 4.73]
        },
        { name: "南通永兴华陆", value: [120.367551, 33.404614, 4.67] },
        { name: "创能一站", value: [123.461215, 41.778005, 2.33] },
        { name: "南通永兴林森", value: [118.920017, 33.552052, 4.59] },
        { name: "创能二站", value: [122.973982, 41.049711, 2.07] },
        {
            name: "祥光新能源6MW全额上网屋顶分布式光伏发电",
            value: [119.491977, 37.059157, 2.0]
        },
        {
            name: "山东潍坊融光一站6MWp光伏电站",
            value: [119.170064, 37.048124, 2.0]
        },
        { name: "融光二站二期", value: [114.383267, 36.125962, 2.02] },
        {
            name: "山东潍坊融光二站6MWp光伏电站",
            value: [119.170064, 37.047952, 1.99]
        },
        { name: "淄博开耀", value: [114.371502, 35.67851, 3.84] },
        { name: "东营金宇", value: [116.198767, 36.399125, 3.18] },
        { name: "东营正凯", value: [116.557801, 37.398347, 3.52] },
        { name: "合肥恒春玻璃", value: [113.920412, 35.272855, 4.93] },
        { name: "科灵空调", value: [123.826202, 42.181145, 2.35] },
        { name: "合肥桥柜", value: [117.429631, 31.889512, 4.81] },
        { name: "合肥润嘉", value: [117.298406, 31.828051, 5.3] },
        { name: "合肥应流二期", value: [113.502879, 34.673248, 5.33] },
        { name: "潍坊安丘汇创", value: [119.228152, 36.483145, 2.11] },
        { name: "安徽安庆永安电子电站", value: [117.080678, 30.509951, 5.47] },
        { name: "阜阳京九丝绸", value: [115.829345, 32.898245, 4.76] },
        { name: "合肥凯盛", value: [117.357838, 31.781652, 5.03] },
        { name: "合肥万润", value: [114.276853, 34.697382, 5.09] },
        {
            name: "合肥恒科1.55184MW光伏电站",
            value: [117.200839, 31.822187, 5.22]
        },
        {
            name: "灌云中森侍庄2.86832MW屋顶电站",
            value: [119.30428, 34.601536, 2.69]
        },
        { name: "启东胜狮二期", value: [123.933992, 30.760809, 4.63] },
        {
            name: "如皋意瑞达2MW屋顶分布式光伏电站",
            value: [120.541273, 32.434058, 3.68]
        },
        { name: "连云港首耀新海", value: [119.291198, 34.590419, 2.19] },
        {
            name: "泗洪归仁1.59636MW屋顶光伏发电站",
            value: [118.204362, 33.674983, 4.44]
        },
        {
            name: "泗洪陈圩1.6854MW屋顶光伏发电站",
            value: [118.362715, 33.333696, 4.09]
        },
        {
            name: "启东林洋电子2MW屋顶分布式光伏电站",
            value: [121.639277, 31.917826, 3.42]
        },
        {
            name: "启东乾朔1.78MW屋顶分布式光伏电站",
            value: [121.623939, 31.819655, 4.01]
        },
        { name: "南通乐歌", value: [121.007375, 31.857338, 4.83] },
        { name: "山东安丘龙鼎重工", value: [119.220486, 36.412793, 2.1] },
        { name: "连云港中森西苑", value: [119.278155, 34.259502, 2.63] },
        { name: "宿州现代鞋城", value: [116.895538, 33.552214, 5.6] },
        { name: "连云港中盛石榴", value: [117.998971, 33.977616, 2.78] },
        {
            name: "山东潍坊新瑞永旭2.28MW屋顶电站",
            value: [119.228754, 36.492428, 2.38]
        },
        { name: "华乐光电二期", value: [121.642417, 31.745777, 3.79] },
        {
            name: "连云港易睐珂克浦南1.987MW光伏电站",
            value: [119.3443, 34.586143, 2.58]
        },
        {
            name: "扬州信息产业园1MW屋顶分布式光伏电站",
            value: [119.5116, 32.395069, 4.83]
        },
        { name: "合肥强建", value: [117.287, 31.946, 5.61] },
        {
            name: "安丘晨晖2MW全额上网屋顶分布式光伏发电项目",
            value: [119.229382, 36.480252, 2.21]
        },
        {
            name: "安徽界首蓝天0.924MW屋顶分布式光伏电站",
            value: [115.466991, 33.247196, 4.85]
        },
        {
            name: "启东凯帝华虹1.18MWP屋顶分布式光伏电站",
            value: [121.6123491124, 31.827141196, 4.0]
        },
        { name: "华乐光电一期", value: [121.616581, 31.810208, 4.45] },
        {
            name: "辽宁乾丰专用车7MW屋顶光伏发电项目",
            value: [123.680107, 42.178133, 0.56]
        },
        {
            name: "启东华泰1MW屋顶光伏电站",
            value: [121.619447, 31.824145, 3.74]
        },
        {
            name: "铁岭际华5.78MW屋顶分布式光伏发电项目",
            value: [123.680107, 42.178133, 0.61]
        },
        { name: "连云港首耀清墩", value: [114.742589, 33.608996, 2.0] },
        {
            name: "南通农副1MW屋顶分布式光伏电站",
            value: [120.957317, 31.983873, 3.04]
        },
        {
            name: "沈阳鲁博厨业3.9MW屋顶分布式光伏发电项目",
            value: [123.4132765888, 41.5754322375, 0.75]
        },
        { name: "宿州中谷0.6552光伏电站", value: [116.954963, 33.65434, 4.49] },
        {
            name: "南通观音山1MW屋顶分布式光伏电站",
            value: [120.724018, 32.37467, 2.6]
        },
        {
            name: "合肥东维0.5MW屋顶分布式光伏电站",
            value: [117.228806, 31.719746, 4.82]
        },
        {
            name: "铁岭林燕新能源科技有限公司",
            value: [123.6904623894, 42.146368079, 0.56]
        },
        {
            name: "铁岭中欧汽车3.5MW屋顶分布式光伏发电项目",
            value: [123.7008864936, 42.1576004524, 0.59]
        },
        { name: "如皋里高", value: [119.383235, 32.416341, 4.8] },
        {
            name: "南通中心村0.5MW屋顶分布式光伏电站",
            value: [121.084309, 31.885101, 3.41]
        },
        {
            name: "海门银燕0.42MW屋顶分布式光伏电站",
            value: [121.203163, 31.912599, 4.02]
        },
        { name: "启东中盛机电", value: [121.877701, 31.921345, 3.88] },
        { name: "冠县公共事业", value: [115.510224, 36.450879, 0.0] },
        { name: "河北风阳卧牛盘", value: [117.359653, 42.470969, 0.0] }
    ];

    var minX, maxX, minY, maxY;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (minX === undefined || minX > data[i].value[0]) {
                minX = data[i].value[0];
            }
            if (maxX === undefined || maxX < data[i].value[0]) {
                maxX = data[i].value[0];
            }
            if (minY === undefined || minY > data[i].value[1]) {
                minY = data[i].value[1];
            }
            if (maxY === undefined || maxY < data[i].value[1]) {
                maxY = data[i].value[1];
            }
        }
    }
    var centerX = (minX + maxX) / 2;
    var centerY = (minY + maxY) / 2;

    var minV, maxV;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (data[i].value[2] === 0.0) continue;
            if (minV === undefined || minV > data[i].value[2]) {
                minV = data[i].value[2];
            }
            if (maxV === undefined || minV < data[i].value[2]) {
                maxV = data[i].value[2];
            }
        }
    }

    var dataTop10 = [];
    var dataOther = [];
    var dataZero = [];
    var topCount = 0;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (data[i].value[2] <= 0.0) {
                dataZero.push(data[i]);
            } else {
                if (topCount < 10) {
                    dataTop10.push(data[i]);
                    ++topCount;
                } else {
                    dataOther.push(data[i]);
                }
            }
        }
    }

    var mapdata = [
        { name: "安徽", value: 4.33 },
        { name: "江苏", value: 4.46 },
        { name: "山东", value: 4.35 },
        { name: "河南", value: 4.38 },
        { name: "内蒙古", value: 6.21 },
        { name: "河北", value: 4.58 },
        { name: "辽宁", value: 2.9 }
    ];

    var nbData = [
        { name: "0-10 %", value: 3613 },
        { name: "10-20 %", value: 1102 },
        { name: "20-30 %", value: 36 },
        { name: "30-40 %", value: 35 },
        { name: "40-50 %", value: 25 },
        { name: "50-60 %", value: 71 },
        { name: "60-70 %", value: 62 },
        { name: "70-80 %", value: 395 },
        { name: "80-90 %", value: 345 },
        { name: "90-100 %", value: 5094 }
    ];
    var yData = [];
    for (const i in nbData) {
        if (data.hasOwnProperty(i)) {
            yData.push(nbData[i].name);
        }
    }

    var colorList = [
        // '#96BFFF',
        // '#8378EA',
        // '#9D96F5',
        "#E7BCF3",
        "#E690D1",
        "#E062AE",
        "#FB7293",
        "#FF9F7F",
        "#FFDB5C",
        "#9FE6B8",
        "#67E0E3",
        "#32C5E9",
        "#37A2DA"
    ];

    var barWidth = 30;

    var series = [
        /*{
    name: '逆变器运行效率',
    type: 'bar',
    roam: false,
    visualMap: false,
    zlevel: 2,
    barMaxWidth: 16,
    //barGap: 10,
    label: {
        show: true,
        position: 'right'
    },
    itemStyle: {
        normal: {
            color: function(params) {
                return colorList[params.dataIndex];
            },
            barBorderRadius: 5
        }
    },
    data: nbData
},*/
        //地图上代表电站的点
        {
            name: "other",
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "stroke"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: function(val) {
                if (val[2] === 0.0) return 5;
                return 5 + ((val[2] - minV) / (maxV - minV)) * 5;
            },
            itemStyle: {
                normal: {
                    color: "#67E0E3"
                }
            },
            data: dataOther
        },
        {
            name: "Top10",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "stroke"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: function(val) {
                if (val[2] === 0.0) return 5;
                return 6 + ((val[2] - minV) / (maxV - minV)) * 6;
            },
            itemStyle: {
                normal: {
                    color: "#9FE6B8"
                }
            },
            data: dataTop10
        },
        {
            name: "zero",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "fill"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: 10,
            itemStyle: {
                normal: {
                    color: "#FB7293"
                }
            },
            data: dataZero
        },
        {
            name: "安徽",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [null, null, null, 4, 13, 21, 2, null]
        },
        {
            name: "河北",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [null, null, null, null, null, 1, null, null]
        },
        {
            name: "河南",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [null, null, null, null, null, 2, null, null]
        },
        {
            name: "江苏",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [null, 1, 2, 22, 16, 9, null, null]
        },
        {
            name: "辽宁",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [null, null, null, null, 2, 3, null, null]
        },
        {
            name: "内蒙古",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [null, null, null, null, null, null, 1, null]
        },
        {
            name: "山东",
            type: "bar",
            stack: "1",
            barWidth: barWidth,
            label: { normal: { show: true, position: "inside" } },
            data: [1, 9, 1, 6, 6, 6, null, 1]
        },
        {
            name: "装机容量",
            type: "map",
            mapType: "china",
            geoIndex: 0,
            data: mapdata
        }
    ];

    let option = {
        backgroundColor: "rgba(255, 255, 255, 0)",
        //color: ['#2196F3', '#FFC107', '#45A349', '#FF5722', '#9C27B0', '#7FBCEC', '#21C7E2', '#84A9C1', '#409E8F'],
        tooltip: {
            trigger: "item",
            formatter: function(params) {
                if (params.seriesType == "map") {
                    if (params.data === undefined) {
                        return null;
                    } else {
                        return (
                            params.data.name +
                            "<br />日等效时数：" +
                            params.data.value +
                            " h"
                        );
                    }
                } else if (params.seriesType == "bar") {
                    return params.data.name + "：" + params.data.value + " 台";
                } else {
                    return (
                        params.data.name +
                        "<br />日等效时数：" +
                        params.data.value[2] +
                        " h"
                    );
                }
            }
        },
        legend: {
            top: "5%",
            left: "65%",
            data: ["安徽", "河北", "河南", "江苏", "辽宁", "内蒙古", "山东"]
        },
        grid: [
            {
                top: "15%",
                height: "70%",
                left: "65%",
                containLabel: false
            }
        ],
        xAxis: {
            type: "value",
            show: false
        },
        yAxis: {
            type: "category",
            data: [
                "0-1 h",
                "1-2 h",
                "2-3 h",
                "3-4 h",
                "4-5 h",
                "5-6 h",
                "6-7 h",
                "7-8 h"
            ]
        },
        /*
    xAxis : [
        {
            type : 'value',
            show: false
        }
    ],
    yAxis : [{
        name: '逆变器运行效率',
        //nameGap: 16,
        nameTextStyle: {
            fontSize: 16
        },
        type : 'category',
        axisLine: {
            show: false
        },
        axisTick: {
            show: false,
            lineStyle: {
                color: '#ddd'
            }
        },
        axisLabel: {
            interval: 0,
            margin: 105,
            textStyle: {
                color: '#455A74',
                align: 'left',
                fontSize: 14
            },
            rich: {
                a0: {backgroundColor: colorList[0], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a1: {backgroundColor: colorList[1], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a2: {backgroundColor: colorList[2], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a3: {backgroundColor: colorList[3], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a4: {backgroundColor: colorList[4], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a5: {backgroundColor: colorList[5], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a6: {backgroundColor: colorList[6], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a7: {backgroundColor: colorList[8], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a8: {backgroundColor: colorList[8], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
                a9: {backgroundColor: colorList[9], color: '#fff', width: 25, height: 18, align: 'center', borderRadius: 2},
            },
            formatter: function(text, i) {
                return [
                    '{a' + i + '|' + (10-i) + '}' + '  ' + text
                ].join('\n');
            }
        },
        data: yData
    }],
    */
        visualMap: {
            show: false,
            type: "continuous",
            //orient: 'horizontal',
            min: 0,
            max: 6.21,
            inRange: {
                color: ["#56bdff", "#026bad"]
            },
            itemWidth: 8,
            itemHeight: 100,
            left: "left",
            top: "70%",
            //text:['高','低'],           // 文本，默认为数值文本
            calculable: true,
            precision: 0,
            //align: 'bottom',
            seriesIndex: [series.length - 1]
        },
        geo: {
            map: "china",
            //zoom: 1,
            //center: [centerX, centerY],
            // boundingCoords: [
            //     [minX, maxY], // 定位左上角经纬度
            //     [maxX, minY]  // 定位右下角经纬度
            // ],
            layoutCenter: ["30%", "50%"],
            layoutSize: "100%",
            zlevel: 0,
            label: {
                emphasis: {
                    show: false
                }
            },
            roam: true,
            itemStyle: {
                areaColor: "#BBDEFB", //'#F2F2F2',
                borderColor: "#727272",
                opacity: 1
            },
            emphasis: {
                itemStyle: {
                    areaColor: "#005184"
                }
            }
        },
        series: series
    };

    return option;
}
