import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TreeNode } from 'primeng/primeng';

import { CommonService } from '../../services/common.service';
import { UserSelectorService } from './user-selector.service';
import { UserData, Department } from './user-selector.model';
import { ObjType } from '../../models/codeItems.model';

@Component({
  selector: 'mpt-user-selector',
  templateUrl: 'user-selector.component.html',
  styleUrls: ['user-selector.component.less'],
})
export class UserSelectorComponent {
  @Input() public isRadio = false;
  @Input() public isShow = false;
  @Input() public modal = true;
  @Input() public selectedUser: UserData[] = [];
  @Output() public selectedData = new EventEmitter<any>();
  @Output() public showEvent = new EventEmitter<any>();

  public selectedUserSave: UserData[] = [];
  public users: UserData[] = [];
  public searchName = '';
  public queryParams = {
    departmentId: '',
    realName: '',
    pageSize: 20,
    pageCurrent: 0,
  };
  public pager = {
    rows: 20,
    pageCount: 0,
    pageCurrent: 0,
    totalRecords: 0,
    rowsPerPage: [10, 20, 30, 50, 100],
  };
  public departments: TreeNode[] = [];
  public selectedDepart: TreeNode[];
  public isShowDept = false;
  loading: boolean;

  constructor(private common: CommonService, private userSelectorService: UserSelectorService) { }

  // 显示选择对象弹出框
  public onShow() {
    this.loadDepartments();
    setTimeout(() => {
      this.isShowDept = true;
    }, 50);
  }
  // 隐藏选择对象弹出框
  public onHide() {
    this.selectedUser = [];
    this.showEvent.emit(false);
    this.isShowDept = false;
  }
  // 提交选择对象弹出框
  public userSubmit() {
    this.selectedData.emit(this.selectedUser);
    this.onHide();
  }

  // 清空选中项
  public reset() {
    this.selectedUser = [];
    this.selectedData.emit(this.selectedUser);
    this.onHide();
  }

  // 选择部门
  public selectDepartment(event?) {
    if (event && event.node) {
      this.selectedDepart = [event.node];
    }
    this.pager.pageCurrent = 0;
    this.queryParams.departmentId = this.selectedDepart[0].data;
    this.queryParams.realName = '';
    this.queryList();
  }

  public unSelectDepartment(event) {
    this.pager.pageCurrent = 0;
    this.queryParams.realName = '';
    this.queryParams.departmentId = null;
    this.queryList();
  }

  // 查询告警对象
  public queryUserList() {
    this.pager.pageCurrent = 0;
    this.queryParams.realName = this.searchName;
    this.queryList();
  }
  // 获取告警对象列表
  public queryList() {
    this.queryParams.pageSize = this.pager.rows;
    this.queryParams.pageCurrent = this.pager.pageCurrent;
    this.loading = true;
    this.userSelectorService.getUserListData(this.queryParams).subscribe(
      (res: any) => {
        this.users = res.content;
        this.pager.pageCount = res.totalPages;
        this.pager.totalRecords = res.totalElements;
      },
      (error) => {
        this.common.errorMessage(error);
      },
      () => {
        this.loading = false;
      },
    );
  }
  // 分页事件
  public onPageChange(e) {
    if (this.pager.pageCurrent !== e.page) {
      this.pager.pageCurrent = e.page;
      this.queryParams.pageCurrent = this.pager.pageCurrent;
    }
    if (this.pager.rows !== parseInt(e.rows, 10)) {
      this.pager.rows = e.rows;
      this.pager.pageCurrent = 0;
      this.queryParams.pageSize = this.pager.rows;
    }
    this.queryList();
  }

  // 获取部门数据
  private loadDepartments() {
    if (this.departments.length) {
      this.setDepartments();
      this.selectDepartment();
    } else {
      this.userSelectorService.getDepartments().subscribe((departments: any) => {
        const departJsonList: TreeNode[] = departments;
        this.departments = departJsonList;
        this.setDepartments();
        this.selectDepartment();
      });
    }
  }

  private setDepartments() {
    this.selectedDepart = [this.departments[0]];
    this.convertToTree(this.departments);
    this.queryParams.departmentId = this.departments[0].data;
  }

  // 遍历部门树数据、数据转换
  private convertToTree(departments: TreeNode[]) {
    for (const department of departments) {
      department.label = `${department.data.deptName} [${department.data.allUserCount}]`;
      if (department.data.deptType === ObjType.department) {
        department.expanded = true;
      }
      if (department.children && department.children.length) {
        department.expanded = true;
        this.convertToTree(department.children);
      } else {
        department.leaf = true;
      }
    }
  }
}
