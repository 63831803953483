import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import * as _ from "lodash";
import * as moment from "moment";

import { AppConfigService } from "../../../app.config";
import { AuthHttp } from "../../../core/auth-http";
import { CommonService } from "../../../common/services/common.service";
import { PropType, PropTypeDetail } from "./shared/prop-types.model";
import { tap } from "rxjs/operators";

@Injectable()
export class PropTypesService {
    public apiUrl: string = this.appConfig.apiUrl;
    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private common: CommonService
    ) {}

    public getPropTypesList(param, page, size) {
        return this.http.get(`${this.apiUrl}/api/prop/propTypes`, {
            search: { typeName: param, page: page, size: size }
        });
    }

    public getPropValueList(typeId) {
        return this.http.get(`${this.apiUrl}/api/prop/propLists`, {
            search: { typeId }
        });
    }

    // 码表删除
    public DeleteType(typeId) {
        return this.http
            .delete(`${this.apiUrl}/api/prop/propTypes`, { search: { typeId } })
            .pipe(tap(res => {}));
    }

    // 码值删除
    public DeleteValue(listId) {
        return this.http
            .delete(`${this.apiUrl}/api/prop/propLists`, { search: { listId } })
            .pipe(tap(res => {}));
    }

    // 上移下移
    public changeOrder(listIds) {
        return this.http
            .put(`${this.apiUrl}/api/prop/changePropListOrder`, listIds)
            .pipe(tap(res => {}));
    }
}
