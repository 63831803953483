import { Injectable } from '@angular/core';
import { AuthHttp } from '../../../../core/auth-http';
import { AppConfigService } from '../../../../app.config';

@Injectable()
export class SearchBoxService {

  public constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService
  ) { }

  public search(text: string) {
    return this
      .http
      .get(`${this.appConfig.apiUrl}/api/search`, { search: { text } })
  }
}
