import * as _ from 'lodash';
import * as echarts from 'echarts';

export const weatherStationDataOption = {};
export function buildWeatherStationDataChart(data) {
    // let option = _.cloneDeep(weatherForecastOption);
    // option.legend.data = data.legend.data;
    // option.xAxis.data = data.xAxis.data;
    // option.series = data.series;

    var initData = [
        { date: '09/03', tfloor: 20, tceil: 23, wind: 4, radiation: 5, text: '晴', icon: '100.png' },
        { date: '09/04', tfloor: 21, tceil: 23, wind: 2, radiation: 4, text: '多云', icon: '多云.png' },
        { date: '09/05', tfloor: 24, tceil: 26, wind: 5, radiation: 5, text: '晴', icon: '100.png' },
        { date: '09/06', tfloor: 21, tceil: 25, wind: 1, radiation: 4, text: '多云', icon: '多云.png' },
        { date: '09/07', tfloor: 24, tceil: 28, wind: 3, radiation: 3, text: '阴', icon: '阴.png' },
        { date: '09/08', tfloor: 24, tceil: 26, wind: 3, radiation: 4, text: '多云', icon: '多云.png' },
        { date: '09/09', tfloor: 22, tceil: 26, wind: 3, radiation: 3, text: '阴', icon: '阴.png' },
        { date: '09/10', tfloor: 20, tceil: 23, wind: 3, radiation: 5, text: '晴', icon: '100.png' },
        { date: '09/11', tfloor: 21, tceil: 24, wind: 3, radiation: null, text: '晴', icon: '100.png' },
        { date: '09/12', tfloor: 22, tceil: 35, wind: 2, radiation: null, text: '阴', icon: '阴.png' },
        { date: '09/13', tfloor: 22, tceil: 26, wind: 2, radiation: null, text: '雨', icon: '雨.png' },
        { date: '09/14', tfloor: 22, tceil: 25, wind: 3, radiation: null, text: '多云', icon: '多云.png' },
        { date: '09/15', tfloor: 20, tceil: 33, wind: 1, radiation: null, text: '阴', icon: '阴.png' },
        { date: '09/16', tfloor: 20, tceil: 24, wind: 5, radiation: null, text: '多云', icon: '多云.png' },
        { date: '09/17', tfloor: 22, tceil: 24, wind: 2, radiation: null, text: '多云', icon: '多云.png' },
    ];

    var datas = [[], [], []];
    var xAxisDatas = [];
    var maxy = 0;
    var marks = [];
    var isToday = false;
    for (var i in initData) {
        var d = initData[i];
        isToday = d.date == '09/10';
        xAxisDatas.push(d.date);
        datas[0].push(d.tfloor);
        datas[1].push(d.tceil);
        datas[2].push(d.radiation);
        if (maxy < d.tceil) maxy = d.tceil;
        marks.push({
            name: '天气',
            value: isToday ? '22℃\n' + d.text : d.text,
            xAxis: d.date,
            yAxis: d.tceil,
            symbolSize: isToday ? 100 : 50,
            //symbolOffset: [0, '-100%'],
            //symbol: 'image://images/'+d.icon,
            itemStyle: {
                color: isToday ? '#FB7293' : null,
            },
            label: {
                show: true,
                fontSize: isToday ? 18 : 12,
                //	color: '#727272',
                //	offset: [0, '5%'],
            },
        });
    }
    maxy += 5;

    //for (var i in initData) {
    //	marks.push({name: '晴', value: 40, xAxis: initData[i].date, yAxis: maxy-4});
    //}

    // 指定图表的配置项和数据
    var option = {
        color: ['#32C5E9', '#FF9F7F', '#FFDB5C'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            bottom: '5%',
            data: ['最低温', '最高温', '日辐照量'],
        },
        grid: {
            left: '5%',
            right: '5%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            splitLine: {
                show: false,
            },
            data: xAxisDatas,
        },
        yAxis: [
            {
                type: 'value',
                scale: true,
                name: '℃',
                max: maxy,
                splitLine: {
                    show: false,
                },
            },
            {
                type: 'value',
                scale: true,
                name: 'KWh/m2',
                splitLine: {
                    show: false,
                },
            },
        ],
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100,
            },
        ],
        series: [
            {
                name: '大气温度',
                type: 'line',
                smooth: true,
                label: {
                    show: true,
                    position: 'bottom',
                    color: '#727272',
                },
                data: datas[0],
            },
            {
                name: '组件温度',
                type: 'line',
                smooth: true,
                label: {
                    show: true,
                    position: 'bottom',
                    color: '#727272',
                },
                data: datas[1],
            },
            {
                name: '日辐照量',
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                data: datas[2],
                symbol: 'none',
                sampling: 'average',
                lineStyle: {
                    width: 0.5,
                },
                areaStyle: {
                    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                        {
                            offset: 0,
                            color: 'rgba(255, 219, 92, 1)',
                        },
                        {
                            offset: 1,
                            color: 'rgba(255, 255, 255, 0)',
                        },
                    ]),
                },
            },
        ],
    };

    return option;
}
