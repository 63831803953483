import * as _ from 'lodash';

export const electricityYearOption = {
  color: [{
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#02C4FF' // 0% 处的颜色
    }, {
      offset: 1, color: '#004579' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  },
  // 径向渐变，前三个参数分别是圆心 x, y 和半径，取值同线性渐变
  {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: [{
      offset: 0, color: '#02FF73' // 0% 处的颜色
    }, {
      offset: 1, color: '#005D2F' // 100% 处的颜色
    }],
    global: false // 缺省为 false
  }, '#D09E49'],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: [],
    x: 'center',
    y: 'bottom'
  },
  xAxis: {
    type: 'category',
    name: '时间',
    boundaryGap: true,
    data: []
  },
  yAxis: [],
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100
    }
  ],
  series: []
};

export function buildElectricityYearChart(data) {
  let option: any = _.cloneDeep(electricityYearOption);
  if (data) {
    option.legend.data = data.legend.data;
    option.xAxis.data = data.xAxis.data;
    option.yAxis = data.yAxis.map(item => {
      return {
        axisLabel: {
          show: false
        },
        ...item,
      }
    });
    // 左边参考线移除
    option.yAxis[0] = { ...option.yAxis[0], splitLine: { show: false } };
    option.series = data.series;
  }
  return option;
}
