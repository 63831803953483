import { DialogModule, PaginatorModule, TooltipModule } from 'primeng/primeng';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { InvertersService } from './inverters.service';
import { InvertersComponent } from './inverters.component';
import { StationMonitorModule } from '@components/station-monitor/station-monitor.module';

const primengModule = [PaginatorModule, TooltipModule, DialogModule]


@NgModule({
  declarations: [InvertersComponent],
  imports: [
    SharedModule,
    ...primengModule,
    StationMonitorModule
  ],
  providers: [InvertersService],
  exports: [InvertersComponent]
})
export class InvertersModule { }
