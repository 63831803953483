import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { NavService } from './nav.service';
import { NavComponent } from './nav.component';
import { SystemTimeComponent } from './system-time/system-time.component';
import { SystemTimeService } from './system-time/system-time.service';
import { SharedModule } from '../../../shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    SharedModule
  ],
  declarations: [
    NavComponent,
    SystemTimeComponent
  ],
  exports: [
    NavComponent
  ],
  providers: [
    NavService,
    SystemTimeService
  ]
})
export class NavModule {
}
