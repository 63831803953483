import {
  Component,
  OnInit,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { TipService } from "@common/services/tip.service";
import * as _ from "lodash";

import { CommonService } from "../../../services/common.service";
import { StationList } from "../object-selector.model";
import { ObjectSelectorService } from "../object-selector.service";

@Component({
  selector: "mpt-station-object",
  templateUrl: "station-object.component.html",
  styleUrls: ["../object-selector.component.less"]
})
export class StationObjectComponent implements OnInit, OnChanges {
  @Input() public queryIndex: number;
  @Input() public isRadio: boolean;
  @Input() public selected: any[] = [];
  @Output() public isShow = new EventEmitter<boolean>();
  @Output() public submitDataEvent = new EventEmitter<StationList[]>();

  public isLoading = false;
  public queryForm: FormGroup;
  public isSubmitAllData = false;
  public stationData: StationList[] = []; // 表格数据
  public selectedData: StationList[] = []; // 已选择数据
  public pager = { pageCurrent: 0, totalRecords: 0, pageSize: 10 };

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private objectSelectorService: ObjectSelectorService,
    private tipservice: TipService
  ) { }

  public ngOnInit() {
    // form初始化
    this.formInit();

    // 表格初始化
    this.queryList(true);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.queryIndex) {
      if (changes.queryIndex.currentValue) {
        this.queryList();
      }
    }
    if (changes.selected) {
      // if (_.isEmpty(changes.selected.currentValue)) {
      //   this.selectedData = [];
      // }
      if (
        !_.isEmpty(changes.selected.currentValue) &&
        !_.isEmpty(this.stationData)
      ) {
        let initSelectedData = [];
        for (let data of changes.selected.currentValue) {
          initSelectedData = _.concat(
            initSelectedData,
            _.find(this.stationData, (item: any) => {
              return item.psrId === data.psrId;
            })
          );
        }
        this.selectedData = _.concat(
          [],
          this.selectedData,
          initSelectedData
        );
      }
    } else {
      this.selectedData = [];
    }
  }

  // form初始化
  public formInit() {
    this.queryForm = this.fb.group({
      shortName: new FormControl()
    });
  }

  // 获取查询参数
  public getParam() {
    let params = this.queryForm.value;
    _.forIn(params, (value, key) => {
      params[key] = value ? value : null;
    });
    return params;
  }
  // 表格查询
  public queryList(isQuery?: boolean) {
    if (isQuery) {
      this.pager.pageCurrent = 0;
    }
    this.isLoading = true;
    this.objectSelectorService
      .getStationList(
        this.getParam().shortName,
        this.pager.pageCurrent,
        this.pager.pageSize
      )
      .subscribe(
        (res: any) => {
          this.pager.totalRecords = res.totalElements;
          this.stationData = res.content;
        },
        error => {
          this.common.errorMessage(error);
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  // 分页事件
  public onPageChange(event) {
    this.pager.pageCurrent = event.page;
    this.pager.pageSize = parseFloat(event.rows);
    this.queryList();
  }

  // 提交
  public submitData() {
    // if (this.selectedData.length === 0) {
    //   this.tipservice.tip('warn', '请最少选择一条数据！');
    //   return false;
    // }
    this.selectedData = this.objectSelectorService.setSelectedDataType(
      this.selectedData,
      "05"
    );
    this.submitDataEvent.emit(this.selectedData);
    this.isShow.emit(false);
  }

  // 选择全部
  public chooseAllData() {
    if (this.pager.totalRecords === 0) {
      this.tipservice.tip("warn", "当前没有数据可选择！");
      return false;
    }
    this.isSubmitAllData = true;
    this.selectedData = this.stationData;
  }

  // 提交 全部
  public submitAllData() {
    // 获取全部数据接口
    this.objectSelectorService
      .getStationList(
        this.getParam().shortName,
        0,
        this.pager.totalRecords
      )
      .subscribe(
        (res: any) => {
          this.selectedData = res.content;

          this.isSubmitAllData = false;
          this.selectedData = this.objectSelectorService.setSelectedDataType(
            this.selectedData,
            "05"
          );
          this.submitDataEvent.emit(this.selectedData);
          this.isShow.emit(false);
        },
        error => this.common.errorMessage(error)
      );
  }

  // 取消选择全部
  public onCancelAllData() {
    this.isSubmitAllData = false;
    this.selectedData = [];
  }

  // 取消
  public cancelData() {
    this.isShow.emit(false);
  }
}
