import { CommonService } from 'src/app/common/services/common.service';
import { AppConfigService } from 'src/app/app.config';
import { AuthHttp } from 'src/app/core/auth-http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StationAreaListService {


  public substationsUrl = '/api/v2/monitor/station/substations';
  apiUrl: string;

  constructor(
    private http: AuthHttp,
    private appconfig: AppConfigService,
    private common: CommonService
  ) {
    this.apiUrl = this.appconfig.apiUrl
  }


  public getSubstations(params): any {
    return this.http.get(`${this.apiUrl}${this.substationsUrl}`, {
      params: this.common.filterEffectParam(params)
    }).toPromise();
  }


  // 逆变器
  public getInverterDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/inverter/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }


  public exportInverterDatas(params) {
    return this.common.fileExport(
      `${this.apiUrl}/api/v2/monitor/station/substations/export`, params
    );
  }
}
