import { Pipe, PipeTransform } from '@angular/core';
import { CodeTableService } from '../../core/code-table.service';

@Pipe({
  name: 'nemsCodetable',
  pure: false,
})
export class NemsCodeTablePipe implements PipeTransform {
  private cached = {};

  public constructor(private codeTableService: CodeTableService) {}

  public transform(codeId: string, emptyLabel): any {
    if (this.cached[codeId]) {
      return this.cached[codeId];
    }
    if (!codeId) {
      return [];
    }

    let _items = [];
    if (emptyLabel) {
      _items.push({ label: emptyLabel, value: null });
    }
    this.codeTableService.getNemsSelectItems(codeId).then((res) => {
      this.cached[codeId] = _items.concat(res);
    });
    return _items;
  }
}
