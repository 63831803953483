import { Pipe, PipeTransform } from "@angular/core";
import { SelectItem, CodeTableService } from "../../core/code-table.service";

@Pipe({
    name: "nemsCodeitem",
    pure: false
})
export class NemsCodeItemPipe implements PipeTransform {
    private cached;
    private promise: Promise<string>;

    public constructor(private codeTableService: CodeTableService) {}

    public transform(itemId: string, codeId: string, emptyLabel: string): any {
        if (typeof this.cached !== "undefined") {
            return this.cached;
        }
        if (this.promise) {
            return undefined;
        }
        if (!itemId) {
            return emptyLabel;
        }

        this.promise = this.codeTableService
            .getNemsCodeItem(codeId, itemId)
            .then(res => {
                this.cached = res ? res.label : emptyLabel || itemId || "";
                if (typeof this.cached === undefined) {
                    this.cached = "";
                }
                return this.cached;
            });
    }
}
