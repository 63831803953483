import {
    Component,
    OnInit,
    OnDestroy,
    ChangeDetectionStrategy,
    NgZone,
    DoCheck,
    ChangeDetectorRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TopoService } from '../../../common/services/topo.service';
import { TopoTabService } from '../../../common/services/topoTab.service';
import { ToastService } from '../../../core/toast.service';

declare const hteditor;
declare const ht;
declare const window;
declare const setTopoData;
declare const setTopoDevice;

@Component({
    selector: 'mpt-topo-editor',
    templateUrl: './topo-editor.component.html',
    styleUrls: ['./topo-editor.component.less'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopoEditorComponent implements OnInit, OnDestroy, DoCheck {
    public stationId: string;
    private topoId: number;
    public isOpenSelector = false;
    public oldIsOpenSelector;

    public isShowObjectDialog = false;
    public oldIsShowObjectDialog;

    public ht_height;

    constructor(
        private route: ActivatedRoute,
        private topoService: TopoService,
        private _ngZone: NgZone,
        private changeRef: ChangeDetectorRef,
        private toastService: ToastService,
        private topoTabService: TopoTabService
    ) {
        window['topoEditorRef'] = { component: this, zone: _ngZone };
        this.ht_height = document.body.clientHeight - 34 - 16 * 2 - 60;
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.topoId = params['topoId'];
            this.stationId = params['stationId'];
            this.topoService.isTopoItemManager().subscribe((res) => {
                this.initEditor(res);
            });
        });
    }

    ngOnDestroy(): void {
        this.topoId = null;
        delete ht.Default.getClassMap().htService; // 删除htservice类，避免报错
        window['topoEditorRef'] = null;
    }

    ngDoCheck(): void {
        if (this.isOpenSelector !== this.oldIsOpenSelector) {
            this.oldIsOpenSelector = this.isOpenSelector;
            this.changeRef.detectChanges();
        }
        if (this.isShowObjectDialog !== this.oldIsShowObjectDialog) {
            this.oldIsShowObjectDialog = this.isShowObjectDialog;
            this.changeRef.detectChanges();
        }
    }
    selectPoint(point) {
        setTopoData(point);
    }

    selectDevice(device) {
        setTopoDevice(device);
    }

    private initEditor(isTopoItemManager) {
        let urls = hteditor.config.subConfigs;
        urls.push(hteditor.init);
        if (hteditor.config.libs) {
            urls = urls.concat(hteditor.config.libs);
        }
        ht.Default.loadJS(urls, () => {
            this.topoService.initEditorService(this.topoId);
            let topoTabSer = this.topoTabService;
            let toastSer = this.toastService;
            if (this.topoId) {
                let topoId = this.topoId;
                window.editor = hteditor.createEditor({
                    container: document.getElementById('ht-editor'),
                    onTabCreated: function(editor, tabObj) {
                        if (tabObj['tab']['_tag']) {
                            topoTabSer.initEditorTabService(
                                topoId,
                                tabObj['tab']['_id']
                            );
                        } else {
                            topoTabSer.initEditorTabService(
                                undefined,
                                tabObj['tab']['_id']
                            );
                        }
                    },
                    onTabUpdated: function(editor, tabObj) {
                        if (tabObj['tab']) {
                            topoTabSer.initEditorTabService(
                                undefined,
                                undefined,
                                tabObj['tab']['_id']
                            );
                        }
                    }
                });
            } else {
                window.editor = hteditor.createEditor({
                    container: document.getElementById('ht-editor'),
                    open: 'newdisplay',
                    onTabCreated: function(editor, tabObj) {
                        topoTabSer.initEditorTabService(
                            undefined,
                            undefined,
                            tabObj['tab']['_id']
                        );
                    },
                    onTabUpdated: function(editor, tabObj) {
                        if (tabObj['tab']) {
                            topoTabSer.initEditorTabService(
                                undefined,
                                undefined,
                                tabObj['tab']['_id']
                            );
                        }
                    }
                });
            }
            window.editor.isOpenable = function(fileNode) {
                if (fileNode.fileType === 'symbol' && !isTopoItemManager) {
                    toastSer.warning('您没有权限编辑图元');
                    return false;
                }
                return true;
            };
        });
    }
}
