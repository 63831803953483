import { Injectable } from '@angular/core';
import { AppConfigService } from '../../../app.config';
import { AuthHttp } from '../../../core/auth-http';
import { CommonService } from '../../services/common.service';
import { DeptPick, Rela } from './dept-picklist.model';
import * as _ from 'lodash';

@Injectable()
export class DeptPicklistService {
  public apiUrl: string = this.appConfig.apiUrl;
  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {}

  public getStations() {
    return this.http
      .get(`${this.apiUrl}/api/index/getSampleStationAll`)
      .toPromise();
  }
  // 设备编号
  public getdeviceNos(param) {
    return this.http.get(`${this.appConfig.apiUrl}/api/device/deviceNos`, {
      search: this.common.filterEffectParam(param),
    });
  }
  public getAllDevices(param, page, size) {
    return this.http
      .get(`${this.apiUrl}/api/device/notInGroup`, {
        search: _.assign(this.common.filterEffectParam(param), {
          page,
          size,
        }),
      })
      .toPromise();
  }

  public getDevicesByGroupId(param, page, size) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/device/inGroup`, {
        search: _.assign(this.common.filterEffectParam(param), {
          page,
          size,
        }),
      })
      .toPromise();
  }
  public removeDeviceInGroup(param) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/deviceGroupRela`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }
  public addDeviceInGroup(devicePick: Rela[]) {
    return this.http
      .post(`${this.appConfig.apiUrl}/api/deviceGroupRela`, devicePick)
      .toPromise();
  }

  // 设备型号/名称 模糊查询
  public getModels(param) {
    if (param.groupType) {
      param.deviceTypes = param.groupType;
    }
    return this.http.get(`${this.appConfig.apiUrl}/api/models/stationModels`, {
      search: this.common.filterEffectParam(param),
    });
  }
}
