import { ClassesCategoryModule } from './../classes-category/classes-category.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PaginatorModule,
  ButtonModule,
  InputTextModule,
  DropdownModule,
  MultiSelectModule,
  RadioButtonModule,
  TabViewModule,
  DialogModule,
} from 'primeng/primeng';

import { SharedModule } from '../../../shared/shared.module';
import { AreaObjectComponent } from './area-object/area-object.component';
import { StationObjectComponent } from './station-object/station-object.component';
import { DeviceObjectComponent } from './device-object/device-object.component';
import { ObjectSelectorComponent } from './object-selector.component';
import { ObjectSelectorService } from './object-selector.service';
import { TableModule } from 'primeng/table';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    TableModule,
    PaginatorModule,
    ButtonModule,
    DropdownModule,
    MultiSelectModule,
    InputTextModule,
    RadioButtonModule,
    TabViewModule,
    DialogModule,
    ClassesCategoryModule,
  ],
  declarations: [
    ObjectSelectorComponent,
    StationObjectComponent,
    AreaObjectComponent,
    DeviceObjectComponent,
  ],
  exports: [
    ObjectSelectorComponent,
    AreaObjectComponent,
    StationObjectComponent,
    DeviceObjectComponent,
  ],
  providers: [ObjectSelectorService],
})
export class ObjectSelectorModule {}
