import { Injectable } from '@angular/core';
import { AppConfigService } from '../../../app.config';
import { TreeviewSelection } from './index';
import { TreeviewI18n } from './treeview-i18n';

@Injectable()
export class DefaultTreeviewI18n extends TreeviewI18n {
  constructor(protected appConfig: AppConfigService) {
    super();
  }

  public getText(selection: TreeviewSelection, defaultText): string {
    if (selection.uncheckedItems.length === 0) {
      return this.appConfig.language === 'zh_CN' ? '全部' : 'All';
    }

    switch (selection.checkedItems.length) {
      case 0:
        return this.appConfig.language === 'zh_CN'
          ? defaultText
            ? defaultText
            : '请选择'
          : 'Select options';
      case 1:
        return selection.checkedItems[0].text;
      case 2:
        return `${selection.checkedItems[0].text},${selection.checkedItems[1].text}`;
      case 3:
        return `${selection.checkedItems[0].text},${selection.checkedItems[1].text},${selection.checkedItems[2].text}`;
      default:
        return this.appConfig.language === 'zh_CN'
          ? `已选${selection.checkedItems.length}项`
          : `${selection.checkedItems.length} options selected`;
    }
  }

  public getAllCheckboxText(): string {
    if (this.appConfig.language === 'zh_CN') {
      return '全部';
    } else {
      return 'All';
    }
  }

  public getFilterPlaceholder(): string {
    if (this.appConfig.language === 'zh_CN') {
      return '搜索';
    } else {
      return 'Filter';
    }
  }

  public getFilterNoItemsFoundText(): string {
    if (this.appConfig.language === 'zh_CN') {
      return '暂无数据';
    } else {
      return 'No items found';
    }
  }

  public getTooltipCollapseExpandText(isCollapse: boolean): string {
    return isCollapse
      ? this.appConfig.language === 'zh_CN'
        ? '展开'
        : 'Expand'
      : this.appConfig.language === 'zh_CN'
      ? '收起'
      : 'Collapse';
  }
}
