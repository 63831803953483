import { NgModule } from '@angular/core';
import { DialogModule, ButtonModule } from 'primeng/primeng';

import { SharedModule } from '../../../shared/shared.module';
import { ModelModule } from '../../../components/device-assets/basic-info/model/model.module';
import { ModelSelectorComponent } from './model-selector.component';
import { AssetsBasicInfoService }
  from '../../../components/device-assets/basic-info/basic-info.service';

@NgModule({
  imports: [
    SharedModule,
    DialogModule,
    ButtonModule,
    ModelModule
  ],
  exports: [
    ModelSelectorComponent
  ],
  declarations: [
    ModelSelectorComponent
  ],
  providers: [AssetsBasicInfoService],
})
export class ModelSelectorModule { }
