import { AppConfigService } from './../../../../app.config';
import { Router } from '@angular/router';
import { CacheService } from './../../../../common/services/cache.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/common/services/common.service';
import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnChanges,
  ElementRef,
} from '@angular/core';
import { buildFounderTopologyChart } from './chart/founder-topology.chart';
import { buildDeviceTopologyChart } from './chart/device-topology.chart';
import { buildMapTopologyChart } from './chart/map-topology.chart';
import { SelectItem } from 'primeng/api';
import { PowerStation } from 'src/app/components/monitor/shared/index.model';
import { flatMap, tap } from 'rxjs/operators';
import { Constants } from 'src/app/common/Constants';
import { StationMonitorService } from 'src/app/components/monitor/shared/index.service';
import { StationMonitorService as SMonitorService } from '../../station-monitor.service';
import { TopoService } from './../../../../common/services/topo.service';
import { TopoOverviewService } from 'src/app/components/monitor/topoOverview/topoOverview.service';

import * as _ from 'lodash';
import { Options, Topology, Lock } from '@topology/core';
import { TopoEditorService } from '@components/topo-manager/topo-editor/topo-editor.service';
import { Store } from 'le5le-store';

declare const ht;

@Component({
  selector: 'app-topology',
  templateUrl: './topology.component.html',
  styleUrls: ['./topology.component.less'],
})
export class TopologyComponent implements OnInit, OnChanges {
  @Input() type: string;

  public topoList: SelectItem[] = [];
  public stationId: string;
  public topoId: string;
  public station: PowerStation;
  public selectedTopo: any;
  public drawView: any;
  private toposPromise;
  private topoItemsPromise;
  private graphView;
  @ViewChild('container', { static: false }) container: ElementRef;
  @ViewChild('workspace', { static: false }) workspace: ElementRef;
  canvas: Topology;
  canvasOptions: Options = {
    color: '#b3cee1',
    font: {
      color: '#b3cee1',
    },
  };
  public objs = {};
  public initStationId = null;
  public selectedFounder = null;

  public founderTopologyOptions = null;
  founderTopologyOption: any;
  founderTopologyHeight: any;
  deviceTopologyHeight: string;
  deviceTopologyOptions: {} = null;
  mapTopologyOptions: any = null;
  mapTopologyHeight: string;
  mapTopologyOption: any;
  mapTopologyValue: any;
  returnValue: any;
  theme: any = 'light';
  highTopologyHeight: string;
  intervals: any;
  data: any;

  constructor(
    private common: CommonService,
    private activatedRoute: ActivatedRoute,
    private cacheService: CacheService,
    private router: Router,
    private monitorService: StationMonitorService,
    private stationmonitorservice: SMonitorService,
    private topoService: TopoService,
    private topoOverviewService: TopoOverviewService,
    private config: AppConfigService,
    private service: TopoEditorService
  ) {
    this.theme = this.config.getAppConfig().theme || 'light';
    this.activatedRoute.queryParams.subscribe((params) => {
      // if (params.stationId && this.stationId === params.stationId) {
      //     return;
      // }
      this.stationId = params.stationId
        ? params.stationId
        : this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID);

      if (!this.stationId) {
        return;
      }
      this.initFounderTopology();
    });
  }

  ngOnInit() {
    // this.initFounderTopology();
    this.founderTopologyHeight = this.common.setTableScrollHeight(50);
    this.deviceTopologyHeight = this.common.setTableScrollHeight(50);
    this.mapTopologyHeight = this.common.setTableScrollHeight(50);
    this.highTopologyHeight = this.common.setTableScrollHeight(120);
    // setTimeout(() => {
    //     // this.founderTopologyOptions = buildFounderTopologyChart('00001');
    // }, 1000);
    // setTimeout(() => {
    //     // this.deviceTopologyOptions = buildDeviceTopologyChart('00001');
    // }, 1000);

    this.activatedRoute.queryParams
      .pipe(
        flatMap((params) => {
          if (!this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID)) {
            this.router.navigateByUrl('/yxjs');
            return;
          }
          this.topoList = [];
          if (this.drawView) {
            this.drawView.clear();
          }

          if (params.stationId) {
            this.stationId = params.stationId;
            this.toposPromise = null;
          } else {
            this.stationId = this.cacheService.getCache(
              Constants.KEY_CURRENT_STATION_ID
            );
          }

          if (this.type == 'highTopology') {
            this.topoId = params['topoId'];

            return this.monitorService
              .getStationByStationId(this.stationId)
              .pipe(
                tap((station: any) => {
                  if (!station) {
                    return;
                  }
                  this.station = station;
                })
              );
          }
          return [];
        })
      )
      .subscribe(() => {
        if (this.type == 'highTopology') {
          this.canvas = new Topology(
            this.workspace.nativeElement,
            this.canvasOptions
          );
          this.monitorService
            .getTopologyGraphicByStaId(this.stationId)
            .then((data) => {
              // 初始化下拉框数据
              this.topoList = data.map((item) => {
                return { label: item.topoName, value: item };
              });

              if (this.topoId) {
                let foundTopo = this.topoList.find(
                  (item) => item.value.topoId + '' === this.topoId + ''
                );
                if (foundTopo) {
                  this.selectedTopo = foundTopo.value;
                }
              } else {
                this.selectedTopo = this.topoList[0].value;
              }

              // 拓扑图切换
              // if (this.selectedTopo) {
              //   this.onOpen({ topoId: this.selectedTopo.topoId });
              // }
            });
        }
      });
  }

  ngOnChanges(changes) {
    if (changes.type.currentValue === 'mapTopology') {
      this.stationmonitorservice
        .getDeviceMap({
          stationId: this.stationId,
        })
        .subscribe((res: any) => {
          if (res) {
            this.returnValue = res;
            this.mapTopologyOption = res.datas.map((item) => {
              return { label: item.name, value: item.id };
            });
            const chart = buildMapTopologyChart(this.returnValue, 0);
            chart.on('click', (params) => {
              if (params && params.data && params.data.device) {
                const device = params.data.device;
                this.stationmonitorservice.setDeviceDetail({
                  deviceId: device.deviceId,
                  stationId: device.stationId,
                  deviceType: device.deviceType,
                  showConfig: true,
                });
              }
            });
          }
        });
    } else if (changes.type.currentValue === 'highTopology') {
      setTimeout(() => {
        this.canvas = new Topology(
          this.workspace.nativeElement,
          this.canvasOptions
        );
        this.monitorService
          .getTopologyGraphicByStaId(this.stationId)
          .then((data) => {
            // 初始化下拉框数据
            this.topoList = data.map((item) => {
              return { label: item.topoName, value: item };
            });

            if (this.topoId) {
              let foundTopo = this.topoList.find(
                (item) => item.value.topoId + '' === this.topoId + ''
              );
              if (foundTopo) {
                this.selectedTopo = foundTopo.value;
              }
            } else {
              this.selectedTopo = this.topoList[0].value;
            }

            // 拓扑图切换
            if (this.selectedTopo) {
              this.onOpen({ topoId: this.selectedTopo.topoId });
            }
          });
      }, 3000);
    }
  }

  selectMap(event) {
    const chart = buildMapTopologyChart(
      this.returnValue,
      _.findIndex(this.returnValue.datas, (o: any) => o.id === event.value)
    );
    chart.on('click', (params) => {
      if (params && params.data && params.data.device) {
        const device = params.data.device;
        this.stationmonitorservice.setDeviceDetail({
          deviceId: device.deviceId,
          stationId: device.stationId,
          deviceType: device.deviceType,
          showConfig: true,
        });
      }
    });
  }

  public initFounderTopology() {
    this.initStationId = null;
    this.monitorService
      .getTopoItemsByStationId(this.stationId)
      .subscribe((station: any) => {
        if (!station) {
          return;
        }
        this.founderTopologyOption = [];
        this.objs = _.cloneDeep(station);
        let objs = _.cloneDeep(this.objs);
        for (let i in objs) {
          if (objs.hasOwnProperty(i)) {
            if (objs[i].type === 'station') {
              this.founderTopologyOption.push({
                label: '全站(' + objs[i].childCount + ')',
                value: objs[i].id,
              });
              this.initStationId = objs[i].stationId;
            }
            if (objs[i].type === 'area') {
              this.founderTopologyOption.push({
                label: objs[i].name + '(' + objs[i].childCount + ')',
                value: objs[i].id,
              });
            }
          }
        }
        // 电站切换
        if (this.type === 'founderTopology' || this.type === 'deviceTopology') {
          setTimeout(() => {
            this.founderTopologyOptions = buildFounderTopologyChart(
              this.initStationId,
              _.cloneDeep(this.objs),
              this.theme
            );
            this.deviceTopologyOptions = buildDeviceTopologyChart(
              this.initStationId,
              _.cloneDeep(this.objs),
              this.theme
            );
          }, 1000);
        }
      });
  }

  /**
   * selectArea
   */
  public selectArea(event) {
    this.founderTopologyOptions = buildFounderTopologyChart(
      event.value,
      _.cloneDeep(this.objs),
      this.theme
    );
  }

  ngOnDestroy(): void {
    if (this.intervals) {
      clearInterval(this.intervals);
      this.intervals = null;
    }
  }

  async onOpen(data: { topoId: string }) {
    const res_data = (await this.service.Get(data)) as any;

    let ret: any = {
      topoId: res_data.topoId,
      topoName: res_data.topoName,
      name: res_data.topoName,
      data: JSON.parse(res_data.topoDesc),
    };

    Store.set('recently', {
      topoId: ret.topoId,
      // image: ret.topoImage,
      name: ret.topoName,
    });
    this.data = ret;
    Store.set('lineName', ret.data && ret.data.lineName);
    Store.set('fromArrowType', ret.data && ret.data.fromArrowType);
    Store.set('toArrowType', ret.data && ret.data.toArrowType);
    Store.set('scale', ret.data && ret.data.scale);
    Store.set('locked', ret.data && ret.data.locked);

    this.canvas.open(ret.data);

    this.canvas.lock(Lock.Readonly);
    // this.canvas.lockPens(this.canvas.data.pens, Lock.Readonly);

    this.canvas.fitView(0);

    Store.set('file', this.data);

    if (this.intervals) {
      clearInterval(this.intervals);
      this.intervals = null;
    }

    this.intervalQuery();
    this.intervals = setInterval(() => {
      this.intervalQuery();
    }, 60 * 1000);
  }

  // 下拉框选择事件，更新拓扑
  public changeTopo() {
    if (!this.selectedTopo || !this.selectedTopo.topoId) {
      return;
    }
    this.onOpen({ topoId: this.selectedTopo.topoId });
  }

  private getPointIds() {
    const ids = [];

    this.canvas.data.pens.map((item) => {
      if (item.data[0] && item.data[0].value) {
        ids.push(item.data[0].value);
      }
    });

    return ids;
  }

  public intervalQuery() {
    let ids = this.getPointIds();
    if (ids.length > 0) {
      this.topoOverviewService.getRealvalues(ids).subscribe((res: any) => {
        for (const point of res) {
          if (!_.isNil(point)) {
            this.setPointValue(point.pid, point.v);
          }
        }
      });
    }
  }

  private setPointValue(pointId, value) {
    this.canvas.data.pens
      .filter((item) => {
        return item.data && item.data[0] && item.data[0].value;
      })
      .map((item: any) => {
        if (!item.data[0].props || _.isEmpty(item.data[0].props)) {
          if (+item.data[0].value === +pointId) {
            item.text = value.toFixed(2);
          }
        } else {
          if (+item.data[0].value === +pointId) {
            const activeProp = item.data[0].props.find((prop) => {
              return +prop.key === +value;
            });

            if (activeProp) {
              item.image = '/assets/topo/' + activeProp.value + '.svg';
            } else {
              item.image = '/assets/topo/' + item.data[0].name + '.svg';
            }
          }
        }
      });
    this.canvas.updateProps(true);
  }
}
