import { Component, OnInit, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/common/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { CacheService } from 'src/app/common/services/cache.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Constants } from 'src/app/common/Constants';
import { forkJoin, fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/primeng';

import { StationMonitorService } from '../../station-monitor.service';
import { buildTimeChart } from '../chart/time.chart';
import { buildBarChart } from '../chart/bar.chart';
import { buildLineChart } from '../chart/line.chart';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-confluence-box',
  templateUrl: './confluence-box.component.html',
  styleUrls: ['./confluence-box.component.less'],
})
export class ConfluenceBoxComponent implements OnInit, OnDestroy {
  public deviceType = 'HLX';
  public stationId: any;
  public form: any;
  public areaList: any;
  public modelList: any;
  public runStatusList: any;
  public transformerIdList: any;
  public inverterIdList: any;
  public resizeEvent: any;
  public scrollHeight = '0px';
  public pager = { page: 0, size: 50, totalRecords: 1 };
  public sortBy: any;
  public sortOrder: any;
  public tableloading: boolean = true;
  public dataList = [];
  public toggle: boolean = true;
  public options = null;
  public columns = [];
  public inveterLoading = true;
  public tableTitle = '';
  public defaultSortOrder = 1;
  public timeChartFields = [];
  public lineChartFields = [];
  public barChartFields = [];
  intervalQuery: NodeJS.Timer;
  echartWidth: any;

  constructor(
    private common: CommonService,
    private stationmonitorservice: StationMonitorService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private formBuilder: FormBuilder,
    private tipservice: TipService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
      } else {
        this.stationId = params.stationId;
      }

      this.initForm();
      this.form.patchValue({
        runStatus: this.stationmonitorservice.getRunStatusCache(),
      });
      this.combinerBoxQuery(true);
    });

    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setscrollHeight();
      });
  }

  ngOnInit() {
    this.intervalQuery = setInterval(() => {
      this.combinerBoxQuery();
    }, 1 * 1000 * 60);
  }

  ngOnDestroy(): void {
    if (this.intervalQuery) {
      clearInterval(this.intervalQuery);
      this.intervalQuery = null;
    }
  }

  public setRunStatus(event) {
    this.stationmonitorservice.setRunStatusCache(this.form.value.runStatus);
  }

  public setscrollHeight() {
    let inveterHeight = 50 + 88 + 50 + 29;
    this.scrollHeight = this.common.setTableScrollHeight(inveterHeight);
  }

  public initForm() {
    this.runStatusList = [
      { label: '', value: null },
      { label: '正常', value: 0 },
      { label: '告警', value: 1 },
      { label: '通讯中断', value: 2 },
    ];
    let areaParams = {
      stationId: this.stationId,
    };
    let modelParams = {
      groupType: [this.deviceType],
      stationIds: [this.stationId],
    };
    let transformerParams = {
      deviceTypes: ['BYQ'],
      stationId: this.stationId,
    };
    let inverterParams = {
      deviceTypes: ['JNB', 'NB'],
      stationId: this.stationId,
    };
    forkJoin(
      this.stationmonitorservice.getArea(areaParams),
      this.stationmonitorservice.getModel(modelParams),
      this.stationmonitorservice.getDeviceParents(transformerParams),
      this.stationmonitorservice.getDeviceParents(inverterParams)
    ).subscribe((res) => {
      this.areaList = res[0];
      this.modelList = res[1];
      this.transformerIdList = res[2];
      this.inverterIdList = res[3];
    });

    this.form = this.formBuilder.group({
      areaIds: new FormControl(''),
      modelIds: new FormControl(''),
      runStatus: new FormControl(''),
      transformerIds: new FormControl(''),
      inverterIds: new FormControl(''),
      deviceName: new FormControl(''),
    });

    this.setscrollHeight();
  }

  public combinerBoxQuery(first?) {
    if (first) {
      this.queryTable(true);
      this.buildChart(null);
    } else {
      this.pager.page = 0;
      this.refreshData();
    }
  }

  public queryTable(first?) {
    if (first) {
      this.pager.page = 0;
      this.sortBy = 'AM-HLX-PVI-INPUT.PV01';
      this.sortOrder = 'asc';
    }
    let params = _.assign({}, this.form.value, {
      page: this.pager.page,
      size: this.pager.size,
      stationId: this.stationId,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
    this.tableloading = true;
    this.stationmonitorservice
      .getCombinerBoxDatas(params)
      .subscribe((res: any) => {
        this.tableloading = false;
        this.timeChartFields = [];
        this.lineChartFields = [];
        this.barChartFields = [];
        if (res) {
          // this.sortBy = res.sortBy;
          // this.sortOrder = res.sortOrder;
          // this.defaultSortOrder = res.sortOrder === 'asc' ? 1 : -1;
          this.columns = res.headers;
          this.dataList = res.datas.content;
          if (
            this.dataList.length === 0 &&
            params.deviceName &&
            !params.areaIds &&
            !params.modelIds &&
            !params.transformerIds &&
            !params.inverterIds &&
            !params.runStatus
          ) {
            this.tipservice.tip(
              'warn',
              `当前电站没有【${params.deviceName}】设备`,
              '查询设备提示'
            );
          }
          this.pager.totalRecords = res.datas.totalElements;
          for (let header of res.headers) {
            if (header.chart === 'time') {
              this.timeChartFields.push(header.code);
            }
            if (header.chart === 'line') {
              this.lineChartFields.push(header.code);
            }
            if (header.chart === 'bar') {
              this.barChartFields.push(header.code);
            }
          }
        }
      });
  }

  // 创建逆变器chart
  public buildChart(column?) {
    this.inveterLoading = true;
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
      column,
    });

    this.stationmonitorservice
      .getCombinerBoxCharts(params)
      .subscribe((res: any) => {
        this.echartWidth = (
          document.querySelector('.list-content') as any
        ).offsetWidth;
        this.inveterLoading = false;
        this.tableTitle = res.title;
        if (this.timeChartFields.indexOf(column) !== -1) {
          this.options = buildTimeChart(res);
        }

        if (this.barChartFields.indexOf(column) !== -1 || column === null) {
          this.options = buildBarChart(res);
        }

        if (this.lineChartFields.indexOf(column) !== -1) {
          this.options = buildLineChart(res);
        }
        // this.toggle = false;
      });
  }

  public customSort(event: LazyLoadEvent) {
    this.sortBy = event.sortField;
    this.sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
    this.refreshData();
  }

  public refreshData() {
    this.queryTable();

    if (
      this.timeChartFields.indexOf(this.sortBy) !== -1 ||
      this.lineChartFields.indexOf(this.sortBy) !== -1 ||
      this.barChartFields.indexOf(this.sortBy) !== -1
    ) {
      this.toggle = true;
      this.buildChart(this.sortBy);
    }
  }

  /**
   * onPageChange
   */
  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryTable();
  }

  /**
   * export
   */
  public export() {
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
    });
    this.stationmonitorservice.combinerBoxExport(params);
  }

  /**
   * deviceDetail
   */
  public deviceDetail(deviceId, stationId, deviceType) {
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });
  }
}
