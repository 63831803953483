import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { TabType } from './searchbox.model';
import { PowerStation } from '../../../monitor/shared/index.model';

import { SearchBoxService } from './searchbox.service';
import { CacheService } from '../../../../common/services/cache.service';
import { Constants } from '../../../../common/Constants';

@Component({
  selector: 'mpt-searchbox',
  templateUrl: 'searchbox.component.html',
  styleUrls: ['./searchbox.component.less']
})

export class SearchBoxComponent implements OnInit, OnDestroy {
  public text: string;
  public searchResult;

  public menu = [];
  public station: PowerStation[] = [];
  public device = [];
  public contact = [];

  public tabType = TabType;

  public activeIndex: number = 0;

  public constructor(
    private searchBoxService: SearchBoxService,
    private _ref: ElementRef,
    private router: Router,
    private cacheService:CacheService
  ) {
  }

  public ngOnInit() { }

  public ngOnDestroy() {
  }

  public search() {
    if (!this.text) {
      this.resetSearchResult();
      return;
    }
    this.searchBoxService.search(this.text.trim()).subscribe((res) => {
      this.searchResult = res;
      this.menu = this.searchResult ? this.searchResult[this.tabType.menu] : [];
      this.station = this.searchResult ? this.searchResult[this.tabType.station] : [];
      this.device = this.searchResult ? this.searchResult[this.tabType.device] : [];
      this.contact = this.searchResult ? this.searchResult[this.tabType.contact] : [];
      this.setActiveIndex();
    });
  }

  public getResultCount(type: string) {
    if (!this[type]) {
      return 0;
    }
    return this[type].length ? this[type].length : 0;
  }

  public getTabHeader(type: string) {
    let tabName = this.getTabName(type);
    let count = '';
    if (this.getResultCount(type)) {
      count = ` (${this.getResultCount(type)})`;
    } else {
      count = '';
    }
    return tabName + count;
  }

  public getMonitorLink(station: PowerStation) {
    this.cacheService.setCache(Constants.KEY_CURRENT_STATION_ID, station.stationId, true);
    return `/dashboard?stationId=${station.stationId}`;
  }

  public getDeviceLink(device) {
    this.cacheService.setCache(Constants.KEY_CURRENT_STATION_ID, device.stationId, true);
    return `/deviceMonitorNew/${device.deviceId}`;
  }

  public clickItem(url) {
    this.router.navigateByUrl(url);
  }

  public setActiveIndex() {
    let allTab = [this.menu, this.station, this.device, this.contact];
    for (let i = 0; i < allTab.length; i++) {
      if (allTab[i] && allTab[i].length > 0) {
        this.activeIndex = i;
        return;
      }
    }
    this.activeIndex = 0;
    return;
  }

  private resetSearchResult() {
    this.menu = [];
    this.station = [];
    this.device = [];
    this.contact = [];
  }

  private getTabName(type: TabType) {// TODO liqian translate
    switch (type) {
      case TabType.menu:
        return '功能';
      case TabType.station:
        return '电站';
      case TabType.device:
        return '设备';
      case TabType.contact:
        return '联系人';
      default:
        return '';
    }
  }

  private getStationPath(station: PowerStation) {
    if (!station) {
      return '';
    }
    let path = [];
    if (station.contryName) {
      path.push(station.contryName);
    }
    if (station.provinceName) {
      path.push(station.provinceName);
    }
    if (station.cityName) {
      path.push(station.cityName);
    }
    if (station.contryName) {
      path.push(station.contryName);
    }
    return path.join(' > ');
  }

  private getDevicePath(device) {
    if (!device) {
      return '';
    }
    let path = [];
    if (device.shortName) {
      path.push(device.shortName);
    }
    if (device.className) {
      path.push(device.className);
    }
    if (device.deviceName) {
      path.push(device.deviceName);
    }
    if (device.areaName) {
      path.push(device.areaName);
    }
    return path.join(' | ');
  }

}
