import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { TreeNode } from 'primeng/primeng';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommonService } from 'src/app/common/services/common.service';
import { AppConfigService } from '../../../app.config';
import { AuthHttp } from '../../../core/auth-http';
import { DeptInfo } from './depts.model';

@Injectable()
export class DeptsService {
  private dataChangedEvent = new Subject<DeptInfo>();

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private commonservice: CommonService
  ) {}

  public fireAddOrUpdateEvent(data: DeptInfo): void {
    this.dataChangedEvent.next(data);
  }

  public onAddOrUpdateEvent(next?: (value: DeptInfo) => void): void {
    this.dataChangedEvent.subscribe({
      next,
    });
  }

  public getDeptsTree(params?): Observable<any> {
    return this.http
      .get<TreeNode[]>(`${this.appConfig.apiUrl}/api/nems/depts/tree`, {
        params,
      })
      .pipe(
        map((res) => {
          return this.expandedAll(res);
        })
      );
  }

  private expandedAll(data) {
    return data.map((item) => {
      return {
        ...item,
        expanded: true,
        children: !_.isEmpty(item.children)
          ? this.expandedAll(item.children)
          : [],
      };
    });
  }

  public getDeptInfo(id: string): Observable<DeptInfo> {
    return this.http.get(`${this.appConfig.apiUrl}/api/nems/depts/${id}`);
  }
  public getDeptUserInfo(params): Observable<DeptInfo> {
    return this.http.get(`${this.appConfig.apiUrl}/api/nems/users`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public addDept(depts): Observable<DeptInfo> {
    return this.http.post(`${this.appConfig.apiUrl}/api/nems/depts`, depts);
  }

  public updateDept(dept: DeptInfo): Observable<DeptInfo> {
    return this.http.put(`${this.appConfig.apiUrl}/api/nems/depts`, dept);
  }

  public deleteDept(deptId: string): Observable<DeptInfo[]> {
    return this.http.delete(`${this.appConfig.apiUrl}/api/nems/depts`, {
      params: { deptId },
    });
  }

  public transferUser(params) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/nems/depts/users`,
      null,
      {
        params,
      }
    );
  }

  public exportDept() {
    return this.commonservice.fileExport(
      `${this.appConfig.apiUrl}/api/nems/depts/export`
    );
  }
}
