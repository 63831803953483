import * as _ from 'lodash';

export function buildPieChart(data) {
  return {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    series: [
      {
        type: 'pie',
        center: ['10%', '50%'],
        radius: [35, 45],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: '{total|10}\n\r{desc|运行}',
            rich: {
              total: {
                fontSize: 14,
                fontFamily: '微软雅黑',
                color: '#fff',
              },
              desc: {
                fontFamily: '微软雅黑',
                fontSize: 12,
                color: '#fff',
                lineHeight: 30,
              },
            },
          },
        },
        hoverOffset: 0,
        labelLine: { normal: { show: false } },
        data: [
          {
            value: 9,
            itemStyle: {
              color: '#0e9c98',
            },
          },
          {
            value: 1,
            itemStyle: {
              color: '#0e3a48',
            },
          },
        ],
      },
      {
        type: 'pie',
        center: ['30%', '50%'],
        radius: [35, 45],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: '{total|1}\n\r{desc| 检修}',
            rich: {
              total: {
                fontSize: 14,
                fontFamily: '微软雅黑',
                color: '#fff',
              },
              desc: {
                fontFamily: '微软雅黑',
                fontSize: 12,
                color: '#fff',
                lineHeight: 30,
              },
            },
          },
        },
        hoverOffset: 0,
        labelLine: { normal: { show: false } },
        data: [
          {
            value: 1,
            itemStyle: {
              color: '#fe946f',
            },
          },
          {
            value: 9,
            itemStyle: {
              color: '#0e3a48',
            },
          },
        ],
      },
      {
        type: 'pie',
        center: ['50%', '50%'],
        radius: [35, 45],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: '{total|0}\n\r{desc|故障}',
            rich: {
              total: {
                fontSize: 14,
                fontFamily: '微软雅黑',
                color: '#fff',
              },
              desc: {
                fontFamily: '微软雅黑',
                fontSize: 12,
                color: '#fff',
                lineHeight: 30,
              },
            },
          },
        },
        hoverOffset: 0,
        labelLine: { normal: { show: false } },
        data: [
          {
            value: 0,
            itemStyle: {
              color: '#fe946f',
            },
          },
          {
            value: 1,
            itemStyle: {
              color: '#0e3a48',
            },
          },
        ],
      },
      {
        type: 'pie',
        center: ['70%', '50%'],
        radius: [35, 45],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: '{total|0}\n\r{desc|待机}',
            rich: {
              total: {
                fontSize: 14,
                fontFamily: '微软雅黑',
                color: '#fff',
              },
              desc: {
                fontFamily: '微软雅黑',
                fontSize: 12,
                color: '#fff',
                lineHeight: 30,
              },
            },
          },
        },
        hoverOffset: 0,
        labelLine: { normal: { show: false } },
        data: [
          {
            value: 0,
            itemStyle: {
              color: '#fe946f',
            },
          },
          {
            value: 1,
            itemStyle: {
              color: '#0e3a48',
            },
          },
        ],
      },
      {
        type: 'pie',
        center: ['90%', '50%'],
        radius: [35, 45],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: '{total|0}\n\r{desc|离线}',
            rich: {
              total: {
                fontSize: 14,
                fontFamily: '微软雅黑',
                color: '#fff',
              },
              desc: {
                fontFamily: '微软雅黑',
                fontSize: 12,
                color: '#fff',
                lineHeight: 30,
              },
            },
          },
        },
        hoverOffset: 0,
        labelLine: { normal: { show: false } },
        data: [
          {
            value: 0,
            itemStyle: {
              color: '#fe946f',
            },
          },
          {
            value: 1,
            itemStyle: {
              color: '#0e3a48',
            },
          },
        ],
      },
    ],
  };
}

export function buildSinglePieChart(value, color) {
  return {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    series: [
      {
        type: 'pie',
        center: ['50%', '50%'],
        radius: [35, 45],
        avoidLabelOverlap: false,
        label: {
          normal: {
            show: true,
            position: 'center',
            formatter: `{total|${value}%}`,
            rich: {
              total: {
                fontSize: 14,
                fontFamily: '微软雅黑',
                color: '#fff',
              },
              desc: {
                fontFamily: '微软雅黑',
                fontSize: 12,
                color: '#fff',
                lineHeight: 30,
              },
            },
          },
        },
        hoverOffset: 0,
        labelLine: { normal: { show: false } },
        data: [
          {
            value: value,
            itemStyle: {
              color: color,
            },
          },
          {
            value: 100 - value,
            itemStyle: {
              color: '#0e3a48',
            },
          },
        ],
      },
    ],
  };
}
