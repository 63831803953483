import { OperateInspectionComponent } from '../operate-inspection/operate-inspection.component';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import {
  PaginatorModule,
  CalendarModule,
  InputTextModule,
  InputTextareaModule,
  MultiSelectModule,
  LightboxModule,
  ConfirmDialogModule,
  TieredMenuModule,
  CheckboxModule,
  FieldsetModule,
  SpinnerModule,
  FileUploadModule,
  RadioButtonModule,
  ConfirmationService,
} from 'primeng/primeng';
import { ButtonModule } from 'primeng/button';
import { StationSelectorModule } from 'src/app/common/components/station-selector/station-selector.module';
import { UserSelectorModule } from 'src/app/common/components/user-selector/user-selector.module';
import { ObjectSelectorModule } from 'src/app/common/components/object-selector/object-selector.module';
import { DialogModule } from 'primeng/dialog';
import { CommentModule } from 'src/app/common/components/comment/comment.module';
import { CcUsersModule } from 'src/app/common/components/cc-users/cc-users.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { InspectionDeviceDetailsComponent } from '../inspection-device-details/inspection-device-details.component';
import { InspectionDeviceItemsComponent } from '../inspection-device-items/inspection-device-items.component';
import { OperateDefectModule } from '../../defect/defect-manage/operate-defect/operate-defect.module';
import { FileService } from 'src/app/common/services/file.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { InspectionsService } from '../inspections.service';

const primengModule = [
  TableModule,
  PaginatorModule,
  CalendarModule,
  InputTextModule,
  InputTextareaModule,
  ButtonModule,
  MultiSelectModule,
  StationSelectorModule,
  UserSelectorModule,
  ObjectSelectorModule,
  LightboxModule,
  ConfirmDialogModule,
  TieredMenuModule,
  DialogModule,
  CheckboxModule,
  FieldsetModule,
  CommentModule,
  CcUsersModule,
  SpinnerModule,
  FileUploadModule,
  RadioButtonModule,
];

@NgModule({
  imports: [...primengModule, SharedModule, OperateDefectModule],
  declarations: [
    OperateInspectionComponent,
    InspectionDeviceDetailsComponent,
    InspectionDeviceItemsComponent,
  ],
  exports: [
    OperateInspectionComponent,
    InspectionDeviceDetailsComponent,
    InspectionDeviceItemsComponent,
  ],
  providers: [CalendarLocales, InspectionsService, ConfirmationService, FileService],
})
export class InspectionsSharedModule {}
