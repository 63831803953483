import * as moment from 'moment';

export function buildTimeChart(returnValue) {
    //本地预置好的echart.option初始值
    let option = {
        color: ['rgba(0,0,0,0)', '#37A2DA', '#FF9F7F', '#FF9F7F'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
            left: '3%',
            width: '92%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function (param) {
                var start = null,
                    end = null,
                    duration = null;
                if (param[0].value != null) {
                    start = new Date(returnValue.dateBegin + param[0].value);
                    end = new Date(
                        returnValue.dateBegin + param[0].value + param[1].value
                    );
                    var tip =
                        param[0].name +
                        '</br>开机时间：' +
                        moment(start).format('hh:mm:ss') +
                        '</br>停机时间：' +
                        (returnValue.stopFlag[param[0].dataIndex]
                            ? moment(end).format('hh:mm:ss')
                            : '');
                    if (param[4]) {
                        tip += '</br>运行时长：' + param[4].value + ' h';
                    }
                    return tip;
                } else {
                    return param[0].name;
                }
            }
        },
        toolbox: {
            feature: {
                dataZoom: {},
                restore: {},
                dataView: {},
                saveAsImage: {}
            }
        },
        dataZoom: [
            {
                type: 'inside',
                realtime: true
            },
            {
                realtime: true
            }
        ],
        legend: {
            show: true,
            data: returnValue.option.legend.data
        },
        xAxis: {
            type: 'category',
            data: returnValue.option.xAxis.data,
            axisLabel: { show: false }
        },
        yAxis: [
            {
                name: null,
                type: 'value',
                axisLabel: {
                    formatter: function (value) {
                        var t = new Date(returnValue.dateBegin + value);
                        return moment(t).format('HH:mm');
                    }
                }
            },
            {
                name: 'h',
                type: 'value',
                splitLine: { show: false }
            }
        ],
        series: returnValue.option.series
    };
    return option;
}
