import { TipService } from './../../../common/services/tip.service';
import { Router } from '@angular/router';
import { BreadcrumbService } from './../../layout/breakcrumb/breadcrumb.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { CacheService } from './../../../common/services/cache.service';
import { ActivatedRoute } from '@angular/router';
import { StationMonitorService } from './../station-monitor.service';
import { forkJoin, fromEvent } from 'rxjs';
import { CommonService } from './../../../common/services/common.service';
import { LazyLoadEvent } from 'primeng/primeng';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { buildPowerChart } from './chart/power.chart';
import { buildTimeChart } from './chart/time.chart';
import { Constants } from 'src/app/common/Constants';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';
import { buildMeterChart } from './chart/meter.chart';
import { CodeTableService } from 'src/app/core/code-table.service';
import { buildBarChart } from '../device-list/chart/bar.chart';
import { buildLineChart } from '../device-list/chart/line.chart';

@Component({
  selector: 'app-energy',
  templateUrl: './energy.component.html',
  styleUrls: ['./energy.component.less'],
})
export class EnergyComponent implements OnInit, OnDestroy {
  public canlendarLocale: any;

  public stationId: any;

  public backItem: any;
  public tabType = 'inveter';

  public inveterForm: any;
  public inveterGroupList: any;
  public inveterAreaList: any;
  public inveterModelList: any;
  public inveterDate: any = moment().format('YYYY-MM-DD');
  public inveterTableTitle = '';
  public inveterToggle: boolean = true;

  public inveterOptions = null;
  public inveterLoading = true;

  public inveterResizeEvent: any;
  public inveterDataList = [];

  public inveterFrozenCols = [
    { header: '逆变器', field: 'deviceNo', weightNormal: true },
  ];

  public inveterColumns = [
    { header: '位置', field: 'areaName', weightNormal: true, width: '120px' },
    {
      header: '装机容量 KW',
      field: 'capacity',
      weightNormal: true,
      width: '130px',
    },
    // { header: '型号', field: 'modelNo', weightNormal: true },
    {
      header: '通信状态',
      field: 'commuStatus',
      weightNormal: true,
      width: '100px',
    },
    { header: '日发电量 kWh', field: 'energyD', width: '130px' },
    { header: '日等效时数 h', field: 'kwhkwp', width: '130px' },
    {
      header: '实时告警',
      field: 'realAlarms',
      weightNormal: true,
      width: '130px',
    },
    { header: '实时功率 KW', field: 'power', width: '130px' },
    { header: '日最大功率 KW', field: 'maxPower', width: '140px' },

    { header: '开机时间', field: 'startTime', width: '100px' },
    { header: '停机时间', field: 'endTime', width: '100px' },
    { header: '运行时长 h', field: 'runTimes', width: '110px' },
  ];
  public inveterScrollHeight;

  public inveterSortBy: any = 'kwhkwp';
  public inveterSortOrder: any = 'desc';
  public inveterTableloading: boolean = true;

  public inveterPager = { page: 0, size: 50, totalRecords: 4 };

  public meterForm: any;
  public meterGroupList: any;
  public meterAreaList: any;
  public meterModelList: any;
  public meterDate: any = moment().format('YYYY-MM-DD');
  public meterTableTitle = '';
  public meterToggle: boolean = true;

  public meterOptions = null;
  public meterLoading = true;

  public meterResizeEvent: any;
  public meterDataList = [];

  public meterScrollHeight = '0px';

  public meterSortBy: any = 'kwhkwp';
  public meterSortOrder: any = 'asc';
  public meterTableloading: boolean = true;

  public meterPager = { page: 0, size: 50, totalRecords: 4 };
  public meterTypesList: any;
  public meterColumns = [];
  public timeChartFields = [];
  public lineChartFields = [];
  public barChartFields = [];
  meterFrozenCols: any;
  intervalQuery: NodeJS.Timer;

  constructor(
    private calendarLocales: CalendarLocales,
    private common: CommonService,
    private stationmonitorservice: StationMonitorService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private codetableservice: CodeTableService,
    private formBuilder: FormBuilder,
    private router: Router,
    private breadcrumbservice: BreadcrumbService,
    private tipservice: TipService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
      } else {
        this.stationId = params.stationId;
      }

      if (params.sortBy) {
        this.inveterSortBy = params.sortBy;
      }

      if (this.tabType == 'inveter') {
        this.initInveterform();
        this.inveterToggle = true;
        this.inveterQuery(true);
      } else {
        this.initMeterform();
        this.meterToggle = true;
        this.meterQuery(true);
      }
    });

    this.inveterResizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setInveterScrollHeight();
        this.setMeterScrollHeight();
      });
  }

  ngOnInit() {
    this.intervalQuery = setInterval(() => {
      if (this.tabType == 'inveter') {
        this.initInveterform();
        this.inveterToggle = true;
        this.inveterQuery(true);
      } else {
        this.initMeterform();
        this.meterToggle = true;
        this.meterQuery(true);
      }
    }, 1 * 1000 * 60);
  }

  ngOnDestroy() {
    this.common.removeBackItem();
    if (this.intervalQuery) {
      clearInterval(this.intervalQuery);
      this.intervalQuery = null;
    }
  }

  public setInveterScrollHeight() {
    const inveterHeight = 50 + 88 + 50 + 29 + 90;
    this.inveterScrollHeight = this.common.setTableScrollHeight(inveterHeight);
  }

  public setMeterScrollHeight() {
    const meterHeight = 50 + 44 + 50 + 57 + 100;
    this.meterScrollHeight = this.common.setTableScrollHeight(meterHeight);
  }

  //初始化form
  public initInveterform() {
    let groupParams = {
      groupType: '01',
      stationId: this.stationId,
    };
    let areaParams = {
      stationId: this.stationId,
    };
    let modelParams = {
      groupType: ['NB', 'JNB'],
      stationIds: [this.stationId],
    };
    forkJoin(
      this.stationmonitorservice.getDeviceGroups(groupParams),
      this.stationmonitorservice.getArea(areaParams),
      this.stationmonitorservice.getModel(modelParams),
      this.codetableservice.getNemsProp('meterType')
    ).subscribe((res) => {
      this.inveterGroupList = res[0];
      this.inveterAreaList = res[1];
      this.inveterModelList = res[2];
      this.meterTypesList = res[3];
    });

    this.inveterForm = this.formBuilder.group({
      groupId: new FormControl(''),
      areaIds: new FormControl(''),
      modelIds: new FormControl(''),
      deviceName: new FormControl(''),
      date: new FormControl(''),
    });

    this.backItem = this.common.getBackItem();
    if (this.backItem && this.backItem.params && this.backItem.params.date) {
      this.inveterDate = this.backItem.params.date;
    }
    this.inveterForm.patchValue({
      date: this.inveterDate,
    });

    this.setInveterScrollHeight();
  }

  public initMeterform() {
    this.meterForm = this.formBuilder.group({
      meterTypes: null,
      date: new FormControl(''),
    });

    this.backItem = this.common.getBackItem();
    if (this.backItem && this.backItem.params && this.backItem.params.date) {
      this.meterDate = this.backItem.params.date;
    }
    this.meterForm.patchValue({
      date: this.meterDate,
    });
    this.setMeterScrollHeight();
  }

  /**
   * tabClick
   */
  public tabClick(type) {
    if (type == 'inveter') {
      this.initInveterform();
      this.inveterQuery(true);
    } else {
      this.initMeterform();
      this.meterQuery(true);
    }
    this.tabType = type;
  }

  public inveterQuery(first?) {
    if (first) {
      this.queryInveterTable(true);
      this.buildInveterChart(this.inveterSortBy);
    }
  }

  public queryInveterTable(first?) {
    if (first) {
      this.inveterPager.page = 0;
      if (!this.inveterSortBy) {
        this.inveterSortBy = 'kwhkwp';
      }
      // this.inveterSortBy = 'kwhkwp';
      // this.inveterSortOrder = 'desc';
    }
    let params = _.assign({}, this.inveterForm.value, {
      page: this.inveterPager.page,
      size: this.inveterPager.size,
      stationId: this.stationId,
      sortBy: this.inveterSortBy,
      sortOrder: this.inveterSortOrder,
      date: moment(this.inveterForm.value.date).format('YYYY-MM-DD'),
    });
    this.inveterTableloading = true;
    this.stationmonitorservice.getInveterDatas(params).subscribe((res: any) => {
      this.inveterTableloading = false;
      if (res) {
        this.inveterDataList = res.content;

        if (
          this.inveterDataList.length === 0 &&
          params.deviceName &&
          !params.groupId &&
          !params.areaIds &&
          !params.modelIds
        ) {
          this.tipservice.tip(
            'warn',
            `当前电站没有【${params.deviceName}】设备`,
            '查询设备提示'
          );
        }

        this.inveterPager.totalRecords = res.totalElements;
      }
    });
  }

  //创建逆变器chart
  public buildInveterChart(column?) {
    this.inveterLoading = true;
    let params = _.assign({}, this.inveterForm.value, {
      stationId: this.stationId,
      column,
      date: moment(this.inveterForm.value.date).format('YYYY-MM-DD'),
    });
    if (
      column == 'power' ||
      column == 'maxPower' ||
      column == 'energyD' ||
      column == 'kwhkwp'
    ) {
      this.stationmonitorservice
        .getInveterCharts(params)
        .subscribe((res: any) => {
          this.inveterLoading = false;
          this.inveterTableTitle = res.title;
          this.inveterOptions = buildPowerChart(res);
        });
    } else {
      this.stationmonitorservice
        .getInveterCharts(params)
        .subscribe((res: any) => {
          this.inveterLoading = false;
          this.inveterTableTitle = res.title;
          this.inveterOptions = buildTimeChart(res);
        });
    }
  }

  public inveterCustomSort(event: LazyLoadEvent) {
    this.inveterSortBy = event.sortField;
    this.inveterSortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
    this.queryInveterTable();
    if (
      this.inveterSortBy == 'power' ||
      this.inveterSortBy == 'maxPower' ||
      this.inveterSortBy == 'energyD' ||
      this.inveterSortBy == 'kwhkwp' ||
      this.inveterSortBy == 'startTime' ||
      this.inveterSortBy == 'endTime' ||
      this.inveterSortBy == 'runTimes'
    ) {
      this.inveterToggle = true;
      this.buildInveterChart(this.inveterSortBy);
    }
  }

  /**
   * onPageChange
   */
  public onInveterPageChange(event) {
    this.inveterPager.size = event.rows;
    this.inveterPager.page = event.page;
    this.queryInveterTable();
  }

  /**
   * export
   */
  public inveterExport() {
    let params = _.assign({}, this.inveterForm.value, {
      stationId: this.stationId,
    });
    this.stationmonitorservice.inveterExport(params);
  }

  public meterQuery(first?) {
    if (first) {
      this.queryMeterTable(true);
      this.buildMeterChart(null);
    } else {
      this.meterPager.page = 0;
      this.refreshData();
    }
  }

  public queryMeterTable(first?) {
    if (first) {
      this.meterPager.page = 0;
      this.meterSortBy = 'AM-DNB-ZXYGZDN';
      this.meterSortOrder = 'asc';
    }
    let params = _.assign({}, this.meterForm.value, {
      page: this.meterPager.page,
      size: this.meterPager.size,
      stationId: this.stationId,
      sortBy: this.meterSortBy,
      sortOrder: this.meterSortOrder,
      date: moment(this.meterForm.value.date).format('YYYY-MM-DD'),
    });
    this.meterTableloading = true;
    this.stationmonitorservice.getEMeterDatas(params).subscribe((res: any) => {
      this.meterTableloading = false;
      this.timeChartFields = [];
      this.lineChartFields = [];
      this.barChartFields = [];
      if (res) {
        // this.meterFrozenCols = [res.headers[0]];
        // console.log(this.meterFrozenCols);
        // res.headers.shift();
        this.meterColumns = res.headers;
        this.meterDataList = res.datas.content;
        this.meterPager.totalRecords = res.datas.totalElements;
        for (let header of res.headers) {
          if (header.chart === 'time') {
            this.timeChartFields.push(header.code);
          }
          if (header.chart === 'line') {
            this.lineChartFields.push(header.code);
          }
          if (header.chart === 'bar') {
            this.barChartFields.push(header.code);
          }
        }
      }
    });
  }

  // 创建逆变器chart
  public buildMeterChart(column?) {
    this.meterLoading = true;
    let params = _.assign({}, this.meterForm.value, {
      stationId: this.stationId,
      column,
      date: moment(this.meterForm.value.date).format('YYYY-MM-DD'),
    });
    this.stationmonitorservice.getEMeterCharts(params).subscribe((res: any) => {
      this.meterLoading = false;
      this.meterTableTitle = res.title;
      if (this.timeChartFields.indexOf(column) !== -1) {
        this.meterOptions = buildTimeChart(res);
      }

      if (this.barChartFields.indexOf(column) !== -1) {
        this.meterOptions = buildBarChart(res);
      }

      if (this.lineChartFields.indexOf(column) !== -1 || column === null) {
        this.meterOptions = buildLineChart(res);
      }
    });
  }

  public meterCustomSort(event: LazyLoadEvent) {
    this.meterSortBy = event.sortField;
    this.meterSortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
    this.refreshData();
  }

  public refreshData() {
    this.queryMeterTable();

    if (
      this.timeChartFields.indexOf(this.meterSortBy) !== -1 ||
      this.lineChartFields.indexOf(this.meterSortBy) !== -1 ||
      this.barChartFields.indexOf(this.meterSortBy) !== -1
    ) {
      this.meterToggle = true;
      this.buildMeterChart(this.meterSortBy);
    }
  }

  /**
   * onPageChange
   */
  public onMeterPageChange(event) {
    this.meterPager.size = event.rows;
    this.meterPager.page = event.page;
    this.queryMeterTable();
  }

  /**
   * export
   */
  public meterExport() {
    let params = _.assign({}, this.meterForm.value, {
      stationId: this.stationId,
      date: moment(this.meterForm.value.date).format('YYYY-MM-DD'),
    });
    this.stationmonitorservice.eMeterExport(params);
  }

  public setDevice(device) {
    this.breadcrumbservice.setStation(
      {
        stationId:
          device.stationId ||
          this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID),
        shortName:
          device.shortName ||
          this.cacheService.getCache(Constants.KEY_CURRENT_STATION_NAME),
      },
      '/monitorStation/deviceList',
      {
        deviceId: device.deviceId,
        stationId:
          device.stationId ||
          this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID),
        deviceType: device.deviceType,
      }
    );
  }

  public goAlarm(deviceName) {
    this.router.navigate(['/alarmManage/realTime'], {
      queryParams: {
        stationId: this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID),
        deviceName,
      },
    });
  }
}
