import { AppConfigService } from 'src/app/app.config';
import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { SelectItem } from 'primeng/api';
import { OverlayPanel } from 'primeng/primeng';
import { forkJoin, fromEvent } from 'rxjs';
import { NavService } from 'src/app/components/layout/nav/nav.service';
import { CodeTableService } from 'src/app/core/code-table.service';
import { FullLayoutComponent } from '../../../layouts/full-layout/full-layout.component';
import { StationMonitorService } from '../../station-monitor/station-monitor.service';
import { CacheService } from './../../../common/services/cache.service';
import { CommonService } from './../../../common/services/common.service';
import { AuthService } from './../../../core/auth.service';
import { BreadcrumbService } from './../breakcrumb/breadcrumb.service';
import { TopbarService } from './app.topbar.service';
import { Location } from '@angular/common';
import { TipService } from '@common/services/tip.service';
declare const $;

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.less'],
})
export class AppTopBarComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public menus = [];
  @Input() public logo: any = {};
  @Input() public homePageUrl = '';

  @ViewChild('audioOption', { static: false }) audioPlayerRef: ElementRef;
  app = {};
  searchKeyWord = '';

  tabActive = 'station';

  // 页面样式弹窗
  pageStyleDisplay = false;

  groupedOptions: SelectItem[] = [
    { label: '分组', value: true },
    { label: '不分组', value: false },
  ];
  grouped: any = true;

  layoutOptions: SelectItem[] = [
    { label: '隐藏', value: 'overlay' },
    { label: '展开', value: 'static' },
    { label: '收缩', value: 'slim' },
    { label: '横向', value: 'horizontal' },
  ];
  layout: any = 'overlay';

  colorOptions: SelectItem[] = [
    { label: '亮', value: 'light' },
    { label: '暗', value: 'dark' },
  ];
  color: any = 'light';

  stationCount = 0;
  deviceCount = 0;
  orderCount = 0;
  keywordList: any[] = [];
  stations: any;
  devices: any;
  orders: any;
  suggestionShow = false;
  alarms: any = [];
  elem: any;
  start: number;
  times: any;
  playAudioSub: any;
  alarmBadge = false;
  messagesBadge = false;

  public pager = { page: 0, totalRecords: 0, size: 5 };
  messages: any = [];
  userProfile: any = {};
  userInfoDisplay = false;
  userInfoForm: any;
  genders: SelectItem[];
  jobs: SelectItem[];
  userAvatar = 'assets/avatar/00.png';
  userInfoEdit = true;

  userInfoLoading = true;

  changePasswordDisplay = false;

  avatarList = [
    '01.png',
    '02.png',
    '03.png',
    '04.png',
    '05.png',
    '06.png',
    '07.png',
    '08.png',
    '09.png',
    '10.png',
    '11.png',
    '12.png',
    '13.png',
    '14.png',
    '15.png',
    '16.png',
    '17.png',
    '18.png',
    '19.png',
    '20.png',
  ];
  userDeptId: any;
  originalProfile: any;

  oldPassword = '';
  newPassword = '';
  newPasswordSecond = '';
  alarmIntervalTimer: any;
  messagesIntervalTimer: any;
  voiceTimes: any;
  alarmOverlay = false;
  marquee: any;
  topbarBg: any;

  constructor(
    // public app: FullLayoutComponent,
    private cacheService: CacheService,
    private authService: AuthService,
    private router: Router,
    private navservice: NavService,
    private topbarservice: TopbarService,
    private common: CommonService,
    private formBuilder: FormBuilder,
    private codetableservice: CodeTableService,
    private breadcrumbservice: BreadcrumbService,
    private stationmonitorservice: StationMonitorService,
    private location: Location,
    private tipservice: TipService,
    private appConfig: AppConfigService
  ) {
    this.topbarBg =
      this.appConfig.login.topbar || '/assets/layouts/imgs/jszy-topbar.png';

    this.keywordList = JSON.parse(localStorage.getItem('keywordList')) || [];

    this.userInfoForm = this.formBuilder.group({
      userName: new FormControl(''),
      realName: new FormControl(
        null,
        Validators.compose([Validators.required, Validators.maxLength(20)])
      ),
      userId: new FormControl(''),
      gender: new FormControl(''),
      attr2: new FormControl(''),
      deptId: new FormControl('', Validators.required),
      title: new FormControl(''),
      telephone: new FormControl(''),
      email: new FormControl('', [Validators.email]),
    });

    forkJoin(
      this.codetableservice.getNemsProp('gender'),
      this.codetableservice.getNemsProp('job')
    ).subscribe((res) => {
      this.genders = res[0];
      this.jobs = res[1];
      this.userInfo();
    });
  }

  public ngOnInit() {
    // 获取用户信息
    if (localStorage.getItem('websocket') === 'false') {
      this.alarmInterval();
      this.alarmIntervalTimer = setInterval(() => {
        this.alarmInterval();
      }, 1000 * 60);
    }

    this.messagesInterval(0);
    this.messagesIntervalTimer = setInterval(() => {
      this.messagesInterval(0);
    }, 1000 * 60);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.logo && !changes.logo.firstChange) {
      if (changes.logo.currentValue && changes.logo.currentValue.marquee) {
        const marquee = { ...changes.logo.currentValue.marquee };
        setTimeout(() => {
          $('#marquee').width(
            $('.layout-topbar').width() -
              79 -
              20 -
              ($('#layout-topbar-logo').width() || 0) -
              ($('.layout-topbar-logo-title').width() || 0) -
              ($('.layout-topbar-logo-all').width() || 0) -
              282
          );
          $('#marquee').html(`<marquee
            behavior="${marquee.behavior}"
            direction="${marquee.direction}"
            loop="${marquee.loop}"
            width="100%"
            scrollamount="${marquee.scrollamount}"
            style="font-family: ${marquee.fontFamily};
            color: ${marquee.color};
            font-size: ${marquee.fontSize};
            font-weight: ${marquee.fontWeight};"
            >${marquee.text}</marquee
          >`);
        }, 2000);
      }
    }
  }

  public ngOnDestroy() {
    if (this.alarmIntervalTimer) {
      clearInterval(this.alarmIntervalTimer);
      this.alarmIntervalTimer = null;
    }
    if (this.messagesIntervalTimer) {
      clearInterval(this.messagesIntervalTimer);
      this.messagesIntervalTimer = null;
    }
  }

  public editUserInfo(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.userInfoDisplay = true;
    this.userInfoForm.patchValue(this.originalProfile);
    this.userProfile = _.cloneDeep(this.originalProfile);
    this.userDeptId = this.userProfile.deptId;
    this.userAvatar = 'assets/avatar/' + this.userProfile.attr3;
  }

  public configStyle(event, overlaypanel: OverlayPanel) {
    // 获取缓存中style
    const style = JSON.parse(localStorage.getItem('style'));
    this.grouped = style.grouped;
    this.layout = style.layout;
    this.color = style.color;

    this.pageStyleDisplay = true;
    overlaypanel.hide();
  }

  /**
   * changeUserPassword
   */
  public changeUserPassword(overlaypanel: OverlayPanel) {
    overlaypanel.hide();
    this.changePasswordDisplay = true;
  }

  /**
   * 保存菜单显示样式
   */
  public saveStyle() {
    const style = {
      grouped: this.grouped,
      layout: this.layout,
      color: this.color,
    };
    // this.navservice.setPageStyle(style).subscribe((res) => {
    //   this.app.changeStyle(style);
    //   localStorage.setItem('style', JSON.stringify(style));
    //   this.pageStyleDisplay = false;
    // });
  }

  /**
   * savePassword
   */
  public savePassword() {
    if (
      this.oldPassword == '' ||
      this.newPassword == '' ||
      this.newPasswordSecond == ''
    ) {
      return this.tipservice.tip('warn', '密码未填写');
    }
    // 密码正则验证
    const pwdReg = /^(?![A-Z]+$)(?![a-z]+$)(?!\d+$)(?![\W_]+$)\S{8,16}$/;
    if (
      !pwdReg.test(this.newPassword) ||
      !pwdReg.test(this.newPasswordSecond)
    ) {
      return this.tipservice.tip(
        'warn',
        '【新密码、确认密码】请根据密码要求填写！'
      );
    }

    if (this.oldPassword == this.newPassword) {
      return this.tipservice.tip('warn', '原密码与新密码相同');
    }
    if (this.newPassword != this.newPasswordSecond) {
      return this.tipservice.tip('warn', '两次输入的密码不同');
    }
    const params = {
      newPassword: this.newPassword,
      oldPassword: this.oldPassword,
    };
    this.topbarservice.savePassword(params).subscribe((res) => {
      this.tipservice.tip('success', '密码修改成功！');
      this.changePasswordDisplay = false;
    });
  }

  /**
   * 菜单结构点击
   */
  public groupedClick(event) {
    if (
      (this.layout == 'slim' || this.layout == 'horizontal') &&
      event.option.value == false
    ) {
      this.layout = this.layoutOptions[0].value;
    }
  }
  /**
   * 菜单分布点击
   */
  public layoutClick(event) {
    if (event.option.value == 'slim' || event.option.value == 'horizontal') {
      this.grouped = true;
    }
  }

  // 注销登录
  public logout(event) {
    event.preventDefault();
    localStorage.removeItem('jwt');
    localStorage.removeItem('userName');
    localStorage.removeItem('userId');

    this.cacheService.clear();
    this.authService.logout();

    this.router.navigate(['/login']);
  }

  /**
   * onEnterSearch
   */
  public onEnterSearch(event) {
    if (event.keyCode == '13') {
      this.searchQuery();
      event.target.blur();
    }
  }

  /**
   * keywordBlur
   */
  public keywordBlur(event) {
    setTimeout(() => {
      this.suggestionShow = false;
    }, 500);
  }

  public selectHistoryWord(value) {
    this.searchKeyWord = value;
    this.searchQuery();
  }

  /**
   * search
   */
  public searchQuery(event?) {
    if (this.searchKeyWord == '') {
      return this.tipservice.tip('warn', '请输入要查询的关键字');
    }
    this.topbarservice
      .search({ text: this.searchKeyWord })
      .subscribe((res: any) => {
        this.keywordList.unshift({ value: this.searchKeyWord });
        if (this.keywordList.length > 5) {
          this.keywordList.length = 5;
        }
        localStorage.setItem('keywordList', JSON.stringify(this.keywordList));
        this.stationCount = res.stationCount;
        this.deviceCount = res.deviceCount;
        this.orderCount = res.orderCount;

        if (this.stationCount === 0 && this.deviceCount !== 0) {
          this.tabActive = 'device';
        } else {
          this.tabActive = 'station';
        }

        this.stations = res.stations;
        this.devices = res.devices;
        this.orders = res.orders;
      });
  }

  /**
   * name
   */
  private messagesInterval(page = 0) {
    this.topbarservice.webMessagesNotice(page).subscribe((res: any) => {
      this.pager.totalRecords = res.totalElements;
      this.messages = res.content;
      if (res.totalElements) {
        this.messagesBadge = true;
      } else {
        this.messagesBadge = false;
      }
      this.messages = res.content;
    });
  }

  /**
   * messages
   */
  public onPageChange(event) {
    this.topbarservice.webMessagesNotice(event.page).subscribe((res: any) => {
      this.pager.totalRecords = res.totalElements;
      this.messages = res.content;
      if (res.totalElements) {
        this.messagesBadge = true;
      } else {
        this.messagesBadge = false;
      }
      this.messages = res.content;
    });
  }

  /**
   * checkMessage
   */
  public checkMessage(message) {
    this.topbarservice
      .checkMessage({ msgId: message.msgId })
      .subscribe((res: any) => {
        message.receiveFlag = '02';
      });
  }
  /**
   * userInfo
   */
  public userInfo() {
    this.topbarservice.userInfo().subscribe((res: any) => {
      if (res) {
        this.userInfoLoading = false;
        this.originalProfile = _.cloneDeep(res);
        this.userProfile = _.cloneDeep(res);
        this.userDeptId = this.userProfile.deptId;
        this.userAvatar = 'assets/avatar/' + this.userProfile.attr3;
        localStorage.setItem('userName', res.realName);
        localStorage.setItem('userId', res.userId);
      }
    });
  }

  /**
   * saveUser
   */
  public saveUser() {
    if (!this.userInfoForm.valid) {
      return;
    }
    let params = {};
    const userInfoFormValue = this.userInfoForm.value;
    params = {
      userId: userInfoFormValue.userId, // 当前用户userId
      userName: userInfoFormValue.userName,
      realName: userInfoFormValue.realName, // 姓名
      email: userInfoFormValue.email, // 电话
      telephone: userInfoFormValue.telephone, // 邮箱
      title: userInfoFormValue.title, // 职务
      gender: userInfoFormValue.gender, // 性别
      deptId: this.userDeptId, // 部门
      attr2: userInfoFormValue.attr2, // 工号
      attr3: this.userProfile.attr3, // 头像
    };
    this.topbarservice.saveUserInfo(params).subscribe((res) => {
      if (res) {
        this.userInfoDisplay = false;
        this.tipservice.tip('success', '用户信息修改成功');
        this.userInfo();
      }
    });
  }

  /**
   * getDeptVal
   */
  public getDeptVal(val) {
    this.userDeptId = val;
  }

  /**
   * selectAvatar
   */
  public selectAvatar(avatar) {
    this.userInfoEdit = true;
    this.userProfile.attr3 = avatar;
  }
  /**
   * msgDetailInfo
   */
  public msgDetailInfo(url?) {
    if (url) {
      this.router.navigate([url]);
    }
  }

  private alarmInterval() {
    this.topbarservice
      .webAlarmNotice()
      .then((res: any) => {
        if (res) {
          this.alarms = res.alarms;
          if (this.alarmOverlay === false) {
            document.getElementById('alarmTrigger').click();
          }
          this.voiceTimes = res.voiceTimes;
          return true;
        }
        return false;
      })
      .then((res) => {
        if (res && this.alarms.length > 0) {
          this.alarmBadge = true;
          this.playTimes(this.voiceTimes);
        } else {
          this.alarmBadge = false;
          if (this.playAudioSub) {
            this.playAudioSub.unsubscribe();
            this.playAudioSub = null;
          }
        }
      });
  }

  alarmOverlayShow() {
    this.alarmOverlay = true;
  }

  alarmOverlayHide() {
    this.alarmOverlay = false;
  }

  goAlarm(alarm, index) {
    this.alarms.splice(index, 1);
    if (this.alarms.length === 0) {
      this.alarmBadge = false;
    }
    if (!alarm.logId) {
      return;
    }
    this.router.navigate(['/alarmManage/realTime'], {
      queryParams: {
        stationId: alarm.stationId,
        deviceName: alarm.deviceName,
        dateType: 'hour',
        date: moment(alarm.startTime).format('YYYY-MM-DD'),
      },
    });
  }

  public playTimes(count = 0) {
    if (count == 0) {
      return;
    }
    this.elem = this.audioPlayerRef.nativeElement;
    this.start = 0;
    this.times = count;
    const playAudio = fromEvent(this.elem, 'ended');
    this.playAudioSub = playAudio.subscribe((e) => {
      this.start++;
      if (this.start < this.times) {
        this.elem.play();
      } else {
        this.playAudioSub.unsubscribe();
        this.playAudioSub = null;
      }
    });
    this.elem.play();
  }

  public setStation(station) {
    this.breadcrumbservice.setStation(
      { stationId: station.stationId, shortName: station.shortName },
      '/monitorStation/overview'
    );
  }

  setHref(child) {
    let params = '';
    for (const key in child.menuParam) {
      if (child.menuParam.hasOwnProperty(key)) {
        const element = child.menuParam[key];
        if (params) {
          params += '&';
        }
        params += key + '=' + element;
      }
    }
    return child.routerLink[0] + (params ? '?' + params : '');
  }

  public setDevice(device) {
    this.breadcrumbservice.setStation(
      { stationId: device.stationId, shortName: device.shortName },
      '/monitorStation/deviceList',
      {
        deviceId: device.deviceId,
        stationId: device.stationId,
        deviceType: device.deviceType,
      }
    );
  }

  public resetMenu(menu) {
    // console.log(this.menus, menu);
    // console.log(123, menu);

    this.menus.map((item) => {
      item.active = false;
    });
    menu.active = true;
  }
}
