import { StationSelectorModule } from './../common/components/station-selector/station-selector.module';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

import {
  NemsCodeTablePipe,
  NemsCodeItemPipe,
  CodeTablePipe,
  CodeItemPipe,
  SanitizeHtmlPipe,
  DurationPipe,
} from './pipes';
import {
  EchartsDirective,
  NiceScrollBarDirective,
  HasPermissionDirective,
} from './directives';
import { DatetimePickerComponent } from './datetime-picker';
import { HorizontalTimelineComponent } from './horizontal-timeline';
import { WebsocketService } from '../common/services/websocket.service';
import { TranslateModule } from '@ngx-translate/core';

import { DeptsPickerModule } from '../base/depts-picker/depts-picker.module';
import { DateTimeRangePickerModule } from '../base/datetime-range-picker/datetime-range-picker.module';
import { UsersPickerModule } from '../base/users-picker/users-picker.module';
import { FormService } from '../common/services/form.service';
import { DeptPickListModule } from '../common/components/dept-picklist/dept-picklist.module';
import { EqualValidator } from './validators/equal-validator.directive';
import { CommentsService } from '../common/services/comments.service';
import { StationsService } from '../common/services/stations.service';
import { MaterialMultiModule } from '@common/components/material-multi/material-multi.module';

@NgModule({
  imports: [CommonModule, FormsModule, TranslateModule],
  declarations: [
    EchartsDirective,
    NiceScrollBarDirective,
    HasPermissionDirective,
    EqualValidator,
    DatetimePickerComponent,
    HorizontalTimelineComponent,
    NemsCodeTablePipe,
    NemsCodeItemPipe,
    CodeTablePipe,
    CodeItemPipe,
    SanitizeHtmlPipe,
    DurationPipe,
  ],
  exports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    DatetimePickerComponent,
    HorizontalTimelineComponent,
    EchartsDirective,
    NiceScrollBarDirective,
    CodeTablePipe,
    CodeItemPipe,
    NemsCodeTablePipe,
    NemsCodeItemPipe,
    SanitizeHtmlPipe,
    DurationPipe,
    DeptsPickerModule,
    DateTimeRangePickerModule,
    UsersPickerModule,
    HasPermissionDirective,
    DeptPickListModule,
    StationSelectorModule,
    MaterialMultiModule,
  ],
  providers: [WebsocketService, FormService, CommentsService, StationsService],
})
export class SharedModule { }
