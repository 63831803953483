import { NgModule } from '@angular/core';
import { SharedModule } from '@shared/shared.module';
import { EmsService } from '../ems.service';
import { MonitorStationRoutingModule } from './monitor-station-routing.module';
import { MonitorStationComponent } from './monitor-station.component';

@NgModule({
  declarations: [MonitorStationComponent],
  imports: [SharedModule, MonitorStationRoutingModule],
  providers: [EmsService],
})
export class MonitorStationModule {}
