import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { InputTextModule } from 'primeng/primeng';
import { ObjectSelectorModule } from 'src/app/common/components/object-selector/object-selector.module';
import { UserSelectorModule } from 'src/app/common/components/user-selector/user-selector.module';
import { UploadDocsModule } from './../../../../common/components/upload-docs/upload-docs.module';
import { UploadPicsModule } from './../../../../common/components/upload-pics/upload-pics.module';
import { CommentModule } from 'src/app/common/components/comment/comment.module';
import { CcUsersModule } from 'src/app/common/components/cc-users/cc-users.module';
import { TableModule } from 'primeng/table';
import { SpinnerModule } from 'primeng/spinner';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from './../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { OperateDefectComponent } from './operate-defect.component';
import { DefectManageService } from '../defect-manage.service';
import { FieldsetModule } from 'primeng/primeng';

import { RepairTicketModule } from 'src/app/components/ticket/repair/repair-ticket/repair-ticket.module';
import { InAndOutPopupsModule } from '../../../device-assets/spare-parts-manage/shared/in-and-out-popups/in-and-out-popups.module';

const primengModule = [
  DialogModule,
  InputTextModule,
  InputTextareaModule,
  FieldsetModule,
  CalendarModule,
  DropdownModule,
  SpinnerModule,
  TableModule,
  ButtonModule,
];

@NgModule({
  declarations: [OperateDefectComponent],
  exports: [OperateDefectComponent],
  imports: [
    SharedModule,
    CcUsersModule,
    CommentModule,
    UploadPicsModule,
    UploadDocsModule,
    UserSelectorModule,
    ObjectSelectorModule,
    InAndOutPopupsModule,
    RepairTicketModule,
    ...primengModule,
  ],
  providers: [DefectManageService],
})
export class OperateDefectModule {}
