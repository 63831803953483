import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  private locationSource = new Subject();
  locationHandler = this.locationSource.asObservable();


  constructor() { }

  setLocaion(data) {
    this.locationSource.next(data)
  }

}
