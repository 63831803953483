import { AppConfigService } from 'src/app/app.config';
import { CacheService } from 'src/app/common/services/cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StationMonitorService } from '../../station-monitor.service';
import * as _ from 'lodash';
import { Constants } from 'src/app/common/Constants';

@Component({
  selector: 'app-station-info',
  templateUrl: './station-info.component.html',
  styleUrls: ['./station-info.component.less'],
})
export class StationInfoComponent implements OnInit, OnDestroy {
  stationInfoInterval: any = null;
  IntervalTime: number = 5;
  loading: boolean;
  station_base_info: any;
  running_time: any;
  daily_power_generation: any;
  inverters_number: any;
  isLnProject: boolean;
  weather_info: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private stationmonitorservice: StationMonitorService,
    private cacheService: CacheService,
    private appconfig: AppConfigService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.initPage({ stationId });
      } else {
        this.initPage({ stationId: params.stationId });
      }
    });
    this.isLnProject = this.appconfig.projectName === 'ln';
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    //清理定时器
    clearInterval(this.stationInfoInterval);
    this.stationInfoInterval = null;
  }

  public initPage(params) {
    if (this.stationInfoInterval) {
      //清理定时器
      clearInterval(this.stationInfoInterval);
      this.stationInfoInterval = null;
    }
    this.stationInfo(params);
    this.stationInfoInterval = setInterval(() => {
      this.stationInfo(params);
    }, 1000 * 60 * this.IntervalTime);
  }

  /**
   * stationInfo
   */
  public stationInfo(params?) {
    this.loading = true;
    this.stationmonitorservice.stationInfo(params).then((res) => {
      if (res) {
        this.station_base_info = res[0];
        this.running_time = res[1];
        this.daily_power_generation = res[2];
        this.inverters_number = res[3];
        this.loading = false;
      }
    });
    this.stationmonitorservice.weatherInfo(params).then((res) => {
      if (res) {
        this.weather_info = res;
      }
    });
  }

  /**
   * pageLink
   */
  public pageLink(route) {
    if (route) {
      localStorage.setItem(
        'backItem',
        JSON.stringify({
          showIcon: true,
          link: ['/monitorStation/overview'],
        })
      );
      this.router.navigate([route]);
    }
  }
}
