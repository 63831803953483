import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../app.config';
import { AuthHttp } from './auth-http';

export interface SelectItem {
  label: string;
  value: any;
}

@Injectable()
export class CodeTableService {
  private cached = {};

  constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

  public getNemsSelectItems(codeId: string): Promise<SelectItem[]> {
    if (this.cached[codeId]) {
      return this.cached[codeId];
    }
    this.cached[codeId] = this.http
      .get(`${this.appConfig.apiUrl}/api/propList/propLists/search`, {
        search: { codeId },
      })
      .pipe(
        map((res: any) => {
          let codes;
          try {
            codes = res;
          } catch (e) {
            console.warn(
              `请求码表返回格式错误, 码表:${codeId}, 错误:${e}, 返回的数据:${res.text()}`
            );
          }
          if (!codes) {
            return [];
          }
          return codes.map((item) => {
            return { label: item.propName, value: item.propValue };
          });
        })
      )
      .toPromise();
    return this.cached[codeId];
  }

  public getNemsCodeItem(codeId: string, itemId: string): Promise<SelectItem> {
    return this.getNemsSelectItems(codeId).then((res) => {
      let codeItem = { value: '', label: '' };
      if (res) {
        codeItem = res.find((item) => item.value === itemId);
      }
      return codeItem;
    });
  }

  public getSelectItems(name: string): Observable<SelectItem[]> {
    let cacheData = this.cached[name];
    if (typeof cacheData !== typeof undefined) {
      return of(cacheData);
    }
    return this.http
      .get<SelectItem[]>(`${this.appConfig.apiUrl}/api/select-items/${name}`)
      .pipe(
        map((res) => {
          let data = res;
          this.cached[name] = data;
          return <SelectItem[]>data;
        })
      );
  }

  public getCodeItem(codeId: string, itemId: string): Promise<SelectItem> {
    return this.getNemsSelectItems(codeId).then((res) => {
      let codeItem = { value: '', label: '' };
      if (res) {
        codeItem = res.find((item) => item.value === itemId);
      }
      return codeItem;
    });
  }

  public getNemsProp(typeId: string): Promise<SelectItem[]> {
    if (this.cached[typeId]) {
      return this.cached[typeId];
    }
    this.cached[typeId] = this.http
      .get(`${this.appConfig.apiUrl}/api/prop`, { search: { typeId } })
      .pipe(
        map((res: any) => {
          let codes;
          try {
            codes = res;
          } catch (e) {
            console.warn(
              `请求码表返回格式错误, 码表:${typeId}, 错误:${e}, 返回的数据:${res.text()}`
            );
          }
          if (!codes) {
            return [];
          }
          return codes.map((item) => {
            return { label: item.propName, value: item.propValue };
          });
        })
      )
      .toPromise();
    return this.cached[typeId];
  }
}
