import { Injectable } from '@angular/core';
import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { Department } from './user-selector.model';
import { TreeNode } from 'primeng/components/common/treenode';

@Injectable()
export class UserSelectorService {
  private apiUrl: string = this.appConfig.apiUrl;
  constructor(private http: AuthHttp, private appConfig: AppConfigService) {}
  // 获得部门树数据
  public getDepartments() {
    return this.http.get(`${this.apiUrl}/api/nems/depts/tree`, {
      params: { getUserCount: true },
    });
  }
  // 获得告警对象表格数据
  public getUserListData(userParams: any) {
    const param = {} as { deptId; name };
    if (userParams && userParams.departmentId && userParams.departmentId.deptId) {
      param.deptId = userParams.departmentId.deptId;
    }
    if (userParams.realName) {
      param.name = userParams.realName;
    }
    return this.http.get(`${this.apiUrl}/api/appUser/users`, {
      search: {
        ...param,
        page: userParams.pageCurrent,
        size: userParams.pageSize,
      },
    });
  }
}
