import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem, SelectItem } from "primeng/primeng";
import { FormBuilder, FormControl } from "@angular/forms";
import { FormGroup } from "@angular/forms";
import { CodeTableService } from "../../../../../core/code-table.service";
import { CodeTable } from "../../../../../common/models/codeTable.model";
import { NullItem } from "../point-config.model";
import { CommonService } from "../../../../../common/services/common.service";
import { TipService } from "@common/services/tip.service";

@Component({
  selector: "volumn-set-dialog",
  templateUrl: "volumn-set-dialog.component.html",
  styleUrls: ["volumn-set-dialog.component.less"]
})
export class VolumnSetDialogComponent implements OnInit {
  @Input() public showDialog = false;
  @Output() public onSubmit = new EventEmitter<any>();
  @Output() public onHide = new EventEmitter<any>();

  @Input() set target(target) {
    if (target) {
      this.changeRemotionType(target.pointCategory.remotionType);
      this.dataForm = this.fb.group({
        pointName: target.pointName,
        pointRemotionType: target.pointCategory.remotionType,
        type: target.pointCategory.sysCateId,
        road: target.pointCategory.displayGroup1,
        status: target.pointCategory.displayGroup2,
        way: target.isDisplay,
        unit: target.pointCategory.pointUnit,
        transform: target.pointCategory.propTypeId
      });
      this.isShowPointName = true;
      this.headerText = "测点配置";
    } else {
      this.dataForm = this.fb.group({
        pointName: "",
        pointRemotionType: "",
        type: "",
        road: "",
        status: "",
        way: "",
        unit: "",
        transform: ""
      });
      this.isShowPointName = false;
      this.headerText = "批量设置";
    }
  }
  @Input() public sysCateIdList;
  @Input() public displayGroup1List;
  @Input() public pvStatusList;
  @Input() public pointIsDisplayList;
  @Input() public unitList;
  @Input() public pointDisplayTransferList;

  public isShowPointName = false;
  public headerText = "批量设置";
  public dataForm: FormGroup;

  public pointRemotionType: SelectItem[] = [];
  public pointTypeList: SelectItem[] = [];

  constructor(
    private fb: FormBuilder,
    private codeTableService: CodeTableService,
    private common: CommonService,
    private tipservice: TipService
  ) {
    this.dataForm = this.fb.group({
      pointName: "",
      pointRemotionType: "",
      type: "",
      road: "",
      status: "",
      way: "",
      unit: "",
      transform: ""
    });
    this.codeTableService
      .getNemsSelectItems(CodeTable.pointRemotionType)
      .then(res => {
        this.pointRemotionType = [...res];
        this.pointRemotionType.unshift(NullItem);
      });
  }

  ngOnInit() { }

  public changeRemotionType(val) {
    if (val === "01" || val === "02") {
      this.pointTypeList = this.sysCateIdList["yxPointTypes"];
    }
    if (val === "03" || val === "04") {
      this.pointTypeList = this.sysCateIdList["ycPointTypes"];
    }
  }

  public hideDialog() {
    this.onHide.emit(false);
  }

  public submitDialog() {
    let dataValue = this.dataForm.value;
    if (this.isShowPointName) {
      if (!dataValue.pointName) {
        this.tipservice.tip("warn", "测点名称必填");
        return false;
      } else {
        this.onSubmit.emit(this.dataForm.value);
      }
    } else {
      this.onSubmit.emit(this.dataForm.value);
    }
    this.cancelDialog();
  }

  public cancelDialog() {
    this.hideDialog();
    this.dataForm.reset();
  }
}
