import { ButtonModule } from 'primeng/button';
import { UserSelectorModule } from 'src/app/common/components/user-selector/user-selector.module';
import { InputTextModule } from 'primeng/inputtext';
import { SpinnerModule } from 'primeng/spinner';
import { CalendarModule } from 'primeng/calendar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DropdownModule } from 'primeng/dropdown';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InAndOutPopupsComponent } from './in-and-out-popups.component';
import { WarehouseModule } from '../../../shared/warehouse/warehouse.module';
import { OptionTypeModule } from '../../../shared/option-type/option-type.module';
import { InputTextareaModule, ConfirmDialogModule } from 'primeng/primeng';
import { MaterialInquiryModule } from '../../../shared/material-inquiry/material-inquiry.module';
import { SparePartsManageService } from '../../spare-parts-manage.service';

const primengModule = [
  DialogModule,
  DropdownModule,
  AutoCompleteModule,
  CalendarModule,
  SpinnerModule,
  InputTextareaModule,
  InputTextModule,
  ConfirmDialogModule,
  ButtonModule,
];

@NgModule({
  declarations: [InAndOutPopupsComponent],
  imports: [
    SharedModule,
    WarehouseModule,
    OptionTypeModule,
    MaterialInquiryModule,
    UserSelectorModule,
    ...primengModule,
  ],
  exports: [InAndOutPopupsComponent],
  providers: [SparePartsManageService],
})
export class InAndOutPopupsModule {}
