import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import {
  PaginatorModule,
  CalendarModule,
  InputTextModule,
  InputTextareaModule,
  MultiSelectModule,
  LightboxModule,
  ConfirmDialogModule,
  TieredMenuModule,
  CheckboxModule,
  FieldsetModule,
  SpinnerModule,
  FileUploadModule,
  RadioButtonModule,
} from 'primeng/primeng';
import { ButtonModule } from 'primeng/button';
import { StationSelectorModule } from 'src/app/common/components/station-selector/station-selector.module';
import { UserSelectorModule } from 'src/app/common/components/user-selector/user-selector.module';
import { ObjectSelectorModule } from 'src/app/common/components/object-selector/object-selector.module';
import { DialogModule } from 'primeng/dialog';
import { CommentModule } from 'src/app/common/components/comment/comment.module';
import { CcUsersModule } from 'src/app/common/components/cc-users/cc-users.module';
import { OperateMaintainComponent } from '../operate-maintain/operate-maintain.component';
import { MaintainDeviceItemsComponent } from '../maintain-device-items/maintain-device-items.component';
import { MaintainDeviceDetailsComponent } from '../maintain-device-details/maintain-device-details.component';
import { OperateDefectModule } from '../../defect/defect-manage/operate-defect/operate-defect.module';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { MaintainsService } from '../maintains.service';
import { ConfirmationService } from 'primeng/api';
import { FileService } from 'src/app/common/services/file.service';

const primengModule = [
  TableModule,
  PaginatorModule,
  CalendarModule,
  InputTextModule,
  InputTextareaModule,
  ButtonModule,
  MultiSelectModule,
  StationSelectorModule,
  UserSelectorModule,
  ObjectSelectorModule,
  LightboxModule,
  ConfirmDialogModule,
  TieredMenuModule,
  DialogModule,
  CheckboxModule,
  FieldsetModule,
  CommentModule,
  CcUsersModule,
  SpinnerModule,
  FileUploadModule,
  RadioButtonModule,
];

@NgModule({
  declarations: [
    OperateMaintainComponent,
    MaintainDeviceItemsComponent,
    MaintainDeviceDetailsComponent,
  ],
  imports: [SharedModule, ...primengModule, OperateDefectModule],
  exports: [OperateMaintainComponent, MaintainDeviceItemsComponent, MaintainDeviceDetailsComponent],
  providers: [CalendarLocales, MaintainsService, ConfirmationService, FileService],
})
export class MaintainsSharedModule {}
