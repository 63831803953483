import {
  CalendarModule,
  PaginatorModule,
  MultiSelectModule,
  TabViewModule,
  DialogModule,
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { SharedModule } from '@shared/shared.module';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WindTurbinesComponent } from '@components/station-monitor/device-list/wind-turbines/wind-turbines.component';
import { CalendarLocales } from '@shared/locales/calendar.locales';
import { WindDeviceMonitorComponent } from '@components/station-monitor/device-detail/wind-device-monitor/wind-device-monitor.component';
import { WindService } from './wind.service';
import { StationMonitorComponent } from './station-monitor/station-monitor.component';
import { StationMonitorModule as _StationMonitorModule } from '@components/station-monitor/station-monitor.module';
import { InvertersModule } from './station-monitor/inverters/inverters.module';
import { WebglLnWindComponent } from './station-monitor/webgl-ln-wind/webgl-ln-wind.component';
export const ROUTES: Routes = [
  {
    path: 'gisMonitor',
    loadChildren: () =>
      import('./gis-monitor/gis-monitor.module').then(
        (m) => m.GisMonitorModule
      ),
  },
  {
    path: 'stationMonitor',
    component: StationMonitorComponent,
  },
  {
    path: 'resourceMonitor',
    loadChildren: () =>
      import('./resource-monitor/resource-monitor.module').then(
        (m) => m.ResourceMonitorModule
      ),
  },
  {
    path: 'submarineCableMonitor',
    loadChildren: () =>
      import('./submarine-cable-monitor/submarine-cable-monitor.module').then(
        (m) => m.SubmarineCableMonitorModule
      ),
  },
  {
    path: 'deviceList',
    component: WindTurbinesComponent,
  },
  {
    path: 'deviceTable',
    component: WindTurbinesComponent,
  },
  {
    path: 'deviceMonitor',
    component: WindDeviceMonitorComponent,
  },
];

const primengModule = [
  TableModule,
  PaginatorModule,
  CalendarModule,
  MultiSelectModule,
  CalendarModule,
  TabViewModule,
  DialogModule,
];

@NgModule({
  declarations: [
    WindTurbinesComponent,
    WindDeviceMonitorComponent,
    StationMonitorComponent,
    WebglLnWindComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forChild(ROUTES),
    ...primengModule,
    // StationMonitorModule,
    _StationMonitorModule,
    InvertersModule,
  ],
  providers: [CalendarLocales, WindService],
  exports: [StationMonitorComponent],
})
export class WindModule {}
