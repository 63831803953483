import { CacheService } from 'src/app/common/services/cache.service';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildElectricityDayChart } from './chart/electricity-day.chart';
import { buildElectricityMonthChart } from './chart/electricity-month.chart';
import { buildElectricityYearChart } from './chart/electricity-year.chart';
import { buildEquivalentDayChart } from './chart/equivalent-day.chart';
import { buildEquivalentMonthChart } from './chart/equivalent-month.chart';
import { buildEquivalentYearChart } from './chart/equivalent-year.chart';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { StationMonitorService } from './../../station-monitor.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { Constants } from 'src/app/common/Constants';
import { buildPowerChart } from './chart/power.chart';

@Component({
  selector: 'app-power-generation-card',
  templateUrl: './power-generation-card.component.html',
  styleUrls: ['../overview.component.less', './power-generation-card.component.less'],
})
export class PowerGenerationCardComponent implements OnInit, OnDestroy {
  //发电情况
  public electricityType: string = 'power';

  //电量 图表option
  public energyOption: any = null;

  //日等效时间数
  public equivalentType: string = 'equivalentDay';

  public equivalentOption: any = null;

  public electricity_block = true;
  public equivalent_block = true;
  public canlendarLocale: any;

  //加载图表 loading
  public energyLoading: boolean = true;

  public electricityDate: string = moment().format('YYYY-MM-DD');
  public electricityDataType: any = 'day';

  public equivalentDate: string = moment().format('YYYY-MM-DD');
  public equivalentDataType: any = 'day';

  private electricity_common_params = null;
  private equivalent_common_params = null;
  private sta_params: any = {};

  private intervalTime = 5;
  private electricityInterval: any = null;
  private kwhkwpInterval: any = null;
  kwhkwpLoading: boolean = true;
  stationId: any;

  constructor(
    private stationmonitorservice: StationMonitorService,
    private calendarLocales: CalendarLocales,
    private router: Router,
    private route: ActivatedRoute,
    private cacheService: CacheService
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID);
        this.stationId = stationId;
        this.initPage({ stationId });
      } else {
        this.stationId = params.stationId;
        this.initPage({ stationId: params.stationId });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    clearInterval(this.electricityInterval);
    this.electricityInterval = null;
    clearInterval(this.kwhkwpInterval);
    this.kwhkwpInterval = null;
  }

  /**
   * initPage
   */
  public initPage(params?) {
    if (this.electricityInterval) {
      clearInterval(this.electricityInterval);
      this.electricityInterval = null;
    }
    if (this.kwhkwpInterval) {
      clearInterval(this.kwhkwpInterval);
      this.kwhkwpInterval = null;
    }
    this.sta_params = params;
    if (_.isEmpty(this.electricity_common_params)) {
      this.electricity_common_params = {
        dataType: this.electricityDataType,
        date: this.electricityDate,
      };
    } else {
      this.electricity_common_params = _.cloneDeep(this.electricity_common_params);
    }
    if (_.isEmpty(this.equivalent_common_params)) {
      this.equivalent_common_params = {
        dataType: this.electricityDataType,
        date: this.electricityDate,
      };
    } else {
      this.equivalent_common_params = _.cloneDeep(this.equivalent_common_params);
    }

    this.getEnergy(_.merge({}, this.electricity_common_params, this.sta_params));
    this.electricityInterval = setInterval(() => {
      this.getEnergy(_.merge({}, this.electricity_common_params, this.sta_params));
    }, 1000 * 60 * this.intervalTime);

    this.getKwhkwp(_.merge({}, this.equivalent_common_params, this.sta_params));
    this.kwhkwpInterval = setInterval(() => {
      this.getKwhkwp(_.merge({}, this.equivalent_common_params, this.sta_params));
    }, 1000 * 60 * this.intervalTime);
  }

  /**
   * getEnergy
   */
  public getEnergy(params) {
    if (this.electricityType == 'power') {
      this.energyLoading = true;
      this.stationmonitorservice.power(params).then((res: any) => {
        let data = res;
        this.energyOption = buildPowerChart(data);
        this.energyLoading = false;
      });
    } else {
      this.energyLoading = true;
      this.stationmonitorservice.energy(params).then((res: any) => {
        let data = res;
        if (params.dataType == 'day') {
          this.energyOption = buildElectricityDayChart(data);
        } else if (params.dataType == 'month') {
          this.energyOption = buildElectricityMonthChart(data);
        } else if (params.dataType == 'year') {
          this.energyOption = buildElectricityYearChart(data);
        }
        this.energyLoading = false;
      });
    }
  }

  /**
   * getKwhkwp
   */
  public getKwhkwp(params) {
    this.kwhkwpLoading = true;
    this.stationmonitorservice.kwhkwp(params).then((res: any) => {
      let data = res;
      if (params.dataType == 'day') {
        this.equivalentOption = buildEquivalentDayChart(data);
      } else if (params.dataType == 'month') {
        this.equivalentOption = buildEquivalentMonthChart(data);
      } else if (params.dataType == 'year') {
        this.equivalentOption = buildEquivalentYearChart(data);
      }
      this.kwhkwpLoading = false;
    });
  }

  /**
   * selectElectricityPower
   */
  public selectElectricity(type, date?) {
    this.electricityType = type;
    switch (type) {
      case 'power':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          dataType: 'day',
          date: this.electricityDate,
        };
        this.getEnergy(_.merge({}, this.electricity_common_params, this.sta_params));
        break;
      case 'electricityDay':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          dataType: 'day',
          date: this.electricityDate,
        };
        this.getEnergy(_.merge({}, this.electricity_common_params, this.sta_params));
        break;
      case 'electricityMonth':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM');
        } else {
          this.electricityDate = moment().format('YYYY-MM');
        }
        this.electricity_common_params = {
          dataType: 'month',
          date: this.electricityDate + '-01',
        };
        this.getEnergy(_.merge({}, this.electricity_common_params, this.sta_params));
        break;
      case 'electricityYear':
        this.electricity_common_params = { dataType: 'year' };
        this.getEnergy(_.merge({}, this.electricity_common_params, this.sta_params));
        break;
      default:
        break;
    }
  }
  /**
   * selectEquivalent
   */
  public selectEquivalent(type, date?) {
    this.equivalentType = type;
    switch (type) {
      case 'equivalentDay':
        if (date) {
          this.equivalentDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.equivalentDate = moment().format('YYYY-MM-DD');
        }

        this.equivalent_common_params = {
          dataType: 'day',
          date: this.equivalentDate,
        };
        this.getKwhkwp(_.merge({}, this.equivalent_common_params, this.sta_params));
        break;
      case 'equivalentMonth':
        if (date) {
          this.equivalentDate = moment(date).format('YYYY-MM');
        } else {
          this.equivalentDate = moment().format('YYYY-MM');
        }
        this.equivalent_common_params = {
          dataType: 'month',
          date: this.equivalentDate + '-01',
        };
        this.getKwhkwp(_.merge({}, this.equivalent_common_params, this.sta_params));
        break;
      case 'equivalentYear':
        this.equivalent_common_params = { dataType: 'year' };
        this.getKwhkwp(_.merge({}, this.equivalent_common_params, this.sta_params));
        break;
      default:
        break;
    }
  }

  electricityDateSelect(event) {
    this.selectElectricity(this.electricityType, event);
  }

  equivalentDateSelect(event) {
    this.selectEquivalent(this.equivalentType, event);
  }

  /**
   * goEnergy
   */
  public goEnergy(type) {
    let params = {};
    if (type == 'electricity') {
      params = {
        date: this.electricity_common_params.date
          ? this.electricity_common_params.date
          : moment().format('YYYY-01-01'),
      };
    } else {
      params = {
        // date: this.equivalent_common_params.data
        //     ? this.equivalent_common_params.data
        //     : moment().format('YYYY-01-01')
        date: this.equivalent_common_params.date
          ? this.equivalent_common_params.date
          : moment().format('YYYY-01-01'),
      };
    }
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/monitorStation/overview'],
        params,
      })
    );
    this.router.navigate(['/monitorStation/energy'], {
      queryParams: {
        stationId: this.stationId,
      },
      queryParamsHandling: 'merge',
    });
  }
}
