import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-no-content',
  templateUrl: './no-content.component.html',
  styleUrls: ['./no-content.component.less'],
})
export class NoContentComponent implements OnInit {
  logo: string;
  constructor() {}

  ngOnInit() {
    this.logo = JSON.parse(localStorage.getItem('logo')).url;
  }
}
