import { LocationService } from './location.service';
import { CommonService } from 'src/app/common/services/common.service';
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ControlAnchor, MapOptions, NavigationControlType } from 'angular2-baidu-map';
import * as _ from 'lodash';
import gcoord from 'gcoord';
import { TipService } from '@common/services/tip.service';
declare const BMap;
declare const BMAP_NORMAL_MAP;
declare const BMAP_SATELLITE_MAP;
@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LocationComponent),
      multi: true
    }
  ]
})
export class LocationComponent implements OnInit, ControlValueAccessor {

  @Input() type: String;
  private innerValue: any = '';
  private onTouchedCallback: () => void = function () { };
  private onChangeCallback: (_: any) => void = function () { };


  public display = false;

  public options: MapOptions = {
    enableMapClick: true,
    enableScrollWheelZoom: true,
    enableAutoResize: true,
    maxZoom: 18,
    centerAndZoom: {
      lng: 118.80242,
      lat: 32.06465,
      zoom: 12
    }
  };

  public mapCtrl = {
    geolocationCtrl: {
      anchor: ControlAnchor.BMAP_ANCHOR_BOTTOM_RIGHT,
    },
    scaleCtrl: {
      anchor: ControlAnchor.BMAP_ANCHOR_BOTTOM_LEFT,
    },
    overviewCtrl: {
      isOpen: true,
    },
    navCtrl: {
      type: NavigationControlType.BMAP_NAVIGATION_CONTROL_LARGE,
    },
  };

  zoom = 15;
  markers: any[] = [];

  point: any = {};

  map

  constructor(private commonservice: CommonService, private locationservice: LocationService, private tipservice: TipService) {
    this.locationservice.locationHandler.subscribe((res: any) => {
      if (res.type === this.type) {
        this.value = res.value;
      }
      if (res.type === 'longitude') {
        this.point.lng = res.value
      } else {
        this.point.lat = res.value
      }
    })
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    if (v !== this.innerValue) {
      this.innerValue = v;
      this.onChangeCallback(+v);
      setTimeout(() => {
        this.locationservice.setLocaion({
          type: this.type,
          value: v
        })
      }, 1);
    }
  }

  writeValue(value: any): void {
    if (value !== this.innerValue) {
      this.innerValue = value;
      setTimeout(() => {
        this.locationservice.setLocaion({
          type: this.type,
          value: value
        })
      }, 1);
    }
  }
  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }
  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    // throw new Error('Method not implemented.');
    console.log('setDisabledState', isDisabled)
  }

  propagateChange(val) { }

  ngOnInit() {

  }

  showMap() {
    if (this.point.lng && this.point.lat) {

      const newPointArray = gcoord.transform(
        [this.point.lng, this.point.lat],    // 经纬度坐标
        gcoord.WGS84,               // 当前坐标系 
        gcoord.BD09,                 // 目标坐标系 
      );

      const newPoint = { lng: newPointArray[0], lat: newPointArray[1] }

      this.options = _.assign({}, this.options, {
        centerAndZoom: {
          lng: newPoint.lng,
          lat: newPoint.lat,
          zoom: this.zoom,
        },
      });

      this.markers = [{
        options: {
          icon: {
            imageUrl: '/assets/map/marker.png',
            size: {
              height: 42,
              width: 30
            },
            imageSize: {
              height: 42,
              width: 30
            }
          },
          offset: {
            height: -42,
            width: -15
          }
        }, point: newPoint
      }];
      setTimeout(() => {
        this.display = true
      }, 500);
    } else {
      this.display = true
    }
  }

  loadMap(event) {
    this.map = event;
    this.map.addControl(new BMap.MapTypeControl({ mapTypes: [BMAP_NORMAL_MAP, BMAP_SATELLITE_MAP] }))

  }

  clickMap(event) {
    this.markers = [{
      options: {
        icon: {
          imageUrl: '/assets/map/marker.png',
          size: {
            height: 42,
            width: 30
          },
          imageSize: {
            height: 42,
            width: 30
          },

        },
        offset: {
          height: -42,
          width: -15
        }
      },
      point: event.point
    },]
  }


  save() {
    if (this.markers && this.markers[0] && this.markers[0].point) {
      const point = this.markers[0].point;

      const newPointArray = gcoord.transform(
        [point.lng, point.lat],    // 经纬度坐标
        gcoord.BD09,                 // 当前坐标系 
        gcoord.WGS84,               // 目标坐标系
      );
      const newPoint = { lng: newPointArray[0], lat: newPointArray[1] }
      if (this.type === 'longitude') {
        this.value = newPoint.lng;
        this.locationservice.setLocaion({
          type: 'longitude',
          value: newPoint.lng
        });
        this.locationservice.setLocaion({
          type: 'latitude',
          value: newPoint.lat
        });
      } else {
        this.value = newPoint.lat;
        this.locationservice.setLocaion({
          type: 'longitude',
          value: newPoint.lng
        });
        this.locationservice.setLocaion({
          type: 'latitude',
          value: newPoint.lat
        });
      }
      this.display = false;
    } else {
      this.tipservice.tip('warn', '未选择设备位置');
    }

  }

}
