import { NgModule } from "@angular/core";
import { TreeTableModule, DialogModule, ButtonModule } from "primeng/primeng";
import { TranslateModule } from "@ngx-translate/core";
import { UsersService } from "../users/shared/users.service";
import { DeptsService } from "../depts/shared/depts.service";
import { UsersPickerComponent } from "./users-picker.component";
import { TableModule } from "primeng/table";

@NgModule({
    imports: [
        TreeTableModule,
        TableModule,
        DialogModule,
        ButtonModule,
        TranslateModule
    ],
    declarations: [UsersPickerComponent],
    exports: [UsersPickerComponent],
    providers: [UsersService, DeptsService]
})
export class UsersPickerModule {}
