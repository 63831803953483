import { AppConfigService } from './../../app.config';
import { Directive, OnChanges, ElementRef, Input, OnInit } from '@angular/core';
import * as _ from 'lodash';
declare var $: any;
@Directive({
  selector: '[niceScrollBar]',
})
export class NiceScrollBarDirective implements OnChanges, OnInit {
  @Input() public mod: any;
  @Input() public option: any = {};

  private defultOption = {
    theme: 'light-thick',
    axis: 'y',
    autoHideScrollbar: true,
    scrollInertia: 300,
  };

  constructor(private el: ElementRef, private appconfig: AppConfigService) {}

  public ngOnChanges() {
    setTimeout(() => $(this.el.nativeElement).mCustomScrollbar('update'), 0);
  }
  public ngOnInit() {
    const config = this.appconfig.getAppConfig();
    let curOption = _.merge(
      {
        ...this.defultOption,
        theme: config.theme === 'dark' ? 'minimal' : 'minimal-dark',
      },
      this.option
    );
    $(this.el.nativeElement).mCustomScrollbar(curOption);
  }
}
