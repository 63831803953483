import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AuthHttp } from "../../../core/auth-http";
import { AppConfigService } from "../../../app.config";
import { PointRequest, PointResponse } from "./index.model";
import { CommonService } from "../../../common/services/common.service";
import { tap } from "rxjs/operators";

@Injectable()
export class PointService {
    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private commonService: CommonService
    ) {}

    public getPoints(param: PointRequest): Observable<PointResponse[]> {
        return this.http
            .get<PointResponse[]>(`${this.appConfig.apiUrl}/api/points`, {
                search: param
            })
            .pipe(
                tap(res => {
                    let points: PointResponse[] = [];
                    try {
                        points = res;
                    } catch (e) {
                        console.warn("/api/points 获取测点失败:" + e);
                    }
                    for (let point of points) {
                        if (point.historyValues && point.historyValues.length) {
                            point.historyValues = point.historyValues.filter(
                                this.commonService.isPointValueValid
                            );
                        }

                        if (
                            point.currValue &&
                            !this.commonService.isPointValueValid(
                                point.currValue
                            )
                        ) {
                            point.currValue.v = null;
                        }
                    }

                    return points;
                })
            );
    }

    public updatePointsValue(pointValues) {
        return this.http
            .put(
                `${this.appConfig.apiUrl}/api/points/updatePointValues`,
                pointValues
            )
            .pipe(
                tap((res: any) => {
                    if (res.status === 200) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
    }

    public updatePointsValueNew(pointValues, calc) {
        return this.http
            .put(
                `${this.appConfig.apiUrl}/api/updatePointValues/updatePointValues?calc=` +
                    calc,
                pointValues
            )
            .pipe(
                tap((res: any) => {
                    if (res.status === 200) {
                        return true;
                    } else {
                        return false;
                    }
                })
            );
    }
}
