import { RepairTicketModule } from './../ticket/repair/repair-ticket/repair-ticket.module';
import { InAndOutPopupsModule } from './../device-assets/spare-parts-manage/shared/in-and-out-popups/in-and-out-popups.module';
import { FileUploadModule } from 'primeng/fileupload';
import { ModelSelectorModule } from './../../common/components/model-selector/model-selector.module';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { TopoOverviewService } from './../monitor/topoOverview/topoOverview.service';
import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyComponent } from './energy/energy.component';
import { RouterModule } from '@angular/router';
import { OverviewComponent } from './overview/overview.component';
import { SharedModule } from 'src/app/shared/shared.module';
import {
  ScrollPanelModule,
  CalendarModule,
  CheckboxModule,
  SpinnerModule,
  PaginatorModule,
  DialogModule,
  MultiSelectModule,
  ConfirmDialogModule,
  TabViewModule,
  InputTextModule,
  ConfirmationService,
  OverlayPanelModule,
  SidebarModule,
  ProgressSpinnerModule,
  TooltipModule,
  InputTextareaModule,
} from 'primeng/primeng';
import { TableModule } from 'primeng/table';
import { StationInfoComponent } from './overview/station-info/station-info.component';
import { HealthCardComponent } from './overview/health-card/health-card.component';
import { PowerGenerationCardComponent } from './overview/power-generation-card/power-generation-card.component';
import { StationMonitorService } from '../monitor/shared/index.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { DeviceOnlineRateCardComponent } from './overview/device-online-rate-card/device-online-rate-card.component';
import { RealTimeAlarmCardComponent } from './overview/real-time-alarm-card/real-time-alarm-card.component';
import { CommunicationStatusCardComponent } from './overview/communication-status-card/communication-status-card.component';
import { OperatingEfficiencyCardComponent } from './overview/operating-efficiency-card/operating-efficiency-card.component';
import { WeatherConditionsCardComponent } from './overview/weather-conditions-card/weather-conditions-card.component';
import { DeviceStatusComponent } from './device-status/device-status.component';
import { DeviceListComponent } from './device-list/device-list.component';
import { TopologyComponent } from './device-list/topology/topology.component';
import { LineComponent } from './device-list/line/line.component';
import { BoxChangeComponent } from './device-list/box-change/box-change.component';
import { FjComponent } from './device-list/fj/fj.component';
import { ConcentratedInverterComponent } from './device-list/concentrated-inverter/concentrated-inverter.component';
import { StringInverterComponent } from './device-list/string-inverter/string-inverter.component';
import { ConfluenceBoxComponent } from './device-list/confluence-box/confluence-box.component';
import { ElectricMeterComponent } from './device-list/electric-meter/electric-meter.component';
import { WeatherStationComponent } from './device-list/weather-station/weather-station.component';
import { OtherComponent } from './device-list/other/other.component';
import { DeviceService } from 'src/app/components/monitor/shared/device.service';
import { ClassesCategoryModule } from '../../common/components/classes-category/classes-category.module';
import { DeviceDetailComponent } from './device-detail/device-detail.component';
import { OperationalMonitoringComponent } from './device-detail/operational-monitoring/operational-monitoring.component';
import { EquipmentFilesComponent } from './device-detail/equipment-files/equipment-files.component';
import { DeviceManageService } from '../device-assets/device-manage/device-manage.service';
import { DeviceTopologyComponent } from './device-detail/device-topology/device-topology.component';
import { DeviceManageModule } from '../device-assets/device-manage/device-manage.module';
import { ObjectSelectorModule } from 'src/app/common/components/object-selector/object-selector.module';
import { PointConfigModule } from '../monitor/shared/point-config/point-config.module';
import { MaintenanceRecordsComponent } from './device-detail/maintenance-records/maintenance-records.component';
import { DeviceInventoryQueryComponent } from './device-detail/device-inventory-query/device-inventory-query.component';
import { DeviceDocumentComponent } from './device-detail/device-document/device-document.component';
import { AddDocumentComponent } from './device-detail/device-document/add/add-document.component';
import { DeviceSubInfoComponent } from './device-detail/device-topology/device-sub-info/device-sub-info.component';
import { DeviceDialogComponent } from './device-detail/shared/device-dialog/device-dialog.component';
import { OperateDefectModule } from '../defect/defect-manage/operate-defect/operate-defect.module';
import { OperateTicketModule } from '../ticket/operate/operate-ticket/operate-ticket.module';
import { InspectionsSharedModule } from '../inspections/shared/inspections-shared.module';
import { MaintainsSharedModule } from '../maintains/shared/maintains-shared.module';
import { WindTurbinesComponent } from './device-list/wind-turbines/wind-turbines.component';
import { WindDeviceMonitorComponent } from './device-detail/wind-device-monitor/wind-device-monitor.component';
import { LocationModule } from '@common/components/location/location.module';

const routes: Routes = [
  {
    path: 'overview',
    component: OverviewComponent,
  },
  {
    path: 'energy',
    component: EnergyComponent,
  },
  {
    path: 'deviceStatus',
    component: DeviceStatusComponent,
  },
  {
    path: 'deviceList',
    component: DeviceListComponent,
  },
];
@NgModule({
  declarations: [
    OverviewComponent,
    EnergyComponent,
    StationInfoComponent,
    HealthCardComponent,
    PowerGenerationCardComponent,
    DeviceOnlineRateCardComponent,
    RealTimeAlarmCardComponent,
    CommunicationStatusCardComponent,
    OperatingEfficiencyCardComponent,
    WeatherConditionsCardComponent,
    DeviceStatusComponent,
    DeviceListComponent,
    TopologyComponent,
    LineComponent,
    BoxChangeComponent,
    FjComponent,
    ConcentratedInverterComponent,
    StringInverterComponent,
    ConfluenceBoxComponent,
    ElectricMeterComponent,
    WeatherStationComponent,
    OtherComponent,
    DeviceDetailComponent,
    OperationalMonitoringComponent,
    EquipmentFilesComponent,
    DeviceTopologyComponent,
    MaintenanceRecordsComponent,
    DeviceInventoryQueryComponent,
    DeviceDocumentComponent,
    AddDocumentComponent,
    DeviceSubInfoComponent,
    DeviceDialogComponent,
    // WindDeviceMonitorComponent,
    // WindTurbinesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScrollPanelModule,
    CalendarModule,
    TableModule,
    CheckboxModule,
    SpinnerModule,
    PaginatorModule,
    DialogModule,
    MultiSelectModule,
    ConfirmDialogModule,
    TabViewModule,
    InputTextModule,
    InputTextareaModule,
    SidebarModule,
    OverlayPanelModule,
    TooltipModule,
    ProgressSpinnerModule,
    ClassesCategoryModule,
    // DeviceManageModule,
    ObjectSelectorModule,
    PointConfigModule,
    OperateDefectModule,
    OperateTicketModule,
    TieredMenuModule,
    ModelSelectorModule,
    InAndOutPopupsModule,
    FileUploadModule,
    InspectionsSharedModule,
    MaintainsSharedModule,
    RepairTicketModule,
    LocationModule,
    RouterModule.forChild(routes),
  ],
  exports: [DeviceDetailComponent, OverviewComponent],
  providers: [
    StationMonitorService,
    CalendarLocales,
    ConfirmationService,
    TopoOverviewService,
    DeviceService,
    DeviceManageService,
  ],
})
export class StationMonitorModule { }
