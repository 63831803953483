import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UploadPicsComponent } from './upload-pics.component';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/shared/shared.module';
import { FileService } from '../../services/file.service';
import { FileUploadModule, LightboxModule } from 'primeng/primeng';
import { ViewerModule } from '../viewer/viewer.module';
@NgModule({
    declarations: [UploadPicsComponent],
    imports: [
        CommonModule,
        HttpClientModule,
        SharedModule,
        FileUploadModule,
        LightboxModule,
        ViewerModule,
    ],
    exports: [UploadPicsComponent],
    providers: [FileService],
})
export class UploadPicsModule {}
