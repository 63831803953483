import { ButtonModule } from 'primeng/button';
import { PaginatorModule } from 'primeng/paginator';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { TableModule } from 'primeng/table';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { TemplateSelectorComponent } from './template-selector.component';
import { InputTextareaModule } from 'primeng/primeng';

const primengModule = [
  TableModule,
  DialogModule,
  InputTextModule,
  InputTextareaModule,
  PaginatorModule,
  ButtonModule,
];

@NgModule({
  declarations: [TemplateSelectorComponent],
  exports: [TemplateSelectorComponent],
  imports: [SharedModule, ...primengModule],
})
export class TemplateSelectorModule {}
