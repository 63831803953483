import { WidgetsService } from './../widgets.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as _ from 'lodash';

@Component({
  selector: 'app-alarm-statistics',
  templateUrl: './alarm-statistics.component.html',
  styleUrls: ['./alarm-statistics.component.less'],
})
export class AlarmStatisticsComponent implements OnInit {
  loading: boolean;

  public alarmLevelsColors = [
    ['#FF6B00', '#FF5236', 'rgba(255,43,9,0.35)'],
    ['#FFBB00', '#FFAE36', 'rgba(245,131,31,0.26)'],
    ['#00C0FF', '#36C2FF', 'rgba(9,144,255,0.35)'],
    ['#00FF58', '#36FF7F', 'rgba(9,255,85,0.35)'],
  ];

  totalAlarmCountOption = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    tooltip: {
      trigger: 'item',
      formatter: '{b} : {c} ({d}%)',
    },
    legend: {
      type: 'scroll',
      orient: 'vertical',
      pageTextStyle: {
        color: '#fff',
      },
      pageIconColor: '#35baff',
      pageIconInactiveColor: '#98bcd6',
      left: 'right',
      top: 20,
      bottom: 20,
      data: [],
    },
    color: ['#0C559F', '#0A7EFF', '#54AAFF', '#AEDAFF'],
    series: [
      {
        name: '告警类型',
        type: 'pie',
        radius: ['50%', '70%'],
        avoidLabelOverlap: false,
        center: ['35%', '50%'],
        cursor: 'default',
        label: {
          normal: {
            show: false,
            position: 'center',
          },
          emphasis: {
            show: true,
            textStyle: {
              fontSize: '24',
              fontWeight: 'bold',
            },
          },
        },
        labelLine: {
          normal: {
            show: false,
          },
        },
        data: [],
      },
    ],
  };
  option = null;
  alarmLevel: any;

  constructor(private widgetsservice: WidgetsService, private router: Router) {}

  ngOnInit() {
    this.getAlarm({});
  }

  public getAlarm(params) {
    this.loading = true;
    this.widgetsservice.alarm1(params).then((res: any) => {
      this.loading = false;
      let data = res;
      this.option = this.buildTotalAlarmCountChart(data.alarmType);
      this.alarmLevel = data.alarmLevel;
    });
  }

  buildTotalAlarmCountChart(data) {
    let option = _.cloneDeep(this.totalAlarmCountOption);
    let lengend = [];
    data.map((item) => {
      lengend.push(item.name);
    });
    option.legend.data = lengend;
    option.series[0].data = data;
    return option;
  }

  public clickEvent(event) {
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/yxjs'],
      })
    );
    this.router.navigate(['/monitorCenter/alarm'], {
      queryParams: {
        cycleType: 'month',
      },
    });
  }
}
