import { Injectable } from '@angular/core';

import { AuthHttp } from '../../core/auth-http';
import { AppConfigService } from '../../app.config';
import { CommonService } from './common.service';

@Injectable()
export class CommentsService {
    public constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private common: CommonService
    ) {}

    //查询评论
    public getComments(params) {
        return this.http.get(`${this.appConfig.apiUrl}/api/comments`, {
            search: this.common.filterEffectParam(params),
        });
    }

    // 添加评论
    public addComments(params) {
        return this.http.post(`${this.appConfig.apiUrl}/api/comments`, params).toPromise();
    }

    // 删除评论
    public deleteComments(commentId) {
        return this.http
            .delete(`${this.appConfig.apiUrl}/api/comments`, {
                search: { commentId },
            })
            .toPromise();
    }

    // 添加评论
    public addWorkOrdersComments(params) {
        return this.http
            .put(`${this.appConfig.apiUrl}/api/workOrders/comment`, {}, { params })
            .toPromise();
    }
}
