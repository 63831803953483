import { Injectable } from "@angular/core";
import { Subject, Observable } from "rxjs";

import { AuthHttp } from "../../../core/auth-http";
import { AppConfigService } from "../../../app.config";
import { Menu } from "./nav.model";
import { tap, map } from "rxjs/operators";

@Injectable()
export class NavService {
    public collapseSubject = new Subject();

    private apiUrl: string = this.appConfig.apiUrl;
    private KEY_LOGO = "frameLogo";
    private KEY_FAVORITE = "menuFavorite";

    constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

    public getNavData(type): Observable<Menu[]> {
        return this.http
            .get(`${this.apiUrl}/api/menu/init`, { search: { datas: type } })
            .pipe(map((res: any) => this.makeNavTree(res.menus)));
    }

    public getInit(type?) {
        return this.http.get(`${this.apiUrl}/api/menu/init`, {
            search: { datas: type }
        });
    }

    public getFavorite() {
        return this.http.get(
            `${this.apiUrl}/api/index/getUserConfig?cfgType=${this.KEY_FAVORITE}`
        );
    }

    public getLogoConfig() {
        return this.http.get(
            `${this.apiUrl}/api/index/getUserConfig?cfgType=${this.KEY_LOGO}`
        );
    }

    public getFirstAccessableMenu(nav: Menu[]): Menu {
        if (!nav) {
            return null;
        }
        for (const item of nav) {
            if (item.children && item.children.length) {
                return item;
            }
        }
    }

    public getPageStyle() {
        return this.http.get(`${this.apiUrl}/api/menu/style`);
    }

    public setPageStyle(params) {
        return this.http.put(`${this.apiUrl}/api/menu/style`, "", { params });
    }

    private makeNavTree(nav: Menu[]) {
        if (!nav || !nav.length) {
            return [];
        }
        for (let item of nav) {
            if (typeof item.parentId !== undefined) {
                let found = nav.find(
                    (navItem: Menu) => navItem.menuId === item.parentId
                );
                if (found) {
                    found.children.push(item);
                }
            }
        }
        return nav.filter((n: Menu) => typeof n.parentId !== undefined);
    }
}
