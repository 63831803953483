import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'lodash';

import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { PowerStation, DeviceArea } from '../../monitor/shared/index.model';
import { CommonService } from '../../../common/services/common.service';
import { DefectParam } from '../../defect/shared/index.model';
import { tap } from 'rxjs/operators';
import { TipService } from '@common/services/tip.service';

@Injectable()
export class DeviceManageService {
  public apiUrl: string = this.appConfig.apiUrl;
  public paramID: string = '';
  public pointsImportSource = new Subject<any>();
  public pointsImport$ = this.pointsImportSource.asObservable();
  public deviceChange = new Subject<boolean>();
  public isDevice = new Subject<boolean>();

  public deviceAssetSaved = new Subject<boolean>();
  public tabViewDiaClose = new Subject<boolean>();

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService,
    private route: ActivatedRoute,
    private tipservice: TipService
  ) { }

  // 根据电站 加载 查询类别
  public getDeviceData(stationIds) {
    // console.log('电站stationIds---', stationIds);
    return this.http.get(`${this.apiUrl}/api/device/deviceClasses`, {
      search: { stationIds },
    });
  }

  public getDeviceTree(params) {
    return this.http.get(`${this.apiUrl}/api/device/tree`, { params: this.common.filterEffectParam(params) }).toPromise()
  }

  // 查询设备状态
  public getselectState(codeId) {
    return this.http.get(`${this.apiUrl}/api/propList/propLists/search`, {
      search: { codeId },
    });
  }

  // 获取设备台账字段列表 /api/device/deviceFields
  public getDeviceFields() {
    return this.http.get(`${this.apiUrl}/api/device/deviceFields`);
  }
  // 查询设备所有列表 参数有 电站，类别，状态，设备编号/名称，分页
  public getDeviceAll(
    stationIds,
    classIds,
    deviceStatus,
    deviceNoOrName,
    companyIds,
    page,
    size,
    treeType,
    treeId,
    treeParentId
  ) {
    const searchParam = {
      stationIds,
      classIds,
      deviceStatus,
      deviceNoOrName,
      companyIds,
      page,
      size,
      treeType,
      treeId,
      treeParentId
    };
    return this.http.get(`${this.apiUrl}/api/device/devices`, {
      search: this.common.filterEffectParam(searchParam),
    });
    /* ??? (res) => {res.json()} */
  }
  /**
   * 获取URL中参数;
   * @param {string} id  参数名
   * @returns {string}  参数值
   */
  public getParam(id: string): string {
    this.route.queryParams.subscribe((param: Params) => {
      this.paramID = param[id];
    });
    console.log('url中参数---', this.paramID);
    return this.paramID;
  }
  // 保存保存用户选择测列 /api/device/saveSelectedDeviceFields
  public saveSelectedDeviceFields(deviceClassId, fields) {
    const searchParam = { deviceClassId, fields };
    return this.http.put(`${this.apiUrl}/api/device/saveSelectedDeviceFields`, {
      search: this.common.filterEffectParam(searchParam),
    });
  }

  // 新的保存保存用户选择测列/api/device/saveDeviceListFields，参数只有数组列信息
  public saveSelectedDeviceFieldsNew(fields) {
    return this.http.put(
      `${this.apiUrl}/api/device/saveDeviceListFields`,
      fields
    );
  }
  // 导出设备列表  /api/device/exportDevices
  public exportDevices(classIds, stationIds, deviceStatus, deviceNoOrName) {
    return this.common.fileExport(`${this.apiUrl}/api/device/export`, {
      classIds,
      stationIds,
      deviceStatus,
      deviceNoOrName,
    });
  }
  // 下载模板
  public download(type: string) {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/device/downloadTemplate`,
      {
        search: { type },
        responseType: 'blob',
        observe: 'response',
      }
    );
  }
  // 导入模板数据
  public importPoints(stationId: string, file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('stationId', stationId);
    return this.http
      .post(`${this.appConfig.apiUrl}/api/device/import`, formData)
      .pipe(
        tap((log) => {
          this.pointsImportSource.next(log);
        })
      );
  }
  // 删除设备 /api/device/deleteDevice
  public deleteDevice(deviceIds) {
    return this.http.delete(`${this.apiUrl}/api/device`, {
      search: { deviceIds },
    });
  }
  // 根据设备id获取设备资料
  public getDeviceById(deviceId: string) {
    return this.http.get(`${this.apiUrl}/api/device/deviceInfo`, {
      search: { deviceId },
    });
  }
  // 获取当前用户有权限的电站列表DTO
  public getSampleStationAll(): Observable<PowerStation[]> {
    return this.http.get(`${this.apiUrl}/api/index/getSampleStationAll`);
  }

  // 电站区域
  public getStationArea(stationId): Observable<DeviceArea[]> {
    return this.http.get(`${this.apiUrl}/api/location/getStationLocation`, {
      search: { stationId },
    });
  }
  // 查找设备图片
  public getDevicePic(deviceId) {
    return this.http.get(`${this.apiUrl}/api/device/devicePics`, {
      search: { deviceId },
    });
  }

  public saveDevice(deviceForm) {
    return this.http.put(`${this.apiUrl}/api/device`, deviceForm);
  }

  // 设备资产--运维记录Tab页
  // 设备情况查看
  public getDeviceViewData(param) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/deviceView/collectByYear`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }

  // 缺陷记录
  public getDefectInfoList(param) {
    return this.http.get(`${this.apiUrl}/api/defects`, {
      search: this.common.filterEffectParam(param),
    });
  }

  public formatDefectInfoList(data) {
    let defectInfoList: any[] = [];
    const timeFormat = 'YYYY-MM-DD HH:mm:ss';
    for (let item of data) {
      let defectInfo: any = {};
      defectInfo.defectId = item.defectId;
      defectInfo.defectCode = item.defectCode;
      defectInfo.shortName = item.shortName;
      defectInfo.defectSourceText = item.defectSourceText;
      defectInfo.defectDesc = item.defectDesc;
      defectInfo.currentStatus = item.currentSituation;
      defectInfo.stationStageText = item.stationStageText;
      defectInfo.reportTime = item.reportTime
        ? moment(new Date(item.reportTime)).format(timeFormat)
        : '';
      defectInfo.defectDeviceTypeText = item.defectDeviceTypeText;
      defectInfo.defectTypeText = item.defectTypeText;
      defectInfo.defectTechTypeText = item.defectTechTypeText;
      defectInfo.supplierName = item.supplierName;
      defectInfo.planEndTime = item.planEndTime
        ? moment(new Date(item.planEndTime)).format(timeFormat)
        : '';
      defectInfo.defectStatusText = item.defectStatusText;
      defectInfo.endTime = item.endTime
        ? moment(new Date(item.endTime)).format(timeFormat)
        : '';
      defectInfo.resultDesc = item.resultDesc;
      defectInfo.responsibleDept = item.responsibleDeptName;
      defectInfo.cooperateDept = item.coordinationDepart;
      defectInfo.responsiblePersonName = item.responsiblePersonName;
      defectInfo.checkStatusText = item.checkStatusText;
      defectInfo.checkTime = item.checkTime
        ? moment(new Date(item.checkTime)).format(timeFormat)
        : '';
      defectInfoList.push(defectInfo);
    }
    return defectInfoList;
  }

  // 运维记录
  public getOptionalTasks(param) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/maintain/tasks`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }

  // 设备变更
  public getDeviceChanges(param) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/device/getDeviceChangeInfo`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }

  public findDeviceChange(changeId) {
    return this.http.get(`${this.appConfig.apiUrl}/api/device/deviceChange`, {
      search: { changeId: changeId },
    });
  }

  public addDeviceChange(changeDevice) {
    return this.http
      .post(`${this.apiUrl}/api/device/deviceChange`, changeDevice)
      .pipe(
        tap((res) => {
          this.deviceChange.next(true);
        })
      )
      .toPromise();
  }

  public updateDeviceChange(changeDevice) {
    return this.http
      .put(`${this.apiUrl}/api/device/deviceChange`, changeDevice)
      .pipe(
        tap((res) => {
          this.deviceChange.next(true);
        })
      )
      .toPromise();
  }

  public deleteDeviceChanges(changeIds) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/device/deleteDeviceChange`, {
        search: { changeIdList: changeIds },
      })
      .pipe(
        tap((res) => {
          this.deviceChange.next(true);
          this.tipservice.tip('success', '操作成功');
        })
      )
      .toPromise();
  }

  // 设备资产--备品备件Tab页
  // 库存列表
  public getClassifyMaterialInventorys(param, page, size) {
    return this.http.get(
      `${this.apiUrl}/api/material/classifyMaterialInventorys`,
      {
        search: _.assign(this.common.filterEffectParam(param), {
          page,
          size,
        }),
      }
    );
  }

  // 设备文档
  public getDeviceDocument(param, page, size) {
    return this.http.get(`${this.appConfig.apiUrl}/api/doc/list`, {
      search: _.assign(this.common.filterEffectParam(param), {
        page,
        size,
      }),
    });
  }

  // 设备文档
  public getDeviceDocumentNew(param) {
    return this.http.get(`${this.appConfig.apiUrl}/api/device/docs`, {
      search: this.common.filterEffectParam(param),
    });
  }

  // 附件下载
  public exportFile(docId) {
    return this.common.fileExport(`${this.appConfig.apiUrl}/api/doc/download`, {
      docId,
    });
  }

  // 附件删除
  public deleteFile(docIds) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/doc/delete`, {
        search: { docIds },
      })
      .pipe(
        tap((res) => {
          this.isDevice.next(true);
          return res;
        })
      )
      .toPromise();
  }

  // 附件上传
  public importFile(file, memo) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('memo', memo);
    return this.http
      .post(`${this.appConfig.apiUrl}/api/doc/upload`, formData)
      .toPromise();
  }

  // 附件上传
  public importFileNew(file, memo) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('memo', memo);
    return this.http
      .post(`${this.appConfig.apiUrl}/api/doc/upload`, formData)
      .toPromise();
  }

  // 上传添加rela
  public createRela(rela) {
    return this.http.post(`${this.apiUrl}/api/doc/rela`, rela).pipe(
      tap((res) => {
        this.isDevice.next(true);
        return res;
      })
    );
  }

  // 上传添加uploadFile
  public uploadFile(rela) {
    console.log(rela);
    const formData: FormData = new FormData();
    formData.append('file', rela.file, rela.file.name);

    return this.http
      .put(`${this.apiUrl}/api/device/docs`, formData, {
        params: {
          deviceId: rela.deviceId,
          docType: rela.docType,
          memo: rela.memo,
        },
      })
      .pipe(
        tap((res) => {
          this.isDevice.next(true);
          return res;
        })
      );
  }

  //获取自设备列表/api/doc/childDevices
  public getChildDevices(deviceId) {
    return this.http.get(`${this.appConfig.apiUrl}/api/device/childDevices`, {
      search: { pDeviceId: deviceId },
    });
  }

  //更新子设备关联信息
  public changeChildDevice(param) {
    return this.http.put(`${this.apiUrl}/api/device/changeChildDevice`, '', {
      search: param,
    });
  }

  //删除子设备/api/deleteChildDevice
  public deleteChildDevice(param) {
    return this.http.delete(`${this.apiUrl}/api/device/deleteChildDevice`, {
      search: {
        childDeviceIds: param.childDeviceIds,
        deleteType: param.deleteType,
      },
    });
  }

  public getManagerRole() {
    return this.http.get(`${this.apiUrl}/api/device/isManager`);
  }

  public createDeviceTypes() {
    return this.http.get(`${this.apiUrl}/api/device/createDeviceTypes`);
  }

  public getDeviceInfoFields(params) {
    return this.http.get(`${this.apiUrl}/api/device/deviceInfoFields`, {
      params: this.common.filterEffectParam(params),
    });
  }
}
