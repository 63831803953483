import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { TipService } from '@common/services/tip.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { ClassesType } from '../../../../common/models/classes-type.model';
import { CommonService } from '../../../../common/services/common.service';
import { CodeTableService } from '../../../../core/code-table.service';
import {
  IoType,
  MaterialType,
} from '../../../device-assets/shared/index.model';
import { DefectManageService } from '../defect-manage.service';
import { CalendarLocales } from './../../../../shared/locales/calendar.locales';
import { buildWorkFlow } from './work-flow.echarts';

@Component({
  selector: 'app-operate-defect',
  templateUrl: 'operate-defect.component.html',
  styleUrls: ['operate-defect.component.less'],
})
export class OperateDefectComponent implements OnInit {
  @Input() public type = 'add';
  @Input() public defectId = 0;
  @Input() public data = null;
  @Input() public isShow = false;

  @Output() hideEvent = new EventEmitter<boolean>();
  @Output() refreshEvent = new EventEmitter<boolean>();
  // 标题
  title: string;
  // defect表单
  form: FormGroup;
  // 字段
  fields: any;
  // 电站id
  stationId: any;
  // 电站选择器参数
  staParams = null;
  // 用户选择器弹窗 开关
  isShowUserDialog: boolean;
  // 用户选择弹窗类型 单选/多选
  userDialogType: boolean;
  // 用户选择器 修改字段名称
  userDialogTarget: any;
  // 用户选择器参数
  person = [];
  // 日历参数
  canlendarLocale: any;

  // 缺陷设备 表格字段
  public deviceColumns = [
    {
      header: '设备编号',
      field: 'objNo',
    },
    {
      header: '设备名称',
      field: 'objName',
    },
    {
      header: '型号',
      field: 'modelNo',
    },
    {
      header: '工作量-h',
      field: 'workHours',
      type: 'spinner',
    },
    {
      header: '损失电量-kWh',
      field: 'lossEnergy',
      type: 'spinner',
    },
    {
      header: '消缺费用-元',
      field: 'objCost',
      type: 'spinner',
    },
    {
      header: '操作',
      field: '',
      width: '50px',
    },
  ];
  // 缺陷设备弹窗
  isShowObject: boolean;

  // 处理过程 默认
  activeTableType = 'tickets';
  // 关联工单 列表
  ticketsList = [];
  // 关联工单 数量
  ticketsListCount: number;
  // 关联工单 表格字段
  ticketsColumns = [
    { header: '工单单号', field: 'orderCode' },
    { header: '工单类型', field: 'orderTypeText' },
    { header: '派单时间', field: 'dispatchTime', type: 'date' },
    { header: '负责人', field: 'executorName' },
    { header: '当前节点', field: 'nodeName' },
    { header: '更新时间', field: 'updateTime', type: 'date' },
  ];
  // 备品备件 列表
  materialsList = [];
  // 备品备件 数量
  materialsCount: number;
  // 备品备件 表格字段
  materialsColumns = [
    { header: '出入库单号', field: 'ioCode' },
    { header: '操作类别', field: 'ioSubTypeText' },
    { header: '出入库时间', field: 'ioTime', type: 'date' },
    { header: '物料料号', field: 'materialNo' },
    { header: '物料名称', field: 'materialName' },
    { header: '出入库数量', field: 'ioNum' },
  ];

  // 订单Id
  orderId: any;
  // 工作流程 nodes
  nodes: any;
  // 工作流程 links
  links: any;
  // 工作流程 图表 options
  options: any;

  // 图片权限
  picPermission: any;
  // 文件权限
  docPermission: any;
  // 抄送权限
  ccPermission: any;
  // 评论权限 （由评论列表中参数控制）
  commentPermission: any;
  // 弹窗 按钮组
  buttons: any;

  // 缺陷检查 弹窗开关
  defectCheckDialog = false;
  // 缺陷检查默认值
  checkDialog = {
    checkStatus: '02',
    checkMemo: null,
  };
  // 缺陷检查状态 选项
  checkStatuses: SelectItem[];

  // 出入库弹窗 开关
  isShowRecords: boolean;
  // 出入库 传入参数
  recordsParam: any;
  // materialType
  materialType = MaterialType.spareParts;
  // ClassesType
  classesType = ClassesType.spareParts;
  // 是否是进行节点扭转 选择用户
  nextUserFlag: boolean;
  // 扭转用户ID
  nextUserIds: any;

  // 备注弹窗 开关
  memoDialog: boolean;
  // 备注弹窗 备注字段
  memo: any;

  // 按钮行为
  buttonAction: any;

  // 评论列表
  commentsList: any[];

  // 设备Id
  deviceId: any;
  objClassOptions: any;
  ioTypes: IoType;
  showOperateRepairTickets: boolean;
  repairTicketData: { parentOrderId: any; stationId: any };
  repairTicketType: string;
  maxDateValue: Date = new Date();

  constructor(
    private fb: FormBuilder,
    private defectmanageservice: DefectManageService,
    private calendarLocales: CalendarLocales,
    private commonservice: CommonService,
    private codeTableService: CodeTableService,
    private confirmationService: ConfirmationService,
    private router: Router,
    private tipservice: TipService
  ) {
    // 初始化 日历参数
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    // 初始化 缺陷单form
    this.initForm();
  }

  ngOnInit() {
    // 根据 type 判断 弹窗显示 挑剔
    if (this.type === 'add') {
      this.title = '新增缺陷单';
    } else if (this.type === 'view') {
      this.title = '查看缺陷单';
    } else if (this.type === 'edit') {
      this.title = '编辑缺陷单';
    }
    // 获取设备id 由告警转缺陷时 需要
    this.deviceId = (this.data && this.data.deviceId) || null;
    // 初始化数据
    this.getDefectData();
  }

  /**
   * 初始化form表单
   */
  private initForm() {
    this.form = this.fb.group({
      // 基础信息
      defectId: new FormControl(null), // 缺陷Id
      defectCode: new FormControl(null), // 缺陷单号
      orderId: new FormControl(null), // orderId
      parentOrderId: new FormControl(null), // parentOrderId
      stationId: new FormControl(null), // 电站Id
      stationName: new FormControl(null), // 电站名称
      reporter: new FormControl(null), // 发现人
      reporterName: new FormControl(null), // 发现人名称
      reportTime: new FormControl(null), // 发现时间
      defectType: new FormControl(null), // 缺陷类型
      originTime: new FormControl(null), // 缺陷发生时间
      defectSource: new FormControl(null), // 缺陷来源
      defectLevel: new FormControl(null), // 缺陷等级
      defectDesc: new FormControl(null), // 缺陷现象
      defectDevices: this.fb.array([]), // 设备
      repairTickets: this.fb.array([]), // 抢修单
      workTickets: this.fb.array([]), // 工作票
      operationTickets: this.fb.array([]), // 操作票
      materials: this.fb.array([]), // 备品备件
      responsiblePerson: new FormControl(null), // 负责人
      responsiblePersonName: new FormControl(null), // 负责人名称
      players: this.fb.array([]), // 参与人
      playersText: new FormControl(''), // 参与人
      startTime: new FormControl(null), // 开始处理时间
      endTime: new FormControl(null), // 处理完成时间
      workHours: new FormControl(null), // 工作量(h)
      defectStatus: new FormControl(null), // 缺陷状态
      lossEnergy: new FormControl(null), // 损失电量(kWh)
      defectCost: new FormControl(null), // 消缺费用(元)
      resultDesc: new FormControl(null), // 处理结果说明
      pics: this.fb.array([]), // 缺陷图片
      docs: this.fb.array([]), // 缺陷附件
      checker: new FormControl(null), // 检查人
      checkerName: new FormControl(null), // 检查人名称
      checkTime: new FormControl(null), // 检查时间
      checkStatus: new FormControl(null), // 检查结果
      checkStatusText: new FormControl(null), // 检查结果
      checkMemo: new FormControl(null), // 检查备注
      // 工作流程
      workflow: this.fb.group({
        nodes: this.fb.array([]),
        links: this.fb.array([]),
      }),

      // 流程日志
      workflowLogs: this.fb.array([]),

      // 抄送
      ccUsers: this.fb.array([]),
    });
  }

  /**
   * 初始化 页面数据
   */
  private getDefectData() {
    this.commonservice.mask.show();
    this.defectmanageservice
      .getDefectByDefectId(this.defectId, { deviceId: this.deviceId })
      .subscribe(
        (res: any) => {
          // 页面字段
          this.fields = res.fields;
          for (const key in this.fields) {
            if (this.fields.hasOwnProperty(key)) {
              const element = this.fields[key];
              if (
                key === 'defectType' ||
                key === 'defectSource' ||
                key === 'defectLevel' ||
                key === 'defectStatus'
              ) {
                if (element.props) {
                  element.props.map((item) => {
                    item.value = item.first;
                    item.label = item.second;
                  });
                }
              }
            }
          }
          // 按钮组
          this.buttons = res.buttons;
          // 返回值
          const values = res.defect;
          if (this.data && this.data.defectSource) {
            values.defectSource = this.data.defectSource;
          }
          if (this.data && this.data.parentOrderId) {
            values.parentOrderId = this.data.parentOrderId;
          }
          if (this.data && this.data.defectDesc) {
            values.defectDesc = this.data.defectDesc;
          }
          // 图片权限
          this.picPermission = res.picPermission;
          // 文档权限
          this.docPermission = res.docPermission;
          // 抄送权限
          this.ccPermission = res.ccPermission;
          // 评论权限
          this.commentPermission = res.commentPermission;
          // orderId
          this.orderId = values.orderId;
          // 处理电站
          this.stationId = values.stationId;
          this.staParams = {
            sta_stationIds: values.stationId,
          };
          // 时间格式处理
          values.originTime = values.originTime
            ? moment(values.originTime).format('YYYY-MM-DD HH:mm')
            : null;
          values.reportTime = values.reportTime
            ? moment(values.reportTime).format('YYYY-MM-DD HH:mm')
            : null;
          values.createTime = values.createTime
            ? moment(values.createTime).format('YYYY-MM-DD HH:mm')
            : null;
          values.startTime = values.startTime
            ? moment(values.startTime).format('YYYY-MM-DD HH:mm')
            : null;
          values.endTime = values.endTime
            ? moment(values.endTime).format('YYYY-MM-DD HH:mm')
            : null;
          // 处理缺陷设备
          if (values.defectDevices !== null) {
            const control = this.form.get('defectDevices') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.defectDevices.map((item, index) => {
              control.push(this.initDefectDevice());
            });
          } else {
            values.defectDevices = [];
          }
          // 处理过程
          // 抢修单
          if (values.repairTickets !== null) {
            const control = this.form.get('repairTickets') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.repairTickets.map((item) => {
              control.push(this.initTickets());
            });
          } else {
            values.repairTickets = [];
          }
          // 工作票
          if (values.workTickets !== null) {
            const control = this.form.get('workTickets') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.workTickets.map((item) => {
              control.push(this.initTickets());
            });
          } else {
            values.workTickets = [];
          }
          // 操作票
          if (values.operationTickets !== null) {
            const control = this.form.get('operationTickets') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.operationTickets.map((item) => {
              control.push(this.initTickets());
            });
          } else {
            values.operationTickets = [];
          }
          // 关联工单（抢修单+工作票+操作票）
          this.ticketsList = _.concat(
            values.repairTickets,
            values.workTickets,
            values.operationTickets
          );
          // 关联工单 数量
          this.ticketsListCount = this.ticketsList.length;
          // 备品备件
          if (values.materials !== null) {
            const control = this.form.get('materials') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.materials.map((item) => {
              control.push(this.initMaterials());
            });
          } else {
            values.materials = [];
          }
          // 备品备件 列表
          this.materialsList = values.materials;
          // 备品备件 数量
          this.materialsCount = this.materialsList.length;

          // 处理参与人
          if (values.players !== null) {
            const control = this.form.get('players') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            const playersText = values.players.map((item) => {
              control.push(this.initUsers());
              return item.userName;
            });
            values.playersText = playersText.join(',');
          } else {
            values.players = [];
          }

          // 照片
          if (values.pics !== null) {
            const control = this.form.get('pics') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.pics.map((item) => {
              control.push(this.initDoc());
            });
          } else {
            values.pics = [];
          }

          // 文件
          if (values.docs !== null) {
            const control = this.form.get('docs') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.docs.map((item) => {
              control.push(this.initDoc());
            });
          } else {
            values.docs = [];
          }

          // 工作流程
          if (values.workflow !== null) {
            const workflow = values.workflow;
            for (const key in workflow) {
              if (workflow.hasOwnProperty(key)) {
                const element = workflow[key];
                if (key === 'nodes') {
                  this.nodes = element;
                  const control = (this.form.get('workflow') as FormGroup)
                    .controls[key] as FormArray;
                  while (control.length) {
                    control.removeAt(control.length - 1);
                  }
                  element.map((item) => {
                    control.push(this.initNodes());
                  });
                } else if (key === 'links') {
                  this.links = element;
                  const control = (this.form.get('workflow') as FormGroup)
                    .controls[key] as FormArray;
                  while (control.length) {
                    control.removeAt(control.length - 1);
                  }
                  element.map((item) => {
                    control.push(this.initLinks());
                  });
                }
              }
            }
            this.options = buildWorkFlow(this.links, this.nodes);
          } else {
            values.workflow = {};
          }

          // 流程日志
          if (values.workflowLogs) {
            const control = this.form.get('workflowLogs') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.workflowLogs.map((item) => {
              control.push(this.initWorkflowLogs());
            });
          } else {
            values.workflowLogs = [];
          }

          // 抄送
          if (values.ccUsers !== null) {
            const control = this.form.get('ccUsers') as FormArray;
            while (control.length) {
              control.removeAt(control.length - 1);
            }
            values.ccUsers.map((item) => {
              control.push(this.initCcUser());
            });
          } else {
            values.ccUsers = [];
          }

          // 评论
          if (values.comments === null) {
            values.comments = [];
            this.commentsList = [];
          } else {
            this.commentsList = values.comments;
          }
          // 表单填值
          this.form.patchValue(values);
          // 查看状态显示权限
          if (this.type === 'view') {
            // 图片权限
            this.picPermission = 0;
            // 文档权限
            this.docPermission = 0;
            // 抄送权限
            this.ccPermission = 0;
            this.form.disable();
          }
        },
        () => { },
        () => {
          this.commonservice.mask.hide();
        }
      );
  }

  // 流程日志数组
  getWorkflowLogs(form) {
    return form.controls.workflowLogs.controls;
  }
  // 弹窗关闭事件
  onHide(event) {
    this.hideEvent.emit(true);
  }
  // 电站选择器 返回事件
  outputStaParamsEvent(event) {
    const defectDevicesCount = this.form.value.defectDevices.length;
    if (
      this.stationId &&
      event.sta_stationIds !== this.stationId &&
      defectDevicesCount > 0
    ) {
      this.confirmationService.confirm({
        header: '提示',
        message: `变更电站后要重新填写缺陷设备列表，确定变更么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          this.stationId = event.sta_stationIds;
          const control = this.form.get('defectDevices') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          this.form.patchValue({
            stationId: event.sta_stationIds,
            defectDevices: [],
          });
        },
        reject: () => {
          this.staParams = {
            sta_stationIds: this.stationId,
          };
        },
      });
    } else {
      this.stationId = event.sta_stationIds;
      this.form.patchValue({
        stationId: event.sta_stationIds,
      });
    }
  }

  /**
   * 打开用户选择
   * @param <{ String }> type - 用户选择器类型 single/multi.
   * @param <{ String }> field - 当前操作的字段.
   * @param <{ Boolean }> editable - 当前字段是否客编辑.
   */
  openUserDialog(event, type, field, editable) {
    // 如果是查看或是不可编辑状态 返回
    if (this.type === 'view' || editable === false) {
      return;
    }
    this.userDialogType = type === 'single' ? true : false;
    this.userDialogTarget = field;
    const value = this.form.value[field];
    // 判断 单选还是多选
    if (this.userDialogType === true) {
      this.person = value ? [{ userId: value }] : null;
    } else {
      this.person = value.map((item) => {
        return {
          userId: item.userId,
          realName: item.userName,
        };
      });
    }
    this.isShowUserDialog = true;
  }

  // 用户选择器 返回事件
  personChange(event) {
    // 节点扭转选择用户
    if (this.nextUserFlag === true) {
      if (event.userId) {
        this.nextUserIds = event.userId;
        this.memoDialog = true;
      }
      this.nextUserFlag = false;
      return;
    }
    if (this.userDialogType) {
      this.form.patchValue({
        [this.userDialogTarget]: event.userId,
        [this.userDialogTarget + 'Name']: event.realName,
      });
    } else {
      const control = this.form.get('players') as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      const players = [];
      const playersText = event.map((item) => {
        players.push({
          userId: item.userId,
          userName: item.realName,
        });
        control.push(this.initUsers());
        return item.realName;
      });
      this.form.get('players').patchValue(players);
      this.form.get('playersText').patchValue(playersText.join(','));
    }
  }

  // 添加设备按钮事件
  addDevices(event) {
    if (!this.stationId) {
      this.tipservice.tip('warn', '请线选择电站');
      return;
    }
    this.isShowObject = true;
  }

  // 设备选择器 返回事件
  getObjectSelected(event) {
    const value = event;
    const control = this.form.get('defectDevices') as FormArray;
    const prevDefectDevices = this.form.value.defectDevices || [];
    const defectDevices = value.map((item, index) => {
      item.objId = item.deviceId;
      item.objNo = item.deviceNo;
      item.objName = item.deviceName;
      item.psrId = item.psrId;
      item.modelNo = item.modelNo;
      control.push(this.initDefectDevice());
      return item;
    });
    _.concat([], prevDefectDevices, defectDevices);
    this.form
      .get('defectDevices')
      .patchValue(_.concat([], prevDefectDevices, defectDevices));
  }

  /**
   * 移除缺陷设备
   * @param <{ String }> objId - 设备Id.
   * @param <{ Number }> index - 下标
   */
  removeDevice(event, objId, index) {
    if (objId) {
      const control = this.form.get('defectDevices') as FormArray;
      control.removeAt(index);
    }
  }
  // 关联工单和备品备件 切换
  toggleRelateOrder(type) {
    this.activeTableType = type;
  }

  // 按钮组
  buttonFunction(button) {
    if (button.formCheck === '1') {
      this.defectmanageservice
        .getNullable(button.targetNodeId)
        .then((res: any) => {
          for (const key in res) {
            if (res.hasOwnProperty(key)) {
              if (key.indexOf('.') === -1) {
                const element = res[key];
                if (this.form.value[key] === null && !element) {
                  this.tipservice.tip(
                    'warn',
                    this.fields[key].name + '字段不能为空'
                  );
                  return;
                }
              } else {
                const aNewKey = key.split('.');
                const element = res[key];
                if (
                  this.form.value[aNewKey[0]] &&
                  this.form.value[aNewKey[0]].length > 0 &&
                  !element
                ) {
                  let flag = true;
                  this.form.value[aNewKey[0]].every((item) => {
                    if (item[aNewKey[1]] === null) {
                      this.tipservice.tip(
                        'warn',
                        this.fields[key].name + '字段不能为空'
                      );
                      flag = false;
                      return false;
                    } else {
                      return true;
                    }
                  });
                  if (!flag) {
                    return;
                  }
                }
              }
            }
          }
          this.switchFunction(button);
        });
    } else {
      this.switchFunction(button);
    }
  }

  // 按钮组 操作
  switchFunction(button) {
    switch (button.buttonAction) {
      case '$remind$':
        this.remindFunction(this.defectId);
        break;
      case '$check$':
        this.openDefectCheckDialog();
        break;
      case '$save$':
        this.openMemoDialog(button);
        break;
      case 'repairTicket':
        this.repairTicketFunction(button);
        break;
      case 'rejectRepair':
        this.rejectRepairFunction(button);
        break;
      case 'finishRepair':
        this.finishRepairFunction(button);
        break;
      case 'workTicket1':
        this.workTicket1Function(button.buttonId);
        break;
      case 'workTicket2':
        this.workTicket2Function(button.buttonId);
        break;
      case 'materialOut':
        this.materialOutFunction();
        break;
      case 'materialIn':
        this.materialInFunction();
        break;
      case 'dispatch':
        this.openDispatchDialog(button);
        break;
      case 'reject':
        this.openMemoDialog(button);
        break;
      case 'close':
        this.openMemoDialog(button);
        break;
      case 'finish':
        this.finishFunction(button);
        break;
      case 'delete':
        this.deleteFunction();
        break;
      default:
        break;
    }
  }

  // 催单操作
  remindFunction(defectId) {
    this.defectmanageservice.remind(defectId).subscribe((res: string) => {
      this.tipservice.tip('success', res);
    });
  }

  // 打开缺陷检查弹窗
  openDefectCheckDialog() {
    this.codeTableService.getNemsProp('orderCheckStatus').then((res) => {
      this.checkStatuses = res;
      this.defectCheckDialog = true;
    });
  }

  // 检查操作
  checkFunction(event) {
    if (this.checkDialog.checkMemo && this.checkDialog.checkMemo.length > 500) {
      this.tipservice.tip('warn', '结果说明不超过500字符');
      return;
    }
    const params = {
      defectId: this.defectId,
      checkStatus: this.checkDialog.checkStatus,
      checkMemo: this.checkDialog.checkMemo,
    };
    this.defectmanageservice.check(params).subscribe((res: any) => {
      this.tipservice.tip('success', '提交成功');
      this.form.patchValue(res);
      this.defectCheckDialog = false;
    });
  }

  // 抢修单
  repairTicketFunction(button) {
    const params = {
      title: this.title,
      defect: this.formatParams(this.form.value),
      action: button,
      nextUserIds: null,
      memo: null,
    };
    this.defectmanageservice.defectAction(params).subscribe((res) => {
      this.tipservice.tip('success', '提交成功');
      this.repairTicketType = 'add';
      this.repairTicketData = {
        parentOrderId: this.orderId,
        stationId: this.stationId,
      };
      this.showOperateRepairTickets = true;
    });
  }

  hideOperateRepairTicketsEvent() {
    this.showOperateRepairTickets = false;
    this.hideEvent.emit(true);
    this.router.navigate(['/ticket/repair']);
  }

  // 抢修单 回退
  rejectRepairFunction(button) {
    if (this.form.value.repairTickets.length !== 0) {
      this.confirmationService.confirm({
        header: '提示',
        message: `回退将删除已创建的抢修单，确定要回退么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          const params = {
            title: this.title,
            defect: this.formatParams(this.form.value),
            action: button,
            nextUserIds: null,
            memo: null,
          };
          this.defectmanageservice.defectAction(params).subscribe((res) => {
            this.tipservice.tip('success', '提交成功');
            this.hideEvent.emit(true);
            this.refreshEvent.emit(true);
          });
        },
      });
    } else {
      const params = {
        title: this.title,
        defect: this.formatParams(this.form.value),
        action: button,
        nextUserIds: null,
        memo: null,
      };
      this.defectmanageservice.defectAction(params).subscribe((res) => {
        this.tipservice.tip('success', '提交成功');
        this.hideEvent.emit(true);
        this.refreshEvent.emit(true);
      });
    }
  }

  // 抢修单 完成
  finishRepairFunction(button) {
    if (this.form.value.repairTickets.length !== 0) {
      const params = {
        title: this.title,
        defect: this.formatParams(this.form.value),
        action: button,
        nextUserIds: null,
        memo: null,
      };
      this.defectmanageservice.defectAction(params).subscribe((res) => {
        this.tipservice.tip('success', '提交成功');
        this.hideEvent.emit(true);
        this.refreshEvent.emit(true);
      });
    } else {
      this.repairTicketType = 'add';
      this.repairTicketData = {
        parentOrderId: this.orderId,
        stationId: this.stationId,
      };
      this.showOperateRepairTickets = true;
    }
  }

  // 一种工作票
  workTicket1Function(buttonId) {
    // todo 跳转 一种操作票
    //     parentId: this.form.value.orderId,
    //     parentButtonId: buttonId
  }

  // 二种工作票
  workTicket2Function(buttonId) {
    // todo 跳转 一种操作票
    //     parentId: this.form.value.orderId,
    //     parentButtonId: buttonId
  }

  // 出库
  materialOutFunction() {
    this.recordsParam = {
      orderCode: this.form.value.defectCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '01',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.out;
    this.isShowRecords = true;
  }

  // 入库
  materialInFunction() {
    this.recordsParam = {
      orderCode: this.form.value.defectCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '01',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.into;
    this.isShowRecords = true;
  }

  // 出入库弹窗 返回事件
  submitAddData(event) {
    this.hideEvent.emit(true);
    this.refreshEvent.emit(true);
  }

  // 打开转派/派单 窗口
  openDispatchDialog(button) {
    this.person = [];
    this.buttonAction = button;
    this.userDialogType = true;
    this.nextUserFlag = true;
    this.isShowUserDialog = true;
  }

  // 驳回/关闭/完成 窗口
  openMemoDialog(button) {
    this.memo = null;
    this.nextUserIds = null;
    this.memoDialog = true;
    this.buttonAction = button;
  }

  // 驳回/关闭/派单/转派
  actionFunction(event) {
    const params = {
      title: this.title,
      defect: this.formatParams(this.form.value),
      action: this.buttonAction,
      nextUserIds: this.nextUserIds ? [this.nextUserIds] : null,
      memo: this.memo,
    };
    if (this.defectId) {
      this.defectmanageservice.defectAction(params).subscribe((res) => {
        this.nextUserIds = null;
        this.memo = null;
        this.tipservice.tip('success', '提交成功');
        this.hideEvent.emit(true);
        this.refreshEvent.emit(true);
      });
    } else {
      this.defectmanageservice.saveAction(params).subscribe((res) => {
        this.nextUserIds = null;
        this.memo = null;
        this.tipservice.tip('success', '提交成功');
        this.hideEvent.emit(true);
        this.refreshEvent.emit(true);
      });
    }
  }

  // 完成
  finishFunction(button) {
    this.confirmationService.confirm({
      header: '提示',
      message: `你确定提交吗？`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        const params = {
          title: this.title,
          defect: this.formatParams(this.form.value),
          action: button,
          nextUserIds: null,
          memo: null,
        };
        this.defectmanageservice.defectAction(params).subscribe((res) => {
          this.tipservice.tip('success', '提交成功');
          this.hideEvent.emit(true);
          this.refreshEvent.emit(true);
        });
      },
    });
  }

  // 删除
  deleteFunction() {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定要删除该缺陷单么?`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        const defectId = this.form.value.defectId;
        this.defectmanageservice
          .defectActionDelete(defectId)
          .subscribe((res) => {
            this.tipservice.tip('success', '删除成功');
            this.hideEvent.emit(true);
            this.refreshEvent.emit(true);
          });
      },
    });
  }

  // 图片上传组件 返回事件
  setPics(data) {
    const value = this.form.value.pics || [];
    const control = this.form.get('pics') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initDoc());
      value.push(item);
    });
    this.form.get('pics').patchValue(value);
  }

  // 附件上传组件 返回事件
  setDocs(data) {
    const value = this.form.value.docs || [];
    const control = this.form.get('docs') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initDoc());
      value.push(item);
    });
    this.form.get('docs').patchValue(value);
  }

  // 抄送组件 返回事件
  ccUserEvent(data) {
    const control = this.form.get('ccUsers') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initCcUser());
    });
    this.form.patchValue({
      ccUsers: data,
    });
  }

  // 初始化 用户 formGroup
  initUsers() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  // 初始化 设备/设施
  initDefectDevice() {
    return new FormGroup({
      objNo: new FormControl(null),
      objId: new FormControl(null),
      deviceNo: new FormControl(null),
      modelNo: new FormControl(null),
      objName: new FormControl(null),
      psrId: new FormControl(null),
      objCompany: new FormControl(null),
      objProvider: new FormControl(null),
      objClass: new FormControl(null),
      objClassName: new FormControl(null),
      workHours: new FormControl(null),
      lossEnergy: new FormControl(null),
      objCost: new FormControl(null),
    });
  }

  public getDevices(form) {
    return form.controls.defectDevices.controls;
  }

  // 初始化操作票
  initTickets() {
    return new FormGroup({
      orderId: new FormControl(null),
      orderCode: new FormControl(null),
    });
  }

  // 初始化备品备件
  initMaterials() {
    return new FormGroup({
      detailId: new FormControl(null),
      materialName: new FormControl(null),
    });
  }

  initDoc() {
    return new FormGroup({
      docId: new FormControl(null),
      docName: new FormControl(null),
      docType: new FormControl(null),
      folderId: new FormControl(null),
      size: new FormControl(null),
      sizeDesc: new FormControl(null),
      memo: new FormControl(null),
      orderNo: new FormControl(null),
      customerId: new FormControl(null),
      uri: new FormControl(null),
      updateTime: new FormControl(null),
      updater: new FormControl(null),
      stationId: new FormControl(null),
      realName: new FormControl(null),
      stationName: new FormControl(null),
      recordType: new FormControl(null),
      recordId: new FormControl(null),
      updaterName: new FormControl(null),
    });
  }

  initNodes() {
    return new FormGroup({
      statusText: new FormControl(null),
      name: new FormControl(null),
      nodeType: new FormControl(null),
      user: new FormControl(null),
      status: new FormControl(null),
    });
  }

  initLinks() {
    return new FormGroup({
      sourceId: new FormControl(null),
      targetId: new FormControl(null),
      source: new FormControl(null),
      target: new FormControl(null),
    });
  }

  initWorkflowLogs() {
    return new FormGroup({
      logId: new FormControl(null),
      action: new FormControl(null),
      executeTime: new FormControl(null),
      flowId: new FormControl(null),
      memo: new FormControl(null),
      nextNodeId: new FormControl(null),
      nextNodeName: new FormControl(null),
      nodeId: new FormControl(null),
      orderId: new FormControl(null),
      preNodeId: new FormControl(null),
      preNodeName: new FormControl(null),
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  initCcUser() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
      userIcon: new FormControl(null),
    });
  }

  formatParams(params) {
    let originTime = {};
    if (params.originTime) {
      originTime = {
        originTime: moment(params.originTime).format(),
      };
    }
    let reportTime = {};
    if (params.reportTime) {
      reportTime = {
        reportTime: moment(params.reportTime).format(),
      };
    }
    let createTime = {};
    if (params.createTime) {
      createTime = {
        createTime: moment(params.createTime).format(),
      };
    }
    let startTime = {};
    if (params.startTime) {
      startTime = {
        startTime: moment(params.startTime).format(),
      };
    }
    let endTime = {};
    if (params.endTime) {
      endTime = {
        endTime: moment(params.endTime).format(),
      };
    }

    return {
      ...params,
      ...originTime,
      ...reportTime,
      ...createTime,
      ...startTime,
      ...endTime,
    };
  }

  setMaxDate() {
    this.maxDateValue = new Date();
  }
}
