export function buildPowerGenerationRankingChart(returnValue: any) {
    if (returnValue.option.series && returnValue.option.series.length) {
        returnValue.option.series.forEach(item => {
            item['barWidth'] = 12;
        });
    }
    let option = {
        color: ['#32C5E9', '#67E0Ee'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
            left: '5%',
            width: '90%',
            top: '2%',
            height: '96%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            show: false,
            type: returnValue.option.xAxis.type,
            name: 'KW'
        },
        yAxis: {
            type: 'category',
            splitLine: { show: false },
            axisTick: { show: false },
            data: returnValue.option.yAxis.data
        },
        series: returnValue.option.series
    };

    return option;
}
