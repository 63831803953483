import { CacheService } from 'src/app/common/services/cache.service';
import { Router, ActivatedRoute } from '@angular/router';
import { StationMonitorService } from './../../station-monitor.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildCommunicationStatusChart } from './chart/communication-status.chart';
import { Constants } from 'src/app/common/Constants';

@Component({
    selector: 'app-communication-status-card',
    templateUrl: './communication-status-card.component.html',
    styleUrls: [
        '../overview.component.less',
        './communication-status-card.component.less'
    ]
})
export class CommunicationStatusCardComponent implements OnInit, OnDestroy {
    //通讯状态 --电站 图表option
    public communicationStatusStationsOption: any = null;
    //通讯状态 --网关 图表option
    public communicationStatusGatewayOption: any = null;
    //通讯状态 --红外集中器 图表option
    public communicationStatusInfraredOption: any = null;
    //通讯状态 --设备 图表option
    public communicationStatusDevicesOption: any = null;

    public stations_block = true;
    public gateway_block = true;
    public infrared_concentrator_block = true;
    public devices_block = true;
    public cardBoxes: string[] = [];
    private intervalTime: number = 5;
    private commStatusInterval: any = null;
    loading: boolean = true;
    stationId: any;

    constructor(
        private stationmonitorservice: StationMonitorService,
        private router: Router,
        private route: ActivatedRoute,
        private cacheService: CacheService
    ) {
        this.route.queryParams.subscribe((params) => {
            if (!params.stationId) {
                let stationId = this.cacheService.getCache(
                    Constants.KEY_CURRENT_STATION_ID
                );
                this.stationId = stationId;
                this.initPage({ stationId });
            } else {
                this.stationId = params.stationId;
                this.initPage({ stationId: params.stationId });
            }
        });
    }

    ngOnInit() {}

    ngOnDestroy(): void {
        clearInterval(this.commStatusInterval);
        this.commStatusInterval = null;
    }

    /**
     * initPage
     */
    public initPage(params?) {
        if (this.commStatusInterval) {
            clearInterval(this.commStatusInterval);
            this.commStatusInterval = null;
        }
        this.getCommStatus(params);
        this.commStatusInterval = setInterval(() => {
            this.getCommStatus(params);
        }, 1000 * 60 * this.intervalTime);
    }

    /**
     * getCommStatus
     */
    public getCommStatus(params) {
        this.loading = true;
        this.stationmonitorservice.commStatus(params).then((res: any) => {
            this.loading = false;
            let data = res;
            this.cardBoxes = data.cardTitle;
            this.communicationStatusStationsOption = buildCommunicationStatusChart(
                data.options[0],
                'stations'
            );
            this.communicationStatusGatewayOption = buildCommunicationStatusChart(
                data.options[1],
                'gateway'
            );
            this.communicationStatusInfraredOption = buildCommunicationStatusChart(
                data.options[2],
                'infrared_concentrator'
            );
            this.communicationStatusDevicesOption = buildCommunicationStatusChart(
                data.options[3],
                'devices'
            );
        });
    }

    /**
     * goCommunication
     */
    public goCommunication(type) {
        console.log(type);
        localStorage.setItem(
            'backItem',
            JSON.stringify({
                showIcon: true,
                link: ['/monitorStation/overview'],
                params: {
                    type
                }
            })
        );
        this.router.navigate(['/monitorCenter/communication']);
    }
    /**
     * goDeviceStatus
     */
    public goDeviceStatus() {
        localStorage.setItem(
            'backItem',
            JSON.stringify({
                showIcon: true,
                link: ['/monitorStation/overview']
            })
        );
        this.router.navigate(['/monitorStation/deviceStatus']);
    }
}
