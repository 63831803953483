import { AuthHttp } from 'src/app/core/auth-http';
import { AppConfigService } from 'src/app/app.config';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class WindService {
  private apiUrl: string;

  private staParamsSource = new Subject<any>();
  staParamsHandler = this.staParamsSource.asObservable();

  constructor(private appconfig: AppConfigService, private http: AuthHttp) {
    this.apiUrl = this.appconfig.apiUrl;
  }

  // 电站基本信息
  public stationInfo(params) {
    return this.http.get(`${this.apiUrl}/api/monitorCenter/map/stationInfo`, {
      params,
    });
  }

  // 查询发电量
  public energy(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorCenter/map/energy`, {
        params,
      })
      .toPromise();
  }

  // 地图初始化
  public mapInit() {
    return this.http.get(`${this.apiUrl}/api/monitorCenter/map/init`);
  }

  // 地图信息
  public mapInfo(params) {
    return this.http.get(`${this.apiUrl}/api/monitorCenter/map/mapInfo`, {
      params,
    });
  }

  public setStaParams(params) {
    this.staParamsSource.next(params);
  }

  public getWindOverview(params) {
    return this.http.get(`${this.apiUrl}/api/monitor/stations/wind/overview`, {
      params,
    });
  }
  public getDeviceStatus(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitor/stations/wind/device/status`,
      {
        params,
      }
    );
  }
}
