import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import jsrsasign from 'jsrsasign';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AppConfigService } from '../app.config';
import { CacheService } from './../common/services/cache.service';
import { AuthHttp, saveToken, tokenNotExpired } from './auth-http';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private authEvents: Subject<AuthEvent>;

  constructor(
    private http: HttpClient,
    private authHttp: AuthHttp,
    private appConfig: AppConfigService,
    private cacheService: CacheService
  ) {
    this.authEvents = new Subject<AuthEvent>();
  }

  async login(username: string, password: string): Promise<HttpResponse<any>> {
    const cipherpwd = await this.getLoginPassword(password);

    return this.http
      .post(`${this.appConfig.apiUrl}/api/frame/auth`, {
        username,
        password: null,
        cipherpwd,
      })
      .pipe(
        tap((res: any) => {
          saveToken(res.token);
          this.authEvents.next(new DidLogin());
        })
      )
      .toPromise();
  }

  autoLogin(username, cipherpwd) {
    return this.http
      .post(`${this.appConfig.apiUrl}/api/frame/auth`, {
        username,
        password: null,
        cipherpwd,
      })
      .pipe(
        tap((res: any) => {
          saveToken(res.token);
          this.authEvents.next(new DidLogin());
        })
      )
      .toPromise();
  }

  async getLoginPassword(password) {
    // RSA
    // 创建一个对象
    let rsa = new jsrsasign.RSAKey();
    // 使用公钥加密
    const secretkey = await this.getPublicKey();

    const publicKey =
      '-----BEGIN PUBLIC KEY-----\n' + secretkey + '\n-----END PUBLIC KEY-----';
    // console.log(publicKey);
    rsa = jsrsasign.KEYUTIL.getKey(publicKey);

    const enc = jsrsasign.KJUR.crypto.Cipher.encrypt(password, rsa);
    // 转换成Base64格式
    const base64Sign = jsrsasign.hextob64(enc);

    return base64Sign;
  }

  public getPublicKey() {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/auth/publicKey`)
      .toPromise();
  }

  public logout(): void {
    localStorage.clear();

    this.cacheService.clear();
    this.authEvents.next(new DidLogout());
  }

  public loggedIn(): boolean {
    return tokenNotExpired();
  }

  get events(): Observable<AuthEvent> {
    return this.authEvents.asObservable();
  }

  public isAdminUser(userName: string): boolean {
    return 'admin' === userName;
  }

  public hasAdminRole(roleId: string): boolean {
    return 'ROLE_ADMIN' === roleId;
  }

  public hasPermission(roleId: string, resName: string): Observable<any> {
    const params = new HttpParams();
    params.set('roleId', roleId);
    params.set('resName', resName);
    return this.authHttp.get(`${this.appConfig.apiUrl}/api/has-permission`, {
      params,
    });
  }

  public hasRole(roleId: string): Observable<any> {
    const params = new HttpParams();
    params.set('roleId', roleId);
    return this.authHttp.get(`${this.appConfig.apiUrl}/api/has-role`, {
      params,
    });
  }

  public hasResource(resName: string): Observable<any> {
    const params = new HttpParams();
    params.set('resName', resName);
    return this.authHttp.get(`${this.appConfig.apiUrl}/api/has-resource`, {
      params,
    });
  }
}

export class DidLogin {}
export class DidLogout {}

export type AuthEvent = DidLogin | DidLogout;
