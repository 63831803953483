import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-device-dialog',
  templateUrl: './device-dialog.component.html',
  styleUrls: ['./device-dialog.component.less'],
})
export class DeviceDialogComponent implements OnInit, OnChanges {
  @Input() isShow: boolean;
  @Input() deviceID: string;
  @Input() pDeviceNo: string;
  @Input() deviceType: string;
  @Input() addDevice: string;
  @Input() stationId: string;
  @Input() subDevice: boolean;
  @Input() areaId: string;
  @Output() public showEvent = new EventEmitter<boolean>();
  sDevicedId: string;
  pDevicedId: string;

  constructor() {}

  ngOnInit() {
    if (this.subDevice) {
      this.pDevicedId = this.deviceID;
    } else {
      this.sDevicedId = this.deviceID;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {}

  back() {
    this.isShow = false;
    this.showEvent.emit(true);
  }
}
