import { StationMonitorService } from 'src/app/components/station-monitor/station-monitor.service';

import { EarthMapBgService } from '@shared/components/earth-map-bg/earth-map-bg.service';
import { Location } from '@angular/common';
import {
  AfterViewInit,
  Component,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ScrollPanel } from 'primeng/primeng';
import { fromEvent } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app.config';
import { Constants } from 'src/app/common/Constants';
import { CacheService } from 'src/app/common/services/cache.service';
import { BreadcrumbService } from 'src/app/components/layout/breakcrumb/breadcrumb.service';
import { NavService } from 'src/app/components/layout/nav/nav.service';
import { FullLayoutDashboardService } from './full-layout-dashboard.service';

declare const $: any;

@Component({
  selector: 'app-full-layout-dashboard',
  templateUrl: './full-layout-dashboard.component.html',
  styleUrls: ['./full-layout-dashboard.component.less'],
})
export class FullLayoutDashboardComponent implements AfterViewInit, OnInit {
  paths = ['/', '/yxjs', '/scgl', '/yyfx', '/xtpz'];

  path = '';

  stationNumber = '/assets/bigScreen/icons/icon-changzhan.png';
  installedCapacity = '/assets/bigScreen/icons/icon-zhuangji.png';
  energyStorageCapacity = '/assets/bigScreen/icons/icon-chuneng.png';
  photovoltaic = '/assets/bigScreen/icons/icon-guangfu.png';
  windPower = '/assets/bigScreen/icons/icon-fengdian.png';
  mix = '/assets/bigScreen/icons/icon-mix.png';

  guangfu = '/assets/bigScreen/icons/icon-guangfudianzhan.png';
  fengdian = '/assets/bigScreen/icons/icon-fengdiandianzhan.png';

  public menus = [];

  earthStepPage = 'monitor';

  public items = [];
  leftChartHeight: number;
  leftChartWidth: number;

  public title = '';

  public stationId = '';

  public rightPanels = [];
  activeUrl: string;
  stationName: any;
  defaultStationId: any;
  orginalMenu: any;
  scene: string;

  showInverters = false;
  stationAreas: any = [];
  dialogWidth: any;
  showOverview: boolean;
  showWindOverview: boolean;
  stationClass: any;

  constructor(
    public renderer: Renderer2,
    private navService: NavService,
    private cacheService: CacheService,
    private router: Router,
    private activatedroute: ActivatedRoute,
    private breadcrumbService: BreadcrumbService,
    private appConfig: AppConfigService,
    private titleservice: Title,
    private location: Location,
    private fulllayoutdashboardservice: FullLayoutDashboardService,
    private earthmapbgservice: EarthMapBgService,
    private stationmonitorservice: StationMonitorService,
    private sanitizer: DomSanitizer
  ) {
    // this.router.events.subscribe(res => {
    //   // console.log(res);
    // })

    this.activatedroute.queryParams.subscribe((res) => {
      if (!res.level) {
        this.showInverters = false;
        this.showOverview = false;
        this.stationId = '';
        setTimeout(() => {
          this.setAgilebins();
        }, 500);
      }
    });

    // 路由监听设置 面包屑
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        // example: NavigationStart, RoutesRecognized, NavigationEnd
        this.activeUrl = event.url;
        $('.layout-content').scrollTop(0);
        if (this.cacheService.getCache(Constants.KEY_CURRENT_STATION_NAME)) {
          this.stationName = this.cacheService.getCache(
            Constants.KEY_CURRENT_STATION_NAME
          );
        }
        if (this.menus.length !== 0) {
          this.breadcrumbService.setItems(
            this.getBreadcrumbItems(this.orginalMenu, this.menus)
          );
        }

        this.path = event.url.split('?')[0];
        this.getTitle(this.orginalMenu, this.path, this.getUrlParameters());
      });

    // 获取init接口
    this.navService.getInit().subscribe((res: any) => {
      localStorage.setItem('websocket', 'false');

      // 获取默认电站
      if (this.getUrlParameters() && this.getUrlParameters().stationId) {
        this.defaultStationId = this.getUrlParameters().stationId;
      } else if (
        this.cacheService.getCache(Constants.KEY_CURRENT_STATION_ID) !=
        undefined
      ) {
        this.defaultStationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
      } else {
        this.cacheService.setCache(
          Constants.KEY_CURRENT_STATION_ID,
          res.defaultStationId,
          true
        );
        this.defaultStationId = res.defaultStationId;
      }

      if (res.stationTree) {
        // 获取电站树
        this.breadcrumbService.setStationTree({
          recentStations: res.recentStations,
          stationTree: this.getStationTree({
            stationTree: res.stationTree,
            stationId: this.defaultStationId,
          }),
        });
      }

      this.orginalMenu = res.menus;
      // 生成菜单
      const menus = this.orginalMenu.map((item) => {
        const exact = item.url ? this.setExactMenusItem(item.url) : false;
        let url = null;
        if (item.url) {
          if (item.url.startsWith('http')) {
            url = item.url;
          } else if (item.url.indexOf('html') !== -1) {
            url = [item.url];
          } else {
            if (this.paths.includes(item.url)) {
              url = [item.url];
            } else {
              let parentItem = this.getParentUrl(
                this.orginalMenu,
                item.parentId
              );

              url = [parentItem.url + item.url];
            }
          }
        } else {
          url = '';
        }

        return {
          label: item.menuLabel,
          icon: item.menuIcon2,
          // routerLink: item.url ? [item.url] : '',
          routerLink: url,
          menuId: item.menuId,
          parentId: item.parentId,
          routerLinkActiveOptions: { exact },
          items: item.children.length > 0 ? item.children : '',
          target: item.openType === '1' ? '_blank' : '_self',
          menuParam: item.menuParam,
        };
      });

      this.menus = this.treeData(menus, 'menuId', 'parentId', 'items');
      // console.log(this.menus);

      // this.menus[3].items[0].items[9].routerLink[0] = '/xtpz/config/sysinit1'

      this.breadcrumbService.setItems(
        this.getBreadcrumbItems(this.orginalMenu, this.menus)
      );

      const path = this.location.path();
      const params = this.getUrlParameters();
      this.getTitle(res.menus, path, params);
    });

    const login = localStorage.getItem('pageConfigs')
      ? JSON.parse(localStorage.getItem('pageConfigs'))
      : this.appConfig.login;
    this.titleservice.setTitle(login.title);
    // this.titleservice.setTitle('致远新能源平台');

    fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setLeftChartHeight();
      });
  }
  ngOnInit() {
    this.setLeftChartHeight();
  }

  ngAfterViewInit(): void {
    this.setAgilebins();
    this.getStationInfo();
  }

  public getParentUrl(menu, parentId) {
    const item = _.find(menu, (o) => o.menuId === parentId);
    if (item && !item.url) {
      return this.getParentUrl(menu, item.parentId);
    } else {
      return item;
    }
  }

  public async getStationInfo() {
    const data: any = await this.fulllayoutdashboardservice.getStationInfo();
    const [data1, data2, data3] = data;
    this.rightPanels = [
      {
        text: data1.value1,
        value: data1.value2,
        unit: data1.value3,
        route: '',
      },
      {
        text: data1.value4,
        value: data1.value5,
        unit: data1.value6,
        route: '',
      },
      {
        text: data2.value1,
        value: data2.value2,
        unit: data2.value3,
        route: data2.route,
        type: 'power',
      },
      {
        text: data2.value4,
        value: data2.value5,
        unit: data2.value6,
        route: data2.route,
        type: 'powerRate',
      },
      {
        text: data3.value1,
        value: data3.value2,
        unit: data3.value3,
        route: data3.route,
        type: 'energy',
      },
      {
        text: data3.value4,
        value: data3.value5,
        unit: data3.value6,
        route: data3.route,
        type: 'kwhkwp',
      },
    ];
  }

  public getTitle(menus, path, params) {
    ['/yxjs', '/scgl', '/yyfx', '/xtpz'].map((item) => {
      if (path.startsWith(item)) {
        this.scene = item.substring(1);
        const current_path = path.split(item)[1];
        if (current_path) {
          const current = _.find(menus, (o) => {
            if (o.menuParam) {
              if (o.url === '/monitorCenter/deviceStatus') {
                return (
                  this.getUrlParameters() &&
                  this.getUrlParameters().deviceType === o.menuParam.deviceType
                );
              }
              return (
                o.url === current_path.split('?')[0] &&
                _.isEqual(o.menuParam, params)
              );
            } else {
              if (current_path.indexOf('?') !== -1) {
                return o.url === current_path.split('?')[0];
              }
              return o.url === current_path;
            }
          });
          if (current) {
            this.title = current.menuLabel;
          }
        }
      }
    });
  }

  /**
   * name
   */
  private getStationTree(data) {
    let i = 0;
    const treeData = [];
    _.forEach(data.stationTree, (value, key) => {
      const citys = [];
      _.forEach(value, (v, k) => {
        const stations = [];
        v.forEach((station) => {
          stations.push({
            objId: station.stationId,
            objName: station.shortName,
            key: station.shortName,
            objType: '03',
          });
          if (station.stationId === data.stationId) {
            this.stationName = station.shortName;
            this.cacheService.setCache(
              Constants.KEY_CURRENT_STATION_NAME,
              this.stationName,
              true
            );
          }
        });
        citys.push({
          objId: k + i,
          objName: k,
          key: k,
          children: stations,
          objType: '02',
        });
      });
      treeData.push({
        objId: i,
        objName: key,
        key,
        children: citys,
        objType: '01',
      });
      i++;
    });
    return treeData;
  }

  public backRoot() {
    let path = this.location.path();
    const params = this.getUrlParameters();
    ['/yxjs', '/scgl', '/yyfx', '/xtpz'].map((item) => {
      if (path.startsWith(item)) {
        if (
          params &&
          params.stationId &&
          !params.backUrl &&
          path.indexOf('/monitorStation/') !== -1
        ) {
          this.router.navigate([item], {
            queryParams: {
              stationId: params.stationId,
              level: 'station',
            },
          });
        } else if (params && params.backUrl) {
          this.router.navigate([item + decodeURIComponent(params.backUrl)]);
        } else {
          if (
            localStorage.getItem('page') === 'station' ||
            localStorage.getItem('inverters') === 'true'
          ) {
            this.router.navigate([item], {
              queryParams: {
                stationId: this.cacheService.getCache(
                  Constants.KEY_CURRENT_STATION_ID
                ),
                level: 'station',
              },
            });
          } else {
            this.router.navigate([item]);
          }
        }
      }
    });
  }

  setAgilebins() {
    $('.slides').agilebins({
      autoPlay: false /* 自动播放(true | false) */,
      delayTime: 10000 /* 自动播放延迟时间(毫秒)，注意：delayTime >= speed >= 0 */,
      pnLoop: true /* 是否需要按钮前后循环(true | false) */,
      loop: true /* 无限循环播放(true | false) */,
      mainEl: '.conts>ul' /* 内容列表容器 */,
      mainState:
        '.tabs>ul' /* 导航列表分页容器（当前为数字，此li样式设为例如line-height:999px;隐藏数字只显示为圆圈） */,
      autoMainState:
        true /* 是否开启自动填充导航HTML元素。与mainState配合使用。 */,
      onClass: 'current' /* 当前选中的索引亮高css样式名 */,
      mPrev: '.prev' /* 上一个按钮元素 */,
      mNext: '.next' /* 下一个按钮元素 */,
      effect:
        'left' /* 效果类型: fade | fold | slideDown | top | right | bottom | left */,
    });
  }

  public setLeftChartHeight() {
    this.leftChartHeight = document.body.clientHeight - 91 - 20;
    this.leftChartWidth = Math.floor((this.leftChartHeight * 512) / 955);
    this.dialogWidth = this.sanitizer.bypassSecurityTrustStyle(
      `calc(100% - ${this.leftChartWidth}px - 40px)`
    );
  }

  isLeftChart() {
    const path = this.location.path();
    return this.paths.includes(path.split('?')[0]);
  }

  public setExactMenusItem(url) {
    switch (url) {
      case '/yxjs':
        return true;
      case '/scgl':
        return true;
      case '/yyfx':
        return true;
      case '/xtpz':
        return true;
      case '/base/users':
        return true;
      case '/ticket/operate':
        return true;
      case '/inspections':
        return true;
      case '/report/custom':
        return true;
      case '/sparePartsManage/safeinventoryreport':
        return true;
      case '/sparePartsManage/inoutinventoryrepot':
        return true;
      default:
        return false;
    }
  }

  private treeData(
    source: any,
    id: string,
    parentId: string,
    children: string
  ) {
    const cloneData = JSON.parse(JSON.stringify(source));
    return cloneData.filter((father) => {
      const branchArr = cloneData.filter(
        (child) => father[id] === child[parentId]
      );
      branchArr.length > 0 ? (father[children] = branchArr) : '';
      return father[parentId] === null;
    });
  }

  public getTime() {
    return moment().format('YYYY-MM-DD HH:mm');
  }

  private getUrlParameters(): any {
    const url = this.location.path();
    if (url.match(/([^?=&]+)(=([^&]*))/g)) {
      return url
        .match(/([^?=&]+)(=([^&]*))/g)
        .reduce(
          (a, v) => (
            (a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)), a
          ),
          {}
        );
    } else {
      return null;
    }
  }

  private getBreadcrumbItems(menus, json) {
    let breadcrumbItems = [];
    const ActiveMenu = this.getActiveMenu(menus, this.activeUrl);
    if (!ActiveMenu) {
      return breadcrumbItems;
    }
    const activeMenuId = ActiveMenu.menuId;
    const breadcrumbArray = this.getTreeList(json, activeMenuId);

    // 设备列表
    let deviceListFlag = false;
    let deviceItems = [];
    breadcrumbItems = breadcrumbArray.map((item) => {
      if (
        [
          '/yxjs/monitorStation/deviceList',
          '/yxjs/monitorStation/deviceStatus',
          '/yxjs/monitorStation/energy',
          '/yxjs/topology',
        ].includes(item.routerLink[0])
      ) {
        deviceListFlag = true;
        deviceItems = breadcrumbArray;
        deviceItems.splice(2, 0, {
          label: this.stationName,
          url: '/yxjs',
          routerLink: ['/yxjs'],
          queryParams: {
            ...item.menuParam,
            stationId: this.cacheService.getCache(
              Constants.KEY_CURRENT_STATION_ID
            ),
          },
        });
      }
      if (
        item.routerLink[0] == '/yxjs' &&
        item.menuParam &&
        item.menuParam.level === 'station'
      ) {
        deviceListFlag = true;
        deviceItems = breadcrumbArray;
        deviceItems.splice(2, 0, {
          label: this.stationName,
          url: '/yxjs',
          routerLink: ['/yxjs'],
          queryParams: {
            ...item.menuParam,
            stationId: this.cacheService.getCache(
              Constants.KEY_CURRENT_STATION_ID
            ),
          },
        });
      }
      if (item.routerLink[0] == '/yxjs/monitorStation/overview') {
        deviceListFlag = true;
        deviceItems = breadcrumbArray;
        deviceItems.splice(1, 0, {
          label: this.stationName,
          url: '/yxjs/monitorStation/overview',
          routerLink: false,
          queryParams: item.menuParam,
        });
        // return {
        //   label: this.stationName,
        //   url: item.routerLink[0],
        //   routerLink: false,
        //   queryParams: item.menuParam,
        // };
      } else {
        return {
          label: item.label,
          url: item.routerLink[0],
          routerLink: item.routerLink,
          queryParams: item.menuParam,
        };
      }
    });

    if (deviceListFlag) {
      breadcrumbItems = deviceItems;
    }

    return breadcrumbItems;
  }

  /**
   * 通过当前url 查询原始menu数组，获取activeMenu对象
   * getActiveMenu
   */
  private getActiveMenu(menus, url) {
    if (url === '/') {
      url = localStorage.getItem('homepage');
    }

    this.paths.map((item) => {
      if (url.startsWith(item) && item !== '/' && url.split('?')[0] !== item) {
        url = url.split(item)[1];
      }
    });
    return _.find(menus, (o) => {
      if (url.indexOf('?') >= 0) {
        if (o.menuParam) {
          if (o.url === '/monitorCenter/deviceStatus') {
            return (
              this.getUrlParameters() &&
              this.getUrlParameters().deviceType === o.menuParam.deviceType
            );
          }

          if (o.url === '/yxjs' && o.menuParam) {
            return (
              this.getUrlParameters() &&
              this.getUrlParameters().level === o.menuParam.level
            );
          }
          return (
            o.url === url.split('?')[0] &&
            _.isEqual(o.menuParam, this.getUrlParameters())
          );
        } else {
          if (
            o.url === '/sparePartsManage/safeinventoryreport' ||
            o.url === '/sparePartsManage/inoutinventoryrepot' ||
            o.url === '/yxjs'
          ) {
            return o.url === url;
          }
          return o.url === url.split('?')[0];
        }
      } else {
        return o.url === url;
      }
    });
  }

  /**
   * 子节点menuId 反推 所有父节点menu数组对象
   * getTreeList
   */
  private getTreeList(json, id) {
    const temp = [];
    const forFn = function (arr, id) {
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (item.menuId === id) {
          temp.unshift(item);
          forFn(json, item.parentId);
          break;
        } else {
          if (item.items) {
            forFn(item.items, id);
          }
        }
      }
    };
    forFn(json, id);
    return temp;
  }

  private splitUrl(): any {
    const url = this.location.path();
    let urlParams = {};
    if (url.indexOf('?') != -1) {
      urlParams = { route: url.split('?')[0], params: url.split('?')[1] };
    } else {
      urlParams = { route: url };
    }
    return urlParams;
  }

  stationChange(event) {
    this.stationId = event;
    const path = this.splitUrl().route;
    if (this.stationId) {
      this.cacheService.setCache(
        Constants.KEY_CURRENT_STATION_ID,
        this.stationId,
        true
      );
      // this.router.navigate([this.router.url.split('?')[0]], {
      //   queryParams: {
      //     stationId: this.stationId,
      //     level: 'station'
      //   }
      // })
    } else {
      if (
        ['/yxjs', '/scgl', '/yyfx', '/xtpz'].includes(
          this.router.url.split('?')[0]
        ) &&
        this.router.url.split('?')[1]
      ) {
        this.router.navigate([this.router.url.split('?')[0]]);
      }
    }
    setTimeout(() => {
      this.setAgilebins();
    }, 500);
  }

  pageChangeEvent(event) {
    this.earthStepPage = event;
    if (this.earthStepPage === 'monitor') {
      this.stationAreas = [];
    }
  }

  showInvertersEvent(event) {
    this.showInverters = false;
    this.showOverview = false;

    if (event.show) {
      if (event.type === 'inverters') {
        this.showInverters = true;
      } else if (event.type === 'overview') {
        this.showOverview = true;
        this.stationClass = event.stationClass || '01';
      }
    } else {
      this.showInverters = false;
      this.showOverview = false;
    }
  }

  countyDataEvent(event) {
    this.stationAreas = event;
  }

  /**
   * pageLink
   */
  public pageLinkStationArea(type, params) {
    // if (route) {
    //   localStorage.setItem(
    //     'backItem',
    //     JSON.stringify({
    //       showIcon: true,
    //       link: ['/yxjs'],
    //     })
    //   );
    //   this.router.navigate([route], {
    //     queryParams: {
    //       dataType: '01',
    //       deviceType: '01',
    //       sortBy: 'all_rate',
    //       sortOrder: 'desc',
    //     },
    //   });
    // }
    if (type === 2) {
      if (params && params.runStatus) {
        this.stationmonitorservice.setRunStatusCache(params.runStatus);
      } else {
        this.stationmonitorservice.clearRunStatusCache();
      }

      this.router.navigate(['/yxjs/monitorStation/deviceList']);
    } else {
      if (params && params.runStatus === 0) {
        this.earthmapbgservice.showStationAreaList(params.runStatus);
      } else {
        this.earthmapbgservice.showStationAreaList(null);
      }
    }
  }

  /**
   * pageLink
   */
  public pageLink(route, type) {
    if (route) {
      localStorage.setItem(
        'backItem',
        JSON.stringify({
          showIcon: true,
          link: ['/yxjs'],
        })
      );
      this.router.navigate([route], { queryParams: { type } });
    }
  }

  close() {
    this.showInverters = false;
    this.showOverview = false;
    this.stationId = '';
    const path = this.location.path().split('?');
    this.router.navigate([path[0]]);
    setTimeout(() => {
      this.setAgilebins();
    }, 500);
  }
}
