import { Location } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TipService } from '@common/services/tip.service';
import { MenuItem, OverlayPanel } from 'primeng/primeng';
import { Subscription } from 'rxjs';
import { CacheService } from 'src/app/common/services/cache.service';
import { CommonService } from './../../../common/services/common.service';
import { HeaderService } from './../header/header.service';
import { BreadcrumbService } from './breadcrumb.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  styleUrls: ['./app.breadcrumb.component.less'],
})
export class AppBreadcrumbComponent implements OnInit, OnDestroy {
  @Input() public favorite: any;
  @Input() public hideIcons: any = false;
  public recentStations: any = [];
  deviceTreeDisplay = false;
  subscription: Subscription;

  items: MenuItem[];
  configSubscription: Subscription;
  configShow = false;
  page: any;
  treeData: any[] = [];
  favoriteIconActive: boolean;
  homeIconActive: boolean;
  firstLoad: boolean;
  favoriteSubscription: Subscription;
  homepage = '/';
  showStationTreeFlag = false;
  deviceId: any;

  constructor(
    public breadcrumbService: BreadcrumbService,
    private common: CommonService,
    private location: Location,
    private cacheService: CacheService,
    private router: Router,
    private headerService: HeaderService,
    private tipservice: TipService
  ) {
    this.subscription = breadcrumbService.itemsHandler.subscribe((response) => {
      if (
        this.splitUrl().route.indexOf('/monitorStation/') !== -1 ||
        this.splitUrl().route.indexOf('/monitorCenter/') !== -1 ||
        this.splitUrl().route === '/topology' ||
        this.splitUrl().route === '/yxjs'
      ) {
        this.showStationTreeFlag = true;
      } else {
        this.showStationTreeFlag = false;
      }

      this.items = response;
    });

    breadcrumbService.stationTreeHandler.subscribe((data: any) => {
      this.breadcrumbService.setRecentStations(data.recentStations);
      this.recentStations = data.recentStations;
      this.treeData = data.stationTree;
    });

    this.configSubscription = breadcrumbService.configHandler.subscribe(
      (res: any) => {
        if (res) {
          this.configShow = res.show;
          this.page = res.page;
          this.deviceId = res.deviceId;
        }
      }
    );

    this.favoriteSubscription = breadcrumbService.favoriteHandler.subscribe(
      (res: any) => {
        this.favoriteIconActive = res;
      }
    );

    this.homepage = localStorage.getItem('homepage');
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.configSubscription) {
      this.configSubscription.unsubscribe();
    }
    if (this.favoriteSubscription) {
      this.favoriteSubscription.unsubscribe();
    }
  }

  /**
   * setStataion
   */
  public setStataion(station) {
    if (
      this.splitUrl().route.indexOf(`/yxjs/monitorCenter/`) !== -1 ||
      this.splitUrl().route === '/yxjs'
    ) {
      this.breadcrumbService.setStation(station, '/yxjs', {
        level: 'station',
        stationId: station.stationId,
      });
    } else {
      this.breadcrumbService.setStation(station);
    }

    // let index = _.findIndex(this.recentStations, (o: any) => {
    //     return o.stationId == station.stationId;
    // });
    // if (index == -1) {
    //     this.recentStations.splice(5, 1);
    // } else {
    //     this.recentStations.splice(index, 1);
    // }

    // this.recentStations.unshift(station);
    // this.cacheService.setCache(Constants.KEY_CURRENT_STATION_ID, station.stationId, true);
    // this.cacheService.setCache(Constants.KEY_CURRENT_STATION_NAME, station.shortName, true);
    // let params = this.splitUrl();
    // this.router.navigate([params.route], {
    //     queryParams: {
    //         stationId: station.stationId,
    //     },
    //     queryParamsHandling: 'merge',
    // });
  }

  public showStationTree(overlaypanel: OverlayPanel) {
    if (this.showStationTreeFlag == true) {
      if (this.deviceTreeDisplay === false) {
        this.deviceTreeDisplay = true;
        overlaypanel.show(event);
      }
    }
  }

  public hideStationTree(overlaypanel: OverlayPanel) {
    if (
      this.showStationTreeFlag == true &&
      !(document.activeElement.tagName === 'INPUT')
    ) {
      // overlaypanel.hide();
      this.deviceTreeDisplay = false;
    }
  }

  /**
   * configFn
   */
  public configFn() {
    if (this.page === 'monitorCenter-overview') {
      this.breadcrumbService.toggleOverviewDialog(true);
    } else if (this.page === 'gisMonitor-overview') {
      this.breadcrumbService.toggleGisMonitorDialog(true);
    } else if (this.page === 'monitorCenter-power') {
      this.breadcrumbService.togglePowerDialog(true);
    } else if (this.page === 'monitorCenter-energy') {
      this.breadcrumbService.toggleEnergyDialog(true);
    } else if (this.page === 'point-config') {
      this.headerService.configSubject.next({ deviceId: this.deviceId });
    }
  }

  /**
   * 页面收藏
   */
  public setFavorites() {
    const params = this.splitUrl();
    if (this.favoriteIconActive) {
      this.breadcrumbService.removeFavorites(params).subscribe((res) => {
        this.tipservice.tip('success', '取消收藏成功！');
        this.breadcrumbService.setFavoriteIcon(false);
        this.breadcrumbService.reloadFavorite();
      });
    } else {
      this.breadcrumbService.setFavorites(params).subscribe((res) => {
        this.tipservice.tip('success', '收藏成功！');
        this.breadcrumbService.setFavoriteIcon(true);
        this.breadcrumbService.reloadFavorite();
      });
    }
  }

  /**
   * 设置首页
   */
  public setHomepage() {
    const params = this.splitUrl();
    this.breadcrumbService.setHomepage(params).subscribe((res) => {
      localStorage.setItem('homepage', params.route);
      this.homepage = localStorage.getItem('homepage');
      this.breadcrumbService.setHome(this.homepage);
      this.tipservice.tip('success', '首页设置成功！');
    });
  }

  public homepageActive() {
    const params = this.splitUrl().route;
    return params == localStorage.getItem('homepage');
  }

  /**
   * 截取url中route和params
   */
  private splitUrl(): any {
    const url = this.location.path();
    let urlParams = {};
    if (url.indexOf('?') != -1) {
      urlParams = { route: url.split('?')[0], params: url.split('?')[1] };
    } else {
      urlParams = { route: url };
    }
    return urlParams;
  }
}
