import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import {
  DefaultTreeviewI18n,
  TreeviewConfig,
  TreeviewI18n,
  TreeviewItem,
} from '../../../../common/components/treeview/index';
import { CommonService } from '../../../../common/services/common.service';
import { DeviceAssetsService } from '../device-assets.service';

@Component({
  selector: 'mpt-option-type',
  templateUrl: 'option-type.component.html',
  providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }],
})
export class OptionTypeComponent implements OnInit, OnChanges {
  @Input() public isRadio: boolean = false;
  @Input() public value: string;
  @Input() public parentVal: string;
  @Input() public childVal: string;
  @Input() public classType: string;

  @Input() public isReset: boolean = false;
  @Input() public values: string[] = [];
  @Output() public getOptionType = new EventEmitter<any>();

  public items: TreeviewItem[] = [];
  public typeData: any = [];

  public config = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: true,
    decoupleChildFromParent: false,
    maxHeight: 200,
  });

  constructor(
    private common: CommonService,
    private deviceAssetsService: DeviceAssetsService
  ) {}

  public ngOnInit() {
    this.deviceAssetsService
      .getoperatingCategory(this.classType)
      .then((res) => {
        this.typeData = res;
        this.items = this.common.filterTreeData(
          'propName',
          'listId',
          this.typeData
        );
      });
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.items = this.common.filterTreeData(
      'propName',
      'listId',
      this.typeData
    );
    if (this.isReset) {
      this.values = [];
    }
    if (this.isRadio) {
      setTimeout(() => {
        this.value = this.deviceAssetsService.setOptionTypeVal(
          this.typeData,
          this.parentVal,
          this.childVal
        );
      }, 500);
    }
  }

  public onSelectedChange(event) {
    let params = null;
    if (this.isRadio) {
      event = [].concat(event);
    }
    let submitData = { parent: [], child: [] };
    for (let data of this.typeData) {
      for (let val of event) {
        if (data.listId === val) {
          submitData.parent.push(data.propValue);
          if (data.children.length) {
            for (let childType of data.children) {
              submitData.child.push(childType.propValue);
              params = childType;
            }
          }
        } else {
          if (data.children.length) {
            for (let childType of data.children) {
              if (childType.listId === val) {
                submitData.parent.push(childType.parentPropValue);
                submitData.child.push(childType.propValue);
                params = childType;
              }
            }
          }
        }
      }
    }
    this.getOptionType.emit({
      parent: _.union(submitData.parent),
      child: _.union(submitData.child),
      params,
    });
  }
}
