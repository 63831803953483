import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-simple-layout",
    templateUrl: "./simple-layout.component.html",
    styleUrls: ["./simple-layout.component.less"]
})
export class SimpleLayoutComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
