import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";

import {
    ButtonModule,
    DialogModule,
    InputTextModule,
    PaginatorModule,
    TreeModule,
    ScrollPanelModule
} from "primeng/primeng";
import { UserSelectorComponent } from "./user-selector.component";
import { UserSelectorService } from "./user-selector.service";
import { SharedModule } from "../../../shared/shared.module";
import { TableModule } from "primeng/table";

@NgModule({
    imports: [
        HttpClientModule,
        SharedModule,
        InputTextModule,
        TableModule,
        ButtonModule,
        DialogModule,
        PaginatorModule,
        TreeModule,
        ScrollPanelModule
    ],
    exports: [UserSelectorComponent],
    declarations: [UserSelectorComponent],
    providers: [UserSelectorService]
})
export class UserSelectorModule { }
