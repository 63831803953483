import { Injectable } from "@angular/core";
import { ActivatedRoute, Params } from "@angular/router";
import * as _ from "lodash";

import { AuthHttp } from "../../../core/auth-http";
import { AppConfigService } from "../../../app.config";
import { CommonService } from "../../../common/services/common.service";

@Injectable()
export class TopoOverviewService {
    public apiUrl: string = this.appConfig.apiUrl;

    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private common: CommonService,
        private route: ActivatedRoute
    ) {}

    public propValues(param) {
        return this.http.get(`${this.apiUrl}/api/topo/propValues`, {
            search: { ...param }
        });
    }

    public exportPropValues(param) {
        let url = `${this.apiUrl}/api/topo/exportValues`;
        this.common.fileExport(url, param);
    }

    public propControl(pointId, value) {
        return this.http.post(`${this.apiUrl}/api/topo/control`, "", {
            search: { pointId, value }
        });
    }

    public getRealvalues(pointIds) {
        return this.http.get(`${this.apiUrl}/api/points/realvalues`, {
            search: { pointIds }
        });
    }

    public propWindow(topoId?, deviceId?) {
        return this.http.get(`${this.apiUrl}/api/topo/prop`, {
            search: { topoId, deviceId }
        });
    }
}
