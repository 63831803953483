import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { Constants } from 'src/app/common/Constants';
import { CacheService } from 'src/app/common/services/cache.service';
import { StationMonitorService } from './../../station-monitor.service';
import { buildMaximumPowerRankingChart } from './chart/maximum-power-ranking.chart';
import { buildPowerGenerationRankingChart } from './chart/power-generation-ranking.chart';
import { buildStartTimeRankingChart } from './chart/start-time-ranking.chart';

@Component({
  selector: 'app-operating-efficiency-card',
  templateUrl: './operating-efficiency-card.component.html',
  styleUrls: [
    '../overview.component.less',
    './operating-efficiency-card.component.less',
  ],
})
export class OperatingEfficiencyCardComponent implements OnInit, OnDestroy {
  @Input() showFlag = {
    performance: false,
    inveters: false,
  };
  public inverter: any = {
    station: [],
    group: [],
    device: [],
  };

  public pvarray: any = {
    station: [],
    group: [],
    device: [],
  };

  public operating_efficiency_inverter_block = true;
  public start_time_ranking_block = true;
  public power_generation_ranking_block = true;
  public maximum_power_ranking_block = true;
  private performanceInterval: any;
  private performanceIntervalTime = 30;
  startTimeRankingOption: {} = null;
  startTimeRankingLoading = true;
  startTimeParams = {
    page: 0,
    size: 10,
  };
  startTimeParamsTotalRecords = 0;
  maximumPowerRankingOption: {} = null;
  maximumPowerRankingLoading = true;
  maximumPowerRankingParams = {
    page: 0,
    size: 10,
  };
  maximumPowerRankingTotalRecords = 0;
  powerGenerationRankingOption: {} = null;
  powerGenerationRankingLoading = true;
  powerGenerationRankingParams = {
    page: 0,
    size: 10,
  };
  powerGenerationRankingTotalRecords = 0;
  stationId: any;

  public performancePager = { page: 0, size: 5, totalRecords: 0 };
  performanceList: any = [];
  runStartTimeSort = {
    sortBy: 'runStartTime',
    sortOrder: 'desc',
  };
  maximumPowerSort = {
    sortBy: 'AM-NB-ZXYGGL-GRID',
    sortOrder: 'desc',
  };
  powerGenerationSort = {
    sortBy: 'AI-NB-ZXYGDXS-D',
    sortOrder: 'desc',
  };
  stationParams: any;
  queryParams: any = {
    page: 0,
    size: 5,
  };
  startTimeRankingInterval: any;
  startTimeRankingIntervalTime = 5;
  maximumPowerRankingInterval: any;
  maximumPowerRankingIntervalTime = 5;
  powerGenerationRankingInterval: any;
  powerGenerationRankingIntervalTime = 5;

  constructor(
    private stationmonitorservice: StationMonitorService,
    private router: Router,
    private route: ActivatedRoute,
    private cacheService: CacheService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        const stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
        this.initPage({ stationId });
      } else {
        this.stationId = params.stationId;
        this.initPage({ stationId: params.stationId });
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    clearInterval(this.performanceInterval);
    this.performanceInterval = null;
    clearInterval(this.startTimeRankingInterval);
    this.startTimeRankingInterval = null;
    clearInterval(this.maximumPowerRankingInterval);
    this.maximumPowerRankingInterval = null;
    clearInterval(this.powerGenerationRankingInterval);
    this.powerGenerationRankingInterval = null;
  }

  /**
   * initPage
   */
  public initPage(params) {
    if (this.performanceInterval) {
      clearInterval(this.performanceInterval);
      this.performanceInterval = null;
    }
    this.getPerformance(_.merge({}, this.queryParams, params));
    this.performanceInterval = setInterval(() => {
      this.getPerformance(_.merge({}, this.queryParams, params));
    }, 1000 * 60 * this.performanceIntervalTime);

    // 逆变器启动时间排名
    if (this.startTimeRankingInterval) {
      clearInterval(this.startTimeRankingInterval);
      this.startTimeRankingInterval = null;
    }
    this.buildStartTimeRankingCharts(
      _.merge(
        {},
        this.runStartTimeSort,
        { stationId: this.stationId },
        this.startTimeParams
      )
    );
    this.startTimeRankingInterval = setInterval(() => {
      this.buildStartTimeRankingCharts(
        _.merge(
          {},
          this.runStartTimeSort,
          {
            stationId: this.stationId,
          },
          this.startTimeParams
        )
      );
    }, 1000 * 60 * this.startTimeRankingIntervalTime);

    // 逆变器日最大功率
    if (this.maximumPowerRankingInterval) {
      clearInterval(this.maximumPowerRankingInterval);
      this.maximumPowerRankingInterval = null;
    }
    this.buildMaximumPowerRankingCharts(
      _.merge(
        {},
        this.maximumPowerSort,
        { stationId: this.stationId },
        this.maximumPowerRankingParams
      )
    );
    this.maximumPowerRankingInterval = setInterval(() => {
      this.buildMaximumPowerRankingCharts(
        _.merge(
          {},
          this.maximumPowerSort,
          {
            stationId: this.stationId,
          },
          this.maximumPowerRankingParams
        )
      );
    }, 1000 * 60 * this.maximumPowerRankingIntervalTime);

    // 逆变器日发电量排名
    if (this.powerGenerationRankingInterval) {
      clearInterval(this.powerGenerationRankingInterval);
      this.powerGenerationRankingInterval = null;
    }
    this.buildPowerGenerationRankingCharts(
      _.merge(
        {},
        this.powerGenerationSort,
        { stationId: this.stationId },
        this.powerGenerationRankingParams
      )
    );
    this.powerGenerationRankingInterval = setInterval(() => {
      this.buildPowerGenerationRankingCharts(
        _.merge(
          {},
          this.powerGenerationSort,
          {
            stationId: this.stationId,
          },
          this.powerGenerationRankingParams
        )
      );
    }, 1000 * 60 * this.powerGenerationRankingIntervalTime);
  }

  /**
   * getPerformance
   */
  public getPerformance(params) {
    this.stationmonitorservice.performance(params).then((res: any) => {
      const data = res;
      this.performanceList = data.content;
      this.performancePager.totalRecords = data.totalElements;
    });
  }

  /**
   * onPageChange
   */
  public onPageChange(event) {
    this.queryParams = {
      page: event.page,
      size: event.rows,
    };
    this.getPerformance(
      _.merge({}, this.queryParams, { stationId: this.stationId })
    );
  }

  /**
   * name
   */
  public goDiscreteRateStatistics() {
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/monitorStation/overview'],
        params: {
          stationId: this.stationId,
        },
      })
    );
    this.router.navigate(['/discreteRateStatistics']);
  }

  /**
   * toggleRunStartTimeOrder
   */
  public toggleOrder(type) {
    switch (type) {
      case 'runStartTime':
        this.runStartTimeSort.sortOrder =
          this.runStartTimeSort.sortOrder === 'desc' ? 'asc' : 'desc';
        this.buildStartTimeRankingCharts(
          _.merge(
            {},
            this.runStartTimeSort,
            {
              stationId: this.stationId,
            },
            this.startTimeParams
          )
        );
        break;
      case 'maximumPower':
        this.maximumPowerSort.sortOrder =
          this.maximumPowerSort.sortOrder === 'desc' ? 'asc' : 'desc';
        this.buildMaximumPowerRankingCharts(
          _.merge(
            {},
            this.maximumPowerSort,
            {
              stationId: this.stationId,
            },
            this.maximumPowerRankingParams
          )
        );
        break;

      case 'powerGeneration':
        this.powerGenerationSort.sortOrder =
          this.powerGenerationSort.sortOrder === 'desc' ? 'asc' : 'desc';
        this.buildPowerGenerationRankingCharts(
          _.merge(
            {},
            this.powerGenerationSort,
            {
              stationId: this.stationId,
            },
            this.powerGenerationRankingParams
          )
        );
        break;
      default:
        break;
    }
  }

  public togglePage(event, type) {
    switch (type) {
      case 'runStartTime':
        this.startTimeParams = {
          page: event.page,
          size: parseFloat(event.rows),
        };
        this.buildStartTimeRankingCharts(
          _.merge(
            {},
            this.runStartTimeSort,
            {
              stationId: this.stationId,
            },
            this.startTimeParams
          )
        );
        break;
      case 'maximumPower':
        this.maximumPowerRankingParams = {
          page: event.page,
          size: parseFloat(event.rows),
        };
        this.buildMaximumPowerRankingCharts(
          _.merge(
            {},
            this.maximumPowerSort,
            {
              stationId: this.stationId,
            },
            this.maximumPowerRankingParams
          )
        );
        break;

      case 'powerGeneration':
        this.powerGenerationRankingParams = {
          page: event.page,
          size: parseFloat(event.rows),
        };
        this.buildPowerGenerationRankingCharts(
          _.merge(
            {},
            this.powerGenerationSort,
            {
              stationId: this.stationId,
            },
            this.powerGenerationRankingParams
          )
        );
        break;
      default:
        break;
    }
  }

  /**
   * buildStartTimeRankingCharts
   */
  public buildStartTimeRankingCharts(params) {
    this.startTimeRankingLoading = true;
    this.stationmonitorservice.inveters(params).then((res: any) => {
      this.startTimeRankingLoading = false;
      this.startTimeRankingOption = buildStartTimeRankingChart(res);
      this.startTimeParamsTotalRecords = res.totalElements;
    });
  }

  /**
   * buildMaximumPowerRankingCharts
   */
  public buildMaximumPowerRankingCharts(params) {
    this.maximumPowerRankingLoading = true;
    this.stationmonitorservice.inveters(params).then((res: any) => {
      this.maximumPowerRankingLoading = false;
      this.maximumPowerRankingOption = buildMaximumPowerRankingChart(res);
      this.maximumPowerRankingTotalRecords = res.totalElements;
    });
  }
  /**
   * buildPowerGenerationRankingCharts
   */
  public buildPowerGenerationRankingCharts(params) {
    this.powerGenerationRankingLoading = true;
    this.stationmonitorservice.inveters(params).then((res: any) => {
      this.powerGenerationRankingLoading = false;
      this.powerGenerationRankingOption = buildPowerGenerationRankingChart(res);
      this.powerGenerationRankingTotalRecords = res.totalElements;
    });
  }

  public goDeviceStatus() {
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/monitorStation/overview'],
      })
    );
    this.router.navigate(['/monitorStation/deviceStatus'], {
      queryParams: {
        stationId: this.stationId,
      },
      queryParamsHandling: 'merge',
    });
  }
}
