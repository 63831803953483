// export function buildFounderTopologyChart(data) {
//     var objs = {
//         "01026": {
//             id: "01026",
//             name: "010101汇流箱",
//             pid: "01001",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01027": {
//             id: "01027",
//             name: "010102汇流箱",
//             pid: "01001",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01028": {
//             id: "01028",
//             name: "010103汇流箱",
//             pid: "01001",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01029": {
//             id: "01029",
//             name: "010104汇流箱",
//             pid: "01001",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01030": {
//             id: "01030",
//             name: "010105汇流箱",
//             pid: "01001",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01031": {
//             id: "01031",
//             name: "010106汇流箱",
//             pid: "01001",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01032": {
//             id: "01032",
//             name: "010201汇流箱",
//             pid: "01002",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01033": {
//             id: "01033",
//             name: "010202汇流箱",
//             pid: "01002",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01034": {
//             id: "01034",
//             name: "010203汇流箱",
//             pid: "01002",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01035": {
//             id: "01035",
//             name: "010204汇流箱",
//             pid: "01002",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01036": {
//             id: "01036",
//             name: "010205汇流箱",
//             pid: "01002",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01037": {
//             id: "01037",
//             name: "010206汇流箱",
//             pid: "01002",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01042": {
//             id: "01042",
//             name: "020101 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01038": {
//             id: "01038",
//             name: "020102 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01043": {
//             id: "01043",
//             name: "020103 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01044": {
//             id: "01044",
//             name: "020104 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00103",
//             stationId: "00001"
//         },
//         "01048": {
//             id: "01048",
//             name: "020105 汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01039": {
//             id: "01039",
//             name: "020106 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01049": {
//             id: "01049",
//             name: "020107 汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01045": {
//             id: "01045",
//             name: "020201汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01040": {
//             id: "01040",
//             name: "020202 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01046": {
//             id: "01046",
//             name: "020203 汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01047": {
//             id: "01047",
//             name: "020204 汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01050": {
//             id: "01050",
//             name: "020205 汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00101",
//             stationId: "00001"
//         },
//         "01041": {
//             id: "01041",
//             name: "020206 汇流箱",
//             pid: "01003",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01051": {
//             id: "01051",
//             name: "020207汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00103",
//             stationId: "00001"
//         },
//         "01057": {
//             id: "01057",
//             name: "020208 汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01053": {
//             id: "01053",
//             name: "030101汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01055": {
//             id: "01055",
//             name: "030102 汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01064": {
//             id: "01064",
//             name: "030103 汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01058": {
//             id: "01058",
//             name: "030104 汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01052": {
//             id: "01052",
//             name: "030105 汇流箱",
//             pid: "01004",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01066": {
//             id: "01066",
//             name: "030106 汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01059": {
//             id: "01059",
//             name: "030107汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00112",
//             stationId: "00001"
//         },
//         "01060": {
//             id: "01060",
//             name: "030108汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01054": {
//             id: "01054",
//             name: "030201 汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01056": {
//             id: "01056",
//             name: "030202 汇流箱",
//             pid: "01005",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01063": {
//             id: "01063",
//             name: "030203汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01065": {
//             id: "01065",
//             name: "030204 汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01062": {
//             id: "01062",
//             name: "030205 汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01061": {
//             id: "01061",
//             name: "030206 汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01067": {
//             id: "01067",
//             name: "030207汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01068": {
//             id: "01068",
//             name: "030208汇流箱",
//             pid: "01006",
//             type: "HLX",
//             areaId: "00102",
//             stationId: "00001"
//         },
//         "01069": {
//             id: "01069",
//             name: "040101汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00104",
//             stationId: "00001"
//         },
//         "01071": {
//             id: "01071",
//             name: "040102 汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00104",
//             stationId: "00001"
//         },
//         "01073": {
//             id: "01073",
//             name: "040103 汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01074": {
//             id: "01074",
//             name: "040104 汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01077": {
//             id: "01077",
//             name: "040105 汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00104",
//             stationId: "00001"
//         },
//         "01079": {
//             id: "01079",
//             name: "040106 汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01081": {
//             id: "01081",
//             name: "040107 汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01070": {
//             id: "01070",
//             name: "040201 汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00104",
//             stationId: "00001"
//         },
//         "01072": {
//             id: "01072",
//             name: "040202 汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00105",
//             stationId: "00001"
//         },
//         "01075": {
//             id: "01075",
//             name: "040203 汇流箱",
//             pid: "01007",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01076": {
//             id: "01076",
//             name: "040204 汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00104",
//             stationId: "00001"
//         },
//         "01078": {
//             id: "01078",
//             name: "040205 汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00105",
//             stationId: "00001"
//         },
//         "01080": {
//             id: "01080",
//             name: "040206 汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01082": {
//             id: "01082",
//             name: "040207汇流箱",
//             pid: "01008",
//             type: "HLX",
//             areaId: "00113",
//             stationId: "00001"
//         },
//         "01083": {
//             id: "01083",
//             name: "050101汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00106",
//             stationId: "00001"
//         },
//         "01085": {
//             id: "01085",
//             name: "050102 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01086": {
//             id: "01086",
//             name: "050103 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01088": {
//             id: "01088",
//             name: "050104 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01090": {
//             id: "01090",
//             name: "050105 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00110",
//             stationId: "00001"
//         },
//         "01092": {
//             id: "01092",
//             name: "050106 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01095": {
//             id: "01095",
//             name: "050107 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01097": {
//             id: "01097",
//             name: "050108 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00110",
//             stationId: "00001"
//         },
//         "01084": {
//             id: "01084",
//             name: "050201 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00114",
//             stationId: "00001"
//         },
//         "01093": {
//             id: "01093",
//             name: "050202 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01087": {
//             id: "01087",
//             name: "050203 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01089": {
//             id: "01089",
//             name: "050204 汇流箱",
//             pid: "01009",
//             type: "HLX",
//             areaId: "00110",
//             stationId: "00001"
//         },
//         "01091": {
//             id: "01091",
//             name: "050205 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00114",
//             stationId: "00001"
//         },
//         "01094": {
//             id: "01094",
//             name: "050206 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00107",
//             stationId: "00001"
//         },
//         "01096": {
//             id: "01096",
//             name: "050207 汇流箱",
//             pid: "01010",
//             type: "HLX",
//             areaId: "00110",
//             stationId: "00001"
//         },
//         "01098": {
//             id: "01098",
//             name: "060101汇流箱",
//             pid: "01011",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01100": {
//             id: "01100",
//             name: "060102 汇流箱",
//             pid: "01011",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01102": {
//             id: "01102",
//             name: "060103 汇流箱",
//             pid: "01012",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01104": {
//             id: "01104",
//             name: "060104 汇流箱",
//             pid: "01012",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01099": {
//             id: "01099",
//             name: "060201 汇流箱",
//             pid: "01011",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01101": {
//             id: "01101",
//             name: "060202 汇流箱",
//             pid: "01011",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01103": {
//             id: "01103",
//             name: "060203 汇流箱",
//             pid: "01012",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01105": {
//             id: "01105",
//             name: "060204汇流箱",
//             pid: "01012",
//             type: "HLX",
//             areaId: "00109",
//             stationId: "00001"
//         },
//         "01013": {
//             id: "01013",
//             name: "FYLQ-301-T01-ZNB0101 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01014": {
//             id: "01014",
//             name: "FYLQ-301-T01-ZNB0102 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01015": {
//             id: "01015",
//             name: "FYLQ-301-T01-ZNB0103 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01016": {
//             id: "01016",
//             name: "FYLQ-301-T01-ZNB0104 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01017": {
//             id: "01017",
//             name: "FYLQ-301-T01-ZNB0201 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01018": {
//             id: "01018",
//             name: "FYLQ-301-T01-ZNB0202 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01019": {
//             id: "01019",
//             name: "FYLQ-301-T01-ZNB0203 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01020": {
//             id: "01020",
//             name: "FYLQ-301-T01-ZNB0204 逆变器",
//             pid: "00111",
//             type: "NB",
//             areaId: "00111",
//             stationId: "00001"
//         },
//         "01021": {
//             id: "01021",
//             name: "FYLQ-301-T01-ZNB0301 逆变器",
//             pid: "01120",
//             type: "NB",
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '01022': {
//             id: '01022',
//             name: 'FYLQ-301-T01-ZNB0302 逆变器',
//             pid: '01120',
//             type: 'NB',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '01023': {
//             id: '01023',
//             name: 'FYLQ-301-T01-ZNB0303 逆变器',
//             pid: '01120',
//             type: 'NB',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '01024': {
//             id: '01024',
//             name: 'FYLQ-301-T01-ZNB0304 逆变器',
//             pid: '01120',
//             type: 'NB',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '01025': {
//             id: '01025',
//             name: 'FYLQ-301-T01-ZNB0401 逆变器',
//             pid: '01120',
//             type: 'NB',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '000010001322': {
//             id: '000010001322',
//             name: '11111 智能网关',
//             pid: '0002200002',
//             type: 'E2BOX',
//             areaId: '0002200002',
//             stationId: '00001'
//         },
//         '01108': {
//             id: '01108',
//             name: '1#开关柜',
//             pid: '00115',
//             type: 'GYKG',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01114': {
//             id: '01114',
//             name: '#1箱变',
//             pid: '00101',
//             type: 'BYQ',
//             areaId: '00101',
//             stationId: '00001'
//         },
//         '01109': {
//             id: '01109',
//             name: '2#开关柜',
//             pid: '00115',
//             type: 'GYKG',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01115': {
//             id: '01115',
//             name: '#2箱变',
//             pid: '00102',
//             type: 'BYQ',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '01110': {
//             id: '01110',
//             name: '3#开关柜',
//             pid: '00115',
//             type: 'GYKG',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01116': {
//             id: '01116',
//             name: '#3箱变',
//             pid: '00102',
//             type: 'BYQ',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '00113': {
//             id: '00113',
//             name: '4#厂房办公楼',
//             pid: '00001',
//             type: 'area',
//             areaId: '00113',
//             stationId: '00001'
//         },
//         '01111': {
//             id: '01111',
//             name: '4#开关柜',
//             pid: '00115',
//             type: 'GYKG',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01117': {
//             id: '01117',
//             name: '#4箱变',
//             pid: '00104',
//             type: 'BYQ',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '01112': {
//             id: '01112',
//             name: '5#开关柜',
//             pid: '00115',
//             type: 'GYKG',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01118': {
//             id: '01118',
//             name: '#5箱变',
//             pid: '00108',
//             type: 'BYQ',
//             areaId: '00108',
//             stationId: '00001'
//         },
//         '01113': {
//             id: '01113',
//             name: '6#开关柜',
//             pid: '00115',
//             type: 'GYKG',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01119': {
//             id: '01119',
//             name: '#6箱变',
//             pid: '00104',
//             type: 'BYQ',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '01120': {
//             id: '01120',
//             name: '#7箱变',
//             pid: '00111',
//             type: 'BYQ',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '00104': {
//             id: '00104',
//             name: 'A线-喷漆美装车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '00105': {
//             id: '00105',
//             name: 'A线-总装焊接车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00105',
//             stationId: '00001'
//         },
//         '00107': {
//             id: '00107',
//             name: 'B线-喷漆美装车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00107',
//             stationId: '00001'
//         },
//         '00108': {
//             id: '00108',
//             name: 'B线-总装焊接车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00108',
//             stationId: '00001'
//         },
//         '01106': {
//             id: '01106',
//             name: 'JH01汇流箱',
//             pid: '00111',
//             type: 'JHL',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '01107': {
//             id: '01107',
//             name: 'JH02汇流箱',
//             pid: '00111',
//             type: 'JHL',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '01001': {
//             id: '01001',
//             name: 'NB01逆变器',
//             pid: '01114',
//             type: 'JNB',
//             areaId: '00101',
//             stationId: '00001'
//         },
//         '01002': {
//             id: '01002',
//             name: 'NB02逆变器',
//             pid: '01114',
//             type: 'JNB',
//             areaId: '00101',
//             stationId: '00001'
//         },
//         '01003': {
//             id: '01003',
//             name: 'NB03逆变器',
//             pid: '01115',
//             type: 'JNB',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '01004': {
//             id: '01004',
//             name: 'NB04逆变器',
//             pid: '01115',
//             type: 'JNB',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '01005': {
//             id: '01005',
//             name: 'NB05逆变器',
//             pid: '01116',
//             type: 'JNB',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '01006': {
//             id: '01006',
//             name: 'NB06逆变器',
//             pid: '01116',
//             type: 'JNB',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '01011': {
//             id: '01011',
//             name: 'NB07逆变器',
//             pid: '01117',
//             type: 'JNB',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '01012': {
//             id: '01012',
//             name: 'NB08逆变器',
//             pid: '01117',
//             type: 'JNB',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '01009': {
//             id: '01009',
//             name: 'NB09逆变器',
//             pid: '01118',
//             type: 'JNB',
//             areaId: '00108',
//             stationId: '00001'
//         },
//         '01010': {
//             id: '01010',
//             name: 'NB10逆变器',
//             pid: '01118',
//             type: 'JNB',
//             areaId: '00108',
//             stationId: '00001'
//         },
//         '01007': {
//             id: '01007',
//             name: 'NB11逆变器',
//             pid: '01119',
//             type: 'JNB',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '01008': {
//             id: '01008',
//             name: 'NB12逆变器',
//             pid: '01119',
//             type: 'JNB',
//             areaId: '00104',
//             stationId: '00001'
//         },
//         '000010001321': {
//             id: '000010001321',
//             name: 'QDSS-JLGDB01电表',
//             pid: '00115',
//             type: 'DNB',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01127': {
//             id: '01127',
//             name: 'SVG无功补偿',
//             pid: '00117',
//             type: 'QT',
//             areaId: '00117',
//             stationId: '00001'
//         },
//         '00117': {
//             id: '00117',
//             name: 'SVG站房',
//             pid: '00001',
//             type: 'area',
//             areaId: '00117',
//             stationId: '00001'
//         },
//         '00112': {
//             id: '00112',
//             name: '办公楼',
//             pid: '00001',
//             type: 'area',
//             areaId: '00112',
//             stationId: '00001'
//         },
//         '01123': {
//             id: '01123',
//             name: '部颁电表2007版v2.00',
//             pid: '00116',
//             type: 'DNB',
//             areaId: '00116',
//             stationId: '00001'
//         },
//         '01124': {
//             id: '01124',
//             name: '灿能电能质量',
//             pid: '00116',
//             type: 'QT',
//             areaId: '00116',
//             stationId: '00001'
//         },
//         '00110': {
//             id: '00110',
//             name: '仓库三',
//             pid: '00001',
//             type: 'area',
//             areaId: '00110',
//             stationId: '00001'
//         },
//         '00109': {
//             id: '00109',
//             name: '仓库一',
//             pid: '00001',
//             type: 'area',
//             areaId: '00109',
//             stationId: '00001'
//         },
//         '00116': {
//             id: '00116',
//             name: '二次设备室',
//             pid: '00001',
//             type: 'area',
//             areaId: '00116',
//             stationId: '00001'
//         },
//         '00115': {
//             id: '00115',
//             name: '高压站房',
//             pid: '00001',
//             type: 'area',
//             areaId: '00115',
//             stationId: '00001'
//         },
//         '01125': {
//             id: '01125',
//             name: '公用测控',
//             pid: '00116',
//             type: 'QT',
//             areaId: '00116',
//             stationId: '00001'
//         },
//         '01126': {
//             id: '01126',
//             name: '国电京润直流屏',
//             pid: '00116',
//             type: 'QT',
//             areaId: '00116',
//             stationId: '00001'
//         },
//         '00111': {
//             id: '00111',
//             name: '海工车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00111',
//             stationId: '00001'
//         },
//         '00114': {
//             id: '00114',
//             name: '零部件加工车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00114',
//             stationId: '00001'
//         },
//         '01121': {
//             id: '01121',
//             name: '频率电压控制柜',
//             pid: '00116',
//             type: 'QT',
//             areaId: '00116',
//             stationId: '00001'
//         },
//         '00001': {
//             id: '00001',
//             name: '启东胜狮',
//             pid: '0',
//             type: 'station',
//             areaId: '',
//             stationId: '00001'
//         },
//         '01122': {
//             id: '01122',
//             name: '气象站',
//             pid: '00101',
//             type: 'QXZ',
//             areaId: '00101',
//             stationId: '00001'
//         },
//         '00103': {
//             id: '00103',
//             name: '太平物流仓库一',
//             pid: '00001',
//             type: 'area',
//             areaId: '00103',
//             stationId: '00001'
//         },
//         '00102': {
//             id: '00102',
//             name: '太平物流车间二',
//             pid: '00001',
//             type: 'area',
//             areaId: '00102',
//             stationId: '00001'
//         },
//         '00101': {
//             id: '00101',
//             name: '太平物流车间一',
//             pid: '00001',
//             type: 'area',
//             areaId: '00101',
//             stationId: '00001'
//         },
//         '00106': {
//             id: '00106',
//             name: '预处理车间',
//             pid: '00001',
//             type: 'area',
//             areaId: '00106',
//             stationId: '00001'
//         }
//     };

//     var stationId = '00001';

//     var v, parent;
//     for (const i in objs) {
//         if (objs.hasOwnProperty(i)) {
//             var child = objs[i];

//             if (child.type == 'ZC') continue;

//             if (child.type == 'station') {
//                 child.itemStyle = { color: '#37A2DA' };
//             } else if (child.type == 'area') {
//                 child.itemStyle = { color: '#37A2DA' };
//                 parent = objs[child.pid];
//                 if (parent != undefined) {
//                     if (parent.children === undefined) {
//                         parent.children = [];
//                     }
//                     parent.children.push(child);
//                 }
//             } else {
//                 v = Math.random();
//                 if (v > 0.98) {
//                     child.itemStyle = { color: '#999999' };
//                     child.tooltip = { formatter: '{b}</br>离线' };
//                 } else if (v > 0.94) {
//                     child.itemStyle = { color: '#FB7293' };
//                     child.tooltip = { formatter: '{b}</br>告警' };
//                 } else {
//                     child.itemStyle = { color: '#9FE6B8' };
//                     child.tooltip = { formatter: '{b}</br>正常' };
//                 }
//                 parent = objs[child.pid];
//                 if (parent != undefined) {
//                     if (child.areaId != parent.areaId) continue;

//                     if (parent.children === undefined) {
//                         parent.children = [];
//                     }
//                     parent.children.push(child);
//                 }
//             }
//         }
//     }

//     // 指定图表的配置项和数据
//     var option = {
//         //color: ['#2196F3', '#FFC107', '#45A349', '#FF5722', '#9C27B0', '#7FBCEC', '#21C7E2', '#84A9C1', '#409E8F'],
//         backgroundColor: 'rgba(255, 255, 255, 0)',
//         tooltip: {
//             trigger: 'item'
//             //triggerOn: 'mousemove'
//         },
//         series: [
//             {
//                 type: 'tree',
//                 symbol: 'circle',
//                 symbolSize: 7,
//                 roam: true,
//                 initialTreeDepth: 3,
//                 itemStyle: {
//                     borderWidth: 0
//                 },
//                 label: {
//                     normal: {
//                         position: 'top',
//                         verticalAlign: 'middle',
//                         align: 'center',
//                         fontSize: 12
//                     }
//                 },
//                 leaves: {
//                     label: {
//                         normal: {
//                             position: 'right',
//                             verticalAlign: 'middle',
//                             align: 'left'
//                         }
//                     }
//                 },
//                 data: [objs[data]]
//             }
//         ]
//     };

//     return option;
// }

export function buildFounderTopologyChart(stationId, objs, theme = 'light') {
  let parent;
  for (const i in objs) {
    if (objs.hasOwnProperty(i)) {
      let child = objs[i];
      theme === 'dark'
        ? (child.label = {
            color: '#fff',
          })
        : '';
      if (child.type === 'station') {
        child.itemStyle = { color: '#37A2DA' };
      } else if (child.type === 'area') {
        child.itemStyle = { color: '#37A2DA' };
        parent = objs[child.pid];
        if (parent !== undefined) {
          if (parent.children === undefined) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      } else {
        if (child.commuStatus !== '0') {
          child.itemStyle = { color: '#999999' };
        } else if (child.realAlarms > 0) {
          child.itemStyle = { color: '#FB7293' };
        } else {
          child.itemStyle = { color: '#9FE6B8' };
        }
        child.tooltip = {
          formatter:
            '设备名称：{b}' +
            '</br>设备分类：' +
            child.className +
            '</br>通讯状态：' +
            (child.commuStatus === '0' ? '在线' : '离线') +
            '</br>实时告警：' +
            child.realAlarms,
        };

        if (child.pid == null) {
          // 无父设备的直接挂到区域下面
          parent = objs[child.areaId];
        } else {
          // 有父设备的，要看父设备是否在同一个区域内，不是同一个区域的，不挂载
          parent = objs[child.pid];
          if (parent && parent.areaId !== child.areaId) {
            parent = objs[child.areaId];
          }
        }
        if (parent !== undefined) {
          if (parent.children === undefined) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      }
    }
  }

  // 指定图表的配置项和数据
  let option = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'tree',
        symbol: 'circle',
        top: '1%',
        left: '2%',
        bottom: '1%',
        right: '2%',
        symbolSize: 7,
        roam: true,
        initialTreeDepth: 3,
        itemStyle: {
          borderWidth: 0,
        },
        label: {
          normal: {
            position: 'top',
            verticalAlign: 'middle',
            align: 'center',
            fontSize: 12,
          },
        },
        leaves: {
          label: {
            normal: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left',
            },
          },
        },
        data: [objs[stationId]],
      },
    ],
  };
  return option;
}
