import * as _ from 'lodash';
import * as echarts from 'echarts';

export const powerOption = {
  color: ['#37A2DA', '#FFDB5C'],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross',
      label: {
        color: '#00437f',
      },
    },
  },
  legend: {
    data: ['当日功率', '昨日功率'],
  },
  xAxis: {
    type: 'category',
    name: '时间',
    boundaryGap: false,
    data: [],
  },
  yAxis: {
    type: 'value',
    name: 'KW',
  },
  dataZoom: [
    {
      type: 'inside',
      start: 0,
      end: 100,
    },
  ],
  series: [
    {
      name: '当日功率',
      type: 'line',
      smooth: true,
      symbol: 'none',
      sampling: 'average',
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: 'rgba(33, 150, 243, 1)',
          },
          {
            offset: 1,
            color: 'rgba(33, 150, 243, 0)',
          },
        ]),
      },
      data: [],
    },
  ],
};

export function buildPowerChart(data) {
  let option: any = _.cloneDeep(powerOption);
  if (data) {
    option.xAxis.data = data.xAxis.data;
    option.yAxis = data.yAxis;
    option.series[0].data = data.series[0].data;
  }
  return option;
}
