import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class PrivatePageLayoutDashboardGuard
  implements CanDeactivate<CanComponentDeactivate>
{
  constructor(private router: Router, private authService: AuthService) { }
  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    if (
      !this.authService.loggedIn() &&
      nextState.url !== '/login'
    ) {
      this.router.navigate(['/login']);
      return true;
    }


    if ((nextState.url.indexOf('yxjs') === -1 || nextState.url.indexOf('scgl') === -1 || nextState.url.indexOf('yyfx') === -1 || nextState.url.indexOf('xtpz') === -1)) {

      if (nextState.url.indexOf('#') !== -1) {
        return true
      }
      if (nextState.url.indexOf('login') !== -1) {
        return true
      }
      if (currentState.url.indexOf('yxjs') !== -1 && nextState.url.indexOf('yxjs') === -1) {
        this.router.navigateByUrl(`/yxjs${nextState.url}`);
      }
      if (currentState.url.indexOf('scgl') !== -1 && nextState.url.indexOf('scgl') === -1) {
        this.router.navigateByUrl(`/scgl${nextState.url}`);
      }
      if (currentState.url.indexOf('yyfx') !== -1 && nextState.url.indexOf('yyfx') === -1) {
        this.router.navigateByUrl(`/yyfx${nextState.url}`);
      }
      if (currentState.url.indexOf('xtpz') !== -1 && nextState.url.indexOf('xtpz') === -1) {
        this.router.navigateByUrl(`/xtpz${nextState.url}`);
      }
    }

    return true;
  }
}
