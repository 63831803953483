import { NgModule } from '@angular/core';

import { SharedModule } from '../../../../shared/shared.module';
import { DeviceAssetsService } from '../device-assets.service';
import { MaterialInquiryComponent } from './material-inquiry.component';
import { DialogModule, InputTextModule, ButtonModule } from 'primeng/primeng';

@NgModule({
  imports: [SharedModule, DialogModule, InputTextModule, ButtonModule],
  exports: [MaterialInquiryComponent],
  declarations: [MaterialInquiryComponent],
  providers: [DeviceAssetsService],
})
export class MaterialInquiryModule {}
