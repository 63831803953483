import { Component, OnInit } from '@angular/core';
import { StationMonitorService } from '../station-monitor.service';

@Component({
  selector: 'app-station-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.less'],
})
export class OverviewComponent implements OnInit {
  showFlag: {
    commStatus: boolean;
    performance: boolean;
    alarms: boolean;
    weather: boolean;
    health: boolean;
    inveters: boolean;
    runStatus: boolean;
    energy: boolean;
  } = {
      commStatus: false,
      performance: false,
      alarms: false,
      weather: false,
      health: false,
      inveters: false,
      runStatus: false,
      energy: false,
    };
  constructor(private stationmonitorservice: StationMonitorService) { }

  ngOnInit() {
    this.stationmonitorservice.monitorStationInit().then((res: any) => {
      this.showFlag = { ...res };
    });
  }
}
