import { Injectable } from "@angular/core";
import { AuthHttp } from "../../../core/auth-http";
import { AppConfigService } from "../../../app.config";
import { ClassesType } from "../../../common/models/classes-type.model";

@Injectable()
export class ClassesService {
    constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

    /**
     * @param type
     * 01	设备分类
     * 02	备品备件分类
     * 03	工器具类别
     * 04	缺陷类别
     * 05	工单类别
     * 09	非生产物资
     */
    public getClassesType(type) {
        let url = "";
        if (type === ClassesType.nonProductiveType) {
            url = `${this.appConfig.apiUrl}/api/class/classes`;
        } else {
            url = `${this.appConfig.apiUrl}/api/class/tree`;
        }
        return this.http.get(url, { search: { classType: type } }).toPromise();
    }
}
