import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import * as _ from 'lodash';
import { DropdownDirective } from './dropdown.directive';
import { TreeviewConfig } from './treeview-config';
import { TreeviewHeaderTemplateContext } from './treeview-header-template-context';
import { TreeviewI18n } from './treeview-i18n';
import { TreeviewItem } from './treeview-item';
import { TreeviewItemTemplateContext } from './treeview-item-template-context';
import { TreeviewComponent } from './treeview.component';

@Component({
  selector: 'ngx-dropdown-treeview',
  templateUrl: './dropdown-treeview.component.html',
  styleUrls: ['./dropdown-treeview.component.less'],
})
export class DropdownTreeviewComponent implements OnChanges {
  @Input() public isRadio: boolean = false;
  @Input() public values: string[] = [];
  @Input() public showText: string = '';
  @Input() public defaultText: string = '';
  @Input() public buttonClass = 'btn-outline-secondary';
  @Input() public headerTemplate: TemplateRef<TreeviewHeaderTemplateContext>;
  @Input() public itemTemplate: TemplateRef<TreeviewItemTemplateContext>;
  @Input() public items: TreeviewItem[];
  @Input() public config: TreeviewConfig;
  @Output() public selectedChange = new EventEmitter<any[]>(true);
  @ViewChild(TreeviewComponent, { static: true })
  public treeviewComponent: TreeviewComponent;
  @ViewChild(DropdownDirective, { static: true })
  public dropdownDirective: DropdownDirective;

  constructor(
    public i18n: TreeviewI18n,
    private defaultConfig: TreeviewConfig
  ) {
    this.config = this.defaultConfig;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.items && changes.values) {
      if (!_.isEmpty(changes.values.currentValue)) {
        this.showText = '';
      }
      if (this.isRadio) {
        this.items = this.setRadioValue(
          changes.items.currentValue,
          changes.values.currentValue[0]
        );
      } else {
        this.items = this.setValue(
          changes.items.currentValue,
          changes.values.currentValue
        );
      }
    }
    if (changes.showText) {
      this.showText = changes.showText.currentValue;
    }
  }
  // 多选初始化
  public setValue(items: TreeviewItem[], value: string[]) {
    let showTexts = [];
    for (let item of items) {
      for (let val of value) {
        if (item.children) {
          if (item.value === val) {
            item.checked = true;
            showTexts.push(item.text);
            // 父节点选中则子节点全部选中，并展开子节点
            item.collapsed = false;
            for (let child of item.children) {
              child.checked = true;
              showTexts.push(child.text);
            }
          } else {
            for (let child of item.children) {
              if (child.value === val) {
                child.checked = true;
                showTexts.push(child.text);
                item.collapsed = false;
              }
            }
            item.children = this.setValue(item.children, [val]);
          }
        } else if (item.value === val) {
          item.checked = true;
          showTexts.push(item.text);
        }
      }
    }
    if (!_.isEmpty(showTexts)) {
      this.showText += `${showTexts.join(',')},`;
    }
    return items;
  }

  // 单选初始化
  public setRadioValue(items: TreeviewItem[], value: string) {
    let showTexts = [];
    for (let item of items) {
      if (item.children) {
        if (item.value === value) {
          let isNodeCollapsed: boolean = true;
          for (let child of item.children) {
            if (child.value === value) {
              child.checked = true;
              isNodeCollapsed = false;
              showTexts.push(child.text);
            }
          }
          item.collapsed = isNodeCollapsed;
          item.checked = isNodeCollapsed;
          if (isNodeCollapsed) {
            showTexts.push(item.text);
          }
          // item.children = this.setRadioValue(item.children, value);
        } else {
          let isNodeCollapsed: boolean = true;
          for (let child of item.children) {
            if (child.value === value) {
              child.checked = true;
              isNodeCollapsed = false;
              showTexts.push(child.text);
              break;
            }
            if (child.children) {
              child.children = this.setRadioValue(child.children, value);
            }
          }
          item.collapsed = isNodeCollapsed;
        }
      } else if (item.value === value) {
        // 无子节点
        item.checked = true;
        showTexts.push(item.text);
      }
    }
    if (!_.isEmpty(showTexts)) {
      this.showText += `${_.union(showTexts).join(',')}`;
    }
    return items;
  }

  public getText(): string {
    return this.i18n.getText(
      this.treeviewComponent.selection,
      this.defaultText
    );
  }

  public onSelectedChange(values: any[]) {
    if (_.isEmpty(this.values) || !this.isRadio) {
      this.showText = this.i18n.getText(
        this.treeviewComponent.selection,
        this.defaultText
      );
    }
    this.selectedChange.emit(values);
  }
}
