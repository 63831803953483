import { Injectable } from '@angular/core';
import { AuthHttp } from './../../../core/auth-http';
import { AppConfigService } from '../../../app.config';

@Injectable({
  providedIn: 'root',
})
export class TopbarService {
  private apiUrl = this.appConfig.apiUrl;

  constructor(private http: AuthHttp, private appConfig: AppConfigService) {}

  /**
   * userInfo
   */
  public userInfo() {
    return this.http.get(`${this.apiUrl}/api/frame/user`);
  }
  /**
   * saveUserInfo
   */
  public saveUserInfo(params) {
    return this.http.put(`${this.apiUrl}/api/frame/user`, params);
  }

  /*
   * savePassword
   */
  public savePassword(params) {
    return this.http.put(`${this.apiUrl}/api/frame/password`, '', {
      params,
    });
  }

  // 获取实时告警页面数据数据(新)
  public webAlarmNotice() {
    return this.http
      .get(`${this.apiUrl}/api/alarmLog/webAlarmNotice`)
      .toPromise();
  }

  // 获取实时告警页面数据数据(新)
  public webMessagesNotice(page) {
    return this.http.get(`${this.apiUrl}/api/frame/messages`, {
      params: { page, size: 5 },
    });
  }

  /**
   * checkMessage
   */
  public checkMessage(params) {
    return this.http.put(`${this.apiUrl}/api/frame/messages`, '', {
      params,
    });
  }

  public search(params) {
    return this.http.get(`${this.apiUrl}/api/frame/search`, {
      params,
    });
  }
}
