import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";

import { PointConfigComponent } from "./point-config.component";
import { PointConfigService } from "./point-config.service";
import { PointEidtListComponent } from "./point-edit-list/point-edit-list.component";
import { SharedModule } from "../../../../shared/shared.module";
import {
    DialogModule,
    DropdownModule,
    ButtonModule,
    SplitButtonModule,
    InputTextModule,
    CheckboxModule
} from "primeng/primeng";
import { VolumnSetDialogComponent } from "./volumn-set-dialog/volumn-set-dialog.component";
import { StationConfigCaseComponent } from "./station-config-case/station-config-case.component";
import { DragulaModule } from "ng2-dragula";
import { TableModule } from "primeng/table";

@NgModule({
    imports: [
        SharedModule,
        DialogModule,
        DropdownModule,
        ButtonModule,
        TableModule,
        SplitButtonModule,
        FormsModule,
        InputTextModule,
        ReactiveFormsModule,
        CommonModule,
        DragulaModule.forRoot(),
        CheckboxModule
    ],
    exports: [PointConfigComponent, VolumnSetDialogComponent],
    declarations: [
        PointConfigComponent,
        PointEidtListComponent,
        VolumnSetDialogComponent,
        StationConfigCaseComponent
    ],
    providers: [PointConfigService]
})
export class PointConfigModule {}
