import { Component, OnInit } from '@angular/core';
import { TipService } from '@common/services/tip.service';
import { MessageService, Message } from 'primeng/primeng';

@Component({
  selector: 'app-tip',
  templateUrl: './tip.component.html',
  styleUrls: ['./tip.component.less'],
})
export class TipComponent implements OnInit {
  constructor(private messageService: MessageService, private tipservice: TipService) {
    this.tipservice.tipContent.subscribe((msg: Message[]) => {
      if (msg.length === 1) {
        this.messageService.add({
          severity: msg[0].severity,
          summary: msg[0].summary,
          detail: msg[0].detail,
        });
      }
    });
  }

  ngOnInit() {
  }
}
