import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { saveToken } from './auth-http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PrivatePageGuard implements CanActivate {
  constructor(
    private activatedroute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private location: Location
  ) {}
  public canActivate() {
    const token = this.getQueryVariable('token');
    if (token) {
      saveToken(token);
    }
    if (!this.authService.loggedIn()) {
      this.router.navigate(['/login']);
    }
    return this.authService.loggedIn();
  }

  public getQueryVariable(variable) {
    const query = this.location.path().split('?')[1];
    if (query) {
      const vars = query.split('&');

      for (const item of vars) {
        const pair = item.split('=');
        if (pair[0] === variable) {
          return pair[1];
        }
      }
    }
    return false;
  }
}
