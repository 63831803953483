import * as _ from 'lodash';

export const communicationStatusOption = {
  color: [],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)',
  },
  title: {
    show: false,
    text: '',
    left: '',
    top: 'middle',
    textStyle: {
      fontSize: '24',
      color: '#727272',
    },
  },
  legend: {
    orient: 'vertical',
    // left: "right",
    right: '9%',
    top: 'middle',
    data: [],
  },
  series: [
    {
      name: '通讯状态',
      type: 'pie',
      radius: ['50%', '65%'],
      avoidLabelOverlap: false,
      center: ['35%', '50%'],
      cursor: 'default',
      label: {
        normal: {
          show: false,
        },
      },
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [],
    },
    {
      name: '',
      type: 'pie',
      radius: [0, '50%'],
      center: ['35%', '50%'],
      label: {
        show: true,
        position: 'center',
        formatter: '{total|1}',
        rich: {
          total: {
            fontSize: 22,
            fontFamily: '微软雅黑',
            color: '#727272',
          },
        },
      },
      itemStyle: {
        normal: {
          color: 'transparent',
        },
        emphasis: {
          show: false,
        },
      },
      hoverAnimation: false,
      data: [100],
      zlevel: 2,
      silent: true,
    },
  ],
};

export function buildCommunicationStatusChart(data, type) {
  let option = _.cloneDeep(communicationStatusOption);
  switch (type) {
    case 'stations':
      option.color = ['#FB7293', '#E7BCF3', '#E0E0E0'];
      break;

    case 'gateway':
      option.color = ['#E690D1', '#E0E0E0'];
      break;

    case 'infrared_concentrator':
      option.color = ['#9D96F5', '#E0E0E0'];
      break;

    case 'devices':
      option.color = ['#96BFFF', '#E0E0E0'];
      break;
    default:
      option.color = ['#FB7293', '#E7BCF3', '#E0E0E0'];
      break;
  }

  option.legend.data = data.legend.data;
  option.series[0].data = data.series[0].data;
  option.series[1].label.formatter = `{total|${data.title.text}}`;
  return option;
}
