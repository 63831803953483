import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';

import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/primeng';
import * as _ from 'lodash';
import { DocumentList } from 'src/app/components/document/shared/document.model';
import { CommonService } from 'src/app/common/services/common.service';
import { DeviceManageService } from 'src/app/components/device-assets/device-manage/device-manage.service';
import { ToastService } from 'src/app/core/toast.service';
import { PageType } from '../equipment-files/equipment-files.component.model';

@Component({
  selector: 'app-device-document',
  templateUrl: './device-document.component.html',
  styleUrls: ['../device-detail.component.less'],
})
export class DeviceDocumentComponent implements OnInit, OnChanges {
  @Input() public deviceID;
  @Input() public type;
  @Input() public stationId;
  @Input() public showButton = true;

  public deviceId;
  public device;
  public queryParam = {};

  public isAdd = false;
  public docType: string = '';
  public recordType;
  public recordId;
  public title;

  public deviceClass: DocumentList[] = [];
  public pagerClass = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };
  public deviceModel: DocumentList[] = [];
  public pagerModle = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };
  public deviceDocument: DocumentList[] = [];
  public pagerDocument = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };

  public dataEditable = false;

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private deviceManageService: DeviceManageService,
    private route: ActivatedRoute,
    private toastService: ToastService,
    private confirmationService: ConfirmationService
  ) {
    this.deviceManageService.isDevice.subscribe((flag) => {
      if (flag) {
        this.queryClass();
        this.queryModle();
        this.queryDocument();
      }
    });
  }

  public ngOnInit() {
    // this.deviceManageService.isDevice.subscribe(flag => {
    //   if (flag) {
    //     this.queryClass();
    //     this.queryModle();
    //     this.queryDocument();
    //   }
    // });
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if (!changes.deviceID && !changes.type && !changes.stationId) { return false; }
    this.route.queryParams.subscribe((params) => {
      if (this.deviceID) {
        this.deviceId = this.deviceID;
        this.deviceManageService.getDeviceById(this.deviceID).subscribe((res) => {
          this.device = res;
          this.queryClass();
          this.queryModle();
          this.queryDocument();
        });
      }

      if (this.type) {
        this.dataEditable = this.type !== PageType.DETAIL ? true : false;
      }
    });
  }

  public onPageChangeClass(event) {
    this.pagerClass.pageCurrent = event.page;
    this.pagerClass.pageSize = parseFloat(event.rows);
    this.queryClass();
  }
  public queryClass() {
    let param = _.assign({}, this.queryParam, {
      deviceId: this.deviceId,
    });
    this.deviceManageService.getDeviceDocumentNew(param).subscribe((res: any) => {
      this.deviceClass = res.DCL;
    });
  }

  public onPageChangeModle(event) {
    this.pagerModle.pageCurrent = event.page;
    this.pagerModle.pageSize = parseFloat(event.rows);
    this.queryClass();
  }
  public queryModle() {
    let param = _.assign({}, this.queryParam, {
      deviceId: this.deviceId,
    });
    this.deviceManageService.getDeviceDocumentNew(param).subscribe((res: any) => {
      this.deviceModel = res.DMD;
    });
  }

  public onPageChangeDocument(event) {
    this.pagerDocument.pageCurrent = event.page;
    this.pagerDocument.pageSize = parseFloat(event.rows);
    this.queryClass();
  }
  public queryDocument() {
    let param = _.assign({}, this.queryParam, {
      deviceId: this.deviceId,
    });
    this.deviceManageService.getDeviceDocumentNew(param).subscribe((res: any) => {
      this.deviceDocument = res.DDV;
    });
  }

  // 下载
  public download(id: string) {
    this.deviceManageService.exportFile(id);
  }

  // 删除单个对象
  public deleteList(id: string) {
    if (!id) {
      this.toastService.warning('请先选择数据');
      return;
    }
    const ids = [];
    ids.push(id);
    this.deleteL(ids);
  }

  // 删除方法
  public deleteL(ids) {
    this.confirmationService.confirm({
      header: '删除记录',
      rejectVisible: true,
      acceptVisible: true,
      message: `确定删除记录吗？`,
      accept: () => {
        this.deviceManageService.deleteFile(ids).catch((error) => {
          this.common.errorMessage(error);
        });
      },
    });
  }

  // 添加文件
  public addDocumentList(docType, title) {
    this.isAdd = true;
    this.docType = docType;
    this.title = title;
  }
}
