import { Injectable } from '@angular/core';
import { TipService } from '@common/services/tip.service';
import * as _ from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AppConfigService } from '../../../app.config';
import { CommonService } from '../../../common/services/common.service';
import { AuthHttp } from '../../../core/auth-http';

@Injectable()
export class AssetsBasicInfoService {
  public apiUrl: string = this.appConfig.apiUrl;
  public isSubmitWarehouse = new Subject<boolean>();
  public isSafeInventories = new Subject<boolean>();
  public isSubmitMaterial = new Subject<boolean>();

  private regionsSource$ = new BehaviorSubject<object>(null);
  currentRegions = this.regionsSource$.asObservable();

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService,
    private tipservice: TipService
  ) { }

  saveRegions(regions: object) {
    this.regionsSource$.next(regions);
  }

  // 仓库查询
  public getWarehouseList(param, page, size) {
    return this.http
      .get(`${this.apiUrl}/api/warehouse/warehouseDtos`, {
        search: _.assign(this.common.filterEffectParam(param), {
          page,
          size,
        }),
      })
      .toPromise();
  }

  // 仓库树
  public getWarehouseTree(param) {
    return this.http
      .get(`${this.apiUrl}/api/warehouse/warehouseTree`, {
        search: _.assign(this.common.filterEffectParam(param)),
      })
      .toPromise();
  }

  // 仓库导出
  public exportWarehouse(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/warehouse/exportWarehouseDtos`,
      param
    );
  }

  // 仓库启用/废弃 状态01/02
  public switchWarehouse(id, status: string = '02') {
    return this.http
      .patch(`${this.apiUrl}/api/warehouse/warehouse`, null, {
        search: {
          warehouseStatus: status,
          warehouseId: id,
        },
      })
      .pipe(
        tap((res) => {
          this.tipservice.tip('success', '操作成功！');
        })
      )
      .toPromise();
  }

  // 仓库新增
  public addWarehouse(warehouse) {
    return this.http
      .post(`${this.apiUrl}/api/warehouse/warehouse`, warehouse)
      .pipe(
        tap((res) => {
          this.isSubmitWarehouse.next(true);
        })
      );
  }

  // 仓库查看
  public getWarehouse(warehouseId) {
    return this.http
      .get(`${this.apiUrl}/api/warehouse/warehouseDto`, {
        search: { warehouseId },
      })
      .toPromise();
  }

  // 租户
  public getCustomers() {
    return this.http
      .get(`${this.apiUrl}/api/customerInfo/appCustomers`)
      .toPromise();
  }

  // 仓库查询
  public getWarehouseParam(name) {
    return this.http
      .get(`${this.apiUrl}/api/warehouse/warehouse`, {
        search: {
          warehouseName: name,
        },
      })
      .toPromise();
  }

  // 电站
  public getStations() {
    return this.http
      .get(`${this.apiUrl}/api/index/getSampleStationAll`)
      .toPromise();
  }
  // 电站
  public getProjectsByCustomerId() {
    return this.http
      .get(`${this.apiUrl}/api/warehouse/projectsByCustomerId`)
      .toPromise();
  }
  // 电站
  public projectsSysc() {
    return this.http
      .get(`${this.apiUrl}/api/warehouse/projectsSysc`)
      .toPromise();
  }

  // 父级仓库
  public getParentWarehouses() {
    return this.http.get(`${this.apiUrl}/api/warehouse/warehouses`).toPromise();
  }

  // 料号列表
  public querySafeInventories(param, page, size) {
    return this.http
      .get(`${this.apiUrl}/api/safeInventories`, {
        search: _.assign(this.common.filterEffectParam(param), {
          page,
          size,
        }),
      })
      .toPromise();
  }

  // 物料自动完成
  public getMaterialIds(name) {
    return this.http
      .get(`${this.apiUrl}/api/materialInfo/materials`, {
        search: this.common.filterEffectParam({
          name,
          materialStatus: '01',
        }),
      })
      .toPromise();
  }

  // 安全库存配置保存
  public saveSafeInventories(param) {
    return this.http.post(`${this.apiUrl}/api/safeInventories`, param).pipe(
      tap((res) => {
        this.isSafeInventories.next(true);
      })
    );
  }
  // 安全库存查询
  public querySafeInventoriesById(safeInventoryId) {
    return this.http
      .get(`${this.apiUrl}/api/safeInventories/${safeInventoryId}`)
      .toPromise();
  }

  // 安全库存配置更新
  public updateSafeInventories(param) {
    return this.http.put(`${this.apiUrl}/api/safeInventories`, param).pipe(
      tap((res) => {
        this.isSafeInventories.next(true);
      })
    );
  }
  // 安全库存配置更新
  public deleteSafeInventoriesbyId(params) {
    return this.http
      .delete(`${this.apiUrl}/api/safeInventories`, {
        search: this.common.filterEffectParam(params),
      })
      .pipe(
        tap((res) => {
          this.isSafeInventories.next(true);
        })
      );
  }

  // 下载模板
  public download(type: string) {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/safeInventories/downloadTemplate`,
      {
        search: { type },
        responseType: 'blob',
        observe: 'response',
      }
    );
  }

  public importSafeInventories(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${this.appConfig.apiUrl}/api/safeInventories/import`, formData)
      .pipe(
        tap((res) => {
          this.isSafeInventories.next(true);
        })
      );
  }

  // 导出
  public exportSafeInventories(params) {
    return this.common.fileExport(
      `${this.appConfig.apiUrl}/api/safeInventories/export`,
      params
    );
  }

  // 料号列表
  public queryMetarials(param, page, size) {
    return this.http
      .get(`${this.apiUrl}/api/materialInfo/materialListDtos`, {
        search: _.assign(this.common.filterEffectParam(param), {
          page,
          size,
        }),
      })
      .toPromise();
  }

  // 物料信息
  public getMaterial(materialId) {
    return this.http
      .get(`${this.apiUrl}/api/materialInfo/materialListDto`, {
        search: { materialId },
      })
      .toPromise();
  }

  public exportMetarialTemplate() {
    return this.common.fileExport(
      `${this.appConfig.apiUrl}/api/materialInfo/downloadTemplate`
    );
  }

  // 料号导入
  public importMetarial(file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      `${this.appConfig.apiUrl}/api/materialInfo/import`,
      formData
    );
  }

  // 料号导出
  public exportMetarial(param) {
    return this.common.fileExport(
      `${this.apiUrl}/api/materialInfo/exportMaterialListDtos`,
      param
    );
  }

  // 料号废弃 状态02
  public scrapMaterial(id, status: string = '02') {
    return this.http
      .patch(`${this.apiUrl}/api/materialInfo/material`, null, {
        search: {
          materialStatus: status,
          materialId: id,
        },
      })
      .pipe(
        tap((res) => {
          this.tipservice.tip('success', '操作成功！');
        })
      )
      .toPromise();
  }

  // 料号新增
  public addMaterial(material) {
    return this.http
      .post(`${this.apiUrl}/api/materialInfo/material`, material)
      .pipe(
        tap((res) => {
          this.isSubmitMaterial.next(true);
        })
      )
      .toPromise();
  }

  // 料号编辑
  public editMaterial(material) {
    return this.http
      .put(`${this.apiUrl}/api/materialInfo/material`, material)
      .pipe(
        tap((res) => {
          this.isSubmitMaterial.next(true);
        })
      )
      .toPromise();
  }

  // 根据名称获取公司信息
  public getCompanyByName(name) {
    return this.http
      .get(`${this.apiUrl}/api/company/companys`, {
        search: { companyName: name },
      })
      .pipe(
        map((res: any) => {
          return res.content;
        })
      )
      .toPromise();
  }

  // 获取用户信息
  public getUserInfos(userIds) {
    return this.http.get(`${this.apiUrl}/api/appUser/getUserInfos`, {
      search: { userIds },
    });
  }

  // 上传图片
  public upload(file, materialId) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${this.apiUrl}/api/materialInfo/uploadPics`, formData, {
        params: this.common.filterEffectParam({ materialId }),
      })
      .toPromise();
  }

  /**
   * name
   */
  public delete(docIds) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/doc/delete`, {
        search: { docIds },
      })
      .toPromise();
  }
}
