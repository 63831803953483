import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MaintainsService } from '../../maintains/maintains.service';
import { CommonService } from 'src/app/common/services/common.service';
import { AppConfigService } from 'src/app/app.config';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-maintain-device-items',
  templateUrl: './maintain-device-items.component.html',
  styleUrls: ['./maintain-device-items.component.less'],
})
export class MaintainDeviceItemsComponent implements OnInit {
  @Input() public isShow: boolean;
  @Input() public maintainDeviceItemsParams: {
    inspectionId: any;
    inspectionCode: any;
    inspectionStatus: any;
    itemType: any;
  };

  @Output() public showEvent = new EventEmitter<boolean>();
  itemTypeChecked: boolean;
  imgSrc: any;
  fileEndpointUrl: string;

  columns = [
    {
      field: '',
      subField: 'itemGroup',
      header: '检修预试项',
      textAlign: 'center',
      sortable: true,
    },
    {
      field: '',
      subField: 'itemName',
      header: '检修预试方法/标准',
      textAlign: 'center',
      sortable: true,
    },
    {
      field: '',
      subField: 'itemValue',
      header: '检修预试结果',
      textAlign: 'center',
      sortable: true,
    },
    {
      field: '',
      subField: 'itemMemo',
      header: '备注',
      textAlign: 'left',
      sortable: true,
    },
  ];
  dataList: any;
  rowGroupMetadata: {};
  pager: { page: number; totalRecords: number; size: number };

  constructor(
    private maintainsservice: MaintainsService,
    private common: CommonService,
    private appConfig: AppConfigService,
    private tipservice: TipService
  ) {
    this.fileEndpointUrl = this.appConfig.fileEndpointUrl;
  }

  ngOnInit() {
    this.itemTypeChecked = this.maintainDeviceItemsParams.itemType === 0 ? false : true;
    this.pager = { page: 0, totalRecords: 0, size: 50 };
    this.queryMaintainsObjItems(true);
  }

  public onHide(event) {
    this.showEvent.emit(true);
  }

  public itemTypeChangeHandler(event) {
    this.maintainDeviceItemsParams.itemType = event === true ? 1 : 0;
    this.queryMaintainsObjItems(true);
  }

  public queryMaintainsObjItems(first?) {
    if (first) {
      this.pager.page = 0;
    }
    const params = {
      inspectionId: this.maintainDeviceItemsParams.inspectionId,
      inspectionStatus: this.maintainDeviceItemsParams.inspectionStatus,
      itemType: this.maintainDeviceItemsParams.itemType,
      page: this.pager.page,
      size: this.pager.size,
    };
    this.maintainsservice.getMaintainsObjItems(params).subscribe((res: any) => {
      if (res) {
        this.rowGroupMetadata = {};
        this.dataList = res.content;
        this.pager.totalRecords = res.totalElements;

        for (let i = 0; i < this.dataList.length; i++) {
          const rowData = this.dataList[i];
          const objId = rowData.objId;
          if (i === 0) {
            this.rowGroupMetadata[objId] = { index: 0, size: 1 };
          } else {
            const previousRowData = this.dataList[i - 1];
            const previousRowGroup = previousRowData.objId;
            if (objId === previousRowGroup) {
              this.rowGroupMetadata[objId].size++;
            } else {
              this.rowGroupMetadata[objId] = { index: i, size: 1 };
            }
          }
        }
      }
    });
  }

  onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryMaintainsObjItems();
  }

  public showImage(objItemId) {
    this.maintainsservice.getMaintainsObjFiles(objItemId).subscribe((res: any) => {
      if (res && res[0]) {
        this.imgSrc = res.map((item) => {
          return {
            source: this.fileEndpointUrl + item.uri,
            thumbnail: this.fileEndpointUrl + item.uri,
            title: item.docName,
          };
        });
        setTimeout(() => {
          const ele = document.querySelectorAll('p-lightbox>div>a')[0] as any;
          ele.click();
        }, 100);
      } else {
        this.tipservice.tip('info', '暂无图片');
      }
    });
  }
}
