import { Component, OnInit, Input } from '@angular/core';
import { MenuItem, SelectItem } from 'primeng/primeng';

import { SysCategories } from '../point-config.model';

@Component({
  selector: 'station-config-case',
  templateUrl: 'station-config-case.component.html',
  styleUrls: ['station-config-case.component.less'],
})

export class StationConfigCaseComponent implements OnInit {
  @Input() public caseInfo: SysCategories[] = [];
  constructor(
  ) {
  }

  ngOnInit() { }
}
