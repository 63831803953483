import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WidgetsService } from '../../widgets.service';

@Component({
  selector: 'app-station-info',
  templateUrl: './station-info.component.html',
  styleUrls: ['./station-info.component.less']
})
export class StationInfoComponent implements OnInit, OnChanges {

  @Input() stationId: string;

  public data: any;

  constructor(private widgetsservice: WidgetsService) { }


  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.getStationInfo();
    }
  }

  async getStationInfo() {
    const data = await this.widgetsservice.getStationInfo({ stationId: this.stationId });
    this.data = data;
  }
}
