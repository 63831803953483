import { WidgetsService } from './../widgets.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-communication-status',
  templateUrl: './communication-status.component.html',
  styleUrls: ['./communication-status.component.less']
})
export class CommunicationStatusComponent implements OnInit {

  option = {};

  loading = true;

  type = 'device'
  deviceOption: any = [];
  communicationOption: any = [];


  constructor(private widgetsservice: WidgetsService, private router: Router) { }

  ngOnInit() {
    this.loading = false;
    this.getStatus({});
  }


  selectType(type) {
    console.log(type);
    this.type = type;
    if (type === 'device') {
      this.option = this.deviceOption;
    } else {
      this.option = this.communicationOption
    }
  }

  public getStatus(params) {
    this.loading = true;
    this.widgetsservice.commStatus(params).then((res: any) => {
      this.loading = false;
      let data = res;
      this.deviceOption = {
        graphic: {
          name: data.cardTitle[3],
          elements: [
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/icon-guangfu2.png',
                width: 58,
                height: 65
              },
              left: 'center',
              top: 'middle',
              z: 100
            },
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/bg-shebei.png',
                width: 160,
                height: 160
              },
              left: 'center',
              top: 'middle',
              z: 0
            }
          ]
        },
        series: [
          {
            type: 'pie',
            name: data.cardTitle[3],
            radius: ['52%', '65%'],
            center: ['50%', '50%'],
            color: ['#0C559F', '#2ABDB6', '#AEDAFF', '#D09E49', '#54AAFF', '#0A7EFF'],
            data: data.options[3].series[0].data,
            label: {
              formatter: '{label|{b}}',
              show: true,
              distanceToLabelLine: 10,
              textStyle: { wordBreak: 'break-all' },
              rich: {
                label: { fontSize: 14, color: '#B8C6CF' },
                value: { fontSize: 14, color: '#37E7FF' }
              }
            },
            labelLine: {
              normal: {
                show: true,
                length: 15,
                length2: 15,
                lineStyle: { color: '#ffffff', opacity: 0.45, width: 1 }
              }
            }
          }
        ]
      }
      this.communicationOption = {
        graphic: {
          name: data.cardTitle[1],
          elements: [
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/icon-tongxun2.png',
                width: 58,
                height: 65
              },
              left: 'center',
              top: 'middle',
              z: 100
            },
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/bg-shebei.png',
                width: 160,
                height: 160
              },
              left: 'center',
              top: 'middle',
              z: 0
            }
          ]
        },
        series: [
          {
            type: 'pie',
            name: data.cardTitle[1],
            radius: ['52%', '65%'],
            center: ['50%', '50%'],
            color: ['#0C559F', '#2ABDB6', '#AEDAFF', '#D09E49', '#54AAFF', '#0A7EFF'],
            data: data.options[1].series[0].data,
            label: {
              formatter: '{label|{b}}',
              show: true,
              distanceToLabelLine: 10,
              textStyle: { wordBreak: 'break-all' },
              rich: {
                label: { fontSize: 14, color: '#B8C6CF' },
                value: { fontSize: 14, color: '#37E7FF' }
              }
            },
            labelLine: {
              normal: {
                show: true,
                length: 15,
                length2: 15,
                lineStyle: { color: '#ffffff', opacity: 0.45, width: 1 }
              }
            }
          }
        ]
      }
      this.option = this.deviceOption;
    });
  }

  public clickEvent(event) {
    if (this.type === 'device') {
      this.goDeviceStatus({
        dataType: '03',
        deviceType: '01',
        sortBy: 'dNB_rate',
        sortOrder: 'asc'
      })
    } else {
      this.goCommunication()
    }
  }

  public goCommunication() {
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/yxjs'],
      })
    );
    this.router.navigate(['/monitorCenter/communication']);
  }


  /**
 * goDeviceStatus
 */
  public goDeviceStatus(queryParams) {
    localStorage.setItem(
      'backItem',
      JSON.stringify({
        showIcon: true,
        link: ['/yxjs'],
      })
    );
    this.router.navigate(['/monitorCenter/deviceStatus'], {
      queryParams,
    });
  }

}
