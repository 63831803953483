import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { AuthHttp } from "../../../../core/auth-http";
import { AppConfigService } from "../../../../app.config";

import { Organization } from "./device-tree.model";

@Injectable()
export class DeviceTreeService {
    public constructor(
        private http: AuthHttp,
        private config: AppConfigService
    ) {}

    // 获取区域设备树数据
    public getTreeData(): Observable<Organization> {
        return this.http.get(`${this.config.apiUrl}/api/index/getOrganization`);
    }

    public getDevicesByAreaId(areaId: string): Observable<Organization[]> {
        return this.http.get(
            `${this.config.apiUrl}/api/index/getOrgDeviceByAreaIds`,
            { search: { areaId } }
        );
    }
}
