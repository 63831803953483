export interface Menu {
  menuId: number;
  menuLabel: string;
  menuGroup: string;
  menuIcon1: string;
  menuIcon2?: string;
  menuIcon3?: string;
  menuOrder: number;
  menuStatus: string;
  menuTree: string;
  menuType: string;
  parentId: number;
  url: string;
  children: Menu[];
}

export enum MenuType {
  favorite = '0',
  group = '1',
  item = '2'
}
