import { CommonService } from './../../services/common.service';
import { FileService } from './../../services/file.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { AppConfigService } from 'src/app/app.config';
import * as _ from 'lodash';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-upload-pics',
  templateUrl: './upload-pics.component.html',
  styleUrls: ['./upload-pics.component.less'],
})
export class UploadPicsComponent implements OnInit {
  @Input() orderId = null;
  @Input() picPermission = 2;
  @Input() files = [];
  @Input() uploadUrl: string;

  @Output() filesChangeEvent = new EventEmitter();
  fileEndpointUrl: string;
  imgSrc: string;
  showView: boolean;

  constructor(
    private fileservice: FileService,
    private appConfig: AppConfigService,
    private commonservice: CommonService,
    private tipservice: TipService
  ) {
    this.fileEndpointUrl = this.appConfig.fileEndpointUrl;
  }

  ngOnInit() { }

  onSelectFile(event) {
    const extName = 'GIF,BMP,JPG,JPEG,PNG';
    for (const file of event.files) {
      const i = file.name.lastIndexOf('.');
      const len = file.name.length;
      const extEndName = file.name.substring(i + 1, len);
      if (extName.indexOf(extEndName.toUpperCase()) === -1) {
        return this.tipservice.tip('warn', '上传的文件格式不正确');
      }
      this.fileservice
        .uploadDocs(this.uploadUrl ? this.uploadUrl : '/api/workOrders/uploadPics', file, {
          orderId: this.orderId,
        })
        .then((res: any) => {
          if (res) {
            this.tipservice.tip('success', '上传成功');
            this.files.push(res);
            this.filesChangeEvent.emit(this.files);
          }
        });
    }
  }

  deleteItem(event, docId) {
    this.fileservice.deleteFile(docId).then((res) => {
      this.tipservice.tip('success', '删除成功');
      _.remove(this.files, (o) => {
        return o.docId === docId;
      });
      this.filesChangeEvent.emit(this.files);
    });
  }

  viewPics(event, src) {
    this.imgSrc = this.fileEndpointUrl + src;
    this.showView = true;
  }

  closeViewEvent(event) {
    this.imgSrc = null;
    this.showView = false;
  }
}
