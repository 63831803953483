import { Injectable, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';

import { Constants } from '../Constants';
import { AuthHttp } from '../../core/auth-http';
import { AppConfigService } from '../../app.config';
import { CommonService } from './common.service';
import { TopoTabService } from './topoTab.service';
import { TipService } from './tip.service';

declare const ht;
declare const window;

window.topoJSON = {};
window.topoImage = {};

if (!window.htService) {
  window.htService = function (handler) {
    this.handler = handler;
    this.handler({
      type: 'connected',
      message: 'Mock service connected',
    });
  };
}

@Injectable()
export class TopoService implements OnInit, OnDestroy {
  private mockStorage = {};
  private stationId;
  private topoId;

  private topoItemsPromise;

  public constructor(
    private http: AuthHttp,
    private config: AppConfigService,
    private route: ActivatedRoute,
    private router: Router,
    private commonService: CommonService,
    private topoTabService: TopoTabService,
    private tipservice: TipService
  ) { }

  ngOnInit(): void { }

  ngOnDestroy(): void {
    this.topoItemsPromise = null;
  }

  public initEditorService(topoId?: number) {
    if (topoId) {
      this.topoId = topoId;
    } else {
      //如果没有传值，需将this.topoId设为undefined,否则会用上一次传入的topoId
      this.topoId = undefined;
    }
    try {
      this.initHtService();
    } catch (e) {
      console.error(e);
    }
  }

  private initHtService() {
    let topoItems = [];
    ht.Default.def('htService', Object, {
      mockStorage: this.mockStorage,
      createTopo: this.createTopo,
      http: this.http,
      config: this.config,
      route: this.route,
      saveTopo: this.saveTopo,
      commonService: this.commonService,
      saveTopoDescById: this.saveTopoDescById,
      topoId: this.topoId,
      topoTabService: this.topoTabService,
      getTopoById: this.getTopoById,
      getNameFromPath: this.getNameFromPath,
      getFullNameFromPath: this.getFullNameFromPath,
      router: this.router,
      saveEditorTopoItem: this.saveEditorTopoItem,
      saveTopoItem: this.saveTopoItem,
      getTopoItems: this.getTopoItems,
      topoItemsPromise: this.topoItemsPromise,
      saveTopoItems: this.saveTopoItems,
      changeDisplayName: this.changeDisplayName,
      getChidrenItemIdsByFolder: this.getChidrenItemIdsByFolder,
      changeFolderPath: this.changeFolderPath,
      deleteTopologyItemByItemIds: this.deleteTopologyItemByItemIds,
      isEmpty: _.isEmpty,
      topoCache: {},
      mkdirAction: false,
      removeExtention: function (path) {
        return path.replace(/\.[^\.]+$/g, '');
      },
      getTypeFromPath: function (path) {
        return path.split('/')[0];
      },
      request: function (cmd, data, callback) {
        this[cmd](data, callback);
        let message = cmd;
        if (data) {
          if (typeof data === 'string') {
            message = cmd + ': ' + data;
          } else if (data.path) {
            message = cmd + ': ' + data.path;
          }
        }
        this.handler({
          type: 'request',
          message: message,
          cmd: cmd,
          data: data,
        });
      },
      explore: function (data, callback) {
        const path = data.substr(1);
        if (this.mkdirAction) {
          this.mkdirAction = false;
          callback(this.mockStorage[path]);
        } else {
          if (path === 'displays') {
            if (this.topoId) {
              this.getTopoById(this.topoId).subscribe((topo) => {
                window.topoJSON[path + '/' + topo.topoName + '.json'] =
                  topo.topoDesc;
                window.topoImage[path + '/' + topo.topoName + '.png'] =
                  topo.thumbnail;
                window.topoType = topo.topoType;
                window.areaId = topo.areaId;
                this.mockStorage[path] = {
                  [topo.topoName + '.json']: true,
                  [topo.topoName + '.png']: true,
                };
                callback(this.mockStorage[path]);
              });
            }
          } else {
            if (!this.topoItemsPromise) {
              this.topoItemsPromise = this.getTopoItems().toPromise();
            }
            this.topoItemsPromise.then((topos) => {
              topoItems = topos;
              const items = {};
              topos
                .filter((item) => {
                  switch (path) {
                    case 'symbols':
                      return item.itemType === '03';
                    case 'components':
                      return item.itemType === '04';
                    case 'assets':
                      return item.itemType === '05';
                  }
                })
                .forEach((item) => {
                  if (!item.path) {
                    return;
                  }
                  if (
                    item.path.indexOf('.dxf') > -1 ||
                    item.path.indexOf('.svg') > -1
                  ) {
                    window.topoJSON[item.path] = item.itemDesc;
                    items[item.path.split('assets/')[1]] = true;
                  } else {
                    this.setPathTopo(items, path, item);
                    const name = this.getFullNameFromPath(item.path);
                    window.topoJSON[name + '.json'] = item.itemDesc;
                    window.topoImage[name + '.png'] = item.thumbnail;
                  }
                });
              this.mockStorage[path] = items;
              callback(this.mockStorage[path]);
            });
          }
        }
      },
      mkdir: function (data, callback) {
        this.mkdirAction = true;
        this.setPathContent(data, {}, callback);
      },
      upload: function (data, callback) {
        const type = this.getTypeFromPath(data.path);
        const path = this.removeExtention(data.path);
        if (!this.topoCache[path]) {
          this.topoCache[path] = {};
        }

        if (/\.(png|jpg|gif|jpeg|bmp)$/i.test(data.path)) {
          this.topoCache[path].thumbnail = data.content;
          if (type === 'assets') {
            this.topoCache[path].path = data.path;
            this.topoCache[path].json = '{}';
            this.topoCache[path].name = this.getNameFromPath(data.path);
          }
        } else {
          this.route.queryParams.subscribe((params) => {
            if (params['stationId']) {
              let json;
              let name;
              try {
                json = JSON.parse(data.content);
                if (json) {
                  name = json.title;
                }
              } catch (e) { }
              if (!name) {
                name = this.getNameFromPath(data.path);
              }
              this.topoCache[path].name = name;
              this.topoCache[path].json = data.content;
              this.topoCache[path].path = data.path;
              this.topoCache[path].stationId = params['stationId'];
            }
          });
        }
        if (
          type !== 'assets' &&
          (!this.topoCache[path].json || !this.topoCache[path].thumbnail)
        ) {
          this.setPathContent(data.path, true, callback);
          return;
        }
        if (type === 'displays') {
          if (
            !!this.topoCache[path].name &&
            !!this.topoCache[path].json &&
            !!this.topoCache[path].thumbnail
          ) {
            this.saveTopo(
              this.topoCache[path].stationId,
              this.topoId,
              window.topoType,
              window.areaId,
              this.topoTabService.currentTab,
              this.topoCache[path].name,
              this.topoCache[path].json,
              this.topoCache[path].thumbnail,
              this.topoCache[path].path
            ).then(() => {
              this.topoCache[path] = {};
              this.setPathContent(data.path, true, callback);
            });
          }
        } else {
          const topoItem = this.getTopoItemByPath(this.topoCache[path].path);
          this.saveEditorTopoItem(
            topoItem ? topoItem.itemId : null,
            this.topoCache[path].name,
            type,
            this.topoCache[path].json,
            this.topoCache[path].thumbnail,
            this.topoCache[path].path
          ).then(() => {
            this.topoCache[path] = {};
            this.topoItemsPromise = this.getTopoItems().toPromise();
            this.setPathContent(data.path, true, callback);
          });
        }
      },
      rename: function (data, callback) {
        if (data.old.indexOf('.') !== -1) {
          let patt = new RegExp('^displays/');
          if (patt.test(data.old)) {
            this.getTopoById(this.topoId).subscribe((topo) => {
              this.changeDisplayName(
                topo.stationId,
                topo.topoId,
                topo.topoType,
                topo.areaId,
                this.getNameFromPath(data.new),
                topo.topoDesc,
                topo.thumbnail,
                data.new
              ).then(() => {
                callback(true);
                this.handler({ type: 'fileChanged', path: data.old });
                this.handler({ type: 'fileChanged', path: data.new });
              });
            });
          } else {
            const topoItem = this.getTopoItemByPath(data.old);
            if (topoItem !== undefined) {
              let ss = data.old.split('/');
              let value = this.mockStorage;
              for (let i = 0; i < ss.length - 1; i++) {
                value = value[ss[i]];
              }
              let oldContent = value[ss[ss.length - 1]];
              delete value[ss[ss.length - 1]];
              value[data.new.split('/').pop()] = oldContent;
              const name = this.getFullNameFromPath(data.new).split('/')[
                this.getFullNameFromPath(data.new).split('/').length - 1
              ];
              this.saveTopoItem(
                topoItem.itemId,
                name,
                topoItem.itemType,
                topoItem.itemDesc,
                topoItem.thumbnail,
                data.new
              )
                .toPromise()
                .then(
                  (res) => {
                    callback(true);
                    this.topoItemsPromise = this.getTopoItems().toPromise();
                    this.handler({ type: 'fileChanged', path: data.old });
                    this.handler({ type: 'fileChanged', path: data.new });
                  },
                  (err) => {
                    callback(false);
                  }
                );
              this.topoItemsPromise = this.getTopoItems().toPromise();
            }
          }
        } else {
          let items = this.changeFolderPath(topoItems, data.old, data.new);
          this.saveTopoItems(items)
            .toPromise()
            .then((res) => {
              callback(true);
              this.topoItemsPromise = this.getTopoItems().toPromise();
              this.handler({ type: 'fileChanged', path: data.old });
              this.handler({ type: 'fileChanged', path: data.new });
            });
        }
      },
      remove: function (data, callback) {
        if (data.indexOf('.') !== -1) {
          const topoItem = this.getTopoItemByPath(data);
          if (topoItem !== undefined) {
            this.deleteTopologyItemByItemIds(topoItem.itemId).then((res) => {
              callback(true);
              this.topoItemsPromise = this.getTopoItems().toPromise();
              this.setPathContent(data, undefined, callback);
            });
          }
        } else {
          let itemIds = this.getChidrenItemIdsByFolder(topoItems, data);
          this.deleteTopologyItemByItemIds(itemIds).then((res) => {
            callback(true);
            this.topoItemsPromise = this.getTopoItems().toPromise();
            this.setPathContent(data, undefined, callback);
          });
        }
      },
      locate: function (data, callback) {
        // Not supported
      },
      source: function (path, callback) {
        if (/\.(png|jpg|gif|jpeg|bmp)$/i.test(path.url)) {
          callback(window.topoImage[path.url]);
        } else {
          callback(window.topoImage[path.url]);
        }
      },
      setPathContent: function (path, content, callback) {
        let ss = path.split('/');
        let value = this.mockStorage;
        for (let i = 0; i < ss.length - 1; i++) {
          if (value[ss[i]] == null) {
            if (content === undefined) {
              break;
            }
            value[ss[i]] = {};
          }
          value = value[ss[i]];
        }
        if (value) {
          value[ss[ss.length - 1]] = content;
        }
        callback(true);
        this.handler({ type: 'fileChanged', path: path });
      },
      setPathTopo: function (topo, folder, topoItem) {
        if (!topo || !folder || !topoItem || !topoItem.path) {
          return;
        }
        const paths = topoItem.path.split('/');
        for (let i = 0; i < paths.length; i++) {
          if (i === paths.length - 1) {
            topo[topoItem.itemName + '.json'] = true;
            topo[topoItem.itemName + '.png'] = true;
          } else if (i !== 0) {
            if (!topo[paths[i]]) {
              topo[paths[i]] = {};
            }
            topo = topo[paths[i]];
          }
        }
      },
      getTopoItemByPath: function (path) {
        let index = _.findLastIndex(topoItems, function (item) {
          return item.path === path;
        });
        return topoItems[index];
      },
    });
  }

  public getNameFromPath(path: string): string {
    const match = /([^\/]+)\.[^\.]+$/.exec(path);
    return match ? match[1] : path;
  }

  public getFullNameFromPath(path: string): string {
    const match = /(.+)\.\w+$/.exec(path);
    return match ? match[1] : path;
  }

  public getChidrenItemIdsByFolder(topoItems, folderName) {
    const childrenItems = _.filter(topoItems, function (o) {
      if (o.path) {
        return o.path.indexOf(folderName) !== -1;
      }
    });
    let childrenItemIds = [];
    childrenItems.forEach((item) => {
      childrenItemIds.push(item.itemId);
    });
    return childrenItemIds;
  }

  public changeDisplayName(
    stationId,
    current_topoId,
    topoType,
    areaId,
    name,
    topoDesc,
    thumbnail,
    path
  ) {
    return this.saveTopoDescById(
      stationId,
      current_topoId,
      topoType,
      areaId,
      name,
      topoDesc,
      thumbnail,
      path
    ).toPromise();
  }

  public changeFolderPath(topoItems, oldFolderName, newFolderName) {
    const items = _.filter(topoItems, function (o) {
      if (o.path) {
        return o.path.indexOf(oldFolderName) !== -1;
      }
    });
    let childrenItems = [];
    items.forEach((item) => {
      item.path = item.path.replace(oldFolderName, newFolderName);
      childrenItems.push(item);
    });
    return childrenItems;
  }

  public getTopoById(topoId: number): Observable<any> {
    return this.http.get(
      `${this.config.apiUrl}/api/topographic/getTopologyGraphicByTopoId`,
      { search: { topoId } }
    );
  }

  public saveTopoDescById(
    stationId: string,
    topoId: number,
    topoType: string,
    areaId: string,
    topoName: string,
    topoDesc: string,
    thumbnail: string,
    path: string
  ) {
    if (!topoId || !topoName) {
      return;
    }
    return this.http.put(
      `${this.config.apiUrl}/api/topographic/saveTopologyGraphic`,
      {
        stationId,
        topoId,
        topoType,
        areaId,
        topoDesc,
        topoName,
        thumbnail,
        path,
      }
    );
  }

  public createTopo(
    stationId: string,
    topoName: string,
    topoType: string,
    topoDesc: string,
    thumbnail: string,
    path: string
  ) {
    return this.http.post(
      `${this.config.apiUrl}/api/topographic/createTopologyGraphic`,
      { stationId, topoName, topoType, topoDesc, thumbnail, path }
    );
  }

  public createTopoItem(
    itemName: string,
    type: string,
    itemDesc: string,
    thumbnail: string,
    path: string
  ) {
    return this.http.post(
      `${this.config.apiUrl}/api/topographic/saveTopologyItem`,
      { itemName, itemDesc, type, thumbnail, path }
    );
  }

  public saveTopoItem(
    itemId,
    itemName: string,
    itemType: string,
    itemDesc: string,
    thumbnail: string,
    path: string
  ) {
    return this.http.post(
      `${this.config.apiUrl}/api/topographic/saveTopologyItem`,
      { itemId, itemName, itemDesc, itemType, thumbnail, path }
    );
  }

  public saveTopoItems(items) {
    return this.http.post(
      `${this.config.apiUrl}/api/topographic/saveTopologyItems`,
      items
    );
  }

  public saveTopo(
    stationId,
    topoId,
    topoType = '01',
    areaId,
    currentTab,
    name,
    topoDesc,
    thumbnail,
    path
  ) {
    if (!name) {
      this.tipservice.tip('warn', '拓扑图名称不能为空。');
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    this.commonService.mask.show();
    let index = _.findIndex(this.topoTabService.tabs, function (o: any) {
      return o._tabId == currentTab;
    });
    let current_topoId;
    if (index == -1) {
      current_topoId = undefined;
      index = this.topoTabService.tabs.length;
    } else {
      current_topoId = this.topoTabService.tabs[index]['_topoId'];
    }

    if (current_topoId) {
      return this.saveTopoDescById(
        stationId,
        current_topoId,
        topoType,
        areaId,
        name,
        topoDesc,
        thumbnail,
        path
      )
        .toPromise()
        .then(
          (res) => {
            this.commonService.mask.hide();
            this.tipservice.tip('success', '保存成功。');
            this.commonService.mask.hide();
          },
          () => {
            this.tipservice.tip('error', '保存失败。');
            this.commonService.mask.hide();
          }
        );
    }
    return this.createTopo(stationId, name, topoType, topoDesc, thumbnail, path)
      .toPromise()
      .then(
        (res: any) => {
          this.commonService.mask.hide();
          this.tipservice.tip('success', '生成拓扑图成功。');
          this.topoId = res.topoId;
          this.topoTabService.tabs[index] = {
            _topoId: res.topoId,
            _tabId: currentTab,
          };
          this.commonService.mask.hide();
        },
        () => {
          this.tipservice.tip('error', '生成拓扑图失败。');
          this.commonService.mask.hide();
        }
      );
  }

  public saveEditorTopoItem(itemId, name, type, desc, thumbnail, path) {
    if (!name) {
      this.tipservice.tip('warn', '拓扑图名称不能为空。');
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    if (!desc) {
      this.tipservice.tip('warn', '拓扑图不能为空。');
      return new Promise((resolve) => {
        resolve(true);
      });
    }
    this.commonService.mask.show();
    return this.saveTopoItem(itemId, name, type, desc, thumbnail, path)
      .toPromise()
      .then(
        (res) => {
          this.commonService.mask.hide();
          this.tipservice.tip('success', '保存成功。');
          this.commonService.mask.hide();
        },
        () => {
          this.tipservice.tip('error', '保存失败。');
          this.commonService.mask.hide();
        }
      );
  }

  public deleteTopologyItemByItemIds(items) {
    return this.http
      .get(
        `${this.config.apiUrl}/api/topographic/deleteTopologyItemByItemIds`,
        { search: { itemIds: items } }
      )
      .toPromise();
  }

  public getTopoItems() {
    return this.http.get(`${this.config.apiUrl}/api/topographic/topo-items`);
  }

  public isTopoItemManager() {
    return this.http.get(
      `${this.config.apiUrl}/api/topographic/isTopoItemManager`
    );
  }
}
