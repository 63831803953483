import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { CommonService } from '../../../common/services/common.service';
import { PowerStation } from '../../monitor/shared/index.model';
import { ConfigType } from './header.model';
import { PageRequest, Page } from '../../../shared/models/pagination';
import { Message, MessageInfo } from './message/message.model';
import { tap } from 'rxjs/operators';

@Injectable()
export class HeaderService {
    public configSubject = new Subject<any>();
    public display = new Subject<MessageInfo>();
    private apiUrl: string = this.appConfig.apiUrl;
    private stations: PowerStation[];
    private stationPromise: Promise<PowerStation[]>;

    public constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private commonService: CommonService
    ) {}

    /**
     *
     * 获取
     *
     * @returns
     * @memberof HeaderService
     */
    public getAlarmStatistics(): Observable<{ count: number }> {
        return this.http.post(
            `${this.appConfig.apiUrl}/api/alarmLog/statistics`,
            {}
        );
    }

    // 获取实时告警页面数据数据(新)
    public webAlarmNotice() {
        return this.http.get(`${this.apiUrl}/api/alarmLog/webAlarmNotice`);
    }

    public subscibeAlarm(): Observable<any> {
        return this.commonService.subscribeData([], 'alarmNotice');
    }

    public getStations(): Promise<PowerStation[]> {
        if (!this.stationPromise) {
            this.stationPromise = this.http
                .get<PowerStation[]>(
                    `${this.apiUrl}/api/index/getSampleStationAll`
                )
                .toPromise();
        }
        return this.stationPromise;
    }

    public getAppMessage(pageRequest: PageRequest): Observable<Page<Message>> {
        let params: any = {};
        params.size = pageRequest.size.toString();
        params.page = pageRequest.page.toString();
        return this.http
            .get<Page<Message>>(`${this.appConfig.apiUrl}/api/app-message`, {
                search: params
            })
            .pipe(
                tap((res) => {
                    const data = res;
                    return {
                        content: <Message[]>data['content'],
                        totalPages: data['totalPages'],
                        totalElements: data['totalElements'],
                        size: data['size'],
                        number: data['number']
                    };
                })
            );
    }

    public updateAppMessage(msgId) {
        return this.http.put(
            `${this.appConfig.apiUrl}/api/app-message/${msgId}`,
            msgId
        );
    }
}
