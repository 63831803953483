import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng/primeng';
import { DeptsService } from '../depts/shared/depts.service';

@Component({
    selector: 'mpt-depts-picker',
    templateUrl: 'depts-picker.component.html',
    styleUrls: ['./depts-picker.component.less'],
})
export class DeptsPickerComponent implements OnInit {
    @Input() public visible: boolean = false;
    @Input() public noSeleted = false;
    @Input() public hideCallback: Function;

    @Output() public onDeptsSelected = new EventEmitter();

    public selectedDept: TreeNode;
    public depts: TreeNode[] = [];

    constructor(private deptsService: DeptsService) {}

    public ngOnInit() {
        this.deptsService.getDeptsTree().subscribe((rootDept) => {
            // liqian 部门树展开到指定层次
            this.checkNodeLeaf(rootDept);
            this.expandTreeToLevel(rootDept, 4);
            this.depts = [rootDept];
        });
    }

    public show() {
        this.visible = true;
    }

    public hide() {
        this.visible = false;
    }

    public onHide() {
        if (this.hideCallback && typeof this.hideCallback === 'function') {
            this.hideCallback();
        }
    }

    public onOK() {
        if (this.noSeleted) {
            this.onDeptsSelected.emit(this.selectedDept ? this.selectedDept.data : null);
        } else {
            this.onDeptsSelected.emit(this.selectedDept.data);
        }
        this.hide();
    }

    private expandTree(node: TreeNode, isExpand: boolean) {
        if (node.children && node.children.length > 0) {
            node.expanded = isExpand;
            node.children.forEach((childNode) => {
                this.expandTree(childNode, isExpand);
            });
        } else {
            node.leaf = true;
        }
    }

    private expandTreeToLevel(node: TreeNode, level: number) {
        if (node.children && node.children.length && level > 0) {
            node.expanded = true;
            node.children.forEach((childNode) => {
                this.expandTreeToLevel(childNode, --level);
            });
        }
    }

    private checkNodeLeaf(node: TreeNode) {
        if (node.children && node.children.length) {
            node.children.forEach((childNode) => {
                this.checkNodeLeaf(childNode);
            });
        } else {
            node.leaf = true;
        }
    }
}
