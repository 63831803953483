
import { CalendarModule, DialogModule, DropdownModule } from 'primeng/primeng';
import { MultiSelectModule } from 'primeng/multiselect';
import { ButtonModule, PaginatorModule } from 'primeng/primeng';
import { InputTextModule } from 'primeng/primeng';
import { StationAreaListService } from './station-area-list.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { StationAreaListComponent } from './station-area-list.component';
import { TableModule } from 'primeng/table';
import { StationMonitorModule } from '@components/station-monitor/station-monitor.module';

const primengModule = [InputTextModule, PaginatorModule, TableModule, ButtonModule, MultiSelectModule, DropdownModule, CalendarModule, DialogModule]

@NgModule({
  declarations: [StationAreaListComponent],
  imports: [
    SharedModule,
    StationMonitorModule,
    ...primengModule,
  ],
  providers: [StationAreaListService],
  exports: [StationAreaListComponent]
})
export class StationAreaListModule { }
