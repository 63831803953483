import { TipService } from './../../../common/services/tip.service';
import { CacheService } from './../../../common/services/cache.service';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from './../../../common/services/common.service';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { buildDeviceStatusChart } from './chart/device-status.chart';
import { Constants } from 'src/app/common/Constants';
import { forkJoin } from 'rxjs';
import { StationMonitorService } from '../station-monitor.service';
import { FormControl, FormBuilder } from '@angular/forms';
import * as _ from 'lodash';
import { LazyLoadEvent } from 'primeng/api';

@Component({
  selector: 'app-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.less'],
})
export class DeviceStatusComponent implements OnInit, OnDestroy {
  public toggle: boolean = true;

  public pager = { page: 0, size: 50, totalRecords: 1 };
  public options: {} = null;
  public runStatuses = [];
  public realAlarmStatuses = [];
  public commuStatuses = [];
  public tableTitle = '';
  public columns = [
    {
      header: '设备名称',
      field: 'deviceName',
      row: 1,
      rowspan: 2,
      sortable: true,
      weightNormal: true,
    },
    {
      header: '设备分类',
      field: 'className',
      row: 1,
      rowspan: 2,
      sortable: true,
      weightNormal: true,
    },
    {
      header: '型号',
      field: 'modelNo',
      row: 1,
      rowspan: 2,
      sortable: true,
      weightNormal: true,
    },
    {
      header: '位置',
      field: 'areaName',
      row: 1,
      rowspan: 2,
      sortable: true,
      weightNormal: true,
    },
    {
      header: '通讯状态',
      field: 'TXZT',
      colspan: 2,
      row: 1,
      sortable: false,
      weightNormal: true,
    },
    {
      header: '运行状态',
      field: 'YXZT',
      colspan: 3,
      row: 1,
      sortable: false,
      weightNormal: true,
    },
    {
      header: '告警状态',
      field: 'GJZT',
      colspan: 3,
      row: 1,
      sortable: false,
      weightNormal: true,
    },
    {
      header: '通讯状态',
      field: 'commuStatusText',
      row: 2,
      sortable: true,
    },
    {
      header: '最近通讯时间',
      field: 'lastDataTime',
      row: 2,
      sortable: true,
    },
    { header: '运行状态', field: 'runStatusText', row: 2, sortable: true },
    { header: '启动时间', field: 'startTime', row: 2, sortable: true },
    { header: '停机时间', field: 'endTime', row: 2, sortable: true },
    { header: '今日告警', field: 'dayAlarms', row: 2, sortable: true },
    { header: '实时告警', field: 'realAlarms', row: 2, sortable: true },
    {
      header: '最近告警时间',
      field: 'lastAlarmTime',
      row: 2,
      sortable: true,
    },
  ];

  public dataList = [];
  backItem: any;
  scrollHeight = '0px';
  stationId: any;
  areaList: any;
  classList: any;
  modelList: any;
  form: any;
  sortBy: string;
  sortOrder: string;
  tableloading: boolean;
  optionLoading: boolean;
  classIds: any;
  runStatus: any;
  alarmStatus: any;
  commStatus: any;

  constructor(
    private stationmonitorservice: StationMonitorService,
    private common: CommonService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private formBuilder: FormBuilder,
    private tipservice: TipService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
      } else {
        this.stationId = params.stationId;
      }
      if (params.classIds) {
        this.classIds = params.classIds;
      }
      if (params.runStatus) {
        this.runStatus = params.runStatus;
      }
      if (params.alarmStatus) {
        this.alarmStatus = params.alarmStatus;
      }
      if (params.commStatus) {
        this.commStatus = params.commStatus;
      }
      this.initform();
      this.query(true);
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    this.common.removeBackItem();
  }

  //初始化form
  public initform() {
    let areaParams = {
      stationId: this.stationId,
    };
    let classParams = {
      stationId: this.stationId,
      deviceType: this.classIds ? this.classIds : [],
    };
    let modelParams = {
      groupType: ['NB', 'JNB'],
      stationIds: [this.stationId],
    };
    forkJoin(
      this.stationmonitorservice.getArea(areaParams),
      this.classIds
        ? this.stationmonitorservice.getClassIds(classParams)
        : this.stationmonitorservice.getClass(classParams),
      this.stationmonitorservice.getModel(modelParams)
    ).subscribe((res) => {
      this.areaList = res[0];
      this.classList = res[1];
      this.modelList = res[2];
    });

    this.runStatuses = [
      {
        label: '请选择',
        value: null,
      },
      {
        label: '停机',
        value: 0,
      },
      {
        label: '运行',
        value: 1,
      },
    ];
    this.realAlarmStatuses = [
      {
        label: '请选择',
        value: null,
      },
      {
        label: '正常',
        value: 0,
      },
      {
        label: '告警',
        value: 1,
      },
    ];
    this.commuStatuses = [
      {
        label: '请选择',
        value: null,
      },
      {
        label: '在线',
        value: 0,
      },
      {
        label: '离线',
        value: 1,
      },
    ];

    this.form = this.formBuilder.group({
      areaIds: new FormControl(''),
      classIds: new FormControl(''),
      modelIds: new FormControl(''),
      deviceName: new FormControl(''),
      runStatus: new FormControl(''),
      realAlarmStatus: new FormControl(''),
      commuStatus: new FormControl(''),
    });

    this.form.patchValue({
      runStatus: this.runStatus ? +this.runStatus : null,
      realAlarmStatus: this.alarmStatus ? +this.alarmStatus : null,
      commuStatus: this.commStatus ? +this.commStatus : null,
    });

    this.backItem = this.common.getBackItem();
    this.toggle = true;
    this.setScrollHeight();
  }

  public query(first) {
    if (first) {
      this.queryTable(true);
      this.buildChart('runStatusText');
    }
  }

  public queryTable(first?) {
    if (first) {
      this.pager.page = 0;
      this.sortBy = 'runStatusText';
      this.sortOrder = 'asc';
    }
    let params = _.assign({}, this.form.value, {
      page: this.pager.page,
      size: this.pager.size,
      stationId: this.stationId,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
    this.tableloading = true;
    this.stationmonitorservice
      .getDeviceStatusDatas(params)
      .subscribe((res: any) => {
        this.tableloading = false;
        if (res) {
          this.dataList = res.content;

          if (
            this.dataList.length === 0 &&
            params.deviceName &&
            !params.areaIds &&
            !params.classIds &&
            !params.modelIds &&
            !params.realAlarmStatus &&
            !params.commuStatus &&
            !params.runStatus
          ) {
            this.tipservice.tip(
              'warn',
              `当前电站没有【${params.deviceName}】设备`,
              '查询设备提示'
            );
          }
          this.pager.totalRecords = res.totalElements;
        }
      });
  }

  public buildChart(column?) {
    this.optionLoading = true;
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
      column,
    });
    this.stationmonitorservice
      .getDeviceStatusCharts(params)
      .subscribe((res: any) => {
        this.optionLoading = false;
        this.tableTitle = res.title;
        this.options = buildDeviceStatusChart(res);
      });
  }

  public customSort(event: LazyLoadEvent) {
    this.sortBy = event.sortField;
    this.sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
    this.queryTable();
    if (
      this.sortBy == 'runStatusText' ||
      this.sortBy == 'startTime' ||
      this.sortBy == 'endTime' ||
      this.sortBy == 'dayAlarms' ||
      this.sortBy == 'realAlarms' ||
      this.sortBy == 'lastAlarmTime' ||
      this.sortBy == 'commuStatusText' ||
      this.sortBy == 'lastDataTime'
    ) {
      this.toggle = true;
      this.buildChart(this.sortBy);
    }
  }

  /**
   * 设置表格滚动条
   * setScrollHeight
   */

  public setScrollHeight() {
    let height: number = 88 + 50 + 57 + 87;
    this.scrollHeight = this.common.setTableScrollHeight(height);
  }

  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryTable();
  }

  public export() {
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
    });
    this.stationmonitorservice.deviceStatusExport(params);
  }
}
