import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { UserService, User } from '../../../../core/user.service';
import { AuthService } from '../../../../core/auth.service';
import { ConfigType } from '../header.model';
import { HeaderService } from '../header.service';
import { CommonService } from '../../../../common/services/common.service';
import { CacheService } from '../../../../common/services/cache.service';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'mpt-profile',
  templateUrl: 'profile.component.html',
  styleUrls: ['./profile.component.less'],
})
export class ProfileComponent implements OnInit {
  @Output() public hide = new EventEmitter();

  public user: User;
  public showResetPassword = false;

  private isHovered = false;

  public constructor(
    private userService: UserService,
    private router: Router,
    private authService: AuthService,
    private headerService: HeaderService,
    private commonService: CommonService,
    private cacheService: CacheService,
    private tipservice: TipService
  ) { }

  public ngOnInit(): void {
    this.userService.me().subscribe((res) => {
      if (!res) {
        return;
      }
      this.user = res;
      localStorage.setItem('userName', res.realName);
      localStorage.setItem('userId', res.userId);
    });
  }

  // 注销登录
  public logout() {
    this.cacheService.clear();
    this.authService.logout();

    this.router.navigate(['/login']);
  }

  public getInfoText(text) {
    return text ? text : '未填写';
  }

  public onRestPassword(success) {
    if (success) {
      this.showResetPassword = false;
    }
  }

  public config() {
    this.hide.emit();
    if (new RegExp(ConfigType.point).test(this.router.url)) {
      this.headerService.configSubject.next(ConfigType.point);
    } else {
      this.tipservice.tip('warn', '该模块暂无可配置内容');
    }
  }
}
