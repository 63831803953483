import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from '../../../common/services/common.service';
import { TopoService } from '../../../common/services/topo.service';

declare const ht;

@Component({
  selector: 'mpt-topo-display',
  templateUrl: './topo-display.component.html',
  styleUrls: ['./topo-display.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopoDisplayComponent implements OnInit {
  @ViewChild('container', { static: true }) container: ElementRef;
  constructor(
    private route: ActivatedRoute,
    private topoService: TopoService,
    private commonService: CommonService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe((queryParams) => {
      if (opener && opener.topoJSON) {
        this.loadDisplayByUrl(this.initGraphView(), queryParams.tag);
      } else {
        this.topoService.getTopoItems().subscribe((topos: any) => {
          topos.forEach((item) => {
            const name = this.topoService.getNameFromPath(item.path);
            window['topoJSON'][name + '.json'] = item.itemDesc;
            window['topoImage'][name + '.png'] = item.thumbnail;
          });
          this.topoService.getTopoById(queryParams.topoId).subscribe((topo) => {
            window['topoJSON'][queryParams.tag] = topo.topoDesc;
            this.loadDisplayByJson(this.initGraphView(), topo.topoDesc);
          });
        });
      }
    });
  }

  private initGraphView() {
    let graphView = new ht.graph.GraphView();
    graphView.fitContent(true);
    this.addToDom(graphView);

    graphView.setMovableFunc((data) => {
      return false;
    });
    return graphView;
  }

  private loadDisplayByUrl(graphView, displayUrl) {
    const dataModel = graphView.dm();
    ht.Default.xhrLoad(displayUrl, (text) => {
      dataModel.deserialize(ht.Default.parse(text));

      this.commonService
        .subscribeData(this.getPointIds(graphView))
        .subscribe((res) => {
          console.log(res);
          for (const point of res) {
            this.setPointValue(graphView, point.pid, point.v);
          }
        });
    });
  }

  private loadDisplayByJson(graphView, jsonText) {
    const dataModel = graphView.dm();
    dataModel.deserialize(ht.Default.parse(jsonText));

    this.commonService
      .subscribeData(this.getPointIds(graphView))
      .subscribe((res) => {
        console.log(res);
        for (const point of res) {
          this.setPointValue(graphView, point.pid, point.v);
        }
      });
  }

  private getPointIds(graphView) {
    const ids = [];
    graphView.each((data) => {
      ids.push(data.a('pointId'));
    });
    return ids;
  }

  private setPointValue(graphView, pointId, value) {
    graphView.each((data) => {
      if (+data.a('pointId') === +pointId) {
        const precious = data.a('precious');
        if (precious) {
          value = value.toFixed(precious);
        }
        data.a('pointValue', value);
      }
    });
  }

  private addToDom(graphView) {
    const view = graphView.getView(),
      style = view.style;
    this.container.nativeElement.appendChild(view);
    style.left = '0';
    style.right = '0';
    style.top = '0';
    style.bottom = '0';
    window.addEventListener(
      'resize',
      function () {
        graphView.iv();
      },
      false
    );
  }
}
