import { Injectable } from '@angular/core';
import { AppConfigService } from './../../app.config';
import { CommonService } from './../../common/services/common.service';
import { AuthHttp } from './../../core/auth-http';

@Injectable()
export class EmsService {
  public apiUrl: string;

  constructor(
    private http: AuthHttp,
    private cs: CommonService,
    private app: AppConfigService
  ) {
    this.apiUrl = this.app.apiUrl;
  }

  public getEmsMapInfo() {
    return this.http.get(`${this.apiUrl}/api/ems/map/info`);
  }

  public getEmsMapEnergies(params) {
    return this.http
      .get(`${this.apiUrl}/api/ems/map/energies`, {
        params: this.cs.filterEffectParam(params),
      })
      .toPromise();
  }

  public getStationMonitorInfos(stationId) {
    return this.http.get(`${this.apiUrl}/api/ems/station/monitor/infos`, {
      params: { stationId },
    });
  }

  public getStationMonitorDatas(stationId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/station/monitor/datas`, {
        params: { stationId },
      })
      .toPromise();
  }

  public getStationMonitorEnergies(stationId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/station/monitor/energies`, {
        params: { stationId },
      })
      .toPromise();
  }

  public getStationMonitorClusters(stationId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/station/monitor/clusters`, {
        params: { stationId },
      })
      .toPromise();
  }
  public getStationMonitorAlarms(stationId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/station/monitor/alarms`, {
        params: { stationId },
      })
      .toPromise();
  }

  public getClusterMonitordatas(deviceId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/cluster/monitor/datas`, {
        params: { deviceId },
      })
      .toPromise();
  }

  public getClusterMonitorEnergies(deviceId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/cluster/monitor/energies`, {
        params: { deviceId },
      })
      .toPromise();
  }

  public getClusterMonitorAlarms(deviceId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/cluster/monitor/alarms`, {
        params: { deviceId },
      })
      .toPromise();
  }
  public getClusterMonitorPacks(deviceId) {
    return this.http
      .get(`${this.apiUrl}/api/ems/cluster/monitor/packs`, {
        params: { deviceId },
      })
      .toPromise();
  }
}
