import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor
} from "@angular/common/http";
import { Observable } from "rxjs";
import * as _ from "lodash";
import { authConfigConsts } from "../auth-http";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const authToken = localStorage.getItem(
            authConfigConsts.DEFAULT_TOKEN_NAME
        );
        if (authToken) {
            request = request.clone({
                setHeaders: {
                    [authConfigConsts.DEFAULT_HEADER_NAME]: `${
                        authConfigConsts.HEADER_PREFIX_BEARER
                    }${authToken}`
                }
            });
        }
        return next.handle(request);
    }
}
