import { Injectable } from "@angular/core";
import { AuthHttp } from "../../../core/auth-http";

import { AppConfigService } from "../../../app.config";
import { CommonService } from "../../../common/services/common.service";
import { PowerStation } from "./index.model";

@Injectable()
export class StationMonitorService {
    private apiUrl: string = this.appConfig.apiUrl;

    private topoItemsPromise;

    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private commonService: CommonService
    ) {}

    // 根据电站id获取电站信息
    public getStationByStationId(stationId: string) {
        return this.http.get(
            `${this.apiUrl}/api/station/getStationByStationId`,
            { search: { stationId } }
        );
    }
    // 获取电站监视下拉框数据
    public getTopographicType() {
        return this.http.get(
            `${this.apiUrl}/api/stationMonitor/topographicType`
        );
    }
    // 获取拓扑图
    public getTopologyGraphicByStaId(
        stationId: string,
        areaId?: string
    ): Promise<any[]> {
        return this.http
            .get<any[]>(
                `${this.apiUrl}/api/topographic/getSimpleTopologyGraphicByStaId`,
                { search: { stationId, areaId } }
            )
            .toPromise();
    }

    // 获取拓扑图
    public getTopologyGraphicByTopoId(topoId: string) {
        return this.http.get(
            `${this.apiUrl}/api/topographic/getTopologyGraphicByTopoId`,
            { search: { topoId } }
        );
    }

    // 根据电站id获取电站图片
    public getStationPicByStationId(stationId: string) {
        return this.http.get(
            `${this.apiUrl}/api/station/getStationPicRecordsById`,
            { search: { stationId } }
        );
    }

    // 根据电站id获取电站文档
    public getStationDocByStationId(stationId: string) {
        return this.http.get(
            `${this.apiUrl}/api/station/getStationDocRecordsById`,
            { search: { stationId } }
        );
    }

    public getStationDocDownload(docId: string) {
        return `${this.apiUrl}/api/station/download?docId=${docId}`;
    }

    public getStationsTotalCapacity(stations: PowerStation[]) {
        if (!stations || !stations.length) {
            return 0;
        }
        return stations.reduce((memo, station) => {
            if (this.commonService.isValidNumber(station.capacity)) {
                memo += station.capacity;
            }
            return memo;
        }, 0);
    }

    public getTopoItems() {
        if (!this.topoItemsPromise) {
            this.topoItemsPromise = this.http
                .get(`${this.apiUrl}/api/topographic/getAllTopologyItem`)
                .toPromise();
        }
        return this.topoItemsPromise;
    }

    // 方阵拓扑 获取拓扑节点
    public getTopoItemsByStationId(stationId: string) {
        return this.http.get(
            `${this.apiUrl}/api/monitorDevice/deviceTopo`,
            { search: { stationId } }
        );
    }
}
