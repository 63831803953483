import { Injectable } from "@angular/core";
import * as echarts from "echarts/lib/echarts";
import { AuthHttp } from "../../../core/auth-http";
import { LonLat, Regions, MapType } from "./index.model";

@Injectable()
export class StationMapService {
  constructor(private http: AuthHttp) { }

  // 省文件请求
  public getMapData(provinceUrll) {
    return this.http.get(`${provinceUrll}`);
  }

  // 获取经纬度
  public getLonLat(geoCoordMapData): LonLat {
    let minLon;
    let minLat;
    let maxLon;
    let maxLat;

    for (let i in geoCoordMapData) {
      if (geoCoordMapData.hasOwnProperty(i) && i !== "null") {
        let idx = geoCoordMapData[i];
        let idx0 = parseFloat(idx[0]);
        let idx1 = parseFloat(idx[1]);
        if (minLon === undefined || minLon > idx0) {
          minLon = idx0;
        }
        if (minLat === undefined || minLat > idx1) {
          minLat = idx1;
        }
        if (maxLon === undefined || maxLon < idx0) {
          maxLon = idx0;
        }
        if (maxLat === undefined || maxLat < idx1) {
          maxLat = idx1;
        }
      }
    }

    let diffLon = maxLon - minLon; // 经度
    let diffLat = maxLat - minLat; // 纬度
    if (diffLon > diffLat) {
      minLat += (diffLon - diffLat) * 0.1;
      maxLat -= (diffLon - diffLat) * 0.1;
    }

    let cenLon = minLon + (maxLon - minLon) * 0.8;
    let cenLat = minLat + (maxLat - minLat) * 0.5;

    return { minLon, minLat, maxLon, maxLat, cenLon, cenLat };
  }

  public setRegions(mapData, mapName, mapType): Regions {
    let regions = {};
    let minValue = 0.0;
    let maxValue = 0.0;
    let address: string;
    let map: any = echarts.getMap(mapName);

    if (map && map.geoJson && map.geoJson.features) {
      for (let i in map.geoJson.features) {
        if (map.geoJson.features.hasOwnProperty(i)) {
          let feature = map.geoJson.features[i];
          if (feature && feature.properties) {
            regions[feature.properties.name] = 0;
          }
        }
      }
    }
    for (let i in mapData) {
      if (mapData.hasOwnProperty(i)) {
        for (let region in regions) {
          if (regions.hasOwnProperty(region)) {
            switch (mapType) {
              case MapType.county:
                address = mapData[i].provinceName;
                break;
              case MapType.province:
                address = mapData[i].cityName;
                break;
              case MapType.city:
                address = mapData[i].contryName;
                break;
              default:
                break;
            }
            if (address && region) {
              if (
                address.indexOf(region) >= 0 ||
                region.indexOf(address) >= 0
              ) {
                regions[region]++;
                break;
              }
            }
          }
        }
        if (mapData[i].capacity < minValue) {
          minValue = mapData[i].capacity;
        } else if (mapData[i].capacity > maxValue) {
          maxValue = mapData[i].capacity;
        }
      }
    }
    let obj: Regions = { minValue, maxValue, regions };
    return obj;
  }

  // 获取隐藏地区
  public getHideRegions(regions) {
    let hideRegions = [];
    for (let i in regions) {
      if (regions[i] === 0) {
        hideRegions.push({
          name: i,
          itemStyle: {
            normal: { opacity: 0.4 },
            emphasis: { opacity: 0.6 }
          }
        });
      }
    }
    return hideRegions;
  }

  // 数据转换
  public convertData(
    geoCoordMapData,
    stations,
    alarmStatus: number,
    limitStatus: number,
    status: string
  ) {
    let res = [];
    for (let station of stations) {
      if (typeof station.alarmStatus === "undefined") {
        station.alarmStatus = 0;
      }
      if (typeof station.limitStatus === "undefined") {
        station.limitStatus = 0;
      }
      if (station.alarmStatus !== alarmStatus) {
        continue;
      }
      if (station.limitStatus !== limitStatus) {
        continue;
      }

      if (!geoCoordMapData[station.stationName]) {
        continue;
      }
      res.push({
        id: station.stationId,
        capacity: station.capacity,
        name: station.stationName,
        locationId: station.locationId,
        status,
        value: geoCoordMapData[station.stationName]
          .concat(station.capacity)
          .concat(station.alarmStatus)
          .concat(station.limitStatus)
      });
    }
    return res;
  }

  // 点的大小
  public calcSize(value, regions) {
    let minValue = regions.minValue;
    let maxValue = regions.maxValue;

    try {
      if (value > 50) {
        let size = (1 + value[2] / 500) * 25;
        if (size > 45) {
          return 45;
        }
        if (size < 25) {
          return 25;
        }
        return size;
      } else {
        let size = ((value[2] - minValue) / (maxValue - minValue)) * 25;
        if (size > 25) {
          return 25;
        }
        if (size < 5) {
          return 10;
        }
        return size;
      }
    } catch (e) {
      return 10;
    }
  }
}
