import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

import { HeaderComponent } from "./header.component";
import { ProfileComponent } from "./profile/profile.component";
import { MessageComponent } from "./message/message.component";
import { NotifyComponent } from "./notify/notify.component";
import { AlarmComponent } from "./alarm/alarm.component";

import { DeviceTreeComponent } from "./device-tree/device-tree.component";
import { SearchBoxModule } from "./searchbox/searchbox.module";
import { SidebarModule, DialogModule } from "primeng/primeng";

import { HeaderService } from "./header.service";
import { RealTimeService } from "../../alarm/alarm-manage/real-time/real-time.service";
import { AlarmManageService } from "../../alarm/alarm-manage/alarm-manage.service";
import { PasswordModule } from "../../../base/password/password.module";
import { PropTypesService } from "../../system/prop-types/prop-types.service";
import { SharedModule } from "../../../shared/shared.module";

@NgModule({
    imports: [
        CommonModule,
        SearchBoxModule,
        DialogModule,
        PasswordModule,
        SharedModule,
        RouterModule,
        SidebarModule
    ],
    exports: [HeaderComponent],
    declarations: [
        HeaderComponent,
        ProfileComponent,
        MessageComponent,
        NotifyComponent,
        AlarmComponent
    ],
    providers: [
        HeaderService,
        RealTimeService,
        AlarmManageService,
        PropTypesService
    ]
})
export class HeaderModule {}
