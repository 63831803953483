import { Injectable } from '@angular/core';
import { AuthHttp } from 'src/app/core/auth-http';
import { AppConfigService } from 'src/app/app.config';
import { UserService } from 'src/app/core/user.service';
import { CommonService } from 'src/app/common/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class OperateService {
  private apiUrl: string = this.appConfig.apiUrl;

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {}

  public getOperateTemplatesList(params) {
    return this.http.get(`${this.apiUrl}/api/tickets/operate/templates`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getOperateTemplatesByTicketId(ticketId) {
    return this.http.get(
      `${this.apiUrl}/api/tickets/operate/templates/${ticketId}`
    );
  }

  public saveTicketsOperateTemplates(params) {
    return this.http.post(
      `${this.apiUrl}/api/tickets/operate/templates`,
      params
    );
  }

  public updateTicketsOperateTemplates(params) {
    return this.http.put(
      `${this.apiUrl}/api/tickets/operate/templates`,
      params
    );
  }

  public exportTicketsOperateTemplates(params) {
    return this.common.fileExport(
      `${this.apiUrl}/api/tickets/operate/templates/export`,
      params
    );
  }

  public exportTicketsOperateTemplatesDownload() {
    return this.common.fileExport(
      `${this.apiUrl}/api/tickets/operate/templates/download`
    );
  }

  public importTicketsOperateTemplates(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(
        `${this.appConfig.apiUrl}/api/tickets/operate/templates/import`,
        formData
      )
      .toPromise();
  }

  public deleteTicketsOperate(ticketIds) {
    return this.http.delete(`${this.apiUrl}/api/tickets/operate`, {
      params: { ticketIds },
    });
  }

  public getOperateList(params) {
    return this.http.get(`${this.apiUrl}/api/tickets/operate`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public exportTicketsOperate(params) {
    return this.common.fileExport(
      `${this.apiUrl}/api/tickets/operate/export`,
      params
    );
  }

  public getOperateByTicketId(ticketId) {
    return this.http.get(`${this.apiUrl}/api/tickets/operate/${ticketId}`);
  }

  public saveOperateTickets(params) {
    return this.http.post(`${this.apiUrl}/api/tickets/operate`, params);
  }

  public updateOperateTickets(params) {
    return this.http.put(`${this.apiUrl}/api/tickets/operate`, params);
  }

  // 获取 表单配置
  public getNullable(targetNodeId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/workflows/nullable`, {
        params: { targetNodeId },
      })
      .toPromise();
  }

  public check(params) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/tickets/operate/check`,
      {},
      { params }
    );
  }
}
