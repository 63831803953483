import { Injectable } from '@angular/core';
import { AuthHttp } from '@core/auth-http';
import { AppConfigService } from 'src/app/app.config';

@Injectable({
  providedIn: 'root'
})
export class FullLayoutDashboardService {

  public apiUrl: string;
  public pathUrls = {
    stationInfo: '/api/monitorCenter/stationInfo'
  }

  constructor(private http: AuthHttp, private appConfig: AppConfigService) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public getStationInfo() {
    return this.http.get(`${this.apiUrl}${this.pathUrls.stationInfo}`).toPromise();
  }
}
