import { StationSelectorService } from "./station-selector.service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import {
    DialogModule,
    TabViewModule,
    SplitButtonModule,
    ButtonModule,
    DropdownModule,
    InputTextModule,
    AutoCompleteModule,
    MultiSelectModule,
    PaginatorModule,
    CheckboxModule
} from "primeng/primeng";
import { StationSelectorComponent } from "./station-selector.component";
import { TableModule } from "primeng/table";
import { CommonModule } from "@angular/common";
import { CompanyTreeSelectorModule } from "../company-tree-selector/company-tree-selector.module";

@NgModule({
    imports: [
        HttpClientModule,
        CommonModule,
        DialogModule,
        TabViewModule,
        SplitButtonModule,
        ButtonModule,
        TableModule,
        DropdownModule,
        InputTextModule,
        AutoCompleteModule,
        MultiSelectModule,
        FormsModule,
        PaginatorModule,
        CheckboxModule,
        ReactiveFormsModule,
        CompanyTreeSelectorModule
    ],
    declarations: [StationSelectorComponent],
    exports: [StationSelectorComponent],
    providers: [StationSelectorService]
})
export class StationSelectorModule {}
