export function buildInstalledCapacityChart() {
    var data = [
        {
            name: "阜阳金种子6.16896MW光伏电站",
            value: [115.849146, 32.889156, 6.169],
            stationType: "水泥屋顶"
        },
        {
            name: "安徽界首蓝天0.924MW屋顶分布式光伏电站",
            value: [115.466991, 33.247196, 0.924],
            stationType: "水泥屋顶"
        },
        {
            name: "海门灵树",
            value: [121.4474, 32.069315, 5.169],
            stationType: "农光互补"
        },
        {
            name: "连云港伊山二期",
            value: [119.245959, 34.3015, 6.754],
            stationType: "农光互补"
        },
        {
            name: "启东合作",
            value: [121.637028, 31.926345, 21.04],
            stationType: "农光互补"
        },
        {
            name: "合肥宝业",
            value: [117.240116, 31.808623, 5.708],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥金诺",
            value: [117.269661, 31.837868, 6.577],
            stationType: "彩钢屋顶"
        },
        {
            name: "阜阳京九丝绸",
            value: [115.829345, 32.898245, 1.784],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥润嘉",
            value: [117.298406, 31.828051, 1.813],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥凯盛",
            value: [117.357838, 31.781652, 1.676],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥桥柜",
            value: [117.429631, 31.889512, 2.196],
            stationType: "彩钢屋顶"
        },
        {
            name: "邳州大诚",
            value: [117.50463, 34.405543, 11.559],
            stationType: "渔光互补"
        },
        {
            name: "兴化钓鱼",
            value: [119.853781, 32.963153, 6.25],
            stationType: "渔光互补"
        },
        {
            name: "张庄华博",
            value: [115.46837, 36.483281, 4.131],
            stationType: "农光互补"
        },
        {
            name: "合肥强建",
            value: [117.287, 31.946, 0.87],
            stationType: "彩钢+水泥"
        },
        {
            name: "南通乐歌",
            value: [121.007375, 31.857338, 1.315],
            stationType: "水泥屋顶"
        },
        {
            name: "启东胜狮二期",
            value: [123.933992, 30.760809, 1.636],
            stationType: "彩钢屋顶"
        },
        {
            name: "启东中盛机电",
            value: [121.877701, 31.921345, 0.275],
            stationType: "水泥屋顶"
        },
        {
            name: "灌南飞展三期",
            value: [115.878888, 33.033457, 24.5],
            stationType: "渔光互补"
        },
        {
            name: "城发华耀二期",
            value: [116.297, 37.509, 10.0],
            stationType: "农光互补"
        },
        {
            name: "安徽亳州40MW光伏电站",
            value: [115.783169, 33.849388, 40.0],
            stationType: "山地电站"
        },
        {
            name: "河北衡水7.2MW光伏电站",
            value: [115.561014, 37.726043, 7.2],
            stationType: "山地电站"
        },
        {
            name: "扬州信息产业园1MW屋顶分布式光伏电站",
            value: [119.5116, 32.395069, 1.01],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥东维0.5MW屋顶分布式光伏电站",
            value: [117.228806, 31.719746, 0.5],
            stationType: "水泥屋顶"
        },
        {
            name: "安徽阜阳168KW屋顶分布式光伏电站",
            value: [115.981392, 32.925128, 0.168],
            stationType: "山地电站"
        },
        {
            name: "安徽亳州华阳",
            value: [115.913536, 33.68733, 21.034],
            stationType: "农光互补"
        },
        {
            name: "宿州经开区44.02684MW农光互补分布式光伏发电项目",
            value: [116.977577, 33.60714, 20.915],
            stationType: "农光互补"
        },
        {
            name: "阜阳永强新乌江二期",
            value: [115.851753, 32.891605, 21.11],
            stationType: "农光互补"
        },
        {
            name: "灌南飞展二期",
            value: [115.878888, 33.033457, 16.841],
            stationType: "农光互补"
        },
        {
            name: "后寨永创二期",
            value: [116.582684, 37.34141, 10.886],
            stationType: "农光互补"
        },
        {
            name: "东营力诺",
            value: [118.312595, 37.906691, 20.006],
            stationType: "农光互补"
        },
        {
            name: "阜阳永强李桥",
            value: [115.823026, 32.890527, 20.26],
            stationType: "农光互补"
        },
        {
            name: "安徽灵璧40.10292MWp地面分布式光伏电站",
            value: [117.534294, 33.82207, 40.103],
            stationType: "山地电站"
        },
        {
            name: "安徽萧县20MWp地面分布式光伏电站",
            value: [117.086415, 34.143958, 20.149],
            stationType: "山地电站"
        },
        {
            name: "启东华泰1MW屋顶光伏电站",
            value: [121.619447, 31.824145, 1.015],
            stationType: "水泥屋顶"
        },
        {
            name: "启东滨海园区鱼塘15MW光伏电站",
            value: [121.870979, 31.898399, 15.002],
            stationType: "渔光互补"
        },
        {
            name: "城发华耀",
            value: [116.313582, 37.518698, 21.164],
            stationType: "农光互补"
        },
        {
            name: "夏津力诺二期",
            value: [116.1511, 37.054587, 10.83],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳颍上耿棚",
            value: [116.121541, 32.647993, 62.27],
            stationType: "渔光互补"
        },
        {
            name: "启东南阳4.8MW农光互补光伏电站",
            value: [121.723259, 31.881728, 4.675],
            stationType: "农光互补"
        },
        {
            name: "辽宁乾丰专用车7MW屋顶光伏发电项目",
            value: [123.680107, 42.178133, 6.82],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港伊山",
            value: [119.291198, 34.590419, 16.988],
            stationType: "农光互补"
        },
        {
            name: "连云港朝阳电站",
            value: [119.307159, 34.683782, 23.185],
            stationType: "农光互补"
        },
        {
            name: "后寨永创一期",
            value: [116.582684, 37.34141, 11.236],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳伍明",
            value: [115.878888, 33.033457, 20.003],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳行流",
            value: [115.723941, 33.049921, 20.219],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳新乌江一期",
            value: [116.166687, 32.869704, 20.389],
            stationType: "农光互补"
        },
        {
            name: "启东胜狮6MW屋顶分布式光伏电站",
            value: [121.702208, 31.792931, 6.237],
            stationType: "彩钢屋顶"
        },
        {
            name: "扬州奔多4.979MW屋顶分布式光伏电站",
            value: [119.504115, 32.367086, 4.939],
            stationType: "彩钢屋顶"
        },
        {
            name: "灵璧明升10MW农光互补光伏电站",
            value: [117.497055, 33.675952, 10.916],
            stationType: "农光互补"
        },
        {
            name: "安徽萧县华耀20MW农光互补光伏电站",
            value: [116.771111, 34.135592, 21.523],
            stationType: "农光互补"
        },
        {
            name: "安徽阜阳新盛电站",
            value: [116.948536, 34.164347, 30.751],
            stationType: "渔光互补"
        },
        {
            name: "萧县大屯镇20MW农光互补分布式光伏发电项目",
            value: [116.972251, 34.169984, 22.145],
            stationType: "农光互补"
        },
        {
            name: "祥光新能源6MW全额上网屋顶分布式光伏发电",
            value: [119.491977, 37.059157, 6.839],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东淄博1.4MW光伏电站",
            value: [117.8231970119, 37.1824021543, 6.119],
            stationType: "彩钢+水泥"
        },
        {
            name: "山东冠县宋村华博",
            value: [115.449025, 36.489694, 73.727],
            stationType: "农光互补"
        },
        {
            name: "宿州经开区河拐村20MW农光互补分布式光伏发电项目库1.",
            value: [116.974604, 33.601044, 23.112],
            stationType: "农光互补"
        },
        {
            name: "金东二期",
            value: [119.672114, 32.194895, 10.063],
            stationType: "彩钢屋顶"
        },
        {
            name: "河北风阳卧牛盘",
            value: [117.359653, 42.470969, 21.387],
            stationType: "山地电站"
        },
        {
            name: "连云港首耀新海",
            value: [119.291198, 34.590419, 3.266],
            stationType: "水泥屋顶"
        },
        {
            name: "盐城利能",
            value: [120.240052, 33.440862, 6.523],
            stationType: "农光互补"
        },
        {
            name: "铁岭际华5.78MW屋顶分布式光伏发电项目",
            value: [123.680107, 42.178133, 5.783],
            stationType: "彩钢屋顶"
        },
        {
            name: "铁岭中欧汽车3.5MW屋顶分布式光伏发电项目",
            value: [123.7008864936, 42.1576004524, 3.481],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东安丘龙鼎重工",
            value: [119.220486, 36.412793, 2.976],
            stationType: "彩钢屋顶"
        },
        {
            name: "安丘晨晖2MW全额上网屋顶分布式光伏发电项目",
            value: [119.229382, 36.480252, 2.174],
            stationType: "彩钢屋顶"
        },
        {
            name: "潍坊安丘汇创",
            value: [119.228152, 36.483145, 4.15],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东潍坊融光一站6MWp光伏电站",
            value: [119.170064, 37.048124, 6.586],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东潍坊融光二站6MWp光伏电站",
            value: [119.170064, 37.047952, 6.586],
            stationType: "彩钢屋顶"
        },
        {
            name: "灌云中森侍庄2.86832MW屋顶电站",
            value: [119.30428, 34.601536, 2.868],
            stationType: "水泥屋顶"
        },
        {
            name: "启东近海镇18MW农光互补分布式光伏发电项目",
            value: [121.802013, 31.911794, 18.145],
            stationType: "农光互补"
        },
        {
            name: "泗洪孙园镇20MW渔光互补分布式光伏发电项目",
            value: [118.222029, 33.462109, 21.234],
            stationType: "渔光互补"
        },
        {
            name: "泗洪梅花镇40MW农光互补光伏“领跑者”项目",
            value: [118.197523, 33.453856, 41.476],
            stationType: "渔光互补"
        },
        {
            name: "扬中阜润",
            value: [119.802256, 32.242, 6.411],
            stationType: "彩钢屋顶"
        },
        {
            name: "安徽安庆永安电子电站",
            value: [117.080678, 30.509951, 1.574],
            stationType: "水泥屋顶"
        },
        {
            name: "沈阳鲁博厨业3.9MW屋顶分布式光伏发电项目",
            value: [123.4132765888, 41.5754322375, 3.942],
            stationType: "彩钢+水泥"
        },
        {
            name: "惠民永正",
            value: [117.596994, 37.497634, 10.129],
            stationType: "农光互补"
        },
        {
            name: "商丘当店",
            value: [115.738182, 34.551813, 77.59],
            stationType: "农光互补"
        },
        {
            name: "冠县公共事业",
            value: [115.510224, 36.450879, 1.8],
            stationType: "水泥屋顶"
        },
        {
            name: "泗洪天岗湖",
            value: [116.785989, 33.944984, 40.199],
            stationType: "渔光互补"
        },
        {
            name: "合肥恒科1.55184MW光伏电站",
            value: [117.200839, 31.822187, 1.552],
            stationType: "水泥屋顶"
        },
        {
            name: "宿州现代鞋城",
            value: [116.895538, 33.552214, 1.093],
            stationType: "水泥屋顶"
        },
        {
            name: "岳胡华博",
            value: [114.706568, 37.938171, 3.219],
            stationType: "农光互补"
        },
        {
            name: "河南厢华",
            value: [118.159106, 39.61673, 20.14],
            stationType: "农光互补"
        },
        {
            name: "营村华博",
            value: [114.218226, 37.110309, 3.322],
            stationType: "农光互补"
        },
        {
            name: "东营金宇",
            value: [116.198767, 36.399125, 3.693],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东东营6kv津禾光伏电站",
            value: [118.439848423, 37.5576934579, 8.507],
            stationType: "彩钢屋顶"
        },
        {
            name: "东营正凯",
            value: [116.557801, 37.398347, 2.045],
            stationType: "彩钢屋顶"
        },
        {
            name: "微山永旭",
            value: [120.394647, 36.085204, 3.743],
            stationType: "彩钢屋顶"
        },
        {
            name: "创能二站",
            value: [122.973982, 41.049711, 6.763],
            stationType: "彩钢屋顶"
        },
        {
            name: "创能一站",
            value: [123.461215, 41.778005, 6.248],
            stationType: "彩钢屋顶"
        },
        {
            name: "南京雨润4MW屋顶分布式光伏电站",
            value: [119.560247, 31.963432, 3.851],
            stationType: "水泥屋顶"
        },
        {
            name: "科灵空调",
            value: [123.826202, 42.181145, 4.774],
            stationType: "彩钢屋顶"
        },
        {
            name: "如皋里高",
            value: [119.383235, 32.416341, 0.403],
            stationType: "水泥屋顶"
        },
        {
            name: "南通永兴华陆",
            value: [120.367551, 33.404614, 3.16],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港中森西苑",
            value: [119.278155, 34.259502, 2.323],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港中盛石榴",
            value: [117.998971, 33.977616, 2.134],
            stationType: "水泥屋顶"
        },
        {
            name: "连云港易睐珂克浦南1.987MW光伏电站",
            value: [119.3443, 34.586143, 1.987],
            stationType: "彩钢屋顶"
        },
        {
            name: "南通永兴林森",
            value: [118.920017, 33.552052, 3.126],
            stationType: "彩钢屋顶"
        },
        {
            name: "融光二站二期",
            value: [114.383267, 36.125962, 6.486],
            stationType: "彩钢屋顶"
        },
        {
            name: "灌南飞展一期",
            value: [114.084712, 34.308878, 12.01],
            stationType: "渔光互补"
        },
        {
            name: "淄博开耀",
            value: [114.371502, 35.67851, 3.405],
            stationType: "水泥屋顶"
        },
        {
            name: "华乐光电一期",
            value: [121.616581, 31.810208, 0.906],
            stationType: "水泥屋顶"
        },
        {
            name: "启东凯帝华虹1.18MWP屋顶分布式光伏电站",
            value: [121.6123491124, 31.827141196, 1.104],
            stationType: "彩钢+水泥"
        },
        {
            name: "铁岭林燕新能源科技有限公司",
            value: [123.6904623894, 42.146368079, 3.963],
            stationType: "彩钢屋顶"
        },
        {
            name: "山东潍坊新瑞永旭2.28MW屋顶电站",
            value: [119.228754, 36.492428, 2.281],
            stationType: "彩钢屋顶"
        },
        {
            name: "宿州中谷0.6552光伏电站",
            value: [116.954963, 33.65434, 0.655],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥绿晖3.81MW光伏电站",
            value: [117.231066, 31.819371, 3.803],
            stationType: "彩钢+水泥"
        },
        {
            name: "泗洪归仁1.59636MW屋顶光伏发电站",
            value: [118.204362, 33.674983, 1.596],
            stationType: "水泥屋顶"
        },
        {
            name: "泗洪陈圩1.6854MW屋顶光伏发电站",
            value: [118.362715, 33.333696, 1.685],
            stationType: "水泥屋顶"
        },
        {
            name: "阜阳金坤达3.159MW光伏电站",
            value: [115.809947, 32.902675, 3.159],
            stationType: "彩钢+水泥"
        },
        {
            name: "镇江金东20MW屋顶分布式光伏电站",
            value: [119.697593, 32.195312, 20.0],
            stationType: "彩钢屋顶"
        },
        {
            name: "如皋意瑞达2MW屋顶分布式光伏电站",
            value: [120.541273, 32.434058, 1.99],
            stationType: "彩钢屋顶"
        },
        {
            name: "夏津力诺一期",
            value: [116.059935, 37.026594, 10.538],
            stationType: "农光互补"
        },
        {
            name: "合肥应流4.32MW屋顶分布式光伏电站",
            value: [117.174211, 31.788516, 4.253],
            stationType: "彩钢屋顶"
        },
        {
            name: "启东林洋电子2MW屋顶分布式光伏电站",
            value: [121.639277, 31.917826, 1.998],
            stationType: "水泥屋顶"
        },
        {
            name: "内蒙乾华135MW地面集中式光伏电站",
            value: [111.284262, 40.344304, 142.697],
            stationType: "农光互补"
        },
        {
            name: "启东乾朔1.78MW屋顶分布式光伏电站",
            value: [121.623939, 31.819655, 1.665],
            stationType: "水泥屋顶"
        },
        {
            name: "南通永兴永林",
            value: [120.918807, 31.961345, 3.275],
            stationType: "彩钢屋顶"
        },
        {
            name: "海门银燕0.42MW屋顶分布式光伏电站",
            value: [121.203163, 31.912599, 0.42],
            stationType: "水泥屋顶"
        },
        {
            name: "南通观音山1MW屋顶分布式光伏电站",
            value: [120.724018, 32.37467, 1.005],
            stationType: "水泥屋顶"
        },
        {
            name: "南通中心村0.5MW屋顶分布式光伏电站",
            value: [121.084309, 31.885101, 0.518],
            stationType: "水泥屋顶"
        },
        {
            name: "南通农副1MW屋顶分布式光伏电站",
            value: [120.957317, 31.983873, 1.0],
            stationType: "水泥屋顶"
        },
        {
            name: "安徽临泉",
            value: [115.264704, 33.070225, 22.389],
            stationType: "农光互补"
        },
        {
            name: "界首金农",
            value: [115.386274, 33.277882, 21.961],
            stationType: "农光互补"
        },
        {
            name: "安徽蚌埠4.4MW光伏电站",
            value: [117.391579, 32.925332, 4.4],
            stationType: "山地电站"
        },
        {
            name: "淮北刘桥永瑞",
            value: [113.262503, 35.190781, 56.138],
            stationType: "农光互补"
        },
        {
            name: "合肥万润",
            value: [114.276853, 34.697382, 1.598],
            stationType: "水泥屋顶"
        },
        {
            name: "合肥徽商大市场",
            value: [114.94965, 35.830754, 4.166],
            stationType: "彩钢+水泥"
        },
        {
            name: "合肥恒春玻璃",
            value: [113.920412, 35.272855, 2.332],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥恒通玻璃",
            value: [113.964015, 34.024943, 3.88],
            stationType: "彩钢屋顶"
        },
        {
            name: "合肥应流二期",
            value: [113.502879, 34.673248, 1.741],
            stationType: "彩钢屋顶"
        },
        {
            name: "连云港首耀清墩",
            value: [114.742589, 33.608996, 1.742],
            stationType: "彩钢屋顶"
        },
        {
            name: "华乐光电二期",
            value: [121.642417, 31.745777, 1.43],
            stationType: "车棚"
        },
        {
            name: "启东宏华",
            value: [121.688697, 31.792931, 6.071],
            stationType: "彩钢屋顶"
        }
    ];

    var stationTypes = {
        农光互补: [],
        山地电站: [],
        "彩钢+水泥": [],
        彩钢屋顶: [],
        水泥屋顶: [],
        渔光互补: [],
        电站类别: [],
        车棚: []
    };

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            stationTypes[data[key].stationType].push(data[key]);
        }
    }

    // 指定图表的配置项和数据

    var data1 = [
        { name: "安徽", value: 670.733 },
        { name: "江苏", value: 607.416 },
        { name: "山东", value: 327.035 },
        { name: "河南", value: 171.348 },
        { name: "内蒙古", value: 142.697 },
        { name: "河北", value: 28.587 },
        { name: "辽宁", value: 23.989 }
    ];
    var data2 = [
        { name: "安徽", value: 41 },
        { name: "江苏", value: 53 },
        { name: "山东", value: 31 },
        { name: "河南", value: 2 },
        { name: "内蒙古", value: 1 },
        { name: "河北", value: 1 },
        { name: "辽宁", value: 5 }
    ];
    var data3 = [
        { name: "2013", value: 3.428 },
        { name: "2014", value: 153.353 },
        { name: "2015", value: 249.731 },
        { name: "2016", value: 978.102 },
        { name: "2017", value: 1310.126 },
        { name: "2018", value: 1456.048 },
        { name: "2019", value: 1694.418 }
    ];
    var data4 = [
        { name: "2013", value: 2 },
        { name: "2014", value: 9 },
        { name: "2015", value: 23 },
        { name: "2016", value: 66 },
        { name: "2017", value: 111 },
        { name: "2018", value: 130 },
        { name: "2019", value: 134 }
    ];

    var data5 = [
        { name: "农光互补", value: 871.049 },
        { name: "渔光互补", value: 265.251 },
        { name: "彩钢屋顶", value: 197.034 },
        { name: "山地电站", value: 133.407 },
        { name: "水泥屋顶", value: 47.984 },
        { name: "彩钢+水泥", value: 23.163 },
        { name: "车棚", value: 1.43 }
    ];

    var data6 = [
        { name: "彩钢屋顶", value: 42 },
        { name: "农光互补", value: 38 },
        { name: "水泥屋顶", value: 29 },
        { name: "山地电站", value: 11 },
        { name: "渔光互补", value: 10 },
        { name: "彩钢+水泥", value: 7 },
        { name: "车棚", value: 1 }
    ];

    var series: any = [
        {
            name: "电站类型(MW)",
            type: "pie",
            radius: [15, 80],
            center: ["75%", "20%"],
            roseType: "radius",
            data: data5,
            label: {
                formatter: "{b}:\n{c} MW"
            },
            tooltip: {
                //formatter: '{b}:</br>{c} MW</br>{d} %'
            }
        },
        {
            name: "装机容量（MW）",
            type: "bar",
            xAxisIndex: 0,
            yAxisIndex: 0,
            stack: "0",
            data: data1
        },
        /*{
            name:'电站数量（座）',
            type:'bar',
            xAxisIndex: 0,
            yAxisIndex: 1,
            stack: '1',
            data: data2
        },*/
        {
            name: "装机容量（MW）",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 1,
            stack: "2",
            data: data3
        }
        /*{
            name:'电站数量（座）',
            type:'bar',
            xAxisIndex: 1,
            yAxisIndex: 3,
            stack: '3',
            data: data4
        },*/
    ];
    var legendData = [];
    for (let i in stationTypes) {
        if (stationTypes[i].length == 0) continue;
        legendData.push(i);
        series.push({
            name: i,
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: 10,
            /*itemStyle: {
                normal: {
                    color: '#FB7293'
                }
            },*/
            data: stationTypes[i],
            tooltip: {
                formatter: function(param) {
                    var tip =
                        '<span style="display:inline-block; width:10px; height:10px; background-color: ' +
                        param.color +
                        '; border-radius:5px; margin-right: 5px"></span>' +
                        param.name +
                        "</br>装机容量：" +
                        param.value[2] +
                        " MW</br>电站类型：" +
                        param.seriesName;
                    console.log(tip);
                    return tip;
                }
            }
        });
    }
    series.push({
        name: "装机容量(MW)",
        type: "map",
        mapType: "china",
        geoIndex: 0,
        data: data1,
        tooltip: {
            formatter: "{b}</br>{a}: {c} MW"
        }
    });

    let option = {
        backgroundColor: "rgba(255, 255, 255, 0)",
        //color: ['#37A2DA', '#67E0E3'],
        tooltip: {
            trigger: "item"
            /* formatter: function(params) {
                if (params instanceof Array) {
                    if (params.length === 0) {
                        return null;
                    } else {
                        var tip = params[0].name;
                        for (var i in params) {
                            tip += "<br />" + params[i].seriesName + ": " + params[i].value
                                + (params[i].seriesName == '装机容量（MW）' ? ' MW' : ' 座');
                        }
                        return tip;
                    }
                } else if (params.seriesType == 'map') {
                    if (params.data === undefined) {
                        return null;
                    } else {
                        return params.data.name + "：" + params.data.value + " MW";
                    }
                } else {
                    return params.data.name + "：" + params.data.value[2] + " WM";
                }
            }*/
        },
        legend: {
            top: "top",
            left: "15%",
            data: legendData
        },
        grid: [
            {
                top: "40%",
                height: "20%",
                left: "62%",
                containLabel: false,
                tooltip: {
                    trigger: "axis"
                }
            },
            {
                top: "70%",
                height: "20%",
                left: "62%",
                //containLabel: false,
                tooltip: {
                    trigger: "axis"
                }
            }
        ],
        xAxis: [
            {
                type: "category",
                axisLabel: {
                    interval: 0
                },
                data: ["安徽", "江苏", "山东", "河南", "内蒙古", "河北", "辽宁"]
            },
            {
                gridIndex: 1,
                type: "category",
                data: ["2013", "2014", "2015", "2016", "2017", "2018", "2019"]
            }
        ],
        yAxis: [
            {
                name: "MW",
                type: "value"
            },
            {
                gridIndex: 1,
                name: "MW",
                type: "value"
            }
        ],
        visualMap: {
            show: false,
            type: "continuous",
            //orient: 'horizontal',
            min: 0,
            max: 670.733,
            inRange: {
                color: ["#56bdff", "#026bad"]
            },
            itemWidth: 8,
            itemHeight: 100,
            left: "left",
            top: "70%",
            //text:['高','低'],           // 文本，默认为数值文本
            calculable: true,
            precision: 0,
            //align: 'bottom',
            seriesIndex: [series.length - 1]
        },
        geo: {
            map: "china",
            //zoom: 1,
            //center: [centerX, centerY],
            // boundingCoords: [
            //     [minX, maxY], // 定位左上角经纬度
            //     [maxX, minY]  // 定位右下角经纬度
            // ],
            layoutCenter: ["30%", "50%"],
            layoutSize: "100%",
            zlevel: 0,
            label: {
                emphasis: {
                    show: false
                }
            },
            roam: true,
            itemStyle: {
                areaColor: "#BBDEFB", //'#F2F2F2',
                borderColor: "#727272",
                opacity: 1
            },
            emphasis: {
                itemStyle: {
                    areaColor: "#005184"
                }
            }
        },
        series: series
    };
    return option;
}
