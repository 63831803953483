export function buildDeviceTopologyChart(stationId, objs, theme = 'light') {
  //构建树形拓扑图
  let parent;
  for (let i in objs) {
    if (objs.hasOwnProperty(i)) {
      let child = objs[i];
      theme === 'dark'
        ? (child.label = {
            color: '#fff',
          })
        : '';

      if (child.type === 'station') {
        child.itemStyle = { color: '#37A2DA' };
        stationId = child.id;
      } else if (child.type === 'area') {
        continue;
      } else {
        if (child.commuStatus !== '0') {
          child.itemStyle = { color: '#999999' };
        } else if (child.realAlarms > 0) {
          child.itemStyle = { color: '#FB7293' };
        } else {
          child.itemStyle = { color: '#9FE6B8' };
        }
        child.tooltip = {
          formatter:
            '设备名称：{b}' +
            '</br>设备分类：' +
            child.className +
            '</br>通讯状态：' +
            (child.commuStatus === '0' ? '在线' : '离线') +
            '</br>实时告警：' +
            child.realAlarms,
        };

        if (child.pid == null) {
          parent = objs[child.stationId];
        } else {
          parent = objs[child.pid];
        }
        if (parent !== undefined) {
          if (parent.children === undefined) {
            parent.children = [];
          }
          parent.children.push(child);
        }
      }
    }
  }

  // 指定图表的配置项和数据
  let option = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    tooltip: {
      trigger: 'item',
    },
    series: [
      {
        type: 'tree',
        symbol: 'circle',
        symbolSize: 7,
        roam: true,
        initialTreeDepth: 3,
        itemStyle: {
          borderWidth: 0,
        },
        label: {
          normal: {
            color: '#fff',
            position: 'top',
            verticalAlign: 'middle',
            align: 'center',
            fontSize: 12,
          },
        },
        leaves: {
          label: {
            normal: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left',
            },
          },
        },
        data: [objs[stationId]],
      },
    ],
  };

  return option;
}
