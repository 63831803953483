import * as _ from 'lodash';

export const deviceOnlineOption = {
  color: [],
  backgroundColor: 'rgba(255, 255, 255, 0)',
  tooltip: {
    trigger: 'item',
    formatter: '{b} : {c} ({d}%)',
  },
  title: {
    show: false,
    text: '',
    left: '',
    top: 'middle',
    textStyle: { fontSize: '22', color: '#727272' },
  },
  legend: {
    orient: 'vertical',
    // left: 'right',
    right: '9%',
    top: 'middle',
    data: [],
  },
  series: [
    {
      name: '设备在线率',
      type: 'pie',
      radius: ['50%', '65%'],
      avoidLabelOverlap: false,
      center: ['35%', '50%'],
      label: {
        normal: {
          show: false,
        },
      },
      cursor: 'default',
      labelLine: {
        normal: {
          show: false,
        },
      },
      data: [
        { value: 122, name: '运行 122' },
        { value: 2, name: '停机 2' },
      ],
    },
    {
      name: '',
      type: 'pie',
      radius: [0, '50%'],
      center: ['35%', '50%'],
      label: {
        show: true,
        position: 'center',
        formatter: '{total|1}',
        rich: {
          total: {
            fontSize: 22,
            fontFamily: '微软雅黑',
            color: '#727272',
          },
        },
      },
      itemStyle: {
        normal: {
          color: 'transparent',
        },
        emphasis: {
          show: false,
        },
      },
      hoverAnimation: false,
      data: [100],
      zlevel: 2,
      silent: true,
    },
  ],
};

export function buildDeviceOnlineRateChart(data, type) {
  let option = _.cloneDeep(deviceOnlineOption);
  switch (type) {
    case 'outsideLineOutlet':
      option.color = ['#37A2DA', '#E0E0E0'];
      break;

    case 'collectingLine':
      option.color = ['#67E0E3', '#E0E0E0'];
      break;

    case 'boxChange':
      option.color = ['#FFDB5C', '#E0E0E0'];
      break;

    case 'inverter':
      option.color = ['#FF9F7F', '#E0E0E0'];
      break;
    default:
      option.color = ['#37A2DA', '#E0E0E0'];
      break;
  }

  option.legend.data = data.legend.data;
  option.series[0].data = data.series[0].data;
  option.series[1].label.formatter = `{total|${data.title.text}}`;
  return option;
}
