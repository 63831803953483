import { ObjectSelectorModule } from 'src/app/common/components/object-selector/object-selector.module';
import { UserSelectorModule } from 'src/app/common/components/user-selector/user-selector.module';
import { UploadDocsModule } from './../../../../common/components/upload-docs/upload-docs.module';
import { UploadPicsModule } from './../../../../common/components/upload-pics/upload-pics.module';
import { CommentModule } from 'src/app/common/components/comment/comment.module';
import { CcUsersModule } from 'src/app/common/components/cc-users/cc-users.module';
import { DropdownModule } from 'primeng/dropdown';
import { SpinnerModule } from 'primeng/spinner';
import { CalendarModule } from 'primeng/calendar';
import { InputTextModule } from 'primeng/inputtext';
import { DialogModule } from 'primeng/dialog';
import { SharedModule } from './../../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { RepairTicketComponent } from './repair-ticket.component';
import { FieldsetModule, InputTextareaModule } from 'primeng/primeng';
import { TeamSelectorModule } from 'src/app/base/team/shared/team-selector/team-selector.module';
import { InAndOutPopupsModule } from '../../../device-assets/spare-parts-manage/shared/in-and-out-popups/in-and-out-popups.module';
import { TableModule } from 'primeng/table';

const primengModule = [
  DialogModule,
  FieldsetModule,
  InputTextModule,
  InputTextareaModule,
  TableModule,
  CalendarModule,
  SpinnerModule,
  DropdownModule,
];

@NgModule({
  declarations: [RepairTicketComponent],
  exports: [RepairTicketComponent],
  imports: [
    SharedModule,
    TeamSelectorModule,
    CcUsersModule,
    CommentModule,
    UploadPicsModule,
    UploadDocsModule,
    UserSelectorModule,
    ObjectSelectorModule,
    InAndOutPopupsModule,
    ...primengModule,
  ],
})
export class RepairTicketModule {}
