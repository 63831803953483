import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TreeNode } from 'primeng/primeng';
import { Page } from '../../shared/models';
import { DeptsService } from '../depts/shared/depts.service';
import { UsersService } from '../users/shared/users.service';
import { UserInfo } from '../users/shared/users.model';

@Component({
  selector: 'mpt-users-picker',
  templateUrl: 'users-picker.component.html',
  styleUrls: ['./users-picker.component.less']
})
export class UsersPickerComponent implements OnInit {
  @Input() public visible: boolean = false;
  @Input() public hideCallback: Function;

  @Output() public onUsersSelected = new EventEmitter();

  public selectedUser: UserInfo;
  public users: Page<UserInfo>;

  public selectedDept: TreeNode;
  public depts: TreeNode[] = [];

  constructor(
    private deptsService: DeptsService,
    private usersService: UsersService
  ) { }

  public ngOnInit() {
    this.deptsService.getDeptsTree().subscribe(
      (rootDept) => {
        this.expandTree(rootDept, true);
        this.depts = [rootDept];
      }
    );
    this.usersService.getUsers({ page: 0, size: 10000 }).subscribe(
      (users) => {
        this.users = users;
      }
    );
  }

  private show() {
    this.visible = true;
  }

  public onSelectDept() {
    this.usersService.getUsers({
      page: 0, size: 10000,
      deptId: this.selectedDept.data.deptId
    }).subscribe(
      (users) => {
        this.users = users;
      }
      );
  }

  public hide() {
    this.visible = false;
  }

  public onHide() {
    if (this.hideCallback && typeof this.hideCallback === 'function') {
      this.hideCallback();
    }
  }

  public onOK() {
    this.onUsersSelected.emit(this.selectedUser);
    this.hide();
  }

  private expandTree(node: TreeNode, isExpand: boolean) {
    if (node.children && node.children.length > 0) {
      node.expanded = isExpand;
      node.children.forEach((childNode) => {
        this.expandTree(childNode, isExpand);
      });
    }
  }

}
