import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { CommonService } from '@common/services/common.service';
import { Component, OnInit } from '@angular/core';
import { options } from './charts/device.chart';
import { LazyLoadEvent } from 'primeng/primeng';

@Component({
  selector: 'app-wind-turbines',
  templateUrl: './wind-turbines.component.html',
  styleUrls: ['./wind-turbines.component.less'],
})
export class WindTurbinesComponent implements OnInit {
  legendItems: any = [
    {
      title: '运行',
      num: 5,
      color: '#fff',
      bgc: '#22a092',
    },
    {
      title: '检修',
      num: 0,
      color: '#000',
      bgc: '#ffb980',
    },
    {
      title: '故障',
      num: 0,
      bgc: '#d0648a',
    },
    {
      title: '待机',
      num: 0,
      color: '#000',
      bgc: '#ebdba4',
    },
    {
      title: '离线',
      num: 0,
      color: '#000',
      bgc: '#cccccc',
    },
  ];

  winds = [
    {
      station: '大连',
      area: [
        {
          name: '#1',
          item: [
            {
              item1: 'LNDL-L1-F001',
              item2: '运行',
              item3: '472',
              item4: '5472',
              type: 'normal',
            },
            {
              item1: 'LNDL-L1-F002',
              item2: '运行',
              item3: '472',
              item4: '5472',
              type: 'normal',
            },
            {
              item1: 'LNDL-L1-F003',
              item2: '运行',
              item3: '472',
              item4: '5472',
              type: 'normal',
            },
            {
              item1: 'LNDL-L1-F004',
              item2: '运行',
              item3: '472',
              item4: '5472',
              type: 'normal',
            },
            {
              item1: 'LNDL-L1-F005',
              item2: '运行',
              item3: '472',
              item4: '5472',
              type: 'normal',
            },
          ],
        },
      ],
    },
  ];

  type = 'matrix';

  public pager = { page: 0, size: 50, totalRecords: 0 };

  tableTile = '日等效时数';
  toggle = true;
  options: any;
  scrollHeight: any;

  defaultSort;
  defaultSortOrder;
  firstSort: any;
  querySortParams: { sortBy: string; sortOrder: string };

  public columns = [
    {
      header: '位置',
      field: 'location',
    },
    {
      header: '馈线',
      field: 'line',
    },
    {
      header: '设备编号',
      field: 'no',
    },
    {
      header: '型号',
      field: 'type',
    },
    {
      header: '风机状态',
      field: 'status',
    },
    {
      header: '日发电量 kWh',
      field: 'daypower',
    },
    {
      header: '日等效时数 h',
      field: 'dayhour',
    },
    {
      header: '实时功率 KW',
      field: 'energy',
    },
    {
      header: '实时风速 m/s',
      field: 'speed',
    },
  ];

  public dataList = [
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070205ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070203ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-NB301',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070201ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070106ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-NB401',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070104ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-NB102',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070204ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070101ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070103ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-NB201',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
    {
      location: '线路1#',
      line: '1#',
      no: 'QDSS-070102ZN',
      type: 'ZGS11-M-500kVA',
      status: '运行',
      daypower: '5225.22',
      dayhour: '5225.22',
      energy: '5225.22',
      speed: '5225.22',
    },
  ];
  canlendarLocale: any;
  queryForm: any;

  constructor(
    private commonservice: CommonService,
    private calendarLocales: CalendarLocales,
    private fb: FormBuilder,
    private activeRoute: ActivatedRoute
  ) {
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.activeRoute.url.subscribe((res) => {
      if (res[0].path === 'deviceTable') {
        this.type = 'list';
      } else {
        this.type = 'matrix';
      }
    });
  }

  ngOnInit() {
    this.options = options;
    this.setScrollHeight();
    setTimeout(() => {
      this.dataList = [...this.dataList];
      this.pager.totalRecords = 13;
    }, 1);
    this.initForm();
  }

  initForm() {
    this.queryForm = this.fb.group({
      location: new FormControl(null),
      type: new FormControl(null),
      status: new FormControl(null),
      line: new FormControl(null),
    });
  }

  /**
   * 设置表格滚动条
   * setScrollHeight
   */

  public setScrollHeight() {
    let height: number = 50 + 50 + 57;
    this.scrollHeight = this.commonservice.setTableScrollHeight(height);
  }

  onPageChange() {}

  /**
   * customSort
   */
  public customSort(event: LazyLoadEvent) {
    // console.log(event);
    if (this.firstSort) {
      this.firstSort = false;
      return;
    }

    this.querySortParams = {
      sortBy: event.sortField,
      sortOrder: event.sortOrder === 1 ? 'asc' : 'desc',
    };
    // this.getDeviceStatusDatas();
    // if (event.sortField !== 'stationName') {
    //   this.toggle = true;
    //   this.getDeviceStatusCharts();
    // }
  }

  queryList(flag) {}

  export() {}
}
