import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { Observable, fromEvent } from 'rxjs';
import * as _ from 'lodash';

import { CommonService } from '../../../../../common/services/common.service';
import { ModelService } from '../model.service';
import { AssetsBasicInfoService } from '../../basic-info.service';
import { Model } from '../../../shared/index.model';
import { ClassesType } from '../../../../../common/models/classes-type.model';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'mpt-model-info',
  templateUrl: './model-info.component.html',
  styles: [
    `
      :host /deep/ p-datatable .grid-table th .ui-column-title {
        display: inline-block;
        width: calc(100% - 2px);
        border-left: 1px solid transparent;
        border-right: 1px solid #ddd;
      }
      :host /deep/ .container-content {
        // min-height: calc(100vh - 400px) !important;
      }
      .class-cate {
        line-height: 18px;
        ::ng-deep .ui-inputtext {
          height: 30px !important;
        }
      }
    `,
  ],
})
export class ModelInfoComponent implements OnInit, OnDestroy {
  @Input() public isEdit: boolean = true; // 是否可编辑
  @Input() public isRadio: boolean = true;
  @Input() public deviceTypes: any = null;
  @Input() public isSelector: boolean = false;
  @Output() public rowSelested = new EventEmitter();
  public isResetParam: boolean = false;
  public isDelete: boolean = false;
  public isShowView: boolean = false;
  public isShowEdit: boolean = false;
  public scrollHeight: string;
  public resizeEvent: any;

  public queryParam = {};
  public selectedmodel: Model;
  public modelId: string;
  public model: Model;
  public classesType = ClassesType.deviceType;
  public modelNo = null;
  public companyName = { companyName: null, companyId: null };
  public companys = [];
  public gridData: Model[] = [];
  public pager = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };
  type: string;

  constructor(
    private common: CommonService,
    private modelService: ModelService,
    private baseInfoService: AssetsBasicInfoService
  ) {
    this.modelService.isSubmitModel.subscribe((flag) => {
      if (flag) {
        this.queryList(true);
      }
    });
    if (this.isSelector) {
      this.resetParam();
    }
    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setScrollHeight();
      });
  }

  public ngOnInit() {
    if (this.isSelector) {
      this.pager.pageSize = 10;
      this.scrollHeight = '360px';
    } else {
      this.setScrollHeight();
    }
    this.queryList();
  }

  // 设置表格滚动条高度
  public setScrollHeight() {
    // this.scrollHeight = document.body.clientHeight - 170 - 60 - 85 + 'px';
    this.scrollHeight = this.common.setTableScrollHeight(44 + 50 + 29 + 40);
  }

  public ngOnDestroy() {
    this.resizeEvent.unsubscribe();
  }

  public getCompanys(e) {
    this.isResetParam = false;
    this.baseInfoService.getCompanyByName(e.query).then((res) => {
      this.companys = res;
    });
  }

  public getClassIds(val) {
    this.isResetParam = false;
    this.queryParam = _.assign({}, this.queryParam, {
      classIds: val,
    });
  }

  public queryList(isQuery?: boolean) {
    if (isQuery) {
      this.pager.pageCurrent = 0;
    }
    this.queryParam = _.assign({}, this.queryParam, {
      deviceTypes: this.deviceTypes,
      modelNo: this.modelNo,
      companyName: _.isObject(this.companyName)
        ? this.companyName.companyName
        : this.companyName,
      page: this.pager.pageCurrent,
      size: this.pager.pageSize,
    });

    this.common.mask.show();
    this.modelService
      .getModels(this.queryParam)
      .then((res: any) => {
        this.pager.totalRecords = res.totalElements;
        this.gridData = res.content;
        this.common.mask.hide();
      })
      .catch((error) => {
        this.common.errorMessage(error);
      });
  }

  public onPageChange(event) {
    this.pager.pageCurrent = event.page;
    this.pager.pageSize = parseFloat(event.rows);
    this.queryList();
  }

  public resetParam() {
    this.isResetParam = true;
    this.modelNo = null;
    this.companyName = { companyName: null, companyId: null };
    this.queryParam = _.assign({}, this.queryParam, {
      classIds: [],
    });
    this.queryList(true);
  }

  public deletemodel(id) {
    this.modelId = id;
    this.isDelete = true;
  }

  public onCancelDelete() {
    this.isDelete = false;
  }

  public onDelete() {
    this.modelService
      .deleteModel(this.modelId)
      .then((res) => {
        this.isDelete = false;
        this.queryList(true);
      })
      .catch((error) => {
        this.isDelete = false;
        this.common.errorMessage(error);
      });
  }

  public showEdit(model?: Model) {
    this.type = model ? 'edit' : 'add';
    this.model = model ? model : null;
    this.isShowEdit = true;
  }

  public showView(model) {
    this.type = 'view';
    this.model = model;
    this.isShowEdit = true;
  }

  public rowSelect(data) {
    this.rowSelested.emit(this.selectedmodel);
  }
}
