import { CacheService } from './../../common/services/cache.service';
import { Router } from '@angular/router';
import { CommonService } from './../../common/services/common.service';
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthService } from '../auth.service';
import { TipService } from '@common/services/tip.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private common: CommonService,
    private router: Router,
    private cacheService: CacheService,
    private tipservice: TipService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        const errorInfo =
          typeof err.error === 'object' ? err.error : JSON.parse(err.error);
        const error = errorInfo.message || err.statusText;
        if (err.status === 401 && request.url.indexOf('/api/auth') === -1) {
          // auto logout if 401 response returned from api
          this.authService.logout();

          this.router.navigate(['/login']);

          return throwError(error);
        } else if (
          err.status === 401 &&
          request.url.indexOf('/api/auth') !== -1
        ) {
          this.tipservice.tip('error', '用户名或者密码错误');
          return throwError(error);
        } else if (
          errorInfo.exception === 'com.cie.nems.common.exception.NemsException'
        ) {
          this.tipservice.tip('error', errorInfo.message);
          return throwError(error);
        } else if (
          errorInfo.exception ===
          'com.cie.nems.common.exception.SessionTimeoutException'
        ) {
          this.authService.logout();

          this.router.navigate(['/login']);

          return throwError(error);
        }
        this.tipservice.tip('error', '系统错误，请联系管理员！');
        return throwError(error);
      })
    );
  }
}
