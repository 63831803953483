import {
  Component, OnInit, Input, Output,
  EventEmitter, ChangeDetectionStrategy
} from '@angular/core';

import { Notify, NotifyLevel } from '../header.model';

@Component({
  selector: 'mpt-notify',
  templateUrl: 'notify.component.html',
  styleUrls: ['./notify.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NotifyComponent implements OnInit {

  @Input() public notify: Notify;
  @Output() public onExpired = new EventEmitter();

  public defaultDuration: number = 8000;

  public ngOnInit() {
    this.timer();
  }

  public getIconPath() {
    return `/assets/notify/${this.notify.level}.png`;
  }

  private timer() {
    let interval = setInterval(() => {
      this.defaultDuration -= 1000;
      if (this.defaultDuration <= 0) {
        clearInterval(interval);
        this.onExpired.emit(true);
      }
    }, 1000);
  }

  private close() {
    this.onExpired.emit(true);
  }

}
