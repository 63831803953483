class BaseEvent {
  handles: any;
  cached: any;
  constructor() {
    this.handles = {};
    this.cached = [];
  }

  on(eventName, callback) {
    if (typeof callback !== 'function') return;

    if (!this.handles[eventName]) {
      this.handles[eventName] = [];
    }
    this.handles[eventName].push(callback);

    if (this.cached[eventName] instanceof Array) {
      callback.apply(null, this.cached[eventName]);
    }
  }

  emit(eventName, ...args) {
    if (this.handles[eventName] instanceof Array) {
      for (let i = 0; i < this.handles[eventName].length; i++) {
        this.handles[eventName][i](...args);
      }
    }
    this.cached[eventName] = args;
  }
}

export default BaseEvent;
