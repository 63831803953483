import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  SimpleChanges,
  EventEmitter
} from "@angular/core";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { SelectItem } from "primeng/primeng";
import * as _ from "lodash";

import { CommonService } from "../../../services/common.service";
import { PointSelectorService } from "../point-selector.service";
import { DevicePoint } from "../point-selector.model";
import { CodeTableService } from "../../../../core/code-table.service";
import { CodeTable } from "../../../models/codeTable.model";
import { ObjType } from "../../relation-point-selector/point-selector.model";
import { TipService } from "@common/services/tip.service";

@Component({
  selector: "mpt-device-point",
  templateUrl: "device-point.component.html",
  styleUrls: ["../point-selector.component.less"]
})
export class DevicePointComponent implements OnInit, OnChanges {
  // @Input() public ruleId: number;
  @Input() public param;
  @Input() public isRadio: boolean;
  @Output() public isOpenPointSelector = new EventEmitter<boolean>();
  @Output() public submitDataEvent = new EventEmitter<DevicePoint[]>();

  public loading: boolean = false;
  public queryForm: FormGroup;
  public isSubmitAllData: boolean = false;

  public stations: SelectItem[] = []; // 电站
  public stationArea: SelectItem[] = []; // 电站区域
  public deviceModel: SelectItem[] = []; // 设备
  public remotionTypes: SelectItem[] = []; // 四遥类型
  public pointNames: any[] = [];
  public deviceNos: any[] = [];

  public gridData: DevicePoint[] = []; // 表格数据
  public selectedData: DevicePoint[] = []; // 已选择数据
  public pager = { pageCurrent: 0, totalRecords: 0, pageSize: 10 };

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private codeTableService: CodeTableService,
    private pointSelectorService: PointSelectorService,
    private tipservice: TipService
  ) { }

  public ngOnInit() {
    // form初始化
    this.formInit();
    // 表格初始化
    // this.queryList();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.param) {
      if (changes.param.currentValue.objType === ObjType.device) {
        if (!_.isEmpty(changes.param.currentValue.stationIds)) {
          this.getStationArea({ value: this.param["stationIds"] });
        }
        if (!_.isEmpty(changes.param.currentValue)) {
          // this.queryList(true);
        }
      }
    }
  }

  // form初始化
  public formInit() {
    this.queryForm = this.fb.group({
      stationIds: new FormControl(),
      areaIds: new FormControl(),
      modelIds: new FormControl(),
      deviceNo: new FormControl(),
      remotionTypes: new FormControl(),
      pointName: new FormControl()
    });

    this.dropdownInit();
  }

  // 下拉框初始化
  public dropdownInit() {
    this.stations = [];
    this.remotionTypes = [];

    // 电站
    this.pointSelectorService.getStations().subscribe((data: any) => {
      this.stations = data.map(val => {
        return { label: val.shortName, value: val.stationId };
      });
    });

    if (this.param["stationId"]) {
      this.queryForm.patchValue({
        stationIds: [this.param["stationId"]]
      });
    }

    // 四遥类型
    this.codeTableService
      .getNemsSelectItems(CodeTable.pointRemotionType)
      .then(data => {
        this.remotionTypes = data;
      });
  }

  // 测点名称
  public getPointName(data) {
    let formValue = this.queryForm.value;
    let param = _.assign({}, this.queryForm.value, {
      objType: this.param["objType"],
      cateName: data.query,
      pointName: null
    });
    if (this.param["stationIds"]) {
      param = _.assign({}, param, {
        stationIds: this.param["stationIds"]
      });
    }
    this.pointSelectorService.getCategories(param).subscribe((res: any) => {
      this.pointNames = res;
    });
  }

  public getdeviceNos(data) {
    let formValue = this.queryForm.value;
    let param = _.assign(
      {},
      {
        stationIds: formValue.stationIds,
        areaIds: formValue.areaIds,
        modelIds: formValue.modelIds,
        deviceNo: data.query
      }
    );
    if (this.param["stationIds"]) {
      param = _.assign({}, param, {
        stationIds: this.param["stationIds"]
      });
    }
    this.pointSelectorService.getdeviceNos(param).subscribe((res: any) => {
      this.deviceNos = res;
    });
  }

  // 根据电站 获取电站区域
  public getStationArea(event) {
    if (_.isEmpty(event.value)) {
      this.tipservice.tip("warn", "测点列表请先选择电站！");
      this.stationArea = [];
      this.deviceModel = [];
      this.queryForm.patchValue({ areaIds: [], modelIds: [] });
      return false;
    }
    let deviceVal = this.queryForm.value;
    deviceVal["areaIds"] = [];
    deviceVal["modelIds"] = [];
    this.queryForm.patchValue(deviceVal);

    this.pointSelectorService.getStationAreas(event.value).subscribe(
      (res: any) => {
        this.stationArea = [];
        this.stationArea = res.map(val => {
          return { label: val.areaName, value: val.areaId };
        });
        // 获取设备型号
        this.getDeviceModel({ value: null });
      },
      error => {
        // this.common.errorMessage(error);
      }
    );
  }

  // 根据电站区域获取设备型号
  public getDeviceModel(event) {
    let param = this.queryForm.value;
    if (_.isEmpty(event.value)) {
      param["modelIds"] = [];
      this.queryForm.patchValue(param);
    }
    let map = {
      stationIds: _.concat([], param.stationIds),
      areaIds: event.value
    };
    if (this.param["stationIds"]) {
      map = _.assign({}, map, {
        stationIds: this.param["stationIds"]
      });
    }
    this.pointSelectorService.getModels(map).subscribe((res: any) => {
      let modelIdArr = [];
      this.deviceModel = [];
      this.deviceModel = res.map(val => {
        modelIdArr.push(val.modelId);
        return { label: val.modelName, value: val.modelId };
      });
      // 联动 设置
      if (_.isArray(param.modelIds)) {
        for (let val of param.modelIds) {
          if (_.indexOf(modelIdArr, val) < 0) {
            param["modelIds"] = _.without(param.modelIds, val);
          }
        }
      }
      this.queryForm.patchValue(param);
    });
  }

  // 获取查询参数
  public getParam() {
    let params = this.queryForm.value;
    params.deviceNo = _.isObject(params.deviceNo)
      ? params.deviceNo.deviceNo
      : params.deviceNo;
    params.pointName = _.isObject(params.pointName)
      ? params.pointName.cateName
      : params.pointName;
    return _.assign({}, params, this.param, {
      page: this.pager.pageCurrent,
      size: this.pager.pageSize
    });
  }

  // 表格查询
  public queryList(isQuery?: boolean) {
    this.loading = true;
    let param = this.getParam();
    if (isQuery) {
      this.pager.pageCurrent = 0;
    }
    if (_.isEmpty(param.stationIds)) {
      this.tipservice.tip("warn", "测点列表请先选择电站！");
      this.loading = false;
      return false;
    }
    this.pointSelectorService.getPointList(param).subscribe(
      (res: any) => {
        this.pager.totalRecords = res.totalElements;
        this.gridData = res.content;
        this.loading = false;

        setTimeout(() => {
          document.getElementById("device").click();
        }, 0);
      },
      error => {
        this.common.errorMessage(error);
        this.loading = false;
      }
    );
  }

  public click() {
    // console.log('query');
  }

  // 分页事件
  public onPageChange(event) {
    this.pager.pageCurrent = event.page;
    this.pager.pageSize = parseFloat(event.rows);
    this.queryList();
  }

  // 提交
  public submitData() {
    let data = this.isRadio
      ? [].concat(this.selectedData)
      : this.selectedData;
    if (data.length === 0) {
      this.tipservice.tip("warn", "请最少选择一条数据！");
      return false;
    }
    if (!this.isRadio) {
      this.pointSelectorService
        .addPoints(this.submitDataProcessing(data))
        .subscribe(res => { });
    }

    this.submitDataEvent.emit(this.selectedData);
    this.isOpenPointSelector.emit(false);
  }

  // 选择全部
  public chooseAllData() {
    if (this.pager.totalRecords === 0) {
      this.tipservice.tip("warn", "当前没有数据可选择！");
      return false;
    }
    // 获取全部数据接口
    this.pointSelectorService
      .getDevicePointListAll(this.getParam())
      .subscribe(
        (res: any) => {
          this.selectedData = res.content;
          this.isSubmitAllData = true;
        },
        error => this.common.errorMessage(error)
      );
  }

  // 提交 全部
  public submitAllData() {
    this.pointSelectorService
      .addPoints(this.submitDataProcessing(this.selectedData))
      .subscribe(res => {
        // 添加成功
        this.isSubmitAllData = false;
        this.submitDataEvent.emit(this.selectedData);
        this.isOpenPointSelector.emit(false);
      });
  }

  // 提交数据处理
  public submitDataProcessing(data: DevicePoint[]) {
    let listData = [];
    for (let val of data) {
      listData.push({
        stationId: val.stationId,
        areaId: val.areaId,
        deviceId: val.deviceId,
        psrId: val.psrId,
        pointId: val.pointId,
        ruleId: this.param["busId"]
      });
    }
    return listData;
  }

  // 取消选择全部
  public onCancelAllData() {
    this.isSubmitAllData = false;
    this.selectedData = [];
  }

  // 取消
  public cancelData() {
    this.isOpenPointSelector.emit(false);
  }
}
