import * as _ from 'lodash';

export const weatherForecastOption = {};
export function buildWeatherForecastChart(data) {
    // let option = _.cloneDeep(weatherForecastOption);
    // option.legend.data = data.legend.data;
    // option.xAxis.data = data.xAxis.data;
    // option.series = data.series;

    var initData = [
        /*{date: '09/03', tfloor: 20, tceil: 28, wind: 4, radiation: 5, text: '晴', icon: '100.png'},
{date: '09/04', tfloor: 21, tceil: 31, wind: 2, radiation: 4, text: '多云', icon: '多云.png'},*/
        { date: '09/05', tfloor: 24, tceil: 34, wind: 5, radiation: 5, text: '晴', icon: '100.png' },
        { date: '09/06', tfloor: 21, tceil: 31, wind: 1, radiation: 4, text: '多云', icon: '多云.png' },
        { date: '09/07', tfloor: 24, tceil: 34, wind: 3, radiation: 3, text: '阴', icon: '阴.png' },
        { date: '09/08', tfloor: 24, tceil: 34, wind: 3, radiation: 4, text: '多云', icon: '多云.png' },
        { date: '09/09', tfloor: 22, tceil: 30, wind: 3, radiation: 3, text: '阴', icon: '阴.png' },
        { date: '09/10', tfloor: 20, tceil: 32, wind: 3, radiation: 5, text: '晴', icon: '100.png' },
        { date: '09/11', tfloor: 21, tceil: 34, wind: 3, radiation: null, text: '晴', icon: '100.png' },
        { date: '09/12', tfloor: 22, tceil: 30, wind: 2, radiation: null, text: '阴', icon: '阴.png' },
        { date: '09/13', tfloor: 22, tceil: 29, wind: 2, radiation: null, text: '雨', icon: '雨.png' },
        { date: '09/14', tfloor: 22, tceil: 29, wind: 3, radiation: null, text: '多云', icon: '多云.png' },
        { date: '09/15', tfloor: 20, tceil: 32, wind: 1, radiation: null, text: '阴', icon: '阴.png' },
        /*{date: '09/16', tfloor: 20, tceil: 29, wind: 5, radiation: null, text: '多云', icon: '多云.png'},
{date: '09/17', tfloor: 22, tceil: 28, wind: 2, radiation: null, text: '多云', icon: '多云.png'},*/
    ];

    var datas = [[], [], []];
    var xAxisDatas = [];
    var maxy = 0;
    var marks = [];
    var isToday = false;
    for (var i in initData) {
        var d = initData[i];
        isToday = d.date == '09/10';
        xAxisDatas.push(d.date);
        datas[0].push(d.tfloor);
        datas[1].push(d.tceil);
        datas[2].push(d.wind);
        if (maxy < d.tceil) maxy = d.tceil;
        marks.push({
            name: '天气',
            value: isToday ? '22℃\n' + d.text : d.text,
            xAxis: d.date,
            yAxis: d.tceil,
            symbolSize: isToday ? 100 : 50,
            //symbolOffset: [0, '-100%'],
            //symbol: 'image://images/'+d.icon,
            itemStyle: {
                color: isToday ? '#FB7293' : null,
            },
            label: {
                show: true,
                fontSize: isToday ? 18 : 12,
                //	color: '#727272',
                //	offset: [0, '5%'],
            },
        });
    }
    maxy += 5;

    // 指定图表的配置项和数据
    var option = {
        color: ['#FFDB5C', '#FF9F7F', '#32C5E9'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        tooltip: {
            trigger: 'axis',
        },
        legend: {
            bottom: '5%',
            data: ['最低温', '最高温', '风速'],
        },
        grid: {
            left: '5%',
            right: '5%',
            containLabel: true,
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            splitLine: {
                //show: false
            },
            data: xAxisDatas,
        },
        yAxis: [
            {
                //show: false,
                type: 'value',
                scale: true,
                name: '℃',
                max: maxy,
                splitLine: {
                    show: false,
                },
            },
            {
                //show: false,
                type: 'value',
                scale: true,
                name: 'm/s',
                splitLine: {
                    show: false,
                },
            },
        ],
        dataZoom: [
            {
                type: 'inside',
                start: 0,
                end: 100,
            },
        ],
        series: [
            {
                name: '最低温',
                type: 'line',
                smooth: true,
                label: {
                    show: false,
                    position: 'bottom',
                    color: '#727272',
                    formatter: '{c}℃',
                },
                data: datas[0],
            },
            {
                name: '最高温',
                type: 'line',
                smooth: true,
                label: {
                    show: false,
                    position: 'bottom',
                    color: '#727272',
                    formatter: '{c}℃',
                },
                data: datas[1],
                markPoint: {
                    data: marks,
                },
            },
            {
                name: '风速',
                type: 'line',
                smooth: true,
                yAxisIndex: 1,
                label: {
                    show: false,
                    position: 'bottom',
                    color: '#727272',
                    formatter: '{c}m/s',
                },
                data: datas[2],
            } /*, {
        name:'日辐照量',
        type:'line',
        smooth: true,
        yAxisIndex: 1,
        data: datas[2],
        symbol: 'none',
        sampling: 'average',
        lineStyle: {
        	width: 0.5,
        },
        areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                offset: 0,
                color: 'rgba(255, 219, 92, 1)'
            }, {
                offset: 1,
                color: 'rgba(255, 255, 255, 0)'
            }])
        },
    }*/,
        ],
    };

    return option;
}
