import * as echarts from 'echarts';

declare const BMap: any;
declare const BMAP_NORMAL_MAP: any;
declare const BMAP_HYBRID_MAP: any;
declare const BMAP_ANCHOR_BOTTOM_RIGHT: any;

export function buildMapTopologyChart(returnValue, index = 0) {
  const myChart: any = echarts.init(
    document.getElementById('mapTopology') as any,
    'light'
  );

  // 计算右上角和左下角坐标，用于自动控制地图显示范围
  let minX;
  let maxX;
  let minY;
  let maxY;
  function setBounds(latitude, longitude) {
    if (minY === undefined || minY > latitude) {
      minY = latitude;
    }
    if (maxY === undefined || maxY < latitude) {
      maxY = latitude;
    }
    if (minX === undefined || minX > longitude) {
      minX = longitude;
    }
    if (maxX === undefined || maxX < longitude) {
      maxX = longitude;
    }
  }
  let d = null;
  for (const key in returnValue.devices) {
    if (returnValue.devices.hasOwnProperty(key)) {
      d = returnValue.devices[key];
      setBounds(d.latitude, d.longitude);
    }
  }

  let minValue;
  let maxValue;
  // 根据数据类型下拉框的选项，用接口返回的数据组装echarts需要的数据
  function createDevices(returnValues, data) {
    // returnValue: 接口返回值对象
    // data: 下拉框选择的数据类型

    function createDevice(device, currDeviceType, deviceType, column, name, d) {
      const value = currDeviceType === deviceType ? d[column] : null;
      const obj: any = {
        id: d.deviceId,
        name: d.deviceName,
        value: [d.longitude, d.latitude, value],
        device: d,
      };
      if (currDeviceType === deviceType) {
        // 要查看数据的设备类型需要标记通讯中断和告警
        obj.dataName = name;
        if (d.commuStatus != null && d.commuStatus !== '0') {
          device[deviceType].offline.push(obj);
        } else if (d.realAlarms != null && d.realAlarms > 0) {
          device[deviceType].alarm.push(obj);
        } else {
          device[deviceType].all.push(obj);
        }
        if (value != null) {
          if (minValue === undefined || minValue > value) {
            minValue = value;
          }
          if (maxValue === undefined || maxValue < value) {
            maxValue = value;
          }
        }
      } else {
        device[deviceType].all.push(obj);
      }
    }

    const devices = {
      ZC: { all: [], alarm: [], offline: [] },
      NB: { all: [], alarm: [], offline: [] },
      BYQ: { all: [], alarm: [], offline: [] },
    };

    const dataToken = data.id.split('.');
    const dataDeviceType = dataToken[0];
    const dataColumn = dataToken[1];
    const dataName = data.name.split('-')[1];

    for (const i in returnValues.devices) {
      if (returnValues.devices.hasOwnProperty(i)) {
        d = returnValues.devices[i];

        if ('NB' === d.deviceType || 'JNB' === d.deviceType) {
          createDevice(devices, 'NB', dataDeviceType, dataColumn, dataName, d);
        } else if ('BYQ' === d.deviceType) {
          createDevice(devices, 'BYQ', dataDeviceType, dataColumn, dataName, d);
        } else if ('ZC' === d.deviceType) {
          createDevice(devices, 'ZC', dataDeviceType, dataColumn, dataName, d);
        }
      }
    }

    function createNormalSerie(series, deviceList, name) {
      if (deviceList == null || deviceList.length === 0) {
        return;
      }

      const isZc = deviceList[0].device.deviceType === 'ZC';

      series.push({
        name,
        type: 'scatter',
        coordinateSystem: 'bmap',
        large: true,
        data: deviceList,
        symbol: isZc ? 'path://M 0 0 L 30 0 L 30 5 L 0 5 Z' : 'rect',
        symbolKeepAspect: true,
        symbolSize: isZc ? 30 : 10,
        label: { show: false },
      });
    }
    function createAlarmSerie(series, deviceList, name, color, zLevel) {
      if (deviceList == null || deviceList.length === 0) {
        return;
      }

      const isZc = deviceList[0].device.deviceType === 'ZC';

      series.push({
        name,
        type: 'effectScatter',
        coordinateSystem: 'bmap',
        large: true,
        data: devices.NB.alarm,
        symbol: 'rect',
        symbolSize: 10,
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke',
        },
        hoverAnimation: true,
        itemStyle: {
          normal: {
            color,
            shadowBlur: 10,
            shadowColor: color,
          },
        },
        zlevel: zLevel,
      });
    }

    const seriesValue = [];
    createNormalSerie(seriesValue, devices.ZC.all, '组串');
    createNormalSerie(seriesValue, devices.NB.all, '逆变器');
    createNormalSerie(seriesValue, devices.BYQ.all, '箱变');

    createAlarmSerie(seriesValue, devices.ZC.alarm, '组串', '#FB7293', 1);
    createAlarmSerie(seriesValue, devices.NB.alarm, '逆变器', '#FB7293', 1);
    createAlarmSerie(seriesValue, devices.BYQ.alarm, '箱变', '#FB7293', 1);

    createAlarmSerie(seriesValue, devices.ZC.offline, '组串', '#999999', 2);
    createAlarmSerie(seriesValue, devices.NB.offline, '逆变器', '#999999', 2);
    createAlarmSerie(seriesValue, devices.BYQ.offline, '箱变', '#999999', 2);

    return seriesValue;
  }

  const series = createDevices(returnValue, returnValue.datas[index]);
  const option = {
    tooltip: {
      trigger: 'item',
      formatter: (param) => {
        let tip =
          '设备名称: ' +
          param.data.name +
          '</br>' +
          '设备类型: ' +
          param.data.device.className +
          '</br>' +
          '设备位置: ' +
          param.data.device.areaName +
          '</br>';
        if (param.data.dataName !== undefined) {
          tip += param.data.dataName + ': ' + param.data.value[2] + '</br>';
        }
        tip +=
          '通讯状态: ' +
          (param.data.device.commuStatus === '0' ? '在线' : '离线') +
          '</br>';
        tip +=
          '实时告警: ' +
          (param.data.device.realAlarms == null
            ? 0
            : param.data.device.realAlarms) +
          '</br>';
        return tip;
      },
    },
    bmap: {
      center: [(minX + maxX) / 2, (minY + maxY) / 2],
      zoom: 5,
      roam: true,
    },
    legend: {
      data: returnValue.legends,
    },
    visualMap: [
      {
        type: 'continuous',
        min: minValue,
        max: maxValue,
        inRange: {
          color: ['#FFFF99', '#CC3366'],
        },
        itemWidth: 8,
        itemHeight: 100,
        left: '5%',
        bottom: '5%',
        text: ['高', '低'], // 文本，默认为数值文本
        calculable: true,
        precision: 0,
        seriesIndex: [1],
      },
    ],
    series,
  };

  // 使用刚指定的配置项和数据显示图表。
  myChart.setOption(JSON.parse(JSON.stringify(option)), true);

  const map = myChart.getModel().getComponent('bmap').getBMap();

  map.addControl(new BMap.MapTypeControl());

  map.addControl(new BMap.ScaleControl({ anchor: BMAP_ANCHOR_BOTTOM_RIGHT })); // 右下角，添加比例尺

  map.enableScrollWheelZoom();

  const view = map.getViewport([
    new BMap.Point(minX, minY),
    new BMap.Point(maxX, maxY),
  ]);
  const mapZoom = view.zoom;
  const centerPoint = view.center;
  console.log(centerPoint);

  map.centerAndZoom(centerPoint, mapZoom);
  return myChart;
}
