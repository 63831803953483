import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';

import { TreeviewI18n, DefaultTreeviewI18n, TreeviewItem, TreeviewConfig } from '../treeview/index';
import { DeptSelectorService } from './dept-selector.service';

@Component({
  selector: 'mpt-dept-selector',
  templateUrl: './dept-selector.component.html',
  providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }],
})
export class DeptSelectorComponent implements OnInit, OnChanges {
  @Input() public isReset: boolean = false;
  @Input() public isRadio: boolean = false;
  @Input() public value: string = ''; // 单选初始化值
  @Input() public values: string[] = []; // 多选初始化值
  @Output() public getSelectedVal = new EventEmitter<string>();

  public treeData: TreeviewItem[] = [];
  public items: TreeviewItem[] = [];
  public mulitConfig = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 200,
  });

  constructor(private deptService: DeptSelectorService) {}

  public ngOnInit() {
    this.deptService.getDepts().subscribe((res: any) => {
      this.treeData = res;
      this.items = this.deptService.filterDeptData('deptName', 'deptId', this.treeData);
    });
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.isReset) {
      this.values = [];
    }
    this.items = this.deptService.filterDeptData('deptName', 'deptId', this.treeData);
  }

  public onSelectedChange(event) {
    this.getSelectedVal.emit(event);
  }
}
