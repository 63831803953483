import { TeamService } from './../../team.service';
import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { ObjType } from 'src/app/common/models/codeItems.model';

@Component({
  selector: 'app-team-selector',
  templateUrl: './team-selector.component.html',
  styleUrls: ['./team-selector.component.less'],
})
export class TeamSelectorComponent implements OnInit {
  @Input() public isShowInput: boolean;
  @Input() public isShow = false;
  @Input() public disabled = false;
  @Input() public modal = true;
  @Input() public isRadio = true;
  @Input() public selectedTeam: any;
  @Output() public selectedData = new EventEmitter<any>();
  @Output() public showEvent = new EventEmitter<any>();
  isShowDept: boolean;
  departments: any = [];
  public pager = {
    page: 0,
    size: 50,
    totalElements: 0,
  };
  queryParams: any = {
    name: null,
  };
  selectedDepart: any[];
  dataList: any;
  teamName: string;
  loading: boolean;
  constructor(private teamservice: TeamService) {}

  ngOnInit() {
    this.teamName = this.selectedTeam[0] && this.selectedTeam[0].teamName;
  }

  openDialog() {
    if (this.disabled) {
      return;
    }
    this.isShow = true;
  }

  // 显示选择对象弹出框
  public onShow() {
    this.loadDepartments();
    setTimeout(() => {
      this.isShowDept = true;
    }, 50);
  }
  // 隐藏选择对象弹出框
  public onHide() {
    this.isShow = false;
    this.showEvent.emit(false);
    this.isShowDept = false;
  }

  public queryTeamList(first?) {
    if (first) {
      this.pager.page = 0;
    }
    const params = {
      deptId: this.queryParams.deptId,
      name: this.queryParams.name || null,
    };
    this.teamservice.getTeamList(params).subscribe(
      (res: any) => {
        if (res.content) {
          this.dataList = res.content || [];
          this.pager.totalElements = res.totalElements;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.loading = false;
      },
    );
  }

  public teamSubmit() {
    this.teamName = this.selectedTeam.teamName;
    this.selectedData.emit(this.selectedTeam);
    this.onHide();
  }

  public reset() {
    this.selectedTeam = null;
  }

  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryTeamList();
  }

  // 获取部门数据
  private loadDepartments() {
    if (this.departments.length) {
      this.setDepartments();
      this.selectDepartment();
    } else {
      this.teamservice.getDepartments().subscribe((departments) => {
        const departJsonList: TreeNode = departments;
        this.departments = departJsonList;
        this.setDepartments();
        this.selectDepartment();
      });
    }
  }

  // 选择部门
  public selectDepartment(event?) {
    if (event && event.node) {
      this.selectedDepart = [event.node];
    }
    this.pager.page = 0;
    this.queryParams.deptId = this.selectedDepart[0].data.deptId;
    this.queryParams.name = '';
    this.queryTeamList();
  }

  public unSelectDepartment(event) {
    this.pager.page = 0;
    this.queryParams.name = '';
    this.queryParams.deptId = null;
    this.queryTeamList();
  }

  private setDepartments() {
    this.selectedDepart = [this.departments[0]];
    this.convertToTree(this.departments);
    this.queryParams.deptId = this.departments[0].data;
  }

  // 遍历部门树数据、数据转换
  private convertToTree(departments: TreeNode[]) {
    for (const department of departments) {
      department.label = department.data.deptName;
      if (department.data.deptType === ObjType.department) {
        department.expanded = true;
      }
      if (department.children && department.children.length) {
        department.expanded = true;
        this.convertToTree(department.children);
      } else {
        department.leaf = true;
      }
    }
  }
}
