import { Injectable } from '@angular/core';
import { Message } from 'primeng/api';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TipService {

  public msgs: Message[] = [];

  public tipContentSource = new Subject<Message[]>();
  public tipContent = this.tipContentSource.asObservable();

  constructor() { }


  // tip提示
  public tip(type: string, detail: string, otherMessage?: string) {
    let message = '';
    switch (type) {
      case 'success':
        message = '成功提示';
        break;
      case 'info':
        message = '信息提示';
        break;
      case 'warn':
        message = '警告提示';
        break;
      case 'error':
        message = '错误提示';
        break;
      default:
        break;
    }
    if (otherMessage) {
      message = otherMessage;
    }
    this.msgs = [];
    this.msgs.push({
      severity: type,
      summary: message,
      detail: detail ? detail : '',
    });
    this.tipContentSource.next(this.msgs);
  }

}
