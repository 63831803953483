import { OnChanges, SimpleChanges } from '@angular/core';
import { WidgetsService } from '../../widgets.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-station-device-status',
  templateUrl: './device-status.component.html',
  styleUrls: ['./device-status.component.less'],
})
export class StationDeviceStatusComponent implements OnInit, OnChanges {
  @Input() stationId: string;
  option = {};

  loading = true;

  type = 'fengji';
  fengjiOption: any = {};
  guangfuOption: any = {};

  constructor(private widgetsservice: WidgetsService, private router: Router) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.loading = false;
      this.getDeviceStatus({ stationId: changes.stationId.currentValue });
    }
  }

  selectType(type) {
    this.type = type;
    if (type === 'fengji') {
      this.option = this.fengjiOption;
    } else {
      this.option = this.guangfuOption;
    }
  }

  public getDeviceStatus(params) {
    this.loading = true;
    this.widgetsservice.getDeviceStatus(params).then((res: any) => {
      this.loading = false;
      let data = res;

      const fj = data.filter((item) => item.id === 'fj');
      const nb = data.filter((item) => item.id === 'nb');

      this.fengjiOption = {
        graphic: {
          name: '风机机组',
          elements: [
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/icon-fengdian2.png',
                width: 58,
                height: 65,
              },
              left: 'center',
              top: 'middle',
              z: 100,
            },
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/bg-shebei.png',
                width: 160,
                height: 160,
              },
              left: 'center',
              top: 'middle',
              z: 0,
            },
          ],
        },
        series: [
          {
            type: 'pie',
            name: '风机机组',
            radius: ['52%', '65%'],
            center: ['50%', '50%'],
            color: [
              '#0C559F',
              '#2ABDB6',
              '#AEDAFF',
              '#D09E49',
              '#54AAFF',
              '#0A7EFF',
            ],
            data: fj[0].data,
            label: {
              formatter: '{label|{b}}\n{value|{c}}',
              show: true,
              distanceToLabelLine: 10,
              textStyle: {
                wordBreak: 'break-all',
              },
              rich: {
                label: {
                  fontSize: 14,
                  color: '#B8C6CF',
                },
                value: {
                  fontSize: 14,
                  color: '#37E7FF',
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: 15,
                length2: 15,
                lineStyle: { color: '#ffffff', opacity: 0.45, width: 1 },
              },
            },
          },
        ],
      };
      this.guangfuOption = {
        graphic: {
          name: '光伏逆变器',
          elements: [
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/icon-guangfu2.png',
                width: 58,
                height: 65,
              },
              left: 'center',
              top: 'middle',
              z: 100,
            },
            {
              type: 'image',
              style: {
                image: '/assets/bigScreen/icons/bg-shebei.png',
                width: 160,
                height: 160,
              },
              left: 'center',
              top: 'middle',
              z: 0,
            },
          ],
        },
        series: [
          {
            type: 'pie',
            name: '光伏逆变器',
            radius: ['52%', '65%'],
            center: ['50%', '50%'],
            color: [
              '#0C559F',
              '#2ABDB6',
              '#AEDAFF',
              '#D09E49',
              '#54AAFF',
              '#0A7EFF',
            ],
            data: nb[0].data,
            label: {
              formatter: '{label|{b}}\n{value|{c}}',
              show: true,
              distanceToLabelLine: 10,
              textStyle: {
                wordBreak: 'break-all',
              },
              rich: {
                label: {
                  fontSize: 14,
                  color: '#B8C6CF',
                },
                value: {
                  fontSize: 14,
                  color: '#37E7FF',
                },
              },
            },
            labelLine: {
              normal: {
                show: true,
                length: 15,
                length2: 15,
                lineStyle: { color: '#ffffff', opacity: 0.45, width: 1 },
              },
            },
          },
        ],
      };
      this.option = this.fengjiOption;
    });
  }
}
