import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import * as _ from 'lodash';
import {
  DefaultTreeviewI18n,
  TreeviewConfig,
  TreeviewI18n,
  TreeviewItem,
} from '../../../../common/components/treeview';
import { CommonService } from '../../../../common/services/common.service';
import { DeviceAssetsService } from '../device-assets.service';

@Component({
  selector: 'mpt-warehouse',
  templateUrl: 'warehouse.component.html',
  providers: [{ provide: TreeviewI18n, useClass: DefaultTreeviewI18n }],
})
export class WarehouseComponent implements OnInit, OnChanges {
  @Input() public isReset = false;
  @Input() public isRadio = false;
  @Input() public allWarehouses = true;
  @Input() public value = ''; // 单选初始化值
  @Input() public values: string[] = []; // 多选初始化值
  @Input() public warehousesTree = false; // 多选初始化值
  @Output() public getWarehouseVal = new EventEmitter<string>();
  @Output() public warehouseManagerName = new EventEmitter<any>();

  public treeData: TreeviewItem[] = [];
  public items: TreeviewItem[] = [];
  public mulitConfig = TreeviewConfig.create({
    hasAllCheckBox: true,
    hasFilter: true,
    hasCollapseExpand: false,
    decoupleChildFromParent: false,
    maxHeight: 200,
  });

  constructor(
    private deviceAssetsService: DeviceAssetsService,
    private common: CommonService
  ) {}

  public ngOnInit() {
    if (this.warehousesTree) {
      this.deviceAssetsService.getWarehousesTree().subscribe((res: any) => {
        this.treeData = res;
        this.items = this.filterWarehouseTreeData(
          'warehouseName',
          'warehouseId',
          this.treeData
        );
        this.getWarehouseVal.emit('true');
      });
    } else {
      this.deviceAssetsService
        .getWarehouses(this.allWarehouses)
        .subscribe((res: any) => {
          this.treeData = res;
          this.items = this.common.filterTreeData(
            'warehouseName',
            'warehouseId',
            this.treeData
          );
          this.getWarehouseVal.emit('true');
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (this.isReset) {
      this.values = [];
    }
    if (changes && changes.warehousesTree) {
      if (changes.warehousesTree.currentValue === true) {
        this.items = this.filterWarehouseTreeData(
          'warehouseName',
          'warehouseId',
          this.treeData
        );
      } else {
        this.items = this.common.filterTreeData(
          'warehouseName',
          'warehouseId',
          this.treeData
        );
      }
    }
    if (changes.value && changes.value.firstChange !== true) {
      this.value = changes.value.currentValue;
    }
  }

  public onSelectedChange(event) {
    this.getWarehouseVal.emit(event);

    const treeData = [];
    const fn = (source) => {
      source.forEach((el) => {
        treeData.push(el);
        el.children && el.children.length > 0 ? fn(el.children) : '';
      });
    };

    fn(this.treeData);

    const warehouse = treeData.filter((item: any) => {
      return item.warehouseId === event;
    });

    this.warehouseManagerName.emit(warehouse);
  }

  public filterWarehouseTreeData(name, value, data): TreeviewItem[] {
    const resultData: TreeviewItem[] = [];
    data.map((item, index) => {
      _.forEach(item, (val, key) => {
        if (key === 'data') {
          resultData.push(
            new TreeviewItem({
              collapsed: val.expanded,
              checked: false,
              text: val[name] || '',
              value: val[value],
            })
          );
        }
        if (key === 'children' && !_.isEmpty(val)) {
          resultData[index].children = this.filterTreeData(name, value, val);
        }
      });
    });

    return resultData;
  }

  public filterTreeData(name, value, data): TreeviewItem[] {
    const resultData: TreeviewItem[] = [];
    for (let i = 0; i < data.length; i++) {
      resultData.push(
        new TreeviewItem({
          collapsed: data[i].data.expanded,
          checked: false,
          text: data[i].data[name],
          value: data[i].data[value],
        })
      );
      if (!_.isEmpty(data[i].children)) {
        resultData[i].children = this.filterTreeData(
          name,
          value,
          data[i].children
        );
      }
      resultData[i].correctChecked();
    }
    return resultData;
  }
}
