import { AppConfigService } from './../../../../app.config';
import { ActivatedRoute, Router } from '@angular/router';
import { StationMonitorService } from './../../station-monitor.service';
import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { buildHistoryChart } from './chart/history-chart';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import * as moment from 'moment';
import * as _ from 'lodash';
import { buildDeviceLineChart } from './chart/device-line.chart';
import { buildDeviceBarChart } from './chart/device-bar.chart';
import { buildPowerChart } from '@components/station-monitor/overview/power-generation-card/chart/power.chart';
import { buildElectricityDayChart } from '@components/station-monitor/overview/power-generation-card/chart/electricity-day.chart';
import { buildElectricityMonthChart } from '@components/station-monitor/overview/power-generation-card/chart/electricity-month.chart';
import { buildElectricityYearChart } from '@components/station-monitor/overview/power-generation-card/chart/electricity-year.chart';
import { CacheService } from '@common/services/cache.service';
import { Constants } from '@common/Constants';
declare const $;
@Component({
  selector: 'app-operational-monitoring',
  templateUrl: './operational-monitoring.component.html',
  styleUrls: ['./operational-monitoring.component.less'],
})
export class OperationalMonitoringComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() deviceId: string = '';
  @Input() deviceType: string = 'GYKG';
  @Input() refreshData = false;
  stationId: string = '';
  public yxToggle = true;
  public ycToggle = true;
  deviceInfo: any;
  showDialog: boolean = false;
  public options: any = {};
  canlendarLocale: any;
  date: any = new Date();
  pointId: any;
  defaultI = 'I';
  electricityType = 'power';
  electricityDate = moment().format('YYYY-MM-DD');
  electricity_common_params: any;
  energyOption: any;
  energyLoading: boolean = true;
  pviLoading: boolean;
  pviDate = moment().format('YYYY-MM-DD');
  pviOption;
  irradiationLoading: boolean;
  irradiationOptions;
  irradiationDate = moment().format('YYYY-MM-DD');
  temperatureLoading: boolean;
  temperatureOptions;
  temperatureDate = moment().format('YYYY-MM-DD');
  rightPoint: any;
  leftPoint: any[];
  theme: any;
  intervalQuery: any = {};
  power1Option: any;
  power2Option: any;
  power3Option: any;
  power4Option: any;
  sta_params: any;
  electricityInterval: any;
  intervalTime: number = 5;
  pointMap: string[] = [];
  showAllPointsDialog: boolean;
  groupName: string;
  allPoints: any;
  pointNames: any = {};
  constructor(
    private stationmonitorservice: StationMonitorService,
    private calendarLocales: CalendarLocales,
    private router: Router,
    private appconfigservice: AppConfigService,
    private activatedroute: ActivatedRoute,
    private cacheservice: CacheService
  ) {
    this.theme = this.appconfigservice.getAppConfig().theme;
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    this.activatedroute.queryParams.subscribe((res) => {
      if (res.stationId) {
        this.stationId = res.stationId;
      } else {
        this.stationId = this.cacheservice.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
      }
    });
  }

  ngOnInit() {}

  ngOnDestroy(): void {
    let object = this.intervalQuery;
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        let element = object[key];
        clearInterval(element);
        element = null;
      }
    }
    this.intervalQuery = {};
  }

  ngOnChanges(changes) {
    setTimeout(() => {
      $('#container-content-main').scrollTop(0);
    }, 100);
    if (changes.deviceId && changes.deviceId.currentValue) {
      this.getDeviceDetail(changes.deviceId.currentValue);
      //   this.getDeviceDetail('000560000108')
    }
    if (changes.deviceType && changes.deviceType.currentValue) {
      this.deviceType = changes.deviceType.currentValue;
    }
    if (changes.refreshData && !changes.refreshData.firstChange) {
      this.getDeviceDetail(this.deviceId);
    }
    if (this.deviceType == 'JNB' || this.deviceType == 'NB') {
      this.electricityType = 'power';
      this.electricityDate = moment().format('YYYY-MM-DD');
      this.energyLoading = true;
      this.getDetailCharts('energy', {
        deviceId: this.deviceId,
        chartType: 'power',
        date: moment().format('YYYY-MM-DD'),
      });
      this.pviLoading = true;
      this.getDetailCharts('pvi', {
        deviceId: this.deviceId,
        chartType: 'pvi',
        date: moment().format('YYYY-MM-DD'),
      });
    }
    if (this.deviceType == 'HLX') {
      this.pviLoading = true;
      this.getDetailCharts('pvi', {
        deviceId: this.deviceId,
        chartType: 'pvi',
        date: moment().format('YYYY-MM-DD'),
      });
    }
    if (this.deviceType == 'DNB') {
      this.electricityType = 'energyHour';
      this.electricityDate = moment().format('YYYY-MM-DD');
      this.energyLoading = true;
      this.getDetailBarCharts('energy', {
        deviceId: this.deviceId,
        chartType: 'energyHour',
        date: moment().format('YYYY-MM-DD'),
      });
    }
    if (this.deviceType == 'QXZ') {
      this.irradiationLoading = true;
      this.getDetailCharts('irradiation', {
        deviceId: this.deviceId,
        chartType: 'irradiation',
        date: moment().format('YYYY-MM-DD'),
      });
      this.temperatureLoading = true;
      this.getDetailCharts('temperature', {
        deviceId: this.deviceId,
        chartType: 'temperature',
        date: moment().format('YYYY-MM-DD'),
      });
    }
    if (this.deviceType === 'FJ') {
      if (this.electricityInterval) {
        clearInterval(this.electricityInterval);
        this.electricityInterval = null;
      }

      this.sta_params = { stationId: this.stationId };
      if (_.isEmpty(this.electricity_common_params)) {
        this.electricity_common_params = {
          dataType: this.electricityType,
          date: this.electricityDate,
        };
      } else {
        this.electricity_common_params = _.cloneDeep(
          this.electricity_common_params
        );
      }

      this.getEnergy(
        _.merge({}, this.electricity_common_params, this.sta_params)
      );
      this.electricityInterval = setInterval(() => {
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
      }, 1000 * 60 * this.intervalTime);
    }
  }

  public renderPie(name, value, unit) {
    // 获取容器尺寸
    const w = document.documentElement.clientWidth - 20;
    const h = document.documentElement.clientHeight - 30;

    // 测点值
    const baseValue = value;
    // 测点名称
    const baseText = name;
    // 测点单位
    let baseGrade = unit;

    // 边缘圆弧基础半径
    const baseRadius0: any = '69%';
    const baseRadius1: any = '74%';
    // 边缘圆弧开始圆角圆心位置
    // 边缘圆弧半径绝对值
    let baseRadiusAbs0 = 0;
    let baseRadiusAbs1 = 0;
    let baseRadiusAbs = 0;
    if (w < h) {
      baseRadiusAbs0 =
        (w * baseRadius0.substr(0, baseRadius0.length - 1)) / 100 / 2;
      baseRadiusAbs1 =
        (w * baseRadius1.substr(0, baseRadius1.length - 1)) / 100 / 2;
    } else {
      baseRadiusAbs0 =
        (h * baseRadius0.substr(0, baseRadius0.length - 1)) / 100 / 2;
      baseRadiusAbs1 =
        (h * baseRadius1.substr(0, baseRadius1.length - 1)) / 100 / 2;
    }
    baseRadiusAbs = (baseRadiusAbs0 + baseRadiusAbs1) / 2;
    // 开始圆角中心
    const startCenterX = w / 2 - baseRadiusAbs;
    // 开始圆角半径
    const startRadius = (baseRadiusAbs1 - baseRadiusAbs0) / 2;
    // 结束圆基底坐标x,y
    const endBaseCenterX =
      w / 2 - baseRadiusAbs * Math.cos((baseValue / 10) * Math.PI);
    const endBaseCenterY =
      h / 2 - baseRadiusAbs * Math.sin((baseValue / 10) * Math.PI);
    // 边缘圆弧渐变色
    let edgeGradient = [
      {
        offset: 0,
        color: '#02F7A8', // 0% 处的颜色
      },
      {
        offset: 0.2,
        color: '#02F7D7', // 20% 处的颜色
      },
      {
        offset: 0.4,
        color: '#09EAE2', // 40% 处的颜色
      },
      {
        offset: 0.6,
        color: '#03B3EA', // 60% 处的颜色
      },
      {
        offset: 0.8,
        color: '#22A2F9', // 80% 处的颜色
      },
      {
        offset: 1,
        color: '#2E43EB', // 100% 处的颜色
      },
    ];
    if (baseValue <= 2) {
      edgeGradient = [
        {
          offset: 0,
          color: '#02F7D7',
        },
        {
          offset: 1,
          color: '#09EAE2',
        },
      ];
    } else if (baseValue <= 4) {
      edgeGradient = [
        {
          offset: 0,
          color: '#02F7D7',
        },
        {
          offset: 0.5,
          color: '#09EAE2',
        },
        {
          offset: 1,
          color: '#22A2F9',
        },
      ];
    } else if (baseValue <= 6) {
      edgeGradient = [
        {
          offset: 0,
          color: '#02F7D7',
        },
        {
          offset: 0.33,
          color: '#09EAE2',
        },
        {
          offset: 0.66,
          color: '#22A2F9',
        },
        {
          offset: 1,
          color: '#2E43EB',
        },
      ];
    } else if (baseValue <= 8) {
      edgeGradient = [
        {
          offset: 0,
          color: '#02F7D7',
        },
        {
          offset: 0.33,
          color: '#09EAE2',
        },
        {
          offset: 0.66,
          color: '#22A2F9',
        },
        {
          offset: 0.88,
          color: '#2E43EB',
        },
        {
          offset: 1,
          color: '#A5107A',
        },
      ];
    }
    let option = {
      backgroundColor: '#021720',
      title: {
        text: '',
        textStyle: {
          color: '#fff',
        },
      },
      series: [
        // 底部仪表
        {
          type: 'gauge',
          startAngle: 180,
          endAngle: 0,
          min: 0,
          max: 10,
          splitNumber: 5,
          axisLine: {
            show: false,
          },
          splitLine: {
            length: 10,
            lineStyle: {
              width: 1,
            },
          },
          axisTick: {
            splitNumber: 20,
            length: 1,
            lineStyle: {
              color: '#4A90E2',
            },
          },
          axisLabel: {
            show: false,
            distance: -40,
            formatter: function (value) {
              let richIndex = 'a';
              switch (value) {
                case 0:
                  richIndex = 'a';
                  break;
                case 2:
                  richIndex = 'b';
                  break;
                case 4:
                  richIndex = 'c';
                  break;
                case 6:
                  richIndex = 'd';
                  break;
                case 8:
                  richIndex = 'e';
                  break;
                case 10:
                  richIndex = 'f';
                  break;
              }
              return `{${richIndex}|${value}}`;
            },
            rich: {
              a: {
                color: '#02F7D7',
              },
              b: {
                color: '#09EAE2',
              },
              c: {
                color: '#22A2F9',
              },
              d: {
                color: '#2E43EB',
              },
              e: {
                color: '#A5107A',
              },
              f: {
                color: '#D20C58',
              },
            },
          },
          pointer: {
            width: 60,
          },
          itemStyle: {
            color: '#023A79',
          },
          title: {
            show: false,
          },
          detail: {
            show: false,
          },
          data: [
            {
              name: '',
              value: baseValue,
            },
          ],
        },
        // 外层环形
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          startAngle: 200,
          radius: ['55%', '74%'],
          z: 1,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: 220,
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'RGBA(8, 27, 63, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(0, 3, 18, 0.1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
            {
              value: 140,
              itemStyle: {
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
        },
        // 中层窄圆环
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          radius: ['50%', '55%'],
          z: 10,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: 220,
              itemStyle: {
                color: 'RGBA(9, 27, 61, 1)',
              },
            },
          ],
        },
        // 内部圆
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          radius: [0, '50%'],
          z: 10,
          labelLine: {
            normal: {
              show: false,
            },
          },
          // 模拟中间文字
          label: {
            show: true,
            position: 'center',
            formatter: function () {
              return `{a|${baseValue}}\n{b|${baseGrade}}\n{c|${baseText}}`;
            },
            rich: {
              a: {
                fontSize: 18,
                color: '#fff',
              },
              b: {
                fontSize: 12,
                color: '#fff',
              },
              c: {
                fontSize: 10,
                color: '#fff',
              },
            },
          },
          silent: true,
          data: [
            {
              value: 220,
              itemStyle: {
                color: {
                  type: 'radial',
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: 'rgba(0, 88, 170, 1)', // 0% 处的颜色
                    },
                    {
                      offset: 0.6,
                      color: 'rgba(2, 49, 108, 1)', // 50% 处的颜色
                    },
                    {
                      offset: 1,
                      color: 'rgba(3, 11, 48, 1)', // 100% 处的颜色
                    },
                  ],
                  global: false, // 缺省为 false
                },
              },
            },
          ],
        },
        // 边缘圆弧
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          startAngle: 180,
          radius: [baseRadius0, baseRadius1],
          z: 1,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: baseValue,
              itemStyle: {
                color: {
                  type: 'linear',
                  x: 0,
                  y: 0,
                  x2: 1,
                  y2: 0,
                  colorStops: edgeGradient,
                  global: false, // 缺省为 false
                },
              },
            },
            {
              value: 0,
              itemStyle: {
                color: 'rgba(0,0,0,0)',
              },
            },
          ],
        },
        // 边缘圆弧开始圆角
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          radius: [0, startRadius],
          center: [w / 2 - baseRadiusAbs, '50%'],
          z: 20,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: 100,
              itemStyle: {
                color: '#02F7D7',
              },
            },
          ],
        },
        // 边缘圆弧结束圆白底
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          radius: [0, startRadius + 3],
          center: [endBaseCenterX, endBaseCenterY + 1],
          z: 20,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: 100,
              itemStyle: {
                color: '#fff',
              },
            },
          ],
        },
        // 边缘圆弧结束圆红底
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          radius: [startRadius, startRadius + 3],
          center: [endBaseCenterX, endBaseCenterY],
          z: 20,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: 100,
              itemStyle: {
                color: edgeGradient[edgeGradient.length - 1].color,
              },
            },
          ],
        },
        // 边缘圆弧结束中间圆
        {
          type: 'pie',
          hoverAnimation: false,
          legendHoverLink: false,
          radius: [0, startRadius - 2],
          center: [endBaseCenterX, endBaseCenterY],
          z: 20,
          labelLine: {
            normal: {
              show: false,
            },
          },
          silent: true,
          data: [
            {
              value: 100,
              itemStyle: {
                color: edgeGradient[edgeGradient.length - 1].color,
              },
            },
          ],
        },
      ],
    };

    return option;
  }

  /**
   * name
   */
  public getDeviceDetail(deviceId) {
    this.stationmonitorservice
      .getDeviceDetail(deviceId)
      .subscribe((res: any) => {
        if (res) {
          this.deviceInfo = res;
          if (this.deviceType == 'GYKG') {
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points.right
            ) {
              let point = [
                { code: 'AM-WX-P', name: '有功功率' },
                { code: 'AM-WX-Q', name: '无功功率' },
                { code: 'AM-WX-COS', name: '功率因数' },
                { code: 'AM-WX-FREQ', name: '电网频率' },
                { code: 'AM-WX-LINEUAB', name: 'Uab' },
                { code: 'AM-WX-PHASEUA', name: 'Ua' },
                { code: 'AM-WX-PHASEIA', name: 'Ia' },
                { code: 'AM-WX-LINEUBC', name: 'Ubc' },
                { code: 'AM-WX-PHASEUB', name: 'Ub' },
                { code: 'AM-WX-PHASEIB', name: 'Ib' },
                { code: 'AM-WX-LINEUCA', name: 'Uac' },
                { code: 'AM-WX-PHASEUC', name: 'Uc' },
                { code: 'AM-WX-PHASEIC', name: 'Ic' },
              ];
              this.rightPoint = [];
              if (this.deviceInfo.points.right.length) {
                this.deviceInfo.points.right.map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.rightPoint[i] === undefined) {
                      this.rightPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.rightPoint[i] = item;
                    }
                  }
                });
              } else {
                this.rightPoint = point;
              }
            }
          }

          if (this.deviceType == 'BYQ') {
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points['lower1-yc']
            ) {
              let point = [
                { code: 'AM-BYQ-ZXYGGL-LV1', name: '有功功率' },
                { code: 'AM-BYQ-ZXWGGL-LV1', name: '无功功率' },
                { code: 'AM-BYQ-FACTOR-LV1', name: '功率因数' },
                { code: 'AM-BYQ-FRE-LV1', name: '频率' },
                { code: 'AM-BYQ-LINEUAB-LV1', name: 'Uab' },
                { code: 'AM-BYQ-PHASEUA-LV1', name: 'Ua' },
                { code: 'AM-BYQ-PHASEIA-LV1', name: 'Ia' },
                { code: 'AM-BYQ-LINEUBC-LV1', name: 'Ubc' },
                { code: 'AM-BYQ-PHASEUB-LV1', name: 'Ub' },
                { code: 'AM-BYQ-PHASEIB-LV1', name: 'Ib' },
                { code: 'AM-BYQ-LINEUCA-LV1', name: 'Uac' },
                { code: 'AM-BYQ-PHASEUC-LV1', name: 'Uc' },
                { code: 'AM-BYQ-PHASEIC-LV1', name: 'Ic' },
              ];
              this.leftPoint = [];
              if (this.deviceInfo.points['lower1-yc'].length) {
                this.deviceInfo.points['lower1-yc'].map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.leftPoint[i] === undefined) {
                      this.leftPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.leftPoint[i] = item;
                    }
                  }
                });
              } else {
                this.leftPoint = point;
              }
            }
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points['lower2-yc']
            ) {
              let point = [
                { code: 'AM-BYQ-ZXYGGL-LV2', name: '有功功率' },
                { code: 'AM-BYQ-ZXWGGL-LV2', name: '无功功率' },
                { code: 'AM-BYQ-FACTOR-LV2', name: '功率因数' },
                { code: 'AM-BYQ-FRE-LV2', name: '频率' },
                { code: 'AM-BYQ-LINEUAB-LV2', name: 'Uab' },
                { code: 'AM-BYQ-PHASEUA-LV2', name: 'Ua' },
                { code: 'AM-BYQ-PHASEIA-LV2', name: 'Ia' },
                { code: 'AM-BYQ-LINEUBC-LV2', name: 'Ubc' },
                { code: 'AM-BYQ-PHASEUB-LV2', name: 'Ub' },
                { code: 'AM-BYQ-PHASEIB-LV2', name: 'Ib' },
                { code: 'AM-BYQ-LINEUCA-LV2', name: 'Uac' },
                { code: 'AM-BYQ-PHASEUC-LV2', name: 'Uc' },
                { code: 'AM-BYQ-PHASEIC-LV2', name: 'Ic' },
              ];
              this.rightPoint = [];
              if (this.deviceInfo.points['lower2-yc'].length) {
                this.deviceInfo.points['lower2-yc'].map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.rightPoint[i] === undefined) {
                      this.rightPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.rightPoint[i] = item;
                    }
                  }
                });
              } else {
                this.rightPoint = point;
              }
            }
          } else if (this.deviceType == 'JNB' || this.deviceType == 'NB') {
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points.right
            ) {
              let point = [
                { code: 'AM-NB-ZXYGDD-A', name: '累计发电量' },
                { code: 'AM-NB-ZXYGDD-D', name: '今日发电量' },
                { code: 'AM-NB-ZXYGGL-GRID', name: '有功功率' },
                { code: 'AM-NB-ZXWGGL-GRID', name: '无功功率' },
                { code: 'AM-NB-FACTOR-GRID', name: '功率因数' },
                { code: 'AM-NB-ZHXL', name: '逆变器效率' },
                { code: 'AM-NB-TEM', name: '机内温度' },
                { code: 'AM-NB-FRE-GRID', name: '电网频率' },
                { code: 'AM-NB-LINEUAB-GRID', name: 'Uab' },
                { code: 'AM-NB-PHASEUA-GRID', name: 'Ua' },
                { code: 'AM-NB-PHASEIA-GRID', name: 'Ia' },
                { code: 'AM-NB-LINEUBC-GRID', name: 'Ubc' },
                { code: 'AM-NB-PHASEUB-GRID', name: 'Ub' },
                { code: 'AM-NB-PHASEIB-GRID', name: 'Ib' },
                { code: 'AM-NB-LINEUCA-GRID', name: 'Uac' },
                { code: 'AM-NB-PHASEUC-GRID', name: 'Uc' },
                { code: 'AM-NB-PHASEIC-GRID', name: 'Ic' },
              ];
              this.rightPoint = [];
              if (this.deviceInfo.points.right.length) {
                this.deviceInfo.points.right.map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.rightPoint[i] === undefined) {
                      this.rightPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.rightPoint[i] = item;
                    }
                  }
                });
              } else {
                this.rightPoint = point;
              }
            }
          } else if (this.deviceType == 'HLX') {
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points.right
            ) {
              let point = [
                { code: 'AM-HLX-BUSBAR-I', name: '母线电流' },
                { code: 'AM-HLX-BUSBAR-V', name: '母线电压' },
                { code: 'AM-HLX-TEM', name: '机内温度' },
              ];
              this.rightPoint = [];
              if (this.deviceInfo.points.right.length) {
                this.deviceInfo.points.right.map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.rightPoint[i] === undefined) {
                      this.rightPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.rightPoint[i] = item;
                    }
                  }
                });
              } else {
                this.rightPoint = point;
              }
            }
          } else if (this.deviceType == 'DNB') {
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points.left
            ) {
              let point = [
                { code: 'AM-DNB-ZXYGZDN' },
                { code: 'AM-DNB-FXYGZDN' },
                { code: 'AM-DNB-ZXWGZDN' },
                { code: 'AM-DNB-FXWGZDN' },
                { code: 'AM-DNB-ZXYGDNJ' },
                { code: 'AM-DNB-FXYGDNJ' },
                { code: 'AM-DNB-ZXYGDNF' },
                { code: 'AM-DNB-FXYGDNF' },
                { code: 'AM-DNB-ZXYGDNP' },
                { code: 'AM-DNB-FXYGDNP' },
                { code: 'AM-DNB-ZXYGDNG' },
                { code: 'AM-DNB-FXYGDNG' },
              ];
              this.leftPoint = [];
              if (this.deviceInfo.points.left.length) {
                this.deviceInfo.points.left.map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.leftPoint[i] === undefined) {
                      this.leftPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.leftPoint[i] = item;
                    }
                  }
                });
              } else {
                this.leftPoint = point;
              }
            }
            if (
              this.deviceInfo &&
              this.deviceInfo.points &&
              this.deviceInfo.points.right
            ) {
              let point = [
                {
                  code: 'AM-DNB-ZXYGZGL',
                  name: '正向有功功率',
                },
                {
                  code: 'AM-DNB-ZXWGZGL',
                  name: '正向无功功率',
                },
                {
                  code: 'AM-DNB-FXYGZGL',
                  name: '反向有功功率',
                },
                {
                  code: 'AM-DNB-FXWGZGL',
                  name: '反向无功功率',
                },
                { code: 'AM-DNB-COS', name: '功率因数' },
                { code: 'AM-DNB-FREQ', name: '电网频率' },
                { code: 'AM-DNB-UAB', name: 'Uab' },
                { code: 'AM-DNB-UA', name: 'Ua' },
                { code: 'AM-DNB-IA', name: 'Ia' },
                { code: 'AM-DNB-UBC', name: 'Ubc' },
                { code: 'AM-DNB-UB', name: 'Ub' },
                { code: 'AM-DNB-IB', name: 'Ib' },
                { code: 'AM-DNB-UCA', name: 'Uac' },
                { code: 'AM-DNB-UC', name: 'Uc' },
                { code: 'AM-DNB-IC', name: 'Ic' },
              ];
              this.rightPoint = [];
              if (this.deviceInfo.points.right.length) {
                this.deviceInfo.points.right.map((item) => {
                  for (let i = 0; i < point.length; i++) {
                    if (this.rightPoint[i] === undefined) {
                      this.rightPoint[i] = point[i];
                    }
                    if (point[i].code == item.code) {
                      this.rightPoint[i] = item;
                    }
                  }
                });
              } else {
                this.rightPoint = point;
              }
            }
          } else if (this.deviceType === 'FJ') {
            const energy = this.deviceInfo.points.power;

            const pointMap = Object.keys(this.deviceInfo.points);

            pointMap.map((item) => {
              if (item.indexOf('-') > -1) {
                this.pointNames[item.split('-')[0]] = item.split('-')[1];
              }
            });

            this.pointMap = Object.keys(this.pointNames);

            this.power1Option = this.renderPie(
              energy[0].name,
              energy[0].v,
              energy[0].unit
            );
            this.power2Option = this.renderPie(
              energy[1].name,
              energy[1].v,
              energy[1].unit
            );
            this.power3Option = this.renderPie(
              energy[2].name,
              energy[2].v,
              energy[2].unit
            );
            this.power4Option = this.renderPie(
              energy[3].name,
              energy[3].v,
              energy[3].unit
            );
          }
        }
      });
  }

  showAllPoints(points, name) {
    console.log(points);

    this.groupName = name;
    this.showAllPointsDialog = true;
    this.allPoints = points;
  }

  /**
   * selectElectricityPower
   */
  public selectPowerElectricity(type, date?) {
    this.electricityType = type;
    switch (type) {
      case 'power':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          dataType: 'day',
          date: this.electricityDate,
        };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      case 'electricityDay':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          dataType: 'day',
          date: this.electricityDate,
        };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      case 'electricityMonth':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM');
        } else {
          this.electricityDate = moment().format('YYYY-MM');
        }
        this.electricity_common_params = {
          dataType: 'month',
          date: this.electricityDate + '-01',
        };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      case 'electricityYear':
        this.electricity_common_params = { dataType: 'year' };
        this.getEnergy(
          _.merge({}, this.electricity_common_params, this.sta_params)
        );
        break;
      default:
        break;
    }
  }

  selectricityDateSelect(event) {
    this.selectPowerElectricity(this.electricityType, event);
  }

  /**
   * getEnergy
   */
  public getEnergy(params) {
    if (this.electricityType == 'power') {
      this.energyLoading = true;
      this.stationmonitorservice.power(params).then((res: any) => {
        let data = res;
        this.energyOption = buildPowerChart(data);
        this.energyLoading = false;
      });
    } else {
      this.energyLoading = true;
      this.stationmonitorservice.energy(params).then((res: any) => {
        let data = res;
        if (params.dataType == 'day') {
          this.energyOption = buildElectricityDayChart(data);
        } else if (params.dataType == 'month') {
          this.energyOption = buildElectricityMonthChart(data);
        } else if (params.dataType == 'year') {
          this.energyOption = buildElectricityYearChart(data);
        }
        this.energyLoading = false;
      });
    }
  }

  public openDialog(pointId) {
    if (!pointId) {
      return;
    }
    this.pointId = pointId;
    this.date = new Date();
    this.options = null;
    this.showDialog = true;
    this.getPointCharts(this.pointId);
  }

  /**
   * name
   */
  public getPointCharts(pointId, date = '') {
    this.stationmonitorservice
      .getPointCharts({
        leftPIds: pointId,
        date,
      })
      .subscribe((res) => {
        this.options = buildHistoryChart(res, 'dark');
      });
  }

  /**
   * subtractDate
   */
  public subtractDate() {
    this.date = new Date(
      moment(this.date).subtract(1, 'days').format('YYYY-MM-DD')
    );
    this.getPointCharts(this.pointId, moment(this.date).format('YYYY-MM-DD'));
  }
  /**
   * addDate
   */
  public addDate() {
    this.date = new Date(moment(this.date).add(1, 'days').format('YYYY-MM-DD'));
    this.getPointCharts(this.pointId, moment(this.date).format('YYYY-MM-DD'));
  }

  public selectDate(e) {
    this.getPointCharts(this.pointId, moment(e).format('YYYY-MM-DD'));
  }

  public goAlarm() {
    this.router.navigate(['/alarmManage/realTime'], {
      queryParams: {
        stationId: this.deviceInfo.stationId,
        deviceNo: this.deviceInfo.deviceNo,
        alarmStatus: 1,
        recentDays: 30,
      },
    });
  }

  public selectElectricity(type, date?) {
    this.electricityType = type;
    this.energyLoading = true;
    switch (type) {
      case 'power':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          chartType: 'power',
          date: this.electricityDate,
        };
        this.getDetailCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      case 'electricityDay':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          chartType: 'energyDay',
          date: this.electricityDate,
        };
        this.getDetailCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      case 'electricityMonth':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM');
        } else {
          this.electricityDate = moment().format('YYYY-MM');
        }
        this.electricity_common_params = {
          chartType: 'energyMonth',
          date: this.electricityDate + '-01',
        };
        this.getDetailCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      case 'electricityYear':
        this.electricity_common_params = { chartType: 'energyYear' };
        this.getDetailCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      default:
        break;
    }
  }
  public selectEnergy(type, date?) {
    this.electricityType = type;
    this.energyLoading = true;
    switch (type) {
      case 'energyHour':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          chartType: 'energyHour',
          date: this.electricityDate,
        };
        this.getDetailBarCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      case 'energyDay':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM-DD');
        } else {
          this.electricityDate = moment().format('YYYY-MM-DD');
        }
        this.electricity_common_params = {
          chartType: 'energyDay',
          date: this.electricityDate,
        };
        this.getDetailBarCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      case 'energyMonth':
        if (date) {
          this.electricityDate = moment(date).format('YYYY-MM');
        } else {
          this.electricityDate = moment().format('YYYY-MM');
        }
        this.electricity_common_params = {
          chartType: 'energyMonth',
          date: this.electricityDate + '-01',
        };
        this.getDetailBarCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      case 'energyYear':
        this.electricity_common_params = { chartType: 'energyYear' };
        this.getDetailBarCharts(
          'energy',
          _.merge({}, this.electricity_common_params, {
            deviceId: this.deviceId,
          })
        );
        break;
      default:
        break;
    }
  }

  /**
   * electricityDateSelect
   */
  public electricityDateSelect(event) {
    this.selectElectricity(this.electricityType, event);
  }

  public pviDateSelect(event) {
    this.pviLoading = true;
    this.getDetailCharts('pvi', {
      deviceId: this.deviceId,
      chartType: 'pvi',
      date: moment(event).format('YYYY-MM-DD'),
    });
  }
  public irradiationDateSelect(event) {
    this.irradiationLoading = true;
    this.getDetailCharts('irradiation', {
      deviceId: this.deviceId,
      chartType: 'irradiation',
      date: moment(event).format('YYYY-MM-DD'),
    });
  }
  public temperatureDateSelect(event) {
    this.temperatureLoading = true;
    this.getDetailCharts('temperature', {
      deviceId: this.deviceId,
      chartType: 'temperature',
      date: moment(event).format('YYYY-MM-DD'),
    });
  }

  public energyDataSelect(event) {
    this.selectEnergy(this.electricityType, event);
  }

  /**
   * getEnergy
   */
  public getDetailCharts(type, params) {
    this.stationmonitorservice.getDetailCharts(params).subscribe((res) => {
      if (type == 'energy') {
        this.energyOption = buildDeviceLineChart(res, 'dark');
        this.energyLoading = false;
      }
      if (type == 'pvi') {
        this.pviOption = buildDeviceLineChart(res, 'dark');
        this.pviLoading = false;
      }
      if (type == 'irradiation') {
        this.irradiationOptions = buildDeviceLineChart(res, 'dark');
        this.irradiationLoading = false;
      }
      if (type == 'temperature') {
        this.temperatureOptions = buildDeviceLineChart(res, 'dark');
        this.temperatureLoading = false;
      }
    });

    if (this.intervalQuery[type]) {
      clearInterval(this.intervalQuery[type]);
      this.intervalQuery[type] = null;
    }

    this.intervalQuery[type] = setInterval(() => {
      this.stationmonitorservice.getDetailCharts(params).subscribe((res) => {
        if (type == 'energy') {
          this.energyOption = buildDeviceLineChart(res, 'dark');
          this.energyLoading = false;
        }
        if (type == 'pvi') {
          this.pviOption = buildDeviceLineChart(res, 'dark');
          this.pviLoading = false;
        }
        if (type == 'irradiation') {
          this.irradiationOptions = buildDeviceLineChart(res, 'dark');
          this.irradiationLoading = false;
        }
        if (type == 'temperature') {
          this.temperatureOptions = buildDeviceLineChart(res, 'dark');
          this.temperatureLoading = false;
        }
      });
    }, 1 * 1000 * 60);
  }
  /**
   * getEnergy
   */
  public getDetailBarCharts(type, params) {
    this.stationmonitorservice.getDetailCharts(params).subscribe((res) => {
      if (type == 'energy') {
        this.energyOption = buildDeviceBarChart(res);
        this.energyLoading = false;
      }
    });
  }
}
