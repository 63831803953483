import {
  Component,
  OnInit,
  Input,
  Output,
  OnChanges,
  SimpleChanges,
  EventEmitter,
} from '@angular/core';

import { Validators, FormControl, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { LazyLoadEvent, SelectItem, ConfirmationService } from 'primeng/primeng';
import { Location } from '@angular/common';
import * as _ from 'lodash';
import { CommonService } from 'src/app/common/services/common.service';
import { DeviceManageService } from 'src/app/components/device-assets/device-manage/device-manage.service';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-add-document',
  templateUrl: './add-document.component.html',
  styleUrls: ['../../device-detail.component.less'],
})
export class AddDocumentComponent implements OnInit, OnChanges {
  @Input() public isShow = false;
  @Input() public docType;
  @Input() public deviceId;
  @Input() public title;

  @Output() public isHide = new EventEmitter<boolean>();
  public uploadedFiles: any[] = [];
  public files = [];
  public event;

  public stationIds: SelectItem[] = [];
  public stationId = '';
  public isNew = true;
  public form: FormGroup;
  public memo = '';
  public rela = {
    deviceId: '',
    docType: '',
    memo: '',
    file: null,
  };
  public folderType;

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private deviceManageService: DeviceManageService,
    private location: Location,
    private tipservice: TipService
  ) { }

  public ngOnInit() {
    this.formInit();
  }
  public ngOnChanges(changes: SimpleChanges) {
    if (changes.isShow && this.isShow) {
      this.formInit();
    }
  }

  private formInit() {
    this.form = this.fb.group({
      memo: new FormControl(''),
    });
  }

  // 附件上传
  public onSelectFile(event) {
    this.files = [event.files[0]];
  }

  public save() {
    if (!this.files || !this.files.length) {
      this.tipservice.tip('warn', '请选择上传文件');
      return;
    }
    this.rela.deviceId = this.deviceId;
    this.rela.docType = this.docType;
    this.rela.memo = this.form.value.memo;
    this.rela.file = this.files[0];

    if (_.find(this.uploadedFiles, { docName: this.files[0].name }) === undefined) {
      this.deviceManageService.uploadFile(this.rela).subscribe((res) => {
        this.onHide();
      });
    }
  }

  /**
   * 关闭窗体
   */
  public onHide() {
    this.isShow = false;
    this.isHide.emit();
  }
}
