import { Injectable } from '@angular/core';

import { Constants } from '../Constants';

@Injectable()
export class CacheService {

  private cached = {};

  public constructor() {
    this.cached = this.loadCacheFromLocalstorage();
  }

  public setCache(key: string, value, isPersistent: boolean = false) {
    this.cached[key] = value;
    if (isPersistent) {
      this.setCacheToLocalstorage(key, value);
    }
  }

  public getCache(key: string) {
    return this.cached[key];
  }

  public clear() {
    this.cached = {};
  }

  private loadCacheFromLocalstorage() {
    let cached = {};
    try {
      cached = JSON.parse(localStorage.getItem(Constants.KEY_CACHED));
    } catch (e) {
      console.warn('加载本地缓存失败:' + e);
    }
    return cached || {};
  }

  private setCacheToLocalstorage(key: string, value) {
    const cached = this.loadCacheFromLocalstorage();
    cached[key] = value;
    try {
      localStorage.setItem(Constants.KEY_CACHED, JSON.stringify(cached));
    } catch (e) {
      console.warn('保存本地缓存失败:' + e);
    }
  }
}
