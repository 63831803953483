import { UserSelectorModule } from './../user-selector/user-selector.module';
import { SharedModule } from './../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CcUsersComponent } from './cc-users.component';

@NgModule({
    declarations: [CcUsersComponent],
    imports: [CommonModule, SharedModule, UserSelectorModule],
    exports: [CcUsersComponent],
})
export class CcUsersModule {}
