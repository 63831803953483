export enum ClassesType {
  deviceType = "01", // 	设备分类
  spareParts = "02", // 	备品备件分类
  toolType = "03", // 	 工器具类别
  defectType = "04", // 	 缺陷类别
  orderType = "05", // 工单类别
  accidentType = "07", // 事故类别
  dangerType = "08", // 风险类别
  nonProductiveType = "09", // 非生产物资
  assetsType = "09" // 资产类别
}
