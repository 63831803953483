import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivatePageFullscreenGuard } from '@core/private-page-fullscreen.guard';
import { PrivatePageLayoutDashboardGuard } from '@core/private-page-layout-dashboard.guard';
import { LoginComponent } from './base/login/login.component';
import { NoContentComponent } from './base/no-content/no-content.component';
import { MapOverviewComponent } from './components/map-overview/map-overview.component';
import { PrivatePageGuard } from './core/private-page.guard';
import { PublicPageGuard } from './core/public-page.guard';
import { FullLayoutDashboardComponent } from './layouts/full-layout-dashboard/full-layout-dashboard.component';
import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
// import { FullLayoutComponent } from './layouts/full-layout/full-layout.component';
import { FullScreenLayoutComponent } from './layouts/full-screen-layout/full-screen-layout.component';
import { SimpleLayoutComponent } from './layouts/simple-layout/simple-layout.component';

const childrenRoute = [
  {
    path: 'ticket',
    loadChildren: () =>
      import('./components/ticket/ticket.module').then((m) => m.TicketModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'power',
    loadChildren: () =>
      import('./components/power/power.module').then((m) => m.PowerModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'report',
    loadChildren: () =>
      import('./components/report/report.module').then((m) => m.ReportModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'inspection',
    loadChildren: () =>
      import('./components/inspection/inspection.module').then(
        (m) => m.InspectionModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'inspections',
    loadChildren: () =>
      import('./components/inspections/inspections.module').then(
        (m) => m.InspectionsModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'maintains',
    loadChildren: () =>
      import('./components/maintains/maintains.module').then(
        (m) => m.MaintainsModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'discreteRateNew',
    loadChildren: () =>
      import(
        './components/monitor/discrete-rate-new/discrete-rate-new.module'
      ).then((m) => m.DiscreteRateNewModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'discreteRateStatistics',
    loadChildren: () =>
      import(
        './components/monitor/discrete-rate-statistics/discrete-rate-statistics.module'
      ).then((m) => m.DiscreteRateStatisticsModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'alarmManage',
    loadChildren: () =>
      import('./components/alarm/alarm-manage/alarm-manage.module').then(
        (m) => m.AlarmManageModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'alarmRule',
    loadChildren: () =>
      import('./components/alarm/alarm-rule/alarm-rule.module').then(
        (m) => m.AlarmRuleModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'alarmNotify',
    loadChildren: () =>
      import('./components/alarm/alarm-notify/alarm-notify.module').then(
        (m) => m.AlarmNotifyModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'alarmFilter',
    loadChildren: () =>
      import('./components/alarm/alarm-filter/alarm-filter.module').then(
        (m) => m.AlarmFilterModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'defect',
    loadChildren: () =>
      import('./components/defect/defect.module').then((m) => m.DefectModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'report/checkin',
    loadChildren: () =>
      import('./components/checkin/checkin.module').then(
        (m) => m.CheckinModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'workOrder',
    loadChildren: () =>
      import('./components/work-order/work-order.module').then(
        (m) => m.WorkOrderModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'sparePartsManage',
    loadChildren: () =>
      import(
        './components/device-assets/spare-parts-manage/spare-parts-manage.module'
      ).then((m) => m.SparePartsManageModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'assetsBasicInfo',
    loadChildren: () =>
      import('./components/device-assets/basic-info/basic-info.module').then(
        (m) => m.AssetsBasicInfoModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'deviceMange',
    loadChildren: () =>
      import(
        './components/device-assets/device-manage/device-manage.module'
      ).then((m) => m.DeviceManageModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'reportFormsCustom',
    loadChildren: () =>
      import(
        './components/report-forms/report-forms-custom/report-forms-custom.module'
      ).then((m) => m.ReportFormsCustomModule),
    canActivate: [PrivatePageGuard],
  },
  // {
  //   path: 'topo',
  //   loadChildren: () =>
  //     import('./components/topo/topo.module').then((m) => m.TopoModule),
  //   canActivate: [PrivatePageGuard],
  // },
  {
    path: 'topo',
    loadChildren: () =>
      import('./components/topo-manager/topo-manager.module').then(
        (m) => m.TopoManagerModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'config',
    loadChildren: () =>
      import('./components/basic-data/shared/basic-data.module').then(
        (m) => m.BasicDataModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'base',
    canActivate: [PrivatePageGuard],
    children: [
      {
        path: 'depts',
        loadChildren: () =>
          import('./base/depts/depts.module').then((m) => m.DeptsModule),
        data: {
          title: '部门管理',
          res: 'base.depts.query',
        },
        canActivate: [PrivatePageGuard],
      },
      {
        path: 'users',
        loadChildren: () =>
          import('./base/users/users.module').then((m) => m.UsersModule),
        data: {
          title: '用户管理',
          res: 'base.users.query',
        },
        canActivate: [PrivatePageGuard],
      },
      {
        path: 'team',
        loadChildren: () =>
          import('./base/team/team.module').then((m) => m.TeamModule),
        data: {
          title: '班组管理',
        },
        canActivate: [PrivatePageGuard],
      },
      {
        path: 'permissions',
        loadChildren: () =>
          import('./base/permissions/permissions.module').then(
            (m) => m.PermissionsModule
          ),
        data: {
          title: '权限管理',
          res: 'base.permissions.query',
        },
        canActivate: [PrivatePageGuard],
      },
    ],
  },
  {
    path: 'topology',
    loadChildren: () =>
      import('./components/monitor/topology/topology.module').then(
        (m) => m.TopologyModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'topoOverview',
    loadChildren: () =>
      import('./components/monitor/topoOverview/topoOverview.module').then(
        (m) => m.TopoOverviewModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'communicationMonitor',
    loadChildren: () =>
      import(
        './components/monitor/communication-monitor/communication-monitor.module'
      ).then((m) => m.CommunicationMonitorModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'meterReading',
    loadChildren: () =>
      import('./components/meter-reading/meter-reading.module').then(
        (m) => m.MeterReadingModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'dataManage',
    loadChildren: () =>
      import('./components/data-manage/data-manage.module').then(
        (m) => m.DataManageModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'operationMaintenance',
    loadChildren: () =>
      import(
        './components/operation-maintenance/operation-maintenance.module'
      ).then((m) => m.OperationMaintenanceModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'deviceGroup',
    loadChildren: () =>
      import('./components/device-group/device-group.module').then(
        (m) => m.DeviceGroupModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'plan',
    loadChildren: () =>
      import('./components/plan/shared/plan.module').then((m) => m.PlanModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'sys',
    loadChildren: () =>
      import('./components/system/shared/system.module').then(
        (m) => m.SystemModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'propTypes',
    loadChildren: () =>
      import('./components/system/prop-types/shared/prop-types.module').then(
        (m) => m.PropTypesModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'workOrderReport',
    loadChildren: () =>
      import('./components/work-order-report/work-order-report.module').then(
        (m) => m.WorkOrderReportModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'safetyCheck',
    loadChildren: () =>
      import('./components/safety/safety-check/safety-check.module').then(
        (m) => m.SafetyCheckModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'securityCheck',
    loadChildren: () =>
      import('./components/safety/security-check/security-check.module').then(
        (m) => m.SecurityCheckModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'safety',
    loadChildren: () =>
      import('./components/safety/shared/safety.module').then(
        (m) => m.SafetyModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'overview',
    redirectTo: '/monitorCenter/overview',
  },
  {
    path: 'monitorCenter',
    loadChildren: () =>
      import('./components/monitor-center/monitor-center.module').then(
        (m) => m.MonitorCenterModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'monitorStation',
    loadChildren: () =>
      import('./components/station-monitor/station-monitor.module').then(
        (m) => m.StationMonitorModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'ln/benchmark',
    loadChildren: () =>
      import('./components/ln-benchmark/ln-benchmark.module').then(
        (m) => m.LnBenchmarkModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'ln/income',
    loadChildren: () =>
      import('./components/ln-income/ln-income.module').then(
        (m) => m.LnIncomeModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'ln/material',
    loadChildren: () =>
      import('./components/ln-material/ln-material.module').then(
        (m) => m.LnMaterialModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'ln/construction',
    loadChildren: () =>
      import('./components/ln-construction/ln-construction.module').then(
        (m) => m.LnConstructionModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'ln/maintain',
    loadChildren: () =>
      import('./components/ln-maintain/ln-maintain.module').then(
        (m) => m.LnMaintainModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'document',
    loadChildren: () =>
      import('./components/document/document.module').then(
        (m) => m.DocumentModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'documentManage',
    loadChildren: () =>
      import('./components/document-manage/document-manage.module').then(
        (m) => m.DocumentManageModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'weather-analysis',
    loadChildren: () =>
      import('./components/weather-analysis/weather-analysis.module').then(
        (m) => m.WeatherAnalysisModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'topo-v2',
    loadChildren: () =>
      import('./components/topo-v2/topo-v2.module').then((m) => m.TopoV2Module),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'one-key-inspection',
    loadChildren: () =>
      import('./components/one-key-inspection/one-key-inspection.module').then(
        (m) => m.OneKeyInspectionModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'wind',
    loadChildren: () =>
      import('./components/wind/wind.module').then((m) => m.WindModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'ems',
    loadChildren: () =>
      import('./components/ems/ems.module').then((m) => m.EmsModule),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'bigScreenConfig',
    loadChildren: () =>
      import('./components/big-screen-config/big-screen-config.module').then(
        (m) => m.BigScreenConfigModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'config/sysinit',
    loadChildren: () =>
      import('./components/sysinit/sysinit.module').then(
        (m) => m.SysinitModule
      ),
    canActivate: [PrivatePageGuard],
  },
  {
    path: 'config/point/template',
    loadChildren: () =>
      import('./components/point-template/point-template.module').then(
        (m) => m.PointTemplateModule
      ),
    canActivate: [PrivatePageGuard],
  },
];

export const ROUTES: Routes = [
  {
    path: '',
    // redirectTo: 'dashboard',
    redirectTo:
      // localStorage.getItem('homepage') === null
      //   ? '/yxjs/monitorCenter/overview'
      //   : localStorage.getItem('homepage'),
      '/yxjs',
    pathMatch: 'full',
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    data: {
      title: 'Pages',
    },
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [PublicPageGuard],
      },
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    data: {
      title: 'Pages',
    },
    children: [
      {
        path: 'autologin',
        component: LoginComponent,
        canActivate: [PublicPageGuard],
      },
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    data: {
      title: 'Map',
    },
    children: [
      {
        path: 'mapOverview',
        component: MapOverviewComponent,
        canActivate: [PrivatePageGuard],
      },
    ],
  },
  {
    path: '',
    component: SimpleLayoutComponent,
    children: [
      {
        path: 'lnDashboard',
        loadChildren: () =>
          import('./components/ln-dashboard/ln-dashboard.module').then(
            (m) => m.LnDashboardModule
          ),
      },
    ],
  },
  {
    path: '',
    component: FullLayoutComponent,
    data: { isBlueBackground: true },
    children: [
      {
        path: 'video',
        loadChildren: () =>
          import(
            './components/monitor/video-monitor/video-monitor.module'
          ).then((m) => m.VideoMonitorModule),
        canActivate: [PrivatePageGuard],
      },
    ],
  },
  {
    path: '',
    component: FullLayoutDashboardComponent,
    canDeactivate: [PrivatePageLayoutDashboardGuard],
    children: [
      {
        path: 'yxjs',
        component: SimpleLayoutComponent,
        children: childrenRoute,
      },
      {
        path: 'scgl',
        component: SimpleLayoutComponent,
        children: childrenRoute,
      },
      {
        path: 'yyfx',
        component: SimpleLayoutComponent,
        children: childrenRoute,
      },
      {
        path: 'xtpz',
        component: SimpleLayoutComponent,
        children: childrenRoute,
      },
    ],
  },
  {
    path: 'IframeSupportInter',
    component: SimpleLayoutComponent,
    data: {
      title: 'Pages',
    },
    canDeactivate: [PrivatePageFullscreenGuard],
    children: [
      {
        path: 'login',
        component: LoginComponent,
        canActivate: [PublicPageGuard],
      },
    ],
  },
  {
    path: 'IframeSupportInter',
    component: FullScreenLayoutComponent,
    canDeactivate: [PrivatePageFullscreenGuard],
    children: childrenRoute,
  },
  {
    path: '**',
    component: NoContentComponent,
  },
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(ROUTES, {
  scrollPositionRestoration: 'enabled',
});
