import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { SharedModule } from './../../../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommentComponent } from './comment.component';

@NgModule({
    declarations: [CommentComponent],
    imports: [CommonModule, SharedModule, InputTextareaModule, ButtonModule],
    exports: [CommentComponent],
})
export class CommentModule {}
