import { Component, OnInit, Input } from "@angular/core";
import { Notify, NotifyLevel, AlarmAction, AlarmLevel } from "./header.model";
import { Observable } from "rxjs";

import * as _ from "lodash";
import { HeaderService } from "./header.service";

@Component({
    selector: "mpt-app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.less"]
})
export class HeaderComponent implements OnInit {
    public display: string = null;

    public notifyList: Notify[] = [];
    public maxNotifyCount = 5;

    public alarmCount = 0;
    public messageCount = 0;
    visibleSidebar1;
    alarmLists: any;
    public constructor(private headerService: HeaderService) {}

    public ngOnInit(): void {
        this.headerService.subscibeAlarm().subscribe(alarm => {
            if (this.notifyList.length === this.maxNotifyCount) {
                this.notifyList.pop();
            }
            this.notifyList.unshift({
                id: alarm.alarmLog.logId,
                level: NotifyLevel[alarm.alarmLog.alarmLevel],
                message: alarm.alarmLog.alarmText,
                dialogFlag: alarm.dialogFlag,
                voiceFlag: alarm.voiceFlag,
                voiceTime: 2
            });
        });

        // this.headerService.getAlarmStatistics().subscribe((result) => {
        //   this.alarmCount = result.count;
        // });
        if (localStorage.getItem("websocket") == "false") {
            this.alarmInterval();
            setInterval(() => {
                this.alarmInterval();
            }, 1000 * 60);
        }

        this.headerService.display.subscribe(res => {
            if (res.flag) {
                this.display = null;
            }
            this.messageCount = res.messageCount;
        });
    }

    public setNotifyExpired(notify: Notify) {
        _.remove(this.notifyList, item => item.id === notify.id);
    }

    private alarmInterval() {
        this.headerService.webAlarmNotice().subscribe((res: any) => {
            if (!res || !res.alarms) {
                return;
            }
            if (res.alarms.length > 0) {
                this.display = "alarm";
                this.alarmLists = res;
                this.alarmCount = res.alarms.length;
            }
        });
    }
}
