export enum DeptType {
  company = '01',
  department = '02',
  station = '03',
  team = '04',
}

export enum ObjType {
  part = '01', // 部件
  device = '02', // 设备
  deviceGroup = '03', // 设备分组
  area = '04', // 电站区域
  station = '05', // 电站
  department = '06', // 部门
  psr = '07', // PSR
  model = '08', // 型号
  class = '09' // 类型
}

export enum newObjType{
  province = '01',
  city = '02',
  station = '03',
  device = '04'
}
