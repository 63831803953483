import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from 'src/app/app.config';
import { CommonService } from 'src/app/common/services/common.service';
import { AuthHttp } from 'src/app/core/auth-http';

@Injectable({
  providedIn: 'root',
})
export class StationMonitorService {
  private apiUrl: string = this.appConfig.apiUrl;

  private staParamsSource = new Subject<any>();
  staParamsHandler = this.staParamsSource.asObservable();

  private deviceDetailSource = new Subject<any>();
  deviceDetailHandler = this.deviceDetailSource.asObservable();

  public runStatusCache = '';
  public areaIdsCache = '';

  /**
   * setCache
   */
  public setRunStatusCache(runStatus) {
    this.runStatusCache = runStatus;
  }
  /**
   * setCache
   */
  public getRunStatusCache() {
    return this.runStatusCache;
  }

  /**
   * clearCache
   */
  public clearRunStatusCache() {
    this.runStatusCache = '';
  }
  /**
   * setCache
   */
  public setAreaIdsCache(AreaIds) {
    this.areaIdsCache = AreaIds;
  }
  /**
   * setCache
   */
  public getAreaIdsCache() {
    return this.areaIdsCache;
  }

  /**
   * clearCache
   */
  public clearAreaIdsCache() {
    this.areaIdsCache = '';
  }

  constructor(
    private appConfig: AppConfigService,
    private http: AuthHttp,
    private common: CommonService
  ) {}

  public setStaParams(params) {
    this.staParamsSource.next(params);
  }

  public setDeviceDetail(params) {
    this.deviceDetailSource.next(params);
  }

  /**
   * monitorStationInit
   */
  public monitorStationInit() {
    return this.http.get(`${this.apiUrl}/api/monitorStation/init`).toPromise();
  }
  /**
   * stationInfo
   */
  public stationInfo(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/stationInfo`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }
  /**
   * stationInfo
   */
  public changeParent(params) {
    return this.http
      .post(`${this.apiUrl}/api/device/changeParent`, null, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }
  /**
   * weatherInfo
   */
  public weatherInfo(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/weather`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
   * name
   */
  public deviceInfo(id) {
    return this.http.get(`${this.apiUrl}/api/device/deviceInfo?deviceId=${id}`);
  }

  /**
   * power
   */
  public power(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/power`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }
  /**
   * power
   */
  public energy(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/energy`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
   * power
   */
  public kwhkwp(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/kwhkwp`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
   * runStatus
   */
  public runStatus(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/runStatus`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
   * alarm1
   */
  public alarm1(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/alarm1`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }
  /**
   * alarm1
   */
  public alarm2(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/alarm2`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
   * commStatus
   */
  public commStatus(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/commStatus`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  /**
   * performance
   */
  public performance(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/performance`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }
  /**
   * inveters
   */
  public inveters(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorStation/inveters`, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }

  // 发电情况

  // 分组
  public getDeviceGroups(params) {
    return this.http
      .get(`${this.apiUrl}/api/deviceGroup/deviceGroups`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          const selection = [{ label: '请选择', value: null }];
          if (!res) {
            return [];
          }
          res.map((item) => {
            return selection.push({
              label: item.groupName,
              value: item.groupId,
            });
          });
          return selection;
        })
      );
  }
  // 位置
  public getArea(params) {
    return this.http
      .get(`${this.apiUrl}/api/stationAreas/all`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          if (!res) {
            return [];
          }
          return res.map((item) => {
            return { label: item.areaName, value: item.areaId };
          });
        })
      );
  }
  // 型号
  public getModel(params) {
    if (params.groupType) {
      params.deviceTypes = params.groupType;
    }
    return this.http
      .get(`${this.apiUrl}/api/models/stationModels`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          if (!res) {
            return [];
          }
          return res.map((item) => {
            return { label: item.modelNo, value: item.modelId };
          });
        })
      );
  }
  // 分类
  public getClass(params) {
    return this.http
      .get(`${this.apiUrl}/api/device/deviceClasses`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          if (!res) {
            return [];
          }
          return res.map((item) => {
            return { label: item.className, value: item.classId };
          });
        })
      );
  }

  // 分类
  public getClassIds(params) {
    return this.http
      .get(`${this.apiUrl}/api/device/classIds`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          if (!res) {
            return [];
          }
          return res.map((item) => {
            return { label: item.className, value: item.classId };
          });
        })
      );
  }

  // 用途
  public getUseFlagGroups(params) {
    return this.http
      .get(`${this.apiUrl}/api/prop`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          if (!res) {
            return [];
          }
          return res.map((item) => {
            return { label: item.propName, value: item.propValue };
          });
        })
      );
  }

  /**
   * getInveterDatas
   */
  public getInveterDatas(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorStation/energy/inveter/datas`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * getInveterDatas
   */
  public getInveterCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorStation/energy/inveter/charts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * inveterExport
   */
  public inveterExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorStation/energy/inveter/export`,
      params
    );
  }

  /**
   * getInveterDatas
   */
  public getMeterDatas(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorStation/energy/meter/datas`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * getInveterDatas
   */
  public getMeterCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorStation/energy/meter/charts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * inveterExport
   */
  public meterExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorStation/energy/meter/export`,
      params
    );
  }

  /**
   * getDeviceStatusDatas
   */
  public getDeviceStatusDatas(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorStation/deviceStatus/datas`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * getInveterDatas
   */
  public getDeviceStatusCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorStation/deviceStatus/charts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * inveterExport
   */
  public deviceStatusExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorStation/deviceStatus/export`,
      params
    );
  }

  // 箱变
  public getDeviceParents(params) {
    return this.http
      .get(`${this.apiUrl}/api/monitorDevice/parents`, {
        params: this.common.filterEffectParam(params),
      })
      .pipe(
        map((res: any) => {
          if (!res) {
            return [];
          }
          return res.map((item) => {
            return { label: item.name, value: item.id };
          });
        })
      );
  }

  /**
   * getNBDatas
   */
  public getNBDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/NB/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public nbExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/NB/export`,
      params
    );
  }

  /**
   * getNbCharts
   */
  public getNbCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/NB/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getDNBDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/DNB/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  /**
   * getDNBCharts
   */
  public getDNBCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/DNB/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }
  /**
   * dnbExport
   */
  public dnbExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/DNB/export`,
      params
    );
  }

  // 逆变器
  public getInverterDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/inverter/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getInverterCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/inverter/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public inverterExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/inverter/export`,
      params
    );
  }

  // 汇流箱
  public getCombinerBoxDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/combinerBox/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getCombinerBoxCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorDevice/combinerBox/charts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }

  public combinerBoxExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/combinerBox/export`,
      params
    );
  }

  // 电表
  public getEMeterDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/meter/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getEMeterCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/meter/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public eMeterExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/meter/export`,
      params
    );
  }

  // 箱变
  public getTransformerDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/transformer/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getTransformerCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorDevice/transformer/charts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }

  public transformerExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/transformer/export`,
      params
    );
  }
  public getWindDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/wind/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getWindCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/wind/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public windExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/wind/export`,
      params
    );
  }

  public getLineDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/outline/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getLineCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/outline/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public lineExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/outline/export`,
      params
    );
  }

  public getWeatherDatas(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorDevice/weatherStation/datas`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }

  public getWeatherCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorDevice/weatherStation/charts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }

  public weatherExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/weatherStation/export`,
      params
    );
  }

  public getOtherDatas(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/others/datas`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public otherExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/others/export`,
      params
    );
  }

  public getDeviceDetail(deviceId) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/detail/${deviceId}`);
  }

  /**
   * name
   */
  public getPointCharts(params) {
    return this.http.get(
      `${this.apiUrl}/api/monitorDevice/detail/pointCharts`,
      {
        params: this.common.filterEffectParam(params),
      }
    );
  }
  /**
   * name
   */
  public getTopo(params) {
    return this.http.get(`${this.apiUrl}/api/device/topo`, {
      params: this.common.filterEffectParam(params),
    });
  }
  /**
   * name
   */
  public getDetailCharts(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/detail/charts`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getDevicePics(deviceId) {
    return this.http.get(`${this.apiUrl}/api/device/devicePics`, {
      params: this.common.filterEffectParam({ deviceId }),
    });
  }

  public getWorkOrders(params) {
    return this.http.get(`${this.apiUrl}/api/workOrders`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getDeviceMap(params) {
    return this.http.get(`${this.apiUrl}/api/monitorDevice/deviceMap`, {
      params: this.common.filterEffectParam(params),
    });
  }

  /**
   * dnbExport
   */
  public windConfigExport(params) {
    this.common.fileExport(
      `${this.apiUrl}/api/monitorDevice/wind/config`,
      params
    );
  }

  // 附件上传
  public importFile(file) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${this.appConfig.apiUrl}/api/monitorDevice/wind/config`, formData)
      .toPromise();
  }
}
