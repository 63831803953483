import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LazyLoadEvent } from 'primeng/primeng';
import { forkJoin, fromEvent } from 'rxjs';
import * as moment from 'moment';
import { debounceTime } from 'rxjs/operators';
import * as _ from 'lodash';

import { CacheService } from './../../../../common/services/cache.service';
import { StationMonitorService } from './../../station-monitor.service';
import { CommonService } from './../../../../common/services/common.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { Constants } from 'src/app/common/Constants';
import { CodeTableService } from 'src/app/core/code-table.service';
import { buildMeterChart } from './chart/meter.chart';
import { buildTimeChart } from '../chart/time.chart';
import { buildBarChart } from '../chart/bar.chart';
import { buildLineChart } from '../chart/line.chart';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-electric-meter',
  templateUrl: './electric-meter.component.html',
  styleUrls: ['./electric-meter.component.less'],
})
export class ElectricMeterComponent implements OnInit, OnDestroy {
  public stationId: any;

  public backItem: any;

  public meterResizeEvent: any;
  public meterScrollHeight = '0px';

  public meterForm: any;
  public meterTypesList: any;

  public meterToggle: boolean = true;
  public meterOptions = null;
  public meterLoading = true;

  public meterTableTitle = '';
  public meterDataList = [];
  public defaultSortOrder = 1;
  public meterSortBy: any;
  public meterSortOrder: any;
  public meterTableloading: boolean = true;
  public meterPager = { page: 0, size: 50, totalRecords: 4 };
  public meterColumns = [];
  public timeChartFields = [];
  public lineChartFields = [];
  public barChartFields = [];
  intervalQuery: NodeJS.Timer;
  echartWidth: any;

  constructor(
    private common: CommonService,
    private stationmonitorservice: StationMonitorService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private codetableservice: CodeTableService,
    private formBuilder: FormBuilder,
    private tipservice: TipService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
      } else {
        this.stationId = params.stationId;
      }

      this.initMeterform();
      this.meterQuery(true);
    });

    this.meterResizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setMeterScrollHeight();
      });
  }

  // ngOnInit() {
  //   this.intervalQuery = setInterval(() => {
  //     this.lineQuery();
  //   }, 1 * 1000 * 60)
  // }

  // ngOnDestroy(): void {
  //   if (this.intervalQuery) {
  //     clearInterval(this.intervalQuery);
  //     this.intervalQuery = null
  //   }
  // }

  ngOnInit() {
    this.intervalQuery = setInterval(() => {
      this.meterQuery();
    }, 1 * 1000 * 60);
  }

  ngOnDestroy() {
    this.common.removeBackItem();
    if (this.intervalQuery) {
      clearInterval(this.intervalQuery);
      this.intervalQuery = null;
    }
  }

  public setMeterScrollHeight() {
    let meterHeight = 50 + 44 + 50 + 57;
    this.meterScrollHeight = this.common.setTableScrollHeight(meterHeight);
  }

  public initMeterform() {
    forkJoin(this.codetableservice.getNemsProp('meterType')).subscribe(
      (res) => {
        this.meterTypesList = res[0];
      }
    );

    this.meterForm = this.formBuilder.group({
      meterTypes: null,
      deviceName: null,
    });

    this.backItem = this.common.getBackItem();
    this.setMeterScrollHeight();
  }

  public meterQuery(first?) {
    if (first) {
      this.queryMeterTable(true);
      this.buildMeterChart(null);
    } else {
      this.meterPager.page = 0;
      this.refreshData();
    }
  }

  public queryMeterTable(first?) {
    if (first) {
      this.meterPager.page = 0;
      this.meterSortBy = 'AM-DNB-ZXYGZDN';
      this.meterSortOrder = 'asc';
    }
    let params = _.assign({}, this.meterForm.value, {
      page: this.meterPager.page,
      size: this.meterPager.size,
      stationId: this.stationId,
      sortBy: this.meterSortBy,
      sortOrder: this.meterSortOrder,
    });
    this.meterTableloading = true;
    this.stationmonitorservice.getEMeterDatas(params).subscribe((res: any) => {
      this.meterTableloading = false;
      this.timeChartFields = [];
      this.lineChartFields = [];
      this.barChartFields = [];
      if (res) {
        // this.meterSortBy = res.sortBy;
        // this.meterSortOrder = res.sortOrder;
        // this.defaultSortOrder = res.sortOrder === 'asc' ? 1 : -1;
        this.meterColumns = res.headers;
        this.meterDataList = res.datas.content;
        if (
          this.meterDataList.length === 0 &&
          params.deviceName &&
          !params.meterTypes
        ) {
          this.tipservice.tip(
            'warn',
            `当前电站没有【${params.deviceName}】设备`,
            '查询设备提示'
          );
        }
        this.meterPager.totalRecords = res.datas.totalElements;
        for (let header of res.headers) {
          if (header.chart === 'time') {
            this.timeChartFields.push(header.code);
          }
          if (header.chart === 'line') {
            this.lineChartFields.push(header.code);
          }
          if (header.chart === 'bar') {
            this.barChartFields.push(header.code);
          }
        }
      }
    });
  }

  // 创建逆变器chart
  public buildMeterChart(column?) {
    this.meterLoading = true;
    let params = _.assign({}, this.meterForm.value, {
      stationId: this.stationId,
      column,
    });
    this.stationmonitorservice.getEMeterCharts(params).subscribe((res: any) => {
      this.echartWidth = (
        document.querySelector('.list-content') as any
      ).offsetWidth;
      this.meterLoading = false;
      this.meterTableTitle = res.title;
      if (this.timeChartFields.indexOf(column) !== -1) {
        this.meterOptions = buildTimeChart(res);
      }

      if (this.barChartFields.indexOf(column) !== -1) {
        this.meterOptions = buildBarChart(res);
      }

      if (this.lineChartFields.indexOf(column) !== -1 || column === null) {
        this.meterOptions = buildLineChart(res);
      }
      // this.meterToggle = false;
    });
  }

  public meterCustomSort(event: LazyLoadEvent) {
    this.meterSortBy = event.sortField;
    this.meterSortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
    this.refreshData();
  }

  public refreshData() {
    this.queryMeterTable();

    if (
      this.timeChartFields.indexOf(this.meterSortBy) !== -1 ||
      this.lineChartFields.indexOf(this.meterSortBy) !== -1 ||
      this.barChartFields.indexOf(this.meterSortBy) !== -1
    ) {
      this.meterToggle = true;
      this.buildMeterChart(this.meterSortBy);
    }
  }

  /**
   * onPageChange
   */
  public onMeterPageChange(event) {
    this.meterPager.size = event.rows;
    this.meterPager.page = event.page;
    this.queryMeterTable();
  }

  /**
   * export
   */
  public meterExport() {
    let params = _.assign({}, this.meterForm.value, {
      stationId: this.stationId,
    });
    this.stationmonitorservice.eMeterExport(params);
  }

  /**
   * deviceDetail
   */
  public deviceDetail(deviceId, stationId, deviceType) {
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });
  }
}
