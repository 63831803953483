import {
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";
import { FormControl, FormGroup, FormBuilder } from "@angular/forms";
import { Dropdown, SelectItem } from "primeng/primeng";
import * as _ from "lodash";

import { CommonService } from "../../../services/common.service";
import { AreaList } from "../object-selector.model";
import { ObjectSelectorService } from "../object-selector.service";
import { TipService } from "@common/services/tip.service";

@Component({
  selector: "mpt-area-object",
  templateUrl: "area-object.component.html",
  styleUrls: ["../object-selector.component.less"]
})
export class AreaObjectComponent implements OnInit, OnChanges {
  @Input() public isRadio: boolean;
  @Input() public queryIndex: number;
  @Input() public stationId: string;
  @Input() public selected: any[] = [];
  @Output() public isShow = new EventEmitter<boolean>();
  @Output() public submitDataEvent = new EventEmitter<AreaList[]>();

  public isLoading = false;
  public isSubmitAllData = false;
  public queryForm: FormGroup;
  public stationList: SelectItem[] = []; // 电站
  public stationAreaData: AreaList[] = []; // 表格数据
  public selectedData: any[] = []; // 已选择数据
  public pager = { pageCurrent: 0, totalRecords: 0, pageSize: 10 };

  constructor(
    private fb: FormBuilder,
    private common: CommonService,
    private objectSelectorService: ObjectSelectorService,
    private tipservice: TipService
  ) { }

  public ngOnInit() {
    this.formInit();
    if (this.stationId) {
      this.queryForm.patchValue({
        stationId: this.stationId
      });
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.stationId) {
      if (changes.stationId.currentValue) {
        if (!this.queryForm) {
          this.formInit();
        }
        this.queryForm.patchValue({
          stationId: changes.stationId.currentValue
        });
        this.queryList();
      }
    }
    if (changes.queryIndex) {
      if (changes.queryIndex.currentValue) {
        this.queryList();
      }
    }
    if (changes.selected) {
      // if (_.isEmpty(changes.selected.currentValue)) {
      //   this.selectedData = [];
      // }
      if (
        !_.isEmpty(changes.selected.currentValue) &&
        !_.isEmpty(this.stationAreaData)
      ) {
        let initSelectedData = [];
        for (let data of changes.selected.currentValue) {
          initSelectedData = _.concat(
            initSelectedData,
            _.find(this.stationAreaData, (item: any) => {
              return item.psrId === data.psrId;
            })
          );
        }
        this.selectedData = _.concat(
          [],
          this.selectedData,
          initSelectedData
        );
      }
    }
  }

  // form初始化
  public formInit() {
    this.queryForm = this.fb.group({
      stationId: new FormControl()
    });

    // 电站初始化
    this.objectSelectorService
      .getSampleStationAll()
      .subscribe((data: any) => {
        for (let val of data) {
          this.stationList.push({
            label: val.shortName,
            value: val.stationId
          });
        }
        if (data.length && !this.stationId) {
          this.queryForm.patchValue({
            stationId: this.stationList[0].value
          });

          // 表格初始化
          this.queryList();
        }
      });
  }

  // 获取查询参数
  public getParam() {
    let params = this.queryForm.value;
    _.forIn(params, (value, key) => {
      params[key] = value ? value : null;
    });
    return params;
  }

  // 表格查询
  public queryList(isQuery?: boolean) {
    if (isQuery) {
      this.pager.pageCurrent = 0;
    }
    this.isLoading = true;
    this.objectSelectorService
      .getStationAreaList(
        this.getParam().stationId,
        this.pager.pageCurrent,
        this.pager.pageSize
      )
      .subscribe(
        (res: any) => {
          this.pager.totalRecords = res.totalElements;
          this.stationAreaData = res.content;
        },
        error => {
          this.isLoading = false;
        },
        () => {
          this.isLoading = false;
        }
      );
  }

  // 分页事件
  public onPageChange(event) {
    this.pager.pageCurrent = event.page;
    this.pager.pageSize = parseFloat(event.rows);
    this.queryList();
  }

  // 提交
  public submitData() {
    // if (this.selectedData.length === 0) {
    //   this.tipservice.tip('warn', '请最少选择一条数据！');
    //   return false;
    // }
    this.selectedData = this.objectSelectorService.setSelectedDataType(
      this.selectedData,
      "04"
    );
    this.submitDataEvent.emit(this.selectedData);
    this.isShow.emit(false);
  }

  // 选择全部
  public chooseAllData() {
    if (this.pager.totalRecords === 0) {
      this.tipservice.tip("warn", "当前没有数据可选择！");
      return false;
    }
    this.isSubmitAllData = true;
    this.selectedData = this.stationAreaData;
  }

  // 提交 全部
  public submitAllData() {
    // 获取全部数据接口
    this.objectSelectorService
      .getStationAreaListAll(this.getParam().stationId)
      .then((res: any) => {
        this.selectedData = res;
        this.isSubmitAllData = false;
        this.selectedData = this.objectSelectorService.setSelectedDataType(
          this.selectedData,
          "04"
        );
        this.submitDataEvent.emit(this.selectedData);
        this.isShow.emit(false);
      });
  }

  // 取消选择全部
  public onCancelAllData() {
    this.isSubmitAllData = false;
    this.selectedData = [];
  }

  // 取消
  public cancelData() {
    this.isShow.emit(false);
  }
}
