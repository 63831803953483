import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { HeaderService } from '../../../layout/header/header.service';
import { ConfigType } from '../../../layout/header/header.model';
import { MenuItem, SelectItem } from 'primeng/primeng';
import { ActivatedRoute } from '@angular/router';
import { PointConfigService } from './point-config.service';
import { CommonService } from '../../../../common/services/common.service';
import { NullItem } from './point-config.model';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'mpt-point-config',
  templateUrl: 'point-config.component.html',
  styleUrls: ['point-config.component.less']
})
export class PointConfigComponent implements OnInit {
  public isShow = false;
  public operaBtnItems: MenuItem[] = [
    {
      label: '只应用于当前设备',
      icon: 'select',
      command: (e) => {
        this.selectItem(e);
        this.selectOpera = '01';
        this.savePointConfigs();
      }
    },
    {
      label: '应用于同型号设备',
      icon: '',
      command: (e) => {
        this.selectItem(e);
        this.selectOpera = '02';
        this.savePointConfigs();
      }
    }
  ];

  public selectOpera = '01';

  public option: SelectItem[] = [{ label: '遥信/遥测', value: '01' }];
  public stations = [
    {
      name: '关机',
      value: '1',
      type: '01',
      road: '',
      status: '',
      way: '01',
      show: '01',
      unit: '1',
      tranform: '',
      rule: '0',
      newRole: '1'
    }
  ];

  public caseInfo: any[] = [];

  public showVolumnDialog = false;
  public volumnData: any;
  public deviceId: string;

  public remoteList = [];
  public alarmList = [];
  public telemetryList = [];
  public chooseList = {
    yxPointTypes: [],
    ycPointTypes: [],
    pvInputs: []
  };
  public confirmDialog = false;
  @Output() public pointConfigChange = new EventEmitter<any>();
  constructor(
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private pointConfigService: PointConfigService,
    private commonService: CommonService,
    private tipservice: TipService
  ) {
    this.headerService.configSubject.subscribe((res: any) => {
      // if (type === ConfigType.point) {
      //     this.getPointConfigs();
      // }
      if (res.deviceId) {
        this.deviceId = res.deviceId;
        this.getPointConfigs();
      }
    });
    // this.activatedRoute.params.subscribe((params: { id: string }) => {
    //     this.deviceId = params.id;
    // });
  }

  ngOnInit() { }

  public getVolumnDialogData(e) {
    this.volumnData = e;
  }

  public savePointConfigs() {
    let param = {
      saveType: this.selectOpera,
      deviceId: this.deviceId,
      points: this.remoteList
        .concat(this.alarmList)
        .concat(this.telemetryList)
    };
    this.pointConfigService.savePointConfigs(param).subscribe(
      () => {
        this.tipservice.tip('success', '保存成功');
        this.isShow = false;
        this.pointConfigChange.emit();
      },
      () => {
        this.tipservice.tip('error', '保存失败');
      }
    );
  }

  public getPointConfigs() {
    // this.commonService.mask.show();
    this.pointConfigService.getPointConfigs(this.deviceId).subscribe(
      (res: any) => {
        this.caseInfo = res.sysCategories;
        this.setTableList(res.points);
        this.chooseList = {
          yxPointTypes: this.pointConfigService.formateDropdownData(
            res.yxPointTypes,
            'cateName',
            'cateId'
          ),
          ycPointTypes: this.pointConfigService.formateDropdownData(
            res.ycPointTypes,
            'cateName',
            'cateId'
          ),
          pvInputs: this.pointConfigService.formateDropdownData(
            res.pvInputs,
            'propName',
            'propValue'
          )
        };
        this.isShow = true;
      },
      (error) => {
        this.commonService.errorMessage(error);
      },
      () => {
        this.commonService.mask.hide();
      }
    );
  }

  public setChooseList(dataList) {
    for (let info of this.caseInfo) {
      let count = 0;
      info.pointCount = dataList.filter(
        (v) => v.pointCategory.sysCateId === info.cateId
      ).length;
    }
  }

  public submitConfirmDialog() {
    this.isShow = false;
    this.confirmDialog = false;
  }

  public pointTypeChange(data) {
    let point = data.rowData;
    this.setChooseList(
      this.remoteList.concat(this.alarmList).concat(this.telemetryList)
    );
    if (!data.flag) {
      return;
    }
    if (
      point.pointCategory.remotionType === '01' ||
      point.pointCategory.remotionType === '02'
    ) {
      if (
        point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-FAULT' ||
        point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-WARN' ||
        point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-INFO' ||
        point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-LOG'
      ) {
        this.alarmList.push(point);
        this.alarmList = [...this.alarmList];
      } else {
        this.remoteList.push(point);
        this.remoteList = [...this.remoteList];
      }
    } else {
      this.telemetryList.push(point);
      this.telemetryList = [...this.telemetryList];
    }
  }

  private setTableList(list) {
    this.remoteList = [];
    this.alarmList = [];
    this.telemetryList = [];
    for (let point of list) {
      if (
        point.pointCategory.remotionType === '01' ||
        point.pointCategory.remotionType === '02'
      ) {
        if (
          point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-FAULT' ||
          point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-WARN' ||
          point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-INFO' ||
          point.pointCategory.sysCateId === 'AM-DEVICE-ALARM-LOG'
        ) {
          this.alarmList.push(point);
        } else {
          this.remoteList.push(point);
        }
      } else {
        this.telemetryList.push(point);
      }
    }
  }

  private selectItem(e) {
    for (let item of this.operaBtnItems) {
      item.icon = '';
    }
    e.item.icon = 'select';
  }
}
