export class Notify {
  public id: number;
  public station: string;
  public type: string;
  public level: string[];
  public object: string[];
  public email: string;
  public weixin: string;
  public dialog: string;
  public sound: string;
};

export interface TablePageJson {
  content: any[];
  totalPages: number;
  last: boolean;
  totalElements: number;
  sort: any;
  size: number;
  number: number;
  first: boolean;
  numberOfElements: number;
}
