import { NgModule } from '@angular/core';
import { DialogModule, ButtonModule } from 'primeng/primeng';

import { SharedModule } from '../../../shared/shared.module';
import { DeptSelectorComponent } from './dept-selector.component';
import { DeptSelectorService } from './dept-selector.service';
import { TreeviewModule } from '../treeview/index';

@NgModule({
  imports: [
    SharedModule,
    DialogModule,
    ButtonModule,
    TreeviewModule.forRoot()
  ],
  exports: [
    DeptSelectorComponent
  ],
  declarations: [
    DeptSelectorComponent
  ],
  providers: [
    DeptSelectorService
  ],
})
export class DeptSelectorModule { }
