import {
    Component,
    OnInit,
    Input,
    OnChanges,
    SimpleChanges
} from "@angular/core";
import { Router } from "@angular/router";
import * as moment from "moment";

import { PropTypesService } from "../../../system/prop-types/prop-types.service";
import { Constants } from "../../../../common/Constants";
import { HeaderService } from "../header.service";
import { Message, MessageInfo } from "./message.model";

@Component({
    selector: "mpt-message",
    templateUrl: "message.component.html",
    styleUrls: ["./message.component.less"]
})
export class MessageComponent implements OnInit, OnChanges {
    @Input() public flag;
    public messages: Message[] = [];
    public interval;
    private map = {};
    public totalElements = 0;

    public constructor(
        private headerService: HeaderService,
        private propTypesService: PropTypesService
    ) {}

    public ngOnInit(): void {
        this.loadMessages(false);
        this.setTime();
        this.propTypesService
            .getPropValueList("docRecordType")
            .subscribe((res: any) => {
                res.forEach(r => {
                    this.map[r.propValue] = r.propName;
                });
            });
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.flag) {
            this.loadMessages(false);
        }
    }

    public loadMessages(methodFlag) {
        this.headerService
            .getAppMessage({ page: 0, size: 10 })
            .subscribe(data => {
                let messageInfo: MessageInfo = {
                    flag: methodFlag,
                    messageCount: data.totalElements
                };
                this.headerService.display.next(messageInfo);
                this.totalElements = data.totalElements;
                this.messages = data.content;
                this.messages.forEach(res => {
                    res.sendTime = moment(res.sendTime).format(
                        Constants.DATETIME_NO_YEAR
                    );
                    res.icon = this.getIcon(res.recordType);
                    res.recordType = this.map[res.recordType];
                });
            });
    }

    private setTime() {
        clearInterval(this.interval);
        this.interval = setInterval(() => {
            this.loadMessages(false);
        }, 1000 * 60 * 5);
    }

    private removeAt(msgId) {
        for (let i = 0; i < this.messages.length; i++) {
            if (msgId === this.messages[i].msgId) {
                this.messages.splice(i, 1);
            }
        }

        this.headerService.updateAppMessage(msgId).subscribe(() => {
            this.loadMessages(true);
        });
    }

    private getIcon(recordType) {
        if (recordType === "B03") {
            return "#icon-shebei";
        } else if (recordType === "B04") {
            return "#icon-weihu";
            // } else if (recordType === 'B02') {
            //   return '#icon-quexian';
            // } else if (recordType === 'B05') {
            //   return '#icon-jigai';
            // } else if (recordType === 'B06') {
            //   return '#icon-jiance';
            // } else if (recordType === 'O05') {
            //   return '#icon-xunshi';
            // } else if (recordType === 'O06') {
            //   return '#icon-bujian';
        } else {
            return "#icon-moren";
        }
    }
}
