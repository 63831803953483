import { NgModule } from '@angular/core';
import { VideoPlayDialogComponent } from './video-play-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { DialogModule } from 'primeng/dialog';
import { VideoPlayDialogService } from './video-play-dialog.service';

const primengModule = [
  DialogModule
]

@NgModule({
  declarations: [VideoPlayDialogComponent],
  imports: [
    SharedModule,
    ...primengModule
  ],
  exports: [VideoPlayDialogComponent],
  providers: [VideoPlayDialogService]
})
export class VideoPlayDialogModule { }
