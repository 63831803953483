import { CalendarModule } from 'primeng/primeng';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { PowerGenerationComponent } from './power-generation/power-generation.component';
import { EquivalentHoursComponent } from './equivalent-hours/equivalent-hours.component';
import { OnlineRateComponent } from './online-rate/online-rate.component';
import { CommunicationStatusComponent } from './communication-status/communication-status.component';
import { AlarmStatisticsComponent } from './alarm-statistics/alarm-statistics.component';
import { WeatherComponent } from './weather/weather.component';
import { ContainerComponent } from './container/container.component';
import { CalendarLocales } from '@shared/locales/calendar.locales';
import { StationInfoComponent } from './station/station-info/station-info.component';
import { StationMonitorComponent } from './station/station-monitor/station-monitor.component';
import { StationDeviceStatusComponent } from './station/device-status/device-status.component';
import { StationPowerGenerationStatisticsComponent } from './station/power-generation-statistics/power-generation-statistics.component';
import { StationRealTimePowerComponent } from './station/real-time-power/real-time-power.component';
import { StationPowerGenerationTrendComponent } from './station/power-generation-trend/power-generation-trend.component';

import { WidgetsService } from './widgets.service';
import { StationEquivalentHoursComponent } from './station/equivalent-hours/equivalent-hours.component';
import { StationOnlineRateComponent } from './station/online-rate/online-rate.component';
import { StationCommunicationStatusComponent } from './station/communication-status/communication-status.component';
import { StationAlarmStatisticsComponent } from './station/alarm-statistics/alarm-statistics.component';
import { VideoPlayDialogModule } from '../video-play-dialog/video-play-dialog.module';
import { BasicDataService } from '@components/basic-data/shared/basic-data.service';

const primengModules = [CalendarModule];

@NgModule({
  declarations: [
    PowerGenerationComponent,
    EquivalentHoursComponent,
    OnlineRateComponent,
    CommunicationStatusComponent,
    AlarmStatisticsComponent,
    WeatherComponent,
    ContainerComponent,
    StationInfoComponent,
    StationMonitorComponent,
    StationDeviceStatusComponent,
    StationPowerGenerationStatisticsComponent,
    StationRealTimePowerComponent,
    StationPowerGenerationTrendComponent,
    StationEquivalentHoursComponent,
    StationOnlineRateComponent,
    StationCommunicationStatusComponent,
    StationAlarmStatisticsComponent,
  ],
  imports: [SharedModule, ...primengModules, VideoPlayDialogModule],
  exports: [
    PowerGenerationComponent,
    EquivalentHoursComponent,
    OnlineRateComponent,
    CommunicationStatusComponent,
    AlarmStatisticsComponent,
    WeatherComponent,
    ContainerComponent,
    StationInfoComponent,
    StationMonitorComponent,
    StationDeviceStatusComponent,
    StationPowerGenerationStatisticsComponent,
    StationRealTimePowerComponent,
    StationPowerGenerationTrendComponent,
    StationEquivalentHoursComponent,
    StationOnlineRateComponent,
    StationCommunicationStatusComponent,
    StationAlarmStatisticsComponent,
  ],
  providers: [CalendarLocales, WidgetsService, BasicDataService],
})
export class WidgetsModule {}
