import { Injectable } from '@angular/core';
import { AppConfigService } from 'src/app/app.config';
import { CommonService } from 'src/app/common/services/common.service';
import { AuthHttp } from 'src/app/core/auth-http';

@Injectable({
  providedIn: 'root',
})
export class TeamService {
  private apiUrl: string;
  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {
    this.apiUrl = this.appConfig.apiUrl;
  }

  public getTeamList(params) {
    return this.http.get(`${this.apiUrl}/api/teams`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public getTeamByTeamId(teamId) {
    return this.http.get(`${this.apiUrl}/api/teams/${teamId}`);
  }

  public saveTeam(params) {
    return this.http.post(`${this.apiUrl}/api/teams`, params);
  }

  public updateTeam(params) {
    return this.http.put(`${this.apiUrl}/api/teams`, params);
  }

  public deleteTeam(teamId) {
    return this.http.delete(`${this.apiUrl}/api/teams`, {
      search: { teamId },
    });
  }

  public getDepartments() {
    return this.http.get(`${this.apiUrl}/api/nems/depts/tree`);
  }
}
