import { InvertersService } from './inverters.service';
import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { StationMonitorService } from '@components/station-monitor/station-monitor.service';

@Component({
  selector: 'app-inverters',
  templateUrl: './inverters.component.html',
  styleUrls: ['./inverters.component.less'],
})
export class InvertersComponent implements OnInit, OnChanges {
  @Input() stationId: String;

  @Output() deviceDetailEvent = new EventEmitter();

  isShow = false;

  public invertersList = [];

  showConfig: boolean = false;
  legends: any = [];

  constructor(
    private invertersservice: InvertersService,
    private stationmonitorservice: StationMonitorService
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.stationId && changes.stationId.currentValue) {
      this.getStationInverters();
    }
  }

  public async getStationInverters() {
    const data = await this.invertersservice.getStationInverters({
      stationId: this.stationId,
    });
    this.invertersList = data.devices;
    this.legends = data.legends;
  }

  public deviceDetail(deviceId, station, deviceType) {
    this.deviceDetailEvent.emit({
      deviceId,
      station,
      deviceType,
    });
  }
}
