export function buildDeviceBarChart(returnValue) {
    // 指定图表的配置项和数据
    var option = {
        //color: ['#37A2DA', '#32C5E9', '#67E0E3', '#9FE6B8', '#FFDB5C', '#FF9F7F', '#FB7293', '#E062AE', '#9D96F5', '#8378EA', '#727272'],
        backgroundColor: 'rgba(255, 255, 255, 0)',
        grid: {
            left: '3%',
            width: '92%',
            containLabel: true
        },
        tooltip: {
            trigger: 'axis',
            formatter: function(param) {
                var tip = null,
                    subTip = null;
                for (var i in param) {
                    if (param[i].seriesType == 'line') {
                        if (
                            param[i].seriesName != returnValue.tooltip.avgLabel
                        ) {
                            if (param[i].data == null) {
                                subTip =
                                    param[i].marker +
                                    returnValue.tooltip.subLabel +
                                    ': ';
                            } else {
                                subTip =
                                    param[i].marker +
                                    returnValue.tooltip.subLabel +
                                    ': ' +
                                    param[i].data +
                                    ' ' +
                                    returnValue.tooltip.subUnit;
                            }
                        }
                    } else if (param[i].data != null) {
                        tip =
                            param[i].name +
                            '</br>' +
                            param[i].marker +
                            returnValue.tooltip.mainLabel +
                            ': ' +
                            param[i].data +
                            ' ' +
                            returnValue.tooltip.mainUnit +
                            '（排名：' +
                            (param[i].dataIndex + 1) +
                            '）</br>';
                    }
                }
                if (tip == null) {
                    if (param[returnValue.tooltip.nullIndex]) {
                        tip =
                            param[returnValue.tooltip.nullIndex].name +
                            '</br>' +
                            param[returnValue.tooltip.nullIndex].marker +
                            returnValue.tooltip.mainLabel +
                            ': （排名：' +
                            (param[returnValue.tooltip.nullIndex].dataIndex +
                                1) +
                            '）</br>';
                    }
                }
                if (subTip == null) {
                    return tip;
                } else {
                    return tip + subTip;
                }
            }
        },
        toolbox: {
            feature: {
                dataZoom: {},
                restore: {},
                dataView: {},
                saveAsImage: {}
            }
        },
        dataZoom: [
            {
                type: 'inside',
                realtime: true
            },
            {
                realtime: true
            }
        ],
        legend: {
            show: true,
            data: returnValue.option.legend.data
        },
        xAxis: {
            type: 'category',
            data: returnValue.option.xAxis.data,
            axisLabel: { show: false }
        },
        yAxis: returnValue.option.yAxis,
        series: returnValue.option.series
    };

    return option;
}
