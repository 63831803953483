import { CommonService } from 'src/app/common/services/common.service';
import { DialogModule } from 'primeng/primeng';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgModule } from '@angular/core';
import { LocationComponent } from './location.component';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { LocationService } from './location.service';
import { BaiduMapModule } from 'angular2-baidu-map';

const primengModule = [InputTextModule, ButtonModule, DialogModule];

@NgModule({
  declarations: [LocationComponent],
  imports: [
    SharedModule,
    BaiduMapModule.forRoot({ ak: '9FRER16abY0A2ulwTK0wNWRdhlIEdR5l' }),
    ...primengModule,
  ],
  exports: [LocationComponent],
  providers: [LocationService, CommonService],
})
export class LocationModule { }
