import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TreeviewModule } from '../../../../common/components/treeview';
import { WarehouseComponent } from './warehouse.component';
import { DeviceAssetsService } from '../device-assets.service';

@NgModule({
  imports: [
    CommonModule,
    TreeviewModule.forRoot()
  ],
  exports: [
    WarehouseComponent
  ],
  declarations: [
    WarehouseComponent
  ],
  providers: [
    DeviceAssetsService
  ]
})
export class WarehouseModule {}
