import { FileService } from '../../common/services/file.service';
import { CommonService } from '../../common/services/common.service';
import { AuthHttp } from '../../core/auth-http';
import { AppConfigService } from '../../app.config';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class MaintainsService {
  public apiUrl = this.appConfig.apiUrl;
  constructor(
    private appConfig: AppConfigService,
    private http: AuthHttp,
    private commonservice: CommonService,
    private fileservice: FileService
  ) { }

  /**
   * 巡检单列表
   */
  public getMaintainsList(params) {
    return this.http.get(`${this.apiUrl}/api/maintains`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }
  /**
   * 导出巡检单
   */
  public exportMaintains(params) {
    return this.commonservice.fileExport(
      `${this.apiUrl}/api/maintains/export`,
      params
    );
  }

  public getMaintainsBymaintainId(maintainId, params) {
    return this.http.get(`${this.apiUrl}/api/maintains/${maintainId}`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public deleteMaintain(inspectionIds) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/maintains`, {
      search: { inspectionIds },
    });
  }

  public saveMaintain(params) {
    return this.http.post(`${this.appConfig.apiUrl}/api/maintains`, params);
  }

  public maintainAction(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/maintains`, params);
  }

  // 获取 表单配置
  public getNullable(targetNodeId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/workflows/nullable`, {
        params: { targetNodeId },
      })
      .toPromise();
  }

  // 催单
  public remind(ticketId) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/maintains/remind`,
      {},
      // { params: { ticketId }, responseType: 'text' }
      { params: { ticketId } }
    );
  }

  public getMaintainsObjItems(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/maintains/items`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public check(params) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/maintains/check`,
      {},
      { params }
    );
  }

  /**
   * getMaintainsItems
   */
  public getMaintainsItems(params) {
    return this.http.get(`${this.apiUrl}/api/report/maintains/items`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  // 导出巡检明细
  public exportMaintainsItems(params) {
    return this.commonservice.fileExport(
      `${this.apiUrl}/api/report/maintains/items/export`,
      params
    );
  }

  public getMaintainsObjFiles(objItemId) {
    return this.http.get(`${this.apiUrl}/api/maintains/obj/files`, {
      params: {
        objItemId,
      },
    });
  }
  public uploadObjFile(file, params) {
    return this.fileservice.uploadDocs(
      '/api/maintains/obj/files',
      file,
      params
    );
  }

  public deleteObjFile(docIds) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/doc/delete`, {
      params: { docIds },
    });
  }

  public getObjItem(params) {
    return this.http.get(`${this.apiUrl}/api/maintains/obj`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public getObjItemHis(params) {
    return this.http.get(`${this.apiUrl}/api/maintains/obj/itemhis`, {
      params: this.commonservice.filterEffectParam(params),
    });
  }

  public saveObjItem(params) {
    return this.http.post(`${this.apiUrl}/api/maintains/obj`, params);
  }

  public deleteObjItem(objId) {
    return this.http.delete(`${this.apiUrl}/api/maintains/obj`, {
      params: { objId },
    });
  }

  public uploadPic(file, params) {
    return this.fileservice.uploadDocs('/api/maintains/pic', file, params);
  }
}
