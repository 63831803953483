import { CommonService } from 'src/app/common/services/common.service';
import { CommentsService } from './../../services/comments.service';
import { Component, OnInit, Input } from '@angular/core';
import * as _ from 'lodash';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.less'],
})
export class CommentComponent implements OnInit {
  @Input() orderId = null;
  @Input() commentsList = [];

  comment = '';

  constructor(private commentsservice: CommentsService, private commonservice: CommonService, private tipservice: TipService) { }

  ngOnInit() { }

  submitComment(event) {
    if (this.comment) {
      if (this.comment.length > 500) {
        return this.tipservice.tip('warn', '评论内容不能超过500字符');
      }
    } else {
      return this.tipservice.tip('warn', '请输入评论内容');
    }
    this.commentsservice
      .addWorkOrdersComments({
        orderId: this.orderId,
        comment: this.comment,
      })
      .then((res) => {
        this.commentsList.unshift(res);
        this.comment = '';
        this.tipservice.tip('success', '评论成功');
      });
  }

  deleteComment(commentId) {
    this.commentsservice.deleteComments(commentId).then((res) => {
      _.remove(this.commentsList, (o) => {
        return o.commentId === commentId;
      });
      this.tipservice.tip('success', '删除成功');
    });
  }
}
