import { StationMonitorService } from 'src/app/components/station-monitor/station-monitor.service';
import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';

import { CommonService } from '../../../../../common/services/common.service';

import { debounceTime } from 'rxjs/operators';
import { DeviceManageService } from 'src/app/components/device-assets/device-manage/device-manage.service';
import { PageType } from '../../equipment-files/equipment-files.component.model';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'app-device-sub-info',
  templateUrl: 'device-sub-info.component.html',
  styleUrls: ['../../device-detail.component.less'],
})
export class DeviceSubInfoComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public deviceID;
  @Input() public type;
  @Input() public stationId;
  @Input() public showButton = true;

  @Output() childrenDeviceChagne = new EventEmitter();

  public pager = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };

  public scrollHeight: string;
  public resizeEvent: any;
  public PageType = PageType;

  //子设备数据
  public subDeviceData: any[];
  public isShowTabViewDia: boolean = false; //显示设备弹窗
  public tabs: string[] = ['di']; //设备信息tab页面
  public diaDeviceId: string; //弹窗页面设备id
  public diaType: string = PageType.NEW; //弹窗类型
  public diaStationId: string; //弹窗站点id
  public isSubTab: boolean = false; //是否为自设备弹窗

  //设备选择弹窗
  public isShowObjectDialog: boolean = false; //显示选择设备弹窗
  public chanParam: any = {
    pDeviceId: this.deviceID, //父设备ID
    oldChildDeviceId: null, //老子设备id
    newChildDeviceId: '', //新子设备id
    inputNo: null, //端口
  }; //更新自设备管理参数
  public oldSubDevice: any; //老的子设备
  public newSubDevice: any; //新的子设备
  public isChangeSub: boolean = false; //标识是否是更新子设备
  public deleteChildDeviceDialog: boolean = false; //显示删除子设备弹窗
  public deleteType: string = 'deleteRela'; //子设备删除类型
  public childDeviceIds: any[];
  addDevice: string;
  tieredMenuItem: any[] = [];
  subDeviceType: any;
  subDeviceId: any;
  subStationId: any;
  subDevice: boolean;
  loading: boolean;
  pDevicedId: any;
  areaId: any;
  pDeviceNo: any;

  constructor(
    private common: CommonService,
    private deviceManageService: DeviceManageService,
    private route: ActivatedRoute,
    private stationmonitorservice: StationMonitorService,
    private tipservice: TipService
  ) {
    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setScrollHeight();
      });
  }

  public ngOnInit() {
    this.setScrollHeight();

    this.getDeviceType();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.deviceID && changes.deviceID.currentValue) {
      this.querySubList();
    }
  }

  public ngOnDestroy() {
    this.resizeEvent.unsubscribe();
  }

  public getDeviceType() {
    this.deviceManageService.createDeviceTypes().subscribe((res: any[]) => {
      this.tieredMenuItem = [];
      res.map((item) => {
        if (this.tieredMenuItem.length > 0) {
          this.tieredMenuItem.push({ separator: true });
        }
        this.tieredMenuItem.push({
          label: item.propName,
          icon: null,
          command: () => {
            this.showSubTabViewDia(null, item.propValue, null, 'add');
          },
        });
      });
    });
  }

  public setScrollHeight() {
    this.scrollHeight = document.body.clientHeight - 215 - 60 + 'px';
  }

  /*---------------------------子设备列表---------------------------*/
  // 列表查询
  public querySubList(isQuery?: boolean) {
    if (isQuery) {
      this.pager.pageCurrent = 0;
    }
    this.loading = true;
    this.deviceManageService.getChildDevices(this.deviceID).subscribe(
      (res: any) => {
        this.subDeviceData = res;
      },
      (error) => {
        this.common.errorMessage(error);
      },
      () => {
        this.loading = false;
      }
    );
  }

  /*---------------------------------设备信息弹出框--------------------------------------------*/
  //显示新增子设备弹窗

  public showSubTabViewDia(deviceId, deviceType, stationId, type) {
    this.subDeviceType = deviceType;
    this.subDeviceId = deviceId || this.deviceID;
    this.addDevice = type;
    this.subStationId = this.stationId;

    if (type === 'add') {
      this.deviceManageService
        .getDeviceById(this.deviceID)
        .subscribe((res: any) => {
          this.pDevicedId = this.deviceID;
          this.pDeviceNo = res.deviceNo;
          this.areaId = res.areaId;
          this.subDevice = true;
          this.isShowTabViewDia = true;
        });
    } else {
      this.subDevice = false;
      this.isShowTabViewDia = true;
    }
  }

  // 新增窗口关闭刷新数据
  public refreshList(event) {
    this.isShowTabViewDia = false;
    this.querySubList(false);
  }

  // 显示更新设备选择弹窗
  public chanSubDevice(subDevice) {
    this.oldSubDevice = subDevice;
    this.isShowObjectDialog = true;
    this.isChangeSub = true;
  }

  //删除子设备弹窗
  public deleteSubDevice(subDeviceId) {
    this.childDeviceIds = [];
    this.childDeviceIds.push(subDeviceId);
    this.deleteChildDeviceDialog = true;
  }

  //删除子设备功能
  public deleteChildDevice(event) {
    this.common.mask.show();
    this.deviceManageService
      .deleteChildDevice({
        childDeviceIds: this.childDeviceIds,
        deleteType: this.deleteType,
      })
      .subscribe(
        (info) => {
          this.tipservice.tip(
            'success',
            this.deleteType == 'deleteDevice'
              ? '子设备删除成功'
              : '父子设备关联关系删除成功'
          );
          this.deleteChildDeviceDialog = false;
          this.common.mask.hide();
          this.querySubList(false);
          this.childrenDeviceChagne.emit(true);
        },
        (error) => {
          this.common.errorMessage(error);
          this.common.mask.hide();
        }
      );
  }
  /*---------------------------------设备信息弹出框--------------------------------------------*/
  //设备选中后的操作
  public deviceSelected(event) {
    let isNeedChange: boolean = true; //标识是否需要进行更新
    this.chanParam = {};
    this.chanParam.pDeviceId = this.deviceID;
    this.chanParam.newChildDeviceId = event.deviceId;
    //如果是更换子设备，则需要判断新的子设备是否和老的相同
    //不同则更新，并把老的设备端口设置在新的上，否则不更新
    if (this.isChangeSub) {
      if (this.oldSubDevice.deviceId !== event.deviceId) {
        this.chanParam.oldChildDeviceId = this.oldSubDevice.deviceId;
        this.chanParam.inputNo = this.oldSubDevice.inputNo;
      } else {
        isNeedChange = false;
      }
    }
    //判断是否需要执行更新操作
    if (isNeedChange) {
      this.common.mask.show();
      this.deviceManageService.changeChildDevice(this.chanParam).subscribe(
        (res) => {
          this.tipservice.tip('success', '设置子设备成功！');
          this.childrenDeviceChagne.emit(true);
          this.querySubList();
        },
        (error) => {
          this.common.errorMessage(error);
        },
        () => {
          this.isShowObjectDialog = false;
          this.common.mask.hide();
        }
      );
    }
  }
}
