export function buildDailyPowerGenerationChart(data) {
    // 指定图表的配置项和数据
    data = [
        {
            name: "内蒙乾华135MW地面集中式光伏电站",
            value: [111.284262, 40.344304, 885940.0]
        },
        { name: "商丘当店", value: [115.738182, 34.551813, 409428.8] },
        { name: "山东冠县宋村华博", value: [115.449025, 36.489694, 384120.0] },
        { name: "安徽阜阳颍上耿棚", value: [116.121541, 32.647993, 295750.0] },
        { name: "淮北刘桥永瑞", value: [113.262503, 35.190781, 281600.0] },
        {
            name: "泗洪梅花镇40MW农光互补光伏“领跑者”项目",
            value: [118.197523, 33.453856, 168300.0]
        },
        { name: "泗洪天岗湖", value: [116.785989, 33.944984, 165660.0] },
        {
            name: "安徽灵璧40.10292MWp地面分布式光伏电站",
            value: [117.534294, 33.82207, 151200.0]
        },
        { name: "安徽阜阳新盛电站", value: [116.948536, 34.164347, 149100.0] },
        {
            name: "阜阳永强新乌江二期",
            value: [115.851753, 32.891605, 117250.0]
        },
        {
            name: "宿州经开区河拐村20MW农光互补分布式光伏发电项目库1.",
            value: [116.974604, 33.601044, 117215.0]
        },
        {
            name: "萧县大屯镇20MW农光互补分布式光伏发电项目",
            value: [116.972251, 34.169984, 116760.0]
        },
        { name: "河南厢华", value: [118.159106, 39.61673, 111372.8] },
        { name: "阜阳永强李桥", value: [115.823026, 32.890527, 110950.0] },
        {
            name: "安徽阜阳新乌江一期",
            value: [116.166687, 32.869704, 108920.0]
        },
        {
            name: "安徽萧县华耀20MW农光互补光伏电站",
            value: [116.771111, 34.135592, 108920.0]
        },
        { name: "东营力诺", value: [118.312595, 37.906691, 106722.0] },
        { name: "城发华耀", value: [116.313582, 37.518698, 106260.0] },
        {
            name: "宿州经开区44.02684MW农光互补分布式光伏发电项目",
            value: [116.977577, 33.60714, 103985.0]
        },
        { name: "安徽亳州华阳", value: [115.913536, 33.68733, 102256.0] },
        { name: "安徽阜阳行流", value: [115.723941, 33.049921, 102200.0] },
        { name: "安徽阜阳伍明", value: [115.878888, 33.033457, 100800.0] },
        { name: "安徽临泉", value: [115.264704, 33.070225, 100520.0] },
        { name: "界首金农", value: [115.386274, 33.277882, 96600.0] },
        {
            name: "安徽萧县20MWp地面分布式光伏电站",
            value: [117.086415, 34.143958, 91840.0]
        },
        {
            name: "镇江金东20MW屋顶分布式光伏电站",
            value: [119.697593, 32.195312, 90090.0]
        },
        {
            name: "泗洪孙园镇20MW渔光互补分布式光伏发电项目",
            value: [118.222029, 33.462109, 89250.0]
        },
        { name: "启东合作", value: [121.637028, 31.926345, 87080.0] },
        { name: "灌南飞展三期", value: [115.878888, 33.033457, 80360.0] },
        {
            name: "启东近海镇18MW农光互补分布式光伏发电项目",
            value: [121.802013, 31.911794, 68400.0]
        },
        {
            name: "启东滨海园区鱼塘15MW光伏电站",
            value: [121.870979, 31.898399, 58800.0]
        },
        { name: "城发华耀二期", value: [116.297, 37.509, 53900.0] },
        { name: "后寨永创一期", value: [116.582684, 37.34141, 53760.0] },
        { name: "夏津力诺二期", value: [116.1511, 37.054587, 53571.0] },
        { name: "后寨永创二期", value: [116.582684, 37.34141, 51520.0] },
        { name: "灌南飞展二期", value: [115.878888, 33.033457, 50800.0] },
        { name: "夏津力诺一期", value: [116.059935, 37.026594, 50631.0] },
        { name: "金东二期", value: [119.672114, 32.194895, 48580.0] },
        { name: "惠民永正", value: [117.596994, 37.497634, 47600.0] },
        { name: "连云港朝阳电站", value: [119.307159, 34.683782, 47600.0] },
        { name: "连云港伊山", value: [119.291198, 34.590419, 45220.0] },
        {
            name: "灵璧明升10MW农光互补光伏电站",
            value: [117.497055, 33.675952, 40320.0]
        },
        {
            name: "山东东营6kv津禾光伏电站",
            value: [118.439848423, 37.5576934579, 37560.0]
        },
        { name: "灌南飞展一期", value: [114.084712, 34.308878, 36480.0] },
        { name: "邳州大诚", value: [117.50463, 34.405543, 35700.0] },
        { name: "海门灵树", value: [121.4474, 32.069315, 32820.0] },
        { name: "合肥金诺", value: [117.269661, 31.837868, 31600.0] },
        {
            name: "阜阳金种子6.16896MW光伏电站",
            value: [115.849146, 32.889156, 31200.0]
        },
        { name: "扬中阜润", value: [119.802256, 32.242, 29680.0] },
        { name: "合肥宝业", value: [117.240116, 31.808623, 29280.0] },
        { name: "兴化钓鱼", value: [119.853781, 32.963153, 28320.0] },
        {
            name: "山东淄博1.4MW光伏电站",
            value: [117.8231970119, 37.1824021543, 26080.0]
        },
        { name: "启东宏华", value: [121.688697, 31.792931, 23360.0] },
        { name: "盐城利能", value: [120.240052, 33.440862, 22400.0] },
        {
            name: "启东胜狮6MW屋顶分布式光伏电站",
            value: [121.702208, 31.792931, 22000.0]
        },
        {
            name: "扬州奔多4.979MW屋顶分布式光伏电站",
            value: [119.504115, 32.367086, 20760.0]
        },
        {
            name: "合肥应流4.32MW屋顶分布式光伏电站",
            value: [117.174211, 31.788516, 19932.0]
        },
        { name: "合肥徽商大市场", value: [114.94965, 35.830754, 19400.0] },
        {
            name: "合肥绿晖3.81MW光伏电站",
            value: [117.231066, 31.819371, 18800.0]
        },
        {
            name: "启东南阳4.8MW农光互补光伏电站",
            value: [121.723259, 31.881728, 18720.0]
        },
        { name: "合肥恒通玻璃", value: [113.964015, 34.024943, 18300.0] },
        { name: "张庄华博", value: [115.46837, 36.483281, 18000.0] },
        { name: "连云港伊山二期", value: [119.245959, 34.3015, 17500.0] },
        { name: "南通永兴永林", value: [120.918807, 31.961345, 15420.0] },
        { name: "营村华博", value: [114.218226, 37.110309, 15280.0] },
        { name: "岳胡华博", value: [114.706568, 37.938171, 15080.0] },
        { name: "微山永旭", value: [120.394647, 36.085204, 14950.0] },
        {
            name: "阜阳金坤达3.159MW光伏电站",
            value: [115.809947, 32.902675, 14950.0]
        },
        { name: "南通永兴华陆", value: [120.367551, 33.404614, 14760.0] },
        { name: "创能一站", value: [123.461215, 41.778005, 14560.0] },
        { name: "南通永兴林森", value: [118.920017, 33.552052, 14340.0] },
        { name: "创能二站", value: [122.973982, 41.049711, 14000.0] },
        {
            name: "祥光新能源6MW全额上网屋顶分布式光伏发电",
            value: [119.491977, 37.059157, 13680.0]
        },
        {
            name: "山东潍坊融光一站6MWp光伏电站",
            value: [119.170064, 37.048124, 13200.0]
        },
        { name: "融光二站二期", value: [114.383267, 36.125962, 13120.0] },
        {
            name: "山东潍坊融光二站6MWp光伏电站",
            value: [119.170064, 37.047952, 13080.0]
        },
        { name: "淄博开耀", value: [114.371502, 35.67851, 13080.0] },
        { name: "东营金宇", value: [116.198767, 36.399125, 12000.0] },
        { name: "东营正凯", value: [116.557801, 37.398347, 11880.0] },
        { name: "合肥恒春玻璃", value: [113.920412, 35.272855, 11490.0] },
        { name: "科灵空调", value: [123.826202, 42.181145, 11200.0] },
        { name: "合肥桥柜", value: [117.429631, 31.889512, 10560.0] },
        { name: "合肥润嘉", value: [117.298406, 31.828051, 9600.0] },
        { name: "合肥应流二期", value: [113.502879, 34.673248, 9280.0] },
        { name: "潍坊安丘汇创", value: [119.228152, 36.483145, 8760.0] },
        {
            name: "安徽安庆永安电子电站",
            value: [117.080678, 30.509951, 8618.0]
        },
        { name: "阜阳京九丝绸", value: [115.829345, 32.898245, 8490.0] },
        { name: "合肥凯盛", value: [117.357838, 31.781652, 8420.0] },
        { name: "合肥万润", value: [114.276853, 34.697382, 8140.0] },
        {
            name: "合肥恒科1.55184MW光伏电站",
            value: [117.200839, 31.822187, 8100.0]
        },
        {
            name: "灌云中森侍庄2.86832MW屋顶电站",
            value: [119.30428, 34.601536, 7720.0]
        },
        { name: "启东胜狮二期", value: [123.933992, 30.760809, 7580.0] },
        {
            name: "如皋意瑞达2MW屋顶分布式光伏电站",
            value: [120.541273, 32.434058, 7280.0]
        },
        { name: "连云港首耀新海", value: [119.291198, 34.590419, 7160.0] },
        {
            name: "泗洪归仁1.59636MW屋顶光伏发电站",
            value: [118.204362, 33.674983, 7080.0]
        },
        {
            name: "泗洪陈圩1.6854MW屋顶光伏发电站",
            value: [118.362715, 33.333696, 6900.0]
        },
        {
            name: "启东林洋电子2MW屋顶分布式光伏电站",
            value: [121.639277, 31.917826, 6840.0]
        },
        {
            name: "启东乾朔1.78MW屋顶分布式光伏电站",
            value: [121.623939, 31.819655, 6680.0]
        },
        { name: "南通乐歌", value: [121.007375, 31.857338, 6355.0] },
        { name: "山东安丘龙鼎重工", value: [119.220486, 36.412793, 6250.0] },
        { name: "连云港中森西苑", value: [119.278155, 34.259502, 6120.0] },
        { name: "宿州现代鞋城", value: [116.895538, 33.552214, 6120.0] },
        { name: "连云港中盛石榴", value: [117.998971, 33.977616, 5940.0] },
        {
            name: "山东潍坊新瑞永旭2.28MW屋顶电站",
            value: [119.228754, 36.492428, 5430.0]
        },
        { name: "华乐光电二期", value: [121.642417, 31.745777, 5420.0] },
        {
            name: "连云港易睐珂克浦南1.987MW光伏电站",
            value: [119.3443, 34.586143, 5120.0]
        },
        {
            name: "扬州信息产业园1MW屋顶分布式光伏电站",
            value: [119.5116, 32.395069, 4882.0]
        },
        { name: "合肥强建", value: [117.287, 31.946, 4826.4] },
        {
            name: "安丘晨晖2MW全额上网屋顶分布式光伏发电项目",
            value: [119.229382, 36.480252, 4800.0]
        },
        {
            name: "安徽界首蓝天0.924MW屋顶分布式光伏电站",
            value: [115.466991, 33.247196, 4480.0]
        },
        {
            name: "启东凯帝华虹1.18MWP屋顶分布式光伏电站",
            value: [121.6123491124, 31.827141196, 4420.0]
        },
        { name: "华乐光电一期", value: [121.616581, 31.810208, 4034.0] },
        {
            name: "辽宁乾丰专用车7MW屋顶光伏发电项目",
            value: [123.680107, 42.178133, 3840.0]
        },
        {
            name: "启东华泰1MW屋顶光伏电站",
            value: [121.619447, 31.824145, 3800.0]
        },
        {
            name: "铁岭际华5.78MW屋顶分布式光伏发电项目",
            value: [123.680107, 42.178133, 3520.0]
        },
        { name: "连云港首耀清墩", value: [114.742589, 33.608996, 3480.0] },
        {
            name: "南通农副1MW屋顶分布式光伏电站",
            value: [120.957317, 31.983873, 3043.0]
        },
        {
            name: "沈阳鲁博厨业3.9MW屋顶分布式光伏发电项目",
            value: [123.4132765888, 41.5754322375, 2940.0]
        },
        {
            name: "宿州中谷0.6552光伏电站",
            value: [116.954963, 33.65434, 2940.0]
        },
        {
            name: "南通观音山1MW屋顶分布式光伏电站",
            value: [120.724018, 32.37467, 2617.0]
        },
        {
            name: "合肥东维0.5MW屋顶分布式光伏电站",
            value: [117.228806, 31.719746, 2458.0]
        },
        {
            name: "铁岭林燕新能源科技有限公司",
            value: [123.6904623894, 42.146368079, 2200.0]
        },
        {
            name: "铁岭中欧汽车3.5MW屋顶分布式光伏发电项目",
            value: [123.7008864936, 42.1576004524, 2040.0]
        },
        { name: "如皋里高", value: [119.383235, 32.416341, 1936.0] },
        {
            name: "南通中心村0.5MW屋顶分布式光伏电站",
            value: [121.084309, 31.885101, 1765.0]
        },
        {
            name: "海门银燕0.42MW屋顶分布式光伏电站",
            value: [121.203163, 31.912599, 1688.0]
        },
        { name: "启东中盛机电", value: [121.877701, 31.921345, 1067.0] },
        { name: "冠县公共事业", value: [115.510224, 36.450879, 0.0] },
        { name: "河北风阳卧牛盘", value: [117.359653, 42.470969, 0.0] }
    ];

    var minX, maxX, minY, maxY;

    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (minX === undefined || minX > data[i].value[0]) {
                minX = data[i].value[0];
            }
            if (maxX === undefined || maxX < data[i].value[0]) {
                maxX = data[i].value[0];
            }
            if (minY === undefined || minY > data[i].value[1]) {
                minY = data[i].value[1];
            }
            if (maxY === undefined || maxY < data[i].value[1]) {
                maxY = data[i].value[1];
            }
        }
    }

    var centerX = (minX + maxX) / 2;
    var centerY = (minY + maxY) / 2;

    var minV, maxV;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (data[i].value[2] === 0.0) continue;
            if (minV === undefined || minV > data[i].value[2]) {
                minV = data[i].value[2];
            }
            if (maxV === undefined || minV < data[i].value[2]) {
                maxV = data[i].value[2];
            }
        }
    }

    var dataTop10 = [];
    var dataOther = [];
    var dataZero = [];
    var topCount = 0;
    for (const i in data) {
        if (data.hasOwnProperty(i)) {
            if (data[i].value[2] <= 0.0) {
                dataZero.push(data[i]);
            } else {
                if (topCount < 10) {
                    dataTop10.push(data[i]);
                    ++topCount;
                } else {
                    dataOther.push(data[i]);
                }
            }
        }
    }

    var mapdata = [
        { name: "安徽", value: 2583170.4 },
        { name: "江苏", value: 1451327 },
        { name: "山东", value: 1190074 },
        { name: "河南", value: 520801.6 },
        { name: "内蒙古", value: 885940 },
        { name: "河北", value: 0 },
        { name: "辽宁", value: 14540 }
    ];

    var series = [
        //日
        {
            name: "计划发电量",
            type: "bar",
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: [
                607,
                597,
                494,
                691,
                473,
                704,
                475,
                644,
                730,
                613,
                708,
                651,
                453,
                642,
                500,
                431,
                724,
                435,
                672,
                718,
                412,
                506,
                564,
                738,
                436,
                403,
                401,
                586,
                514,
                656,
                587
            ]
        },
        //月
        {
            name: "计划发电量",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 2,
            data: [
                11956,
                13961,
                12718,
                13648,
                7670,
                15706,
                8349,
                14963,
                13458,
                8665,
                16474,
                16413,
                16365,
                15676
            ]
        },
        {
            name: "实际发电量",
            type: "bar",
            xAxisIndex: 1,
            yAxisIndex: 2,
            data: [
                8469,
                14958,
                15960,
                7273,
                15934,
                8015,
                13807,
                10073,
                14699,
                11550,
                16426,
                9321,
                10379,
                16470
            ]
        },
        {
            name: "计划完成率",
            type: "line",
            smooth: true,
            //symbol: 'none',
            symbolSize: 10,
            sampling: "average",
            xAxisIndex: 1,
            yAxisIndex: 3,
            data: [86, 111, 102, 94, 86, 80, 83, 111, 103, 87, 80, 101, 109, 90]
        },
        //年
        {
            name: "计划发电量",
            type: "bar",
            xAxisIndex: 2,
            yAxisIndex: 4,
            data: [11956, 13961, 12718, 13648, 7670, 15706]
        },
        {
            name: "实际发电量",
            type: "bar",
            xAxisIndex: 2,
            yAxisIndex: 4,
            data: [8469, 14958, 15960, 7273, 15934, 8015]
        },
        {
            name: "计划完成率",
            type: "line",
            smooth: true,
            //symbol: 'none',
            symbolSize: 10,
            sampling: "average",
            xAxisIndex: 2,
            yAxisIndex: 5,
            data: [86, 111, 102, 94, 86, 80]
        },
        //地图上代表电站的点
        {
            name: "other",
            type: "scatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "stroke"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: function(val) {
                if (val[2] === 0.0) return 10;
                return 10 + ((val[2] - minV) / (maxV - minV)) * 10;
            },
            itemStyle: {
                normal: {
                    color: "#FFDB5C"
                }
            },
            data: dataOther
        },
        {
            name: "Top10",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "stroke"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: function(val) {
                if (val[2] === 0.0) return 10;
                return 10 + ((val[2] - minV) / (maxV - minV)) * 10;
            },
            itemStyle: {
                normal: {
                    color: "#FFDB5C"
                }
            },
            data: dataTop10
        },
        {
            name: "zero",
            type: "effectScatter",
            coordinateSystem: "geo",
            zlevel: 100,
            large: true,
            rippleEffect: {
                brushType: "fill"
            },
            label: {
                normal: {
                    show: false
                }
            },
            symbolSize: 10,
            itemStyle: {
                normal: {
                    color: "#FB7293"
                }
            },
            data: dataZero
        },
        {
            name: "装机容量",
            type: "map",
            mapType: "china",
            geoIndex: 0,
            data: mapdata
        }
    ];

    let option = {
        backgroundColor: "rgba(255, 255, 255, 0)",
        color: ["#37A2DA", "#67E0E3", "#FFDB5C"],
        tooltip: {
            trigger: "item"
            /*formatter: function(params) {
            if (params instanceof Array) {
                if (params.length === 0) {
                    return null;
                } else {
                    var tip = params[0].name;
                    for (var i in params) {
                        tip += "<br />" + params[i].seriesName + ": " + params[i].value
                            + (params[i].seriesType == 'bar' ? ' 万KWh' : ' %');
                    }
                    return tip;
                }
            } else if (params.seriesType == 'map') {
                if (params.data === undefined) {
                    return null;
                } else {
                    return params.data.name + "<br />今日电量：" + params.data.value + " 万KWh";
                }
            } else {
                return params.data.name + "<br />今日电量：" + params.data.value[2] + " 万KWh";
            }
        }*/
        },
        legend: {
            top: "5%",
            left: "65%",
            data: ["计划发电量", "实际发电量", "计划完成率"]
        },
        grid: [
            {
                top: "15%",
                height: "20%",
                left: "62%",
                containLabel: false,
                tooltip: {
                    trigger: "axis"
                }
            },
            {
                top: "45%",
                height: "20%",
                left: "62%",
                containLabel: false,
                tooltip: {
                    show: true,
                    trigger: "axis",
                    formatter: function(params) {
                        console.log("grid1 tooltip");
                        console.dir(params);
                    }
                }
            },
            {
                top: "75%",
                height: "20%",
                left: "62%",
                containLabel: false,
                tooltip: {
                    show: true,
                    trigger: "axis",
                    formatter: function(params) {
                        console.log("grid2 tooltip");
                        console.dir(params);
                    }
                }
            }
        ],
        xAxis: [
            {
                type: "category",
                data: [
                    "07/13",
                    "07/14",
                    "07/15",
                    "07/16",
                    "07/17",
                    "07/18",
                    "07/19",
                    "07/20",
                    "07/21",
                    "07/22",
                    "07/23",
                    "07/24",
                    "07/25",
                    "07/26",
                    "07/27",
                    "07/28",
                    "07/29",
                    "07/30",
                    "07/31",
                    "08/01",
                    "08/02",
                    "08/03",
                    "08/04",
                    "08/05",
                    "08/06",
                    "08/07",
                    "08/08",
                    "08/09",
                    "08/10",
                    "08/11",
                    "08/12"
                ]
            },
            {
                gridIndex: 1,
                type: "category",
                data: [
                    "18/07",
                    "18/08",
                    "18/09",
                    "18/10",
                    "18/11",
                    "18/12",
                    "19/01",
                    "19/02",
                    "19/03",
                    "19/04",
                    "19/05",
                    "19/06",
                    "19/07",
                    "19/08"
                ]
            },
            {
                gridIndex: 2,
                type: "category",
                data: ["2014", "2015", "2016", "2017", "2018", "2019"]
            }
        ],
        yAxis: [
            {
                name: "日发电量：万KWh",
                nameTextStyle: {
                    color: "#727272",
                    fontSize: 10
                },
                nameGap: 10,
                type: "value",
                splitNumber: 3,
                axisLabel: {
                    color: "#727272",
                    fontSize: 8
                }
            },
            {
                //name: '日完成率：%',
                nameTextStyle: {
                    color: "#727272",
                    fontSize: 10
                },
                nameGap: 10,
                type: "value",
                splitNumber: 3,
                splitLine: {
                    show: false
                },
                axisLabel: {
                    color: "#727272",
                    fontSize: 8
                }
            },
            {
                gridIndex: 1,
                name: "月发电量：万KWh",
                nameTextStyle: {
                    color: "#727272",
                    fontSize: 10
                },
                nameGap: 10,
                type: "value",
                splitNumber: 3,
                axisLabel: {
                    color: "#727272",
                    fontSize: 8
                }
            },
            {
                gridIndex: 1,
                name: "月完成率：%",
                nameTextStyle: {
                    color: "#727272",
                    fontSize: 10
                },
                nameGap: 10,
                type: "value",
                splitNumber: 3,
                splitLine: {
                    show: false
                },
                axisLabel: {
                    color: "#727272",
                    fontSize: 8
                }
            },
            {
                gridIndex: 2,
                name: "年发电量：万KWh",
                nameTextStyle: {
                    color: "#727272",
                    fontSize: 10
                },
                nameGap: 10,
                type: "value",
                splitNumber: 3,
                axisLabel: {
                    color: "#727272",
                    fontSize: 8
                }
            },
            {
                gridIndex: 2,
                name: "年完成率：%",
                nameTextStyle: {
                    color: "#727272",
                    fontSize: 10
                },
                nameGap: 10,
                type: "value",
                splitNumber: 3,
                splitLine: {
                    show: false
                },
                axisLabel: {
                    color: "#727272",
                    fontSize: 8
                }
            }
        ],
        visualMap: {
            show: false,
            type: "continuous",
            //orient: 'horizontal',
            min: 0,
            max: 2583170.4,
            inRange: {
                color: ["#56bdff", "#026bad"]
            },
            itemWidth: 8,
            itemHeight: 100,
            left: "left",
            top: "70%",
            //text:['高','低'],           // 文本，默认为数值文本
            calculable: true,
            precision: 0,
            //align: 'bottom',
            seriesIndex: [series.length - 1]
        },
        geo: {
            map: "china",
            //zoom: 1,
            //center: [centerX, centerY],
            // boundingCoords: [
            //     [minX, maxY], // 定位左上角经纬度
            //     [maxX, minY]  // 定位右下角经纬度
            // ],
            layoutCenter: ["30%", "50%"],
            layoutSize: "100%",
            zlevel: 0,
            label: {
                emphasis: {
                    show: false
                }
            },
            roam: true,
            itemStyle: {
                areaColor: "#BBDEFB", //'#F2F2F2',
                borderColor: "#727272",
                opacity: 1
            },
            emphasis: {
                itemStyle: {
                    areaColor: "#005184"
                }
            }
        },
        series: series
    };
    return option;
}
