export interface PowerStation {
  psrId: string; // 与测点objId关联
  areaId: string;
  stationCode: string;
  shortName: string; // 电站简称
  stationId: string;
  address: string;
  capacity: number; // 装机容量
  parallelCapacity: number; // 并网装机容量
  stationName: string; // 电站全称
  stationStatus: string;
  latitude: string; // 纬度
  longitude: string; // 经度
  alarmStatus: number; // 告警状态
  limitStatus: number; // 限电状态
  provinceId: string;
  provinceName: string;
  cityName: string;
  cityId: string;
  contryName: string;
  countryId: string;
  countryName: string;
  countyName: string;
  countyId: string;
  elevation: string;
  operationDate: string;

  area: string;
  attributes: {};
  connectionMode: string;
  connectionModeText: string;
  construnctComp: string;
  construnctCompName: string;
  customerId: string;
  customerName: string;
  deptId: string;
  deptIdName: string;
  designComp: string;
  designCompName: string;
  elevationText: string;
  finishDate: string;
  introduction: string;
  parentDeptId: string;
  parentDeptName: string;
  postcode: string;
  powerComp: string;
  powerCompName: string;
  projectComp: string;
  projectCompName: string;
  propertyComp: string;
  propertyCompName: string;
  stationMaster: string;
  stationMasterName: string;
  stationStatusText: string;
  stationType: string;
  stationTypeText: string;
  timezone: string;
  voltageLevel: string;
  voltageLevelText: string;
  moduleAngle: number;
  moduleLayout: string;
  picId?: number;
  sellPrice: number;
  subsidyPrice: number;
}

export enum StationStatus {
  planning = '01', // 规划中
  building = '02', // 建设中
  connected = '03', // 已并网
  inSystem = '04', // 已接入系统
}
