import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PointConfigService } from '../point-config.service';
import { CommonService } from '../../../../../common/services/common.service';
import {
  CodeTableService,
  SelectItem
} from '../../../../../core/code-table.service';
import { CodeTable } from '../../../../../common/models/codeTable.model';
import * as _ from 'lodash';
import { NullItem } from '../point-config.model';
import { DragulaService } from 'ng2-dragula';
import { TipService } from '@common/services/tip.service';

@Component({
  selector: 'mpt-point-edit-list',
  templateUrl: 'point-edit-list.component.html',
  styleUrls: ['point-edit-list.component.less']
})
export class PointEidtListComponent implements OnInit {
  @Input() public listTitle;
  @Input() public sysCateIdList;
  @Input() public displayGroup1List;
  @Input() public pointList = [];

  @Output() public getValues = new EventEmitter<any>();
  @Output() public pointTypeChange = new EventEmitter<any>();

  public showVolumnDialog = false;

  public selectedPointList = [];
  public target = null;
  public editTarget = null;
  public pvStatusList;
  public pointIsDisplayList;
  public unitList;
  public pointDisplayTransferList;

  public allSelected = false;
  public random = Math.random() + '';
  constructor(
    private pointConfigService: PointConfigService,
    private commonService: CommonService,
    private codeTableService: CodeTableService,
    private dragulaService: DragulaService,
    private tipservice: TipService
  ) {
    dragulaService.createGroup(this.random, {
      moves: (el, container, handle) => {
        return handle.className === 'fa fa-bars';
      }
    });
    dragulaService.dropModel(this.random).subscribe((value) => {
      this.pointList = value.sourceModel;
      this.resetData();
    });
    this.codeTableService
      .getNemsSelectItems(CodeTable.pvStatus)
      .then((res) => {
        this.pvStatusList = [...res];
        this.pvStatusList.unshift(NullItem);
      });
    this.codeTableService
      .getNemsSelectItems(CodeTable.pointIsDisplay)
      .then((res) => {
        this.pointIsDisplayList = [...res];
        this.pointIsDisplayList.unshift(NullItem);
      });
    this.codeTableService.getNemsSelectItems(CodeTable.unit).then((res) => {
      this.unitList = [...res];
      this.unitList.unshift(NullItem);
    });
    this.codeTableService
      .getNemsSelectItems(CodeTable.pointDisplayTransfer)
      .then((res) => {
        this.pointDisplayTransferList = [...res];
        this.pointDisplayTransferList.unshift(NullItem);
      });
  }

  ngOnInit() { }

  public getVolumnDialogData(e) {
    if (this.target) {
      this.target.pointName = e.pointName;
      this.target.pointCategory.remotionType = e.pointRemotionType;
      this.target.pointCategory.sysCateId = e.type;
      this.target.pointCategory.displayGroup1 = e.road;
      this.target.pointCategory.displayGroup2 = e.status;
      this.target.isDisplay = e.way;
      this.target.pointCategory.pointUnit = e.unit;
      this.target.pointCategory.propTypeId = e.transform;
      this.changeType(this.target);
    } else {
      for (let point of this.selectedPointList) {
        point.pointCategory.remotionType = e.pointRemotionType;
        point.pointCategory.sysCateId = e.type;
        point.pointCategory.displayGroup1 = e.road;
        point.pointCategory.displayGroup2 = e.status;
        point.isDisplay = e.way;
        point.pointCategory.pointUnit = e.unit;
        point.pointCategory.propTypeId = e.transform;
        this.changeType(point);
      }
    }
    this.resetData();
  }

  public setVolumnData(item?) {
    this.editTarget = null;
    this.target = null;
    if (item) {
      this.target = item;
      this.editTarget = _.cloneDeep(item);
      this.showVolumnDialog = true;
    } else {
      if (this.selectedPointList && this.selectedPointList.length > 0) {
        this.showVolumnDialog = true;
      } else {
        this.tipservice.tip('warn', '请选择要批量设置的测点');
      }
    }
  }

  public upRowData(row) {
    const rowIndex = this.pointList.findIndex(
      (v) => v.pointId === row.pointId
    );
    if (rowIndex === 0) {
      return;
    }
    this.pointList.splice(rowIndex, 1);
    this.pointList.splice(rowIndex - 1, 0, row);
    this.resetData();
  }

  public downRowData(row) {
    const rowIndex = this.pointList.findIndex(
      (v) => v.pointId === row.pointId
    );
    if (rowIndex === this.pointList.length - 1) {
      return;
    }
    this.pointList.splice(rowIndex, 1);
    this.pointList.splice(rowIndex + 1, 0, row);
    this.resetData();
  }

  public upRowToTop(row) {
    const rowIndex = this.pointList.findIndex(
      (v) => v.pointId === row.pointId
    );
    if (rowIndex === 0) {
      return;
    }
    this.pointList.splice(rowIndex, 1);
    this.pointList.unshift(row);
    this.resetData();
  }

  public changeType(data) {
    // switch (this.listTitle) {
    //   case '遥信/遥控':
    //     if (
    //       data.pointCategory.sysCateId === 'AM-DEVICE-ALARM-FAULT' ||
    //       data.pointCategory.sysCateId === 'AM-DEVICE-ALARM-WARN' ||
    //       data.pointCategory.sysCateId === 'AM-DEVICE-ALARM-INFO' ||
    //       data.pointCategory.sysCateId === 'AM-DEVICE-ALARM-LOG'
    //     ) {
    //       this.deleteData(data);
    //       return;
    //     }
    //     break;
    //   case '告警':
    //     if (
    //       data.pointCategory.sysCateId !== 'AM-DEVICE-ALARM-FAULT' &&
    //       data.pointCategory.sysCateId !== 'AM-DEVICE-ALARM-WARN' &&
    //       data.pointCategory.sysCateId !== 'AM-DEVICE-ALARM-INFO' &&
    //       data.pointCategory.sysCateId !== 'AM-DEVICE-ALARM-LOG'
    //     ) {
    //       this.deleteData(data);
    //       return;
    //     }
    //     break;
    //   default:
    //     break;
    // }
    // this.deleteData(data);
    this.pointTypeChange.emit({ rowData: data, flag: false });
  }

  public allSelectedChange(value) {
    if (value) {
      this.selectedPointList = [...this.pointList];
    } else {
      this.selectedPointList = [];
    }
  }

  public findListLabel(id, list) {
    if (_.isArray(list)) {
      for (let item of list) {
        if (item.value === id) {
          return item.label;
        }
      }
    } else {
      for (let i in list) {
        for (let item of list[i]) {
          if (item.value === id) {
            return item.label;
          }
        }
      }
    }
  }
  private deleteData(row) {
    let rowIndex = this.pointList.findIndex(
      (v) => v.pointId === row.pointId
    );
    this.pointList.splice(rowIndex, 1);
    this.pointTypeChange.emit({ rowData: row, flag: true });
    this.resetData();
  }

  private resetData() {
    this.pointList = [...this.pointList];
    this.getValues.emit(this.pointList);
  }
}
