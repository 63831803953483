export interface Alarm {
  id: string;
  stationId: string;
  title: string;
  content: string;
  time: string;
  icon: string;
  level: AlarmLevel;
}
// 告警级别
export enum AlarmLevel {
  level1 = '01', // 严重
  level2 = '02', // 普通
  level3 = '03', // 提示
  level4 = '04' // 日志
}

for (let key in AlarmLevel) {
  if (AlarmLevel.hasOwnProperty(key)) {
    AlarmLevel[AlarmLevel[key]] = key;
  }
}

export enum AlarmIcon {
  level1 = 'rt-alarm-btn_u920.png',
  level2 = 'rt-alarm-btn_u898.png',
  level3 = 'rt-alarm-btn_u898.png',
  level4 = 'rt-alarm-btn_u898.png'
}
