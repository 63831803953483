import { CommonService } from './../../services/common.service';
import { Injectable } from '@angular/core';
import { AuthHttp } from 'src/app/core/auth-http';
import { AppConfigService } from 'src/app/app.config';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class StationSelectorService {
    private apiUrl: string = this.appConfig.apiUrl;

    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private common: CommonService
    ) {}

    /**
     * getStaionInfoByStaionId
     */
    public getStaionInfoByStaionId(stationId) {
        return this.http.get(`${this.apiUrl}/api/station/${stationId}`).pipe(
            map((res: any) => {
                return { stationId: res.stationId, shortName: res.shortName };
            })
        );
    }

    // 获得部门树数据
    public getStationAutoComplate(params) {
        return this.http
            .get(`${this.apiUrl}/api/station/autoComplate`, {
                params,
            })
            .toPromise()
            .then((res: any) => {
                return res.map((item) => ({
                    stationId: item.stationId,
                    shortName: item.shortName,
                }));
            });
    }

    public getRegions(id) {
        return this.http.get(`${this.apiUrl}/api/regions/subRegions`, {
            params: { parentId: id },
        });
    }

    /**
     * name
     */
    public getSampleStations(params) {
        return this.http.get(`${this.apiUrl}/api/station/sampleStations`, {
            params: this.common.filterEffectParam(params),
        });
    }
}
