import {
  Component,
  Injectable,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  OnChanges,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import * as _ from 'lodash';
import { TreeviewI18nDefault } from './index';
import { TreeviewI18n } from './treeview-i18n';
import { TreeviewHeaderTemplateContext } from './treeview-header-template-context';
import { TreeviewItem } from './treeview-item';
import { TreeviewConfig } from './treeview-config';
import { TreeviewItemTemplateContext } from './treeview-item-template-context';
import { DropdownTreeviewComponent } from './dropdown-treeview.component';
import { TreeviewHelper } from './treeview-helper';

@Component({
  selector: 'ngx-dropdown-treeview-radio',
  templateUrl: './dropdown-treeview-select.component.html',
  styleUrls: ['./dropdown-treeview-select.component.less'],
})
export class DropdownTreeviewSelectComponent implements OnChanges {
  @Input() public value: any;
  @Input() public items: TreeviewItem[];
  @Input() public config: TreeviewConfig;
  @Output() public selectedChange = new EventEmitter<any[]>(true);
  @ViewChild(DropdownTreeviewComponent, { static: false })
  public dropdownTreeviewComponent: DropdownTreeviewComponent;

  public showText: string;
  public values: string[];
  public filterText: string = '';
  private dropdownTreeviewSelectI18n: TreeviewI18nDefault;

  constructor(public i18n: TreeviewI18n) {
    this.config = TreeviewConfig.create({
      hasAllCheckBox: false,
      hasCollapseExpand: false,
      hasFilter: true,
      maxHeight: 200,
    });
    this.dropdownTreeviewSelectI18n = i18n as TreeviewI18nDefault;
  }

  public ngOnChanges(changes: SimpleChanges) {
    this.updateSelectedItem();
  }

  public select(item: TreeviewItem) {
    this.selectItem(item, true);
  }

  public onCollapseExpand() {}

  private updateSelectedItem() {
    if (!_.isNil(this.items)) {
      const selectedItem = TreeviewHelper.findItemInList(this.items, this.value, true);
      if (selectedItem) {
        this.selectItem(selectedItem);
      } else {
        this.showText = '';
        this.values = [];
      }
    }
  }

  private selectItem(item: TreeviewItem, clickFlag?) {
    this.dropdownTreeviewComponent.dropdownDirective.close();
    if (this.dropdownTreeviewSelectI18n.selectedItem !== item) {
      this.dropdownTreeviewSelectI18n.selectedItem = item;
      this.value = item.value;
      this.showText = item.text;
      this.values = _.concat([], item.value);
      this.selectedChange.emit(item.value);
    } else if (clickFlag) {
      this.dropdownTreeviewSelectI18n.selectedItem = null;
      this.value = null;
      this.showText = '';
      this.values = [];
      this.selectedChange.emit(null);
    }
  }
}
