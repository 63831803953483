import { AppConfigService } from './../../app.config';
import {
  Directive,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  HostListener,
  OnChanges,
  OnDestroy,
  SimpleChange,
} from '@angular/core';

import * as ECharts from 'echarts';
import 'echarts-liquidfill';
import 'echarts/extension/bmap/bmap';

@Directive({
  selector: '[echarts]',
})
export class EchartsDirective implements OnChanges, OnDestroy {
  @Input() public options: any;
  @Input() public dataset: any[];
  @Input() public theme: string;
  @Input() public loading: boolean;
  @Input() public notMerged;
  @Output() public clickEvent = new EventEmitter<any>();

  private myChart: any = null;
  private currentWindowWidth = null;

  constructor(
    private el: ElementRef,
    private appconfigservice: AppConfigService
  ) { }

  public ngOnChanges(changes: { [propertyName: string]: SimpleChange }) {
    if (changes['options']) {
      this.onOptionsChange(this.options);
    }

    if (changes['dataset']) {
      this.onDatasetChange(this.dataset);
    }

    if (changes['loading']) {
      this.onLoadingChange(this.loading);
    }
  }

  public ngOnDestroy() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  }

  public destroyChart() {
    if (this.myChart) {
      this.myChart.dispose();
      this.myChart = null;
    }
  }

  private onOptionsChange(opt: any) {
    if (opt) {
      if (!this.myChart) {
        this.myChart = this.createChart();
        // 添加点击事件 hlweng
        this.myChart.on('click', (params) => {
          this.clickEvent.emit(params);
        });
      }
      if (this.notMerged || this.hasData()) {
        this.updateChart();
      }
    }
  }

  // restore() {
  //     this.myChart.dispatchAction({ type: 'restore' })
  // }
  private onDatasetChange(dataset: any[]) {
    if (this.myChart && this.options) {
      if (!this.options.series) {
        this.options.series = [];
      }

      for (let i = 0, len = dataset.length; i < len; i++) {
        if (!this.options.series[i]) {
          this.options.series[i] = { data: dataset[i] };
        } else {
          this.options.series[i].data = dataset[i];
        }
      }

      this.updateChart();
    }
  }

  private onLoadingChange(loading: boolean) {
    if (this.myChart) {
      if (loading) {
        const appconfig = this.appconfigservice.getAppConfig();
        if (['dark', 'jszy'].includes(appconfig.theme)) {
          this.myChart.showLoading('default', {
            maskColor: '#0e293c',
            textColor: '#fff',
          });
        } else if (appconfig.theme === 'dark-green') {
          this.myChart.showLoading('default', {
            maskColor: '#0b2127',
            textColor: '#fff',
          });
        } else {
          this.myChart.showLoading();
        }
      } else {
        this.myChart.hideLoading();
      }
    }
  }

  /**
   * method to check if the option has dataset.
   */
  private hasData(): boolean {
    if (!this.options.series || !this.options.series.length) {
      return false;
    }

    for (let serie of this.options.series) {
      if (serie.data) {
        return true;
      }
    }

    return false;
  }

  private createChart() {
    const appconfig = this.appconfigservice.getAppConfig();
    this.theme =
      ['dark', 'dark-green', 'jszy'].includes(appconfig.theme)
        ? 'dark'
        : this.theme
          ? 'light'
          : 'default';

    this.currentWindowWidth = window.innerWidth;
    try {
      return ECharts.init(this.el.nativeElement, this.theme);
    } catch (e) {
      console.log('echarts初始化失败:' + e);
    }
  }

  private updateChart() {
    try {
      if (this.myChart) {
        this.myChart.clear();
      }
      const appconfig = this.appconfigservice.getAppConfig();
      if (appconfig.theme === 'jszy') {
        this.options = {
          ...this.options,
          backgroundColor: 'transparent',
        }
      }
      this.myChart.setOption(this.options, this.notMerged);
      this.myChart.resize();
    } catch (e) {
      console.log('echarts设置失败:' + e);
    }
  }

  @HostListener('window:resize', ['$event']) private onWindowResize(event) {
    // if (event.target.innerWidth !== this.currentWindowWidth) {
    this.currentWindowWidth = event.target.innerWidth;
    if (this.myChart) {
      this.myChart.resize();
    }
    // }
  }
}
