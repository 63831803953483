import { Injectable } from '@angular/core';
import * as _ from 'lodash';

import { AuthHttp } from '../../core/auth-http';
import { AppConfigService } from '../../app.config';
import { CommonService } from './common.service';

@Injectable()
export class FileService {
  public constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {}

  // 附件上传
  public importFile(file) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${this.appConfig.apiUrl}/api/doc/upload`, formData)
      .toPromise();
  }

  // 附件下载
  public exportFile(docId) {
    return this.fileExport(`${this.appConfig.apiUrl}/api/doc/download`, {
      docId,
    });
  }

  // 附件删除
  public deleteFile(docIds) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/doc/delete`, {
        search: { docIds },
      })
      .toPromise();
  }

  // 导出excel
  public fileExport(url, param: any = {}) {
    this.common.mask.show();
    this.http
      .get(url, {
        responseType: 'blob',
        observe: 'response',
        search: this.common.filterEffectParam(param),
      })
      .subscribe(
        (res: any) => {
          let fileNameArr = res.headers.get('Content-Disposition').split('.');
          let suffix = _.findLast(fileNameArr);
          let type;
          if (suffix === 'xlsx') {
            type =
              'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
          } else if (suffix === 'xls') {
            type = 'application/vnd.ms-excel';
          }
          let blob = new Blob([res.body], { type });
          let fileName = fileNameArr[0].substring(
            fileNameArr[0].indexOf('=') + 1
          );
          if (fileNameArr.length > 2) {
            for (let i = 1, len = fileNameArr.length; i < len - 1; i++) {
              fileName += '.' + fileNameArr[i];
            }
          }
          fileName = decodeURIComponent(fileName);
          fileName = fileName.split('+').join(' ') + '.' + suffix;
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.download = fileName;
          a.href = URL.createObjectURL(blob);
          a.click();
        },
        (error) => {
          this.common.errorMessage(error);
        },
        () => {
          this.common.mask.hide();
        }
      );
  }

  uploadDocs(url, file, params) {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${this.appConfig.apiUrl}${url}`, formData, {
        params: this.common.filterEffectParam(params),
      })
      .toPromise();
  }
}
