import { Injectable } from '@angular/core';
import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { UserService } from '../../../core/user.service';
import { CommonService } from '../../../common/services/common.service';

@Injectable({
  providedIn: 'root',
})
export class RepairService {
  public stationList: any[] = [];
  private apiUrl: string = this.appConfig.apiUrl;

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private user: UserService,
    private common: CommonService
  ) { }

  public getRepairTicketsList(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/repairTickets`, {
      params: this.common.filterEffectParam(params),
    });
  }

  // 获取 缺陷单
  public getRepairTicketByTicketId(ticketId, params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/repairTickets/${ticketId}`, {
      params: this.common.filterEffectParam(params),
    });
  }

  public deleteReparidTicket(ticketId) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/m/v2/repairTickets`, {
      search: { ticketId },
    });
  }

  // 催单
  public remind(ticketId) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/repairTickets/remind`,
      {},
      // { params: { ticketId }, responseType: 'text' }
      { params: { ticketId } }
    );
  }

  public exportRepairTickets(params) {
    return this.common.fileExport(`${this.apiUrl}/api/repairTickets/export`, params);
  }

  // 获取 表单配置
  public getNullable(targetNodeId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/workflows/nullable`, {
        params: { targetNodeId },
      })
      .toPromise();
  }

  public check(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/repairTickets/check`, {}, { params });
  }

  public repairTicketsAction(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/repairTickets`, params);
  }
}
