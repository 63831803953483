import { Injectable } from '@angular/core';
import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { CommonService } from '../../services/common.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class PointSelectorService {
  public apiUrl: string = this.appConfig.apiUrl;

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {}

  // 电站测点列表   params
  public getPointStationList(params, page, size) {
    return this.http.post(
      `${this.apiUrl}/api/AlarmRule/getSelectPointStation`,
      params,
      { search: { page, size } }
    );
  }

  // 电站区域测点列表
  public getPointStationAreaList(params, page, size) {
    return this.http.post(
      `${this.apiUrl}/api/AlarmRule/getSelectPointStationArea`,
      params,
      { search: { page, size } }
    );
  }

  // 设备测点列表
  public getDevicePointList(params, page, size) {
    return this.http.post(
      `${this.apiUrl}/api/AlarmRule/getDevicePointList`,
      params,
      { search: { page, size } }
    );
  }

  // 电站列表所有数据
  public getPointStationListAll(params) {
    return this.http.post(
      `${this.apiUrl}/api/AlarmRule/getSelectPointStationAll`,
      params
    );
  }

  // 电站区域列表所有数据
  public getPointStationAreaListAll(params) {
    return this.http.post(
      `${this.apiUrl}/api/AlarmRule/getSelectPointStationAreaAll`,
      params
    );
  }

  // 设备测点所有数据
  public getDevicePointListAll(params) {
    return this.http.post(
      `${this.apiUrl}/api/AlarmRule/getDevicePointListAll`,
      params
    );
  }

  // 选择测点页面电站
  public getStations() {
    return this.http.get(`${this.apiUrl}/api/index/getSampleStationAll`);
  }

  // 电站区域
  public getStationAreas(stationId) {
    return this.http.get(`${this.apiUrl}/api/location/getStationLocation`, {
      search: { stationId },
    });
  }

  // 测点名称 模糊查询
  public getCategories(param) {
    return this.http.get(`${this.appConfig.apiUrl}/api/points/categories`, {
      search: this.common.filterEffectParam(param),
    });
  }

  // 设备型号/名称 模糊查询
  public getModels(param) {
    if (param.groupType) {
      param.deviceTypes = param.groupType;
    }
    return this.http.get(`${this.appConfig.apiUrl}/api/models/stationModels`, {
      search: this.common.filterEffectParam(param),
    });
  }

  // 设备编号
  public getdeviceNos(param) {
    return this.http.get(`${this.appConfig.apiUrl}/api/device/deviceNos`, {
      search: this.common.filterEffectParam(param),
    });
  }

  // 测点列表
  public getPointList(param) {
    return this.http.get(`${this.appConfig.apiUrl}/api/points/selector`, {
      search: this.common.filterEffectParam(param),
    });
  }

  // 设备测点 - 设备型号
  public getDeviceModel(map) {
    return this.http.post(`${this.apiUrl}/api/AlarmRule/getModelXLK`, map);
  }

  // 四遥类型
  public getSiYaoType() {
    return this.http.get(
      `${this.apiUrl}/api/AlarmRule/getPointConditionOfSiYaoType`
    );
  }

  // 关联测点批量添加  list
  public addPoints(list) {
    return this.http
      .post(`${this.apiUrl}/api/AlarmRule/addPoints`, list)
      .pipe(tap((res) => {}));
  }
}
