export interface PageObj {
  pageSize: number;
  pageCurrent: number;
}

// 历史告警数据请求接口post data类型
export interface HistoryParam {
  stationId: any[];
  assetName: string;
  modelId: any[];
  alarmAction: any[];
  alarmType: any[];
  alarmLevel: any[];
  alarmText: string;
  startTime: string;
  endTime: string;
}

// 实时告警数据请求接口post data 类型
export interface RealTimeParam {
  alarmLevel: string;
  alarmType?: string;
  alarmList: string;
  stationId: string[];
  logId: string;
  limit: number;
  startTime?: string;
  endTime?: string;
}

// 实时告警数据请求接口post data 类型
export interface RealTimeQueryParam {
  stationIds?: string[];
  alarmLevels?: string;
  deviceName?: string;
  alarmTypes?: string[];
  beginDate?: string;
  endDate?: string;
  alarmStatus?: string[];
  alarmCheckStatus?: string[];
  sta_stationIds?: any;
  sta_provinceIds?: any;
  sta_cityIds?: any;
  sta_countyIds?: any;
  sta_propertyComps?: any;
  sta_stationTypes?: any;
  sta_name?: string;
  page?: number;
  size?: number;
}

// 实时告警的确认提交参数
export interface RealTimeSubmitParam {
  logIds: string[];
  memo: string;
}

export enum AlarmAction {
  add = '0',
  update = '1',
  delete = '2',
}

export interface Option {
  name: string;
  num: number;
  id: string;
  chartOption: any;
}
