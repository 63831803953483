import { TipService } from './../../../../common/services/tip.service';
import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../../../common/services/common.service';
import { ActivatedRoute } from '@angular/router';
import { CacheService } from '../../../../common/services/cache.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { Constants } from '../../../../common/Constants';
import { debounceTime } from 'rxjs/operators';
import { fromEvent, forkJoin } from 'rxjs';
import { StationMonitorService } from '../../station-monitor.service';
import { LazyLoadEvent } from 'primeng/api';
import { buildTimeChart } from '../chart/time.chart';
import { buildBarChart } from '../chart/bar.chart';
import { buildLineChart } from '../chart/line.chart';
import * as _ from 'lodash';

@Component({
  selector: 'app-other',
  templateUrl: './other.component.html',
  styleUrls: ['./other.component.less'],
})
export class OtherComponent implements OnInit {
  public pager = { page: 0, size: 50, totalRecords: 1 };
  public deviceType = 'OTHER';
  public stationId: any;
  public resizeEvent: any;
  public scrollHeight = '0px';
  public form: any;
  public areaList: any;
  public modelList: any;
  public runStatusList: any;
  public sortBy: any;
  public sortOrder: any;
  public tableloading: boolean = true;
  public tableTitle = '';
  public defaultSortOrder = 1;
  public options = null;
  public columns = [];
  public dataList = [];
  public toggle: boolean = true;

  constructor(
    private common: CommonService,
    private stationmonitorservice: StationMonitorService,
    private route: ActivatedRoute,
    private cacheService: CacheService,
    private formBuilder: FormBuilder,
    private tipservice: TipService
  ) {
    this.route.queryParams.subscribe((params) => {
      if (!params.stationId) {
        let stationId = this.cacheService.getCache(
          Constants.KEY_CURRENT_STATION_ID
        );
        this.stationId = stationId;
      } else {
        this.stationId = params.stationId;
      }
      this.initForm();
      this.otherQuery(true);
      this.form.patchValue({
        runStatus: this.stationmonitorservice.getRunStatusCache(),
      });
    });

    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setscrollHeight();
      });
  }

  ngOnInit() {}

  public setRunStatus(event) {
    this.stationmonitorservice.setRunStatusCache(this.form.value.runStatus);
  }
  public getClassIds(data) {
    this.form.patchValue({
      classIds: data,
    });
  }

  public setscrollHeight() {
    let otherHeight = 50 + 88 + 50 + 29;
    this.scrollHeight = this.common.setTableScrollHeight(otherHeight);
  }

  public initForm() {
    this.runStatusList = [
      { label: '', value: null },
      { label: '正常', value: 0 },
      { label: '告警', value: 1 },
      { label: '通讯中断', value: 2 },
    ];
    let areaParams = {
      stationId: this.stationId,
    };
    let modelParams = {
      groupType: [this.deviceType],
      stationIds: [this.stationId],
    };
    forkJoin(
      this.stationmonitorservice.getArea(areaParams),
      this.stationmonitorservice.getModel(modelParams)
    ).subscribe((res) => {
      this.areaList = res[0];
      this.modelList = res[1];
    });

    this.form = this.formBuilder.group({
      areaIds: new FormControl(''),
      classIds: new FormControl(''),
      modelIds: new FormControl(''),
      runStatus: new FormControl(''),
      deviceName: new FormControl(''),
    });

    this.setscrollHeight();
  }

  public otherQuery(first?) {
    if (first) {
      this.queryTable(true);
    } else {
      this.pager.page = 0;
      this.refreshData();
    }
  }

  public queryTable(first?) {
    if (first) {
      this.pager.page = 0;
      this.sortBy = 'deviceName';
      this.sortOrder = 'asc';
    }
    let params = _.assign({}, this.form.value, {
      page: this.pager.page,
      size: this.pager.size,
      stationId: this.stationId,
      sortBy: this.sortBy,
      sortOrder: this.sortOrder,
    });
    this.tableloading = true;
    this.stationmonitorservice.getOtherDatas(params).subscribe((res: any) => {
      this.tableloading = false;
      if (res) {
        // this.sortBy = res.sortBy;
        // this.sortOrder = res.sortOrder;
        // this.defaultSortOrder = res.sortOrder === 'asc' ? 1 : -1;
        this.columns = res.headers;
        this.dataList = res.datas.content;
        if (
          this.dataList.length === 0 &&
          params.deviceName &&
          !params.areaIds &&
          _.isEmpty(params.classIds) &&
          !params.modelIds &&
          !params.runStatus
        ) {
          this.tipservice.tip(
            'warn',
            `当前电站没有【${params.deviceName}】设备`,
            '查询设备提示'
          );
        }
        this.pager.totalRecords = res.datas.totalElements;
      }
    });
  }

  public customSort(event: LazyLoadEvent) {
    this.sortBy = event.sortField;
    this.sortOrder = event.sortOrder === 1 ? 'asc' : 'desc';
    this.refreshData();
  }

  public refreshData() {
    this.queryTable();
  }

  /**
   * onPageChange
   */
  public onPageChange(event) {
    this.pager.size = event.rows;
    this.pager.page = event.page;
    this.queryTable();
  }

  /**
   * export
   */
  public export() {
    let params = _.assign({}, this.form.value, {
      stationId: this.stationId,
    });
    this.stationmonitorservice.otherExport(params);
  }

  /**
   * deviceDetail
   */
  public deviceDetail(deviceId, stationId, deviceType) {
    this.stationmonitorservice.setDeviceDetail({
      deviceId,
      stationId,
      deviceType,
      showConfig: true,
    });
  }
}
