import { Injectable } from "@angular/core";
import { AuthHttp } from "../../../core/auth-http";
import { Observable } from "rxjs";
import { AppConfigService } from "../../../app.config";
import { PowerStation } from "../../monitor/shared/index.model";

@Injectable()
export class TopoMangeService {
    public constructor(
        private http: AuthHttp,
        private config: AppConfigService
    ) {}

    public getStations(): Observable<PowerStation[]> {
        return this.http.get(
            `${this.config.apiUrl}/api/index/getSampleStationAll`
        );
    }

    public getTopoList(
        stationId: string,
        page: number = 1,
        size: number = 10
    ): Observable<any> {
        return this.http.get(
            `${this.config.apiUrl}/api/topographic/getTopologyGraphicByStaId`,
            { search: { stationId, page, size } }
        );
    }

    public getStationArea(stationId: string) {
        return this.http.get(
            `${this.config.apiUrl}/api/location/getStationLocation`,
            { search: { stationId } }
        );
    }

    public getDeviceTopoByAreaIds(areaIds: string[]) {
        return this.http.get(
            `${this.config.apiUrl}/api/topographic/getDeviceTopoByAreaIds`,
            { search: { areaIds } }
        );
    }

    public changeArea(topoId: string, stationId: string, areaId: string) {
        return this.http.put(
            `${this.config.apiUrl}/api/topographic/changeArea`,
            "",
            { search: { topoId, stationId, areaId } }
        );
    }

    public getAllAreas(stationId: string) {
        return this.http.get(`${this.config.apiUrl}/api/stationAreas/all`, {
            search: { stationId }
        });
    }

    public deleteTopo(topoIds: number[]) {
        return this.http.delete(
            `${this.config.apiUrl}/api/topographic/deleteTopologyGraphicByTopoIds`,
            { search: { topoIds } }
        );
    }

    /**
     * 保存页面自动生成的方阵设备拓扑图
     *
     * @param {any} deviceTopos key:areaId value:desc
     * @memberof TopoMangeService
     */
    public saveDeviceTopos(deviceTopos) {
        return this.http.post(
            `${this.config.apiUrl}/api/topographic/deviceTopo`,
            deviceTopos
        );
    }
}
