import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TipService } from '@common/services/tip.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ClassesType } from 'src/app/common/models/classes-type.model';
import { CommonService } from 'src/app/common/services/common.service';
import {
  IoType,
  MaterialType,
} from 'src/app/components/device-assets/shared/index.model';
import { CodeTableService } from 'src/app/core/code-table.service';
import { CalendarLocales } from 'src/app/shared/locales/calendar.locales';
import { buildWorkFlow } from '../../repair/repair-ticket/work-flow.echarts';
import { OperateService } from './../operate.service';

@Component({
  selector: 'app-operate-ticket',
  templateUrl: './operate-ticket.component.html',
  styleUrls: ['./operate-ticket.component.less'],
})
export class OperateTicketComponent implements OnInit {
  @Input() public type = 'add';
  @Input() public ticketId = 0;
  @Input() public data = null;
  @Input() public isShow = false;

  @Output() hideEvent = new EventEmitter<boolean>();
  @Output() refreshEvent = new EventEmitter<boolean>();
  // 标题
  title: string;
  // ticket表单
  form: FormGroup;
  // 字段
  fields: any;
  // 电站id
  stationId: any;
  // 电站选择器参数
  staParams = null;
  // 用户选择器弹窗 开关
  isShowUserDialog: boolean;
  // 用户选择弹窗类型 单选/多选
  userDialogType: boolean;
  // 用户选择器 修改字段名称
  userDialogTarget: any;
  // 用户选择器参数
  person = [];
  // 日历参数
  canlendarLocale: any;

  // 订单Id
  orderId: any;
  // 工作流程 nodes
  nodes: any;
  // 工作流程 links
  links: any;
  // 工作流程 图表 options
  options: any;

  // 图片权限
  picPermission: any;
  // 文件权限
  docPermission: any;
  // 抄送权限
  ccPermission: any;
  // 评论权限 （由评论列表中参数控制）
  commentPermission: any;
  // 弹窗 按钮组
  buttons: any;

  // 抢修检查 弹窗开关
  ticketCheckDialog = false;
  // 抢修检查默认值
  checkDialog = {
    checkStatus: '02',
    checkMemo: null,
  };
  // 抢修检查状态 选项
  checkStatuses: SelectItem[];

  // 出入库弹窗 开关
  isShowRecords: boolean;
  // 出入库 传入参数
  recordsParam: any;
  // materialType
  materialType = MaterialType.spareParts;
  // ClassesType
  classesType = ClassesType.spareParts;
  // 是否是进行节点扭转 选择用户
  nextUserFlag: boolean;
  // 扭转用户ID
  nextUserIds: any;

  // 备注弹窗 开关
  memoDialog: boolean;
  // 备注弹窗 备注字段
  memo: any;

  // 按钮行为
  buttonAction: any;

  // 评论列表
  commentsList: any[];

  // 设备Id
  objClassOptions: any;
  ioTypes: IoType;
  selectedTeam: { teamId: any; teamName: any }[];
  tasks: any[];
  showTemplateSelector: boolean;
  loadTemplate: any;

  constructor(
    private fb: FormBuilder,
    private operateservice: OperateService,
    private calendarLocales: CalendarLocales,
    private commonservice: CommonService,
    private codeTableService: CodeTableService,
    private confirmationService: ConfirmationService,
    private tipservice: TipService
  ) {
    // 初始化 日历参数
    this.canlendarLocale = this.calendarLocales.getCurrentLocale();
    // 初始化 操作票form
    this.initForm();
  }

  ngOnInit() {
    // 根据 type 判断 弹窗显示 挑剔
    if (this.type === 'add') {
      this.title = '新增操作票';
    } else if (this.type === 'view') {
      this.title = '查看操作票';
    } else if (this.type === 'edit') {
      this.title = '编辑操作票';
    }
    // 初始化数据
    this.getTicketData();
  }

  /**
   * 初始化form表单
   */
  private initForm() {
    this.form = this.fb.group({
      // 基础信息
      ticketId: new FormControl(null), // 抢修Id
      ticketCode: new FormControl(null), // 抢修单号
      orderId: new FormControl(null), // orderId
      stationId: new FormControl(null), // 电站Id
      stationName: new FormControl(null), // 电站名称
      parentOrderCode: new FormControl(null), // 工作票票号
      operateType: new FormControl(null), // 操作类型
      sender: new FormControl(null), // 发令人
      senderName: new FormControl(null), // 发令人名称
      receiver: new FormControl(null), // 受令人
      receiverName: new FormControl(null), // 受令人名称
      orderTime: new FormControl(null), // 受令时间
      operateDesc: new FormControl(null), // 操作任务
      auditor: new FormControl(null), // 审票人
      auditorName: new FormControl(null), // 审票人名称
      ticketLeader: new FormControl(null), // 值班负责人
      ticketLeaderName: new FormControl(null), // 值班负责人名称
      executer: new FormControl(null), // 操作人
      executerName: new FormControl(null), // 操作人名称
      supervisior: new FormControl(null), // 监护人
      supervisiorName: new FormControl(null), // 监护人名称

      // 操作结果
      tasks: new FormArray([]),
      memo: new FormControl(null), // 备注
      startTime: new FormControl(null), // 操作开始时间
      endTime: new FormControl(null), // 操作完成时间
      workHours: new FormControl(null), // 工作量
      creater: new FormControl(null), // 填票人
      createrName: new FormControl(null), // 填票人名称
      createTime: new FormControl(null), // 填票时间

      pics: this.fb.array([]), // 抢修图片
      docs: this.fb.array([]), // 抢修附件
      checker: new FormControl(null), // 检查人
      checkerName: new FormControl(null), // 检查人名称
      checkTime: new FormControl(null), // 检查时间
      checkStatus: new FormControl(null), // 检查结果
      checkStatusText: new FormControl(null), // 检查结果
      checkMemo: new FormControl(null), // 检查备注
      // 工作流程
      workflow: this.fb.group({
        nodes: this.fb.array([]),
        links: this.fb.array([]),
      }),

      // 流程日志
      workflowLogs: this.fb.array([]),

      // 抄送
      ccUsers: this.fb.array([]),
    });
  }

  public initTask() {
    return new FormGroup({
      orderNo: new FormControl(null),
      taskDesc: new FormControl(null, Validators.required),
      taskId: new FormControl(null),
      ticketId: new FormControl(null),
      executeTime: new FormControl(null),
    });
  }

  /**
   * 初始化 页面数据
   */
  private getTicketData() {
    this.commonservice.mask.show();
    this.operateservice.getOperateByTicketId(this.ticketId).subscribe(
      (res: any) => {
        // 页面字段
        this.fields = res.fields;
        for (const key in this.fields) {
          if (this.fields.hasOwnProperty(key)) {
            const element = this.fields[key];
            if (key === 'operateType') {
              if (element.props) {
                element.props.map((item) => {
                  item.value = item.first;
                  item.label = item.second;
                });
              }
            }
          }
        }

        // 按钮组
        this.buttons = res.buttons;
        // 返回值
        const values = res.ticket;

        // 图片权限
        this.picPermission = res.picPermission;
        // 文档权限
        this.docPermission = res.docPermission;
        // 抄送权限
        this.ccPermission = res.ccPermission;
        // 评论权限
        this.commentPermission = res.commentPermission;
        // 加载典型操作票
        this.loadTemplate = res.loadTemplate;
        // orderId
        this.orderId = values.orderId;
        // 处理电站
        this.stationId = values.stationId;
        if (this.stationId) {
          this.staParams = {
            sta_stationIds: values.stationId,
          };
        } else {
          this.staParams = {};
        }

        // 时间格式处理
        values.orderTime = values.orderTime
          ? moment(values.orderTime).toDate()
          : null;
        values.createTime = values.createTime
          ? moment(values.createTime).toDate()
          : null;
        values.startTime = values.startTime
          ? moment(values.startTime).toDate()
          : null;
        values.endTime = values.endTime
          ? moment(values.endTime).toDate()
          : null;

        if (values.tasks !== null) {
          const control = this.form.get('tasks') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          values.tasks.map((item, index) => {
            control.push(this.initTask());
            item.executeTime = item.executeTime
              ? moment(item.executeTime).toDate()
              : null;
          });
        } else {
          const control = this.form.get('tasks') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          control.push(this.initTask());
          values.tasks = [
            {
              orderNo: null,
              taskDesc: null,
              taskId: null,
              ticketId: null,
              executeTime: null,
            },
          ];
        }

        // 照片
        if (values.pics !== null) {
          const control = this.form.get('pics') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          values.pics.map((item) => {
            control.push(this.initDoc());
          });
        } else {
          values.pics = [];
        }

        // 文件
        if (values.docs !== null) {
          const control = this.form.get('docs') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          values.docs.map((item) => {
            control.push(this.initDoc());
          });
        } else {
          values.docs = [];
        }

        // 工作流程
        if (values.workflow !== null) {
          const workflow = values.workflow;
          for (const key in workflow) {
            if (workflow.hasOwnProperty(key)) {
              const element = workflow[key];
              if (key === 'nodes') {
                this.nodes = element;
                const control = (this.form.get('workflow') as FormGroup)
                  .controls[key] as FormArray;
                while (control.length) {
                  control.removeAt(control.length - 1);
                }
                element.map((item) => {
                  control.push(this.initNodes());
                });
              } else if (key === 'links') {
                this.links = element;
                const control = (this.form.get('workflow') as FormGroup)
                  .controls[key] as FormArray;
                while (control.length) {
                  control.removeAt(control.length - 1);
                }
                element.map((item) => {
                  control.push(this.initLinks());
                });
              }
            }
          }
          this.options = buildWorkFlow(this.links, this.nodes);
        } else {
          values.workflow = {};
        }

        // 流程日志
        if (values.workflowLogs) {
          const control = this.form.get('workflowLogs') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          values.workflowLogs.map((item) => {
            control.push(this.initWorkflowLogs());
          });
        } else {
          values.workflowLogs = [];
        }

        // 抄送
        if (values.ccUsers !== null) {
          const control = this.form.get('ccUsers') as FormArray;
          while (control.length) {
            control.removeAt(control.length - 1);
          }
          values.ccUsers.map((item) => {
            control.push(this.initCcUser());
          });
        } else {
          values.ccUsers = [];
        }

        // 评论
        if (values.comments === null) {
          values.comments = [];
          this.commentsList = [];
        } else {
          this.commentsList = values.comments;
        }
        // 表单填值
        this.form.patchValue(values);
        // 查看状态显示权限
        if (this.type === 'view') {
          // 图片权限
          this.picPermission = 0;
          // 文档权限
          this.docPermission = 0;
          // 抄送权限
          this.ccPermission = 0;
          this.form.disable();
        }
      },
      () => { },
      () => {
        this.commonservice.mask.hide();
      }
    );
  }

  // 流程日志数组
  getWorkflowLogs(form) {
    return form.controls.workflowLogs.controls;
  }
  // 弹窗关闭事件
  onHide(event) {
    this.hideEvent.emit(true);
  }
  // 电站选择器 返回事件
  outputStaParamsEvent(event) {
    if (event) {
      this.stationId = event.sta_stationIds;
      this.form.patchValue({
        stationId: event.sta_stationIds,
        stationName: event.sta_stationName,
      });
    } else {
      this.stationId = null;
      this.form.patchValue({
        stationId: null,
        stationName: null,
      });
    }
  }

  /**
   * 打开用户选择
   * @param <{ String }> type - 用户选择器类型 single/multi.
   * @param <{ String }> field - 当前操作的字段.
   * @param <{ Boolean }> editable - 当前字段是否客编辑.
   */
  openUserDialog(event, type, field, editable) {
    // 如果是查看或是不可编辑状态 返回
    if (this.type === 'view' || editable === false) {
      return;
    }
    this.userDialogType = type === 'single' ? true : false;
    this.userDialogTarget = field;
    const value = this.form.value[field];
    // 判断 单选还是多选
    if (this.userDialogType === true) {
      this.person = value ? [{ userId: value }] : null;
    } else {
      this.person = value.map((item) => {
        return {
          userId: item.userId,
          realName: item.userName,
        };
      });
    }
    this.isShowUserDialog = true;
  }

  // 用户选择器 返回事件
  personChange(event) {
    // 节点扭转选择用户
    if (this.nextUserFlag === true) {
      if (event.userId) {
        this.nextUserIds = event.userId;
        this.alertTypeFunction();
      }
      this.nextUserFlag = false;
      return;
    }
    if (this.userDialogType) {
      this.form.patchValue({
        [this.userDialogTarget]: event.userId,
        [this.userDialogTarget + 'Name']: event.realName,
      });
    } else {
      const control = this.form.get(this.userDialogTarget) as FormArray;
      while (control.length) {
        control.removeAt(control.length - 1);
      }
      const Users = [];
      const UsersText = event.map((item) => {
        Users.push({
          userId: item.userId,
          userName: item.realName,
        });
        control.push(this.initUsers());
        return item.realName;
      });
      this.form.get(this.userDialogTarget).patchValue(Users);
      this.form
        .get(this.userDialogTarget + 'Text')
        .patchValue(UsersText.join(','));
    }
  }

  public getTask(form) {
    return form.controls.tasks.controls;
  }

  public addTask(i) {
    const control = this.form.get('tasks') as FormArray;
    control.insert(i + 1, this.initTask());
    this.getMaxExecuteTime();
  }

  public deleteTask(i) {
    const control = this.form.get('tasks') as FormArray;
    if (control.controls.length === 1) {
      return;
    }
    this.confirmationService.confirm({
      header: '提示',
      message: `确定删除当前步骤么？`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        control.removeAt(i);
        this.getMinExecuteTime();
        this.getMaxExecuteTime();
      },
    });
  }

  public taskExecuteTimeChange(event, i) {
    if (event) {
      if (moment(event).valueOf() > moment().valueOf()) {
        this.tipservice.tip('warn', '操作时间不得晚于当前系统时间');
        const control = this.form.get('tasks') as FormArray;
        control.controls[i].patchValue({ executeTime: null });
        this.getMinExecuteTime();
        this.getMaxExecuteTime();
        return;
      }
    }
    this.getMinExecuteTime();
    this.getMaxExecuteTime();
  }

  public getMinExecuteTime() {
    const control = this.form.get('tasks') as FormArray;
    const minDate = _.minBy(control.controls, (o: any) => {
      return moment(o.value.executeTime).valueOf();
    });
    if (minDate && minDate.value && minDate.value.executeTime) {
      this.form.patchValue({
        startTime: moment(minDate.value.executeTime).format('YYYY-MM-DD HH:mm'),
      });
    } else {
      this.form.patchValue({
        startTime: null,
      });
    }
  }

  public getMaxExecuteTime() {
    let sum = 0;
    const control = this.form.get('tasks') as FormArray;
    control.controls.map((item) => {
      if (item.value.executeTime) {
        sum += 1;
      }
    });
    if (control.controls.length === sum) {
      const maxDate = _.maxBy(control.controls, (o: any) => {
        return moment(o.value.executeTime).valueOf();
      });
      this.form.patchValue({
        endTime: moment(maxDate.value.executeTime).format('YYYY-MM-DD HH:mm'),
      });
    } else {
      this.form.patchValue({
        endTime: null,
      });
    }
  }

  checkTimes(event, type) {
    if (type === 'startTime' || type === 'endTime') {
      if (moment(this.form.value[type]).valueOf() > moment().valueOf()) {
        this.tipservice.tip('warn', '操作票时间不得晚于当前系统时间');
        this.form.patchValue({
          [type]: null,
        });
        return;
      }
    }
    if (this.form.value.endTime && this.form.value.startTime) {
      if (
        moment(this.form.value.endTime).valueOf() <
        moment(this.form.value.endTime).valueOf()
      ) {
        this.tipservice.tip('warn', '操作票结束时间不得早于操作开始时间');
        return;
      }
    }
  }

  public outputTicketTasksEvent(event) {
    if (event) {
      this.staParams = {
        sta_stationIds: event.stationId,
      };
      this.form.patchValue({
        stationId: event.stationId,
        operateDesc: event.operateDesc,
      });
      if (event.tasks) {
        const control = this.form.get('tasks') as FormArray;
        while (control.length) {
          control.removeAt(control.length - 1);
        }
        event.tasks.map((item, index) => {
          // 删除引用典型票的taskId，ticketId
          item.taskId = null;
          item.ticketId = null;
          control.push(this.initTask());
        });
        this.form.get('tasks').patchValue(event.tasks);
      }
    }
  }

  // 按钮组 操作
  switchFunction(button) {
    switch (button.buttonAction) {
      case '$check$':
        this.openDefectCheckDialog();
        break;
      case 'materialOut':
        this.materialOutFunction();
        break;
      case 'materialIn':
        this.materialInFunction();
        break;
      case 'delete':
        this.deleteFunction();
        break;
      case 'tmpSave':
        this.tmpSaveFunction(button);
        break;
      default:
        this.buttonFunction(button);
        break;
    }
  }

  tmpSaveFunction(button) {
    if (button.formCheck === '1') {
      this.operateservice.getNullable(button.targetNodeId).then((res: any) => {
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            if (key.indexOf('.') === -1) {
              const element = res[key];
              if (this.form.value[key] === null && !element) {
                this.tipservice.tip(
                  'warn',
                  this.fields[key].name + '字段不能为空'
                );
                return;
              }
            } else {
              const aNewKey = key.split('.');
              const element = res[key];
              if (this.form.value[aNewKey[0]].length > 0 && !element) {
                let flag = true;
                this.form.value[aNewKey[0]].every((item) => {
                  if (item[aNewKey[1]] === null) {
                    this.tipservice.tip(
                      'warn',
                      this.fields[key].name + '字段不能为空'
                    );
                    flag = false;
                    return false;
                  } else {
                    return true;
                  }
                });
                if (!flag) {
                  return;
                }
              }
            }
          }
        }
        const params = {
          title: this.title,
          ticket: this.formatParams(this.form.value),
          action: button,
          nextUserIds: null,
          memo: this.memo,
        };
        if (this.ticketId === 0) {
          this.operateservice
            .saveOperateTickets(params)
            .subscribe((res: number) => {
              this.tipservice.tip('success', '暂存成功');
              this.ticketId = res;
              this.orderId = res;
              this.form.patchValue({
                ticketId: res,
                orderId: res,
              });
            });
        } else {
          this.operateservice.updateOperateTickets(params).subscribe((res) => {
            this.tipservice.tip('success', '暂存成功');
          });
        }
      });
    } else {
      const params = {
        title: this.title,
        ticket: this.formatParams(this.form.value),
        action: button,
        nextUserIds: null,
        memo: this.memo,
      };
      if (this.ticketId === 0) {
        this.operateservice
          .saveOperateTickets(params)
          .subscribe((res: number) => {
            this.tipservice.tip('success', '暂存成功');
            this.ticketId = res;
            this.orderId = res;
            this.form.patchValue({
              ticketId: res,
              orderId: res,
            });
          });
      } else {
        this.operateservice.updateOperateTickets(params).subscribe((res) => {
          this.tipservice.tip('success', '暂存成功');
        });
      }
    }
  }

  // 按钮组 step1：formCheck
  buttonFunction(button) {
    if (button.formCheck === '1') {
      this.operateservice.getNullable(button.targetNodeId).then((res: any) => {
        for (const key in res) {
          if (res.hasOwnProperty(key)) {
            if (key.indexOf('.') === -1) {
              const element = res[key];
              if (this.form.value[key] === null && !element) {
                this.tipservice.tip(
                  'warn',
                  this.fields[key].name + '字段不能为空'
                );
                return;
              }
            } else {
              const aNewKey = key.split('.');
              const element = res[key];
              if (this.form.value[aNewKey[0]].length > 0 && !element) {
                let flag = true;
                this.form.value[aNewKey[0]].every((item) => {
                  if (item[aNewKey[1]] === null) {
                    this.tipservice.tip(
                      'warn',
                      this.fields[key].name + '字段不能为空'
                    );
                    flag = false;
                    return false;
                  } else {
                    return true;
                  }
                });
                if (!flag) {
                  return;
                }
              }
            }
          }
        }
        this.userSelectorFunction(button);
      });
    } else {
      this.userSelectorFunction(button);
    }
  }

  // 按钮组 step2：userSelector
  public userSelectorFunction(button) {
    this.buttonAction = button;
    if (button.userSelector === '0') {
      this.alertTypeFunction(button);
    } else if (button.userSelector === '1') {
      this.person = [];
      this.userDialogType = true;
      this.nextUserFlag = true;
      this.isShowUserDialog = true;
    } else if (button.userSelector === '2') {
      this.person = [];
      this.userDialogType = false;
      this.nextUserFlag = true;
      this.isShowUserDialog = true;
    }
  }

  public alertTypeFunction(button?) {
    button = button ? button : this.buttonAction;
    this.memo = null;
    if (button.alertType === '0') {
      this.actionFunction();
    } else if (button.alertType === '1') {
      this.confirmationService.confirm({
        header: '提示',
        message: `确定要提交么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          this.actionFunction();
        },
      });
    } else if (button.alertType === '2') {
      this.memo = null;
      this.nextUserIds = null;
      this.memoDialog = true;
    }
  }

  // 打开抢修检查弹窗
  openDefectCheckDialog() {
    this.codeTableService.getNemsProp('orderCheckStatus').then((res) => {
      this.checkStatuses = res;
      this.ticketCheckDialog = true;
    });
  }

  // 检查操作
  checkFunction(event) {
    if (this.checkDialog.checkMemo && this.checkDialog.checkMemo.length > 500) {
      this.tipservice.tip('warn', '结果说明不超过500字符');
      return;
    }
    const params = {
      ticketId: this.ticketId,
      checkStatus: this.checkDialog.checkStatus,
      checkMemo: this.checkDialog.checkMemo,
    };
    this.operateservice.check(params).subscribe((res: any) => {
      this.tipservice.tip('success', '提交成功');
      this.form.patchValue(res);
      this.ticketCheckDialog = false;
    });
  }

  // 出库
  materialOutFunction() {
    this.recordsParam = {
      orderCode: this.form.value.ticketCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '08',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.out;
    this.isShowRecords = true;
  }

  // 入库
  materialInFunction() {
    this.recordsParam = {
      orderCode: this.form.value.ticketCode,
      orderId: this.form.value.orderId,
      relaBusinessType: '08',
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
    };
    this.ioTypes = IoType.into;
    this.isShowRecords = true;
  }

  // 出入库弹窗 返回事件
  submitAddData(event) {
    this.hideEvent.emit(true);
    this.refreshEvent.emit(true);
  }

  // 删除
  deleteFunction() {
    this.confirmationService.confirm({
      header: '提示',
      message: `确定要删除该抢修单么?`,
      acceptLabel: '确定',
      rejectLabel: '取消',
      accept: () => {
        const ticketId = this.form.value.ticketId;
        this.operateservice.deleteTicketsOperate(ticketId).subscribe((res) => {
          this.tipservice.tip('success', '删除成功');
          this.hideEvent.emit(true);
          this.refreshEvent.emit(true);
        });
      },
    });
  }

  // 打开转派/派单 窗口
  openDispatchDialog(button) {
    this.person = [];
    this.buttonAction = button;
    this.userDialogType = true;
    this.nextUserFlag = true;
    this.isShowUserDialog = true;
  }

  // 驳回/关闭/完成 窗口
  openMemoDialog(button) {
    this.memo = null;
    this.nextUserIds = null;
    this.memoDialog = true;
    this.buttonAction = button;
  }

  //
  actionFunction() {
    const params = {
      title: this.title,
      ticket: this.formatParams(this.form.value),
      action: this.buttonAction,
      nextUserIds: this.nextUserIds ? [this.nextUserIds] : null,
      memo: this.memo,
    };
    if (this.ticketId === 0) {
      this.operateservice.saveOperateTickets(params).subscribe((res) => {
        this.nextUserIds = null;
        this.memo = null;
        this.tipservice.tip('success', '提交成功');
        this.hideEvent.emit(true);
        this.refreshEvent.emit(true);
      });
    } else {
      this.operateservice.updateOperateTickets(params).subscribe((res) => {
        this.nextUserIds = null;
        this.memo = null;
        this.tipservice.tip('success', '提交成功');
        this.hideEvent.emit(true);
        this.refreshEvent.emit(true);
      });
    }
  }

  openTemplateSelector(event) {
    const stationId = this.form.value.stationId;
    if (stationId) {
      this.staParams = {
        sta_stationIds: stationId,
      };
    } else {
      this.staParams = {};
    }
    this.showTemplateSelector = true;
  }

  templateSelectorEvent(event) {
    this.showTemplateSelector = false;
  }

  // 图片上传组件 返回事件
  setPics(data) {
    const value = this.form.value.pics || [];
    const control = this.form.get('pics') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initDoc());
      value.push(item);
    });
    this.form.get('pics').patchValue(value);
  }

  // 附件上传组件 返回事件
  setDocs(data) {
    const value = this.form.value.docs || [];
    const control = this.form.get('docs') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initDoc());
      value.push(item);
    });
    this.form.get('docs').patchValue(value);
  }

  // 抄送组件 返回事件
  ccUserEvent(data) {
    const control = this.form.get('ccUsers') as FormArray;
    while (control.length) {
      control.removeAt(control.length - 1);
    }
    data.map((item) => {
      control.push(this.initCcUser());
    });
    this.form.patchValue({
      ccUsers: data,
    });
  }

  // 初始化 用户 formGroup
  initUsers() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  initDoc() {
    return new FormGroup({
      docId: new FormControl(null),
      docName: new FormControl(null),
      docType: new FormControl(null),
      folderId: new FormControl(null),
      size: new FormControl(null),
      sizeDesc: new FormControl(null),
      memo: new FormControl(null),
      orderNo: new FormControl(null),
      customerId: new FormControl(null),
      uri: new FormControl(null),
      updateTime: new FormControl(null),
      updater: new FormControl(null),
      stationId: new FormControl(null),
      realName: new FormControl(null),
      stationName: new FormControl(null),
      recordType: new FormControl(null),
      recordId: new FormControl(null),
      updaterName: new FormControl(null),
    });
  }

  initNodes() {
    return new FormGroup({
      statusText: new FormControl(null),
      name: new FormControl(null),
      nodeType: new FormControl(null),
      user: new FormControl(null),
      status: new FormControl(null),
    });
  }

  initLinks() {
    return new FormGroup({
      sourceId: new FormControl(null),
      targetId: new FormControl(null),
      source: new FormControl(null),
      target: new FormControl(null),
    });
  }

  initWorkflowLogs() {
    return new FormGroup({
      logId: new FormControl(null),
      action: new FormControl(null),
      executeTime: new FormControl(null),
      flowId: new FormControl(null),
      memo: new FormControl(null),
      nextNodeId: new FormControl(null),
      nextNodeName: new FormControl(null),
      nodeId: new FormControl(null),
      orderId: new FormControl(null),
      preNodeId: new FormControl(null),
      preNodeName: new FormControl(null),
      userId: new FormControl(null),
      userName: new FormControl(null),
    });
  }

  initCcUser() {
    return new FormGroup({
      userId: new FormControl(null),
      userName: new FormControl(null),
      userIcon: new FormControl(null),
    });
  }

  formatParams(params) {
    let permitTime = {};
    if (params.permitTime) {
      permitTime = {
        permitTime: moment(params.permitTime).format(),
      };
    }
    let permitWorkTime = {};
    if (params.permitWorkTime) {
      permitWorkTime = {
        permitWorkTime: moment(params.permitWorkTime).format(),
      };
    }

    let startTime = {};
    if (params.startTime) {
      startTime = {
        startTime: moment(params.startTime).format(),
      };
    }
    let endTime = {};
    if (params.endTime) {
      endTime = {
        endTime: moment(params.endTime).format(),
      };
    }
    let reportTime = {};
    if (params.reportTime) {
      reportTime = {
        reportTime: moment(params.reportTime).format(),
      };
    }

    return {
      ...params,
      ...permitTime,
      ...reportTime,
      ...permitWorkTime,
      ...startTime,
      ...endTime,
    };
  }
}
