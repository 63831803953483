import { WidgetsService } from './../widgets.service';
import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.less'],
})
export class WeatherComponent implements OnInit {
  loading: boolean;
  option: any;

  constructor(private widgetsservice: WidgetsService) {}

  ngOnInit() {
    this.getWeather({});
  }

  public getWeather(stationParams) {
    this.loading = true;
    const params = {
      ...stationParams.staParams,
      date: moment().format('YYYY-MM-DD'),
    };
    this.widgetsservice.weather(params).then((res) => {
      this.loading = false;
      this.option = this.buildWeatherStatisticsChart(res);
    });
  }

  public buildWeatherStatisticsChart(returnValue) {
    const option = {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          // 坐标轴指示器，坐标轴触发有效
          type: 'shadow', // 默认为直线，可选为：'line' | 'shadow'
        },
      },
      grid: {
        bottom: 35,
        top: 35,
      },
      legend: {
        type: 'scroll',
        padding: [5, 20],
        pageTextStyle: {
          color: '#fff',
        },
        pageIconColor: '#35baff',
        pageIconInactiveColor: '#98bcd6',
        data: returnValue.option.legend.data,
      },
      xAxis: {
        type: 'category',
        data: returnValue.option.xAxis.data,
      },
      yAxis: {
        type: 'value',
      },
      series: returnValue.option.series,
    };
    return option;
  }
}
