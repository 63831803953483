import { Injectable } from "@angular/core";
import * as _ from "lodash";

@Injectable()
export class TopoTabService {
    private topoId;
    public tabs: any = [];
    public currentTab: any;

    public constructor() {}

    public initEditorTabService(topoId?: any, tabId?: any, currentTab?: any) {
        if (currentTab != undefined) {
            this.currentTab = currentTab;
        }
        if (
            _.findIndex(this.tabs, function(o: any) {
                return o._tabId == tabId;
            }) == -1 &&
            currentTab == undefined
        ) {
            this.tabs.push({ _topoId: topoId, _tabId: tabId });
        }
    }
}
