import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import * as _ from "lodash";

import { AppConfigService } from "../../app.config";
import { PointValue } from "../../components/monitor/shared/index.model";

declare var SockJS: any;
declare var Stomp: any;

@Injectable()
export class WebsocketService {
    private stompClient;
    private connectedPromise;
    private socket;
    private reconnectInterval;
    private infoLogStyle = "background: #222; color: #00ff00";
    private cached = {};

    public constructor(
        private appConfig: AppConfigService
    ) // private commonService: CommonService,
    {}

    public connect(
        isReconnect: boolean = true,
        interval: number = 1000,
        maxTimes: number = 1
    ) {
        let jwt = localStorage.getItem("jwt");
        if (!this.socket) {
            this.socket = this.createSocke(jwt);
        }

        this.stompClient = Stomp.over(this.socket);
        this.stompClient.debug = false;
        let headers = {
            token: jwt
        };
        // this.stompClient.connect(headers);

        this.connectedPromise = new Promise((resolve, reject) => {
            this.stompClient.connect(
                headers,
                () => {
                    console.log("%c websocket: 连接成功.", this.infoLogStyle);
                    resolve();
                },
                error => {
                    console.warn("websocket: 连接失败," + error);
                    if (isReconnect) {
                        this.reconnect(interval, maxTimes);
                    }
                    reject();
                }
            );
        });
        return this.connectedPromise;
    }

    public disconnect() {
        return new Promise((resolve, reject) => {
            if (this.stompClient) {
                this.stompClient.disconnect(() => {
                    this.connectedPromise = null;
                    resolve();
                });
            } else {
                reject("websocket: 尚未建立连接.");
            }
        });
    }

    public send(destination: string, headers?: Object, body?: string) {
        if (!this.connectedPromise) {
            this.connect();
        }
        return this.connectedPromise.then(() => {
            console.log(
                `%c websocket: 发起订阅 destination:${destination}, headers:${JSON.stringify(
                    headers
                )}, body:`,
                this.infoLogStyle
            );
            console.dir(JSON.parse(body));
            this.stompClient.send(destination, headers ? headers : {}, body);
        });
    }

    /**
     * 订阅websocket数据
     *
     * @param {string} destination 订阅地址
     * @param {object} [headers] 订阅请求自定义headers
     * @returns Promise
     * @memberof WebsocketService
     */
    public subscribe(destination, type, headers = null) {
        return Observable.create(observer => {
            let executor = message => {
                let result = null;

                if (message && message.body) {
                    try {
                        result = JSON.parse(message.body);
                    } catch (e) {
                        let msg = "websocket: 不能转换数据" + e;
                        console.warn(msg);
                        observer.error({ msg, destination });
                    }
                }
                console.log(
                    `%c websocket: 接收到数据, destination:${destination}, headers:${headers}, 订阅结果:`,
                    this.infoLogStyle
                );

                if (type === "point") {
                    // TODO q过滤
                    result = _.filter(result, (val: PointValue) => {
                        return val.q % 1000 < 100;
                    });
                }
                console.dir(result);
                observer.next(result);
                // observer.complete(destination);
            };

            this.connectedPromise.then(() => {
                this.stompClient.subscribe(destination, executor, headers);
            });
        });
    }

    public unsubscribe(destination) {
        console.log(
            `%c websocket: 取消订阅, destination:${destination}`,
            this.infoLogStyle
        );
        return this.stompClient && this.stompClient.unsubscribe(destination);
    }

    private createSocke(jwt: string) {
        return new SockJS(
            `${this.appConfig.messagingUrl}/api/push?token=${jwt}`
        );
    }

    private reconnect(interval: number = 3000, maxTimes: number) {
        let times = 1;
        return new Promise((resolve, reject) => {
            if (this.reconnectInterval) {
                clearInterval(this.reconnectInterval);
            }
            this.connectedPromise.catch(() => {
                this.reconnectInterval = setInterval(() => {
                    if (times >= maxTimes) {
                        clearInterval(this.reconnectInterval);
                        reject(`websocket: 重连失败, 超过最大次数${maxTimes}.`);
                    }
                    console.log(
                        `%c websocket: 正在第${times}重连`,
                        this.infoLogStyle
                    );
                    this.connect(false, interval, maxTimes).then(() => {
                        clearInterval(this.reconnectInterval);
                        resolve();
                    });
                    times++;
                }, interval);
            });
        });
    }
}
