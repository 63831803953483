import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { buildEnergyDayChart } from '../map/charts/energyDay.chart';
import { CommonService } from './../../../common/services/common.service';
import { EmsService } from './../ems.service';

@Component({
  selector: 'app-monitor-station',
  templateUrl: './monitor-station.component.html',
  styleUrls: ['./monitor-station.component.less'],
})
export class MonitorStationComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  stationId: string;
  stationInfo: any;
  mapEnergiesDayOptions: any;
  energyLoading: boolean;
  runInfo: any;
  clustersData: any;
  backItem: any = {
    link: '',
  };
  alarmInfo: any = [];
  removeBackItem = true;

  constructor(
    private es: EmsService,
    private activatedroute: ActivatedRoute,
    private cs: CommonService,
    private router: Router
  ) {
    this.activatedroute.queryParams.subscribe((res) => {
      this.stationId = res.stationId;
    });
  }

  ngOnInit() {
    this.backItem = this.cs.getBackItem();
  }

  ngAfterViewInit(): void {
    this.es.getStationMonitorInfos(this.stationId).subscribe((res) => {
      this.stationInfo = res;
    });
    this.getEmsMapEnergiesDay(this.stationId);
    this.getStationMonitorDatas(this.stationId);
    this.getStationMonitorAlarms(this.stationId);
  }

  ngOnDestroy(): void {
    if (this.removeBackItem) {
      this.cs.removeBackItem();
    }
  }

  async getEmsMapEnergiesDay(stationId) {
    const returnData = await this.es.getStationMonitorEnergies(stationId);
    this.mapEnergiesDayOptions = buildEnergyDayChart(returnData);
  }

  async getStationMonitorDatas(stationId) {
    const data: any = await this.es.getStationMonitorDatas(stationId);
    this.runInfo = {
      ...data,
      soc: data.soc && data.soc.replace(/\s+/g, ''),
      soh: data.soh && data.soh.replace(/\s+/g, ''),
    };
  }

  async getStationMonitorAlarms(stationId) {
    const data: any = await this.es.getStationMonitorAlarms(stationId);
    this.alarmInfo = data;
    this.getStationMonitorClusters(this.stationId);
  }

  async getStationMonitorClusters(stationId) {
    const data: any = await this.es.getStationMonitorClusters(stationId);
    this.clustersData = data.map((item) => {
      return {
        ...item,
        soc: item.soc && item.soc.replace(/\s+/g, ''),
        soh: item.soh && item.soh.replace(/\s+/g, ''),
      };
    });
  }

  goCluster(device) {
    // this.removeBackItem = false;
    // localStorage.setItem(
    //   'backItem',
    //   JSON.stringify({
    //     link: ['/ems/monitor/station'],
    //   })
    // );
    // this.router.navigate(['/ems/monitor/cluster'], {
    //   queryParams: { deviceId: device.deviceId, stationId: this.stationId },
    // });
  }
}
