import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from '@common/services/common.service';
import { TipService } from '@common/services/tip.service';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map } from 'rxjs/operators';
import { AuthService } from './../auth.service';

@Injectable()
export class ResponseHandlerInterceptor implements HttpInterceptor {
  constructor(
    private common: CommonService,
    private authService: AuthService,
    private router: Router,
    private tipservice: TipService
  ) { }
  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      filter((event) => event instanceof HttpResponse && event.status === 200),
      map((event: HttpResponse<any>) => {
        if (event.body.status === -1) {
          throw new HttpErrorResponse({
            ...event,
            error: event.body.data,
            status: 500,
            statusText: 'error',
          });
        }
        return event.clone({ body: event.body.data });
      }),
      catchError((res) => {
        switch (res.error.code) {
          case 300:
            this.authService.logout();
            this.router.navigate(['/login']);
            break;
          default:
            if (
              res.error.error === 'com.cie.nems.common.exception.NemsException'
            ) {
              this.tipservice.tip('error', res.error.message);
            } else {
              this.tipservice.tip('error', '系统错误，请联系管理员！');
            }
        }
        return throwError(res);
      })
    );
  }
}
