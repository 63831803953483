import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { Router, ActivatedRoute, Params, ParamMap } from '@angular/router';
import { SelectItem } from 'primeng/primeng';
import * as _ from 'lodash';
import { Observable, fromEvent } from 'rxjs';

import { debounceTime } from 'rxjs/operators';
import { Inventory, MaterialType } from 'src/app/components/device-assets/shared/index.model';
import { ClassesType } from 'src/app/common/models/classes-type.model';
import { CommonService } from 'src/app/common/services/common.service';
import { DeviceManageService } from 'src/app/components/device-assets/device-manage/device-manage.service';
import { PageType } from '../equipment-files/equipment-files.component.model';

@Component({
  selector: 'app-device-inventory-query',
  templateUrl: 'device-inventory-query.component.html',
  styleUrls: ['../device-detail.component.less'],
})
export class DeviceInventoryQueryComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public deviceID;
  @Input() public type;
  @Input() public stationId;
  @Input() public showButton = true;

  public inventoryData1: Inventory[] = [];
  public pager1 = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };

  public inventoryData2: Inventory[] = [];
  public pager2 = { pageCurrent: 0, totalRecords: 0, pageSize: 50 };

  public classesType = ClassesType.spareParts;
  public materialType = MaterialType.spareParts;

  public ioTypes: string;
  public recordsData: Inventory;
  public isShowRecords = false;
  public queryParam = {
    classType: ClassesType.spareParts,
    deviceId: '',
    // stationId: ''   ??
    isSameStation: '',
  };

  public scrollHeight: string;
  public resizeEvent: any;

  public dataEditable = false;

  constructor(
    private common: CommonService,
    private deviceManageService: DeviceManageService,
    private route: ActivatedRoute
  ) {
    this.resizeEvent = fromEvent(window, 'resize')
      .pipe(debounceTime(100))
      .subscribe((e: any) => {
        this.setScrollHeight();
      });
  }

  public ngOnInit() {
    this.setScrollHeight();
    // this.queryList1();
    // this.queryList2();
  }

  public ngOnChanges(changes: SimpleChanges) {
    // if (!changes.deviceID && !changes.type && !changes.stationId) { return false; }
    this.route.queryParams.subscribe((params) => {
      if (this.deviceID) {
        this.queryParam.deviceId = this.deviceID;
      }

      if (this.type) {
        this.dataEditable = this.type !== PageType.DETAIL ? true : false;
      }
    });

    this.queryList1();
    this.queryList2();
  }

  public ngOnDestroy() {
    this.resizeEvent.unsubscribe();
  }

  public setScrollHeight() {
    this.scrollHeight = document.body.clientHeight - 215 - 60 + 'px';
  }

  // 获取参数
  public getParam(isSameStation) {
    this.queryParam.isSameStation = isSameStation;
    return this.queryParam;
  }

  /*---------------------------本电站库存---------------------------*/
  // 列表查询
  public queryList1(isQuery?: boolean) {
    if (isQuery) {
      this.pager1.pageCurrent = 0;
    }
    this.common.mask.show();
    this.deviceManageService
      .getClassifyMaterialInventorys(
        this.getParam('01'),
        this.pager1.pageCurrent,
        this.pager1.pageSize
      )
      .subscribe(
        (res: any) => {
          this.pager1.totalRecords = res.totalElements;
          this.inventoryData1 = res.content;
          for (let i = 0, len = res.content.length; i < len; i++) {
            this.inventoryData1[i]['index'] = i + 1;
          }
        },
        (error) => {
          this.common.errorMessage(error);
        },
        () => {
          this.common.mask.hide();
        }
      );
  }

  // 分页
  public onPageChange1(event) {
    this.pager1.pageCurrent = event.page;
    this.pager1.pageSize = parseFloat(event.rows);
    this.queryList1();
  }

  // 出入库
  public submitAddData(event) {
    if (event) {
      // 更新列表
      this.queryList1(true);
      this.queryList2(true);
    }
  }

  // 出库入库操作
  public showStroageRecords(data, type) {
    this.isShowRecords = true;
    this.recordsData = data;
    this.ioTypes = type;
  }

  /*---------------------------非本电站库存---------------------------*/
  // 列表查询
  public queryList2(isQuery?: boolean) {
    if (isQuery) {
      this.pager2.pageCurrent = 0;
    }
    this.common.mask.show();
    this.deviceManageService
      .getClassifyMaterialInventorys(
        this.getParam('00'),
        this.pager2.pageCurrent,
        this.pager2.pageSize
      )
      .subscribe(
        (res: any) => {
          this.pager2.totalRecords = res.totalElements;
          this.inventoryData2 = res.content;
          for (let i = 0, len = res.content.length; i < len; i++) {
            this.inventoryData2[i]['index'] = i + 1;
          }
        },
        (error) => {
          this.common.errorMessage(error);
        },
        () => {
          this.common.mask.hide();
        }
      );
  }

  // 分页
  public onPageChange2(event) {
    this.pager2.pageCurrent = event.page;
    this.pager2.pageSize = parseFloat(event.rows);
    this.queryList2();
  }

  // // 出入库
  // public submitAddData2(event) {
  //   if (event) {
  //     // 更新列表
  //     this.queryList2(true);
  //   }
  // }

  // // 出库入库操作
  // public showStroageRecords2(data, type) {
  //   this.isShowRecords = true;
  //   this.recordsData = data;
  //   this.ioTypes = type;
  // }
}
