import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
// import "rxjs/add/operator/map";
// import "rxjs/add/operator/catch";

import * as _ from 'lodash';
import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { DataImportStatus } from '../../../common/models/codeTable.model';
import { DataImportLog } from './basic-data.model';
import { Page } from '../../../shared/models/pagination';
import { CommonService } from '../../../common/services/common.service';
import { tap, map } from 'rxjs/operators';

@Injectable()
export class BasicDataService {
  public e2BoxAddedSource = new Subject<string>();
  public e2BoxAdded$ = this.e2BoxAddedSource.asObservable();

  public pointsImportSource = new Subject<any>();
  public pointsImport$ = this.pointsImportSource.asObservable();

  public stationSource = new Subject<any>();
  public station$ = this.stationSource.asObservable();

  public stationAreaSource = new Subject<any>();
  public stationArea$ = this.stationAreaSource.asObservable();

  public mapPoint = new Subject<any>();

  private configOrder: string[] = [
    'stationFlag',
    'deviceFlag',
    'pointFlag',
    'formulaFlag',
    'preprocessFlag',
    'alarmFlag',
  ];

  public constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {}

  public getDataImportLogs(
    stationIds: string[],
    stationStatus: string[],
    page: number,
    size: number
  ): Observable<Page<DataImportLog>> {
    return this.http.get<Page<DataImportLog>>(
      `${this.appConfig.apiUrl}/api/basicData/dataImportLogs`,
      {
        search: { stationIds, stationStatus, page, size },
      }
    );
  }

  // 选择测点页面电站
  public getSampleStationAll() {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/basicData/getSampleStationAll`
    );
  }

  getPic(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/station/pic`, {
      params,
    });
  }
  public postPic(file, stationId) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('stationId', stationId);

    return this.http
      .post(`${this.appConfig.apiUrl}/api/station/pic`, formData)
      .toPromise();
  }

  public deleteByLogId(logId: string) {
    let id = parseInt(logId, 10);
    return this.http.delete(
      `${this.appConfig.apiUrl}/api/basicData/dataImportLogs`,
      {
        search: { logId: id },
      }
    );
  }
  public deleteStationById(params) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/station`, {
      params,
    });
  }

  public deleteE2boxDevice(deviceId: string) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/e2boxes`, {
      search: { deviceId },
    });
  }

  public deletePointImportLog(importBatchNo: string) {
    return this.http.delete(
      `${this.appConfig.apiUrl}/api/basicData/pointImportLogs`,
      {
        search: { importBatchNo },
      }
    );
  }

  public getConfitCurrentStep(dataConfig) {
    for (let key of this.configOrder) {
      if (dataConfig[key] !== DataImportStatus.NOT_START) {
        continue;
      }
      return key;
    }
  }

  public getPointImportLogs(stationId: string) {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/basicData/pointImportLogs`,
      {
        search: { stationId },
      }
    );
  }

  public getStationAreas(stationId: string) {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/location/getStationLocation`,
      {
        search: { stationId },
      }
    );
  }

  public addE2box(e2boxConfig) {
    return this.http.post(`${this.appConfig.apiUrl}/api/e2boxes`, e2boxConfig);
  }

  public importPoints(deviceId: string, protocol: string, file: File) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('protocol', protocol);
    formData.append('deviceId', deviceId);
    return this.http
      .post(`${this.appConfig.apiUrl}/api/basicData/importPoints`, formData)
      .pipe(
        tap((log) => {
          this.pointsImportSource.next(log);
        })
      );
  }

  public reImportPoints(file: File, importBatchNo: string) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('batchNo', importBatchNo);
    return this.http.post(
      `${this.appConfig.apiUrl}/api/basicData/reimportPoints`,
      formData
    );
  }

  public download(id: string, type: string) {
    return this.http.get(`${this.appConfig.apiUrl}/api/basicData/export`, {
      search: { id, type },
      responseType: 'blob',
      observe: 'response',
    });
  }

  public getStationById(stationId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/station/${stationId}`)
      .toPromise();
  }

  public addStation(station) {
    return this.http
      .put(`${this.appConfig.apiUrl}/api/station`, station)
      .pipe(
        tap((res) => {
          this.stationSource.next(true);
        })
      )
      .toPromise();
  }

  public deleteStation(stationId) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/station`, {
        search: stationId,
      })
      .pipe(
        tap((res) => {
          this.stationSource.next(true);
        })
      )
      .toPromise();
  }

  public getTimezone() {
    return this.http.get(`${this.appConfig.apiUrl}/api/timezones`).toPromise();
  }

  public getUsers(name) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/appUser/appUsers`, {
        search: { name },
      })
      .pipe(
        map((res: any) => {
          return res.content;
        })
      )
      .toPromise();
  }

  public getCountries() {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/regions/countries`)
      .toPromise();
  }

  public getSubRegions(parentId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/regions/subRegions`, {
        search: { parentId },
      })
      .toPromise();
  }

  public setRegionsData(data) {
    let result = [];
    for (let val of data) {
      result.push({
        label: val.regionName,
        value: val.regionId + '-' + val.regionName,
      });
    }
    return result;
  }

  public getStationAreaList(param) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/stationAreas/all`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }
  public getStationAreaListPage(param) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/stationAreas/page`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }

  // 下载电站区域模板
  public DownloadStationAreaTemp() {
    return this.common.fileExport(
      `${this.appConfig.apiUrl}/api/stationAreas/downloadTemplate`
    );
  }
  public exportArea(params) {
    return this.common.fileExport(
      `${this.appConfig.apiUrl}/api/stationAreas/export`,
      params
    );
  }

  // 导入区域
  public importStationArea(file: File, stationId) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http
      .post(`${this.appConfig.apiUrl}/api/stationAreas/import`, formData, {
        search: { stationId },
      })
      .pipe(
        tap((log) => {
          this.stationAreaSource.next(log);
        })
      );
  }

  public addStationArea(area) {
    return this.http
      .put(`${this.appConfig.apiUrl}/api/stationAreas`, area)
      .pipe(
        tap((res) => {
          this.stationAreaSource.next(true);
        })
      );
  }

  public deleteStationArea(ids) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/stationAreas`, {
        search: { areaIds: ids },
      })
      .pipe(
        tap((res) => {
          this.stationAreaSource.next(true);
        })
      );
  }

  public getPointsCurdata(
    importBatchNo: string,
    getCurrValue: boolean,
    getFrontendValue: boolean
  ) {
    return this.http.get(`${this.appConfig.apiUrl}/api/points`, {
      search: { importBatchNo, getCurrValue, getFrontendValue },
    });
  }

  public addPoint(
    e2boxDeviceId,
    deviceIds: any[],
    tempId: any,
    isExtAddrWithDev: boolean
  ) {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/pointTest/createPointsByTemplate`,
      {
        search: { e2boxDeviceId, deviceIds, tempId, isExtAddrWithDev },
      }
    );
  }

  public exportPoint(batchNos) {
    return this.common.fileExport(
      `${this.appConfig.apiUrl}/api/pointTest/export`,
      { batchNos }
    );
  }

  public fileExists(deviceId, filename) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/pointTest/fileExists`, {
        search: { deviceId, filename },
      })
      .toPromise();
  }

  public exportStations(param) {
    this.common.fileExport(
      `${this.appConfig.apiUrl}/api/station/export`,
      param
    );
  }
}
