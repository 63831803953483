import { CompanyTreeSelectorComponent } from "./company-tree-selector.component";
import { CompanyTreeSelectorService } from "./company-tree-selector.service";
import { NgModule } from "@angular/core";
import { SharedModule } from "../../../shared/shared.module";
import { DialogModule, ButtonModule } from "primeng/primeng";
import { TreeviewModule } from "../treeview/index";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [CompanyTreeSelectorComponent],
    imports: [
        CommonModule,
        FormsModule,
        TranslateModule,
        DialogModule,
        ButtonModule,
        TreeviewModule.forRoot()
    ],
    exports: [CompanyTreeSelectorComponent],
    providers: [CompanyTreeSelectorService]
})
export class CompanyTreeSelectorModule {}
