import { AuthHttp } from './../../../core/auth-http';
import { Component, Input, OnInit, AfterViewInit, SimpleChanges, OnChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { MenuItem } from 'primeng/api';
import { AppConfigService } from 'src/app/app.config';
import * as _ from 'lodash';
import { FullLayoutComponent } from 'src/app/layouts/full-layout/full-layout.component';

@Component({
  selector: 'app-menu',
  template: `
    <ul
      app-submenu
      [item]="app.grouped ? modelGrouped : modelUngrouped"
      root="true"
      class="layout-menu"
      visible="true"
      [reset]="reset"
      parentActive="true"
    ></ul>
  `,
})
export class AppMenuComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() reset: boolean;
  @Input() menus: any[] = [];
  modelGrouped: any[];
  modelUngrouped: any[];

  constructor(
    public app: FullLayoutComponent,
    public http: AuthHttp,
    private appConfig: AppConfigService
  ) {}

  ngOnInit() {
    this.initMenu(this.menus);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.menus && changes.menus.firstChange == false) {
      this.menus = changes.menus.currentValue;
      this.initMenu(this.menus);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.app.layoutMenuScrollerViewChild.moveBar();
    }, 100);
  }

  initMenu(menus) {
    this.modelGrouped = menus;
    this.modelUngrouped = [
      {
        label: '',
        icon: 'pi pi-fw pi-home',
        items: this.modelGrouped,
      },
    ];
  }

  treeData(source: any, id: string, parentId: string, children: string) {
    let cloneData = JSON.parse(JSON.stringify(source));
    return cloneData.filter((father) => {
      let branchArr = cloneData.filter((child: any) => father[id] == child[parentId]);
      branchArr.length > 0 ? (father[children] = branchArr) : '';
      return father[parentId] == null;
    });
  }

  changeTheme(theme: string, scheme: string) {
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href = 'assets/layout/css/layout-' + theme + '.css';

    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href = 'assets/theme/' + theme + '/theme-' + scheme + '.css';

    const topbarLogo: HTMLImageElement = document.getElementById(
      'layout-topbar-logo'
    ) as HTMLImageElement;

    const menuLogo: HTMLImageElement = document.getElementById(
      'layout-menu-logo'
    ) as HTMLImageElement;

    // if (theme === "yellow" || theme === "lime") {
    //     topbarLogo.src = "assets/layout/images/logo-black.png";
    //     menuLogo.src = "assets/layout/images/logo-black.png";
    // } else {
    //     topbarLogo.src = "assets/layout/images/logo-white.png";
    //     menuLogo.src = "assets/layout/images/logo-white.png";
    // }

    if (scheme === 'dark') {
      this.app.darkMenu = true;
    } else if (scheme === 'light') {
      this.app.darkMenu = false;
    }
  }
}

@Component({
  /* tslint:disable:component-selector */
  selector: '[app-submenu]',
  /* tslint:enable:component-selector */
  template: `
    <ng-template ngFor let-child let-i="index" [ngForOf]="root ? item : item.items">
      <li
        [ngClass]="{
          'layout-root-menuitem': root,
          'active-menuitem': isActive(i)
        }"
        [class]="child.badgeStyleClass"
        *ngIf="child.visible === false ? false : true"
      >
        <div *ngIf="root">
          <span class="layout-menuitem-text">{{ child.label }}</span>
        </div>
        <a
          [href]="child.url || '#'"
          (click)="itemClick($event, child, i)"
          (mouseenter)="onMouseEnter(i)"
          *ngIf="!child.routerLink"
          [class]="child.styleClass"
          [attr.tabindex]="!visible ? '-1' : null"
          target="{{ child.target }}"
        >
          <i class="layout-menuitem-icon iconfont" [ngClass]="child.icon"></i>
          <span class="layout-menuitem-text">{{ child.label }}</span>
          <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="child.items"></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
        </a>
        <!-- 自身tab页 -->
        <a
          (mouseenter)="onMouseEnter(i)"
          *ngIf="child.routerLink && child.target === '_self'"
          [routerLink]="child.routerLink"
          [queryParams]="child.menuParam"
          routerLinkActive="active-route"
          [routerLinkActiveOptions]="child.routerLinkActiveOptions"
          [fragment]="child.fragment"
          [attr.tabindex]="!visible ? '-1' : null"
          target="{{ child.target }}"
        >
          <i class="layout-menuitem-icon iconfont" [ngClass]="child.icon"></i>
          <span class="layout-menuitem-text">{{ child.label }}</span>
          <i
            class="pi pi-fw pi-angle-down layout-submenu-toggler"
            *ngIf="child.items"
            (click)="itemClick($event, child, i)"
          ></i>
          <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
        </a>
        <!-- new_tab -->
        <a
          *ngIf="child.routerLink && child.target === '_blank'"
          [href]="setHref(child)"
          target="{{ child.target }}"
        >
          <i class="layout-menuitem-icon iconfont" [ngClass]="child.icon"></i>
          <span class="layout-menuitem-text">{{ child.label }}</span>
          <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
        </a>
        <div class="layout-menu-tooltip">
          <div class="layout-menu-tooltip-arrow"></div>
          <div class="layout-menu-tooltip-text">
            {{ child.label }}
          </div>
        </div>
        <ul
          app-submenu
          [item]="child"
          *ngIf="child.items"
          [visible]="isActive(i)"
          [style.maxHeight.px]="slimItemMaxHeight(i)"
          [reset]="reset"
          [parentActive]="isActive(i)"
          [@children]="
            (app.isSlim() || app.isHorizontal()) && !app.isMobile() && root
              ? isActive(i)
                ? 'visible'
                : 'hidden'
              : isActive(i)
              ? 'visibleAnimated'
              : app.grouped === true && root
              ? 'visibleAnimated'
              : 'hiddenAnimated'
          "
        ></ul>
      </li>
    </ng-template>
  `,
  styles: [
    `
      .layout-root-menuitem > ul {
        height: auto !important;
      }
    `,
  ],
  animations: [
    trigger('children', [
      state(
        'hiddenAnimated',
        style({
          height: '0px',
          'max-height': '*',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
          'max-height': '*',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
          'z-index': 100,
        })
      ),
      state(
        'hidden',
        style({
          height: '0px',
          'z-index': '*',
        })
      ),
      transition(
        'visibleAnimated => hiddenAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
      transition(
        'hiddenAnimated => visibleAnimated',
        animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')
      ),
    ]),
  ],
})
export class AppSubMenuComponent {
  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  _parentActive: boolean;

  _reset: boolean;

  activeIndex: number;

  constructor(public app: FullLayoutComponent, public appMenu: AppMenuComponent) {}

  itemClick(event: Event, item: MenuItem, index: number) {
    event.stopPropagation();
    if (this.root) {
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
    // avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    // activate current item and deactivate active sibling if any
    this.activeIndex = this.activeIndex === index ? null : index;

    // execute command
    if (item.command) {
      item.command({ originalEvent: event, item });
    }

    // prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      setTimeout(() => {
        this.app.layoutMenuScrollerViewChild.moveBar();
      }, 450);
      event.preventDefault();
    }

    // hide menu
    if (!item.items) {
      if (this.app.isHorizontal() || this.app.isSlim()) {
        this.app.resetMenu = true;
      } else {
        this.app.resetMenu = false;
      }

      this.app.overlayMenuActive = false;
      this.app.staticMenuMobileActive = false;
      this.app.menuHoverActive = !this.app.menuHoverActive;
    }
  }

  setHref(child) {
    let params = '';
    for (const key in child.menuParam) {
      if (child.menuParam.hasOwnProperty(key)) {
        const element = child.menuParam[key];
        if (params) {
          params += '&';
        }
        params += key + '=' + element;
      }
    }
    return child.routerLink[0] + (params ? '?' + params : '');
  }

  onMouseEnter(index: number) {
    if (
      this.root &&
      this.app.menuHoverActive &&
      (this.app.isHorizontal() || this.app.isSlim()) &&
      !this.app.isMobile() &&
      !this.app.isTablet()
    ) {
      this.activeIndex = index;
    }
  }

  isActive(index: number): boolean {
    return this.activeIndex === index;
  }

  slimItemMaxHeight(index) {
    if (this.app.isSlim()) {
      let height = document.body.clientHeight - 65;
      return height - index * 65 - 10;
    } else if (this.app.isHorizontal()) {
      return 450;
    }
  }

  @Input() get reset(): boolean {
    return this._reset;
  }

  set reset(val: boolean) {
    this._reset = val;

    if (this._reset && (this.app.isHorizontal() || this.app.isSlim())) {
      this.activeIndex = null;
    }
  }

  @Input() get parentActive(): boolean {
    return this._parentActive;
  }

  set parentActive(val: boolean) {
    this._parentActive = val;

    if (!this._parentActive) {
      this.activeIndex = null;
    }
  }
}
