import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { AppConfigService } from '../../../app.config';
import { AuthHttp } from '../../../core/auth-http';
import { Page, PageRequest, SelectableData } from '../../../shared/models';

import { RoleInfo } from '../../permissions/shared/permissions.model';
import { UserInfo, UserQueryCondition, RoleOfUser } from './users.model';
import { CommonService } from '../../../common/services/common.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class UsersService {
  private dataChangedEvent = new Subject<UserInfo>();

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private common: CommonService
  ) {}

  public fireAddOrUpdateEvent(data: UserInfo): void {
    this.dataChangedEvent.next(data);
  }

  public onAddOrUpdateEvent(next?: (value: UserInfo) => void): void {
    this.dataChangedEvent.subscribe({
      next,
    });
  }

  public getUsers(params: UserQueryCondition): Observable<Page<any>> {
    return this.http
      .get<Page<UserInfo>>(`${this.appConfig.apiUrl}/api/nems/users`, {
        search: this.common.filterEffectParam(params),
      })
      .pipe(
        tap((res) => {
          const data = res;
          return {
            content: data.content as UserInfo[],
            totalPages: data.totalPages,
            totalElements: data.totalElements,
            size: data.size,
            number: data.number,
          };
        })
      );
  }

  public makeRandomPassword(): Observable<string> {
    return this.http.get(
      `${this.appConfig.apiUrl}/api/nems/users/password/random`
    );
  }

  public addUser(user: UserInfo): Observable<UserInfo> {
    return this.http.post(`${this.appConfig.apiUrl}/api/nems/users`, user);
  }

  public batchAddUsers(users: UserInfo[]): Observable<UserInfo[]> {
    return this.http.post(`${this.appConfig.apiUrl}/api/users/batch`, users);
  }

  public getUser(userId: string): Observable<UserInfo> {
    return this.http.get<UserInfo>(
      `${this.appConfig.apiUrl}/api/nems/users/${userId}`
    );
  }

  public updateUser(user: UserInfo): Observable<UserInfo> {
    return this.http.put<UserInfo>(
      `${this.appConfig.apiUrl}/api/nems/users`,
      user
    );
  }

  public getRoles(userId: string): Observable<RoleInfo[]> {
    return this.http.get<RoleInfo[]>(`${this.appConfig.apiUrl}/api/roles/`);
  }

  public deleteUser(userId) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/nems/users`, {
      params: {
        userId,
      },
    });
  }

  public lockUser(userId: string) {
    return this.http.post(
      `${this.appConfig.apiUrl}/api/nems/users/lock`,
      null,
      {
        params: { userId },
      }
    );
  }

  public unlockUser(userId: string) {
    return this.http.post(
      `${this.appConfig.apiUrl}/api/nems/users/unlock`,
      null,
      {
        params: { userId },
      }
    );
  }

  public recoverUser(userId: string) {
    return this.http.post(
      `${this.appConfig.apiUrl}/api/nems/users/recover`,
      {},
      {
        params: { userId },
      }
    );
  }

  public resetPassword(params) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/nems/users/password/reset`,
      params
    );
  }

  public getRolesOfUser(
    userId: string
  ): Observable<Array<SelectableData<RoleOfUser>>> {
    return this.http
      .get<Array<SelectableData<RoleOfUser>>>(
        `${this.appConfig.apiUrl}/api/users/${userId}/roles`
      )
      .pipe(
        tap((res) => {
          let data = res;
          return <Array<SelectableData<RoleOfUser>>>data;
        })
      );
  }

  public updateUsersOfRole(
    userId: string,
    roleIds: Array<SelectableData<RoleOfUser>>
  ): Observable<RoleInfo> {
    return this.http.post(
      `${this.appConfig.apiUrl}/api/nems/users/roles`,
      null,
      {
        params: this.common.filterEffectParam({
          userId,
          roleIds: roleIds.join(),
        }),
      }
    );
  }

  // 获取用户日志
  public getApplogs(param) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/applogs`, {
        search: this.common.filterEffectParam(param),
      })
      .toPromise();
  }

  /**
   * exportUser
   */
  public exportUser(params) {
    this.common.fileExport(
      `${this.appConfig.apiUrl}/api/nems/users/export`,
      params
    );
  }
}
