import {
  AfterViewInit,
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import * as _ from 'lodash';

declare var $: any;
declare var jQuery: any;

export interface TimePickerEvent {
  time: {
    value: number; // getTime()
    meridian: string; // AM || PM
    hours: number;
    minutes: number;
    seconds: number;
  };
}

@Component({
  selector: 'datetime-picker',
  templateUrl: 'datetime-picker.component.html',
  styles: ['.datetime-picker *[hidden] { display: none; }'],
})
export class DatetimePickerComponent
  implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges
{
  @Output() public dateChange: EventEmitter<Date> = new EventEmitter<Date>();
  @Input('timepicker') public timepickerOptions: any = {};
  @Input('datepicker') public datepickerOptions: any = {};
  @Input('hasClearButton') public hasClearButton = false;
  @Input() public readonly: boolean = null;
  @Input() public required: boolean = null;
  @Input() public tabindex: string;

  public date: Date; // ngModel
  public dateModel: string;
  public timeModel: string;

  // instances
  public datepicker: any;
  public timepicker: any;

  public idDatePicker: string = uniqueId('q-datepicker_');
  public idTimePicker: string = uniqueId('q-timepicker_');

  constructor(private ngControl: NgControl) {
    ngControl.valueAccessor = this; // override valueAccessor
  }

  @HostListener('dateChange', ['$event'])
  public onChange = (_: any) => {};
  @HostListener('blur')
  public onTouched = () => {};

  @HostBinding('attr.tabindex')
  get tabindexAttr(): string {
    return this.tabindex === undefined ? '-1' : undefined;
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.init();
    }, 100);
  }

  public ngOnDestroy() {
    if (this.datepicker) {
      this.datepicker.datepicker('destroy');
    }
    if (this.timepicker) {
      this.timepicker.timepicker('remove');
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes) {
      if (
        changes.datepickerOptions &&
        this.datepicker &&
        !_.isEqual(
          changes.datepickerOptions.currentValue,
          changes.datepickerOptions.previousValue
        )
      ) {
        this.datepicker.datepicker('destroy');

        if (changes.datepickerOptions.currentValue) {
          this.datepicker = null;
          this.init();
        } else if (changes.datepickerOptions.currentValue === false) {
          this.datepicker.remove();
        }
      }
      if (changes.timepickerOptions && this.timepicker) {
        this.timepicker.timepicker('remove');

        if (changes.timepickerOptions.currentValue) {
          this.timepicker = null;
          this.init();
        } else if (changes.timepickerOptions.currentValue === false) {
          this.timepicker.parent().remove();
        }
      }
    }
  }

  public writeValue(value: any): void {
    this.date = value;
    if (isDate(this.date) || this.date) {
      setTimeout(() => {
        this.updateModel(this.date);
      }, 0);
    } else {
      this.clearModels();
    }
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public checkEmptyValue(e: any) {
    const value = e.target.value;
    if (
      value === '' &&
      (this.timepickerOptions === false ||
        this.datepickerOptions === false ||
        (this.timeModel === '' && this.dateModel === ''))
    ) {
      this.dateChange.emit(null);
    }
  }

  public clearModels() {
    this.dateChange.emit(null);
    if (this.timepicker) {
      this.timepicker.timepicker('setTime', null);
    }
    this.updateDatepicker(null);
  }

  public showTimepicker() {
    this.timepicker.timepicker('showWidget');
  }

  public showDatepicker() {
    this.datepicker.datepicker('show');
  }

  //////////////////////////////////

  private init(): void {
    if (!this.datepicker && this.datepickerOptions !== false) {
      const options = jQuery.extend(
        { enableOnReadonly: !this.readonly },
        this.datepickerOptions
      );
      this.datepicker = ($('#' + this.idDatePicker) as any).datepicker(options);
      this.datepicker.on('changeDate', (e: any) => {
        const newDate: Date = e.date;

        if (isDate(this.date) && isDate(newDate)) {
          // get hours/minutes
          newDate.setHours(this.date.getHours());
          newDate.setMinutes(this.date.getMinutes());
          newDate.setSeconds(this.date.getSeconds());
        }

        this.date = newDate;
        this.dateChange.emit(newDate);
      });
    } else if (this.datepickerOptions === false) {
      ($('#' + this.idDatePicker) as any).remove();
    }

    if (!this.timepicker && this.timepickerOptions !== false) {
      const options = jQuery.extend(
        { defaultTime: false },
        this.timepickerOptions
      );
      this.timepicker = ($('#' + this.idTimePicker) as any).timepicker(options);
      this.timepicker.on('changeTime.timepicker', (e: TimePickerEvent) => {
        let { meridian, hours } = e.time;

        if (meridian) {
          // has meridian -> convert 12 to 24h
          if (meridian === 'PM' && hours < 12) {
            hours = hours + 12;
          }
          if (meridian === 'AM' && hours === 12) {
            hours = hours - 12;
          }
          hours = parseInt(this.pad(hours));
        }

        if (!isDate(this.date)) {
          this.date = new Date();
          this.updateDatepicker(this.date);
        }

        this.date.setHours(hours);
        this.date.setMinutes(e.time.minutes);
        this.date.setSeconds(e.time.seconds);
        this.dateChange.emit(this.date);
      });
    } else if (this.timepickerOptions === false) {
      ($('#' + this.idTimePicker) as any).parent().remove();
    }

    this.updateModel(this.date);
  }

  public triggerClick() {
    ($('#' + this.idDatePicker) as any).datepicker('show');
  }

  private updateModel(date: Date): void {
    this.updateDatepicker(date);

    // update timepicker
    if (this.timepicker !== undefined && isDate(date)) {
      let hours = date.getHours();
      if (this.timepickerOptions.showMeridian) {
        // Convert 24 to 12 hour system
        hours = hours === 0 || hours === 12 ? 12 : hours % 12;
      }
      const meridian = date.getHours() >= 12 ? ' PM' : ' AM';
      const time =
        this.pad(hours) +
        ':' +
        this.pad(this.date.getMinutes()) +
        ':' +
        this.pad(this.date.getSeconds()) +
        (this.timepickerOptions.showMeridian ||
        this.timepickerOptions.showMeridian === undefined
          ? meridian
          : '');
      this.timepicker.timepicker('setTime', time);
      this.timeModel = time; // fix initial empty timeModel bug
    }
  }

  private updateDatepicker(date?: any) {
    if (this.datepicker !== undefined) {
      this.datepicker.datepicker('update', date);
    }
  }

  private pad(value: any): string {
    return value.toString().length < 2 ? '0' + value : value.toString();
  }
}

let id = 0;
function uniqueId(prefix: string): string {
  return prefix + ++id;
}

function isDate(obj: any) {
  return Object.prototype.toString.call(obj) === '[object Date]';
}
