import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { TipService } from '@common/services/tip.service';
import * as _ from 'lodash';
import * as moment from 'moment';
import { ConfirmationService, SelectItem } from 'primeng/primeng';
import { CodeTable } from '../../../../../common/models/codeTable.model';
import { CommonService } from '../../../../../common/services/common.service';
import { CodeTableService } from '../../../../../core/code-table.service';
import { ToastService } from '../../../../../core/toast.service';
import { UserService } from '../../../../../core/user.service';
import { DeviceAssetsService } from '../../../shared/device-assets.service';
import { IoType, Material, MaterialFormVal } from '../../../shared/index.model';
import { SparePartsManageService } from '../../spare-parts-manage.service';

@Component({
  selector: 'app-in-and-out-popups',
  templateUrl: './in-and-out-popups.component.html',
  styleUrls: ['./in-and-out-popups.component.less'],
})
export class InAndOutPopupsComponent implements OnInit, OnChanges {
  // 弹窗显示
  @Input() public isShow = false;
  // 出入库类型（入库，出库）
  @Input() public ioTypes: string; // 库存 - 出入库类型
  // 数据拷贝
  @Input() public isCopy = false;
  // 出入库数据
  @Input() public recordsData: any = {} as any;
  // 出入库参数
  @Input() public recordsParam: any;
  // 物料类型
  @Input() public materialType: string;
  // 物料分类
  @Input() public classType: string;
  // 弹窗关闭事件
  @Output() public showEvent = new EventEmitter<boolean>();
  // 数据提交事件
  @Output() public isSubmitData = new EventEmitter<boolean>();

  // 日历本地化
  public chinaCalendar = this.common.getChinaCalendar();
  // 弹窗标题
  public recordsTitle: string;
  // 显示物料查询条件
  public isShowMaterialQuery = false;
  // 物料序号
  public materialIndex = 0;
  // 数量显示配置
  public ioNumOption: any[] = [];

  // 库存类别是否可编辑
  public isEditInventoryCate = false;
  // 规格型号
  public isEditMaterialSn = false;
  // 仓库ID
  public warehouseVal: string; // 初始化仓库
  // 操作类型初始化树下拉框
  public ioTypeVal = { parent: null, child: null };
  // 出入库表单
  public editForm: FormGroup;
  // 物料表单
  public materialForm: FormGroup;
  // 关联业务类型选项
  public businessType: SelectItem[] = [];
  // 操作类型选项
  public ioSubTypes: SelectItem[] = [];
  // 库存类别选项
  public inventoryCates: SelectItem[] = [];
  // 出/入库人
  public inOutPersonList = []; // 出入库人
  // 关联业务单号
  public firmList = []; // 关联业务单号
  // 物料查询出入库类型参数
  public inOrOut: string;
  // 物料查询物料号参数
  public materialNoSearch: string;
  // 出入库人 弹窗
  isShowUserDialog: boolean;
  // 出入库人
  person = [];
  showRelaWarehouse = false;
  relaWarehouseId;
  ioFincanceSubtypeOptions: any = {
    '01': [
      {
        label: '',
        value: null,
      },
    ],
    '02': [
      {
        label: '',
        value: null,
      },
    ],
  };
  defaultIoFincanceSubtypeOptions: any;
  previousValue: any;
  constructor(
    private common: CommonService,
    private fb: FormBuilder,
    private assetsService: DeviceAssetsService,
    private codeTableService: CodeTableService,
    private sparePartsManageService: SparePartsManageService,
    private toastService: ToastService,
    private userService: UserService,
    private confirmationService: ConfirmationService,
    private tipservice: TipService
  ) { }

  public ngOnInit() {
    if (!this.editForm) {
      this.formInit();
      this.initMaterialForm();
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (!this.isShow) {
      return false;
    }
    this.ioNumOption = [];
    this.showRelaWarehouse = false;
    if (!this.editForm) {
      this.formInit();
      this.initMaterialForm();
    }
    this.removeMaterial(0);
    this.initRecordsTitle();
    this.initPager();

    if (changes.recordsParam) {
      if (!_.isEmpty(changes.recordsParam.currentValue)) {
        this.editForm.patchValue({
          relaBusinessType: this.recordsParam.relaBusinessType,
          relaBusinessId: {
            orderCode: this.recordsParam.orderCode,
            orderId: this.recordsParam.orderId,
          },
          ioOperator: {
            realName: this.recordsParam.ioOperator.realName,
            userId: this.recordsParam.ioOperator.userId,
          },
        });
      }
    }
  }

  public initRecordsTitle() {
    if (this.ioTypes || _.isEmpty(this.recordsData) || this.isCopy) {
      this.recordsTitle = `新建出入库记录 | 创建人: ${localStorage.getItem(
        'userName'
      )}`;
    } else {
      this.recordsTitle =
        `创建人: ${this.recordsData.createrName}  ` +
        ` | 创建时间: ${moment(this.recordsData.createTime).format(
          'YYYY-MM-DD HH:mm:ss'
        )}`;
    }
  }

  public initPager() {
    // 备品备案 出库/入库
    if (this.ioTypes) {
      this.ioTypeInit();
      this.setAddRecordsData();
      if (this.recordsData && this.recordsData.inventoryId) {
        this.setInventoryMaterials(this.recordsData.inventoryId);
      }
    } else {
      if (_.isEmpty(this.recordsData)) {
        this.setAddData();
      } else {
        this.setEditData();
        this.setMaterials(
          this.recordsData.ioType,
          this.recordsData.materialIostorgeDetails
        );
      }
    }
  }

  public setAddRecordsData() {
    if (this.recordsData && this.recordsData.warehouseId) {
      this.warehouseVal = this.recordsData.warehouseId;
    }
    this.editForm.reset();

    this.editForm.patchValue({
      relaBusinessId: {
        orderId: null,
        orderCode: null,
      },
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
      ioTime: new Date(),
    });
  }

  public setAddData() {
    this.warehouseVal = '';
    this.ioTypeVal.parent = '';
    this.ioTypeVal.child = '';
    this.editForm.reset();

    this.editForm.patchValue({
      relaBusinessId: { orderId: '', orderCode: '' },
      ioOperator: {
        realName: localStorage.getItem('userName'),
        userId: localStorage.getItem('userId'),
      },
      ioTime: moment().toDate(),
    });
  }

  public setEditData() {
    this.warehouseVal = this.recordsData.warehouseId;
    this.ioTypeVal = {
      parent: this.recordsData.ioType,
      child: this.recordsData.ioSubType,
    };
    this.recordsData.ioTime = this.recordsData.ioTime
      ? new Date(this.recordsData.ioTime)
      : null;
    this.recordsData.ioOperator = {
      realName: this.recordsData.ioOperatorName,
      userId: this.recordsData.ioOperator,
    };
    this.recordsData.relaBusinessId = {
      orderId: this.recordsData.relaBusinessId,
      orderCode: this.recordsData.relaBusinessIdName,
    };
    this.editForm.patchValue(this.recordsData);
  }

  // 备品备案出入库 物料清单
  public setInventoryMaterials(inventoryId) {
    this.sparePartsManageService.getInventoryMaterials(inventoryId).subscribe(
      (res: any) => {
        this.setMaterials(this.ioTypes, res);
      },
      (error) => {
        this.common.errorMessage(error);
      }
    );
  }

  // 加载物料清单
  public setMaterials(type, materialData) {
    // let materialFormData = [];
    materialData = _.isNull(materialData) ? [] : materialData;

    const materialControls = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    while (materialControls.length) {
      materialControls.removeAt(materialControls.length - 1);
    }
    for (let i = 0, len = materialData.length; i < len; i++) {
      // 是否序列化 物料
      if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
        if (materialData[i].isSn === '1') {
          // materialFormData[i].inventoryNum = 1;
          this.ioNumOption[i] = {
            disabled: true,
            max: 1,
          };
        } else {
          this.ioNumOption[i] = {
            disabled: false,
            max: materialData[i].inventoryNum || 10000000000,
          };
        }
      } else {
        this.ioNumOption[i] = _.assign(this.ioNumOption[i], {
          disabled: false,
          max: 10000000000,
        });
      }
      if (
        !materialData[i].inventoryNum &&
        (materialData[i].oldNum || materialData[i].oldNum === 0)
      ) {
        materialData[i].inventoryNum = materialData[i].oldNum;
      }
      materialControls.push(this.fb.group(new MaterialFormVal()));
      materialControls.controls[i].patchValue({
        ioNum: materialData[i].ioNum,
        ioFincanceSubtype: materialData[i].ioFincanceSubtype,
      });
    }
    this.materialForm.patchValue({ materialFormArray: materialData });
  }

  // 初始化form表单
  public formInit() {
    // 操作类型 仓库
    this.editForm = this.fb.group({
      ioSubType: new FormControl(null, Validators.required),
      ioOperator: this.fb.group({
        userId: new FormControl(null, Validators.required),
        realName: new FormControl(null, Validators.required),
      }),
      relaBusinessType: new FormControl(null),
      relaBusinessId: new FormControl(null),
      warehouseManagerName: new FormControl(null),
      ioTime: new FormControl(null, Validators.required),
      ioDesc: new FormControl(null),
    });

    // 关联业务类型
    Promise.all([
      this.codeTableService.getNemsSelectItems(CodeTable.orderType),
      this.codeTableService.getNemsSelectItems(CodeTable.inventoryCate),
      this.sparePartsManageService.getIoFincanceSubtype('ioFincanceSubtype'),
    ])
      .then((data) => {
        this.businessType = data[0];
        this.inventoryCates = data[1];
        const ioFincanceSubtype = data[2];
        this.ioFincanceSubtypeOptions = {
          '01': [
            {
              label: '',
              value: null,
            },
          ],
          '02': [
            {
              label: '',
              value: null,
            },
          ],
        };
        (ioFincanceSubtype as any).map((item) => {
          this.ioFincanceSubtypeOptions[item.parentPropValue].push({
            label: item.propName,
            value: item.propValue,
          });
        });
        if (this.ioTypes) {
          this.defaultIoFincanceSubtypeOptions =
            this.ioFincanceSubtypeOptions[this.ioTypes];
        } else {
          this.defaultIoFincanceSubtypeOptions = null;
        }
      })
      .catch((error) => {
        this.common.errorMessage(error);
      });
  }

  // 初始化 操作类型
  public ioTypeInit() {
    this.ioSubTypes = [];
    this.sparePartsManageService
      .getoperatingCategory(this.classType)
      .then((res: any) => {
        for (const val of res) {
          if (val.propValue === this.ioTypes) {
            this.ioSubTypes = val.children.map((item: any) => {
              return {
                label: item.propName,
                value: item.propValue,
                otherParams: item.param3,
              };
            });
          }
        }
      })
      .catch((error) => {
        this.common.errorMessage(error);
      });
    this.isShowMaterialQuery = false;
    if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
      this.isEditInventoryCate = true;
      this.isEditMaterialSn = true;
    } else {
      this.isEditInventoryCate = false;
      this.isEditMaterialSn = false;
    }
  }

  // 关联业务单号查询
  public searchFirmIdList(e) {
    if (!this.editForm.value.relaBusinessType) {
      this.toastService.warning('请先选择关联业务类型');
    } else {
      const code = e.query;
      const type = _.concat([], this.editForm.value.relaBusinessType);
      this.sparePartsManageService
        .getFirmIdList(type, code)
        .subscribe((res: any) => {
          this.firmList = res;
        });
    }
  }

  getPreviousValue(event, i) {
    const materialControls = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    const materialData = materialControls.value;
    this.previousValue = materialData[i].inventoryCate;
  }

  inventoryCatesChange(event, i) {
    if (event.value === '05') {
      this.confirmationService.confirm({
        message: '云平台库存”出入库记录不同步NC系统，确认选择？',
        accept: () => {
          this.getmaterialInventoryDtosByInventoryCateId(event, i);
        },
        reject: () => {
          const materialControls = this.materialForm.get(
            'materialFormArray'
          ) as FormArray;
          const materialData = materialControls.value;
          const value = {
            ...materialData[i],
            inventoryCate: this.previousValue,
          };
          materialControls.controls[i].patchValue(value);
          this.getmaterialInventoryDtosByInventoryCateId(
            { value: this.previousValue },
            i
          );
        },
        key: 'inAndOut',
        header: '提示',
        acceptLabel: '确定',
        rejectLabel: '取消',
      });
      return;
    } else {
      this.getmaterialInventoryDtosByInventoryCateId(event, i);
    }
  }

  getmaterialInventoryDtosByInventoryCateId(data, i) {
    const materialControls = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    const materialData = materialControls.value;
    const params = {
      warehouseId: materialData[i].warehouseId,
      materialId: materialData[i].materialId,
      inventoryCateId: data.value,
    };
    let value = {
      ...materialData[i],
    };
    this.sparePartsManageService
      .materialInventoryDtosByInventoryCateId(params)
      .subscribe((res) => {
        const data = res[0] || {};
        value = {
          ...value,
          inventoryCate: data.inventoryCate || value.inventoryCate,
          inventoryCateText: data.inventoryCateText || value.inventoryCateText,
          inventoryNum: data.inventoryNum || 0,
          inventoryId: data.inventoryId || null,
        };
        if (
          (this.ioTypes === IoType.out ||
            this.ioTypeVal.parent === IoType.out) &&
          !data.inventoryNum
        ) {
          this.tipservice.tip('warn', '没有可出库数量');
        }
        materialControls.controls[i].patchValue(value);
      });
  }

  changeIoSubTypes(event) {
    const selectedItem = this.ioSubTypes.filter((item) => {
      return item.value === event.value;
    });
    if ((selectedItem[0] as any).otherParams !== null) {
      this.showRelaWarehouse = true;
    } else {
      this.showRelaWarehouse = false;
    }
  }

  // 出入库人查询
  public searchInOutPersonList(e) {
    this.assetsService.getUsers(e.query).then((res) => {
      this.inOutPersonList = res;
    });
  }

  // 仓库
  public getWarehouseVal(val) {
    if (val === 'true') {
      return;
    }
    if (
      this.warehouseVal &&
      this.warehouseVal !== val &&
      this.materialForm.value.materialFormArray.length > 0
    ) {
      this.confirmationService.confirm({
        key: 'inAndOut',
        header: '提示',
        message: `变更仓库后需要重新填写物料清单，确认变更么？`,
        acceptLabel: '确定',
        rejectLabel: '取消',
        accept: () => {
          this.warehouseVal = val;
          this.isShowMaterialQuery = false;
          const materialControls = this.materialForm.get(
            'materialFormArray'
          ) as FormArray;
          while (materialControls.length) {
            materialControls.removeAt(materialControls.length - 1);
          }
        },
        reject: () => {
          const warehouseVal = this.warehouseVal;
          this.warehouseVal = val;
          setTimeout(() => {
            this.warehouseVal = warehouseVal;
          }, 500);
          this.isShowMaterialQuery = false;
        },
      });
    } else {
      this.warehouseVal = val;
      this.isShowMaterialQuery = false;
    }
  }

  getRelaWarehouseVal(val) {
    if (val === 'true') {
      return;
    }
    this.relaWarehouseId = val;
  }

  getWarehouseManagerName(event) {
    if (!_.isEmpty(event)) {
      this.editForm.patchValue({
        warehouseManagerName: event[0].warehouseManagerName,
      });
    }
  }

  // 操作类别
  public getOptionType(val) {
    if (val && val.params && val.params.param3) {
      this.showRelaWarehouse = true;
    } else {
      this.showRelaWarehouse = false;
    }
    this.ioTypeVal.parent = val.parent[0];
    this.ioTypeVal.child = val.child[0];
    this.isShowMaterialQuery = false;
    if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
      this.isEditInventoryCate = true;
      this.isEditMaterialSn = true;
    } else {
      this.isEditInventoryCate = false;
      this.isEditMaterialSn = false;
    }
    const materialControls = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    const materialData = materialControls.value;
    for (let i = 0, len = materialData.length; i < len; i++) {
      // 是否序列化 物料
      if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
        if (materialData[i].isSn === '1') {
          this.ioNumOption[i] = {
            disabled: true,
            max: 1,
          };
        } else {
          this.ioNumOption[i] = {
            disabled: false,
            max: materialData[i].inventoryNum || 10000000000,
          };
        }
        materialControls.controls[i].patchValue({
          ioNum: Math.min(
            materialData[i].ioNum,
            materialData[i].inventoryNum || 10000000000
          ),
        });
      } else {
        this.ioNumOption[i] = _.assign(this.ioNumOption[i], {
          disabled: false,
          max: 10000000000,
        });
      }
      if (!_.isEqual(this.ioTypeVal.child, this.editForm.value.ioSubType)) {
        materialControls.controls[i].patchValue({
          ioFincanceSubtype: null,
        });
      }
    }
  }

  // 初始化物料表单
  public initMaterialForm() {
    this.materialForm = this.fb.group({
      materialFormArray: this.fb.array([
        this.fb.group({
          inventoryId: new FormControl(null),
          warehouseId: new FormControl(null),
          materialId: new FormControl(null, Validators.required),
          materialNo: new FormControl(null),
          materialName: new FormControl(null),
          materialSpec: new FormControl(null),
          materialSn: new FormControl(null),
          materialSnId: new FormControl(null),
          inventoryCate: new FormControl(null),
          inventoryNum: new FormControl(null),
          ioFincanceSubtype: new FormControl(null),
          ioNum: new FormControl(null, [
            Validators.required,
            Validators.min(1),
          ]),
        }),
      ]),
    });
    this.ioNumOption.push({
      disabled: false,
      max: 100000000,
    });
  }

  // 物料表单添加
  public addMaterial(materialData = []) {
    this.isShowMaterialQuery = false;
    const materialControl = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    materialControl.push(this.fb.group(new MaterialFormVal()));
    this.ioNumOption.push({
      disabled: false,
      max: 100000000,
    });
    if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
      this.isEditInventoryCate = true;
      this.isEditMaterialSn = true;
    } else {
      this.isEditInventoryCate = false;
      this.isEditMaterialSn = false;
    }
    // 物料清单表格内会新增一个空行，同时弹出物料查询
    if (_.isEmpty(materialData)) {
      this.showMaterialQuery(materialControl.value.length - 1);
    }
  }

  // 物料表单删除
  public removeMaterial(i: number) {
    // 编辑 删除相应数据
    this.isShowMaterialQuery = false;
    const materialControl = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    materialControl.removeAt(i);

    this.ioNumOption = _.remove(this.ioNumOption, (n, index) => {
      return index !== i;
    });
  }

  // 选择的物料数据
  public getMaterialVal(material: Material) {
    const materialFormData = this.materialForm.get('materialFormArray').value;
    let selectedMaterial = {};
    if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
      // if (!material.inventoryNum) {
      //     return false;
      //   }
      // selectedMaterial = {
      //   inventoryId: material.inventoryId,
      //   materialId: material.materialId
      // };
      // 未添加过，则添加
      const materialControls = this.materialForm.get(
        'materialFormArray'
      ) as FormArray;
      materialControls.controls[this.materialIndex].patchValue(material);
      // 序列号物料  数量只能为1
      if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
        if (material.isSn === '1') {
          this.ioNumOption[this.materialIndex] = {
            disabled: true,
            max: 1,
          };
          materialControls.controls[this.materialIndex].patchValue({
            ioNum: material.inventoryNum ? 1 : 0,
          });
        } else {
          // 出库数量 不能大于 库存数量
          this.ioNumOption[this.materialIndex] = {
            disabled: false,
            max: material.inventoryNum,
          };
          if (material.inventoryNum === 0) {
            this.tipservice.tip('warn', '该物料库存数量为0');
            materialControls.controls[this.materialIndex].patchValue({
              ioNum: 0,
            });
          }
        }
      }
    }
    if (this.ioTypes === IoType.into || this.ioTypeVal.parent === IoType.into) {
      selectedMaterial = {
        materialId: material.materialId,
      };
      if (_.find(materialFormData, selectedMaterial) === undefined) {
        // 未添加过，则添加
        const materialControls = this.materialForm.get(
          'materialFormArray'
        ) as FormArray;
        materialControls.controls[this.materialIndex].patchValue(material);
      } else {
        this.tipservice.tip('warn', '该物料已添加！');
      }
    }
  }

  // 物料搜索框  仓库和操作类别选择完成之后才可操作
  public showMaterialQuery(index) {
    // 出库：物料明细与仓库关联
    // 入库，物料明细与仓库没有关系
    if (_.isEmpty(this.warehouseVal)) {
      if (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) {
        this.tipservice.tip('warn', '请先选择仓库');
        return;
      }
      if (
        this.ioTypes === IoType.into ||
        this.ioTypeVal.parent === IoType.into
      ) {
        this.tipservice.tip('warn', '请先选择仓库');
        return;
      }
    }
    if (!this.ioTypeVal.parent && !this.ioTypes) {
      this.tipservice.tip('warn', '请先选择操作类别');
      return;
    } else {
      if (this.ioTypeVal.parent === IoType.out || this.ioTypes === IoType.out) {
        this.inOrOut = 'out';
      } else {
        this.inOrOut = 'in';
      }

      //   console.log(this.isShowMaterialQuery);
      this.isShowMaterialQuery = !this.isShowMaterialQuery;
      this.materialIndex = index;

      const materialControls = this.materialForm.get(
        'materialFormArray'
      ) as FormArray;
      const materialData = materialControls.value;
      this.materialNoSearch = materialData[index].materialNo
        ? materialData[index].materialNo
        : '';
    }
  }

  // 数据提交
  public submitRecords() {
    // 基本表单数据
    let result = _.assign({}, this.recordsData, this.editForm.value);
    const materials = this.materialForm.get('materialFormArray').value;
    if (!this.vaildFormData(result)) {
      return false;
    }
    if (!this.validMaterial(materials)) {
      return false;
    }

    if (this.showRelaWarehouse === true) {
      if (this.relaWarehouseId) {
        result.relaWarehouseId = this.relaWarehouseId;
      } else {
        this.tipservice.tip('warn', '对方仓库不能为空');
        return false;
      }
    }

    // 提交数据处理
    if (this.ioTypes) {
      result.ioType = this.ioTypes;
      result.ioSubType = result.ioSubType;
    } else {
      result.ioType = this.ioTypeVal.parent;
      result.ioSubType = this.ioTypeVal.child;
    }
    if (!_.isDate(result.updateTime) && !_.isNull(result.updateTime)) {
      result.updateTime = new Date(result.updateTime);
    }
    if (!_.isDate(result.ioTime) && !_.isNull(result.ioTime)) {
      result.updateTime = new Date(result.ioTime);
    }
    result.warehouseId = this.warehouseVal;
    result.ioOperator = result.ioOperator.userId;
    result.relaBusinessId = result.relaBusinessId.orderId;
    result.materialIostorgeDetails = materials;
    this.common.mask.show();
    if (_.isEmpty(this.recordsData) || this.ioTypes || this.isCopy) {
      if (this.isCopy) {
        result = _.omit(result, 'iostorageId');
      }
      this.sparePartsManageService.addInOutStock(result).subscribe(
        () => {
          // this.common.mask.hide();
          this.showEvent.emit(false);
          this.isSubmitData.emit(true);
        },
        () => {
          this.common.mask.hide();
        }
      );
    } else {
      this.dealEditMetail(result);
      this.sparePartsManageService.updateEditStock(result).subscribe(
        () => {
          // this.common.mask.hide();
          this.showEvent.emit(false);
          this.isSubmitData.emit(true);
        },
        () => {
          this.common.mask.hide();
        }
      );
    }
  }

  // 物料清单 编辑提交数据处理
  public dealEditMetail(result) {
    const materials = result.materialIostorgeDetails;
    for (let i = 0, len = materials.length; i < len; i++) {
      for (const material of this.recordsData.materialIostorgeDetails) {
        if (
          _.find(_.concat([], material), {
            materialId: materials[i].materialId,
          })
        ) {
          materials[i] = _.merge(material, materials[i]);
        }
      }
    }
  }

  // 物料与仓库关联校验
  public validMaterial(materials: Material[]) {
    if (_.isEmpty(materials)) {
      this.tipservice.tip('warn', '请至少选择一条物料');
      return false;
    }
    // 物料必填校验
    const materialsControls = this.materialForm.get(
      'materialFormArray'
    ) as FormArray;
    for (let i = 0, len = materialsControls.length; i < len; i++) {
      const materialControls = materialsControls.controls[i] as FormGroup;
      if (!materialControls.controls.materialId.value) {
        this.tipservice.tip('warn', '请填写所有物料信息');
        return false;
      }
      if (materialControls.controls.ioNum.value < 1) {
        this.tipservice.tip('warn', '请填写正确的物料数量');
        return false;
      }
      if (
        (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out) &&
        !materialControls.controls.inventoryNum.value
      ) {
        this.tipservice.tip('warn', '没有可出库数量');
        return false;
      }
    }

    for (const material of materials) {
      if (
        material.warehouseId !== this.warehouseVal &&
        (this.ioTypes === IoType.out || this.ioTypeVal.parent === IoType.out)
      ) {
        this.tipservice.tip('warn', '物料与所选仓库不符，请重新选择');
        return false;
      }
    }
    return true;
  }

  public vaildFormData(result) {
    const tipTxt = '请检查输入项！';
    // 必填字段校验
    for (const field in this.editForm.controls) {
      if (this.ioTypes) {
        if (!this.editForm.controls[field].valid) {
          this.editForm.controls[field].markAsDirty();
          this.tipservice.tip('warn', tipTxt);
          return false;
        }
      } else {
        if (field !== 'ioSubType') {
          if (!this.editForm.controls[field].valid) {
            this.editForm.controls[field].markAsDirty();
            this.tipservice.tip('warn', tipTxt);
            return false;
          }
        }
      }
    }

    if (!_.isObject(result.relaBusinessId)) {
      this.editForm.controls.relaBusinessId.markAsDirty();
      this.tipservice.tip('warn', '关联业务单号不存在！');
      return false;
    }

    if (!_.isObject(result.ioOperator)) {
      this.editForm.controls.ioOperator.markAsDirty();
      this.tipservice.tip('warn', '出入库人不存在！');
      return false;
    }

    if (!this.warehouseVal) {
      this.tipservice.tip('warn', tipTxt);
      return false;
    }

    if (!this.ioTypeVal.parent && !this.ioTypes) {
      this.tipservice.tip('warn', tipTxt);
      return false;
    }

    return true;
  }

  public onHide() {
    this.showEvent.emit(false);
    this.warehouseVal = null;
    this.ioTypeVal.parent = null;
    this.ioTypeVal.child = null;
  }

  public getMaterialFormItem(form) {
    return form.controls.materialFormArray.controls;
  }

  openUserDialog(event) {
    this.person = [{ userId: this.editForm.value.ioOperator.userId }];
    this.isShowUserDialog = true;
  }

  personChange(event) {
    this.editForm.patchValue({
      ioOperator: {
        realName: event.realName,
        userId: event.userId,
      },
    });
  }
}
