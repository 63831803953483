import { Injectable } from '@angular/core';
import { SelectItem, Message } from 'primeng/primeng';
import * as moment from 'moment';

import { AuthHttp } from '../../../core/auth-http';
import { AppConfigService } from '../../../app.config';
import { User, UserService } from '../../../core/user.service';
import { DefectParam, DefectInfo, DefectStatus } from '../shared/index.model';
import { CommonService } from '../../../common/services/common.service';
import { Observable } from 'rxjs';

@Injectable()
export class DefectManageService {
  public stationList: any[] = [];
  private userInfo: User;
  private apiUrl: string = this.appConfig.apiUrl;

  constructor(
    private http: AuthHttp,
    private appConfig: AppConfigService,
    private user: UserService,
    private common: CommonService
  ) {
    this.getUserInfo();
  }

  // 获取电站列表数据
  public getStationList(): Promise<any[]> {
    if (this.stationList.length === 0) {
      return this.http
        .get(`${this.apiUrl}/api/index/getSampleStationAll`)
        .toPromise()
        .then((res: any) => {
          this.stationList = res;
          return this.stationList;
        });
    } else {
      return new Promise((resolve, reject) => {
        resolve(this.stationList);
      });
    }
  }

  // 缺陷列表数据
  public getDefectInfoList(defectParam: DefectParam, pageCurrent, pageSize) {
    return this.http.get(`${this.apiUrl}/api/defects`, {
      search: {
        stationIds: defectParam.stationIds.join(','),
        defectTypes: defectParam.defectTypes.join(','),
        defectTechTypes: defectParam.defectTechTypes.join(','),
        defectDeviceTypes: defectParam.defectDeviceTypes.join(','),
        responsiblePerson: defectParam.responsiblePerson,
        startReportTime: defectParam.startReportTime,
        endReportTime: defectParam.endReportTime,
        startEndTime: defectParam.startEndTime,
        endEndTime: defectParam.endEndTime,
        page: pageCurrent,
        size: pageSize,
      },
    });
  }

  public formatDefectInfoList(data) {
    let defectInfoList: any[] = [];
    const timeFormat = 'YYYY-MM-DD HH:mm:ss';
    for (let item of data) {
      let defectInfo: any = {};
      defectInfo.defectId = item.defectId;
      defectInfo.defectCode = item.defectCode;
      defectInfo.shortName = item.shortName;
      defectInfo.defectSourceText = item.defectSourceText;
      defectInfo.defectDesc = item.defectDesc;
      defectInfo.currentStatus = item.currentSituation;
      defectInfo.stationStageText = item.stationStageText;
      defectInfo.reportTime = item.reportTime
        ? moment(new Date(item.reportTime)).format(timeFormat)
        : '';
      defectInfo.defectDeviceTypeText = item.defectDeviceTypeText;
      defectInfo.defectTypeText = item.defectTypeText;
      defectInfo.defectTechTypeText = item.defectTechTypeText;
      defectInfo.supplierName = item.supplierName;
      defectInfo.planEndTime = item.planEndTime
        ? moment(new Date(item.planEndTime)).format(timeFormat)
        : '';
      defectInfo.defectStatusText = item.defectStatusText;
      defectInfo.endTime = item.endTime ? moment(new Date(item.endTime)).format(timeFormat) : '';
      defectInfo.resultDesc = item.resultDesc;
      defectInfo.responsibleDept = item.responsibleDeptName;
      defectInfo.cooperateDept = item.coordinationDepart;
      defectInfo.responsiblePersonName = item.responsiblePersonName;
      defectInfo.checkStatusText = item.checkStatusText;
      defectInfo.checkTime = item.checkTime
        ? moment(new Date(item.checkTime)).format(timeFormat)
        : '';
      defectInfoList.push(defectInfo);
    }
    return defectInfoList;
  }

  public getUserInfo(): Observable<any> {
    return this.user.me();
  }

  public addDefect(defect) {
    return this.http.post(`${this.apiUrl}/api/defects`, defect);
  }

  public getNewDefectFlowSteps(stationId) {
    return this.http.get(`${this.apiUrl}/api/defects/getNewDefectFlowSteps?stationId=${stationId}`);
  }

  public deleteDefect(id) {
    return this.http.delete(`${this.apiUrl}/api/defects?defectIds=${id}`);
  }

  public getWorkOrders(id) {
    return this.http.delete(`${this.apiUrl}/api/defects/workOrders?defectId=${id}`);
  }

  public getDefect(id) {
    return this.http.get(`${this.apiUrl}/api/defects/${id}`);
  }

  public getDeviceList(params, page, size) {
    return this.http.post(`${this.apiUrl}/api/model/search`, params, {
      search: { page, size },
    });
  }

  public getUserInfos(userIds) {
    return this.http.get(`${this.apiUrl}/api/appUser/getUserInfos`, {
      search: { userIds },
    });
  }

  public updateDefect(defect) {
    return this.http.put(`${this.apiUrl}/api/defects`, defect);
  }

  public importFile(file) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    return this.http.post(`${this.appConfig.apiUrl}/api/doc/upload`, formData).toPromise();
  }

  // 附件下载
  public exportFile(docId) {
    return this.common.fileExport(`${this.appConfig.apiUrl}/api/doc/download`, {
      docId,
    });
  }

  // 附件删除
  public deleteFile(docIds) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/doc/delete`, {
        search: { docIds },
      })
      .toPromise();
  }

  //获取评论列表
  public getCommentList(orderId) {
    return this.http.get(`${this.appConfig.apiUrl}/api/workOrders/comments`, {
      search: { orderId },
    });
  }

  //添加评论
  public addComment(orderId, comment) {
    return this.http
      .post(`${this.appConfig.apiUrl}/api/workOrders/addComment`, '', {
        search: { orderId: orderId, comment: comment },
      })
      .toPromise();
  }

  //删除评论
  public deleteComment(commentId) {
    return this.http
      .delete(`${this.appConfig.apiUrl}/api/workOrders/deleteComment`, {
        search: { commentId },
      })
      .toPromise();
  }

  // 查询缺陷列表
  public getDefectList(params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/defects`, {
      params: this.common.filterEffectParam(params),
    });
  }

  // 仓库导出
  public exportDefects(params) {
    return this.common.fileExport(`${this.apiUrl}/api/defects/export`, params);
  }

  // 获取 缺陷单
  public getDefectByDefectId(defectId, params) {
    return this.http.get(`${this.appConfig.apiUrl}/api/m/v2/defect/${defectId}`, {
      params: this.common.filterEffectParam(params),
    });
  }

  // 获取 表单配置
  public getNullable(targetNodeId) {
    return this.http
      .get(`${this.appConfig.apiUrl}/api/workflows/nullable`, {
        params: { targetNodeId },
      })
      .toPromise();
  }

  // 催单
  public remind(defectId) {
    return this.http.put(
      `${this.appConfig.apiUrl}/api/m/v2/defect/remind`,
      {},
      { params: { defectId }, responseType: 'text' }
    );
  }

  public check(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/m/v2/defect/check`, {}, { params });
  }

  public defectAction(params) {
    return this.http.put(`${this.appConfig.apiUrl}/api/m/v2/defect`, params);
  }
  public saveAction(params) {
    return this.http.post(`${this.appConfig.apiUrl}/api/m/v2/defect`, params);
  }

  public defectActionDelete(defectId) {
    return this.http.delete(`${this.appConfig.apiUrl}/api/m/v2/defect`, {
      search: { defectId },
    });
  }
}
