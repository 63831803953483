import { HttpClient, HttpRequest } from "@angular/common/http";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { HttpClientModule } from "@angular/common/http";
import { SkipSelf, Optional } from "@angular/core";
import { AuthService } from "./auth.service";
import { PrivatePageGuard } from "./private-page.guard";
import { PublicPageGuard } from "./public-page.guard";
import { authHttpServiceCreator, AuthHttp } from "./auth-http";
import { AppConfigService } from "../app.config";
import { UserService } from "./user.service";
import { ProfileDataResolver } from "./profile-data.resolver";
import { ToastService } from "./toast.service";
import { CodeTableService } from "./code-table.service";

@NgModule({
    imports: [CommonModule, HttpClientModule],
    exports: [],
    providers: [
        {
            provide: AuthHttp,
            useFactory: authHttpServiceCreator,
            deps: [HttpClient, AppConfigService]
        },
        AuthService,
        PrivatePageGuard,
        PublicPageGuard,
        UserService,
        ProfileDataResolver,
        ToastService,
        CodeTableService
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        if (parentModule) {
            throw new Error(
                "CoreModule is already loaded. Import it in the AppModule only"
            );
        }
    }
}
