import { Injectable } from "@angular/core";
import { SelectItem } from "primeng/primeng";

import { AuthHttp } from "../../../../core/auth-http";
import { AppConfigService } from "../../../../app.config";
import { CommonService } from "../../../../common/services/common.service";
import { NullItem } from "./point-config.model";

@Injectable()
export class PointConfigService {
    constructor(
        private http: AuthHttp,
        private appConfig: AppConfigService,
        private commonService: CommonService
    ) {}

    // 通过deviceId来获取设备资料
    public getPointConfigs(id) {
        return this.http.get(
            `${this.appConfig.apiUrl}/api/basicData/pointConfigs?deviceId=${id}`
        );
    }

    public savePointConfigs(data) {
        return this.http.post(
            `${this.appConfig.apiUrl}/api/basicData/pointConfigs`,
            data
        );
    }

    public formateDropdownData(data, label, value): SelectItem[] {
        if (!data) {
            return [];
        }
        let newData: SelectItem[] = [];
        for (let d of data) {
            newData.push({ label: d[label], value: d[value] });
        }
        newData.unshift(NullItem);
        return newData;
    }
}
